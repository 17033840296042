import React, { useState, useEffect } from 'react';
import { useTheme, useMediaQuery, Drawer, Box, Typography, IconButton } from '@mui/material';
import { Link, Outlet, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import menuData from './settingsMenuData.json';
import { useSettingsStyles } from '../common/SettingsStyleProvider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function SettingsMenu({ hitchUser }) {
  const { isDrawerOpen, setIsDrawerOpen } = useSettingsStyles();  // Access the state from context
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navBarHeight = 64;
  const drawerWidth = '240px';
  const location = useLocation();
  const font = '"Poppins", sans-serif';  // Define the Poppins font
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const [subMenuHovered, setSubMenuHovered] = useState(null);
  const [menuState, setMenuState] = useState(
    menuData.menuOptions.map((menuOption) => ({ ...menuOption, expanded: true }))
  );

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);  // Update state using the context setter
  };

  const toggleMenu = (menuOption) => {
    menuOption.expanded = !menuOption.expanded;
    setMenuState([...menuState]);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const matchedSubMenu = findSubMenuByPath(currentPath);
    if (matchedSubMenu) {
      setSelectedMenuItem(matchedSubMenu.name);
    }
  }, [location.pathname]);

  const findSubMenuByPath = (path) => {
    for (const menuOption of menuData.menuOptions) {
      const subMenuOption = menuOption.subMenuOptions.find(
        (subMenuOption) => path.includes(subMenuOption.functionName.toLowerCase())
      );
      if (subMenuOption) {
        return subMenuOption;
      }
    }
    return null;
  };

  const handleMenuItemClick = (menuItem, functionName) => {
    setSelectedMenuItem(menuItem);
  };

  const menuStyle = {
    backgroundColor: theme.palette.chatLeftMenuBackground[theme.palette.mode],
    color: theme.palette.chatLeftMenuText[theme.palette.mode],
    paddingTop: '20px',
    paddingLeft: '4px',
    paddingRight: '4px',
    width: '100%',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    flexGrow: 1,
    overflowY: 'auto',
    fontFamily: font,  // Apply Poppins font to menu items
  };

  const headerContainerStyle = {
    padding: '8px',
    backgroundColor: theme.palette.chatLeftMenuBackground[theme.palette.mode],
    color: theme.palette.chatLeftMenuText[theme.palette.mode],
    borderBottom: '1px solid #1a1a1a',
    fontFamily: font,  // Apply Poppins font to menu items
  };

  return (
    <Box sx={{ zIndex: 1000, display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box
        component="main"
        sx={{
          zIndex: 1000,
          flexGrow: 1,
          p: 3,
          marginLeft: isDrawerOpen && !isMobile ? '240px' : '0px',
          marginRight: '0px',
        }}
      >
        <Outlet /> {/* This will render the selected settings page */}
      </Box>

      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Drawer
          variant={isMobile ? 'temporary' : 'persistent'}
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            width: drawerWidth,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              top: `${navBarHeight}px`,
              backgroundColor: theme.palette.chatLeftMenuBackground[theme.palette.mode],
              color: theme.palette.chatLeftMenuText[theme.palette.mode],
              borderRight: `1px solid ${theme.palette.chatLeftMenuBackground[theme.palette.mode]}`,
              height: `calc(100vh - ${navBarHeight}px)`,
              overflowY: 'auto',
              paddingRight: 0,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              backgroundColor: theme.palette.chatLeftMenuBackground[theme.palette.mode],
              overflowY: 'auto',
              flexGrow: 1,
            }}
          >
            <div style={menuStyle}>
              <div style={headerContainerStyle}>
                <Typography sx={{
                  fontFamily: font, display: 'flex', alignItems: 'center', fontSize: '16px', marginTop: '4px', marginBottom: '8px'
                }}>
                  <DashboardIcon sx={{ fontSize: '16px', marginRight: 0.5 }} />
                  My Account
                </Typography>


                <Link
                  to="/app/conversation"
                  style={{
                    padding: '0px 16px 8px 16px',
                    fontSize: '12px',
                    display: 'flex',  // Use flex to align the icon and text
                    alignItems: 'center', // Vertically center the icon with the text
                    cursor: 'pointer',
                    color: theme.palette.link[theme.palette.mode],
                    position: 'relative',
                    zIndex: 1000,
                    textDecoration: 'none',
                  }}
                >
                  <ArrowBackIcon fontSize="small" sx={{ marginRight: '4px' }} />
                  Return to Chat
                </Link>


              </div>

              {menuState.map((menuOption, index) => {
                if (menuOption.name === 'Admin' && !hitchUser?.roles.includes('HitchAdmin')) {
                  return null;
                }

                return (
                  <div key={index}>
                    <div
                      onClick={() => toggleMenu(menuOption)}
                      style={{
                        cursor: 'pointer',
                        marginTop: '8px',
                        marginBottom: '8px',
                        width: '208px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        textDecoration: 'none',
                        paddingLeft: '8px',
                        fontSize: '14px',
                        color: theme.palette.chatLeftMenuText[theme.palette.mode],
                        backgroundColor: theme.palette.chatLeftMenuBackground[theme.palette.mode],
                        ...(menuOption.expanded && { fontWeight: 'bold' }),
                      }}
                    >
                      {menuOption.name.toUpperCase()}
                      {menuOption.expanded ? (
                        <ExpandLessIcon sx={{ color: theme.palette.chatLeftMenuText[theme.palette.mode] }} />
                      ) : (
                        <ExpandMoreIcon sx={{ color: theme.palette.chatLeftMenuText[theme.palette.mode] }} />
                      )}
                    </div>
                    {menuOption.expanded &&
                      menuOption.subMenuOptions.map((subMenuOption, subIndex) => {
                        if (subMenuOption.name === 'My Organizations' && !hitchUser?.roles.includes('HitchAdmin')) {
                          return null;
                        }

                        const isActive = subMenuOption.name === selectedMenuItem;
                        const isHovered = subMenuOption.name === subMenuHovered;

                        return (
                          <Link
                            to={`/app/account/${subMenuOption.functionName.toLowerCase()}`}
                            key={subIndex}
                            style={{
                              cursor: 'pointer',
                              paddingTop: '4px',
                              paddingBottom: '4px',
                              display: 'flex',
                              alignItems: 'center',
                              textDecoration: 'none',
                              paddingLeft: '24px',
                              fontSize: '14px',
                              backgroundColor: isActive
                                ? (isHovered ? theme.palette.listItemBackground.selected_hover[theme.palette.mode] : theme.palette.listItemBackground.selected[theme.palette.mode])
                                : (isHovered ? theme.palette.listItemBackground.hover[theme.palette.mode] : 'transparent'),
                              color: theme.palette.chatLeftMenuText[theme.palette.mode],
                              width: '100%',
                            }}
                            onClick={() => handleMenuItemClick(subMenuOption.name, subMenuOption.functionName)}
                            onMouseEnter={() => setSubMenuHovered(subMenuOption.name)}
                            onMouseLeave={() => setSubMenuHovered(null)}
                          >
                            {subMenuOption.name}
                          </Link>
                        );
                      })}
                  </div>
                );
              })}
            </div>
          </Box>
        </Drawer>

        {isMobile && (
          <IconButton
            onClick={toggleDrawer(true)}
            size="small"
            style={{
              color: 'white',
              backgroundColor: '#18181B',
              opacity: 0.8,
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
              margin: '10px',
              zIndex: 1000,
              position: 'fixed',
              top: '66px',
              left: '16px',
              '&:hover': {
                backgroundColor: 'rgba(21, 101, 192, 0.8)',
              },
            }}
          >
            <KeyboardDoubleArrowRight />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

export default SettingsMenu;
