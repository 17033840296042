import { Accordion, AccordionDetails, AccordionSummary, Box, FormControlLabel, Grid, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import Slider from "react-slick";
import BotOrgChartStyles from "./BotOrgChartStyles";

function BotGenerationsSettings({
    bot,
    editData,
    editMode,
    expandedSection,
    formFields,
    handleChange,
    handleOptionChange,
    handleSliderChange,
    handleTopPSliderChange,
    setExpandedSection,
    selectedOption,
    themePrimaryButtonColor
}) {
    const {
        themePrimaryTextColor,
        themeSecondaryBackgroundColor,
        fixedWidthStyle
    } = BotOrgChartStyles();
    return (
        <Accordion id="section3" elevation={0} expanded={expandedSection.section3} onChange={() => setExpandedSection({ ...expandedSection, section3: !expandedSection.section3 })}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />} sx={{ backgroundColor: themeSecondaryBackgroundColor }}>
                <Typography variant="h6" sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Generation Settings</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: themeSecondaryBackgroundColor }}>
                <Box>
                    <Grid container spacing={2}>
                        {editMode ? (
                            <>
                                <Grid item xs={12}>
                                    <RadioGroup
                                        row
                                        aria-label="generation-settings"
                                        name="generation-settings"
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                    >
                                        <FormControlLabel
                                            value="temperature"
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: themePrimaryButtonColor,
                                                        '&.Mui-checked': { color: themePrimaryButtonColor },
                                                    }}
                                                />
                                            }
                                            label={
                                                <Tooltip title="Temperature: Controls the randomness of the output. Higher values mean more random, lower values mean more focused and deterministic.">
                                                    <span style={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Temperature</span>
                                                </Tooltip>
                                            }
                                        />
                                        <FormControlLabel
                                            value="topP"
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: themePrimaryButtonColor,
                                                        '&.Mui-checked': { color: themePrimaryButtonColor },
                                                    }}
                                                />
                                            }
                                            label={
                                                <Tooltip title="Top P: Controls the diversity of the output by limiting the model to only the most probable words until the sum of their probabilities equals the top P value.">
                                                    <span style={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Top P</span>
                                                </Tooltip>
                                            }
                                        />
                                    </RadioGroup>
                                </Grid>
                                {selectedOption === 'temperature' ? (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            name="temperature"
                                            label="Temperature"
                                            variant="outlined"
                                            value={editData.temperature ?? '1.0'}
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                style: {
                                                    color: themePrimaryTextColor,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '14px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    color: themePrimaryTextColor,
                                                    fontSize: '16px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                    ...fixedWidthStyle,
                                                },
                                                sx: {
                                                    background: themeSecondaryBackgroundColor,
                                                    height: '40px',
                                                    color: themePrimaryTextColor,
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        ...formFields.temperature.outlineStyle,
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        ...formFields.temperature.outlineStyleActive,
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        ...formFields.temperature.outlineStyleActive
                                                    },
                                                }
                                            }}
                                            sx={{ marginBottom: '16px' }}
                                            autoComplete="off"
                                        />
                                        <Box width="50%">
                                            <Slider
                                                value={parseFloat(editData.temperature)}
                                                min={0}
                                                max={2}
                                                step={0.01}
                                                onChange={handleSliderChange}
                                                aria-labelledby="temperature-slider"
                                                sx={{ color: themePrimaryButtonColor }}
                                            />
                                        </Box>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            name="topP"
                                            label="Top P"
                                            variant="outlined"
                                            value={editData.topP ?? '1.0'}
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                style: {
                                                    color: themePrimaryTextColor,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '14px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    color: themePrimaryTextColor,
                                                    fontSize: '16px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                    ...fixedWidthStyle,
                                                },
                                                sx: {
                                                    background: themeSecondaryBackgroundColor,
                                                    height: '40px',
                                                    color: themePrimaryTextColor,
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        ...formFields.topP.outlineStyle,
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        ...formFields.topP.outlineStyleActive,
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        ...formFields.topP.outlineStyleActive
                                                    },
                                                }
                                            }}
                                            sx={{ marginBottom: '16px' }}
                                            autoComplete="off"
                                        />
                                        <Box width="50%">
                                            <Slider
                                                value={parseFloat(editData.topP)}
                                                min={0}
                                                max={1}
                                                step={0.01}
                                                onChange={handleTopPSliderChange}
                                                aria-labelledby="topP-slider"
                                                sx={{ color: themePrimaryButtonColor }}
                                            />
                                        </Box>
                                    </Grid>
                                )}
                            </>
                        ) : (
                            <Grid item xs={12}>
                                <Typography sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Temperature: {bot.temperature ?? '1.0'}</Typography>
                                <Typography sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Top P: {bot.topP ?? '1.0'}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export default BotGenerationsSettings;