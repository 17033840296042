import { Box } from "@mui/material";
import HitchBox from "../../components/hitchbox/HitchBox"; // Assuming you still need this for other parts of your application
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { useEffect, useState } from 'react';

function VectorStoreGrid({data, height}) {
    const navigate = useNavigate(); 

    const columns = [
        { field: 'id', headerName: 'ID', width: 300 },
        {
            field: 'name',
            headerName: 'Store Name',
            width: 250,
            editable: true,
        },
        {
            field: 'documentCount',
            headerName: 'Documents',
            description: 'This is the number of documents in this store.',
            width: 150,
            editable: false,
            align: 'right', // Justify content to the right
            valueFormatter: (params) => {
                return params?.value?.toLocaleString(); // Add commas to the number
            }
        },
        {
            field: 'botCount',
            headerName: 'Bots Attached',
            description: 'This is the number of bots that are currently using this store.',
            sortable: false,
            width: 150,
            align: 'right', // Justify content to the right
            valueFormatter: (params) => {
                return params?.value?.toLocaleString(); // Add commas to the number
            }
        },
    ];
    

    // Assuming an average row height of 52 pixels
    const [pageSize, setPageSize] = useState(5);

    useEffect(() => {
        const calculatePageSize = () => {
            const rowHeight = 52; // Adjust based on your DataGrid's row height
            const headerHeight = 56; // Adjust based on your DataGrid's header height
            const viewportHeight = window.innerHeight;
            const availableHeight = viewportHeight - headerHeight; // Adjust if there are more elements
            const newPageSize = Math.floor(availableHeight / rowHeight);
            setPageSize(newPageSize);
        };

        calculatePageSize();

        window.addEventListener('resize', calculatePageSize);
        return () => window.removeEventListener('resize', calculatePageSize);
    }, []);

    return (
        <Box sx={{ height: `${height}px`, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={columns}
                pageSize={pageSize}
                disableRowSelectionOnClick
                onRowClick={(params) => navigate(`/app/storage/${params.id}`)} // Add the onRowClick event to navigate
                sx={{
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer', // Change the cursor to pointer on row hover
                    },
                    height: '100%', // Ensure the DataGrid fills its container
                }}
            />
        </Box>
    );
}

export default VectorStoreGrid;
