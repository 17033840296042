import React, { useEffect, useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
    IconButton
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useHubConnections } from '../../utils/HubConnectionsProvider';
import { useChatConnections } from '../../utils/ChatConnectionProvider';

function BotEditModal({ open, onClose }) {
    const theme = useTheme();
    const [botName, setBotName] = useState('');
    const [botInstructions, setBotInstructions] = useState('');
    const [bot, setBot] = useState({});

    const {
        conversation,
        chatConnection,
        hitchCommand
    } = useChatConnections();

    useEffect(() => {
        if (chatConnection && conversation && hitchCommand && open) {
            chatConnection.on('6ed4f39c-66fe-491a-8a06-aab443a31555', setBot);
            let botId = conversation.accountId;
            if (conversation.activeBotId) {
                botId = conversation.activeBotId;
            }
            console.log('botId', botId);
            hitchCommand('chat', 'bot', 'get', { theBotId: botId });

            return () => {
                chatConnection.off('6ed4f39c-66fe-491a-8a06-aab443a31555', setBot);
            };
        }
    }, [chatConnection, conversation, hitchCommand, open]);

    useEffect(() => {
        console.log('bot', bot);
        setBotName(bot.name);
        setBotInstructions(bot.standardInstructions);
    }, [bot]);

    const handleReset = () => {
        setBotName(bot?.name);
        setBotInstructions(bot?.standardInstructions);
    };

    const handleSave = () => {
        // Save botName and botInstructions
        console.log('Bot Name:', botName);
        console.log('Bot Instructions:', botInstructions);

        let theBot = bot;

        theBot.name = botName;
        theBot.standardInstructions = botInstructions;

        hitchCommand('chat', 'bot', 'update', { theBot: theBot });

        onClose();
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: {
            xs: '90%',
            sm: '80%',
            md: '70%',
            lg: '800px',
        },
        backgroundColor: theme.palette.modalBackground[theme.palette.mode],
        color: theme.palette.modalTextSecondary[theme.palette.mode],
        border: '1px solid #2A2E39',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.6)', // Subtle shadow for depth
    p: 4,
        textAlign: 'center',
        borderRadius: '10px',
        fontFamily: '"Poppins", sans-serif',
        zIndex: 3000, // Ensure higher zIndex than other components
    };

    const cancelButtonStyle = {
        color: 'white',
        backgroundColor: '#333',
        border: '1px solid #555',
        fontFamily: 'Poppins, sans-serif',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
    };

    const saveButtonStyle = {
        fontFamily: 'Poppins, sans-serif',
        color: '#000000',
        backgroundColor: '#00EDED',
        '&:hover': {
            backgroundColor: '#00EDED',
        },
        '&:active': {
            backgroundColor: '#00EDED',
            boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.3)',
        },
        marginLeft: '20px',
    };

    return (
        <Modal sx={{ zIndex: 2501 }} open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={modalStyle}>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 16,
                        color: theme.palette.modalTextSecondary[theme.palette.mode],
                    }}
                >
                    <FontAwesomeIcon icon={faTimes} size="1x" />
                </IconButton>
                <Typography variant="h6" gutterBottom style={{ color: theme.palette.modalTextSecondary[theme.palette.mode], fontFamily: '"Poppins", sans-serif', marginBottom: '20px' }}>
                    Edit Bot
                </Typography>
                <TextField
                    label="Bot Name"
                    value={botName}
                    onChange={(e) => setBotName(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                        style: {
                            color: 'grey',
                            fontSize: '18px',
                            fontFamily: '"Poppins", sans-serif',
                        },
                    }}
                    InputProps={{
                        style: {
                            color: theme.palette.modalTextSecondary[theme.palette.mode],
                            fontSize: '18px',
                            fontFamily: '"Poppins", sans-serif',
                        },
                        sx: {
                            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'grey' },
                            '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                        },
                    }}
                />
                <TextField
                    label="Bot Instructions"
                    value={botInstructions}
                    onChange={(e) => setBotInstructions(e.target.value)}
                    fullWidth
                    margin="normal"
                    multiline
                    rows={10} // Increased number of rows
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                        style: {
                            color: 'grey',
                            fontSize: '18px',
                            fontFamily: '"Poppins", sans-serif',
                        },
                    }}
                    InputProps={{
                        style: {
                            color: theme.palette.modalTextSecondary[theme.palette.mode],
                            fontSize: '18px',
                            fontFamily: '"Poppins", sans-serif',
                        },
                        sx: {
                            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'grey' },
                            '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                        },
                    }}
                />
                <Typography variant="body2" style={{ color: theme.palette.modalTextSecondary[theme.palette.mode], fontFamily: '"Poppins", sans-serif', marginTop: '10px' }}>
                Note: Changes to bot instructions will take effect in new chats. 
                </Typography>
                <Box display="flex" justifyContent="space-between" mt={2}>
                    <Button variant="contained" color="secondary" onClick={handleReset} sx={cancelButtonStyle}>Reset</Button>
                    <Box>
                        <Button variant="outlined" onClick={onClose} sx={{ mr: 1, ...cancelButtonStyle }}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={handleSave} sx={saveButtonStyle}>Save</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

export default BotEditModal;
