import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, ButtonGroup, Grid, IconButton, TextField, Typography } from "@mui/material";
import QuickbooksStep from "./QuickbooksStep";
import TypeAhead from "./TypeAhead";
import TransformerStep from "./TransformerStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCopy, faExpand, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { v4 as uuidv4 } from 'uuid';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import QbTransformerSteps from "./QbTransformerSteps";
import QbResults from "./QbResults";

function QbTransformer({ step, hubConnection, sessionId, outputOptions, outputType, allResults, runIfTrue, setTransformer, setTransformerSteps, transformerSteps, transformer, finalStep, setFinalStep, handleMessage }) {
    // const [transformer, setTransformer] = useState('');
    // const [transformerSteps, setTransformerSteps] = useState([]);
    // const [finalStep, setFinalStep] = useState(false);

    useEffect(() => {
        console.log('outputType', outputType);
    }, [outputType]);

    useEffect(() => {
        console.log('allResults', allResults);
    }, [allResults]);

    function handleInputCommit(event) {
        // Send a message to the SignalR server when transformer changes
        if (hubConnection) {
            console.log(`Sending In ${event.target.value}`);
            hubConnection.invoke("InputCommit", sessionId, event.target.value);
        }

        if (event.target.value === "JSON") {
            // TODO, MAKE SURE WE HAVE A STEP NAMED OUTPUT, AND CHANGE THE BODY TO BE '{Output}'   
        }
    }





    function generateGUID() {
        return uuidv4();
    }

    return (
        <Grid className="qb_top_row" container spacing={2}>
            <Grid item xs={12}>
                <TypeAhead
                    outputType={outputType}
                    outputOptions={outputOptions}
                    outputTypeChanged={handleInputCommit}
                    fullWidth
                    label="Output Type"
                />
            </Grid>

            <Grid item xs={12}>
                {outputType === 'JSON' &&
                    <>
                        <JsonView
                            src={transformer}
                            onChange={(e) => setTransformer(e.target.value)} />
                    </>
                }
                {outputType !== 'JSON' &&
                    <TextField
                        multiline
                        fullWidth
                        rows={10}
                        value={transformer}
                        onChange={(e) => setTransformer(e.target.value)}
                        label="Transformer Output" />
                }
            </Grid>

        </Grid>
    );
}

export default QbTransformer;
