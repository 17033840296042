import React from 'react';
import Box from '@mui/material/Box';

const HitchBox = ({ backgroundColor, color, children }) => {
  const containerStyle = {
    backgroundColor: backgroundColor,
    color: color,
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  };

  return (
    <Box style={containerStyle}>
      {children}
    </Box>
  );
};
 
export default HitchBox;
