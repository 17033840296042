import { MarkerType } from "reactflow";

export const nodes = [
  {
    id: '4adccf1f-99ff-48db-9f21-873c795ad76c',
    type: 'event',
    data: {
      label: 'Marketing - New Question',
      service: 'Marketing',
      serviceId: '1259df13-46d9-4237-a2f1-6c7ae95ac2e6',
      feature: 'Customer Relations',
      featureId: '545615cf-e350-40af-9dfa-2de66fc2e8ae',
      operation: 'Incoming Contact',
      operationId: 'dc54ba04-9cdd-4745-a337-60b247b52911',
      payload: {
        "TheEmail": "test@example.com",
        "TheQuestion": "When can I use my early access code and get access?"
      }
    },
    position: { x: 0, y: 50 },
  },
  {
    id: '4b42a180-5ba4-423d-95cc-61abf218bfd4',
    type: 'rule',
    data: {
      name: 'Inbound Bot Question',
      ruleExpression: 'true'
    },
    position: { x: 350, y: 50 },
  },
  {
    id: 'condition-node',
    type: 'condition',
    data: {
      label: 'Condition 1',
    },
    position: { x: 750, y: 50 },
  },
  {
    id: 'dependency-node',
    type: 'dependency',
    data: {
      label: 'Dependency 1'
    },
    position: { x: 1050, y: 50 }
  },
  {
    id: 'action-node',
    type: 'action',
    data: {
      label: 'Action 1'
    },
    position: { x: 350, y: 400 }
  },
  {
    id: 'dependency-node-2',
    type: 'dependency',
    data: {
      label: 'Dependency 2'
    },
    position: { x: 700, y: 400 }
  }
];

export const edges = [
  { 
    id: 'event-rule-edge', 
    source: '4adccf1f-99ff-48db-9f21-873c795ad76c', 
    target: '4b42a180-5ba4-423d-95cc-61abf218bfd4', 
    style: { stroke: '#003333', strokeWidth: 5 },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 10,
      height: 10,
      color: '#003333',
    }
  },
  {
    id: 'rule-condition-edge', 
    source: '4b42a180-5ba4-423d-95cc-61abf218bfd4', 
    target: 'condition-node', 
    style: { stroke: '#003333', strokeWidth: 5 },
    animated: true,
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 10,
      height: 10,
      color: '#003333',
    }
  },
  // ... other edges to connect different nodes as required
];