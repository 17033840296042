import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './hitchy/Dashboard/Dashboard';
import userManager from './services/authService';
import PrivateRoute from './services/privateRoute';
import Home from './components/home/Home';
import PrivacyPolicyPage from './components/privacypolicy/PrivacyPolicyPage';
import TermsofServicePage from './components/termsofservice/TermsOfServicePage';
import Contact from './components/contact/ContactUsPage';
import Admin from './Admin/Admin';
import { CustomThemeProvider } from './ThemeContext';
import RequestDemoPage from './components/requestdemo/RequestDemoPage';
import { HubConnectionsProvider } from './utils/HubConnectionsProvider';
import SigninOidc from './components/signinoidc/SigninOidc';
import Affiliate2 from './components/partners/affiliate2/affiliate2';
import { HitchModalProvider } from './utils/HitchModalContext/HitchModalContext';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { loginWithMicrosoft } from './services/useAuth'; // Corrected import statement
import Qualifier from './Qualifier/Qualifier';
import { ChatConnectionsProvider } from './utils/ChatConnectionProvider';
import { QualifierConnectionsProvider } from './utils/QualifierConnectionProvider';
import CarolinaAmesty from './hitch-ai-unit/carolina-amesty/CarolinaAmesty';
import VirtualSales from './hitch-ai-unit/virtual-sales/VirtualSales';

function App() {
  const hostname = window.location.hostname;

  const Login = () => {
    console.log('OIDC Authority:', window._env_.REACT_APP_OIDC_AUTHORITY);
    console.log('Client ID:', window._env_.REACT_APP_OIDC_CLIENT_ID);
    console.log('Redirect URI:', `${window.location.origin}/callback`);

    userManager.signinRedirect();
    return <div>Redirecting...</div>;
  };


  const LoginWithMicrosoft = () => {
    console.log('OIDC Authority:', window._env_.REACT_APP_OIDC_AUTHORITY);
    console.log('Client ID:', window._env_.REACT_APP_OIDC_CLIENT_ID);
    console.log('Redirect URI:', `${window.location.origin}/callback`);

    loginWithMicrosoft();
    return <div>Redirecting to Microsoft...</div>
  };

  const Logout = () => {
    userManager.signoutRedirect();
    return <div>Logging out...</div>;
  };

  let rootComponent;

  if (hostname.includes("hitch.partners")) {
    rootComponent = <Affiliate2 />;
  } else {
    rootComponent = (
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/app" element={<PrivateRoute />}>
            <Route path="/app" element={<Dashboard />} />
            <Route path="/app/*" element={<Dashboard />} />
          </Route>
          <Route path="/admin" element={<PrivateRoute />}>
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/*" element={<Admin />} />
          </Route>
          <Route path="/carolina" element={<QualifierConnectionsProvider><CarolinaAmesty /></QualifierConnectionsProvider>} />
          <Route path="/carolina/:guid" element={<QualifierConnectionsProvider><CarolinaAmesty /></QualifierConnectionsProvider>} />

          <Route path="/sales" element={<QualifierConnectionsProvider><VirtualSales /></QualifierConnectionsProvider>} />
          {/*<Route path="/register" Component={Register} />*/}
          <Route exact path="/letstalk" element={<Contact />} />
          {/*<Route exact path="/hitch-ai" element={<HitchAi />} />*/}
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/logout" element={<Logout />} />
          <Route exact path="/callback" element={<SigninOidc />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route exact path="/tos" element={<TermsofServicePage />} />
          {/*<Route exact path="/signup-joinourjourney" element={<JoinOurJourney />} />*/}
          <Route exact path="/request-demo" element={<RequestDemoPage />} />
          {/*<Route exact path="/show-me" element={<LearnMore />} />*/}
          {/*<Route path="/show-me/*" element={<LearnMore />} />*/}
          {/*<Route path="/partners/poc1" element={<Affiliate2 />} />*/}
          {/*<Route path="/partners/poc2" element={<Affiliate4 />} />*/}
          {/*<Route path="/partners/poc3" element={<Affiliate3 />} />*/}
          <Route path="/qualifier/:email" element={
            <QualifierConnectionsProvider>
              <Qualifier />
            </QualifierConnectionsProvider>
          } />
          {/*<Route path="/login_google" element={<LoginGoogle />} />*/}
          {/*<Route path="/login_microsoft" element={<LoginWithMicrosoft />} />*/}
        </Routes>
      </Router>
    );
  }

  return (
    <CustomThemeProvider>
      <CssBaseline /> {/* Ensures consistent baseline styles */}
      <SnackbarProvider maxSnack={3}>
        <HubConnectionsProvider>
          <ChatConnectionsProvider>
            <HitchModalProvider>
              <div className="App">
                {rootComponent}
              </div>
            </HitchModalProvider>
          </ChatConnectionsProvider>
        </HubConnectionsProvider>
      </SnackbarProvider>
    </CustomThemeProvider>
  );
}

export default App;
