import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import roleOptions from '../../DomainFiles/roleOptions.json';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


function UserInvite({ open, onClose}) {
  const firstNameRef = useRef(null);
  const [isFirstNameActive, setisFirstNameActive] = useState(true); // Add this state variable

  const [enteredData, setEnteredData] = useState({});

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState(''); // State for first name
  const [lastName, setLastName] = useState(''); // State for last name
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [roleText, setRoleText] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isRoleLabelShrunk, setIsRoleLabelShrunk] = useState(false);

  const [firstNameOutlineStyle, setFirstNameOutlineStyle] = useState({
    borderColor: 'white', // Default white outline color
  });

  const [firstNameOutlineStyleActive, setFirstNameOutlineStyleActive] = useState({
    borderColor: '#00EDED', // Default white outline color
  });

  const [firstNameError, setFirstNameError] = useState('');

  const [lastNameOutlineStyle, setLastNameOutlineStyle] = useState({
    borderColor: 'white', // Default white outline color
  });

  const [lastNameOutlineStyleActive, setLastNameOutlineStyleActive] = useState({
    borderColor: '#00EDED', // Default white outline color
  });

  const [lastNameError, setLastNameError] = useState('');

  const [emailOutlineStyle, setEmailOutlineStyle] = useState({
    borderColor: 'white', // Default white outline color
  });

  const [emailOutlineStyleActive, setEmailOutlineStyleActive] = useState({
    borderColor: '#00EDED', // Default white outline color
  });

  const [emailError, setEmailError] = useState('');

  const [roleOutlineStyle, setRoleOutlineStyle] = useState({
    borderColor: 'white', // Default white outline color
  });

  const [roleOutlineStyleActive, setRoleOutlineStyleActive] = useState({
    borderColor: '#00EDED', // Default white outline color
  });

  const [roleError, setRoleError] = useState('');

  const columnCount = 3; // Specify the number of columns

// Calculate the number of checkboxes per column
const checkboxesPerColumn = 8;

const SuccessDialog = () => (
  <Dialog open={isSuccessDialogOpen} onClose={() => setIsSuccessDialogOpen(false)}>
    <DialogTitle>Email Submitted Successfully</DialogTitle>
    <DialogContent>
      <Alert severity="success">
        <AlertTitle>Form Submitted for</AlertTitle>
        {`"${firstName} ${lastName}" with these roles: ${roles}. Email sent successfully to ${email}.`}
      </Alert>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => { setIsSuccessDialogOpen(false); onClose(); }} color="primary" autoFocus>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);


  useEffect(() => {
    handleRequiredValidation();
  }, [enteredData]);


  useEffect(() => {
    // Reset the form when the component is mounted
    resetForm();
  }, []); // Empty dependency array to ensure it only runs once when mounted

  useEffect(() => {
    if (open) {
      resetForm();
      // Set focus on the firstName input field
      firstNameRef.current?.focus();
    }
  }, [open]);

  useEffect(() => {
    // Check if the dialog is closed
    if (!open) {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (selectedRoles.length > 0) {
      setRoleText(selectedRoles.join(', '));
    } else {
      setRoleText('');
    }
  }, [selectedRoles]);


  useEffect(() => {
    if (isFirstNameActive) {
      firstNameRef.current?.focus();
    }
  }, [isFirstNameActive]);

  const selectedRolesInColumns = () => {
    const rolesPerColumn = Math.ceil(selectedRoles.length / 3); // Adjust the number of columns as needed
    const columns = [];
  
    for (let i = 0; i < 3; i++) {
      const start = i * rolesPerColumn;
      const end = (i + 1) * rolesPerColumn;
      const columnRoles = selectedRoles.slice(start, end);
      columns.push(columnRoles);
    }
  
    return columns.map((columnRoles, columnIndex) => (
      <div key={columnIndex} style={{ flex: 1, marginLeft: '16px' }}>
        {columnRoles.map((role) => (
          <div key={role} style={{ marginBottom: '4px' }}>
            {role}
          </div>
        ))}
      </div>
    ));
  };

  const resetForm = () => {
    // Reset all fields to initial values
    setEmail('');
    setFirstName('');
    setLastName('');
  
    // Set roles to the entire roleOptions array as default roles
    setRoles(roleOptions);// Reset selectedRoles to an empty array
    setSelectedRoles([]);
    setRoleText('');
    // Reset all error states and styles
    setFirstNameError('');
    setFirstNameOutlineStyle({ borderColor: 'white' });
    setFirstNameOutlineStyleActive({ borderColor: '#00EDED' });
    setLastNameError('');
    setLastNameOutlineStyle({ borderColor: 'white' });
    setLastNameOutlineStyleActive({ borderColor: '#00EDED' });
    setEmailError('');
    setEmailOutlineStyle({ borderColor: 'white' });
    setEmailOutlineStyleActive({ borderColor: '#00EDED' });
    setRoleError('');
    setRoleOutlineStyle({ borderColor: 'white' });
    setRoleOutlineStyleActive({ borderColor: '#00EDED' });
    setIsRoleLabelShrunk(false);
    firstNameRef.current?.focus();
    // console.log('Default Roles:', roleOptions);
    // console.log('Selected Roles:', selectedRoles);
    // console.log('reset form.');
  };
  

  function handleRequiredValidation() {

    if (!firstName) {
      setFirstNameError('First Name is required. Please provide a value.');
      setFirstNameOutlineStyle({ borderColor: 'red' });
      setFirstNameOutlineStyleActive({ borderColor: 'red' });
    } else {
      setFirstNameError('');
      setFirstNameOutlineStyle({ borderColor: 'white' });
      setFirstNameOutlineStyleActive({ borderColor: '#00EDED' });
    }
    if (!lastName) {
      setLastNameError('Last Name is required. Please provide a value.');
      setLastNameOutlineStyle({ borderColor: 'red' });
      setLastNameOutlineStyleActive({ borderColor: 'red' });
    } else {
      setLastNameError('');
      setLastNameOutlineStyle({ borderColor: 'white' });
      setLastNameOutlineStyleActive({ borderColor: '#00EDED' });
    }
    if (!email) {
      setEmailError('Email is required. Please provide a value.');
      setEmailOutlineStyle({ borderColor: 'red' });
      setEmailOutlineStyleActive({ borderColor: 'red' });
    } else {
      setEmailError('');
      setEmailOutlineStyle({ borderColor: 'white' });
      setEmailOutlineStyleActive({ borderColor: '#00EDED' });
    }
    if (!roles) {
      setRoleError('Role is required. Please provide a value.');
      setRoleOutlineStyle({ borderColor: 'red' });
      setRoleOutlineStyleActive({ borderColor: 'red' });
    } else {
      setRoleError('');
      setRoleOutlineStyle({ borderColor: 'white' });
      setRoleOutlineStyleActive({ borderColor: '#00EDED' });
    }
  }

//updated
  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);

    if (value.trim() === '') {
      // If input is cleared, set the border to red and show error message
      setFirstNameError('First Name is required. Please provide a value.');
      setFirstNameOutlineStyle({ borderColor: 'red' });
      setFirstNameOutlineStyleActive({ borderColor: 'red' });
    } else {
      // If there is input, keep the border white and remove error message
      setFirstNameError('');
      setFirstNameOutlineStyle({ borderColor: 'white' });
      setFirstNameOutlineStyleActive({ borderColor: '#00EDED' });
    }
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setLastName(value);

    if (value.trim() === '') {
      // If input is cleared, set the border to red and show error message
      setLastNameError('Last Name is required. Please provide a value.');
      setLastNameOutlineStyle({ borderColor: 'red' });
      setLastNameOutlineStyleActive({ borderColor: 'red' });
    } else {
      // If there is input, keep the border white and remove error message
      setLastNameError('');
      setLastNameOutlineStyle({ borderColor: 'white' });
      setLastNameOutlineStyleActive({ borderColor: '#00EDED' });
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (value.trim() === '') {
      // If input is cleared, set the border to red and show error message
      setEmailError('Email is required. Please provide a value.');
      setEmailOutlineStyle({ borderColor: 'red' });
      setEmailOutlineStyleActive({ borderColor: 'red' });
    } else {
      // If there is input, keep the border white and remove error message
      setEmailError('');
      setEmailOutlineStyle({ borderColor: 'white' });
      setEmailOutlineStyleActive({ borderColor: '#00EDED' });
    }
  };

  const handleRoleCheckboxChange = (role) => {
    // Update the selected roles based on checkbox changes
    if (selectedRoles.includes(role)) {
      setSelectedRoles((prevRoles) => prevRoles.filter((r) => r !== role));
    } else {
      setSelectedRoles((prevRoles) => [...prevRoles, role]);
    }
  
    // Update the roles state with the selected roles
    setRoles((prevRoles) => [...prevRoles, role]);
  };

//updated
const handleSendEmail = () => {
  let hasError = false;

  // Check if enteredData.firstName is empty or null
  if (!firstName || firstName.trim() === '') {
    setFirstNameError('First Name is required. Please provide a value.');
    setFirstNameOutlineStyle({ borderColor: 'red' });
    setFirstNameOutlineStyleActive({ borderColor: 'red' });
    hasError = true;
  } else {
    setFirstNameError('');
    setFirstNameOutlineStyle({ borderColor: 'white' });
    setFirstNameOutlineStyleActive({ borderColor: '#00EDED' });
  }

  if (!lastName || lastName.trim() === '') {
    setLastNameError('Last Name is required. Please provide a value.');
    setLastNameOutlineStyle({ borderColor: 'red' });
    setLastNameOutlineStyleActive({ borderColor: 'red' });
    hasError = true;
  } else {
    setLastNameError('');
    setLastNameOutlineStyle({ borderColor: 'white' });
    setLastNameOutlineStyleActive({ borderColor: '#00EDED' });
  }

  if (!email || email.trim() === '') {
    setEmailError('Email is required. Please provide a value.');
    setEmailOutlineStyle({ borderColor: 'red' });
    setEmailOutlineStyleActive({ borderColor: 'red' });
    hasError = true;
  } else {
    setEmailError('');
    setEmailOutlineStyle({ borderColor: 'white' });
    setEmailOutlineStyleActive({ borderColor: '#00EDED' });
  }

  // Email validation using a simple regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    setEmailError('Invalid email format.');
    setEmailOutlineStyle({ borderColor: 'red' });
    setEmailOutlineStyleActive({ borderColor: 'red' });
    hasError = true;
  }

  if (!selectedRoles || selectedRoles.length === 0) {
    setRoleError('At least one role is required. Please provide a value.');
    setRoleOutlineStyle({ borderColor: 'red' });
    setRoleOutlineStyleActive({ borderColor: 'red' });
    hasError = true;
  } else {
    setRoleError('');
    setRoleOutlineStyle({ borderColor: 'white' });
    setRoleOutlineStyleActive({ borderColor: '#00EDED' });
  }

  // If there are errors, prevent further execution of saving logic
  if (hasError) {
    // console.log('There are errors. Form will not be submitted.');
    return;
  }

  // Open the success dialog
  setIsSuccessDialogOpen(true);

  // console.log('Submitting the form...');

  // Implement your logic to send the email invitation with the selected role here
  // ...

  // console.log('Email sent successfully.');

  // Close the dialog after sending the email
  //resetForm();
  //onClose();
  // console.log('Form closed.');
};

  const handleCancel = () => {
    // Close the dialog without sending the email
    resetForm();
    onClose();
  };

  const dividerStyle = {
    borderTop: '1px solid #777',
    margin: '8px -8px 16px -8px',
  }; 

  const buttonStyle = {
    backgroundColor: '#00EDED',
    color: 'black',
    height: '28px',
    padding: '16px 16px',
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '16px',
    marginRight: '16px'
  };

  const buttonStyleSecondary = {
      backgroundColor: '#333333', // Seafoam green
      borderColor: 'white',
      color: 'white', // Black font color
      height: '28px', // Adjusted height
      padding: '16px 16px', // Adjusted padding (top & bottom, left & right)
      fontSize: '0.75rem', // Decreased font size
      display: 'flex', // Add flex display
      alignItems: 'center', // Align content vertically
      gap: '8px', // Add gap between icon and text
      marginBottom: '16px'
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleCancel}
      PaperProps={{
        style: {
          backgroundColor: '#0C0C0D',
          borderColor: 'gray', // A border color that stands out on a black background
          borderStyle: 'solid',
          borderWidth: '1px', // Thickness of the border
          boxShadow: '0 4px 8px 0 rgba(255, 255, 255, 0.2)', // A subtle white glow for depth
          color: 'white', // Ensures text is white against the black background
          paddingLeft: '16px',
          paddingRight: '16px'
        },
      }}
    >
      <DialogTitle>Send Invitation</DialogTitle>
      <DialogContent>
      <div>
                <TextField
                ref={firstNameRef}
                autoFocus={isFirstNameActive} 
                name="firstName"
                label="First Name"
                variant="outlined"
                fullWidth
                value={firstName}
                onChange={handleFirstNameChange}
                style={{ marginTop: '8px' }}
                InputLabelProps={{
                  style: {
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '14px',
                  },
                }}
                InputProps={{
                  style: {
                    color: 'white',
                    fontSize: '16px',
                    width: '300px',
                  },
                  sx: {
                    
                    background: '#000',
                    height: '40px',
                    color: 'white',
                    '& .MuiOutlinedInput-notchedOutline': {
                      ...firstNameOutlineStyle,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      ...firstNameOutlineStyleActive,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      ...firstNameOutlineStyleActive
                    },
                  },
                }}
                sx={{
                  marginBottom: '16px',
                }}
                required
                error={!!firstNameError}
                helperText={firstNameError}
                autoComplete="off" 
              />
            </div>
                
            <div>
              <TextField
              name="lastName"
              label="Last Name"
              variant="outlined"
              fullWidth
              value={lastName}
              onChange={handleLastNameChange}
              InputLabelProps={{ 
                style: {
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center', 
                  fontSize: '14px'
                },
              }}
              InputProps={{
                style: {
                  color: 'white',
                  fontSize: '16px',
                  width: '300px', 
                },
                sx: {
                  background: '#000',
                  height: '40px',
                  color: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    ...lastNameOutlineStyle,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    ...lastNameOutlineStyleActive,
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    ...lastNameOutlineStyleActive,
                  },
                },
              }}
              sx={{
                marginBottom: '16px',
              }}
              required
              error={!!lastNameError}
              helperText={lastNameError}
              autoComplete="off" 
            />
            </div>
            <div>
              <TextField
              name="email"
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={handleEmailChange}
              InputLabelProps={{ 
                style: {
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center', 
                  fontSize: '14px'
                },
              }}
              InputProps={{
                style: {
                  color: 'white',
                  fontSize: '16px',
                  width: '300px', 
                },
                sx: {
                  background: '#000',
                  height: '40px',
                  color: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    ...emailOutlineStyle,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    ...emailOutlineStyleActive,
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    ...emailOutlineStyleActive,
                  },
                },
              }}
              sx={{
                marginBottom: '16px',
              }}
              required
              error={!!emailError}
              helperText={emailError}
              autoComplete="off" 
            />
            </div>

            
            <div style={dividerStyle}></div>

            <div>
            <div style={{ marginBottom: '8px' }}>
              <InputLabel style={{ color: 'white', marginBottom: '4px' }}>
              Select role(s) to assign:
              </InputLabel>
            </div>


            <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {Array.from({ length: columnCount }).map((_, columnIndex) => (
                <div key={columnIndex} style={{ flexBasis: `${125 / columnCount}%`, marginRight: '16px', ...(columnIndex === 0 && { marginLeft: '16px' }) }}>
                  {roleOptions
                    .sort((a, b) => a.localeCompare(b))
                    .slice(columnIndex * checkboxesPerColumn, (columnIndex + 1) * checkboxesPerColumn)
                    .map((role) => (
                      <FormControlLabel
                        key={role}
                        control={
                          <Checkbox
                            checked={selectedRoles.includes(role)}
                            onChange={() => handleRoleCheckboxChange(role)}
                            name={role}
                            style={{ color: 'white' }}
                          />
                        }
                        label={role}
                        sx={{
                          marginBottom: '-4px',
                          width: '100%',
                        }}
                      />
                    ))}
                </div>
              ))}
            </FormControl>
          

            <div>
              <div style={{ marginTop: '16px', marginBottom: '0px' }}>
                <InputLabel style={{ color: 'white', marginBottom: '0px', fontSize: '12px', }}>
                  Selected Roles*
                </InputLabel>
              </div>
              <TextField
  name="roles"
  label="Selected Roles"
  variant="outlined"
  fullWidth
  value={roleText}
  multiline
  InputLabelProps={{
    style: {
      display: 'none',
    },
  }}
  InputProps={{
    readOnly: true,
    style: {
      color: 'grey',
      fontSize: '16px',
      width: '100%',
      borderBottom: '1px solid white', // White bottom border
      paddingTop: '8px',
      paddingBottom: '8px',
      overflow: roleText.split('\n').length > 1 ? 'hidden' : 'auto',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      borderRadius: '0', 
    },
  }}
  sx={{
    marginBottom: '0px',
  }}
  required
  error={!!roleError}
  helperText={roleError}
/>


            </div>

        </div>
      </DialogContent>
      <DialogActions>
      <Button
                  variant="outlined"
                  onClick={handleCancel}
                  style={buttonStyleSecondary} 
                >
                  <CancelIcon fontSize="smaller" /> Cancel
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleSendEmail}
                  style={buttonStyle} 
                >
                  <SendIcon fontSize="smaller" /> Send
                </Button>
      </DialogActions>
      {/* Render the new dialog component */}
      <SuccessDialog />
    </Dialog>
  );
}

export default UserInvite;