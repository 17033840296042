import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import FooterMain from '../footer/FooterMain';

function TermsofServicePage() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isExtraWide = useMediaQuery('(min-width:1920px)'); // Custom breakpoint for extra wide screens

    // Determine margin based on device size
    let horizontalMargin = 16;
    if (isMobile) {
        horizontalMargin = 4; // Smaller margin for mobile
    } else if (isTablet) {
        horizontalMargin = 8; // Medium margin for tablet
    } else if (isExtraWide) {
        horizontalMargin = 48; // Larger margin for extra wide screens
    }

    const paragraphStyle = {
        marginBottom: '16px', // Adjust this value as needed for the desired spacing
    };

    const headerStyle = {
        marginBottom: '24px', // Extra spacing below headers
    };

    const bulletBoxStyle = {
        marginLeft: '20px', // Adjust this value as needed for the desired indentation
        marginBottom: '16px', // Adjust this value as needed for the desired spacing
    };

    const bulletStyle = {
        marginBottom: '0px', // Adjust this value as needed for the desired spacing
    };

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', color: 'black', minHeight: '100vh' }}>
            <Box style={{ flex: 1 }}>
                <AppBar position="sticky" color="default" elevation={0}>
                    <Toolbar sx={{ marginTop: 1, marginBottom: 1 }}>
                        <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                            <img src={'/img/hitch.png'} alt="Hitch Logo" style={{ width: '80px', height: '80px', marginRight: '10px' }} />
                            <Typography variant="h6">Terms of Service</Typography>
                        </Link>
                    </Toolbar>
                </AppBar>
                <Box style={{ backgroundColor: 'black', color: 'white', width: '100%', padding: '16px 0', marginBottom: '24px' }}></Box>
                <Box
                    marginLeft={horizontalMargin}
                    marginRight={horizontalMargin}
                    marginTop={4}
                    marginBottom={4}
                    textAlign="left"
                >
                    <Typography variant="body1" style={{ textAlign: 'left', marginBottom: '8px' }}>
                        <strong>PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS AND REMEDIES.</strong>
                    </Typography>
                
                    <Typography variant="body1" style={{ textAlign: 'left', marginBottom: '24px' }}>
                        <strong>Effective: July 20, 2024</strong>
                    </Typography>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>1. Overview</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        These Terms of Service ("Terms") are between Hitch Software, LLC, a Florida Limited Liability Company ("Company"), and you. They become effective when you use our website at <a href="http://www.hitchsoftware.com">hitchsoftware.com</a> ("Site") or upon electronic acceptance. These Terms outline the terms and conditions for using the Site and any features, services, products, or applications offered by Hitch Software, LLC (collectively, the "Service"). By using the Service, you acknowledge that you have read, understand, and agree to be bound by these Terms and our Privacy Policy.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        When we refer to "we," "us," or "our," we mean Hitch Software, LLC. "You," "your," "User," or "customer" refers to any individual or entity who accepts these Terms, uses our Site, or accesses the Services. These Terms do not grant any rights or benefits to third parties.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        We reserve the right to change or modify these Terms at any time. If we make changes, we will update the 'Effective' date at the top of these Terms. By continuing to use the Site or the Services after any changes, you accept the modified Terms.
                    </Typography>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>2. Eligibility and Registration</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        You may use the Service only if you can form a binding contract with us and are not barred from using the Service under applicable laws. You must be at least 18 years old to use the Service. If you are under 18, you need a parent or guardian's consent to use the Service.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        When you create an account, you agree to provide accurate and current information. You are responsible for safeguarding your account by using a strong password and maintaining the confidentiality of your login details. You accept responsibility for all activities that occur under your account. We are not liable for any loss or damage arising from your failure to comply with the above.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        If you are accepting these Terms on behalf of a company, organization, or other legal entity, you represent and warrant that you are authorized to do so and have the authority to bind that entity to these Terms. In such cases, "you" and "your" will refer to that entity. If you do not have this authority, you will be personally responsible for the obligations under these Terms.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        We reserve the right to terminate accounts if you provide inaccurate information or violate these Terms.
                    </Typography>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>3. User Responsibilities and Acceptable Use</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        By using our Service, you agree to comply with these Terms, our Privacy Policy, and all applicable laws. We reserve the right to modify the Service at any time, including adding, removing, or changing features without notice.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        You agree to use the Service responsibly and ethically. Specifically, you agree not to use the Service for any unlawful purposes or to engage in any conduct that could harm, disable, overburden, or impair our Service. This includes, but is not limited to:
                    </Typography>
                    <Box style={bulletBoxStyle}>
                        <Typography variant="body1" style={bulletStyle}>
                            • Engaging in illegal activities or promoting harmful content
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            • Violating others' intellectual property or privacy rights
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            • Spamming, hacking, or conducting unauthorized testing of our systems
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            • Developing competing products or services based on our Service
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            • Using any automated tools, bots, scrapers, crawlers, or other software programs to access, collect, copy, or monitor any part of the Service without our prior written consent
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            • Disabling, impairing, or disrupting our servers, networks, or the Service's functionality
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            • Circumventing safety features or access restrictions put in place
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            • Engaging in activities that could cause harm or damage to our Service or other users
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            • Modifying, distributing, creating derivative works of, mirroring, or framing the Service without our explicit authorization
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            • Using the Service for unauthorized machine learning or AI model development
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            • Encouraging others to violate these Terms
                        </Typography>
                        <Typography variant="body1" style={paragraphStyle}>
                            • Violating export control laws and regulations
                        </Typography>
                    </Box>
                    <Typography variant="body1" style={paragraphStyle}>
                        Users acting on behalf of an organization must obtain proper authorization and follow any additional rules set by their organization's administrator.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        You are responsible for any content you submit through the Service. It is your duty to ensure that your content is legal, accurate, and does not violate the rights of others. We reserve the right to remove any content that violates these Terms without prior notice.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        We take these rules seriously and will enforce them to maintain the integrity and security of our Service. Violation of these rules may result in the termination of your account or restriction of your access to the Service.
                    </Typography>
                    
                    <Typography variant="h5" style={headerStyle}>
                        <strong>4. User Content and Intellectual Property</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        You retain ownership of any content you submit, post, or display through our Service, including content from your accounts with third-party services. By transmitting your content through the Service, you grant Hitch Software, LLC a worldwide, non-exclusive, royalty-free, and limited-term license to access, use, process, copy, store, and display your content as necessary to provide, maintain, and support the Service; to prevent or address service, security, support, or technical issues; or as required by law.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        All content provided by the Service, including text, graphics, and software, is protected by copyright and other intellectual property laws. You may not use, copy, or modify our content without permission unless explicitly allowed by these Terms. Hitch Software, LLC owns the Service, and you agree not to reverse engineer, modify, or create derivative works based on the Service without explicit permission. You agree not to infringe on third-party intellectual property rights when using our Service.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        AI-generated results can often be similar across different users due to the nature of the technology. These outputs may not always be fully accurate or reliable and should not be solely relied upon for critical decisions or as a substitute for professional advice.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        If you provide us with any feedback, suggestions, or comments about the Service, we may use this information without any obligation to you. Your feedback is considered non-confidential and may be used to improve our Service or for any other purpose we see fit.
                    </Typography>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>5. Third-Party Services and Content</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        Our Service may integrate with third-party services, and by using these integrations, you agree to comply with our Terms as well as the terms of the third parties. Our Service may also contain links to external websites. We do not control or endorse the content or practices of these third-party sites, and you use them at your own risk. We are not liable for any issues that arise from your interactions with third parties through our Service.
                    </Typography>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>6. Payment Terms</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        For paid subscriptions, we will automatically charge your payment method at each renewal period unless you cancel. Prices and fees may change, and such changes will be effective immediately upon posting on our Site. Payments are processed by us or through a third-party processor, so please ensure you provide accurate billing information and update it promptly if there are any changes.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        If we do not receive payment, we may suspend or downgrade your access to the Service. You agree to pay all amounts due upon demand. Payments are generally non-refundable, except where required by law.
                    </Typography>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>7. Disclaimer of Representations and Warranties</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        Your access to and use of the Site and Services are at your own risk. The Site and Services are provided to you on an "AS IS" and "AS AVAILABLE" basis. Hitch Software, LLC, along with its parents, affiliates, related companies, officers, directors, employees, agents, representatives, partners, and licensors ("Hitch Software Entities"), make no guarantees or promises about the Site or Services.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        To the fullest extent permitted under applicable law, the Hitch Software Entities disclaim all warranties and conditions, whether express or implied, of merchantability, fitness for a particular purpose, or non-infringement. The Hitch Software Entities make no warranty or representation and disclaim all responsibility and liability for: (i) the completeness, accuracy, availability, timeliness, security, or reliability of the Site or Services; (ii) any harm to your computer system, loss of data, or other harm that results from your access to or use of the Site or Services; (iii) the deletion of, or the failure to store or transmit, any content and other communications maintained by the Site or Services; and (iv) whether the Site or Services will meet your requirements or be available on an uninterrupted, secure, or error-free basis.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        No advice or information, whether oral or written, obtained from the Hitch Software Entities or through the Site or Services, will create any warranty or representation not expressly made herein. 
                    </Typography>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>8. Limitation of Liability</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, OR THIRD-PARTY SERVICE PROVIDERS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES; LOST PROFITS; LOSS OF USE; LOSS OF DATA; PERSONAL INJURY; FINES; FEES; PENALTIES; OR OTHER LIABILITIES, WHETHER BASED ON WARRANTY, CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, ARISING FROM OR RELATED TO THE ACCURACY, COMPLETENESS, OR CONTENT OF THIS SITE OR ANY LINKED SITES; SERVICES PROVIDED ON THIS SITE OR ANY LINKED SITES; PERSONAL INJURY OR PROPERTY DAMAGE; ANY THIRD-PARTY CONDUCT; SERVICE INTERRUPTIONS; VIRUSES OR MALICIOUS CODE TRANSMITTED VIA THIS SITE OR LINKED SITES; USER CONTENT OR ANY CONTENT DEEMED OBJECTIONABLE; AND ANY LOSS OR DAMAGE FROM YOUR USE OF THIS SITE OR SERVICES. COMPANY IS NOT LIABLE FOR DELAYS OR FAILURES CAUSED BY EVENTS BEYOND OUR CONTROL, SUCH AS NATURAL DISASTERS, INTERNET FAILURES, EQUIPMENT FAILURES, LABOR DISPUTES, OR GOVERNMENTAL ACTIONS. THIS LIMITATION OF LIABILITY APPLIES TO THE FULLEST EXTENT PERMITTED BY LAW AND SURVIVES ANY TERMINATION OF YOUR USE OF THIS SITE OR SERVICES.
                    </Typography>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>10. Indemnity</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        You agree to defend, indemnify, and hold harmless Hitch Software, LLC, along with its officers, directors, employees, agents, partners, and affiliates ("Hitch Software Entities"), from and against any claims, demands, costs, expenses, losses, liabilities, and damages, including reasonable legal and accounting fees, that arise from or relate to:
                    </Typography>
                    <Box style={bulletBoxStyle}>
                        <Typography variant="body1" style={bulletStyle}>
                            1. Your use of and access to the Site and Services,
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            2. Your violation of any provision of these Terms or any policies or agreements incorporated by reference.
                        </Typography>
                        <Typography variant="body1" style={paragraphStyle}>
                            3. Your violation of any third-party right, including any intellectual property or proprietary rights.
                        </Typography>
                    </Box>
                    <Typography variant="body1" style={paragraphStyle}>
                        These indemnification obligations will continue to apply even after these Terms end or you stop using the Site or Services.
                    </Typography>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>11. Availability of Website</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        We aim to provide access to our Site 24/7, but there may be times when the Site is unavailable due to maintenance, repairs, or circumstances beyond our control, such as telecommunication failures or third-party service outages. We do not guarantee that the Site will always be available or uninterrupted, and we are not liable for any interruptions, including those caused by third-party services.
                    </Typography>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>12. Discontinued Services</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        We reserve the right to discontinue any Service at any time, for any reason, without prior notice. If a Service is discontinued, it will no longer be supported. Where applicable, we may offer a comparable Service for migration or provide a refund. We are not liable for any modification, suspension, or discontinuance of any Service.
                    </Typography>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>13. Termination and Suspension</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        We may suspend or terminate your access to the Service if you violate these Terms, if we are required to do so by law, if your conduct poses a risk to us or others, or if your account has been inactive for over a year (with notice).
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        You may terminate your account at any time by following our account closure process. Upon termination, your access will be disabled, and your data may be deleted.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        If you believe your account was suspended or terminated in error, you can appeal by contacting our Support team at <a href="mailto:support@hitchsoftware.com">support@hitchsoftware.com</a>.
                    </Typography>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>14. No Third-Party Beneficiaries</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        These Terms do not create any third-party rights or benefits.
                    </Typography>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>15. Assignment</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        You cannot transfer or assign your rights or obligations under these Terms without our prior written consent. We may assign these Terms or any of our rights to any third party without your consent.
                    </Typography>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>16. Dispute Resolution</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        These Terms are governed by the laws of the State of Florida, United States. Any disputes arising from these Terms will be resolved through binding arbitration in Florida, on an individual basis. You waive any right to participate in a class action lawsuit against us.
                    </Typography>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>17. DMCA Notice and Procedure for Copyright Infringement Claims</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        If you believe that any content on our Site infringes your copyright, you can report it by providing our Copyright Agent with the following information in writing:
                    </Typography>
                    <Box style={bulletBoxStyle}>
                        <Typography variant="body1" style={bulletStyle}>
                            • An electronic or physical signature of the person authorized to act on behalf of the copyright owner.
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            • A description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) where the copyrighted work exists or a copy of the copyrighted work.
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            • Identification of the URL or other specific location on the Service where the material that you claim is infringing is located.
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            • Your address, telephone number, and email address.
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            • A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.
                        </Typography>
                        <Typography variant="body1" style={paragraphStyle}>
                            • A statement by you, made under penalty of perjury, that the information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.
                        </Typography>
                    </Box>
                    <Typography variant="body1" style={paragraphStyle}>
                        Please send your DMCA notice to our Copyright Agent via email or by regular mail at:
                    </Typography>
                    <Box style={bulletBoxStyle}>
                        <Typography variant="body1" style={bulletStyle}>
                            Hitch Software, LLC
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            DMCA Copyright Agent
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            2412 Irwin Street
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            Melbourne, FL 32901
                        </Typography>
                        <Typography variant="body1" style={bulletStyle}>
                            United States
                        </Typography>
                        <Typography variant="body1" style={paragraphStyle}>
                            Email: <a href="mailto:support@hitchsoftware.com">support@hitchsoftware.com</a>
                        </Typography>
                    </Box>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>18. Changes to the Service</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        We may update features, introduce new functionalities, or adjust limits on the Service. Parts of the Service may be suspended, discontinued, or have access restricted at any time. We strive to notify you in advance of significant changes, but immediate notice may not always be possible.
                    </Typography>

                    <Typography variant="h5" style={headerStyle}>
                        <strong>19. General Terms</strong>
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        These Terms constitute the entire agreement between you and us regarding the Service. If any provision of these Terms is found to be unenforceable, the remaining provisions will remain in effect. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        By using our Service, you consent to receive communications from us electronically. These communications may include service announcements, updates, and other information related to your use of the Service. You acknowledge that all agreements, notices, disclosures, and other communications provided to you electronically satisfy any legal requirements for written communication.
                    </Typography>
                    <Typography variant="body1" style={paragraphStyle}>
                        We make no representation that the content on this Site is appropriate or legal in every country or jurisdiction. Users who access this Site are responsible for compliance with all local laws.
                    </Typography>
                </Box>
            </Box>
            <FooterMain />
        </Box>
    );
}

export default TermsofServicePage;
