import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useMemo } from "react";
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-duotone-svg-icons";
import AdminFormEdit from "./AdminFormEdit";
import AdminSearchHeader from "./AdminSearchHeader";

function AdminGrid({
  columns,
  filter,
  editingModel,
  handleRowClick,
  hubConnection,
  list,
  rowSelectionModel,
  searchTerm,
  sessionId,
  setEditingModel,
  setSearchTerm,
  title,
  makeANewOne
}) {

  function onSave(model) {
    if (hubConnection && title) {
      // console.log('saving model', model);
      hubConnection.invoke(`Update${title}`, sessionId, model, searchTerm)
        .then(() => {
          setEditingModel(null);
          hubConnection.invoke(`Get${title}s`, sessionId, searchTerm)
            .then(() => {

            }).catch(error => console.error("Error sending message:", error));
        })
        .catch(error => console.error("Error sending message:", error));

    } else {
      // TODO: ERROR HERE BECAUSE SIGNAL R IS MISSING
    }
  }

  const columnsWithEdit = useMemo(() => [
    ...columns.map(column => ({
      ...column,
      width: column.width || 200
    })),
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            // console.log("Edit clicked:", params.row);
            setEditingModel(params.row);
          }}
          aria-label="edit"
          style={{ color: 'white', marginLeft: 'auto' }}
        >
          <FontAwesomeIcon icon={faPencil} />
        </IconButton>
      )
    },
  ], [columns, setEditingModel]);


  return (
    <Grid item xs={12}>
      <AdminSearchHeader
        editingModel={editingModel}
        filter={filter}
        hubConnection={hubConnection}
        searchTerm={searchTerm}
        sessionId={sessionId}
        setSearchTerm={setSearchTerm}
        title={title}
        makeANewOne={makeANewOne}
      />
      {/* {!editingModel && ( */}
        <DataGrid
          rows={list ?? []}
          columns={columnsWithEdit}
          onRowClick={handleRowClick}
          selectionModel={rowSelectionModel}
          pageSizeOptions={[5, 10]}
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          onRowEditStart={(params) => {
            setEditingModel(params.row);
          }}
          getRowClassName={(params) =>
            rowSelectionModel.includes(params.id) ? 'data-grid-selected-row' : ''}
        />
      {/* )} */}
      {/* <Grid container sx={{ backgroundColor: '#FFFFFF', paddingBottom: '10px', color: '#000000' }}>
        <AdminFormEdit
          editingModel={editingModel}
          setEditingModel={setEditingModel}
          onSave={onSave} />
      </Grid> */}
    </Grid>
  );
}

export default AdminGrid;