import React, { useEffect, useState } from 'react';
import { Typography, Button, TextField, MenuItem, Box, IconButton } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import HitchImageUploader from '../../../components/HitchImageUploader/HitchImageUploader';
import { useSettingsStyles } from '../common/SettingsStyleProvider';
import ViewOrganizationDetails from './ViewOrganizationDetails';
import { useHubConnections } from '../../../utils/HubConnectionsProvider';
import statesData from '../../DomainFiles/states.json';
import { useTheme, useMediaQuery } from '@mui/material';
import SettingsMenu from '../SettingsMenu/SettingsMenu';  // Import the SettingsMenu component
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';

function ManageOrganization() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);

  const {
    font,
    themeBackgroundColor,
    themePrimaryTextColor,
    supportStyle,
    boxStyle,
    buttonStyle,
    buttonStyleSecondary,
    dividerStyle,
    headerStyle,
    titleStyle,
    buttonContainerStyle,
    setIsDrawerOpen,
    defaultOutlineStyle,
    activeOutlineStyle,
    errorOutlineStyle,
    initialState,
    containerStyle,
    helperTextStyle,
    fixedWidthStyle
  } = useSettingsStyles();

  const { hitchUser, account, dashboardConnection, hitchDashCommand, isConnectionReady, errorSnack, infoSnack, successSnack } = useHubConnections();

  const [selectedData, setSelectedData] = useState(account);
  const [editedData, setEditedData] = useState({});
  const [isEditMode, setEditMode] = useState(false);
  const [formFields, setFormFields] = useState(initialState);
  const [contacts, setContacts] = useState([]);
  
  useEffect(() => {
    if (!isMobile) {
      setIsDrawerOpen(true);  // Open the drawer when not in mobile view
    }
  }, [isMobile, setIsDrawerOpen]);

  useEffect(() => {
    if (dashboardConnection && isConnectionReady && account) {
      dashboardConnection.on("ec43cfb8-dd4e-49f4-9aff-ae49529ef3e5", (data) => {
        if (data.StackTraceString) {
          errorSnack(`Error: ${data.Message}`)
        } else {
          console.log('ec43cfb8-dd4e-49f4-9aff-ae49529ef3e5', data);
          console.log('======================HERE-------------------');
          setContacts(data);
          //setEditMode(false);
          //successSnack('Organization has been updated.'); BUG: when this is here, this repeatedly shows when loads
        }
      });
      dashboardConnection.on("53b23a27-7373-4c92-b5b5-a674b89a1231", (data) => {
        if (data.StackTraceString) {
          errorSnack(`Error: ${data.Message}`)
        } else {
          console.log('53b23a27-7373-4c92-b5b5-a674b89a1231', data);
          console.log('---------------------HERE-------------------');
          setSelectedData(data);
          successSnack('Organization has been updated.');
        }
      })

      function callForAccountDetails() {
        console.log('Calling for Account');
        hitchDashCommand('account', 'role', 'user-list', {
          theAccountId: account.id
        });
      }

      callForAccountDetails();

      return () => {
        if (dashboardConnection) {
          dashboardConnection.off("ec43cfb8-dd4e-49f4-9aff-ae49529ef3e5");
          dashboardConnection.off("53b23a27-7373-4c92-b5b5-a674b89a1231");
        }
      };
    }
  }, [dashboardConnection, isConnectionReady, account]);


  useEffect(() => {
    if (selectedData) {
      console.log("-------------setEditedData from selectedData-----------------:", editedData);
      setEditedData(selectedData);
    }
  }, [selectedData])

  function saveToService() {
    console.log("saveToService called with editedData:", editedData);
    if (dashboardConnection && isConnectionReady) {
      var theAccount = {
        account: {
          id: account.id,
          name: editedData.name,
          description: editedData.description,
          billingAddress1: editedData.billingAddress1,
          billingAddress2: editedData.billingAddress2,
          billingAddress3: editedData.billingAddress3,
          billingCity: editedData.billingCity,
          billingState: editedData.billingState,
          billingZipCode: editedData.billingZipCode,
          subscriptionId: account.subscriptionId,
          lastBillingDate: account.lastBillingDate,
          created: account.created,
          createdBy: account.createdBy,
          updated: account.updated,
          updatedBy: account.updatedBy,
          //defaultContactId: editedData.primaryContactName,
          squareUp_Customer_Id: account.squareUp_Customer_Id,
          quickbooks_Customer_Id: account.quickbooks_Customer_Id,
          active: account.active,
          agreedToTerms: account.agreedToTerms
        }
      };

      console.log("Saving the following account data:", theAccount);

      hitchDashCommand('account', 'profile', 'update', theAccount)
      //.then(response => {
      // console.log("Save successful, response:", response);
      infoSnack('Saving data.');
      // })
      // .catch(error => {
      //  console.error("Save failed, error:", error);
      //errorSnack('Cannot Save, Something went wrong.');
      //});
    } else {
      console.error("Dashboard connection not available");
      errorSnack('Cannot Save, Something went wrong.');
    }
  }

  useEffect(() => {
    if (account) {
      console.log('=====================setSelectedData from account-------------------', account);
      setSelectedData(account);
    }
  }, [account]);

  function handleInputChange(event) {
    const { name, value } = event.target;
    // Trim the value but allow spaces within the name
    let formattedValue = value;

    // If the entire input is just spaces, set the value to an empty string
    if (value.trim() === '') {
      formattedValue = '';
    }

    if (name === 'defaultPhoneNumber') {
      formattedValue = formatPhoneNumber(value);
      if (formattedValue.replace(/\D/g, '').length === 0) {
        formattedValue = '';
      }
    }

    setEditedData({
      ...editedData,
      [name]: formattedValue,
    });
  }

  const fieldLabels = {
    name: 'Name',
    description: 'Description',
    billingAddress1: 'Billing Address Line 1',
    billingAddress2: 'Billing Address Line 2',
    billingAddress3: 'Billing Address Line 3',
    billingCity: 'City',
    billingState: 'State',
    billingZipCode: 'Postal Code',
  };

  useEffect(() => {
    handleRequiredValidation();
  }, [editedData]);


  const requiredFields = ['name', 'description', 'billingAddress1', 'billingCity', 'billingState', 'billingZipCode'];


  function handleRequiredValidation() {
    const newFieldsState = { ...formFields };

    // const requiredFields = Object.keys(fieldLabels);

    requiredFields.forEach(field => {
      if (!editedData[field]) {
        newFieldsState[field].error = `${fieldLabels[field]} is required. Please provide a value.`;
        newFieldsState[field].outlineStyle = { borderColor: errorOutlineStyle };
        newFieldsState[field].outlineStyleActive = { borderColor: errorOutlineStyle };
      } else {
        newFieldsState[field].error = '';
        newFieldsState[field].outlineStyle = { borderColor: defaultOutlineStyle };
        newFieldsState[field].outlineStyleActive = { borderColor: activeOutlineStyle };
      }
    });

    setFormFields(newFieldsState);
  }


  function handleEdit() {
    setEditMode(true);
    // setEditedData({
    //   organizationName: selectedData?.name,
    //   organizationDescription: selectedData?.description,
    //  // primaryContactName: selectedData?.primaryContactName,
    //   phone: selectedData?.phone,
    //   billingAddress1: selectedData?.billingAddress1,
    //   billingAddress2: selectedData?.billingAddress2,
    //   billingAddress3: selectedData?.billingAddress3,
    //   billingCity: selectedData?.billingCity,
    //   billingState: selectedData?.billingState,
    //   billingZipCode: selectedData?.billingZipCode,
    //   //primaryContactName: selectedData?.defaultContactId
    // });
  }

  function handleSave() {
    console.log("handleSave called with editedData:", editedData);
    let hasError = false;

    const validateField = (fieldName, fieldValue, errorMessage) => {
      const trimmedValue = fieldValue ? fieldValue.trim() : ''; // Trim the value
      if (!trimmedValue) {
        setFormFields(prevFields => ({
          ...prevFields,
          [fieldName]: {
            ...prevFields[fieldName],
            error: errorMessage,
            outlineStyle: { borderColor: errorOutlineStyle },
            outlineStyleActive: { borderColor: errorOutlineStyle },
          },
        }));
        hasError = true;
      } else {
        setFormFields(prevFields => ({
          ...prevFields,
          [fieldName]: {
            ...prevFields[fieldName],
            error: '',
            outlineStyle: { borderColor: defaultOutlineStyle },
            outlineStyleActive: { borderColor: activeOutlineStyle },
          },
        }));
      }
    };
    console.log("handle validations");
    validateField('name', editedData.name, 'Name is required. Please provide a value.');
    validateField('description', editedData.description, 'Description is required. Please provide a value.');
    //validateField('primaryContactName', editedData.primaryContactName, 'Primary Contact Name is required. Please provide a value.');
    validateField('billingAddress1', editedData.billingAddress1, 'Billing Address Line 1 is required. Please provide a value.');
    validateField('billingCity', editedData.billingCity, 'Billing City is required. Please provide a value.');
    validateField('billingState', editedData.billingState, 'Billing State is required. Please provide a value.');
    validateField('billingZipCode', editedData.billingZipCode, 'Billing Zip Code is required. Please provide a value.');

    console.log("handle validations");
    if (hasError) {
      console.warn("Form validation failed with errors in:", formFields);
      return;
    }

    saveToService();

    setSelectedData({
      ...selectedData,
      ...editedData,
    });

    setEditMode(false);
  }

  function handleCancel() {
    setEditedData(selectedData)
    setEditMode(false);
  }

  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return '';

    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
    const formattedPhoneNumber = `(${numericPhoneNumber.slice(0, 3)}) ${numericPhoneNumber.slice(3, 6)}-${numericPhoneNumber.slice(6)}`;

    return formattedPhoneNumber;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <SettingsMenu hitchUser={hitchUser} isDrawerOpen={drawerOpen} setIsDrawerOpen={setDrawerOpen} />
      <Box sx={{ flexGrow: 1, marginLeft: !isMobile && drawerOpen ? '240px' : '0px' }}>
        {isMobile && !drawerOpen && (
          <IconButton
            onClick={() => setDrawerOpen(true)}
            size="small"
            sx={{
              color: 'white',
              backgroundColor: '#18181B',
              opacity: 0.8,
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
              margin: '10px',
              zIndex: 1000,
              position: 'fixed',
              top: '66px',
              left: '16px',
              '&:hover': {
                backgroundColor: 'rgba(21, 101, 192, 0.8)',
              },
            }}
          >
            <KeyboardDoubleArrowRight />
          </IconButton>
        )}

        <Box sx={supportStyle}>
          <Box sx={boxStyle}>
            <Box sx={headerStyle}>
              <Typography variant="h6" sx={titleStyle}>Manage Organization</Typography>
            </Box>
            <div style={dividerStyle}></div>
            <Typography variant="subtitle1" style={{ marginTop: '8px', marginBottom: '16px', fontSize: '16px', fontFamily: font, }}>
              Organization Logo
            </Typography>
            <Box display="flex" justifyContent="center" marginBottom="32px">
              <HitchImageUploader guid={account?.id} />
            </Box>

            <div style={dividerStyle}></div>
            <div style={buttonContainerStyle}>
              {!isEditMode ? (
                <Button
                  variant="outlined"
                  onClick={handleEdit}
                  style={buttonStyle}
                >
                  <CreateIcon fontSize="smaller" /> Edit
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    onClick={handleCancel}
                    style={buttonStyleSecondary}
                  >
                    <CancelIcon fontSize="smaller" /> Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleSave}
                    style={buttonStyle}
                  >
                    <SaveIcon fontSize="smaller" /> Save
                  </Button>
                </>
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="subtitle1" style={{ marginBottom: '16px', fontSize: '18px', fontFamily: font, }}>
                General Information
              </Typography>
            </div>

            {isEditMode ? (
              <>
                <div style={containerStyle}>
                  <div>
                    <TextField
                      name="name"
                      label={fieldLabels.name}
                      variant="outlined"
                      fullWidth
                      value={editedData?.name}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        shrink: !!editedData?.name,  // Ensure boolean
                        style: {
                          color: themePrimaryTextColor,
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '16px',
                          fontFamily: font,
                        },
                      }}
                      InputProps={{
                        style: {
                          color: themePrimaryTextColor,
                          fontSize: '16px',
                          fontFamily: font,
                          ...fixedWidthStyle,
                        },
                        sx: {
                          background: themeBackgroundColor,
                          height: '40px',
                          color: themePrimaryTextColor,
                          '& .MuiOutlinedInput-notchedOutline': {
                            ...formFields.name.outlineStyle,
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            ...formFields.name.outlineStyleActive,
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            ...formFields.name.outlineStyleActive
                          },
                        }
                      }}
                      sx={{
                        marginBottom: '16px',
                      }}
                      required
                      error={!!formFields.name.error}
                      helperText={formFields.name.error || ' '}
                      FormHelperTextProps={{ style: helperTextStyle }}
                      autoComplete="off"
                    />
                  </div>

                  <div>
                    <TextField
                      name="description"
                      label={fieldLabels.description}
                      variant="outlined"
                      fullWidth
                      multiline={true}
                      maxRows={2}
                      value={editedData?.description}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        shrink: !!editedData?.description,  // Ensure boolean
                        style: {
                          color: themePrimaryTextColor,
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '16px',
                          fontFamily: font,
                        },
                      }}
                      InputProps={{
                        shrink: true,  // Add this line to shrink the label
                        style: {
                          color: themePrimaryTextColor,
                          fontSize: '16px',
                          fontFamily: font,
                          ...fixedWidthStyle,
                        },
                        sx: {
                          background: themeBackgroundColor,
                          height: '72px',
                          color: themePrimaryTextColor,
                          '& .MuiOutlinedInput-notchedOutline': {
                            ...formFields.description.outlineStyle,
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            ...formFields.description.outlineStyleActive,
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            ...formFields.description.outlineStyleActive,
                          },
                        }
                      }}
                      sx={{
                        marginBottom: '12px',
                      }}
                      //helperText={' '}
                      // FormHelperTextProps={{ style: helperTextStyle }}
                      autoComplete="off"
                    />
                  </div>
                  {/*<div>
                                <TextField
                                    name="primaryContactName"
                                    label={fieldLabels.primaryContactName}
                                    variant="outlined"
                                    select
                                    fullWidth
                                    value={editedData?.primaryContactName}
                                    style={{
                                        textAlign: 'left',
                                    }}
                                    onChange={handleInputChange}
                                    InputLabelProps={{
                                        shrink: true,  // Add this line to shrink the label
                                        style: {
                                            color: themePrimaryTextColor,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: '16px',
                                            fontFamily: font,
                                        },
                                    }}
                                    InputProps={{
                                        style: {
                                            color: themePrimaryTextColor,
                                            fontSize: '16px',
                                            fontFamily: font,
                                            ...fixedWidthStyle,
                                        },
                                        sx: {
                                            background: themeBackgroundColor,
                                            height: '40px',
                                            color: themePrimaryTextColor,
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                ...formFields.primaryContactName.outlineStyle,
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                ...formFields.primaryContactName.outlineStyleActive,
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                ...formFields.primaryContactName.outlineStyleActive,
                                            },
                                            '.MuiSvgIcon-root ': {
                                                fill: "rgba(255, 255, 255, 0.7) !important",
                                            }
                                        },
                                    }}
                                    sx={{
                                        marginBottom: '12px',
                                    }}
                                    helperText={' '}
                                    FormHelperTextProps={{ style: helperTextStyle }}
                                    autoComplete="off"
                                >
                                    {contacts.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option === null ? '-' : option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>*/}
                </div>
                <div style={dividerStyle}></div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="subtitle1" style={{ marginTop: '8px', marginBottom: '16px', fontSize: '18px', fontFamily: font, }}>
                    Address Information
                  </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div>
                    <TextField
                      name="billingAddress1"
                      label={fieldLabels.billingAddress1}
                      variant="outlined"
                      fullWidth
                      value={editedData?.billingAddress1 || ''}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        style: {
                          shrink: true,  // Add this line to shrink the label
                          color: themePrimaryTextColor,
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '16px',
                          fontFamily: font,
                        },
                      }}
                      InputProps={{
                        style: {
                          color: themePrimaryTextColor,
                          fontSize: '16px',
                          fontFamily: font,
                          ...fixedWidthStyle,
                        },
                        sx: {
                          background: themeBackgroundColor,
                          height: '40px',
                          color: themePrimaryTextColor,
                          '& .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingAddress1.outlineStyle,
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingAddress1.outlineStyleActive,
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingAddress1.outlineStyleActive,
                          },
                        },
                      }}
                      sx={{
                        marginBottom: '12px',
                      }}
                      required
                      error={!!formFields.billingAddress1.error}
                      helperText={formFields.billingAddress1.error || ' '}
                      FormHelperTextProps={{ style: helperTextStyle }}
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <TextField
                      name="billingAddress2"
                      label={fieldLabels.billingAddress2}
                      variant="outlined"
                      fullWidth
                      value={editedData.billingAddress2 || ''}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        shrink: true,  // Add this line to shrink the label
                        style: {
                          color: themePrimaryTextColor,
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '16px',
                          fontFamily: font,
                        },
                      }}
                      InputProps={{
                        style: {
                          color: themePrimaryTextColor,
                          fontSize: '16px',
                          fontFamily: font,
                          ...fixedWidthStyle,
                        },
                        sx: {
                          background: themeBackgroundColor,
                          height: '40px',
                          color: themePrimaryTextColor,
                          '& .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingAddress2.outlineStyle,
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingAddress2.outlineStyleActive,
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingAddress2.outlineStyleActive,
                          },
                        },
                      }}
                      sx={{
                        marginBottom: '12px',
                      }}
                      helperText={' '}
                      FormHelperTextProps={{ style: helperTextStyle }}
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <TextField
                      name="billingAddress3"
                      label={fieldLabels.billingAddress3}
                      variant="outlined"
                      fullWidth
                      value={editedData.billingAddress3 || ''}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        shrink: true,  // Add this line to shrink the label
                        style: {
                          color: themePrimaryTextColor,
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '16px',
                          fontFamily: font,
                        },
                      }}
                      InputProps={{
                        style: {
                          color: themePrimaryTextColor,
                          fontSize: '16px',
                          fontFamily: font,
                          ...fixedWidthStyle,
                        },
                        sx: {
                          background: themeBackgroundColor,
                          height: '40px',
                          color: themePrimaryTextColor,
                          '& .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingAddress3.outlineStyle,
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingAddress3.outlineStyleActive,
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingAddress3.outlineStyleActive,
                          },
                        }
                      }}
                      sx={{
                        marginBottom: '12px',
                      }}
                      helperText={' '}
                      FormHelperTextProps={{ style: helperTextStyle }}
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <TextField
                      name="billingCity"
                      label={fieldLabels.billingCity}
                      variant="outlined"
                      fullWidth
                      value={editedData?.billingCity || ''}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        style: {
                          shrink: true,  // Add this line to shrink the label
                          color: themePrimaryTextColor,
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '16px',
                          fontFamily: font,
                        },
                      }}
                      InputProps={{
                        style: {
                          color: themePrimaryTextColor,
                          fontSize: '16px',
                          fontFamily: font,
                          ...fixedWidthStyle,
                        },
                        sx: {
                          background: themeBackgroundColor,
                          height: '40px',
                          color: themePrimaryTextColor,
                          '& .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingCity.outlineStyle,
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingCity.outlineStyleActive,
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingCity.outlineStyleActive,
                          },
                        }
                      }}
                      sx={{
                        marginBottom: '12px',
                      }}
                      required
                      error={!!formFields.billingCity.error}
                      helperText={formFields.billingCity.error || ' '}
                      FormHelperTextProps={{ style: helperTextStyle }}
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <TextField
                      name="billingState"
                      label={fieldLabels.billingState}
                      variant="outlined"
                      select
                      fullWidth
                      value={editedData?.billingState || ''}
                      style={{
                        textAlign: 'left',
                      }}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        style: {
                          shrink: true,  // Add this line to shrink the label
                          color: themePrimaryTextColor,
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '16px',
                          fontFamily: font,
                        },
                      }}
                      InputProps={{
                        style: {
                          color: themePrimaryTextColor,
                          fontSize: '16px',
                          fontFamily: font,
                          ...fixedWidthStyle,
                        },
                        sx: {
                          background: themeBackgroundColor,
                          height: '40px',
                          color: themePrimaryTextColor,
                          '& .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingState.outlineStyle,
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingState.outlineStyleActive,
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingState.outlineStyleActive,
                          },
                          '.MuiSvgIcon-root ': {
                            fill: "rgba(255, 255, 255, 0.7) !important",
                          }
                        },
                      }}
                      sx={{
                        marginBottom: '12px',
                      }}
                      required
                      error={!!formFields.billingState.error}
                      helperText={formFields.billingState.error || ' '}
                      FormHelperTextProps={{ style: helperTextStyle }}
                      autoComplete="off"
                    >
                      {statesData.map((option) => (
                        <MenuItem key={option.abr} value={option.abr}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div>
                    <TextField
                      name="billingZipCode"
                      label={fieldLabels.billingZipCode}
                      variant="outlined"
                      fullWidth
                      value={editedData?.billingZipCode || ''}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        style: {
                          shrink: true,  // Add this line to shrink the label
                          color: themePrimaryTextColor,
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '16px',
                          fontFamily: font,
                        },
                      }}
                      InputProps={{
                        style: {
                          color: themePrimaryTextColor,
                          fontSize: '16px',
                          fontFamily: font,
                          ...fixedWidthStyle,
                        },
                        sx: {
                          background: themeBackgroundColor,
                          height: '40px',
                          color: themePrimaryTextColor,
                          '& .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingZipCode.outlineStyle,
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingZipCode.outlineStyleActive,
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            ...formFields.billingZipCode.outlineStyleActive,
                          },
                        }
                      }}
                      sx={{
                        marginBottom: '12px',
                      }}
                      required
                      error={!!formFields.billingZipCode.error}
                      helperText={formFields.billingZipCode.error || ' '}
                      FormHelperTextProps={{ style: helperTextStyle }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </>
            ) : (
              <ViewOrganizationDetails
                editedData={editedData}
                formatPhoneNumber={formatPhoneNumber}
                selectedData={selectedData} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ManageOrganization;