import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography, Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SupportIcon from '@mui/icons-material/Support';
import { useTheme, useMediaQuery } from '@mui/material';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import SettingsMenu from '../SettingsMenu/SettingsMenu';
import { useSettingsStyles } from '../common/SettingsStyleProvider';
import { useHubConnections } from '../../../utils/HubConnectionsProvider';

function AccountDashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const navigate = useNavigate();
  const drawerWidth = 240; // Adjust to match your drawer's width
  const themeTextColor = theme.palette.primaryText[theme.palette.mode];
  const themeCardBackground = theme.palette.cardBackground[theme.palette.mode];
  const themeButtonColor = theme.palette.primaryButton[theme.palette.mode];
  const themeButtonHoverColor = theme.palette.primaryButton.hover[theme.palette.mode];
  const themeButtonTextColor = theme.palette.primaryButtonText[theme.palette.mode];
  const font = '"Poppins", sans-serif';  // Define the Poppins font

  const {
    supportStyle,
    boxStyle,
    buttonStyle,
    headerStyle,
    titleStyle,
  } = useSettingsStyles();

  const { hitchUser } = useHubConnections();

  const dashboardItems = [
    {
      icon: <AccountCircleIcon fontSize="large" />,
      title: 'Profile Information',
      description: 'View and update your personal profile information.',
      action: () => navigate('/app/account/profile'),
    },
    {
      icon: <CreditCardIcon fontSize="large" />,
      title: 'Subscription',
      description: 'Manage your subscription plan and payment methods.',
      action: () => navigate('/app/account/manage-plan'),
    },
    {
      icon: <CreditCardIcon fontSize="large" />,
      title: 'Billing History',
      description: 'Manage your subscription plan and payment methods.',
      action: () => navigate('/app/account/billing'),
    },
    {
      icon: <SupportIcon fontSize="large" />,
      title: 'Support & Help Center',
      description: 'Access help resources or contact support.',
      action: () => navigate('/app/account/contact-support'), // Example navigation
    },
    hitchUser?.roles.includes('HitchAdmin') && {
      icon: <NotificationsIcon fontSize="large" />,
      title: 'Notification Preferences',
      description: 'View and manage your notification preferences.',
      action: () => navigate('/app/account/notification-preferences'),
    },
  ].filter(Boolean); // Filter out undefined or false items

  useEffect(() => {
    if (!isMobile) {
      setDrawerOpen(true);  // Open the drawer when not in mobile view
    }
  }, [isMobile]);

  return (
    <Box sx={{ display: 'flex' }}>
      <SettingsMenu hitchUser={hitchUser} isDrawerOpen={drawerOpen} setIsDrawerOpen={setDrawerOpen} />
      <Box sx={{ flexGrow: 1, marginLeft: !isMobile && drawerOpen ? `${drawerWidth}px` : '0px' }}>
        {isMobile && !drawerOpen && (
          <IconButton
            onClick={() => setDrawerOpen(true)}
            size="small"
            sx={{
              color: 'white',
              backgroundColor: '#18181B',
              opacity: 0.8,
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
              margin: '10px',
              zIndex: 1000,
              position: 'fixed',
              top: '66px',
              left: '16px',
              '&:hover': {
                backgroundColor: 'rgba(21, 101, 192, 0.8)',
              },
              fontFamily: font,  // Apply Poppins font to the button
            }}
          >
            <KeyboardDoubleArrowRight />
          </IconButton>
        )}

        <Box sx={supportStyle}>
          <Box style={boxStyle}>

            <Box sx={headerStyle}>
              <Typography variant="h6" sx={{ ...titleStyle, fontFamily: font }}>Account Dashboard</Typography>
            </Box>
            <Grid container spacing={3}>
              {dashboardItems.map((item, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Paper
                    sx={{
                      padding: 2,
                      backgroundColor: themeCardBackground,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      fontFamily: font,  // Apply Poppins font to the entire card
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                      {item.icon}
                      <Typography variant="h6" sx={{ marginLeft: 2, color: themeTextColor, fontFamily: font }}>
                        {item.title}
                      </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ marginBottom: 2, color: themeTextColor, fontFamily: font }}>
                      {item.description}
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={item.action}
                      sx={{
                        color: themeButtonTextColor,
                        backgroundColor: themeButtonColor,
                        fontWeight: 'bold', // Make the text bold
                        fontFamily: font,  // Apply Poppins font to the button
                        '&:hover': {
                          backgroundColor: themeButtonHoverColor, // A slightly darker shade for hover
                          color: themeButtonTextColor, // Keep the text color the same on hover
                        },
                      }}
                    >
                      Go to {item.title}
                    </Button>

                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AccountDashboard;
