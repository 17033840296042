import { faFileContract, faFileSignature, faInbox, faPaperPlaneTop, faSliders, faTaco } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { deepOrange } from "@mui/material/colors";

function ExtensionsDrawerList({ setDrawerOpen, serviceId }) {
  return (
    <Box sx={{ width: 250 }} role="presentation" onClick={(e) => setDrawerOpen(false)}>
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Avatar src={`${window._env_.HITCH_API}/api/accimage/${serviceId}.png`} sx={{ bgcolor: deepOrange[500] }}>E</Avatar>
            </ListItemIcon>
            <ListItemText primary={"Docusign"} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <FontAwesomeIcon icon={faFileContract} />
            </ListItemIcon>
            <ListItemText primary={'View Templates'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <FontAwesomeIcon icon={faFileSignature} />
            </ListItemIcon>
            <ListItemText primary={'View Envelopes'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <FontAwesomeIcon icon={faPaperPlaneTop} />
            </ListItemIcon>
            <ListItemText primary={'Send Document'} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
      <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <FontAwesomeIcon icon={faSliders} />
            </ListItemIcon>
            <ListItemText primary={'Service Configuration'} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
    </Box>
  );
}

export default ExtensionsDrawerList;