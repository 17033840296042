import React from 'react';
import { Box, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function MessageBusy({ isMobile, isTablet }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        paddingLeft: isMobile || isTablet ? '8px' : '32px',
        paddingBottom: '8px',
        paddingTop: '8px',
      }}
    >
      <Avatar
        src='/img/glowingsmiley.svg'
        style={{
          marginRight: '16px',
          width: '38px',
          height: '38px',
          backgroundColor: theme.palette.glowingSmiley[theme.palette.mode],
          boxShadow: '0 0 8px 1px rgba(54, 103, 89, 0.8)',
        }}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end', // Align items to the bottom
          justifyContent: 'center',
          height: '20px',
          marginTop: '8px', // Adjust as needed to lower the dots
        }}
      >
        <div
          style={{
            width: '8px',
            height: '8px',
            margin: '0 2px',
            backgroundColor: '#00EDED',
            borderRadius: '50%',
            display: 'inline-block',
            animation: 'typing 1s infinite',
          }}
        />
        <div
          style={{
            width: '8px',
            height: '8px',
            margin: '0 2px',
            backgroundColor: '#00EDED',
            borderRadius: '50%',
            display: 'inline-block',
            animation: 'typing 1s infinite',
            animationDelay: '0.2s',
          }}
        />
        <div
          style={{
            width: '8px',
            height: '8px',
            margin: '0 2px',
            backgroundColor: '#00EDED',
            borderRadius: '50%',
            display: 'inline-block',
            animation: 'typing 1s infinite',
            animationDelay: '0.4s',
          }}
        />
        <style>
          {`
            @keyframes typing {
              0%, 80%, 100% {
                transform: translateY(0);
              }
              40% {
                transform: translateY(-8px);
              }
            }
          `}
        </style>
      </div>
    </Box>
  );
}

export default MessageBusy;
