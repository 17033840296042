import React, { useEffect, useState } from 'react';
import AddPlan from './AddPlan'; // Import the new component
import PaymentAndSummary from './PaymentAndSummary';
import './SubscriptionWizard.css'; // Make sure to create this CSS file
import plansData from './plans.json'; // Adjust the path as needed
import { Typography } from '@mui/material';
import { useHubConnections } from '../utils/HubConnectionsProvider';

function Subscribe() {
  const defaultPlan = plansData.find(plan => plan.isDefault) || plansData[0];
  const [step, setStep] = useState(1);
  const [selectedPlanId, setSelectedPlanId] = useState(defaultPlan.id);
  const [plansData, setPlansData] = useState([]);

  const [showCancelPrompt, setShowCancelPrompt] = useState(false);
  const { dashboardConnection, hitchUser, hitchDashCommand, infoSnack, warningSnack } = useHubConnections();

  useEffect(() => {
    console.info('hello!!!');
  }, []);

  useEffect(() => {
    console.log('WHAAAAAAAAAAAATTTTTTTTTTTTTTTTTTTT');
    if (hitchDashCommand && dashboardConnection) {
      console.log('I DONT KNOW!');
      //dashboardConnection.on("689c44bd-0cf2-454b-9951-a5f56c71bd42", setPlansData);
      dashboardConnection.on("5de65fab-e866-4ec3-ba1a-733d13ef53c9", unlockOffer);

      function callForOffers() {
        if (dashboardConnection) {
          console.log('Calling for Subscription List');
          hitchDashCommand('marketing', 'offer', 'subscriptions', {});
        }
      } 

      callForOffers();

      return () => {
        //dashboardConnection.off("689c44bd-0cf2-454b-9951-a5f56c71bd42", setPlansData);
        dashboardConnection.off("5de65fab-e866-4ec3-ba1a-733d13ef53c9", unlockOffer);
      };
    }
  }, [dashboardConnection, hitchDashCommand]);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    phoneType: '',
    howDidYouHear: '',
    organizationName: '',
    employees: '',
    selectedPlanId: '',
  });

  function planData(data) {
    console.log('SETTING PLANS DATA!', data);
    console.log('It was', plansData);
    // setPlansData(data);
  }

  function unlockOffer(data) {
    console.log('plansData', plansData);
    console.log('unlockOffer', data);
    console.log('plansData', plansData);

    if (data.isLocked) {
      console.log('offer still locked:', data.title);
      // warningSnack(`Access Code Invalid`);
      return;
    }
    console.log('the unlocked offer', data);
    console.log('plansData', plansData);
    // Ensure plansData is defined and has elements before mapping
    if (!plansData || plansData.length === 0) {
      console.error('plansData is empty or not defined');
      return;
    }

    const updatedPlansData = plansData.map(plan => {
      if (plan.id === "00000000-0000-0000-0000-000000000000") {
        return {
          ...plan,
          ...data
        };
      }
      return plan;
    });

    console.log('find in here', updatedPlansData);
    planData(updatedPlansData);
  }


  const handleNext = (newData) => {
    setFormData(prevData => ({ ...prevData, ...newData }));
    if (step === 1) {
      // On the first step, move to the second step directly
      setStep(2);
    } else {
      // Handle completion of the second step here
      // E.g., finalizing the subscription
    }
  };

  const handleBack = () => {
    if (step === 2) {
      // Allow going back to the first step
      setStep(1);
    } else {
      // Handle going back from the first step (if needed)
    }
  };


  // Render different content based on the current step
  const renderStepContent = () => {
    switch (step) {
      case 1:
        console.error('step 1');
        return (
          <AddPlan
            selectedPlanId={selectedPlanId}
            setSelectedPlanId={setSelectedPlanId}
            onBack={handleBack}
            onNext={handleNext}
            plansData={plansData}
            planData={planData}
          />
        );
      case 2:
        console.error('step 2');
        return (
          <PaymentAndSummary
            formData={formData}
            onBack={() => setStep(1)} // Go back to step 1
            onSubscribe={() => {
              // Handle subscription logic here
            }}
          />
        );
      default:
        return <div>Unknown Step</div>;
    }
  };

  return (
    <div style={{
      zIndex: 2000, // This should be higher than other elements
    }} className="container-subscribe">
      <div className="white-box-subscribe">
        <Typography
          sx={{
            color: "white",
            backgroundColor: "yellow"
          }}
        >

          Toodle Loodle Loo
        </Typography>
        {/* New Step Indicator */}
        <div className="step-indicator-subscribe">
          <Typography
            sx={{
              color: "white",
              backgroundColor: "orange"
            }}
          >

            Toodle Loodle Loo123
          </Typography>
          {[1, 2].map((number, index) => (
            <div key={number} className="step-subscribe">
              <div className={`step-circle-subscribe ${step === number ? 'active' : ''}`}>
                {number}
              </div>
              <div className="step-text-subscribe">
                {number === 1 ? 'Choose Plan' : 'Checkout'}
              </div>
              {index < 1 && (
                <div className="step-line-subscribe"></div>
              )}
            </div>
          ))}
        </div>
        <Typography
          sx={{
            color: "white",
            backgroundColor: "cyan"
          }}
        ></Typography>
        <div className="content-setup-subscribe">
          {renderStepContent()}
        </div>

      </div>
    </div>
  );
}



export default Subscribe;