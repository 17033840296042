import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Box, ButtonGroup, Grid, Modal, TextField, Typography, Checkbox, FormControlLabel } from "@mui/material";
import AdminGrid from "../AdminGrid";
import { useHubConnections } from "../../utils/HubConnectionsProvider";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import LocalMetadata from './LocalMetadata';
import dayjs from 'dayjs';
// import SkuGrid from './SkuGrid';
import { useAdminHubConnections } from '../../utils/HubAdminConnectionProvider';
// import SkuModal from './SkuModal';
import planlist from './planlist.json'

function Plan({ sessionId, setActiveOrganization }) {
    const {
        defaultSnack,
        infoSnack,
        warningSnack,
        errorSnack
    } = useHubConnections();

    const { adminConnection, hitchAdminCommand, hitchUser } = useAdminHubConnections();

    const [list, setList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [editingModel, setEditingModel] = useState({
        id: uuidv4(),
        name: 'New Offer!',
        accountId: hitchUser?.accountId,
        quota: 0,
        assigned: 0,
        startDate: dayjs(),
        endDate: dayjs().add(1, 'month'),
        factsToMeet: {},
        skus: [],
        isSubscription: false,
        accessCode: '',
        hasAccessCode: false,
        subTitle: '',
        paidWaitingList: false,
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [metadataKeys, setMetadataKeys] = useState([]);
    const [skuModalOpen, setSkuModalOpen] = useState(false);

    const columns = [
        { field: 'id', headerName: 'ID', width: 300 },
        { field: 'name', headerName: 'Offer Name', width: 400, editable: true },
    ];

    function editRow(data) {
        setModalOpen(true);
        setEditingModel({
            ...data,
            startDate: dayjs(data.startDate),
            endDate: dayjs(data.endDate),
            factsToMeet: data.factsToMeet || {},
            skus: data.skus || []
        });
    }

    useEffect(() => {
        if (adminConnection) {
            adminConnection.on("544adfcb-01fe-4671-bf6a-63f156e1c7f4", (data) => {
                console.log('offer', data);
                if (data.StackTraceString) {
                    errorSnack(`Error: ${data.Message}`)
                } else {
                    setList(planlist);
                }
            });
            adminConnection.on("5de65fab-e866-4ec3-ba1a-733d13ef53c9", (data) => {
                console.log('offer', data);
                if (data.StackTraceString) {
                    errorSnack(`Error: ${data.Message}`)
                } else {
                    if(data.id === editingModel.id) {
                        setEditingModel(data);
                    }
                }
            });
            callForOffers();
        }

        return () => {
            if (adminConnection) {
                adminConnection.off("544adfcb-01fe-4671-bf6a-63f156e1c7f4");
            }
        };
    }, [adminConnection]);

    useEffect(() => {
        if (editingModel) {
            setMetadataKeys(Object.keys(editingModel.factsToMeet || {}));
        }
    }, [editingModel]);

    useEffect(() => {
        if (editingModel.accessCode && editingModel.accessCode.trim() !== '') {
            setEditingModel((prev) => ({ ...prev, hasAccessCode: true }));
        } else {
            setEditingModel((prev) => ({ ...prev, hasAccessCode: false }));
        }
    }, [editingModel.accessCode]);

    const filter = () => {
        if (adminConnection) {
            adminConnection
                .invoke('GetOrganizations', sessionId, searchTerm)
                .then(() => {
                    setLoading(true);
                })
                .catch((error) => console.error('Error sending message:', error));
        }
    };

    function callForOffers() {
        if (adminConnection) {
            console.log('Calling for Offer List');
            hitchAdminCommand('marketing', 'offer', 'ui-list', {});
        }
    }

    function handleRowClick(data) {
        console.log('click', data);

        setRowSelectionModel([data.id]);
    }

    function makeANewOne() {
        defaultSnack('New One Called.');
        setEditingModel({
            id: uuidv4(),
            name: 'New Offer!',
            accountId: hitchUser?.accountId,
            quota: 0,
            assigned: 0,
            startDate: dayjs(),
            endDate: dayjs().add(1, 'month'),
            factsToMeet: {},
            skus: [],
            isSubscription: false,
            accessCode: '',
            hasAccessCode: false,
            subTitle: '',
            paidWaitingList: false,
        });

        setModalOpen(true);
    }

    const handleModalClose = () => setModalOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'auto'
    };

    function cancelModal() {
        setModalOpen(false);
        // warningSnack('Edit Offer Cancelled.');
    }

    function saveOffer() {
        if (adminConnection) {
            console.log('saving: ', editingModel);
            hitchAdminCommand('marketing', 'offer', 'save', { theOffer: editingModel });
        }
        setModalOpen(false);
        infoSnack('Offer saved.');
    }

    const handleAddMetadata = () => {

        setEditingModel((prev) => {
            const factsToMeet = prev.factsToMeet || {}; // Initialize if null or undefined
            console.log('yo', factsToMeet);
            if (Object.keys(factsToMeet).length < 16) {
                const newKey = `key${Object.keys(factsToMeet).length + 1}`;
                return {
                    ...prev,
                    factsToMeet: { ...factsToMeet, [newKey]: '' },
                };
            }
            return prev; // Return previous state if the length condition is not met
        });

        setMetadataKeys((prev) => {
            const factsToMeet = editingModel.factsToMeet || {}; // Ensure this is initialized before accessing length
            if (Object.keys(factsToMeet).length < 16) {
                const newKey = `key${Object.keys(factsToMeet).length + 1}`;
                return [...prev, newKey];
            }
            return prev; // Return previous state if the length condition is not met
        });
    };


    const handleMetadataChange = (key, value) => {
        setEditingModel((prev) => ({
            ...prev,
            factsToMeet: { ...prev.factsToMeet, [key]: value },
        }));
    };

    function onSkuSelect(data) {

        if (adminConnection) {

            console.log('Selected Sku', data);
            console.log('shaka-khan! -> Need to call to save this sku to this offer');
            hitchAdminCommand('marketing', 'offer', 'add-sku', {
                offerId: editingModel.id,
                skuId: data,
                overridePrice: 10.00
            });
        }

    }

    const handleRemoveMetadata = (key) => {
        const newMetadata = { ...editingModel.factsToMeet };
        delete newMetadata[key];
        setEditingModel((prev) => ({ ...prev, factsToMeet: newMetadata }));
        setMetadataKeys((prev) => prev.filter((k) => k !== key));
    };

    const handleFieldChange = (field, value) => {
        setEditingModel((prev) => ({ ...prev, [field]: value }));
    };

    const handleDateRangeChange = (newValue) => {
        setEditingModel((prev) => ({
            ...prev,
            startDate: newValue[0],
            endDate: newValue[1],
        }));
    };

    const handleAddSku = () => {
        setSkuModalOpen(true);
    };

    const handleSkuModalClose = () => setSkuModalOpen(false);

    const handleSkuSelect = (sku) => {
        setEditingModel((prev) => ({
            ...prev,
            skus: [...prev.skus, sku],
        }));
        setSkuModalOpen(false);
    };

    function handleRemoveSku(data) {
        console.log('data remove', data);
        if (adminConnection) {
            hitchAdminCommand('marketing', 'offer', 'remove-sku', {
                offerId: editingModel.id,
                skuId: data.id
            });
        }
    }

    function handleSkuChange(index, key, value) {
        setEditingModel((prev) => {
            const newSkus = [...prev.skus];
            newSkus[index] = { ...newSkus[index], [key]: value };
            return {
                ...prev,
                skus: newSkus
            };
        });
    }

    function handleSaveSku(data) {
        console.log('handleSaveSku', data);

        hitchAdminCommand('marketing', 'offer', 'add-sku', {
            offerId: data.offerId,
            skuId: data.skuId,
            overridePrice: data.overridePrice
        });
    }

    return (
        <Box m={3}>
            <AdminGrid
                columns={columns}
                filter={filter}
                editingModel={editingModel}
                handleRowClick={handleRowClick}
                hubConnection={adminConnection}
                list={list}
                rowSelectionModel={rowSelectionModel}
                searchTerm={searchTerm}
                sessionId={sessionId}
                setEditingModel={editRow}
                setSearchTerm={setSearchTerm}
                title={'Plans'}
                makeANewOne={makeANewOne}
            />

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
            >
                <Box sx={style}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Id"
                                disabled
                                fullWidth
                                value={editingModel?.id} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Plan Name"
                                fullWidth
                                value={editingModel?.name}
                                onChange={(e) => handleFieldChange('name', e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="SquareUp Plan Id"
                                fullWidth
                                value={editingModel?.squareSubscriptionPlanId}
                                onChange={(e) => handleFieldChange('squareSubscriptionPlanId', e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <ButtonGroup fullWidth>
                                <Button onClick={saveOffer}>Save Plan</Button>
                                <Button onClick={cancelModal}>Cancel</Button>
                            </ButtonGroup>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='h3'>Plan offer grid here.</Typography>
                        </Grid>
                    </Grid>


                </Box>
            </Modal>
            {/* <SkuModal
                handleSkuSelect={handleSkuSelect}
                handleSkuModalClose={handleSkuModalClose}
                skuModalOpen={skuModalOpen}
                onSkuSelect={onSkuSelect}
                style={style} /> */}
        </Box>
    );
}

export default Plan;
