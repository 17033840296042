import { Grid } from "@mui/material";
import { useState } from "react";
import ServiceFlow from "./ServiceFlow";


function ServiceStatus() {
    
    return (
    <Grid container>
        <Grid item xs={12}>
            <ServiceFlow />
        </Grid>
    </Grid>
    );
}

export default ServiceStatus;