// BillDetails.js
import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const BillDetails = ({ data }) => {
    const columns = [
        { field: 'date', headerName: 'Date', width: 150 },
        { field: 'description', headerName: 'Description', width: 300 },
        { field: 'amount', headerName: 'Amount', width: 150 },
    ];

    return (
        <div style={{ height: 400, width: '100%' }}>
            <h2>Bill Details</h2>
            <DataGrid rows={data} columns={columns} pageSize={5} />
        </div>
    );
}

export default BillDetails;
