import React, { useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Avatar, Grid, Typography, IconButton } from '@mui/material';
import ContextMenu from './ContextMenu';
import { useHubConnections } from '../../utils/HubConnectionsProvider';

const HitchBotNode = ({ data }) => {
  const [hovered, setHovered] = useState(false);

  const { hitchUser, infoSnack } = useHubConnections();
  const [userImage, setUserImage] = useState('');

  useEffect(() => {
    if(hitchUser) {
      // Assuming you have an API to fetch user images
      setUserImage(`${window._env_.HITCH_API}/api/accimage/${data?.id}.png`);
    }
  }, [hitchUser]);

  const [contextMenuAnchor, setContextMenuAnchor] = useState(null);

  const handleContextMenuOpen = (event) => {
    event.preventDefault();
    setContextMenuAnchor(event.currentTarget);
  };

  const handleContextMenuClose = () => {
    setContextMenuAnchor(null);
  };

  const handleOpenModal = () => {
    data.setBotId(data.id); // Opens the bot details modal
  };

  const handleDeleteBot = () => {
    handleContextMenuClose();
    if (window.confirm('Are you sure you want to delete this bot?')) {
      data.deleteBot(data.id); // Delete bot function passed via data
    }
  };

  const handleCreateAndConnect = () => {
    handleContextMenuClose();
    data.createNewBot(); // Create a new bot and connect it
  };

  const menuItems = [
    {
      label: 'Edit Bot',
      onClick: handleOpenModal,
    },
    {
      label: 'Delete Bot',
      onClick: handleDeleteBot,
    },
    {
      label: 'Create and Connect',
      onClick: handleCreateAndConnect,
    }
  ];

  return (
    <div
      className="customNode hitchy-node"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="customNodeBody" onContextMenu={handleContextMenuOpen}>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Avatar sx={{ width: 24, height: 24 }} src={userImage} />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle1" className="user-name">{data.label}</Typography>
            <Typography variant="body2" className="user-title"></Typography>
          </Grid>
          <Grid item xs={2}>
            {hovered && <div className="snowmanIcon" onClick={handleContextMenuOpen}>&#8942;</div>}
          </Grid>
        </Grid>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        className="customHandle"
      />
      <Handle
        type="target"
        position={Position.Top}
        id="b"
        className="customHandle"
      />
      <ContextMenu anchorEl={contextMenuAnchor} handleClose={handleContextMenuClose} menuItems={menuItems} />
    </div>
  );
};

export default HitchBotNode;
