import React, { useEffect, useRef } from 'react';
import { Box, IconButton, Container, useMediaQuery, Backdrop } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AiConversation from './AiConversation';
import { useQualifierConnections } from '../../utils/QualifierConnectionProvider';

function AiChatDrawer({ isOpen, onClose, overrideHeight, theme, mode, defaultPlaceholder }) {
  const inputRef = useRef(null);
  const captchaRef = useRef(null);
  const maxWidth = '1584px';
  const isXS = useMediaQuery(theme.breakpoints.down('sm'));
  const isSM = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  // Get connection and CAPTCHA status from QualifierConnectionProvider
  const {
    sessionId,
    captchaValid, // Get the status of CAPTCHA validation from provider
    validateCaptcha, // Call CAPTCHA validation
    initializeConnection, // Initialize connection after CAPTCHA is validated
  } = useQualifierConnections();

  let baseHeightValue;
  if (overrideHeight) {
    baseHeightValue = overrideHeight;
  } else {
    if (isXS) {
      baseHeightValue = 65;
    } else if (isSM) {
      baseHeightValue = 40;
    } else {
      baseHeightValue = 40;
    }
  }

  const overrideHeightInternal = `${baseHeightValue}vh`;
  let adjHeight;
  let drawerHeightValue;
  if (isXS) {
    const screenHeightThresholdMobileXsm = 500;
    const screenHeightThresholdMobileSm = 600;
    const screenHeightThresholdMobileMd = 800;

    if (window.innerHeight < screenHeightThresholdMobileXsm) {
      drawerHeightValue = `95vh`;
    } else if (window.innerHeight <= screenHeightThresholdMobileSm && window.innerHeight > screenHeightThresholdMobileXsm) {
      drawerHeightValue = `95vh`;
    } else if (window.innerHeight <= screenHeightThresholdMobileMd && window.innerHeight > screenHeightThresholdMobileSm) {
      drawerHeightValue = `95vh`;
    } else {
      drawerHeightValue = `95vh`;
    }
  } else if (isSM) {
    drawerHeightValue = `${baseHeightValue + 16}vh`;
  } else {
    const screenHeightThreshold = 1200;
    adjHeight = window.innerHeight < screenHeightThreshold ? 20 : 12;
    drawerHeightValue = `${baseHeightValue + adjHeight}vh`;
  }

  const boxHeight = drawerHeightValue;

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  // Ensure the CAPTCHA is rendered after the component mounts
  useEffect(() => {
    if (!captchaValid && captchaRef.current && isOpen) {
      window.turnstile.render(captchaRef.current, {
        sitekey: "0x4AAAAAAAi3N-gBYbMDWdJF", // Your site key from Cloudflare
        callback: (token) => {
          validateCaptcha(token).then((isValid) => {
            if (isValid) {
              initializeConnection(token, mode); // Initialize the chat connection
            }
          });
        },
        "error-callback": () => console.log("CAPTCHA Error"),
      });
    }
  }, [captchaValid, captchaRef, validateCaptcha, initializeConnection, isOpen]);

  return (
    <>
      <Backdrop open={isOpen} />

      {isOpen && (
        <Box className={`chat-drawer ${isOpen ? 'open' : ''}`}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: boxHeight,
            backgroundColor: '#1a1a1a',
            color: 'white',
            left: 0,
            right: 0,
            maxWidth: maxWidth,
          }}
        >
          {/* Close button */}
          <IconButton onClick={onClose}
            sx={{
              alignSelf: 'flex-end',
              color: 'white',
              mt: '2px',
              mr: '4px',
            }}>
            <CloseIcon />
          </IconButton>

          {/* CAPTCHA or Chat */}
          {!captchaValid ? (
            <Container
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', // Ensure the container takes full height
              }}
            >
              <div ref={captchaRef} /> {/* CAPTCHA will be rendered here */}
            </Container>
          ) : (
            <Container className='fix-chat' sx={{ minWidth: '100%', maxWidth: 'none', mt: '-14px' }}>
              <AiConversation
                isInChatDrawer={true}
                noHistory={true}
                overrideSessionId={sessionId}
                maxWidth={maxWidth}
                overrideHeight={overrideHeightInternal}
                drawerHeightValue={drawerHeightValue}
                theme={theme}
                mode={mode}
                defaultPlaceholder={defaultPlaceholder} />
            </Container>
          )}
        </Box>
      )}
    </>
  );
}

export default AiChatDrawer;
