import React, { useEffect, useState } from 'react';
import { Typography, Avatar, Box } from "@mui/material";
import OrgLabel from "./OrgLabel";
import { useSettingsStyles } from "../common/SettingsStyleProvider";
import { useHubConnections } from "../../../utils/HubConnectionsProvider";

function ViewOrganizationDetails({ editedData, formatPhoneNumber, selectedData }) {
    const { font, dividerStyle } = useSettingsStyles();
    const { GetProfile } = useHubConnections();
    const [primaryContactName, setPrimaryContactName] = useState('');
    const [avatarUrl, setAvatarUrl] = useState('');

    //const contactId = selectedData?.primaryContactName || editedData?.primaryContactName || '\u00A0';

    useEffect(() => {
        if(editedData) {
            console.log('1111111111 editedData', editedData);
        }
    }, [editedData]);

    useEffect(() => {
        if(editedData) {
            console.log('111111111 selectedData', selectedData);
        }
    }, [editedData]);


    // useEffect(() => {
    //     console.log('Contact ID changed:', contactId);
    //     if (contactId && contactId !== '\u00A0') {
    //         GetProfile(contactId)
    //             .then(name => {
    //                 console.log('Fetched name:', name);
    //                 setPrimaryContactName(name);
    //             })
    //             .catch(error => {
    //                 console.error('Error fetching profile:', error);
    //                 setPrimaryContactName(''); // Reset name on error
    //             });
    //         setAvatarUrl(`${window._env_.HITCH_API}/api/accimage/${contactId}.png`);
    //     } else {
    //         setPrimaryContactName(''); // Reset if contactId is invalid
    //         setAvatarUrl(''); // Reset avatar URL if contactId is invalid
    //     }
    // }, [contactId, GetProfile]);

    return (
        <>
            <div style={{
                display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '8px', marginLeft: '24px',
                fontFamily: font,
            }}>
                <OrgLabel label='Name' data={selectedData?.name || editedData?.name || '\u00A0'} />
                <OrgLabel label='Description' data={selectedData?.description || editedData?.description || '\u00A0'} />
                {/*<OrgLabel label='Primary Contact' data={primaryContactName} />*/}

                {/* <OrgLabel label='Phone' data={formatPhoneNumber(selectedData?.phone || editedData?.phone) || '\u00A0'} /> */}
            </div>
            <div style={dividerStyle}></div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1" style={{ marginTop: '8px', marginBottom: '16px', fontSize: '18px', fontFamily: font, }}>
                    Address Information
                </Typography>
            </div>
            <div style={{
                display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '8px', marginLeft: '24px',
                fontFamily: font,
            }}>
                <OrgLabel label='Address Line 1' data={selectedData?.billingAddress1 || editedData?.billingAddress1 || '\u00A0'} />
                <OrgLabel label='Address Line 2' data={selectedData?.billingAddress2 || editedData?.billingAddress2 || '\u00A0'} />
                <OrgLabel label='Address Line 3' data={selectedData?.billingAddress3 || editedData?.billingAddress3 || '\u00A0'} />
                <OrgLabel label='City' data={selectedData?.billingCity || editedData?.billingCity || '\u00A0'} />
                <OrgLabel label='State' data={selectedData?.billingState || editedData?.billingState || '\u00A0'} />
                <OrgLabel label='Zip/Postal Code' data={selectedData?.billingZipCode || editedData?.billingZipCode || '\u00A0'} />
                {/* <OrgLabel label='Country' data={selectedData?.country || editedData?.country || '\u00A0'} /> */}
            </div>
        </>
    );
}

export default ViewOrganizationDetails;
