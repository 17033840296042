import { faBug } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@mui/material";

function QbError({ errorMessage, setErrorMessage }) {
    return (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={8} style={{ textAlign: 'center' }}>
                {errorMessage && (
                    <>
                        <FontAwesomeIcon icon={faBug} color="red" onClick={(e) => setErrorMessage('')} /> {errorMessage} 
                    </>
                )}
            </Grid>
        </Grid>
    );
}

export default QbError;