import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import FooterMain from '../footer/FooterMain';

const PrivacyPolicyPage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isExtraWide = useMediaQuery('(min-width:1920px)'); // Custom breakpoint for extra wide screens

     // Determine margin based on device size
     let horizontalMargin = 16;
     if (isMobile) {
         horizontalMargin = 4; // Smaller margin for mobile
     } else if (isTablet) {
         horizontalMargin = 8; // Medium margin for tablet
     } else if (isExtraWide) {
         horizontalMargin = 48; // Larger margin for extra wide screens
     }

    const paragraphStyle = {
        marginBottom: '16px', // Adjust this value as needed for the desired spacing
    };

    return (
        <Box style={{ display: 'flex', flexDirection: 'column',
        backgroundColor: 'white', color: 'black', minHeight: '100vh' }}>
            <Box style={{ flex: 1 }}>
            <AppBar position="sticky" color="default" elevation={0}>
                <Toolbar sx={{ marginTop: 1, marginBottom: 1 }}>
                    <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                        <img src={'/img/hitch.png'} alt="Hitch Logo" style={{ width: '80px', height: '80px', marginRight: '10px' }} />
                        <Typography variant="h6">Privacy Policy</Typography>
                    </Link>
                </Toolbar>
            </AppBar>
            <Box style={{ backgroundColor: 'black', color: 'white', width: '100%', padding: '16px 0', marginBottom: '24px' }}>
                <Typography variant="body1" style={{ textAlign: 'center' }}>
                    <strong>Last Updated: August 14, 2023</strong>
                </Typography>
            </Box>
            <Box
                marginLeft={horizontalMargin}
                marginRight={horizontalMargin}
                marginTop={4}
                marginBottom={4}
                textAlign="left"
            >
                {/* Content */}
                <Typography variant="h5" style={paragraphStyle}>
                    <strong>1. Introduction</strong>
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    Welcome to Hitch Software, LLC ("we," "our," or "us"). We are committed to protecting your privacy and providing transparency about how we collect, use, and share your personal information when you visit our website, hitchsoftware.com ("Website"). This Privacy Policy outlines our practices concerning your data. By accessing or using our Website, you consent to the terms outlined in this Privacy Policy.
                </Typography>

                <Typography variant="h5" style={paragraphStyle}>
                    <strong>2. Information We Collect</strong>
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    We may collect various types of information, including but not limited to:
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    2.1. <strong>Personal Information:</strong> This includes information that can identify you, such as your name, email address, and contact details. We may collect this information when you voluntarily provide it to us through forms on our Website or by contacting us.
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    2.2. <strong>Non-Personal Information:</strong> We may collect non-personal information, such as your browser type, device type, and IP address, to enhance the functionality of our Website and improve your user experience. This information is typically collected through cookies and similar technologies.
                </Typography>

                <Typography variant="h5" style={paragraphStyle}>
                    <strong>3. How We Use Your Information</strong>
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    We may use your information for the following purposes:
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    3.1. To provide and improve our services.
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    3.2. To respond to your inquiries and provide customer support.
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    3.3. To send you important updates and information about our services.
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    3.4. To analyze and monitor the usage of our Website.
                </Typography>

                <Typography variant="h5" style={paragraphStyle}>
                    <strong>4. Disclosure of Your Information</strong>
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    We may share your information with third parties in the following circumstances:
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    4.1. With service providers and business partners who assist us in operating our Website or providing services to you.
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    4.2. In response to a legal request or when required to comply with applicable laws and regulations.
                </Typography>

                <Typography variant="h5" style={paragraphStyle}>
                    <strong>5. Your Choices</strong>
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    You have the right to:
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    5.1. Access and correct your personal information.
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    5.2. Opt out of receiving marketing communications.
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    5.3. Delete your personal information, subject to applicable legal obligations.
                </Typography>

                <Typography variant="h5" style={paragraphStyle}>
                    <strong>6. Security</strong>
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    We use reasonable security measures to protect your information. However, no data transmission or storage can be guaranteed to be 100% secure, so we cannot guarantee the security of your information.
                </Typography>

                <Typography variant="h5" style={paragraphStyle}>
                    <strong>7. Changes to This Privacy Policy</strong>
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date will be revised accordingly. Please review this Privacy Policy periodically.
                </Typography>

                <Typography variant="h5" style={paragraphStyle}>
                    <strong>8. Contact Us</strong>
                </Typography>
                <Typography variant="body1" style={paragraphStyle}>
                    If you have any questions or concerns regarding this Privacy Policy, please{' '}
                    <Link to="/letstalk">contact us via our online form</Link>.
                </Typography>
            </Box>
            </Box>
            <FooterMain />
        </Box>
    );
};

export default PrivacyPolicyPage;
