import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

function MessageLink({ message }) {
    const [payload, setPayload] = React.useState({});
    const [hover, setHover] = React.useState(false);

    // console.log('message', message);

    const cardStyle = {
        maxWidth: 345,
        color: '#fff',
        fontFamily: '"Poppins", sans-serif',
        backgroundColor: '#000000', // Dark grey background
        //border: '1px solid #00EDED', // Normal border
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#00000', // Same background color on hover
            //boxShadow: '0px 0px 12px 2px rgba(255,255,255,0.2)', // Subtle glow effect
            //border: '2px solid #00EDED', // Thicker border on hover
        }
    };

    const iconStyle = {
        marginLeft: 4,
        fontSize: '0.8em',
        verticalAlign: 'middle',
        color: hover ? '#00EDED' : '#2E8B57', // Color changes based on hover state
        transition: 'color 0.2s ease, transform 0.2s ease' // Smooth transition for hover effects
    };


    const tooltipTitle = payload?.NewWindow ? "Open In New Window" : "";

    const cardContent = (
        
<CardActionArea onClick={(e) => clicked()}>
    {payload?.ImageUrl && (
        <CardMedia
            sx={{
                paddingTop: '33.33%', // Reduced to 1/3 size for smaller image
                height: 0, // Ensures that the area is calculated based on the padding
                backgroundSize: 'contain', // Ensures the image fits without cropping
                backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                backgroundPosition: 'center', // Centers the image within the area
            }}
            image={payload?.ImageUrl}
            title={payload?.DisplayText}
        />
    )}
    <CardContent>
        <Typography color="text.secondary" sx={{ color: 'white',
        fontFamily: '"Poppins", sans-serif', }}>
            {payload?.DisplayText}
            {payload?.NewWindow && (
                <FontAwesomeIcon
                    icon={faExternalLinkAlt}
                    style={{
                        ...iconStyle,
                        color: hover ? '#00EDED' : '#00EDED',
                        transform: hover ? 'scale(1.1)' : 'scale(1)'
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                />
            )}
        </Typography>
    </CardContent>
</CardActionArea>

    );

    React.useEffect(() => {
        if (message.content) {
            try {
                let theObject = JSON.parse(message.content);
                setPayload(theObject);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }
    }, [message]);
    

    function clicked() {
        // console.log('clicked', payload);
        if (payload && payload.Url) {
            if (payload.NewWindow) {
                // Open in a new window/tab
                window.open(payload.Url, '_blank');
            } else {
                // Open in the same window
                window.location.href = payload.Url;
            }
        }
    }
    
    if (message.contentType === "Link" || message.contentType === "link") {
        return (
            <Tooltip title={payload?.NewWindow ? "Open In New Window" : ""}>
                <Card
                    sx={cardStyle}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                   
                    {cardContent}
                </Card>
            </Tooltip>
        );
    } else {
        return (<></>)
    }
}

export default MessageLink;