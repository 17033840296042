
function BillingPeriod({billingPeriod, setBillingPeriod}) {
    return (
        <div className="header-row-subscribe">
        <div className="plan-header-subscribe">Choose a plan</div>
        {/*<div className="billing-period-container-subscribe">
          <div className="billing-period-options-subscribe">
            <input
              type="radio"
              id="monthly"
              name="billingPeriod"
              value="Monthly"
              checked={billingPeriod === 'Monthly'}
              onChange={() => setBillingPeriod('Monthly')}
            />
            <label htmlFor="monthly" className="billing-period-label-subscribe">Pay monthly</label>

            <input
              type="radio"
              id="annually"
              name="billingPeriod"
              value="Annually"
              checked={billingPeriod === 'Annually'}
              onChange={() => setBillingPeriod('Annually')}
            />
            <label htmlFor="annually" className="billing-period-label-subscribe">Pay annually</label>
          </div>
        </div>*/}
      </div>
    );
}

export default BillingPeriod;