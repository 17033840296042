import React, { useState, useEffect, useRef } from 'react';
import { Grid, Modal, Box } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ChatBox from './ChatBox';
import LinearColor from "./LinearColor";
import ConversationTabs from './ConversationTabs';
import LeftConversationDrawer from './LeftConversationDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MatrixPhone from './Matrix/MatrixPhone';
import { useHubConnections } from '../../utils/HubConnectionsProvider';
import isValidGUID from '../../utils/IsValidGuid';
import MiniVariantDrawer from '../MiniVariantDrawer/MiniVariantDrawer';
import { useChatConnections } from '../../utils/ChatConnectionProvider';

function Conversation({ noHistory, redirectPath, shouldFocusChatBox, overrideSessionId, maxWidth, overrideHeight, isInChatDrawer, drawerHeightValue, noHeader }) {
    const [newMessage, setNewMessage] = useState('');
    const [tabValue, setTabValue] = useState('history');
    const [drawersOpen, setDrawersOpen] = useState(false);
    const [hitchPhone, setHitchPhone] = useState(false);
    const chatContainerRef = useRef(null);
    const miniDrawerRef = useRef(null);
    const leftDrawerRef = useRef(null);
    const history = useNavigate();
    const { guid } = useParams();
    const [chatSessionId, setChatSessionId] = useState('');
    const [useChatGpt, setUseChatGpt] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [isChatBoxActive, setIsChatBoxActive] = useState(false);
    const [editingMessage, setEditingMessage] = useState(null);
    const [editedMessageText, setEditedMessageText] = useState('');
    const theme = useTheme();
    const themeBackgroundColor = theme.palette.secondary[theme.palette.mode];
    const themeSecondaryTextColor = theme.palette.secondaryText[theme.palette.mode];
    const isTabletMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const chatBoxRef = useRef(null);

    const {
        dashboardConnection,
        setChatConnection,
        jwtToken,
        hitchUser,
        setHitchUser,
        dashStarted,
        started,
        users
    } = useHubConnections();

    const {
        accountId,
        chatBusy,
        chatConnection,
        conversation,
        conversationHistories,
        GetProfile,
        hitchCommand,
        hitchCommandWithResponseHandling,
        isBusy,
        isAuthenticated,
        loading,
        matrixRoutes,
        messages,
        sessionId,
        setMessages,
        setSessionId,
        user,
        setUser,
        isTyping // Access isTyping from context
    } = useChatConnections();

    const handleDrawersToggle = () => {
        if (isTabletMobile || isTablet) {
            setDrawersOpen(!drawersOpen);
            if (!drawersOpen) {
                setTabValue('history'); // Ensure ConversationHistory tab is selected
            }
        }
    };

    const handleTabChangeFromDrawer = (tab) => {
        if (isTabletMobile || isTablet) {
            if (tabValue === tab) {
                setDrawersOpen(false);
            } else {
                setTabValue(tab);
                setDrawersOpen(true);
            }
        } else {
            if (tabValue === tab && drawersOpen) {
                setDrawersOpen(false);
            } else {
                setTabValue(tab);
                setDrawersOpen(true);
            }
        }
    };

    const handleNewChat = () => {
        const newGuid = uuidv4();
        history(`${redirectPath}/${newGuid}`);
        setSessionId(newGuid);
        setTabValue('history');
        if (isTabletMobile || isTablet) {
            setDrawersOpen(false);
        }
    };

    const [chatBoxKey, setChatBoxKey] = useState(0);

    const focusChatBoxInput = () => {
        setIsChatBoxActive(true);
        setChatBoxKey(prevKey => prevKey + 1);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
        setIsChatBoxActive(false);
    };

    const openSidebar = () => {
        setSidebarOpen(true);
        setIsChatBoxActive(false);
    };

    const requestCancel = () => {
        hitchCommand("chat", "thread", "cancel");
    };

    useEffect(() => {
        if (guid && isValidGUID(guid)) {
            setSessionId(guid);
        } else {
            if (!overrideSessionId) {
                const newGuid = uuidv4();
                history(`${redirectPath}/${newGuid}`);
                setSessionId(newGuid);
            }
        }
    }, [guid, overrideSessionId]);

    useEffect(() => {
        if (overrideSessionId) {
            setChatSessionId(overrideSessionId);
            setSessionId(overrideSessionId);
        } else {
            setChatSessionId(guid);
        }
    }, [overrideSessionId]);

    useEffect(() => {
        focusChatBoxInput();
    }, []);

    useEffect(() => {
        if (shouldFocusChatBox) {
            focusChatBoxInput();
        }
    }, [shouldFocusChatBox]);

    useEffect(() => {
        if (!isTabletMobile) {
            setDrawersOpen(true);
        } else {
            setDrawersOpen(false);
        }
        if (noHistory) {
            setDrawersOpen(false);
        }
    }, [isTabletMobile]);

    useEffect(() => {
        if (chatContainerRef.current) {
            const element = chatContainerRef.current;
            element.scrollTop = element.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'h' && event.altKey) {
                setHitchPhone(true);
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                miniDrawerRef.current &&
                leftDrawerRef.current &&
                !miniDrawerRef.current.contains(event.target) &&
                !leftDrawerRef.current.contains(event.target)
            ) {
                if (isTabletMobile || isTablet) {
                    setDrawersOpen(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isTabletMobile, isTablet]);

    function handlePhoneClose() {
        setHitchPhone(false);
    }

    function errorHandler(error) {
        console.error("SignalR Error", error);
    }

    const handleSendMessage = () => {
        if (newMessage.content !== '') {
            hitchCommand('chat', 'conversation', 'message-add', newMessage, () => {
                // Once the message is added, you can handle additional logic here
            });
            const newMessageToAdd = {
                text: newMessage.content,
                content: newMessage.content,
                contentType: 'text',
                type: 'user',
                user: newMessage.user
            };
            setMessages(prevMessages => [...prevMessages, newMessageToAdd]);
            setNewMessage({ content: '', contentType: 'text', user: null });
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const deleteConversation = (conversationId) => {
        hitchCommand('chat', 'conversation', 'delete', { deleteSessionId: conversationId });

    };

    const setName = (conversationName, conversationId) => {
        if (chatConnection) {
            hitchCommand('chat', 'conversation', 'set-name', { 
                theConversationId: conversationId,
                newName: conversationName 
            });
        } else {
            console.error('Chat session is dead yo.');
        }
    };

    const isHelpful = (messageId) => {
        hitchCommand('chat', 'conversation', 'message-helpful', messageId);
    };

    const isNotHelpful = (messageId) => {
        hitchCommand('chat', 'conversation', 'message-not-helpful', messageId);
    };

    const saveEditedMessage = () => {
        if (editingMessage) {
            editingMessage.text = [editedMessageText];
            setEditingMessage(null);
            setEditedMessageText('');
        }
    };

    const LeftConversationDrawerWidth = 275;
    const miniDrawerWidth = 84;
    const totalDrawerWidth = 348;
    const miniDrawerWidthPlusMargin = 72;

    const contentShiftStyle = {
        position: 'relative',
        width: isTabletMobile
            ? '100%'
            : `calc(100% - ${drawersOpen ? totalDrawerWidth : miniDrawerWidth}px)`,
        maxWidth: maxWidth,
        margin: isInChatDrawer
            ? 'auto'
            : !isTabletMobile && drawersOpen
                ? `0 auto 0 ${totalDrawerWidth}px`
                : !isTabletMobile && !drawersOpen
                    ? `0 auto 0 ${miniDrawerWidthPlusMargin}px`
                    : 'auto',
        padding: 0, 
    };

    const supportStyle = {
        backgroundColor: themeBackgroundColor,
        width: `calc(100vw)`, 
        height: `calc(100vh - 84px)`, 
        margin: 0, 
        marginLeft: isMobile ? 'auto' : '-24px',
        padding: 0, 
        display: 'flex',
        flexDirection: 'column',
    };

    return (
        <div style={supportStyle}>
            <Grid container spacing={0} marginTop={0} style={{ maxHeight: overrideHeight, backgroundColor: themeBackgroundColor }}>
                {!noHistory && (
                    <Grid item xs={3}>
                        <LeftConversationDrawer
                            open={drawersOpen}
                            onClose={handleDrawersToggle}
                            setOpen={setDrawersOpen}
                            tabValue={tabValue}
                            setTabValue={setTabValue}
                            conversationHistory={conversationHistories}
                            focusChatBoxInput={focusChatBoxInput}
                            deleteConversation={deleteConversation}
                            redirectPath={redirectPath}
                            selectedChat={guid}
                            setName={setName}
                            miniDrawerOpen={drawersOpen}
                            setMiniDrawerOpen={setDrawersOpen}
                            isTabletMobile={isTabletMobile}
                            drawerRef={leftDrawerRef}
                        />
                    </Grid>
                )}

                <Grid item xs={12} lg={12} style={contentShiftStyle}>
                    <Grid item xs={12} style={{ backgroundColor: themeBackgroundColor, display: 'flex', alignItems: 'center', maxWidth: maxWidth }}>
                        <ConversationTabs
                            user={user}
                            hitchUser={hitchUser}
                            conversation={conversation}
                            useChatGpt={useChatGpt}
                            setUseChatGpt={setUseChatGpt}
                            guid={guid}
                            messages={messages}
                            users={users}
                            chatContainerRef={chatContainerRef}
                            isHelpful={isHelpful}
                            isNotHelpful={isNotHelpful}
                            saveEditedMessage={saveEditedMessage}
                            setEditingMessage={setEditingMessage}
                            setEditedMessageText={setEditedMessageText}
                            setName={setName}
                            editedMessageText={editedMessageText}
                            editingMessage={editingMessage}
                            isTabletMobile={isTabletMobile}
                            isMobile={isMobile}
                            isTablet={isTablet}
                            overrideHeight={overrideHeight}
                            maxWidth={maxWidth}
                            isInChatDrawer={isInChatDrawer}
                            noHeader={noHeader}
                            onOpenLeftDrawer={handleDrawersToggle}
                            onOpenMiniDrawer={handleDrawersToggle}
                            isTyping={isTyping} // Pass isTyping to ConversationTabs
                            setMessages={setMessages}
                            chatBoxRef={chatBoxRef} 
                        />
                    </Grid>
                    <Grid item xs={12} style={{ backgroundColor: themeBackgroundColor, display: 'flex', alignItems: 'center', maxWidth: maxWidth }}>
                        <ChatBox
                            key={chatBoxKey}
                            newMessage={newMessage}
                            handleSendMessage={handleSendMessage}
                            setNewMessage={setNewMessage} 
                            isChatBoxActive={isChatBoxActive}
                            hitchUser={hitchUser}
                            leftConversationDrawerOpen={drawersOpen}
                            isMobile={isTabletMobile}
                            isInChatDrawer={isInChatDrawer}
                            drawerHeight={drawerHeightValue}
                            maxWidth={maxWidth}
                            setMessages={setMessages}
                            ref={chatBoxRef} 
                        />
                        {loading &&
                            <LinearColor
                                requestCancel={requestCancel} />
                        }
                    </Grid>

                </Grid>
                <Modal
                    open={hitchPhone}
                    onClose={handlePhoneClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <MatrixPhone
                        handlePhoneClose={handlePhoneClose}
                        hitchCommand={hitchCommand}
                        hitchPhone={hitchPhone}
                        matrixRoutes={matrixRoutes}
                    />
                </Modal>
            </Grid>
            {(isTabletMobile || isTablet) && !noHistory && (
                <MiniVariantDrawer
                    open={drawersOpen}
                    onClose={handleDrawersToggle}
                    onSelect={handleTabChangeFromDrawer}
                    onNewChat={handleNewChat}
                    drawerOpen={drawersOpen}
                    drawerRef={miniDrawerRef}
                    isTabletMobile={isTabletMobile}
                    isTablet={isTablet}
                    leftDrawerOpen={drawersOpen} // Pass the drawer open state
                    selectedTab={tabValue}
                />
            )}
            {(!isTabletMobile && !isTablet) && !noHistory && (
                <MiniVariantDrawer
                    open={true}
                    onClose={() => { }}
                    onSelect={handleTabChangeFromDrawer}
                    onNewChat={handleNewChat}
                    drawerOpen={true}
                    drawerRef={miniDrawerRef}
                    selectedTab={tabValue}
                    leftDrawerOpen={drawersOpen} // Pass the drawer open state
                />
            )}
        </div>
    );
}

export default Conversation;
