// Sidebar.js
import React from 'react';

const Sidebar = ({ cartItems }) => {
    return (
        <aside className="sidebar">
            <h2>Your Offers</h2>
            <ul>
                {cartItems.map(item => (
                    <li key={item.id}>
                        <span>{item.name}</span>
                        <span>${item.price.toFixed(2)}</span>
                    </li>
                ))}
            </ul>
        </aside>
    );
}

export default Sidebar;
