import React from 'react';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditableField from './EditableField';
import { TreeItem } from '@mui/x-tree-view';

const HitchRoleEditor = ({ roles, menuType, itemLabel, onAddRole, onUpdateRole, onDeleteRole }) => {
    return (
        <TreeItem
            nodeId={`${menuType}-${itemLabel}-roles`}
            label="Roles"
            style={{ fontStyle: 'italic', color: 'gray' }}
        >
            <IconButton size="small" onClick={() => onAddRole(menuType, itemLabel)}>
                <AddIcon fontSize="inherit" />
            </IconButton>
            {roles && roles.length > 0 ? (
                roles.map((role, index) => (
                    <div key={`${menuType}-${itemLabel}-role-${index}`}>
                        <EditableField
                            initialValue={role}
                            onSave={(newValue) => onUpdateRole(menuType, itemLabel, role, newValue)}
                            label="Role"
                        />
                        <IconButton size="small" onClick={() => onDeleteRole(menuType, itemLabel, role)}>
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    </div>
                ))
            ) : (
                <TreeItem nodeId={`${menuType}-${itemLabel}-no-roles`} label="No roles" />
            )}
        </TreeItem>
    );
};

export default HitchRoleEditor;
