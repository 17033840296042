import { useState } from "react";
import ReactFlow, { addEdge, useEdgesState, useNodesState } from "reactflow";

import fNodes from './nodes.json';
import fEdges from './edges.json';
import { useEffect } from "react";
import { useCallback } from "react";
import ColorSelectorNode from './ColorSelectorNode';
import './flow.css';
import HitchComponent from "./HitchComponent";

function ServiceFlow() {
    const initBgColor = '#1A192B';

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [bgColor, setBgColor] = useState(initBgColor);
    const connectionLineStyle = { stroke: '#fff' };
    const snapGrid = [20, 20];
    const proOptions = { hideAttribution: true };

    const onConnect = useCallback(
        (params) =>
            setEdges((eds) => addEdge({ ...params, animated: true, style: { stroke: '#fff' } }, eds)),
        []
    );

    const defaultViewport = { x: 0, y: 0, zoom: 1.5 };

    const nodeTypes = {
        selectorNode: ColorSelectorNode,
        hitch: HitchComponent
    };

    useEffect(() => {
        setNodes(fNodes);
        setEdges(fEdges);
    }, []);
    return (
        <div className="flowDiagram" style={{ height: 800 }}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                proOptions={proOptions}
                nodeTypes={nodeTypes}
                style={{ background: bgColor }}
                connectionLineStyle={connectionLineStyle}
                snapToGrid={true}
                snapGrid={snapGrid}
                defaultViewport={defaultViewport}
                fitView
                attributionPosition="bottom-left"
                hei
            >

            </ReactFlow>
            </div>
    );
}

export default ServiceFlow;