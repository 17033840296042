import React, { useEffect, useState } from 'react';
import { Box, OutlinedInput, InputAdornment, Typography, Grid, styled, Dialog, DialogTitle, DialogContent, Button, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoopIcon from '@mui/icons-material/Loop';
import { DataGrid } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useHubConnections } from '../../../utils/HubConnectionsProvider';
import { useTheme, useMediaQuery } from '@mui/material';
import { Pagination } from '@mui/material';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useSettingsStyles } from '../common/SettingsStyleProvider';
import SettingsMenu from '../SettingsMenu/SettingsMenu';

export const CustomPagination = (props) => {
  const { rowCount, onPageChange, page, rowsPerPage, onPageSizeChange, theme } = props;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '10px', color: theme.palette.gridColumnHeadersText[theme.palette.mode] }}>
        Rows per page:
      </span>
      <select
        value={rowsPerPage}
        onChange={(e) => onPageSizeChange(+e.target.value)}
        style={{
          marginRight: '10px',
          color: theme.palette.gridColumnHeadersText[theme.palette.mode],
          backgroundColor: theme.palette.gridColumnHeaders[theme.palette.mode],
          border: `1px solid ${theme.palette.gridCellBorderColor[theme.palette.mode]}`,
          borderRadius: '4px',
          padding: '5px 10px',
          appearance: 'none',
          '-webkit-appearance': 'none',
          '-moz-appearance': 'none',
        }}
      >
        {[5, 10, 20].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </select>
      {/* Pagination component remains unchanged */}
      <Pagination
        page={page}
        count={Math.ceil(rowCount / rowsPerPage)}
        onChange={onPageChange}
        shape="rounded"
        siblingCount={1}
        boundaryCount={1}
        variant="outlined"
        sx={{
          color: theme.palette.gridColumnHeadersText[theme.palette.mode], // Set text color for arrows and text
          '& .MuiPaginationItem-root': {
            color: theme.palette.gridColumnHeadersText[theme.palette.mode], // Ensures all pagination items match the theme color
          },
          '& .MuiPaginationItem-previousNext': {
            color: theme.palette.gridColumnHeadersText[theme.palette.mode], // Specifically target the color of next and previous arrows
          },
        }}
      />
    </div>
  );
};



const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  fontFamily: '"Poppins", sans-serif', // Apply the Poppins font family
  '& .fixed-background': {
    backgroundColor: theme.palette.fixedGridBackground.default,
    '&:hover': {
      backgroundColor: theme.palette.fixedGridBackground.hover[theme.palette.mode],
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.fixedGridBackground.selected[theme.palette.mode], // Apply selected color from theme
      '&:hover': {
        backgroundColor: theme.palette.fixedGridBackground.selected_hover[theme.palette.mode], // Apply selected hover color from theme
      },
    },

  },
  // Add a horizontal scrollbar for the data grid
  overflowX: 'auto',
  overflowY: 'auto',
  '& .MuiDataGrid-row': {
    backgroundColor: theme.palette.fixedGridBackground.default[theme.palette.mode],
    '&:hover': {
      backgroundColor: theme.palette.fixedGridBackground.hover[theme.palette.mode],
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.fixedGridBackground.selected[theme.palette.mode],
      '&:hover': {
        backgroundColor: theme.palette.fixedGridBackground.selected_hover[theme.palette.mode],
      },
    }
  },
  // Set a minimum width for each column to ensure content is visible
  '& .MuiDataGrid-colCell': {
    minWidth: '150px', // Adjust the minimum width as needed
  },
  /* Add your custom styling for deactivated rows here */
  '& .deactivated-row': {
    fontStyle: 'italic',
    /* You can adjust other styling properties as needed */
  },
  /* Define styles for links */
  '& .role-link, & .action-link': {
    color: theme.palette.link[theme.palette.mode],//'#00EDED',
    textDecoration: 'none', // Remove default underlines
    '&:hover': {
      textDecoration: 'underline', // Underline on hover
    },

  },
  // Style for filter icons

  '& .MuiDataGrid-cell': {
    borderColor: theme.palette.gridCellBorderColor[theme.palette.mode],
    border: '2',
  },
  '& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIconButton': {
    opacity: 1,
    color: theme.palette.link[theme.palette.mode],//'#00EDED',
  },
  '& .MuiDataGrid-iconSeparator': {
    opacity: 1,
    color: theme.palette.gridColumnHeaders[theme.palette.mode]
  },
  '& .MuiDataGrid-footerContainer': {
    backgroundColor: theme.palette.gridColumnHeaders[theme.palette.mode], // Use the same color as the header
    color: theme.palette.gridColumnHeadersText[theme.palette.mode], // Use the same text color as the header
  },

  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.gridColumnHeaders[theme.palette.mode],
    color: theme.palette.gridColumnHeadersText[theme.palette.mode],
    fontSize: 16
  },
  '& .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row': {
    borderBottom: '1px solid #000 !important',
  },
}));

const CustomInput = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  padding: '0px 10px',
  marginBottom: '10px',
  background: theme.palette.secondary[theme.palette.mode],
  color: theme.palette.gridText[theme.palette.mode],
  fontFamily: '"Poppins", sans-serif',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.textboxOutline[theme.palette.mode],
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.textboxOutline.hover[theme.palette.mode],
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.textboxOutline.hover[theme.palette.mode],
    borderWidth: '2px',
  },
}));

function ListOrganizations() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [selectedRowId, setSelectedRowId] = useState(null); // State to store the ID of the selected row
  const themeSecondaryTextColor = theme.palette.secondaryText[theme.palette.mode];
  const themeGridColumnHeadersText = theme.palette.gridColumnHeadersText[theme.palette.mode];
  const themePrimaryBorderColor = theme.palette.primaryBorder[theme.palette.mode]
  const themePrimaryBorderHoverColor = theme.palette.primaryBorder.hover[theme.palette.mode]

  const {
    font,
    themeBackgroundColor,
    themePrimaryTextColor,
    supportStyle,
    boxStyle,
    buttonStyle,
    buttonStyleSecondary,
    dividerStyle,
    headerStyle,
    titleStyle,
    buttonContainerStyle,
    setIsDrawerOpen,
    defaultOutlineStyle,
    activeOutlineStyle,
    errorOutlineStyle,
    initialState,
    containerStyle,
    helperTextStyle,
    fixedWidthStyle
  } = useSettingsStyles();

  const { hitchUser, dashboardConnection, isConnectionReady } = useHubConnections();
  const drawerWidth = 240; // Adjust to match your drawer's width

  //const marginLeft = isDesktop ? drawerWidth : 0;
  //const marginLeft = isDrawerOpen && isDesktop ? drawerWidth : 0;

  const [data, setData] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState({
    // ... initial state
  });

  const [filteredData, setFilteredData] = useState(data);
  const navigate = useNavigate();

  const selectedRow = async (newSelection) => {
    const id = newSelection.rowIds.length > 0 ? newSelection.rowIds[0] : null;
    setSelectedRowId(id);
  };

  const SelectedRowText = styled(Typography)(({ theme }) => ({
    color: themeGridColumnHeadersText,
  }));

  const [pageSize, setPageSize] = useState(5); // Default page size

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  useEffect(() => {
    // Subscribe to events when the component mounts
    const subscribeToEvents = () => {
      if (dashboardConnection && isConnectionReady) {
        // console.log('dashboardConnection', dashboardConnection);
        dashboardConnection.on('AvailableAccounts', (data) => {
          setData(data);
        });

        dashboardConnection.invoke("AccountRequest")
          .then(() => {
          })
          .catch(error => console.error("Error sending message:", error));
      }
    };

    // Unsubscribe from events when the component unmounts
    const unsubscribeFromEvents = () => {
      if (dashboardConnection) {
        dashboardConnection.off('AvailableAccounts');
      }
    };

    subscribeToEvents();

    // The function returned from useEffect will run when the component unmounts
    return unsubscribeFromEvents;
  }, [dashboardConnection, isConnectionReady]); // Re-run this effect if hubConnection changes

  const [filterText, setFilterText] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  const [isFocused, setIsFocused] = useState(false);
  const [isHoveredInput, setIsHoveredInput] = useState(false);
  // Event handlers for input field interactions
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const handleMouseEnter = () => setIsHoveredInput(true);
  const handleMouseLeave = () => setIsHoveredInput(false);

  // Handle clearing the search input
  const handleClearSearch = () => {
    setFilterText('');
  };



  const columns = [
    {
      field: 'isCurrent',
      headerName: '',
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      renderCell: (cellValues) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            {cellValues.row.isCurrent ? (

              <CheckCircleIcon style={{ color: theme.palette.link[theme.palette.mode], marginRight: '8px' }} />

            ) : (
              <Button
                onClick={() => handleSwitch(cellValues.row)}
                variant="outlined"
                style={{
                  width: '80px',
                  borderColor: '#fff',
                  //todo: put back
                  //borderColor: theme.palette.secondaryBorder[theme.palette.mode],
                  backgroundColor: cellValues.row.isCurrent === 'Y' ? theme.palette.link[theme.palette.mode] : theme.palette.secondaryButton[theme.palette.mode],
                  color: theme.palette.secondaryButtonText[theme.palette.mode],
                  fontSize: '0.75rem',
                  height: '24px',
                  padding: '4px 10px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  fontFamily: '"Poppins", sans-serif', // Apply the Poppins font family

                }}
              >
                <LoopIcon fontSize="smaller" /> SWITCH
              </Button>
            )}
          </div>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Organization Name',
      flex: 1,
      renderCell: (cellValues) => (
        <div
          style={{
            color: cellValues.row.isCurrent ? theme.palette.link[theme.palette.mode] : theme.palette.gridText[theme.palette.mode],
            cursor: cellValues.row.isCurrent ? 'pointer' : 'default',
          }}
          onClick={
            cellValues.row.isCurrent
              ? () => navigate('/app/account/manage-organization')
              : undefined
          }
        >
          {cellValues.row.name}
        </div>
      ),
    },
  ];

  function handleSwitch(row) {
    //Drew
    if (dashboardConnection && isConnectionReady) {
      dashboardConnection.invoke("SwitchAccount", row.accountId)
        .then(() => {
        })
        .catch(error => console.error("Error sending message:", error));
    }
  }

  useEffect(() => {
    const currentOrg = data.find((org) => org.isCurrent === 'Y');
    if (currentOrg) {
      setSelectedOrgData(currentOrg);
    }
  }, [data]);

  useEffect(() => {
    const filtered = data.filter((row) =>
      row.name?.toLowerCase().includes(filterText.toLowerCase())
    );
    setFilteredData(filtered);
  }, [data, filterText]);



  // Handle changes to the search input
  const handleSearchChange = (event) => {
    setFilterText(event.target.value);
  };


  // Define styles for the clear button with dynamic theme handling
  const clearButtonStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    backgroundColor: theme.palette.secondaryButton[theme.palette.mode], // Dynamic background color
    border: '#FFFFFF',
    //todo: put back
    //border: `1px solid ${theme.palette.secondaryBorder[theme.palette.mode]}`,
    borderRadius: '100px',
    cursor: 'pointer',
    padding: 0,
    transition: 'all 0.3s', // Ensure smooth transition for hover effect
  };

  // Use a function to determine the hover style based on the theme mode
  const getHoverStyle = (mode) => ({

    backgroundColor: theme.palette.secondaryButton[theme.palette.mode],
    borderColor: '#71EEB8',
    //todo: put back
    //backgroundColor: theme.palette.secondaryButton.hover[theme.palette.mode] , // Dynamic hover color based on mode
    ////'borderColor: theme.palette.secondaryBorder[theme.palette.mode], // Maintain border color
    //border: `1px solid ${theme.palette.secondaryBorder[theme.palette.mode]}`,
  });


  const activeStyle = {
    backgroundColor: '#333333',
    borderColor: '#71EEB8',
  };

  // Define the getRowClassName function to return a fixed class for all rows

  const getRowClassName = (params) => {
    if (params.isSelected) {
      return 'selected-row';
    }
    return '';
  };

  useEffect(() => {
    if (!isMobile) {
      setIsDrawerOpen(true);  // Open the drawer when not in mobile view
    }
  }, [isMobile, setIsDrawerOpen]);
  
  return (
    <Box sx={{ display: 'flex' }}>
      <SettingsMenu hitchUser={hitchUser} isDrawerOpen={drawerOpen} setIsDrawerOpen={setDrawerOpen} />
      <Box sx={{ flexGrow: 1, marginLeft: !isMobile && drawerOpen ? '240px' : '0px' }}>
        {isMobile && !drawerOpen && (
          <IconButton
            onClick={() => setDrawerOpen(true)}
            size="small"
            sx={{
              color: 'white',
              backgroundColor: '#18181B',
              opacity: 0.8,
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
              margin: '10px',
              zIndex: 1000,
              position: 'fixed',
              top: '66px',
              left: '16px',
              '&:hover': {
                backgroundColor: 'rgba(21, 101, 192, 0.8)',
              },
            }}
          >
            <KeyboardDoubleArrowRight />
          </IconButton>
        )}

        <Box sx={supportStyle}>
          <Box style={boxStyle}>

            <Box sx={headerStyle}>
              <Typography variant="h6" sx={titleStyle}>My Organizations</Typography>
            </Box>
            <div style={{ padding: '10px' }}>
              <CustomInput
                value={filterText}
                onChange={handleSearchChange}
                placeholder="Search Organizations..."
                type="text"
                endAdornment={
                  filterText && (
                    <InputAdornment position="end">
                      <button
                        onClick={handleClearSearch}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{
                          ...clearButtonStyle,
                          ...(isHovered ? getHoverStyle() : {}),
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          style={{
                            color: 'white',
                            fontSize: '16px',
                          }}
                        />
                      </button>
                    </InputAdornment>
                  )
                }
              />
            </div>
            <div
              style={{
                marginTop: '8px',
                overflowY: 'hidden',
                height: '800px',
              }}
            >
              {filteredData.length > 0 ? (
                <Box sx={{ 
                  width: '100%', 
                  backgroundColor: theme.palette.fixedGridBackground.default[theme.palette.mode] 
                  }}>
                  <StyledDataGrid
                    rows={filteredData}
                    columns={columns}
                    autoHeight
                    getRowClassName={getRowClassName}
                    rowHeight={36}
                    onSelectionChange={selectedRow} // Pass the selectedRow function to onSelectionChange
                    localeText={{
                      footerRowSelected: (count) => (
                        <SelectedRowText>{`${count} row${count === 1 ? '' : 's'} selected`}</SelectedRowText>
                      ) // Use custom message component for selected row text
                    }}
                    disableSelectionOnClick // Disables selection when clicking on a cell
                    onRowClick={(params, event) => {
                      // Add logic here if you want to do something when a row is clicked
                      // console.log("Row clicked: ", params.id);
                    }}
                    sx={{
                      '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none', // Removes cell focus outline
                      },
                      '& .MuiDataGrid-cell:focus': {
                        outline: 'none', // Additional removal of focus styles if needed
                      },
                      '& .selected-row': {
                        color: themeGridColumnHeadersText, // Set text color for selected row
                      },
                      // Include other style overrides here
                    }}
                    getRowId={(row) => row.accountId}
                    onCellClick={(params, event) => {
                      if (params.field !== 'isCurrent') {
                        event.defaultMuiPrevented = true;
                      }
                    }}
                  />
                </Box>
              ) : (
                <Typography>No organizations found.</Typography>
              )}
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default ListOrganizations;
