import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function AnalyzingConnection({ onNext }) {
  const [isAnalyzing, setIsAnalyzing] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnalyzing(false); // Stop analyzing and show the checkmark
      setTimeout(() => {
        onNext(); // Move to the next step after showing the checkmark
      }, 1000); // Show the checkmark for 1 second
    }, 2000); // Adjust the delay for analyzing

    return () => clearTimeout(timer);
  }, [onNext]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      {isAnalyzing ? (
        <>
          <CircularProgress style={{ color: 'blue', fontSize: '48px' }} />
          <h2>Analyzing Connection...</h2>
        </>
      ) : (
        <>
          <CheckCircleIcon style={{ color: 'green', fontSize: '48px' }} />
          <h2>Connection Analyzed Successfully</h2>
        </>
      )}
    </div>
  );
}

export default AnalyzingConnection;
