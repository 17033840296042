function updateRuleWithDiagramElements(diagramData) {
    const { nodes } = diagramData;
  
    // Start with a fresh structure for conditions and actions
    let updatedConditions = [];
    let updatedActions = [];
  
    // Iterate over each node to rebuild conditions and actions
    nodes.forEach(node => {
      switch (node.type) {
        case 'condition':
          updatedConditions.push({
            ...node.data, // Assuming node.data contains all necessary condition properties
            // Include any additional transformation or assignment here if necessary
          });
          break;
        case 'action':
          updatedActions.push({
            ...node.data, // Assuming node.data contains all necessary action properties
            // Include any additional transformation or assignment here if necessary
          });
          break;
        // Handle other types if necessary
      }
    });
  
    // Construct the updated rule object with the rebuilt conditions and actions
    const updatedRule = {
      ...diagramData.rule, // Preserve existing rule properties
      condition: updatedConditions,
      ruleAction: updatedActions,
    };
  
    return updatedRule;
}

export default updateRuleWithDiagramElements;
