import React, { useState, useCallback, useEffect } from 'react';
import ReactFlow, {
    addEdge,
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    ReactFlowProvider,
    useReactFlow,
    Panel,
} from 'reactflow';
import dagre from 'dagre';
import { nodes as initialNodes, edges as initialEdges } from './initial-elements';
import { useNavigate } from 'react-router-dom';
import BotDetailModal from './BotDetailModal'; // Adjust the path as needed
import 'reactflow/dist/style.css';
import './BotOrgChart.css';
import { useHubConnections } from '../../utils/HubConnectionsProvider';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import {
    nodeTypes,
    minimapStyle,
    nodeWidth,
    nodeHeight
} from './BotOrgChartStyles';

const flowKey = 'example-flow';
const onInit = (reactFlowInstance) => console.log('flow loaded:', reactFlowInstance);
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
    const isHorizontal = true;
    dagreGraph.setGraph({ rankdir: direction, marginx: 10, marginy: 10 }); // Adjust margins for a more compact layout

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? 'left' : 'top';
        node.sourcePosition = isHorizontal ? 'right' : 'bottom';

        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        };

        return node;
    });

    return { nodes, edges };
};

function BotOrgChartWrapper({ user, isAuthenticated }) {
    return (
        <ReactFlowProvider>
            <BotOrgChart user={user} isAuthenticated={isAuthenticated} />
        </ReactFlowProvider>
    );
}

function BotOrgChart({ user, isAuthenticated }) {
    const { accountId, dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady } = useHubConnections();
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [bots, setBots] = useState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [showServices, setShowServices] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rfInstance, setRfInstance] = useState(null);
    const { setViewport } = useReactFlow();
    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
    const [botId, setBotId] = useState(null);

    function callForMenus() {
        if (dashboardConnection) {
            hitchDashCommand('chat', 'bot', 'list', {});
        }
    }

    // Create new bot function
    const createNewBot = useCallback(() => {
        if (dashboardConnection && isConnectionReady) {
            hitchDashCommand('chat', 'bot', 'create', { name: 'New Bot', description: 'Newly created bot' }, (newBotData) => {
                const newBotNode = {
                    id: newBotData.id,
                    type: 'hitchbot',
                    position: { x: 0, y: 0 }, // Initial position
                    data: {
                        label: newBotData.name,
                        setBotId: setBotId,
                        deleteBot: deleteBot,
                        createNewBot: createNewBot
                    }
                };
                setNodes((nds) => [...nds, newBotNode]); // Add new bot node
                setBotId(newBotData.id); // Set the newly created bot as selected
            });
        }
    }, [dashboardConnection, isConnectionReady, setNodes, setBotId]);

    // Delete bot function with confirmation prompt
    const deleteBot = useCallback((id) => {
        if (window.confirm("Are you sure you want to delete this bot?")) {
            setNodes((nds) => nds.filter((node) => node.id !== id)); // Remove from nodes
            setEdges((eds) => eds.filter((edge) => edge.source !== id && edge.target !== id)); // Remove related edges
            hitchDashCommand('chat', 'bot', 'delete', { id }, () => {
                console.log(`Bot with id ${id} deleted.`);
                setBotId(null); // Clear selected bot
            });
        }
    }, [setNodes, setEdges, hitchDashCommand, setBotId]);

    useEffect(() => {
        if (bots.nodes) {
            const filteredNodes = showServices
                ? bots.nodes
                : bots.nodes.filter(node => node.type !== 'connection');
            const layoutedElements = getLayoutedElements(filteredNodes, bots.edges);
            setNodes(filteredNodes);
            setEdges(layoutedElements.edges);
        }
    }, [showServices, bots]);

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on("cc7195ed-aaf0-477a-ab0c-51f2c86ec980", (data) => {
                const updatedData = data.nodes
                    .filter(node => !["Chat", "User", "OpenAi", "Account", "Document"].includes(node.data.label))
                    .map(node => ({
                        ...node,
                        data: {
                            ...node.data,
                            setBotId: setBotId,
                            deleteBot: deleteBot,
                            createNewBot: createNewBot
                        }
                    }));

                const updatedEdges = data.edges.map(edge => ({
                    ...edge,
                    type: 'step'
                }));

                const updatedNodes = { ...data, nodes: updatedData, edges: updatedEdges };
                setBots(updatedNodes);
            });
            callForMenus();
        }

        return () => {
            if (dashboardConnection) {
                dashboardConnection.off("cc7195ed-aaf0-477a-ab0c-51f2c86ec980");
            }
        };
    }, [dashboardConnection, isConnectionReady]);

    useEffect(() => {
        if (bots) {
            if (bots.nodes) {
                const nodesWithSetBotId = bots.nodes.map(node => ({
                    ...node,
                    data: {
                        ...node.data,
                        setBotId: setBotId,
                        deleteBot: deleteBot,
                        createNewBot: createNewBot
                    }
                }));

                let layoutedElements = getLayoutedElements(nodesWithSetBotId, bots.edges);
                setNodes(layoutedElements.nodes);
                setEdges(layoutedElements.edges);
            }
        }
    }, [bots, setBotId]);

    const navigate = useNavigate();
    const onSave = useCallback(() => {
        if (rfInstance) {
            const flow = rfInstance.toObject();
            localStorage.setItem(flowKey, JSON.stringify(flow));
        }
    }, [rfInstance]);

    const onRestore = useCallback(() => {
        const restoreFlow = async () => {
            const flow = JSON.parse(localStorage.getItem(flowKey));

            if (flow) {
                const { x = 0, y = 0, zoom = 1 } = flow.viewport;
                setNodes(flow.nodes || []);
                setEdges(flow.edges || []);
                setViewport({ x, y, zoom });
            }
        };

        restoreFlow();
    }, [setNodes, setViewport]);

    const proOptions = { hideAttribution: true };

    const handleClick = () => {
        navigate(`/app/bots/1efce345-e209-4aa3-b439-c29588b442f9`);
    };

    const handleCloseModal = () => {
        setBotId(null);
    };

    return (
        <div className='drewOrgChart'>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                proOptions={proOptions}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onInit={setRfInstance}
                attributionPosition="top-right"
                className='drewOrgChart'
                onNodeDoubleClick={handleClick}
                nodeTypes={nodeTypes}
            >
                <Panel position="top-right">
                    <button onClick={onSave}>save</button>
                    <button onClick={onRestore}>restore</button>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={showServices} onChange={() => setShowServices(!showServices)} />}
                            label="Services"
                            sx={{ color: '#004444' }}
                        />
                    </FormGroup>
                </Panel>
                <MiniMap style={minimapStyle} zoomable pannable />
                <Controls />
                <Background color="#aaa" gap={16} />
            </ReactFlow>
            <BotDetailModal botId={botId}
                handleCloseModal={handleCloseModal} />
        </div>
    );
}

export default () => (
    <ReactFlowProvider>
        <BotOrgChartWrapper />
    </ReactFlowProvider>
);
