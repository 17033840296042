// MainContent.js
import React from 'react';
import DemoVideo from './content/DemoVideo';
import BillDetails from './content/BillDetails';
import DefaultContent from './content/DefaultContent';

const MainContent = ({ content }) => {
    if (!content) {
        return <DefaultContent />;
    }

    switch(content.type) {
        case 'video':
            return <DemoVideo url={content.payload.url} />;
        case 'billDetails':
            return <BillDetails data={content.payload} />;
        // Add more cases as needed
        default:
            return <DefaultContent />;
    }
}

export default MainContent;
