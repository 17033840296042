import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHubConnections } from "../../../utils/HubConnectionsProvider";

function VectorSourceSelect({ selectedVectorStore, setSelectedVectorStore }) {
    const { dashboardConnection, hitchDashCommand, isConnectionReady } = useHubConnections();
    const [open, setOpen] = useState(false);
    const [newVectorStoreName, setNewVectorStoreName] = useState('');
    const [vectorStores, setVectorStores] = useState([]);
    const {guid} = useParams();

    useEffect(() => {
        if (dashboardConnection && isConnectionReady && guid) {
            dashboardConnection.on("9a60e99d-2ba1-4bd1-9365-1ee82597b483", setVectorStoreList);
            // console.log('-----------> firing <-----------');
            hitchDashCommand('openai', 'vector-store', 'list', { 'limit': 20 }, () => {});
        }

        return () => {
            if (dashboardConnection) {
                dashboardConnection.off("9a60e99d-2ba1-4bd1-9365-1ee82597b483", setVectorStoreList);
            }
        };
            
        }, [dashboardConnection, isConnectionReady, guid, hitchDashCommand]);

    const handleOpenNewVectorStoreModal = () => {
        setOpen(true);
    };

    const handleCloseNewVectorStoreModal = () => {
        setOpen(false);
        setNewVectorStoreName('');
    };

    const handleVectorStoreChange = (event) => {
        // console.log('event', event);
        let value = event.target.value;
        let name = vectorStores.find(a => a.id === value).name;
        setSelectedVectorStore(value, name);
    };

    const handleCreateNewVectorStore = () => {
        // console.log("Create new vector store:", newVectorStoreName);
        // Implement the logic to create a new vector store on Open AI
        handleCloseNewVectorStoreModal();
    };

    function setVectorStoreList(inbound) {
        // console.log(inbound);
        const stores = inbound.data.map(store => ({
            ...store,
            name: store.name || 'Unnamed Vector Store' // Provide a default name if name is null
        }));
        setVectorStores(stores);
    }

    useEffect(() => {
        // console.log('vectorStores', vectorStores);
    }, [vectorStores]);

    return (
        <Box mt={2}>
            <FormControl fullWidth>
                <FormLabel>Vector Store</FormLabel>
                <Select
                    value={selectedVectorStore}
                    onChange={handleVectorStoreChange}
                >
                    {vectorStores.map(store => (
                        <MenuItem key={store.id} value={store.id}>{store.name} ({store.id})</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button variant="contained" startIcon={<FontAwesomeIcon icon={faPlus} />} onClick={handleOpenNewVectorStoreModal}>
                Add Vector Store
            </Button>
            <Dialog open={open} onClose={handleCloseNewVectorStoreModal}>
                <DialogTitle>Add New Vector Store</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the name for the new vector store.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Vector Store Name"
                        type="text"
                        fullWidth
                        value={newVectorStoreName}
                        onChange={(e) => setNewVectorStoreName(e.target)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNewVectorStoreModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCreateNewVectorStore} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default VectorSourceSelect;
