import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';

const SettingsStyleContext = createContext();

export const SettingsStyleProvider = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(!isMobile);  // Moved state here
  const isDesktop = !isMobile;
  const drawerWidth = 240; // Adjust to match your drawer's width
  const marginLeft = isDrawerOpen && isDesktop ? drawerWidth : 0;
  const font = '"Poppins", sans-serif';
  const themeSecondaryTextColor = theme.palette.secondaryText[theme.palette.mode];
  const themePrimaryButtonColor = theme.palette.primaryButton[theme.palette.mode];
  const themePrimaryButtonHoverColor = theme.palette.primaryButton.hover[theme.palette.mode];
  const themePrimaryButtonTextColor = theme.palette.primaryButtonText[theme.palette.mode];
  const themeSecondaryButtonColor = theme.palette.secondaryButton[theme.palette.mode];
  const themeSecondaryButtonTextColor = theme.palette.secondaryButtonText[theme.palette.mode];
  const themeSecondaryButtonBorderColor = theme.palette.secondaryButtonBorder[theme.palette.mode];
  const themeSecondaryDividerColor = theme.palette.secondaryDivider[theme.palette.mode];
  const themeBackgroundColor = theme.palette.secondary[theme.palette.mode];
  const themePrimaryTextColor = theme.palette.primaryText[theme.palette.mode];
  const supportStyle = {
    backgroundColor: themeBackgroundColor,
    color: themePrimaryTextColor,
    width: `calc(100vw - ${marginLeft}px)`,
    height: 'calc(100vh - 48px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'fixed',
    top: 0,
    left: marginLeft,
    boxSizing: 'border-box',
    overflowY: 'auto', // Enable vertical scrolling
    overflowX: 'hidden', // Disable horizontal scrolling
  };

  const boxStyle = {
    backgroundColor: themeBackgroundColor,
    color: themePrimaryTextColor,
    maxWidth: '1000px',
    width: '100%', // Box width grows with screen size
    padding: '20px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    fontSize: '14px',
    marginTop: isDesktop ? '96px' : '72px', // 24px for desktop, -10px for mobile/tablet
    marginRight: isDesktop ? '32px' : '8px', // 8px for desktop, 32px for mobile/tablet
    marginLeft: isDesktop ? '32px' : '8px', // 8px for desktop, 32px for mobile/tablet
  };

  const buttonStyle = {
    backgroundColor: themePrimaryButtonColor,
    color: themePrimaryButtonTextColor,
    height: '28px',
    padding: '4px 10px',
    fontSize: '0.80rem',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontFamily: font,
    '&:hover': {
      backgroundColor: themePrimaryButtonHoverColor ,
      color: themePrimaryButtonTextColor, 
    },
  };
  

  const buttonStyleSecondary = {
    backgroundColor: themeSecondaryButtonColor,
    borderColor: themeSecondaryButtonBorderColor,
    color: themeSecondaryButtonTextColor,
    height: '28px',
    padding: '4px 10px',
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontFamily: font,
    '&:hover': {
      backgroundColor: themeSecondaryButtonColor,
      color: themeSecondaryButtonTextColor,
    },
  };
  

  const dividerStyle = {
    borderTop: `1px solid ${themeSecondaryDividerColor}`,
    margin: '16px 8px 16px 8px',
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start', // Align items to the start of the cross axis (top in a row layout)
    marginLeft: isDesktop ? '8px' : '40px', // 8px for desktop, 32px for mobile/tablet
    marginTop: isDesktop ? '0px' : '-10px', // 24px for desktop, -10px for mobile/tablet
    marginBottom: '8px',
    fontFamily: font,
  };

  const listStyle = {
    paddingInlineStart: '20px', // Adjusts the padding to bring bullets closer to the text
    color: '#F0F0F0',
    textAlign: 'left',
    marginLeft: '14%', // Adjusts the margin to bring the list closer to the center
    marginRight: '10%', // Optionally ensures the list does not stretch too far on the right
    fontFamily: '"Poppins", sans-serif',
};

  const titleStyle = {
    textTransform: 'uppercase',
    fontSize: '16px',
    marginBottom: '8px',
    fontFamily: font,
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-end', // Align the buttons to the right
    gap: '8px', // Space between buttons
    
    marginBottom: '16px',
  };

  const greetingContainerStyle = {
    marginTop: '20px',
    textAlign: 'center',
  };

  const themeTextboxOutlineColor = theme.palette.textboxOutline[theme.palette.mode];
  const themeTextboxOutlineHoverColor = theme.palette.textboxOutline.hover[theme.palette.mode];
  const themeTextboxOutlineErrorColor = theme.palette.textboxOutline.error[theme.palette.mode];
  const themeLinkColor = theme.palette.link[theme.palette.mode];

  const defaultOutlineStyle = { borderColor: themeTextboxOutlineColor };
  const activeOutlineStyle = { borderColor: themeTextboxOutlineHoverColor };
  const errorOutlineStyle = { borderColor: themeTextboxOutlineErrorColor };

  const initialState = {
    
    firstName: {
      outlineStyle: defaultOutlineStyle,
      outlineStyleActive: activeOutlineStyle,
      error: '',
    },
    lastName: {
      outlineStyle: defaultOutlineStyle,
      outlineStyleActive: activeOutlineStyle,
      error: '',
    },
    defaultPhoneNumber: {
      outlineStyle: defaultOutlineStyle,
      outlineStyleActive: activeOutlineStyle,
      error: '',
    },
    name: {
      outlineStyle: defaultOutlineStyle,
      outlineStyleActive: activeOutlineStyle,
      error: '',
    },
    description: {
      outlineStyle: defaultOutlineStyle,
      outlineStyleActive: activeOutlineStyle,
      error: '',
    },
    primaryContactName: {
      outlineStyle: defaultOutlineStyle,
      outlineStyleActive: activeOutlineStyle,
      error: '',
    },
    phone: {
      outlineStyle: defaultOutlineStyle,
      outlineStyleActive: activeOutlineStyle,
      error: '',
    },
    billingAddress1: {
      outlineStyle: defaultOutlineStyle,
      outlineStyleActive: activeOutlineStyle,
      error: '',
    },
    billingAddress2: {
      outlineStyle: defaultOutlineStyle,
      outlineStyleActive: activeOutlineStyle,
      error: '',
    },
    billingAddress3: {
      outlineStyle: defaultOutlineStyle,
      outlineStyleActive: activeOutlineStyle,
      error: '',
    },
    billingCity: {
      outlineStyle: defaultOutlineStyle,
      outlineStyleActive: activeOutlineStyle,
      error: '',
    },
    billingState: {
      outlineStyle: defaultOutlineStyle,
      outlineStyleActive: activeOutlineStyle,
      error: '',
    },
    billingZipCode: {
      outlineStyle: defaultOutlineStyle,
      outlineStyleActive: activeOutlineStyle,
      error: '',
    },
  };

  const linkStyle = {
    color: themeLinkColor,
    cursor: 'pointer',
    textDecoration: 'none',
    marginLeft: '8px',
    fontFamily: font,
    fontWeight: '600', // This makes the text bold
  };

   // Container style for centering
   const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Align items in the center horizontally
    justifyContent: 'space-between', // Align items in the center vertically
    textAlign: 'center',
    marginTop: '0px',
  };

  // Style for helper text
  const helperTextStyle = {
    fontSize: '0.7rem', // Adjust the font size as needed
    lineHeight: 'normal', // Adjust the line height as needed
    fontFamily: font,
  };

  const fixedWidthStyle = {
    width: '350px', // Adjust the width as needed
  };


  const value = {
    theme,
    font,
    themeBackgroundColor,
    themePrimaryTextColor,
    supportStyle,
    boxStyle,
    buttonStyle,
    buttonStyleSecondary,
    dividerStyle,
    headerStyle,
    titleStyle,
    buttonContainerStyle,
    themeSecondaryTextColor,
    themePrimaryButtonColor,
    themePrimaryButtonTextColor,
    themeSecondaryButtonColor,
    themeSecondaryButtonTextColor,
    themeSecondaryButtonBorderColor,
    themeSecondaryDividerColor,
    isMobile,
    marginLeft,
    isDesktop,
    drawerWidth,
    isDrawerOpen,  // Provide the state to context
    setIsDrawerOpen,  // Provide the state setter to context
    themeTextboxOutlineColor,
    themeTextboxOutlineHoverColor,
    themeTextboxOutlineErrorColor,
    themeLinkColor,
    defaultOutlineStyle,
    activeOutlineStyle,
    errorOutlineStyle,
    initialState,
    linkStyle,
    containerStyle,
    helperTextStyle,
    fixedWidthStyle,
    greetingContainerStyle,
    listStyle
  };

  return (
    <SettingsStyleContext.Provider value={value}>
      {children}
    </SettingsStyleContext.Provider>
  );
};

export const useSettingsStyles = () => useContext(SettingsStyleContext);
