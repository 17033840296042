import { Alert, AlertTitle } from "@mui/material";


function MessageErrorV2(message) {
    if(message.message.contentType === "Error") {
    return (
            <Alert severity="error">
                <AlertTitle>Hitch GPT Error</AlertTitle>
                {message.messagecontent}
            </Alert>
    )
    } else {
        return (<></>)
    }
}

export default MessageErrorV2;