import React, { useState, useEffect, useRef } from 'react';
import { DialogActions, Button, TextField, Checkbox, FormControlLabel, FormGroup, Alert } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import './RegistrationWizard.css';
import { useNavigate } from 'react-router-dom';
import CancelPrompt from './CancelPrompt'; // Adjust the path as per your directory structure
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHubConnections } from '../utils/HubConnectionsProvider';

const darkTheme = createTheme({ palette: { mode: 'dark' } });

function AddUser({
  firstName, setFirstName,
  lastName, setLastName,
  phoneNumber, setPhoneNumber,
  organizationName, setOrganizationName,
  termsAccepted, setTermsAccepted,
  onNext
}) {
  
  const {
    dashboardConnection,
    hitchDashCommand,
    hitchUser,
    account
  } = useHubConnections();

  const [showCancelPrompt, setShowCancelPrompt] = useState(false);
  const firstNameRef = useRef(null);
  const [isFirstNameActive, setisFirstNameActive] = useState(true);
  const [email, setEmail] = useState('');
  const [firstNameOutlineStyle, setFirstNameOutlineStyle] = useState({
    borderColor: 'white', // Default white outline color
  });

  useEffect(() => {
    console.log('email', email);
  }, [email]);

  useEffect(() => {
    console.log('firstName', firstName);
  }, [firstName]);

  useEffect(() => {
    console.log('lastName', lastName);
  }, [lastName]);

  useEffect(() => {
    console.log('phoneNumber', phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    if(hitchUser) {
      console.log('hitchUser', hitchUser);
      setEmail(hitchUser.email ?? '');
      setFirstName(hitchUser.firstName ?? '');
      setLastName(hitchUser.lastName ?? '');
      setPhoneNumber(hitchUser.defaultPhoneNumber ?? '');
    }
  }, [hitchUser]);

  useEffect(() => {
    if(account) {
      setOrganizationName(account.name ?? '');
    }
  }, [account]);

  const [firstNameOutlineStyleActive, setFirstNameOutlineStyleActive] = useState({
    borderColor: '#00EDED', // Default white outline color
  });

  const [firstNameError, setFirstNameError] = useState('');

  const [lastNameOutlineStyle, setLastNameOutlineStyle] = useState({
    borderColor: 'white', // Default white outline color
  });

  const [lastNameOutlineStyleActive, setLastNameOutlineStyleActive] = useState({
    borderColor: '#00EDED', // Default white outline color
  });

  const [lastNameError, setLastNameError] = useState('');

  const [phoneNumberOutlineStyle, setPhoneNumberOutlineStyle] = useState({
    borderColor: 'white', // Default white outline color
  });

  const [phoneNumberOutlineStyleActive, setPhoneNumberOutlineStyleActive] = useState({
    borderColor: '#00EDED', // Default white outline color
  });

  const [phoneNumberError, setPhoneNumberError] = useState('');

  const [organizationNameOutlineStyle, setOrganizationNameOutlineStyle] = useState({
    borderColor: 'white', // Default white outline color
  });

  const [organizationNameOutlineStyleActive, setOrganizationNameOutlineStyleActive] = useState({
    borderColor: '#00EDED', // Default white outline color
  });

  const [organizationNameError, setOrganizationNameError] = useState('');

  const [termsError, setTermsError] = useState('');

  // Define the base styles for the buttons
  const baseButtonStyle = {
    borderRadius: '24px',
    height: '36px',
    padding: '4px 10px',
    fontSize: '0.75rem',
    fontFamily: 'Poppins, sans-serif',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    width: '144px',
    marginTop: '36px',
    marginBottom: '-8px',
  };

  // Define styles for the primary button
  const primaryButtonStyle = {
    ...baseButtonStyle,
    backgroundColor: '#00EDED',
    color: 'black',
    cursor: 'pointer',
  };

  // Define styles for the secondary button
  const secondaryButtonStyle = {
    ...baseButtonStyle,
    backgroundColor: '#000',
    borderColor: 'white',
    color: 'white',
  };

  // Container style for centering
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Align items in the center horizontally
    justifyContent: 'center', // Align items in the center vertically
    textAlign: 'center',
    marginTop: '0px',
    // border: '3px solid cyan'
  };

  const subtitleContainerStyle = {
    textAlign: 'center',
    width: '100%',
    display: 'block',
    marginBottom: '16px',
  };

  const subtitleStyle = {
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins, sans-serif',
    marginBottom: '16px',
    color: 'white',
    marginLeft: '0px',
  };

  // Adjusted InputProps style
  const inputPropsStyle = {
    color: 'white',
    fontSize: '16px',
    width: '300px',
    fontFamily: 'Poppins, sans-serif',
  };

  // Style for helper text
  const helperTextStyle = {
    fontSize: '0.50rem', // Adjust the font size as needed
    lineHeight: 'normal', // Adjust the line height as needed
    fontFamily: 'Poppins, sans-serif',
  };

  useEffect(() => {
    if (isFirstNameActive) {
      firstNameRef.current?.focus();
    }
  }, [isFirstNameActive]);

  const validateName = (name) => {
    const trimmedName = name.trim(); // Trim leading and trailing spaces
    const nameRegex = /^[a-zA-Z]+(?:[-' ][a-zA-Z]+)*$/;
    return nameRegex.test(trimmedName);
  };

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);

    if (value.trim() === '' || !validateName(value)) {
      setFirstNameError('Please enter a valid first name.');
      setFirstNameOutlineStyle({ borderColor: 'red' });
      setFirstNameOutlineStyleActive({ borderColor: 'red' });
    } else {
      setFirstNameError('');
      setFirstNameOutlineStyle({ borderColor: 'white' });
      setFirstNameOutlineStyleActive({ borderColor: '#00EDED' });
    }
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setLastName(value);

    if (value.trim() === '' || !validateName(value)) {
      setLastNameError('Please enter a valid last name.');
      setLastNameOutlineStyle({ borderColor: 'red' });
      setLastNameOutlineStyleActive({ borderColor: 'red' });
    } else {
      setLastNameError('');
      setLastNameOutlineStyle({ borderColor: 'white' });
      setLastNameOutlineStyleActive({ borderColor: '#00EDED' });
    }
  };

  function formatPhoneNumber(value) {
    const cleaned = ('' + value).replace(/\D/g, '');
    let formattedNumber = '';

    if (cleaned.length > 3) {
      formattedNumber = `(${cleaned.substring(0, 3)})`;
    } else if (cleaned.length > 0) {
      formattedNumber = `(${cleaned}`;
    }

    if (cleaned.length > 3 && cleaned.length <= 6) {
      formattedNumber += ` ${cleaned.substring(3)}`;
    } else if (cleaned.length > 6) {
      formattedNumber += ` ${cleaned.substring(3, 6)}-${cleaned.substring(6, 10)}`;
    }

    return formattedNumber;
  }

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const cleanedValue = value.replace(/\D/g, ''); // Remove non-digit characters

    if (cleanedValue.length <= 10) {
      setPhoneNumber(formatPhoneNumber(cleanedValue));
    }
  };

  const handlePhoneNumberBlur = () => {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, ''); // Remove non-digit characters

    if (cleanedPhoneNumber.length !== 10) {
      setPhoneNumberError('Phone must be 10 digits.');
      setPhoneNumberOutlineStyle({ borderColor: 'red' });
      setPhoneNumberOutlineStyleActive({ borderColor: 'red' });
    } else {
      setPhoneNumberError('');
      setPhoneNumberOutlineStyle({ borderColor: 'white' });
      setPhoneNumberOutlineStyleActive({ borderColor: '#00EDED' });
    }
  };

  const handleOrganizationNameChange = (e) => {
    const value = e.target.value;
    setOrganizationName(value);

    if (value.trim() === '') {
      setOrganizationNameError('Please enter an organization name.');
      setOrganizationNameOutlineStyle({ borderColor: 'red' });
      setOrganizationNameOutlineStyleActive({ borderColor: 'red' });
    } else {
      setOrganizationNameError('');
      setOrganizationNameOutlineStyle({ borderColor: 'white' });
      setOrganizationNameOutlineStyleActive({ borderColor: '#00EDED' });
    }
  };

  const handleCancel = () => {
    setShowCancelPrompt(true); // Show the cancel prompt
  };

  const handleCloseCancelPrompt = () => {
    setShowCancelPrompt(false); // Hide the prompt without doing anything
  };

  const handleConfirmCancel = () => {
    setShowCancelPrompt(false); // Hide the prompt and perform cancellation actions
    // Navigate to the desired URL or perform other actions
    window.location.href = '/';
  };

  const handleNextClick = () => {
    let hasError = false;

    console.log("Next button clicked");

    // Validate first name
    if (!firstName.trim() || !validateName(firstName)) {
      setFirstNameError('Please enter a valid first name.');
      setFirstNameOutlineStyle({ borderColor: 'red' });
      setFirstNameOutlineStyleActive({ borderColor: 'red' });
      hasError = true;
    } else {
      setFirstNameError('');
      setFirstNameOutlineStyle({ borderColor: 'white' });
      setFirstNameOutlineStyleActive({ borderColor: '#00EDED' });
    }

    // Validate last name
    if (!lastName.trim() || !validateName(lastName)) {
      setLastNameError('Please enter a valid last name.');
      setLastNameOutlineStyle({ borderColor: 'red' });
      setLastNameOutlineStyleActive({ borderColor: 'red' });
      hasError = true;
    } else {
      setLastNameError('');
      setLastNameOutlineStyle({ borderColor: 'white' });
      setLastNameOutlineStyleActive({ borderColor: '#00EDED' });
    }

    // Validate phone number
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, ''); // Remove non-digit characters
    if (cleanedPhoneNumber.length !== 10) {
      setPhoneNumberError('Phone must be 10 digits.');
      setPhoneNumberOutlineStyle({ borderColor: 'red' });
      setPhoneNumberOutlineStyleActive({ borderColor: 'red' });
      hasError = true;
    } else {
      setPhoneNumberError('');
      setPhoneNumberOutlineStyle({ borderColor: 'white' });
      setPhoneNumberOutlineStyleActive({ borderColor: '#00EDED' });
    }

    // Validate organization name
    if (!organizationName.trim()) {
      setOrganizationNameError('Please enter an organization name.');
      setOrganizationNameOutlineStyle({ borderColor: 'red' });
      setOrganizationNameOutlineStyleActive({ borderColor: 'red' });
      hasError = true;
    } else {
      setOrganizationNameError('');
      setOrganizationNameOutlineStyle({ borderColor: 'white' });
      setOrganizationNameOutlineStyleActive({ borderColor: '#00EDED' });
    }

    // Validate terms acceptance
    if (!termsAccepted) {
      setTermsError('You must agree to the terms of service and privacy policy.');
      hasError = true;
    } else {
      setTermsError('');
    }

    // Only proceed if there are no errors
    if (!hasError && dashboardConnection) {
      console.log('theUserId', hitchUser);

      hitchDashCommand('marketing', 'offer', 'establish-account', {
        theUserId:hitchUser.userId,
        Email:hitchUser.email,
        PhoneNumber:phoneNumber,
        FirstName:firstName,
        LastName:lastName,
        OrganizationName:organizationName,
        AgreeTerms:termsAccepted
      });    

      console.log("No errors, calling onNext with: ", { firstName, lastName, phoneNumber, organizationName });
      onNext({ firstName, lastName, phoneNumber, organizationName });
    } else {
      console.log(hasError);
      console.log('dashboardConnection', dashboardConnection);
      console.log("Validation errors present");
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div style={containerStyle}>
        <div style={subtitleContainerStyle}>
          
          <p style={subtitleStyle}>To start, let's create your account.</p>
        </div>
        <div>
          <TextField
            name="email"
            label="Email"
            variant="outlined"
            fullWidth
            value={email ?? ''}
            InputLabelProps={{
              style: {
                color: 'grey', // Set label color to grey
                fontSize: '16px',
                fontFamily: 'Poppins, sans-serif',
              },
            }}
            InputProps={{
              readOnly: true, // Make the TextField non-editable
              style: {
                color: 'grey', // Set text color to grey
                fontSize: '16px',
                height: '40px', // Set the same height as other text fields
                width: '300px', // Set the same width as other text fields
                borderColor: 'grey !important', // Set border color to grey
                fontFamily: 'Poppins, sans-serif',
              },
            }}
            sx={{
              background: 'black',
              marginBottom: '16px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'grey !important', // Set outline color to grey
              },
            }}
            required
            // Add any error handling or helper text as needed
            autoComplete="off"
          />
        </div>
        <div>
          <TextField
            ref={firstNameRef}
            autoFocus={isFirstNameActive}
            name="firstName"
            label="First Name"
            variant="outlined"
            fullWidth
            value={firstName ?? ''}
            onChange={handleFirstNameChange}
            style={{ marginTop: '8px' }}
            InputLabelProps={{
              style: {
                color: 'rgba(255, 255, 255, 0.7)',
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                fontFamily: 'Poppins, sans-serif',
              },
            }}
            InputProps={{
              style: {
                color: 'white',
                fontSize: '16px',
                width: '300px',
                fontFamily: 'Poppins, sans-serif',
              },
              sx: {
                background: 'black',
                height: '40px',
                color: 'black',
                '& .MuiOutlinedInput-notchedOutline': {
                  ...firstNameOutlineStyle,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  ...firstNameOutlineStyleActive,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  ...firstNameOutlineStyleActive
                },
              },
            }}
            sx={{
              marginBottom: '12px',
            }}
            required
            error={!!firstNameError}
            helperText={firstNameError || ' '}
            FormHelperTextProps={{ style: helperTextStyle }}
            autoComplete="off"
          />
        </div>

        <div>
          <TextField
            name="lastName"
            label="Last Name"
            variant="outlined"
            fullWidth
            value={lastName ?? ''}
            onChange={handleLastNameChange}
            InputLabelProps={{
              style: {
                color: 'rgba(255, 255, 255, 0.7)',
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                fontFamily: 'Poppins, sans-serif',
              },
            }}
            InputProps={{
              style: {
                color: 'white',
                fontSize: '16px',
                width: '300px',
                fontFamily: 'Poppins, sans-serif',
              },
              sx: {
                background: 'black',
                height: '40px',
                color: 'white',
                '& .MuiOutlinedInput-notchedOutline': {
                  ...lastNameOutlineStyle,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  ...lastNameOutlineStyleActive,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  ...lastNameOutlineStyleActive,
                },
              },
            }}
            sx={{
              marginBottom: '12px',
            }}
            required
            error={!!lastNameError}
            helperText={lastNameError || ' '}
            FormHelperTextProps={{ style: helperTextStyle }}
            autoComplete="off"
          />
        </div>
        <div>
          <TextField
            name="phoneNumber"
            label="Phone"
            variant="outlined"
            value={phoneNumber ?? ''}
            onChange={handlePhoneNumberChange}
            onBlur={handlePhoneNumberBlur}
            InputLabelProps={{
              style: {
                color: 'rgba(255, 255, 255, 0.7)',
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                fontFamily: 'Poppins, sans-serif',
              },
            }}
            InputProps={{
              style: {
                color: 'white',
                fontSize: '16px',
                width: '300px', // Set the width same as first name and last name fields
                fontFamily: 'Poppins, sans-serif',
              },
              sx: {
                background: 'black',
                height: '40px',
                color: 'white',
                '& .MuiOutlinedInput-notchedOutline': {
                  ...phoneNumberOutlineStyle,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  ...phoneNumberOutlineStyleActive,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  ...phoneNumberOutlineStyleActive,
                },
              },
            }}
            sx={{
              marginBottom: '12px',
            }}
            required
            error={!!phoneNumberError}
            helperText={phoneNumberError || ' '}
            FormHelperTextProps={{ style: helperTextStyle }}
            autoComplete="off"
          />
        </div>

        <div>
          <TextField
            name="organizationName"
            label="Organization Name"
            variant="outlined"
            fullWidth
            value={organizationName ?? ''}
            onChange={handleOrganizationNameChange}
            InputLabelProps={{
              style: {
                color: 'rgba(255, 255, 255, 0.7)',
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                fontFamily: 'Poppins, sans-serif',
              },
            }}
            InputProps={{
              style: {
                color: 'white',
                fontSize: '16px',
                width: '300px', // Set the width same as first name and last name fields
                fontFamily: 'Poppins, sans-serif',
              },
              sx: {
                background: 'black',
                height: '40px',
                color: 'white',
                '& .MuiOutlinedInput-notchedOutline': {
                  ...organizationNameOutlineStyle,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  ...organizationNameOutlineStyleActive,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  ...organizationNameOutlineStyleActive,
                },
              },
            }}
            sx={{
              marginBottom: '12px',
            }}
            required
            error={!!organizationNameError}
            helperText={organizationNameError || ' '}
            FormHelperTextProps={{ style: helperTextStyle }}
            autoComplete="off"
          />
        </div>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                sx={{
                  color: 'white',
                  '&.Mui-checked': {
                    color: '#00EDED',
                  },
                }}
              />
            }
            label={
              <span style={{ color: 'white', fontFamily: 'Poppins, sans-serif' }}>
                I agree to the <a href="/tos" target="_blank" rel="noopener noreferrer" style={{ color: '#00EDED' }}>Terms</a> 
              </span>
            }
          />
        </FormGroup>
        {termsError && <p style={{ color: 'red', fontSize: '0.75rem' }}>{termsError}</p>}

        <DialogActions style={{ marginTop: '-16px' }}>
          <Button
            variant="outlined"
            onClick={handleNextClick}
            style={primaryButtonStyle}
          >
            Next <FontAwesomeIcon icon={faArrowRight} fontSize="smaller" />
          </Button>
        </DialogActions>
        <CancelPrompt
          open={showCancelPrompt}
          onClose={handleCloseCancelPrompt}
          onConfirm={handleConfirmCancel}
        />
      </div>
    </ThemeProvider>
  );
}

export default AddUser;
