import { Button, Grid, Typography } from "@mui/material";
import HitchBox from "../../components/hitchbox/HitchBox";
import { useEffect, useRef, useState } from "react";
import VectorStoreGrid from "./VectorStoreGrid";
import { useHubConnections } from "../../utils/HubConnectionsProvider";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";

function VectorStore() {
    const navigate = useNavigate();
    const containerRef = useRef(null);
    const { dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady, user } = useHubConnections();
    const [data, setData] = useState([]);

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on("ab183cbd-c1a8-4bf3-a264-c8fa7d58660c", handleIt);

            hitchDashCommand('document', 'repo', 'list', {
              }, () => {
                // console.log('Message sent successfully');
              });
        }

        return (() => {
            if (dashboardConnection) {
                dashboardConnection.off("ab183cbd-c1a8-4bf3-a264-c8fa7d58660c", handleIt);
            }
        });

    }, [dashboardConnection]);

    function handleIt(theData) {
        // console.log('store-list', theData);
        setData(theData);
    }

    function newStore() {
        let newId = uuidv4();
        navigate(`/app/storage/${newId}`)
    }

    return (
        <Grid container ref={containerRef}>
            <Grid xs item></Grid>
            <Grid xs={9} item>
                <Grid container>
                    <Grid xs={9} item>
                        <HitchBox backgroundColor="#000000" color="#00FFFF">
                            <Typography variant="h4">Document Storage</Typography>
                        </HitchBox>
                    </Grid>
                    <Grid xs={3} item>
                        <HitchBox backgroundColor="#000000" color="#FFFFFF">
                            <Button
                                variant="contained"
                                onClick={(e) => newStore()}>
                                Create a Store
                            </Button>
                        </HitchBox>
                    </Grid>
                    <Grid xs={12} item>
                        <HitchBox backgroundColor="#007877" color="#FFFFFF">
                            <VectorStoreGrid data={data} />
                        </HitchBox>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs item></Grid>
        </Grid>
    );

}

export default VectorStore;