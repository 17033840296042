import React, { createContext, useContext, useState, useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import isValidGUID from '../utils/IsValidGuid';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';

const QualifierConnectionsContext = createContext();

// Helper function to get cookie value by name
const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

export const QualifierConnectionsProvider = ({ children }) => {
    const [accountId, setAccountId] = useState('');
    const [chatConnection, setChatConnection] = useState(null);
    const [started, setStarted] = useState(false);
    const [activeSessionId, setActiveSessionId] = useState(null);
    const [isBusy, setIsBusy] = useState(false);
    const [responseListeners, setResponseListeners] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [sessionId, setSessionId] = useState(null); // Session ID is initialized in useEffect
    const [user, setUser] = useState(null);
    const [chatBusy, setChatBusy] = useState(false);
    const [messages, setMessages] = useState([]);
    const [conversation, setConversation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [matrixRoutes, setMatrixRoutes] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const [mode, setMode] = useState(null);
    const [captchaValid, setCaptchaValid] = useState(false);

    const { guid } = useParams();

    const userUuid = getCookie('userId') || uuidv4(); // Retrieve or generate UUID for the user

    // Initialize sessionId when component mounts
    useEffect(() => {
        setSessionId(guid);
    }, [guid]);

    const validateCaptcha = async (token) => {
        console.log('Validating CAPTCHA...');
        const response = await fetch(`${window._env_.HITCH_API}/api/validateCaptcha`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token, userUuid })
        });

        const data = await response.json();
        if (data.success) {

            if (!getCookie('userId')) {
                document.cookie = `userId=${userUuid}; max-age=${30 * 24 * 60 * 60}; path=/; Secure`;
            }
            document.cookie = `accessToken=${data.accessToken}; max-age=${30 * 24 * 60 * 60}; path=/; Secure`;

            setCaptchaValid(true);
            return true;
        }

        console.log('CAPTCHA validation failed.');
        return false;
    };

    useEffect(() => {
        console.log('started', started);
    }, [started]);

    const initializeConnection = async (captchaToken, theMode) => {
        const accessToken = getCookie('accessToken');
        console.log("Access Token:", accessToken);

        if (!accessToken || !captchaToken || !sessionId || !isValidGUID(sessionId)) {
            console.log('Connection initialization failed due to missing parameters.');
            return;
        }

        let theUrl = `${window._env_.HITCH_API}/qualifierHub?sessionId=${sessionId}`;
        if (theMode) {
            theUrl = `${window._env_.HITCH_API}/qualifierHub?sessionId=${sessionId}&chatMode=${theMode}`;
        }

        console.log('The Connection:', theUrl);

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(theUrl, {
                accessTokenFactory: () => accessToken
            })
            .withAutomaticReconnect()
            .build();

        try {
            await connection.start();
            setChatConnection(connection);
            setStarted(true);
            setActiveSessionId(sessionId);
            console.log('Attaching event listeners...');
            connection.on("ConversationUpdate", handleConversationUpdate);
            connection.on("HitchyIsBusy", setLoading);
            connection.on("MatrixRoutes", setMatrixRoutes);
            connection.on("MessageDelta", handleMessageDelta);
        } catch (error) {
            console.error("Error starting QualifierHub connection:", error);
        }

        return () => {
            console.log('Detaching event listeners...');
            connection.off("ConversationUpdate", handleConversationUpdate);
            connection.off("HitchyIsBusy", setLoading);
            connection.off("MatrixRoutes", setMatrixRoutes);
            connection.off("MessageDelta", handleMessageDelta);
            connection.stop().then(() => console.log('Disconnected from QualifierHub'));
        };
    };

    const handleMessageDelta = (delta) => {
        console.log('delta', delta);
        setMessages((prevMessages) => {
            let found = false;
            const updatedMessages = prevMessages.map((msg) => {
                if (msg.streamId === delta.id) {
                    found = true;
                    if (delta.delta.final) {
                        msg.finished = true;
                    }
                    if (!msg.finished) {
                        let newContent = msg.content;
                        delta.delta.content.forEach(change => {
                            if (change.type === "text") {
                                newContent += change.text.value;
                            }
                        });
                        return { ...msg, content: newContent };
                    }
                }
                return msg;
            });
            if (!found) {
                if (!delta.delta.final) {
                    const newMessage = {
                        messageId: uuidv4(),
                        streamId: delta.id,
                        content: delta.delta.content.reduce((acc, change) => {
                            if (change.type === "text") {
                                return acc + change.text.value;
                            }
                            return acc;
                        }, ""),
                        contentType: 'string',
                        type: 'bot',
                        userId: accountId,
                        messageDateTime: new Date().toISOString(),
                        finished: false
                    };
                    return [...updatedMessages, newMessage];
                }
            }
            return updatedMessages;
        });
    };

    const handleConversationUpdate = (updatedConversation) => {
        console.log('handleConversationUpdate', updatedConversation);
        setMessages(updatedConversation.messages.map(msg => ({
            messageId: msg.messageId,
            dateTime: msg.messageDateTime,
            user: msg.user,
            text: msg.message,
            content: msg.content,
            contentType: msg.contentType,
            isHelpful: msg.isHelpful,
            isNotHelpful: msg.isNotHelpful
        })));
        setConversation(updatedConversation);
    };

    function hitchCommand(service, feature, operation, payload = {}, onSuccess, onError) {
        const payloadWithToken = { ...payload };
        if (chatConnection) {
            console.log(`HitchCommand: ${sessionId} - ${service}, ${feature}, ${operation}`, payloadWithToken);
            chatConnection.invoke("HitchCommand", sessionId, service, feature, operation, payloadWithToken)
                .then(() => {
                    if (onSuccess && typeof onSuccess === 'function') {
                        onSuccess();
                    }
                })
                .catch(error => {
                    if (onError && typeof onError === 'function') {
                        onError(error);
                    } else {
                        console.error("HitchCommand error:", error);
                    }
                });
        } else {
            console.error("Chat connection not established.");
        }
    }

    const value = {
        accountId,
        chatBusy,
        chatConnection,
        conversation,
        hitchCommand,
        isBusy,
        isAuthenticated,
        loading,
        matrixRoutes,
        messages,
        sessionId,
        setMessages,
        setSessionId,
        user,
        setUser,
        isTyping,
        setMode,
        initializeConnection,
        validateCaptcha,
        captchaValid
    };

    useEffect(() => {
        const setupConnection = async () => {
            if (!started && sessionId && isValidGUID(sessionId)) {
                const token = getCookie('accessToken');
                if (token) {
                    await initializeConnection(token, mode);
                }
            }
        };

        setupConnection();

        return () => {
            if (chatConnection) {
                chatConnection.stop().then(() => console.log('Disconnected from QualifierHub'));
            }
        };
    }, [sessionId, mode, started]);

    return (
        <QualifierConnectionsContext.Provider value={value}>
            {children}
        </QualifierConnectionsContext.Provider>
    );
};

// Hook to use QualifierConnection
export const useQualifierConnections = () => useContext(QualifierConnectionsContext);
