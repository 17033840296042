import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { KeyboardDoubleArrowRight as MenuIcon } from '@mui/icons-material';
import {
  Grid, Typography, IconButton, TextField, Box, Avatar, Modal, List, ListItem, ListItemText, Dialog, DialogTitle, DialogActions, Button,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useHubConnections } from '../../../utils/HubConnectionsProvider';
import { useTheme, useMediaQuery } from '@mui/material';
import SettingsMenu from '../SettingsMenu/SettingsMenu';  // Import the SettingsMenu component
import { useSettingsStyles } from '../common/SettingsStyleProvider';


function AppList() {
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);

  const {
    font,
    themeBackgroundColor,
    themePrimaryTextColor,
    defaultOutlineStyle,
    activeOutlineStyle,
    errorOutlineStyle,
    supportStyle,
    boxStyle,
    buttonStyle,
    buttonStyleSecondary,
    dividerStyle,
    headerStyle,
    titleStyle,
    buttonContainerStyle,
    initialState,
    containerStyle,
    helperTextStyle,
    fixedWidthStyle,
    greetingContainerStyle,
    setIsDrawerOpen,
  } = useSettingsStyles();

  const { dashboardConnection, account, hitchDashCommand, hitchUser, isConnectionReady } = useHubConnections();

  
  const themeSecondaryTextColor = theme.palette.secondaryText[theme.palette.mode];
  const themePrimaryButtonColor = theme.palette.primaryButton[theme.palette.mode];
  const themePrimaryButtonTextColor = theme.palette.primaryButtonText[theme.palette.mode];
  const themeSecondaryButtonColor = theme.palette.secondaryButton[theme.palette.mode];
  const themeSecondaryButtonTextColor = theme.palette.secondaryButtonText[theme.palette.mode];
  const themeSecondaryButtonBorderColor = theme.palette.secondaryButtonBorder[theme.palette.mode];
  const themeSecondaryDividerColor = theme.palette.secondaryDivider[theme.palette.mode];
  const themeTextboxOutlineColor = theme.palette.textboxOutline[theme.palette.mode];
  const themeTextboxOutlineHoverColor = theme.palette.textboxOutline.hover[theme.palette.mode];
  const themeTextboxOutlineErrorColor = theme.palette.textboxOutline.error[theme.palette.mode];
  const themeLinkColor = theme.palette.link[theme.palette.mode];

  const [apps, setApps] = useState([]);
  const [filteredApps, setFilteredApps] = useState([]);
  const [expandedApp, setExpandedApp] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listItems, setListItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isMobile) {
      setIsDrawerOpen(true);  // Open the drawer when not in mobile view
    }
  }, [isMobile, setIsDrawerOpen]);

  useEffect(() => {
  
    if (dashboardConnection && isConnectionReady) {
      dashboardConnection.on("ConnectionUpdate", (data) => {
        // console.log('ConnectionUpdate', data);
        setApps(data); // Update apps with the data received from SignalR
        callForAvailables();
      });

      dashboardConnection.on("7ca387cb-6ff9-4a13-8dff-2fdb954cbf89", (data) => {
        setApps(data);
        console.log("7ca387cb-6ff9-4a13-8dff-2fdb954cbf89", data);
      });

      dashboardConnection.on("31f2e265-5336-490d-b5f3-b33b85c79dd1", (data) => {
        setListItems(data);
      });

      callForConnections();
      callForAvailables();
    }

    return () => {
      if (dashboardConnection) {
        dashboardConnection.off("ConnectionUpdate");
        dashboardConnection.off("7ca387cb-6ff9-4a13-8dff-2fdb954cbf89");
        dashboardConnection.off("31f2e265-5336-490d-b5f3-b33b85c79dd1");
      }
    };
  }, [dashboardConnection, isConnectionReady]);

  useEffect(() => {
    if (apps) {
      setFilteredApps(apps.filter(app => app.name?.toLowerCase().includes(searchTerm ? searchTerm.toLowerCase() : '')));
    }
  }, [apps, searchTerm]);

  function callForConnections() {
    if (dashboardConnection) {
      hitchDashCommand('account', 'connection', 'list', {
        "theAccountId": account.Id
      }, () => {
        // console.log('Message sent successfully');
      });
    }
  }

  function buildServicePackage(serviceId, serviceName) {
    let thePackage = {
      TheServiceId: serviceId,
      ConnectionName: `New ${serviceName}`
    };
    return thePackage;
  }

  function callForAvailables() {
    if (dashboardConnection) {
      hitchDashCommand('account', 'connection', 'available-services', {});
    }
  }

  const handleAutocompleteChange = (event, newValue) => {
    setSearchTerm(newValue); // Update searchTerm when a new value is selected
  };

  const toggleExpansion = (appName) => {
    setExpandedApp(expandedApp === appName ? null : appName);
  };

  // Handle opening the modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
    // You might want to call the SignalR method to get the list items here or in useEffect
  };

  // Handle selecting an item from the list
  const handleListItemClick = (item) => {
    setSelectedItem(item);
    setIsConfirmDialogOpen(true);
  };

  // Confirm connection setup
  function handleConfirmConnection() {
    console.log("Confirmed connection for:", selectedItem);
    if (dashboardConnection) {
      // Proceed with the connection setup
      let thePackage = buildServicePackage(selectedItem.serviceId, selectedItem.name);
      hitchDashCommand('account', 'connection', 'create', thePackage);
    }
    setIsConfirmDialogOpen(false);
    setIsModalOpen(false);
  }





  const autocompleteStyles = {
    input: {
      color: 'white',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white', // Set the border color
      },
      '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white', // Set the border color on hover
      },
      '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white', // Set the border color on focus
      },
    },
    label: {
      color: 'white',
    },
  };

  const navigateToAppConnection = (connectionId) => {
    navigate(`/app/connection/${connectionId}`);
  };

  
  const drawerWidth = 240; // Adjust to match your drawer's width


  const flexItemStyle = {
    flex: 1,
  };

  const flexRowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginLeft: '8px',
  };

  const detailItemStyle = {
    marginBottom: '4px', // Adding more space between the lines
    fontFamily: '"Poppins", sans-serif', // Apply the Poppins font family
  };

  const listItemStyle = {
    border: '1px solid #ccc',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '50%', // Example: Set width to 80% of the parent container
    maxWidth: '600px', // You can also set a max-width
    marginLeft: 'auto', // These two lines center the list item
    marginRight: 'auto',
    fontFamily: '"Poppins", sans-serif', // Apply the Poppins font family
  };

  const appInfoStyle = {
    display: 'flex',
    alignItems: 'center',
    flex: '1', // Adjust the flex property as needed
  };

  const scheduleStyle = {
    textAlign: 'center', // Centers the text
    flex: '2', // Adjust to ensure alignment
    marginRight: '20px', // Adjust this value to increase spacing before the MenuIcon
  };

  const buttonStyle2 = {
    backgroundColor: themePrimaryButtonColor,
    color: themePrimaryButtonTextColor,
    border: '1px solid #00EDED',
    borderRadius: '50%', // Making the button circular
    padding: '6px', // Adjust padding to fit the circle
    marginLeft: '10px', // Space between the combo box and the button
    fontFamily: '"Poppins", sans-serif', // Apply the Poppins font family
  };

  const openInviteDialog = () => {
    //setInviteDialogOpen(true);
    handleOpenModal();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <SettingsMenu hitchUser={hitchUser} isDrawerOpen={drawerOpen} setIsDrawerOpen={setDrawerOpen} />
      <Box sx={{ flexGrow: 1, marginLeft: !isMobile && drawerOpen ? '240px' : '0px' }}>
        {isMobile && !drawerOpen && (
          <IconButton
            onClick={() => setDrawerOpen(true)}
            size="small"
            sx={{
              color: 'white',
              backgroundColor: '#18181B',
              opacity: 0.8,
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
              margin: '10px',
              zIndex: 1000,
              position: 'fixed',
              top: '66px',
              left: '16px',
              '&:hover': {
                backgroundColor: 'rgba(21, 101, 192, 0.8)',
              },
            }}
          >
            <KeyboardDoubleArrowRight />
          </IconButton>
        )}

        <Box sx={supportStyle}>
          <Box sx={boxStyle}>
            <Box sx={headerStyle}>
              <Typography variant="h6" sx={titleStyle}>App Integration</Typography>
            </Box>
        <div style={dividerStyle}></div>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            <Box display="flex" alignItems="center">
              <Autocomplete
                value={searchTerm}
                onChange={handleAutocompleteChange}
                options={apps ? apps.map((app) => app?.name) : []}
                onInputChange={(event, newInputValue) => {
                  setSearchTerm(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search Apps"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: themeSecondaryTextColor }} />
                        </InputAdornment>
                      ),
                      style: {
                        color: themeSecondaryTextColor, height: '40px',
                        fontFamily: '"Poppins", sans-serif',
                      },
                    }}
                    sx={{
                      width: 248,
                      marginRight: '10px',
                      '.MuiOutlinedInput-root': {
                        color: 'white',
                        '& fieldset': {
                          borderColor: themeTextboxOutlineColor,
                        },
                        '&:hover fieldset': {
                          borderColor: themeTextboxOutlineHoverColor,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: themeTextboxOutlineHoverColor,
                        },
                        '& .MuiInputBase-input': {
                          padding: '12px 14px',
                          height: '0px',
                        },
                      },
                    }}
                  />
                )}
              />
              <IconButton onClick={openInviteDialog} style={buttonStyle}>
                <AddIcon fontSize="small" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <div style={dividerStyle}></div>
        <div style={flexRowStyle}>
          <div style={flexItemStyle}>
            <p style={detailItemStyle}>Connected Apps:</p>
            <ul>
              {filteredApps.length > 0 ? (
                filteredApps.map((app, index) => (
                  <li key={index} style={listItemStyle}>
                    <div style={appInfoStyle}>
                      <Avatar
                        style={{ marginRight: '10px' }}
                        src={`${window._env_.HITCH_API}/api/accimage/${app.serviceId}.png`} />
                      {app.name}
                    </div>
                    <div style={scheduleStyle}>
                      {app.scheduleCount}
                      <br />
                      Schedules
                    </div>
                    <MenuIcon style={{ cursor: 'pointer' }} onClick={() => navigateToAppConnection(app.connectionId)} />
                    {expandedApp === app.appName && (
                      <div>
                        <p>Connection setup details for {app.appName}</p>
                      </div>
                    )}
                  </li>
                ))
              ) : (
                <Typography variant="body2">No apps found that match that criteria.</Typography>
              )}
            </ul>
          </div>
        </div>
      </Box>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <List>
            {listItems.map((item, index) => (
              <ListItem button key={index} onClick={() => handleListItemClick(item)}>
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>
      <Dialog open={isConfirmDialogOpen} onClose={() => setIsConfirmDialogOpen(false)}>
        <DialogTitle>Confirm Connection</DialogTitle>
        <DialogActions>
          <Button onClick={() => setIsConfirmDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmConnection} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
          </Box>
        </Box>
    </Box>
  );
}

export default AppList;
