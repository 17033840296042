import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const columns = [
    { field: 'id', headerName: 'ID', width: 350 },
    {
      field: 'name',
      headerName: 'Name',
      width: 800,
      editable: true,
    }
  ];
  
function RuleList( {hubConnection, dashboardHitchCommand, sessionId} ) {
    const navigate = useNavigate(); // Hook for navigation
    const [rows, setRows] = useState([]);

    const handleRowClick = (params) => {
        navigate(`/app/rule/${params.id}`); // Navigate to the route with the row's ID
    };

    function setTheRows(data) {
        const transformedData = data.map(item => item.rule);
        setRows(transformedData);
    }
    

    useEffect(() => {
        if(hubConnection && dashboardHitchCommand) {
            hubConnection.on("RuleList", setTheRows);
            dashboardHitchCommand('account', 'rule', 'list');

            return () => {
              if (hubConnection) {
                hubConnection.off("RuleList", setTheRows);
              }
            };
        }
    }, [hubConnection, dashboardHitchCommand]);

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
                onRowClick={handleRowClick} // Add the row click handler
            />
        </Box>
    );
}

export default RuleList;