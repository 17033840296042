import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useState } from "react";



function PromptOperationStepper({ activeStep, setActiveStep, steps }) {

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper
                activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                   
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    )
}

export default PromptOperationStepper;