import React from 'react';
import { Box, Typography, Stack } from '@mui/material';

function Features()  {
    return (
        <Box 
            bgcolor="#000000" 
            justifyContent="center" 
            sx={{ 
                paddingX: { xs: 1, sm: 2, md: 9 } ,
                paddingY: { xs: 9, sm: 11, md: 14 } ,
                borderLeft: '1px solid black', // Add left border
                borderRight: '1px solid black', // Add right border
            }}
        >
            <Stack 
                spacing={5} 
                alignItems="space-between" 
                textAlign="center"
            >
                <Box //flex={1} 
                    sx={{ paddingX: { xs: 1, sm: 4, md: 35 } }}
                    bgcolor="#000000" 
                    b
                >
                    <Typography 
                    variant="h2"
                    sx={{
                        fontWeight: '600', // Bold
                        fontSize: { xs: '36px', sm: '50px', md: '50px' }, 
                        color:"#ffffff", 
                        marginBottom: { xs: 2, sm: 1.5, md: 1.5} ,
                        letterSpacing: '-1.5px', // Adjust the value based on your preference
                        lineHeight: 1.15, // Adjusted line height
                    }}
                    >
                        Discover the value of your data in three easy steps
                    </Typography>
                    <Typography 
                        variant="body1"
                        sx={{
                            color:"#ffffff", 
                            //marginTop:"16px",
                            marginBottom: { xs: 1, sm: 1, md: 4} ,
                            fontSize: { xs: '20px', sm: '20px', md: '20px' }, 
                        }}
                        >
                            Explore the possibilities with our interactive demo, connect your business tools for personalized data play, and uncover real-world insights into your business’s data.
                        </Typography>
                </Box>




                <Box bgcolor="#000000" sx={{ paddingX: { xs: 2, sm: 4, md: 9 } }}>
  <Stack
    spacing={{ xs: 4, sm: 6, md: 15 }} // Adjust the spacing between items
    direction={{ xs: 'column', sm: 'row' }} // Adjust the direction based on your design
    justifyContent="center"
  >
    {/* First Box */}
    <Box textAlign="center" bgcolor="#000000">
    <img
              src={'/img/RobustWorkflow.svg'}
              alt="Robust Workflow"
              style={{
                width: '32px', // Adjust the width as needed
                height: '32px', // Adjust the height as needed
              }}
            />
      <Typography
        variant="h4"
        sx={{
          fontWeight: '600',
          fontSize: { xs: '16px', sm: '28px', md: '32px' },
          marginTop: { xs: 1, sm: 1, md: 4 },
          marginBottom: '12px',
        }}
      >
        Robust Workflow
      </Typography>
      <Typography variant="body1"
        sx={{
          fontSize: { xs: '16px', sm: '18px', md: '18px' },
        }}>
        Streamline your operations and eliminate errors with our powerful AI-powered tool.
      </Typography>
    </Box>

    {/* Second Box */}
    <Box textAlign="center" bgcolor="#000000">
    <img
              src={'/img/Flexibility.svg'}
              alt="Flexibility"
              style={{
                width: '32px', // Adjust the width as needed
                height: '32px', // Adjust the height as needed
              }}
            />
      <Typography
        variant="h4"
        sx={{
          fontWeight: '600',
          fontSize: { xs: '16px', sm: '28px', md: '32px' },
          marginTop: { xs: 1, sm: 1, md: 4 },
          marginBottom: '12px',
        }}
      >
        Flexibility
      </Typography>
      <Typography variant="body1"
        sx={{
          fontSize: { xs: '16px', sm: '18px', md: '18px' },
        }}>
        Adapt and conquer any task with Hitch.AI, customized for your business.
      </Typography>
    </Box>

    {/* Third Box */}
    <Box textAlign="center" bgcolor="#000000">
    <img
              src={'/img/UserFriendly.svg'}
              alt="UserFriendly"
              style={{
                width: '32px', // Adjust the width as needed
                height: '32px', // Adjust the height as needed
              }}
            />
      <Typography
        variant="h4"
        sx={{
          fontWeight: '600',
          fontSize: { xs: '16px', sm: '28px', md: '32px' },
          marginTop: { xs: 1, sm: 1, md: 4 },
          marginBottom: '12px',
        }}
      >
        User Friendly
      </Typography>
      <Typography variant="body1"
        sx={{
          fontSize: { xs: '16px', sm: '18px', md: '18px' },
        }}>
        Generate complex reporting and insights with ease, even if you’re a tech novice.
      </Typography>
    </Box>
  </Stack>
</Box>

                    
            </Stack>
        </Box>
    );
}

export default Features;