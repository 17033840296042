import { v4 as uuidv4 } from 'uuid';

function ConditionDependencyFactory(accountId, ruleId, conditionId) {
    const position = {
        x: 0,
        y: 0,
    };

    const newId = uuidv4();

    return {
        id: newId,
        type: 'conditiondependency',
        position,
        data: { 
            id: newId,
            name: 'New Condition Dependency',
            accountId: accountId,
            conditionId: conditionId,
            ruleId: ruleId,
            serviceId: '',
            featureId: '',
            operationId: '',
            conditionDependencyProperty: []
        }
    };
}

export default ConditionDependencyFactory;