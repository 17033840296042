import React from 'react';
import { Modal, Box, Backdrop, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContactForm from '../contact/ContactForm'; // Import updated ContactForm

function ContactModal({ isOpen, handleClose }) {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        invisible: false,
        style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
      }}
    >
      <Box
        sx={{
          width: {
            xs: '80vw',
            sm: '70vw',
            md: '60vw',
          },
          maxWidth: '100%',
          maxHeight: '90vh',
          overflowY: 'auto',
          padding: '20px',
          backgroundColor: 'white',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '10px',
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" sx={{ marginBottom: '20px', textAlign: 'center' }}>
        Contact Us
      </Typography>
      </Box>
    </Modal>
  );
}

export default ContactModal;
