import React, { useState, useEffect } from "react";
import { Box, Grid, TextField, Tabs, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Collapse } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import OrganizationDetails from "./OrganizationDetails";
import AccountRoles from "./AccountRoles";
import OrganizationJournals from "./OrganizationJournals";
import OrganizationConnections from "./OrganizationConnections";
import CustomTab from "../User/CustomTab";
import { useAdminHubConnections } from "../../utils/HubAdminConnectionProvider";
import AdminSearchGridV2 from "../AdminSearchGridV2";
import HitchBox from "../../components/hitchbox/HitchBox";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useHubConnections } from "../../utils/HubConnectionsProvider";

const Organizations = ({ activeOrganization, setActiveOrganization }) => {
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [organizationTabValue, setOrganizationTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [expandedDetails, setExpandedDetails] = useState(true); // Add this line
  const [expandedConnections, setExpandedConnections] = useState(true);
  const [expandedRoles, setExpandedRoles] = useState(true);
  const [expandedJournals, setExpandedJournals] = useState(true);
  const [accountToDelete, setAccountToDelete] = useState(null);
  const {
    adminConnection,
    hitchUser,
    isConnectionReady
  } = useAdminHubConnections();
  const {         
    defaultSnack,
    infoSnack,
    warningSnack,
    successSnack,
    errorSnack, 
} = useHubConnections();

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'Organization Name', width: 300 }
  ];

  useEffect(() => {
    if (adminConnection) {
      const subscribeToEvents = () => {
        adminConnection.on('Accounts', (data) => {
          setLoading(true);
          setList(data);
        });

        adminConnection.on('Account', (data) => {
          const editingModel = data;
          setSelectedOrganizations((prevOrganizations) => [...prevOrganizations, editingModel]);
        });

        // Add other event subscriptions here
      };

      subscribeToEvents();

      adminConnection.invoke("GetAccounts", hitchUser?.userId, searchTerm)
        .then(() => {
          console.log('getting accounts');
          setLoading(true);
        })
        .catch(error => console.error("Error sending message:", error));

      return () => {
        adminConnection.off('Accounts');
        adminConnection.off('Account');
        // Unsubscribe from other events here
      };
    }
  }, [adminConnection]);

  const filter = (term) => {
    if (adminConnection) {
      adminConnection.invoke("GetAccounts", hitchUser?.userId, term)
        .then(() => setLoading(true))
        .catch(error => console.error("Error sending message:", error));
    }
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term) {
      filter(term);
    } else {
      setList([]); // Show full list when search is cleared
    }
  };

  const handleSearchFocus = () => {
    filter(''); // Show full list on focus
  };

  function setDeleteOrganization(accountId) {
    setAccountToDelete(accountId);
  }

  const handleRowClick = (params) => {
    console.log('Selected organization:', params.row);
    const selectedOrganization = list.find(org => org.id === params.row.id);
    setSelectedOrganizations(prevOrganizations => [...prevOrganizations, selectedOrganization]);
    setSearchTerm(''); // Clear search term to hide the search list
    setList([]); // Hide the list after selection
    setOrganizationTabValue(selectedOrganizations.length); // Switch to the newly added tab
  };

  const handleOrganizationTabChange = (event, newValue) => {
    setOrganizationTabValue(newValue);
  };

  const handleTabClose = (index) => {
    setSelectedOrganizations((prevOrganizations) => prevOrganizations.filter((_, i) => i !== index));
    if (organizationTabValue === index && selectedOrganizations.length > 1) {
      setOrganizationTabValue(index === 0 ? 0 : index - 1);
    } else if (selectedOrganizations.length === 1) {
      setOrganizationTabValue(0);
    }
  };

  const handleCloseSearch = () => {
    setList([]);
    setSearchTerm('');
  };

  const handleSave = () => {
    // Simulate save
    alert("Organization details updated successfully");
    setEditMode(false);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleDelete = () => {
    adminConnection.invoke("WipeOutAccount", hitchUser?.userId, accountToDelete)
        .then(() => {
          infoSnack('Organization is being deleted');
          setAccountToDelete(null);
        })
        .catch(error => console.error("Error sending message:", error));

    setAccountToDelete(null);
  };

  const handleAddNew = () => {
    // Add new organization logic here
  };

  return (
    <HitchBox backgroundColor={"#004444"} color={"#00eded"}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <TextField
            label="Search Organizations"
            value={searchTerm}
            onChange={handleSearchChange}
            onFocus={handleSearchFocus}
            fullWidth
            sx={{ backgroundColor: '#fff', color: '#000', borderColor: '#d3d3d3' }}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleCloseSearch} sx={{ color: '#fff' }}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <Button variant="contained" color="primary" onClick={handleAddNew}>
            Add New
          </Button>
        </Grid>
        {list.length > 0 && (
          <Grid item xs={12}>
            <AdminSearchGridV2
              columns={columns}
              list={list}
              handleRowClick={handleRowClick}
              rowSelectionModel={[]}
            />
          </Grid>
        )}
        {selectedOrganizations.length > 0 && (
          <Grid item xs={12}>
            <Tabs
              value={organizationTabValue}
              onChange={handleOrganizationTabChange}
              aria-label="organization tabs"
              TabIndicatorProps={{ style: { backgroundColor: '#00eded' } }} // Change the color of the indicator
            >
              {selectedOrganizations.map((org, index) => (
                <CustomTab
                  key={org.id}
                  label={org.name}
                  onClose={() => handleTabClose(index)}
                  sx={{
                    color: organizationTabValue === index ? '#00eded' : '#000',
                    '&.Mui-selected': {
                      color: '#00eded',
                    },
                  }}
                />
              ))}
            </Tabs>
            {selectedOrganizations.map((org, index) => (
              <Box key={org.id} hidden={organizationTabValue !== index}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                      <Button
                        variant="contained"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() => setDeleteOrganization(org.id)}
                      >
                        Delete Organization
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ border: '1px solid #00eded', padding: '20px', borderRadius: '4px' }}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="h5" sx={{ marginBottom: '20px', color: '#00eded' }}>
                          Organization Details
                        </Typography>
                        <Box>
                          <IconButton
                            onClick={() => setExpandedDetails(!expandedDetails)}
                            aria-expanded={expandedDetails}
                            aria-label="show more"
                          >
                            {expandedDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          </IconButton>
                          {editMode ? (
                            <>
                              <IconButton onClick={handleSave} color="primary">
                                <SaveIcon />
                              </IconButton>
                              <IconButton onClick={handleCancel} color="secondary">
                                <CancelIcon />
                              </IconButton>
                            </>
                          ) : (
                            <IconButton onClick={() => setEditMode(true)} color="primary">
                              <EditIcon />
                            </IconButton>
                          )}
                        </Box>
                      </Grid>
                      <Collapse in={expandedDetails} timeout="auto" unmountOnExit>
                        <OrganizationDetails
                          organization={org}
                          editMode={editMode}
                          setEditMode={setEditMode}
                          handleSave={handleSave}
                          handleCancel={handleCancel} />
                      </Collapse>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ border: '1px solid #00eded', padding: '20px', borderRadius: '4px', marginBottom: '20px' }}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="h5" sx={{ marginBottom: '20px', color: '#00eded' }}>
                          Connections
                        </Typography>
                        <IconButton
                          onClick={() => setExpandedConnections(!expandedConnections)}
                          aria-expanded={expandedConnections}
                          aria-label="show more"
                        >
                          {expandedConnections ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </Grid>
                      <Collapse in={expandedConnections} timeout="auto" unmountOnExit>

                        <OrganizationConnections
                          organization={org} />

                      </Collapse>
                    </Box>
                    <Box sx={{ border: '1px solid #00eded', padding: '20px', borderRadius: '4px', marginBottom: '20px' }}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="h5" sx={{ marginBottom: '20px', color: '#00eded' }}>
                          Account Roles
                        </Typography>
                        <IconButton
                          onClick={() => setExpandedRoles(!expandedRoles)}
                          aria-expanded={expandedRoles}
                          aria-label="show more"
                        >
                          {expandedRoles ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </Grid>
                      <Collapse in={expandedRoles} timeout="auto" unmountOnExit>

                        <AccountRoles
                          organizationId={org.id} />

                      </Collapse>
                    </Box>
                    <Box sx={{ border: '1px solid #00eded', padding: '20px', borderRadius: '4px' }}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="h5" sx={{ marginBottom: '20px', color: '#00eded' }}>
                          Organization Journals
                        </Typography>
                        <IconButton
                          onClick={() => setExpandedJournals(!expandedJournals)}
                          aria-expanded={expandedJournals}
                          aria-label="show more"
                        >
                          {expandedJournals ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </Grid>
                      <Collapse in={expandedJournals} timeout="auto" unmountOnExit>

                        <OrganizationJournals
                          organization={org} />

                      </Collapse>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Grid>
        )}
      </Grid>
      <Dialog
        open={accountToDelete !== null}
        onClose={() => setAccountToDelete(null)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this organization?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAccountToDelete(null)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </HitchBox>
  );
};

export default Organizations;
