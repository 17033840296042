import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Alert, Box, Snackbar, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import * as signalR from '@microsoft/signalr';
import "./Quickbooks.css";
import QuickbooksSteps from "./QuickbooksSteps";
import QbTabBar from "./QbTabBar";
import QbTraining from "./QbTraining";
import QbTrainingFile from "./QbTrainingFile";
import QbTester from "./QbTester";
import QbPrompt from "./QbPrompt";
import QbStatus from "./QbStatus";
import QbError from "./QbError";
import isValidGUID from "../../utils/IsValidGuid";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function Quickbooks({ user, isAuthenticated }) {
    //#region State

    // FOR PROMPT PHASE
    const [prompt, setPrompt] = useState('');

    // FOR STEP PHASE
    const [steps, setSteps] = useState([]);
    const [selectedStep, setSelectedStep] = useState(null);


    const [entity, setEntity] = useState('');
    const [report, setReport] = useState('');
    const [rows, setRows] = useState([]);
    const [reportParams, setReportParams] = useState([]);
    const [reportParameters, setReportParameters] = useState({});
    const [columns, setColumns] = useState([]);
    const [reportColumns, setReportColumns] = useState([]);
    const [reportRows, setReportRows] = useState([]);
    const [editStepId, setEditStepId] = useState('');
    const [query, setQuery] = useState('');
    const [entities, setEntities] = useState([]);
    const [reports, setReports] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const [hubConnection, setHubConnection] = useState(null);
    const [sessionId, setSessionId] = useState('');
    const [started, setStarted] = useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [tone, setTone] = useState('primary');
    const [tabValue, setTabValue] = React.useState(0);

    const [jsonl, setJsonl] = useState('');
    const [trainingFile, setTrainingFile] = useState('');
    const [outputOptions, setOutputOptions] = useState([]);
    const [outputType, setOutputType] = useState('');
    const [allResults, setAllResults] = useState([]);

    const [packageResult, setPackageResult] = useState([]);

    const [gptResponse, setGptResponse] = useState('');

    const [busy, setBusy] = useState(false);

    const [transformer, setTransformer] = useState('');
    const [transformerSteps, setTransformerSteps] = useState([]);
    const [finalStep, setFinalStep] = useState(false);

    const [tfLineCount, setTfLineCount] = useState(0);
    const [chatModel, setChatModel] = useState('');

    const [runIfTrue, setRunIfTrue] = useState([]);

    //#endregion

    const history = useNavigate();
    const { guid } = useParams();

    //#region useEffects
    useEffect(() => {
        if (guid && isValidGUID(guid)) {
            setSessionId(guid);
            setStarted(false);
        } else {
            const newGuid = uuidv4();
            history(`/app/quickbooks/${newGuid}`);
            setSessionId(newGuid);
        }
    }, [guid]);

    useEffect(() => {
        console.log('reportRows', reportRows);
    }, [reportRows])

    useEffect(() => {
        console.log('Checking conditions to start SignalR:');
        if (!started && isAuthenticated && isValidGUID(sessionId)) {
            const jwtToken = user.id_token;
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(`${window._env_.HITCH_API}/quickbooksHub?sessionId=${sessionId}`, {
                    accessTokenFactory: () => jwtToken
                })
                .withAutomaticReconnect()
                .build();

            connection.start()
                .then(() => {
                    console.log('QB SignalR Online');
                    setHubConnection(connection);
                    setStarted(true);
                    connection.on("Entities", setEntities);
                    connection.on("Reports", setReports);
                    connection.on("Steps", setSteps);
                    connection.on("DataArray", handleDataArray);
                    connection.on("DataValue", handleDataValue);
                    connection.on("SetTrainingLine", setJsonl);
                    connection.on("TrainingFileUpdate", setTrainingFile);
                    connection.on("AvailableOptions", setOutputOptions);
                    connection.on("OptionChanged", setOutputType);
                    connection.on("AllResults", setAllResults);
                    connection.on("TabValue", setTabValue);
                    connection.on("GptResponse", setGptResponse);
                    connection.on("TfLineCount", setTfLineCount);
                    connection.on("ChatModel", setChatModel);

                    connection.on("EditStep", handleEditStep);
                    connection.on("Exception", handleException);
                    connection.on("SelectedReport", handleSelectedReport);
                    connection.on("PackageResult", handlePackageResult);
                    connection.on("StepSelected", selectStep);

                    connection.on("InitialPrompt", setPrompt);
                    connection.on("SetErrorMessage", setErrorMessage);
                })
                .catch(error => {
                    console.error("SignalR connection error:", error);
                    if (error?.statusCode === 401 || error?.status === 401 || error.message === 'login_required') {
                        const currentAddress = `${window.location.pathname}${window.location.search}`;
                        window.location.href = `/login?redirect=${encodeURIComponent(currentAddress)}`;
                    }
                });

            return () => {
                if (hubConnection) {
                    hubConnection.off("Entities", setEntities);
                    hubConnection.off("Reports", setReports);
                    hubConnection.off("Steps", setSteps);
                    hubConnection.off("SetTrainingLine", setJsonl);
                    hubConnection.off("TrainingFileUpdate", setTrainingFile);
                    hubConnection.off("AvailableOptions", setOutputOptions);
                    hubConnection.off("OptionChanged", setOutputType);
                    hubConnection.off("AllResults", setAllResults);
                    hubConnection.off("TfLineCount", setTfLineCount);
                    hubConnection.off("ChatModel", setChatModel);

                    hubConnection.off("DataArray", handleDataArray);
                    hubConnection.off("DataValue", handleDataValue);
                    hubConnection.off("Exception", handleException);
                    hubConnection.off("SelectedReport", handleSelectedReport);
                    hubConnection.off("PackageResult", handlePackageResult);
                    hubConnection.off("SetErrorMessage", setErrorMessage);

                    hubConnection.stop();
                    setStarted(false);
                }
            };
        }
    }, [started, hubConnection, isAuthenticated, guid]);

    useEffect(() => {
        if (selectedStep && steps) {
            console.log(steps);
            const foundStep = steps.find(step => step.step_id === selectedStep.step_id);
            //selectStep(foundStep);
            setSelectedStep(foundStep);
        } else {
            if (steps.count > 0) {
                selectStep(steps[0]);
            }
        }
    }, [steps, selectedStep]);

    useEffect(() => {
        updateTransformerSteps();
    }, [transformerSteps]);

    useEffect(() => {
        if (selectedStep) {
            console.log('selectedStep', selectedStep);
            setEditStepId(selectedStep.step_id);
            if (selectedStep.query) {
                setQuery(selectedStep.query);
                setEntity(selectedStep.queryEntity);
                setTabValue(1);
            } else {
                setQuery('');
                setEntity('');
            }
            if (selectedStep.transformer_operations) {
                console.log('selectedStep.transformer_operations', selectedStep.transformer_operations);
                console.log('number 2');
                setTransformerSteps(selectedStep.transformer_operations);
            } else {
                setTransformerSteps([]);
            }
            if (selectedStep.run_if_true) {
                console.log('selectedStep.run_if_true', selectedStep.run_if_true);
                setRunIfTrue(selectedStep.run_if_true);
            } else {
                setRunIfTrue([]);
            }
            if (selectedStep.production_type) {
                console.log('selectedStep.production_type', selectedStep.production_type);
                setOutputType(selectedStep.production_type);
            } else {
                setOutputType('');
            }
            if (selectedStep.transformer_output) {
                console.log('selectedStep.transformer_output', selectedStep.transformer_output);
                setTransformer(selectedStep.transformer_output);
            } else {
                setTransformer('');
            }
            if (selectedStep.returnMe) {
                console.log('selectedStep.returnMe', selectedStep.returnMe);
                setFinalStep(selectedStep.returnMe);
            } else {
                setFinalStep(false);
            }
        }
    }, [selectedStep]);

    //#endregion


    //#region SignalR Outbound

    function executePrompt() {
        setBusy(true);
        if (hubConnection) {
            hubConnection.invoke("ExecutePrompt", sessionId, prompt)
                .then(() => {
                })
                .catch(error => console.error("Error sending report request:", error));
        }
    }

    function runQuery() {
        if (hubConnection) {

            console.groupEnd();
            const transformation = {
                step_id: selectedStep.step_id,
                system: "Quickbooks",
                operation_type: "Query",
                transformer_operations: [],
                run_if_true: [],
                queryEntity: entity,
                query: query,
                returnMe: false,
                name: selectedStep.name
            };

            transformerSteps.forEach((step, index) => {
                if (step.variable && step.lambda) {
                    transformation.transformer_operations.push({ Variable: step.variable, Lambda: step.lambda });
                }
            });

            runIfTrue.forEach((step, index) => {
                if (step.variable && step.lambda) {
                    transformation.run_if_true.push({ Variable: step.variable, Lambda: step.lambda });
                }
            });

            hubConnection.invoke("AddTransformer", sessionId, transformation)
                .then(() => {

                })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    function runReport() {
        if (hubConnection) {
            hubConnection.invoke("RunReport", sessionId, report, reportParameters)
                .then(() => {
                })
                .catch(error => console.error("Error sending report request:", error));
        }
    }

    function botAssist() {
        if (hubConnection) {
            hubConnection.invoke("BotAssist", sessionId)
                .then(() => {
                    console.log('Bot Assist Started');
                })
                .catch(error => console.error("Error sending report request:", error));
        }
    }

    function reset() {
        if (hubConnection) {
            hubConnection.invoke("Reset", sessionId)
                .then(() => {
                    setEntity('Item');
                    setEntity('Account');
                    setRows([]);
                })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    function editStep(stepId) {
        if (hubConnection) {
            hubConnection.invoke("EditStep", sessionId, stepId)
                .then(() => {
                })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    function updateStep(step) {
        console.log('updateStep', step);
        if (hubConnection) {
            hubConnection.invoke("UpdateStep", sessionId, step)
                .then(() => {
                })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    function deleteStep(stepId) {
        if (hubConnection) {
            hubConnection.invoke("DeleteStep", sessionId, stepId)
                .then(() => {
                })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    function runTest() {
        if (hubConnection) {
            hubConnection.invoke("RunTest", sessionId)
                .then(() => {
                })
                .catch(error => console.error("Error Running Test:", error));
        }
    }

    function handleReportChange(event) {
        if (hubConnection) {
            hubConnection.invoke("ReportSelected", sessionId, event.target.value)
                .then(() => {
                })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    function updatePrompt(event) {
        let eventData;
        if (event.target) {
            eventData = event.target.value;
        }
        else {
            eventData = event;
        }
        setPrompt(eventData);
        if (hubConnection) {
            hubConnection.invoke("SetPrompt", sessionId, eventData)
                .then(() => {
                })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    function addStep(stepType) {
        const newStep = {
            operation_type: stepType,
            id: uuidv4(),
            name: `New ${stepType}`,
            system: 'Quickbooks'
        };

        if (hubConnection) {
            hubConnection.invoke("AddStep", sessionId, newStep)
                .then(() => {

                })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    function updateTransformerSteps() {
    }

    function trainNow() {
        if (hubConnection) {
            hubConnection.invoke("TrainModel", sessionId)
                .then(() => {

                })
                .catch(error => console.error("Error sending message:", error));
        }
    }
    //TrainModel

    //#endregion

    //#region SignalR Inbound

    function handleDataArray(data) {
        console.log('handleDataArray', data);
        let jsonObject = JSON.parse(data);
        setRows(jsonObject);

        // Generate columns based on the keys in the first row of data
        if (jsonObject.length > 0) {
            const newColumns = Object.keys(jsonObject[0]).map((key) => ({
                field: key,
                headerName: key, // You can customize the header name if needed
                width: 150, // You can customize the width as per your requirements
                editable: true, // If you want columns to be editable
            }));

            setColumns(newColumns);
        }
        else {
            setColumns([]);
        }
    }

    function handleDataValue(data) {
        let jsonObject = JSON.parse(data);
        console.log('data', jsonObject);

        const transformedColumns = jsonObject?.Columns?.map((column) => ({
            field: column.ColTitle.replace(/\s+/g, '_'), // Replace spaces with underscores
            headerName: column.ColTitle,
            width: 150, // Set the width as needed
        }));

        const transformedRows = jsonObject?.Rows?.map((row, index) => {
            const rowData = {
                id: index + 1, // Start id from 1
            };

            row.AnyIntuitObjects.forEach((obj) => {
                if (obj.ColData) {
                    obj.ColData.forEach((colData) => {
                        const fieldName = colData.value.replace(/\s+/g, '_'); // Replace spaces with underscores
                        rowData[fieldName] = parseFloat(colData.value) || colData.value;
                    });
                }
            });

            return rowData;
        });

        // Set the transformed columns and rows in state
        setReportColumns(transformedColumns);
        setReportRows(transformedRows);
    }

    function handleEditStep(data) {
        console.log('handleEditStep', data);
        setEditStepId(data.step_id);

        // IF SOMETHING IS BROKEN, THEN CHANGE SELECTED STEP.  I"M WORRIED IT WILL BE RECURSIVE AT THE MOMENT.

        // if (data.query) {
        //     setQuery(data.query);
        //     setEntity(data.queryEntity);
        //     setTabValue(0);
        //     return;
        // }
        // if (data.transformer_operations) {
        //     console.log('number 1');
        //     setTransformerSteps(data.transformer_operations);
        // }
        // if (data.transformer_output) {
        //     setTransformer(data.transformer_output);
        // }
    }

    function handleException(data) {
        setTone('error');
        setSnackMessage(data);
        setOpenSnack(true);
    }

    function handleMessage(data) {
        setTone('success');
        setSnackMessage(data);
        setOpenSnack(true);
    }

    function handleSelectedReport(reportName, reportProperties) {
        setReport(reportName);
        setReportParams(reportProperties);
    }

    function handlePackageResult(result) {
        console.log('handlePackageResult', result);
        setPackageResult(result);
        // try {
        //     // Assuming result is already an object and has a property 'payload'
        //     // which is a JSON string that needs to be parsed.
        //     if (result) {
        //         const parsedPayload = JSON.parse(resultcontent);
        //         setPackageResult(parsedPayload);
        //     } else {
        //         console.error("Payload is missing or not a string");
        //     }
        // } catch (error) {
        //     console.error("Error parsing payload JSON:", error);
        //     // Handle the error as needed, e.g., set an error state.
        // }
    }




    //#endregion

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };

    const handleParameterChange = (paramName, paramValue) => {
        setReportParameters({
            ...reportParameters,
            [paramName]: paramValue,
        });
    };

    function stopExecution() {
        setBusy(false);
    }

    function selectStep(stepId) {
        console.log('stepId', stepId);
        let step = steps.find(s => s.step_id == stepId);
        if (step) {
            console.log('step', step);
            setSelectedStep(step);
        }
        else {
            setSelectedStep(null);
        }
    }

    function handleChange(event) {
        setEntity(event.target.value);
        setQuery(`SELECT * FROM ${event.target.value}`);
        // fixIt(event.target.value);
    }

    return (
        <div className="qb_top_row">
            <QbStatus
                hubConnection={hubConnection}
            />
            <QbError
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
            />
            <QbTabBar
                tabValue={tabValue}
                setTabValue={setTabValue}
                a11yProps={a11yProps} />
            <TabPanel value={tabValue} index={0}>
                <QbPrompt
                    updatePrompt={updatePrompt}
                    prompt={prompt}
                    busy={busy}
                    stopExecution={stopExecution}
                    executePrompt={executePrompt}
                    reset={reset}
                    gptResponse={gptResponse} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <QuickbooksSteps
                    steps={steps}
                    editStep={editStep}
                    deleteStep={deleteStep}
                    selectedStep={selectedStep}
                    selectStep={selectStep}
                    updateStep={updateStep}
                    addStep={addStep}
                    entity={entity}
                    entities={entities}
                    handleChange={handleChange}
                    columns={columns}
                    setEntity={setEntity}
                    setQuery={setQuery}
                    query={query}
                    reset={reset}
                    rows={rows}
                    runQuery={runQuery}
                    hubConnection={hubConnection}
                    sessionId={sessionId}
                    outputOptions={outputOptions}
                    outputType={outputType}
                    allResults={allResults}
                    setTransformer={setTransformer}
                    setTransformerSteps={setTransformerSteps}
                    transformerSteps={transformerSteps}
                    transformer={transformer}
                    runIfTrue={runIfTrue}
                    setRunIfTrue={setRunIfTrue}
                    finalStep={finalStep}
                    setFinalStep={setFinalStep}
                    handleMessage={handleMessage}
                    botAssist={botAssist} />
            </TabPanel>

            {/* <TabPanel value={tabValue} index={9}>
                <QuickbooksReport
                    reportColumns={reportColumns}
                    handleReportChange={handleReportChange}
                    report={report}
                    reports={reports}
                    reset={reset}
                    reportParams={reportParams}
                    reportRows={reportRows}
                    handleParameterChange={handleParameterChange}
                    runReport={runReport} />
            </TabPanel> */}
            <TabPanel value={tabValue} index={2}>
                <QbTester
                    packageResult={packageResult}
                    runTest={runTest} />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
                <QbTraining
                    hubConnection={hubConnection}
                    sessionId={sessionId}
                    prompt={prompt}
                    updatePrompt={updatePrompt}
                    jsonl={jsonl}
                    trainNow={trainNow}
                    tfLineCount={tfLineCount}
                    chatModel={chatModel}
                />
            </TabPanel>
            <TabPanel value={tabValue} index={6}>
                <QbTrainingFile
                    hubConnection={hubConnection}
                    sessionId={sessionId}
                    trainingFile={trainingFile} />
            </TabPanel>

            <Snackbar open={openSnack} autoHideDuration={10000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={tone} sx={{ width: '100%' }}>
                    {snackMessage}
                </Alert>
            </Snackbar>

        </div>
    )
}

export default Quickbooks; 