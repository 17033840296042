import React from 'react';
import { Tab, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CustomTab = ({ label, onClose, ...props }) => {
    return (
        <Tab
            component="div"
            label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{label}</span>
                    <IconButton
                        size="small"
                        onClick={onClose}
                        style={{ marginLeft: '5px' }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </div>
            }
            {...props}
        />
    );
};

export default CustomTab;
