import { TreeItem } from "@mui/x-tree-view";
import EditableField from "./EditableField";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBooksMedical, faDeleteLeft, faTrash } from "@fortawesome/pro-solid-svg-icons";

// Assuming onDelete is a new prop for handling deletion
function HitchMenuItem({ nodeId, label, initialValue, onSave, onDelete, onChild, isEditable, isDeleteable, addChild, children }) {
  return (
    <TreeItem
      nodeId={nodeId}
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isEditable ? (
            <EditableField label={label} initialValue={initialValue} onSave={onSave} />
          ) : (
            <span>{label}</span>
          )}
          {isDeleteable && (<IconButton size="small" onClick={onDelete}>
            <FontAwesomeIcon icon={faTrash} style={{ color: 'white' }} />
          </IconButton>)}
          {addChild && (<IconButton size="small" onClick={onChild}>
            <FontAwesomeIcon icon={faBooksMedical} style={{ color: 'white' }} />
          </IconButton>)}
        </div>
      }
    >
      {children}
    </TreeItem>
  );
}

export default HitchMenuItem;
