import React, { useState, useEffect } from 'react';
import { Button, IconButton } from "@mui/material";
import { Close as CloseIcon, Save as SaveIcon, Create as CreateIcon, Cancel as CancelIcon, UnfoldMore as UnfoldMoreIcon, UnfoldLess as UnfoldLessIcon, Menu as MenuIcon } from '@mui/icons-material';
import { useHubConnections } from "../../utils/HubConnectionsProvider";
import { useModal } from '../../utils/HitchModalContext/HitchModalContext';
import OperationSelectionModel from "../OperationSelectionModal/OperationSelectionModal"; // Ensure this path is correct
import BotOperations from './BotOperations';
import BotGenerationsSettings from './BotGenerationsSettings';
import BotFileManagement from './BotFileManagement';
import BotMetadata from './BotMetadata';
import BotBasics from './BotBasics';
import BotConfiguration from './BotConfiguration';
import BotOrgChartStyles from './BotOrgChartStyles';


function BotOrgChartDetail({ botId, onClose }) {
    const { dashboardConnection, isConnectionReady, hitchDashCommand } = useHubConnections();
    const [bot, setBot] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState({ temperature: "1.0", topP: "1.0" });
    const [open, setOpen] = useState(false);
    const [menuVisible, setMenuVisible] = useState(true);
    const { requestConfirm } = useModal();
    const [functions, setFunctions] = useState([]);
    const [models, setModels] = useState([]);
    const [allowAnonymous, setAllowAnonymous] = useState(false); // Renamed from botEnabled
    const [jsonMode, setJsonMode] = useState(false);
    const [deployCode, setDeployCode] = useState(''); // New deploy code state

    // fileSearch is "useVectorStore"
    const [vectorStores, setVectorStores] = useState([]);
    const [selectedOption, setSelectedOption] = useState('temperature');
    const [allExpanded, setAllExpanded] = useState(true); // New state for expand/collapse all

    const {
        bottomRowStyle,
        buttonStyle,
        themePrimaryTextColor,
        themePrimaryButtonColor,
        buttonStyleSecondary,
        deleteButtonStyle,
        dividerStyle,
        titleStyle,
        buttonContainerStyle,
        formFields,
        modalStyle,
        headerStyle,
        headerRowStyle,
        thirdRowStyle,
        expandCollapseButtonStyle,
        bodyStyle,
        menuStyle,
        menuListStyle,
        menuItemStyle,
        menuLinkStyle,
        contentStyle
    } = BotOrgChartStyles();

    // Individual states for each accordion
    const [expandedSection, setExpandedSection] = useState({
        section1: true,
        section2: true,
        section3: true,
        section4: true,
        section5: true,
        section6: true,
    });

    useEffect(() => {
        if (bot?.functions) {
            const initialFunctions = bot.functions.map(funcObj => ({
                ...(funcObj.function && { name: funcObj.function.name, ...funcObj.function })
            })).filter(funcObj => funcObj.name);
            setFunctions(initialFunctions);
        } else {
            setFunctions([]);
        }
    }, [bot?.functions]);

    useEffect(() => {
        if (bot) {
            setAllowAnonymous(bot.allowAnonymous || false);
            setDeployCode(bot.deployCode || '');
            console.log('bot', bot);
        }
    }, [bot]);

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on("ab183cbd-c1a8-4bf3-a264-c8fa7d58660c", setVectorStores);
            dashboardConnection.on("b9704db3-9b31-4509-824f-4fea33241c5e", setTheModels);
            dashboardConnection.on("6ed4f39c-66fe-491a-8a06-aab443a31555", (data) => {
                setBot(data);
                setEditData({
                    ...data,
                    assistantId: data.openAiAssistantId,
                    temperature: data.temperature || "1.0",
                    topP: data.topP || "1.0"
                });
            });
            callForBot();

            function callForBot() {
                if (dashboardConnection) {
                    hitchDashCommand('chat', 'bot', 'get', { theBotId: botId });
                    hitchDashCommand('openai', 'models', 'list', {});
                    hitchDashCommand('document', 'repo', 'list', {});
                }
            }
        }

        return () => {
            if (dashboardConnection) {
                dashboardConnection.off("ab183cbd-c1a8-4bf3-a264-c8fa7d58660c", setVectorStores);
                dashboardConnection.off("b9704db3-9b31-4509-824f-4fea33241c5e", setTheModels);
                dashboardConnection.off("6ed4f39c-66fe-491a-8a06-aab443a31555");
            }
        };
    }, [botId, dashboardConnection, hitchDashCommand, isConnectionReady]);

    function setTheModels(data) {
        setModels(data.data);
    }

    const handleSliderChange = (event, newValue) => {
        setEditData(prevData => ({ ...prevData, temperature: newValue.toString() }));
    };

    const handleTopPSliderChange = (event, newValue) => {
        setEditData(prevData => ({ ...prevData, topP: newValue.toString() }));
    };

    const handleSave = () => {
        const updatedFunctions = functions.map(func => ({
            type: 'function',
            function: {
                description: func.description,
                name: func.name,
                parameters: {
                    type: 'object',
                    properties: func.parameters.properties,
                    required: func.parameters.required
                }
            }
        }));

        const updatedEditData = {
            ...editData,
            functions: updatedFunctions,
            allowAnonymous,
            deployCode, // Save deployCode with the bot
            jsonMode,
        };

        console.log('updatedEditData', updatedEditData);

        if (dashboardConnection && isConnectionReady) {
            setBot(updatedEditData);
            setEditMode(false);
            hitchDashCommand('chat', 'bot', 'update', { theBot: updatedEditData });
        }
    };

    function removeFunction(functionName) {
        if (!functionName) return;
        setFunctions(currentFunctions => currentFunctions.filter(func => func.name !== functionName));
    }

    function onSelectedOperation(service, feature, operation) {
        if (dashboardConnection && isConnectionReady) {
            hitchDashCommand('chat', 'bot', 'attach-operation', {
                botId: bot.id,
                service: service,
                feature: feature,
                operation: operation
            });
        }
    }

    const handleCancel = () => {
        // console.log('bot cancelled', bot);
        setEditData(bot);
        setEditMode(false);
    };

    const handleRevert = () => {
        // console.log('bot revert', bot);
        setEditData(bot);
    };

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleDelete = () => {
        requestConfirm("Are you sure? Like delete this bot??", () => {
            confirmDelete();
        });
    };

    const confirmDelete = () => {
        if (dashboardConnection) {
            hitchDashCommand('chat', 'bot', 'delete', { theBotId: bot.id }, () => {
                // handle post delete actions here if necessary
            });
        }
    }

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (type === "checkbox") {
            setEditData((prevData) => {
                let currentFunctions = new Set(prevData.functions || []);
                if (checked) {
                    currentFunctions.add(name);
                } else {
                    currentFunctions.delete(name);
                }
                return { ...prevData, functions: Array.from(currentFunctions) };
            });
        } else {
            setEditData((prevData) => ({ ...prevData, [name]: value }));
        }
    };

    const handleAllowAnonymousChange = (event) => {
        setAllowAnonymous(event.target.checked);
    };

    const handleDeployCodeChange = (event) => {
        const newValue = event.target.value;
        setDeployCode(newValue);  // Update the local state
        setEditData(prevData => ({ ...prevData, deployCode: newValue }));  // Ensure it reflects in editData
    };
    

    const handleJsonModeChange = (event) => {
        setJsonMode(event.target.checked);
    };

    const handleFileSearchChange = (event) => {
        setEditData(prevState => ({
            ...prevState,
            fileSearch: event.target.checked
        }));
    };

    const handleVectorStoreChange = (event) => {
        setEditData(prevState => ({
            ...prevState,
            selectedVectorStore: event.target.value
        }));
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };



    const handleExpandCollapseAll = () => {
        const newExpandedState = !allExpanded;
        setAllExpanded(newExpandedState);
        setExpandedSection({
            section1: newExpandedState,
            section2: newExpandedState,
            section3: newExpandedState,
            section4: newExpandedState,
            section5: newExpandedState,
            section6: newExpandedState,
        });
    };

    const toggleMenuVisibility = () => {
        setMenuVisible(!menuVisible);
    };


    useEffect(() => {
        const anchors = document.querySelectorAll('.modal-menu a');
        anchors.forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                const target = document.querySelector(this.getAttribute('href'));
                target.scrollIntoView({ behavior: 'smooth' });
            });
        });

        return () => {
            anchors.forEach(anchor => {
                anchor.removeEventListener('click', function (e) {
                    e.preventDefault();
                    const target = document.querySelector(this.getAttribute('href'));
                    target.scrollIntoView({ behavior: 'smooth' });
                });
            });
        };
    }, []);

    return (
        <div style={modalStyle}>
            <div style={headerStyle}>
                <div style={headerRowStyle}>
                    <h2 style={titleStyle}>Manage Bot</h2>
                    <IconButton onClick={onClose} style={{ color: themePrimaryTextColor }}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div style={bottomRowStyle}>
                    <div style={buttonContainerStyle}>
                        {editMode ? (
                            <>
                                <Button variant="outlined" onClick={handleRevert} style={buttonStyleSecondary}>
                                    <CancelIcon fontSize="smaller" /> Revert
                                </Button>
                                <Button variant="outlined" onClick={handleCancel} style={buttonStyleSecondary}>
                                    <CancelIcon fontSize="smaller" /> Cancel
                                </Button>
                                <Button variant="outlined" onClick={handleSave} style={buttonStyle}>
                                    <SaveIcon fontSize="smaller" /> Save
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button variant="outlined" onClick={handleDelete} style={deleteButtonStyle}>
                                    <CancelIcon fontSize="smaller" /> Delete
                                </Button>
                                <Button variant="outlined" onClick={handleEdit} style={buttonStyle}>
                                    <CreateIcon fontSize="smaller" /> Edit
                                </Button>
                            </>
                        )}
                    </div>
                </div>
                <div style={thirdRowStyle}>
                    <IconButton onClick={toggleMenuVisibility} style={{ color: themePrimaryTextColor }}>
                        <MenuIcon />
                    </IconButton>
                    <Button
                        variant="outlined"
                        onClick={handleExpandCollapseAll}
                        endIcon={allExpanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                        style={expandCollapseButtonStyle}
                    >
                        {allExpanded ? 'Collapse All' : 'Expand All'}
                    </Button>
                </div>
            </div>
            <div style={bodyStyle}>
                {menuVisible && (
                    <div className="modal-menu" style={menuStyle}>
                        <ul style={menuListStyle}>
                            <li style={menuItemStyle}><a href="#section1" style={menuLinkStyle}>Bot Basics</a></li>
                            <li style={menuItemStyle}><a href="#section2" style={menuLinkStyle}>Bot Configuration</a></li>
                            <li style={menuItemStyle}><a href="#section3" style={menuLinkStyle}>Generation Settings</a></li>
                            <li style={menuItemStyle}><a href="#section4" style={menuLinkStyle}>Operations</a></li>
                            <li style={menuItemStyle}><a href="#section5" style={menuLinkStyle}>File Management</a></li>
                            <li style={menuItemStyle}><a href="#section6" style={menuLinkStyle}>Metadata</a></li>
                        </ul>
                    </div>
                )}
                <div style={contentStyle}>
                    <BotBasics
                        bot={bot}
                        allowAnonymous={allowAnonymous}
                        deployCode={deployCode}
                        editData={editData}
                        editMode={editMode}
                        expandedSection={expandedSection}
                        handleAllowAnonymousChange={handleAllowAnonymousChange}
                        handleDeployCodeChange={handleDeployCodeChange}
                        handleChange={handleChange}
                        setExpandedSection={setExpandedSection}
                    />

                    <div style={dividerStyle}></div>
                    <BotConfiguration
                        bot={bot}
                        editData={editData}
                        editMode={editMode}
                        expandedSection={expandedSection}
                        formFields={formFields}
                        handleChange={handleChange}
                        handleJsonModeChange={handleJsonModeChange}
                        jsonMode={jsonMode}
                        models={models}
                        setExpandedSection={setExpandedSection} />

                    <div style={dividerStyle}></div>
                    <BotGenerationsSettings
                        bot={bot}
                        editData={editData}
                        editMode={editMode}
                        expandedSection={expandedSection}
                        formFields={formFields}
                        handleChange={handleChange}
                        handleOptionChange={handleOptionChange}
                        handleSliderChange={handleSliderChange}
                        handleTopPSliderChange={handleTopPSliderChange}
                        setExpandedSection={setExpandedSection}
                        selectedOption={selectedOption}
                        themePrimaryButtonColor={themePrimaryButtonColor}
                    />

                    <div style={dividerStyle}></div>
                    <BotOperations
                        editMode={editMode}
                        expandedSection={expandedSection}
                        functions={functions}
                        removeFunction={removeFunction}
                        setExpandedSection={setExpandedSection}
                        setOpen={setOpen}
                    />

                    <div style={dividerStyle}></div>
                    <BotFileManagement
                        editMode={editMode}
                        expandedSection={expandedSection}
                        editData={editData}
                        formFields={formFields}
                        handleFileSearchChange={handleFileSearchChange}
                        handleVectorStoreChange={handleVectorStoreChange}
                        setExpandedSection={setExpandedSection}

                        vectorStores={vectorStores}
                    />

                    <div style={dividerStyle}></div>
                    <BotMetadata
                        bot={bot}
                        expandedSection={expandedSection}
                        setExpandedSection={setExpandedSection} />

                    <OperationSelectionModel open={open} setOpen={setOpen} selected={onSelectedOperation} />

                </div>
            </div>
        </div>
    );
}

export default BotOrgChartDetail;
