import React, { useState, useEffect } from 'react';
import {
    Stack,
    Button,
    Typography,
    Box,
} from '@mui/material';
import houseArrowUpImage from './marlin.png';
import './affiliate2.css';
import ErrorBoundary from '../../../utils/ErrorBoundary';
import NavigationBar from '../../naviagationbar/NavigationBar';
import ChatDrawer from '../../chatdrawer/chatdrawer'; // Import ChatDrawer

function LandingPage() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0); // Define activeIndex state
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const openDrawer = () => {
        setDrawerOpen(true);
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
    };

    // Define recommendations array
    const recommendations = [
        {
            text: "In the world of business, being well-connected means having Hitch.AI in your toolkit. With it, I'm setting trends, not just following them. Financial analysis has become like reading comic strips. And with Hitch.AI, I'm governing my success—a true capital gain.",
            name: 'Marlin "The Governor" Hutchens',
            position: "Owner of Owners Being Owners"
        },
        // Add more recommendation objects as needed
    ];


    return (
        <Box bgcolor="#000" style={{ position: 'relative', zIndex: 1 }}>
            <header style={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 9999 }}>
                <Box sx={{
                    maxWidth: '1584px',
                    margin: '0 auto', // Centers the navigation bar
                    backgroundColor: '#000', // Match the header background if needed
                }}>
                    <ErrorBoundary fallback={<p>Something went wrong</p>}>
                        <NavigationBar />
                    </ErrorBoundary>
                </Box>
            </header>

            <Stack sx={{
                maxWidth: '1584px',
                margin: '64px auto 0',
                overflow: 'hidden',
                position: 'relative',
                zIndex: 1
            }}>
                <Stack
                    bgcolor="#fff"
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={1}
                    justifyContent={{ xs: 'flex-start', sm: 'space-evenly' }}
                    sx={{
                        marginBottom: '48px',
                        padding: 0,
                        overflow: 'hidden',
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    <Box
                        bgcolor="white"
                        flex={5}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="left"
                        sx={{
                            width: '100%', // Take full width on mobile devices
                            height: { xs: 'auto', sm: '840px' }, // Use auto height for mobile to accommodate content
                            paddingX: { xs: 1, sm: 0, md: 0 }, // Adjust padding for different screen sizes
                            paddingY: { xs: 1, sm: 0, md: 0 },
                        }}
                    >
                        <Box
                            bgcolor="white"
                            width="100%"
                            height={{ xs: 'auto', sm: '840px' }} // Responsive height
                            display="flex"
                            justifyContent="center"
                            alignItems="left"
                            overflow="hidden"
                            sx={{
                                // This maintains the container size but allows for image size adjustments
                                '& img': {
                                    width: { sm: '100%' }, // For devices larger than the xs breakpoint, use full width
                                    height: 'auto', // Maintain aspect ratio
                                    objectFit: 'cover', // Cover the container
                                    // Apply a max-height on mobile devices to prevent the image from being too large
                                    maxHeight: { xs: '40vh', sm: 'none' },
                                },
                            }}
                        >
                            <img
                                src={houseArrowUpImage}
                                alt="Affiliate"
                                style={{
                                    maxWidth: '100%', // Ensures the image is not wider than the box on all devices
                                    height: 'auto', // Adjust height automatically to maintain aspect ratio
                                    objectFit: 'cover', // Ensures the image covers the box
                                }}
                            />
                        </Box>

                    </Box>
                    <Box
                        bgcolor="white"
                        flex={5}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="left"
                        paddingX={{ xs: 1, sm: 2, md: 2 }}
                        paddingY={{ xs: 2, sm: 4, md: 4 }} // Adjusted padding for sm breakpoint
                        sx={{
                            maxHeight: '100%',
                            overflow: 'hidden',
                        }}
                    >
                        <Typography
                            variant="h1"
                            sx={{
                                fontWeight: 'bold',
                                fontFamily: '"Roboto Mono", monospace',
                                fontSize: { xs: '32px', sm: '32px', md: '48px' },
                                marginBottom: { xs: '24px', sm: '48px' }, // Reduced marginBottom on xs screens
                                color: '#000',
                                textAlign: 'center', // Ensure text is centered
                            }}
                        >
                            Recommended by <br />{recommendations[activeIndex].name}
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: { xs: '16px', sm: '16px', md: '24px' },
                                fontFamily: '"Courier New", Courier, monospace',
                                color: '#000',
                                marginBottom: '48px', // You can also adjust this if needed
                                marginLeft: '48px',
                                marginRight: '48px',
                            }}
                        >
                            "{recommendations[activeIndex].text}"
                        </Typography>
                        <Button
                            variant="contained"
                            color="seafoamGreen"
                            sx={{
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: '600',
                                fontSize: '18px',
                                textTransform: 'none',
                                width: { xs: '184px', md: "179px" },
                                height: { xs: '64px', md: "60px" },
                                borderRadius: '8px',
                                marginBottom: { xs: '64px', md: '0px' },
                                color: '#4A4A68',
                            }}
                            onClick={openDrawer} // Adjust this line
                        >
                            Learn More
                        </Button>

                        {/* Render the ChatDrawer component */}
                        <ChatDrawer isOpen={drawerOpen} onClose={closeDrawer} />
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
}

export default LandingPage;
