import React, { useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Box, Typography } from '@mui/material';

const Pill = ({ text, color }) => (
    <Box sx={{
        display: 'inline-flex',
        bgcolor: color,
        p: 1,
        borderRadius: '999px',
        m: 1,
        color: '#fff' // Assuming a dark text color for better contrast
    }}>
        {text}
    </Box>
);

const FormulaBuilder = () => {
    const [items, setItems] = useState([]); // This would be your pills

    const onDragEnd = (result) => {
        // Handle dropping items onto the canvas
        // Add the item to the `items` state, setting the color based on its hierarchy
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="canvas">
                {(provided) => (
                    <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        sx={{
                            minHeight: 'calc(100vh - 100px)', // Adjust the 100px based on your AppBar's height and any other paddings/margins
                            bgcolor: '#f0f0f0',
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center', // This centers the pills vertically, remove if not desired
                            alignItems: 'center', // This centers the pills horizontally
                        }}
                    >
                        {items.length ? items.map((item, index) => (
                            <Pill key={index} text={item.text} color={item.color} />
                        )) : <Typography variant="subtitle1">Drag and drop items here</Typography>}
                        {provided.placeholder}
                    </Box>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default FormulaBuilder;
