import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import planData from './planData.json';
import usageData from './usageData.json';
import SubscriptionWizard from '../../../Registration/RegistrationWizard';
import PaymentMethod from './PaymentMethod';
import { useHubConnections } from '../../../utils/HubConnectionsProvider';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useSettingsStyles } from '../common/SettingsStyleProvider';
import SettingsMenu from '../SettingsMenu/SettingsMenu';

function ManagePlan() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [daysLeftInTrial, setDaysLeftInTrial] = useState(0);
  const [data, setData] = useState(planData);
  const [usage, setUsage] = useState(usageData); // Usage data state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const {
    font,
    themeBackgroundColor,
    themePrimaryTextColor,
    supportStyle,
    boxStyle,
    buttonStyle,
    buttonStyleSecondary,
    dividerStyle,
    headerStyle,
    titleStyle,
    buttonContainerStyle,
    setIsDrawerOpen,
    defaultOutlineStyle,
    activeOutlineStyle,
    errorOutlineStyle,
    initialState,
    containerStyle,
    helperTextStyle,
    fixedWidthStyle
  } = useSettingsStyles();

  const { hitchUser, dashboardConnection, isConnectionReady, hitchDashCommand } = useHubConnections();

  //const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);

  const drawerWidth = 240; // Adjust to match your drawer's width
  //const marginLeft = isDrawerOpen && isDesktop ? drawerWidth : 0;

  useEffect(() => {
    if (!isMobile) {
      setIsDrawerOpen(true);  // Open the drawer when not in mobile view
    }
  }, [isMobile, setIsDrawerOpen]);
  
  useEffect(() => {
    if (dashboardConnection && isConnectionReady) {
      dashboardConnection.on("fdaa57de-b6c3-440b-b98d-aac406d4a131", something);

      function callForPlan() {
        console.log('Calling for User Profile');
        hitchDashCommand('squareup', 'subscriptions', 'activesubscription', {
          theAccountId: hitchUser.accountId
        });
      }

      callForPlan();

      return () => {
        if (dashboardConnection) {
          dashboardConnection.off("fdaa57de-b6c3-440b-b98d-aac406d4a131", something);
        }
      };
    }
  }, [dashboardConnection, isConnectionReady]);

  function something(data) {
    console.log('data', data);
    //setData(data);  TODO: put back for real data
  }

  const themeStyles = {
    backgroundColor: theme.palette.secondary[theme.palette.mode],
    primaryTextColor: theme.palette.primaryText[theme.palette.mode],
    secondaryTextColor: theme.palette.secondaryText[theme.palette.mode],
    primaryButtonColor: theme.palette.primaryButton[theme.palette.mode],
    primaryButtonTextColor: theme.palette.primaryButtonText[theme.palette.mode],
    secondaryButtonColor: theme.palette.secondaryButton[theme.palette.mode],
    secondaryButtonTextColor: theme.palette.secondaryButtonText[theme.palette.mode],
    secondaryButtonBorderColor: theme.palette.secondaryButtonBorder[theme.palette.mode],
    secondaryDividerColor: theme.palette.secondaryDivider[theme.palette.mode],
    textboxOutlineColor: theme.palette.textboxOutline[theme.palette.mode],
    textboxOutlineHoverColor: theme.palette.textboxOutline.hover[theme.palette.mode],
    textboxOutlineErrorColor: theme.palette.textboxOutline.error[theme.palette.mode],
    linkColor: theme.palette.link[theme.palette.mode],
  };

  const styles = {
    closeButton: {
      position: 'absolute',
      top: '8px',
      right: '8px',
      color: 'white',
    },
    
    title: {
      textTransform: 'uppercase',
      fontSize: '16px',
      marginBottom: '8px',
      fontFamily: '"Poppins", sans-serif',
    },
    subtitle: {
      fontSize: '14px',
      fontWeight: 'normal',
      color: themeStyles.secondaryTextColor,
      marginTop: '8px',
      marginBottom: '4px',
      fontFamily: '"Poppins", sans-serif',
    },
    detailItem: {
      marginBottom: '4px',
      marginLeft: '8px',
    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    flexContainerCenter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '16px',
      '@media (max-width: 768px)': {
        flexDirection: 'column',
      },
    },
    divider: {
      borderTop: '1px solid #26272B',
      margin: '16px 8px',
    },
    dividerBold: {
      borderTop: '3px solid #26272B',
      margin: '16px 8px',
    },
    
    button: {
      backgroundColor: '#00EDED',
      color: 'black',
      height: '28px',
      padding: '4px 10px',
      fontSize: '0.75rem',
      fontFamily: '"Poppins", sans-serif',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      '@media (max-width: 768px)': {
        height: '28px',
      },
    },
    link: {
      color: themeStyles.linkColor,
      cursor: 'pointer',
      marginLeft: '8px',
      textDecoration: 'none',
      fontFamily: '"Poppins", sans-serif',
    },
    listItem: {
      marginBottom: '4px',
      marginLeft: '8px',
      fontFamily: '"Poppins", sans-serif',
    },
    flexRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    rightAlign: {
      textAlign: 'right',
    },
    flexItem: {
      flex: 1,
      marginRight: '8px',
    },
    dialog: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'radial-gradient(circle, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.9))',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1100,
      overflowY: 'auto',
    },
    dialogTitle: {
      background: 'black',
      color: 'white',
      fontFamily: '"Poppins", sans-serif',
    },
    dialogContent: {
      background: 'black',
      color: 'white',
      fontFamily: '"Poppins", sans-serif',
    },
    paperProps: {
      backgroundColor: 'black',
      color: 'white',
      borderRadius: '10px',
      border: '1px solid white',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      maxWidth: '600px',
      width: isMobile ? '95%' : '80%',
      margin: 'auto',
      position: 'relative',
      maxWidth: '1500px',
      minWidth: '300px',
      fontFamily: '"Poppins", sans-serif',
    },
    innerDiv: {
      padding: '20px',
      backgroundColor: 'black',
      color: 'white',
    },
  };

  const displayName = data.freeTrial ? `${data.planName} (Trial)` : data.planName;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const handleChangePlanMethod = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);
  const openDialog = () => setIsDialogOpen(true);

  return (
    <Box sx={{ display: 'flex' }}>
      <SettingsMenu hitchUser={hitchUser} isDrawerOpen={drawerOpen} setIsDrawerOpen={setDrawerOpen} />
      <Box sx={{ flexGrow: 1, marginLeft: !isMobile && drawerOpen ? '240px' : '0px' }}>
        {isMobile && !drawerOpen && (
          <IconButton
            onClick={() => setDrawerOpen(true)}
            size="small"
            sx={{
              color: 'white',
              backgroundColor: '#18181B',
              opacity: 0.8,
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
              margin: '10px',
              zIndex: 1000,
              position: 'fixed',
              top: '66px',
              left: '16px',
              '&:hover': {
                backgroundColor: 'rgba(21, 101, 192, 0.8)',
              },
            }}
          >
            <KeyboardDoubleArrowRight />
          </IconButton>
        )}
        <Box sx={supportStyle}>
          <Box style={boxStyle}>
            <Box sx={headerStyle}>
              <Typography variant="h6" sx={titleStyle}>Current Plan</Typography>
            </Box>
            <div style={styles.flexContainer}>
              <div>
                <p style={styles.detailItem}>{displayName}</p>
                <p style={styles.detailItem}>{data.planPrice}</p>
                <p style={styles.detailItem}>Your plan renews on: {data.renewalDate}</p>
              </div>
            </div>
            <div style={styles.divider}></div>

            {/* Questions and Upgrade Option */}
            <div style={styles.flexContainerCenter}>
              <p>Have questions about your plan?{" "}
                <span
                  style={styles.link}
                  onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
                  onMouseOut={(e) => e.target.style.textDecoration = 'none'}
                  onClick={handleChangePlanMethod}
                >
                  Learn about plans and pricing
                </span>.
              </p>
              {data.upgradeOption && (
                <Button onClick={openDialog} style={styles.button}>
                  <EditIcon fontSize="smaller" /> Change Plan
                </Button>
              )}
            </div>
            <div style={styles.dividerBold}></div>
            {/* Payment Method Section */}
            <PaymentMethod
              paymentMethod={{
                cardBrand: data.cardBrand,
                last4: data.last4,
                expMonth: data.expMonth,
                expYear: data.expYear,
                billingAddress: data.billingAddress
              }}
              titleStyle={styles.title}
            />
          </Box>
          <Dialog onClose={closeDialog} open={isDialogOpen} PaperProps={{ style: styles.paperProps }}>
            <div style={styles.innerDiv}>
              <DialogTitle id="cancel-dialog-title" style={styles.dialogTitle}>
                <IconButton aria-label="close" onClick={closeDialog} style={styles.closeButton}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent style={styles.dialogContent}>
                <SubscriptionWizard />
              </DialogContent>
            </div>
          </Dialog>
        </Box>
      </Box>
    </Box>
  );
}

export default ManagePlan;
