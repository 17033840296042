import {
    Paper,
    ChatBubbleOutline as ChatBubbleOutlineIcon,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const MatrixItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    // height: 60,
    lineHeight: '60px',
}));

export default MatrixItem;