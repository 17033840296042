import React, { useState, useEffect } from 'react';
import Message from './MessageV2';
import { Grid, Paper, Box, useTheme } from '@mui/material';

function MessageListV2({
  messages,
  hitchUser,
  chatContainerRef,
  isHelpful,
  isNotHelpful,
  createNewTab,
  saveEditedMessage,
  setEditingMessage,
  setEditedMessageText,
  editedMessageText,
  editingMessage,
  defaultHeight,
  isTabletMobile,
  isMobile,
  isTablet,
  isInChatDrawer,
}) {
  const theme = useTheme();
  const themeBackgroundColor = theme.palette.secondary[theme.palette.mode];

  const getInitialHeight = () => {
    if (isInChatDrawer) {
      if (isMobile) {
        return '70vh'; // Mobile and in chat drawer
      }
      if (isTablet) {
        return '25vh'; // Mobile and in chat drawer
      } else {
        return '24vh'; // Tablet and in chat drawer
      }
    } else {
      if (isTabletMobile) {
        return '67vh'; // Mobile or tablet and NOT in chat drawer
      } else {
        return '67vh'; // Otherwise
      }
    }
  };

  const [chatContainerHeight, setChatContainerHeight] = useState(getInitialHeight);

  const [editingWidth, setEditingWidth] = useState(null);
  const [hoveredMessageId, setHoveredMessageId] = useState(null); // Track the hovered message

  useEffect(() => {
    function updateChatContainerHeight() {
      let newHeight;

      if (isInChatDrawer) {
        // Apply logic based on device type directly in vh
        newHeight = defaultHeight;
      } else {
        // Default case when not in chat drawer
        newHeight = isTabletMobile ? '67vh' : '67vh';
      }

      // Directly apply the calculated height
      if (chatContainerRef.current) {
        chatContainerRef.current.style.height = newHeight;
      }
    }

    // Initial call and setup for resize listener
    updateChatContainerHeight();
    window.addEventListener('resize', updateChatContainerHeight);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', updateChatContainerHeight);
    };
  }, [isInChatDrawer, isMobile, isTablet, isTabletMobile]); // Ensure all relevant props are listed as dependencies

  const startEditingMessage = (message) => {
    setEditingMessage(message);
    setEditedMessageText(message.text ? message.text.join('\n') : '');

    const currentWidth = chatContainerRef.current.offsetWidth + 'px';
    setEditingWidth(currentWidth);
  };

  return (
    <Box
      ref={chatContainerRef}
      sx={{
        backgroundColor: themeBackgroundColor,
        height: chatContainerHeight,
        overflowY: 'auto',
        width: '100%',
        //paddingBottom: '32px',
        paddingLeft: '8px',
        '&::-webkit-scrollbar': {
          width: '6px', // Adjust the width of the scrollbar
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888', // Adjust the color of the scrollbar thumb
          borderRadius: '3px', // Adjust the border radius of the scrollbar thumb
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#555', // Adjust the color of the scrollbar thumb on hover
        },
      }}
    >
      {messages.map((message, index) => (
        <Message
          key={index}
          editedMessageText={editedMessageText}
          editingMessage={editingMessage}
          hoveredMessageId={hoveredMessageId}
          index={index}
          message={message}
          messageIsHelpful
          messageIsNotHelpful
          saveEditedMessage={saveEditedMessage}
          setEditedMessageText={setEditedMessageText}
          setEditingMessage={setEditingMessage}
          setHoveredMessageId={setHoveredMessageId}
          startEditingMessage={startEditingMessage}
          hitchUser={hitchUser}
          createNewTab={createNewTab} // Pass the function to the Message component
          isTabletMobile={isTabletMobile}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      ))}
    </Box>
  );
}

export default MessageListV2;
