import Select from "./Select";

function SfoSelect({handleChange, label, localId, nodeId, options}) {
    // Prepend the NOT SELECTED option with an empty value
    const modifiedOptions = [{ value: '', label: '**NOT SELECTED**' }, ...options];

    return (
        <div>
          <label>{label}:</label>
          <Select
            value={localId}
            options={modifiedOptions} 
            onChange={handleChange}
            nodeId={nodeId}
          />
        </div>
    );
}

export default SfoSelect;
