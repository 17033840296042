import React from 'react';
import {
    Modal,
    Box,
    Typography,
    IconButton,
    Button,
    Divider
} from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useFileListStyles } from './FileListStyleProvider';

export const DeleteConfirmationModal = ({ open, onClose, fileToDelete, onConfirmDelete }) => {
    const { modalStyle, cancelButtonStyleDelete, deleteButtonStyle } = useFileListStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="delete-modal-title"
            aria-describedby="delete-modal-description"
            sx={{ zIndex: 2501 }} // Ensure the modal is above other elements
        >
            <Box sx={modalStyle}>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 16,
                        color: 'white',
                        '&:hover': {
                            backgroundColor: 'transparent', // Prevent hover effect
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faTimes} size="1x" />
                </IconButton>
                <Typography variant="h6" style={{ color: 'white', fontFamily: '"Poppins", sans-serif', marginBottom: '20px' }}>
                    Confirm Delete
                </Typography>
                <Typography id="delete-modal-description" sx={{ mt: 2, fontFamily: '"Poppins", sans-serif' }}>
                    Deleting "{fileToDelete?.name}".
                    <br /><br />
                    If you want it back later, just upload it again with a new name.
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                    <Button onClick={onClose} style={cancelButtonStyleDelete}>
                        Cancel
                    </Button>
                    <Button onClick={onConfirmDelete} style={deleteButtonStyle}>Delete</Button>
                </Box>

            </Box>
        </Modal>
    );
};

export const InfoModal = ({ open, onClose }) => {
    const { modalStyle, buttonStyle, themeModalTextSecondaryColor, themeDividerColor } = useFileListStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="info-modal-title"
            aria-describedby="info-modal-description"
            sx={{ zIndex: 2501 }} // Ensure the modal is above other elements
        >
            <Box sx={modalStyle}>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 16,
                        color: themeModalTextSecondaryColor,
                        '&:hover': {
                            backgroundColor: 'transparent', // Prevent hover effect
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faTimes} size="1x" />
                </IconButton>
                <Typography variant="h6" style={{ color: themeModalTextSecondaryColor, fontFamily: '"Poppins", sans-serif', marginBottom: '16px' }}>
                    Document Info
                </Typography>
                <Divider sx={{ marginTop: '4px', backgroundColor: themeDividerColor }} />
                <Typography id="info-modal-description" sx={{ mt: 2, fontFamily: '"Poppins", sans-serif', textAlign: 'left', color: themeModalTextSecondaryColor }}>
                    <strong>Why These Docs Matter:</strong>
                    <Box sx={{ pl: 2, mt: 1, fontWeight: 'light' }}>
                        These documents expand Hitchy's knowledge base, giving you more control over the insights you receive.
                        <br /><br />
                        Your documents become content Hitchy can access and discuss with you directly.
                        <br /><br />
                        Upload what matters. Get precise answers about your own content.
                    </Box>
                    <br />

                    <Divider sx={{ marginBottom: '16px', backgroundColor: themeDividerColor }} />
                    <strong>Supported Formats:</strong>
                    {/* <Box sx={{ pl: 2, mt: 1 }}>
                        <ul style={{ paddingLeft: '40px' }}>
                            <li>.c, .cs, .cpp</li>
                            <li>.doc, .docx</li>
                            <li>.html, .java</li>
                            <li>.json, .md</li>
                            <li>.pdf, .php</li>
                            <li>.pptx, .py, .rb, .tex</li>
                            <li>.txt, .css, .js</li>
                            <li>.sh, .ts</li>
                        </ul>
                    </Box> */}
                    <Box sx={{ pl: 2, mt: 1 }}>
                        <ul style={{ paddingLeft: '40px' }}>
                            <li>.c, .cs, .cpp</li>         
                            <li>.doc, .docx, .pdf</li>     
                            <li>.html, .css, .js, .ts</li> 
                            <li>.json</li>                
                            <li>.pptx</li>                 
                            <li>.py, .tex</li>             
                            <li>.txt</li>                 
                        </ul>
                    </Box>

                    <br />

                    <Divider sx={{ marginBottom: '16px', backgroundColor: themeDividerColor }} />
                    <strong>Limits:</strong>
                    <Box sx={{ pl: 2, mt: 1 }}>
                        <ul style={{ paddingLeft: '40px' }}>
                            <li>Maximum 15 pages per PDF document</li>
                            <li>No re-uploading the same file without a name change.</li>
                        </ul>
                    </Box>
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                    <Button onClick={onClose} sx={buttonStyle}>
                        Got It
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
