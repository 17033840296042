import { Typography } from "@mui/material";

function TrainingHeader({
    editingModel,
    filter,
    hubConnection,
    searchTerm,
    sessionId,
    setSearchTerm,
    title
  }) {
    return (
        <Typography variant="h3">Hitch.AI Training</Typography>
    )
  }
  export default TrainingHeader;