import React, { useEffect, useState } from 'react';
import AddUser from './AddUser';
import AddOrganization from './AddOrganization';
import AddPlan from './AddPlan';
import PaymentAndSummary from './PaymentAndSummary';
import ConnectionWizard from '../hitchy/ConnectionWizard/ConnectionWizard';
import { useNavigate } from 'react-router-dom';
import './RegistrationWizard.css';
import stepsData from './registrationSteps.json';
import { useHubConnections } from '../utils/HubConnectionsProvider';
import { v4 as uuidv4 } from 'uuid';
import SplashScreen from './SplashScreen';

function RegistrationWizard({ user, isAuthenticated }) {
  const { account, dashboardConnection, hitchDashCommand, hitchUser, warningSnack, tacoData, setTacoData } = useHubConnections();
  const navigate = useNavigate();
  const [backupData, setBackupData] = useState([]);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    phoneType: '',
    howDidYouHear: '',
    organizationName: '',
    organizationUrl: '',
    employees: '',
    selectedPlanId: uuidv4(),
  });
  const [firstName, setFirstName] = useState('');
  const [paymentLink, setPaymentLink] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneType, setPhoneType] = useState('');
  const [howDidYouHear, setHowDidYouHear] = useState('');
  const [otherHowDidYouHear, setOtherHowDidYouHear] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [organizationName, setOrganizationName] = useState('');
  const [organizationUrl, setOrganizationUrl] = useState('');
  const [employees, setEmployees] = useState('');
  const [industry, setIndustry] = useState('');
  const [annualRevenue, setAnnualRevenue] = useState('');
  const [country, setCountry] = useState('');
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [showCancelPrompt, setShowCancelPrompt] = useState(false);
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(true);
  const [isConnectionOpen, setIsConnectionOpen] = useState(false);
  const [thePlanData, setThePlanData] = useState(null);
  const [showSplash, setShowSplash] = useState(false);

  useEffect(() => {
    if (dashboardConnection) {
      console.log('dashConnection we have');
    }
  }, [dashboardConnection]);

  useEffect(() => {
    if(tacoData) {
      setBackupData(tacoData);
    }
  }, [tacoData]);

  useEffect(() => {
    if(selectedPlanId) {
      console.log('selectedPlanId', selectedPlanId);
    }
  }, [selectedPlanId]);

  function planData(data) {
    setTacoData(data);
  }

  function unlockOffer(data) {
    if (data.isLocked) {
      warningSnack(`Access Code Invalid`);
      return;
    }
    planData(data);
  }

  useEffect(() => {
    if (hitchDashCommand && dashboardConnection) {
      dashboardConnection.on("689c44bd-0cf2-454b-9951-a5f56c71bd42", thePlansCameIn);
      dashboardConnection.on("5de65fab-e866-4ec3-ba1a-733d13ef53c9", unlockOffer);
      dashboardConnection.on("8b069ff4-f01a-40bf-bf28-3b94916c6888", planSelected);
      dashboardConnection.on("OfferPayment", handleOfferPayment);

      function callForOffers() {
        if (dashboardConnection) {
          hitchDashCommand('marketing', 'offer', 'subscriptions', {});
        }
      }

      callForOffers();

      return () => {
        dashboardConnection.off("689c44bd-0cf2-454b-9951-a5f56c71bd42", thePlansCameIn);
        dashboardConnection.off("5de65fab-e866-4ec3-ba1a-733d13ef53c9", unlockOffer);
        dashboardConnection.off("8b069ff4-f01a-40bf-bf28-3b94916c6888", planSelected);
        dashboardConnection.off("OfferPayment", handleOfferPayment);
      };
    }
  }, [dashboardConnection]);

  useEffect(() => {
    console.log('selectedPlanId changed!');
    if(dashboardConnection && selectedPlanId) {
        console.log('--------------------> selectedPlanId', selectedPlanId);
        console.log('account', account);
        console.log('hitchUser', hitchUser);

        hitchDashCommand('account', 'subscription', 'get', {
          theNewOfferId: selectedPlanId,
          theAccountId: hitchUser.accountId
        });
    } else {
      console.log('something up.  dashboardConnection', dashboardConnection);
      console.log('something up.  selectedPlanId', selectedPlanId);
    }
  }, [dashboardConnection, selectedPlanId]);

  function planSelected(data) {
    console.log('PLAN SELECTED --->', data);
    setThePlanData(data);
  }

  let paymentProcessed = false; // Track if the payment link has been processed

  function handleOfferPayment(data) {
    // Introduce a 2-second delay before processing the payment link
    setTimeout(() => {
      console.log('handleOfferPayment received:', data);
  
      if (typeof data === 'string' && !paymentProcessed) {
        // Case when the payment link is received as a string directly
        console.log('Payment link is available (string):', data);
        paymentProcessed = true; // Mark as processed
  
        // Redirect immediately without hiding the splash screen
        window.location.href = data;
  
      } else if (data && data.paymentLink && !paymentProcessed) {
        // Case when the payment link is received within an object
        console.log('Payment link is available (object):', data.paymentLink);
        paymentProcessed = true; // Mark as processed
  
        // Redirect immediately without hiding the splash screen
        window.location.href = data.paymentLink;
  
      } else if (!paymentProcessed) {
        // Log that we are waiting longer but don't show an error or hide the splash screen
        console.log('Still waiting for payment link...');
        // Optionally, you could add a longer timeout here if needed before concluding there's an issue.
      }
    }, 2000); // Initial 2-second delay before processing
  }
  

  function thePlansCameIn(data) {
    planData(data);
  }

  function doThePayment() {
    console.log('doThePayment!!!!!!!!...');
    if (dashboardConnection && hitchDashCommand) {
      console.log('Starting payment process...');
      setShowSplash(true); // Show the splash screen when payment starts

      hitchDashCommand('marketing', 'offer', 'claim', {
        theOfferId: selectedPlanId,
        theAccountId: hitchUser.accountId
      }, handleOfferPayment);
    }
  }

  const handleNext = (newData) => {
    setFormData(prevData => ({ ...prevData, ...newData }));
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleCancel = () => {
    setShowCancelPrompt(true);
  };

  const handleCloseCancelPrompt = () => {
    setShowCancelPrompt(false);
  };

  const handleConfirmCancel = () => {
    setShowCancelPrompt(false);
    window.location.href = '/';
  };

  const handleSubscribe = () => {
    navigate('/app/connectme');
  };

  const componentMap = {
    AddUser,
    AddOrganization,
    AddSubscription: AddPlan,
    AddPayment: (props) => <PaymentAndSummary {...props} onSubscribe={doThePayment} scenario="new" />,
  };

  const renderStepContent = (currentStep) => {
    const Component = componentMap[currentStep.navigation];
    return (
      <Component
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        phoneType={phoneType}
        setPhoneType={setPhoneType}
        howDidYouHear={howDidYouHear}
        setHowDidYouHear={setHowDidYouHear}
        otherHowDidYouHear={otherHowDidYouHear}
        setOtherHowDidYouHear={setOtherHowDidYouHear}
        termsAccepted={termsAccepted}
        setTermsAccepted={setTermsAccepted}
        organizationName={organizationName}
        setOrganizationName={setOrganizationName}
        organizationUrl={organizationUrl}
        setOrganizationUrl={setOrganizationUrl}
        employees={employees}
        setEmployees={setEmployees}
        industry={industry}
        setIndustry={setIndustry}
        annualRevenue={annualRevenue}
        setAnnualRevenue={setAnnualRevenue}
        country={country}
        setCountry={setCountry}
        selectedPlanId={selectedPlanId}
        setSelectedPlanId={setSelectedPlanId}
        onBack={handleBack}
        onNext={handleNext}
        onCancel={handleCancel}
        plansData={tacoData}
        thePlanData={thePlanData}
        paymentLink={paymentLink}
        planData={planData}
      />
    );
  };

  const renderStepIndicator = (currentStep) => {
    return (
      <div className="step-indicator-registration">
        {stepsData.map((step, index) => (
          <div key={step.stepId} className="step-item-registration">
            <div
              className={`step-circle-registration ${step.stepId === currentStep.stepId ? 'active' : ''}`}
            >
              {step.stepId}
            </div>
            <div className="step-label-registration">{step.label}</div>
            {index < stepsData.length - 1 && <div className="step-line-registration" />}
          </div>
        ))}
      </div>
    );
  };

  const orderedStepsData = stepsData.sort((a, b) => a.stepId - b.stepId);
  const currentStepIndex = step - 1;
  const totalSteps = orderedStepsData.length;
  const currentStep = orderedStepsData[currentStepIndex];

  if (showSplash) {
    return (
      <SplashScreen
        message="You will be redirected to Square's Checkout Page..."
        details="It might take a few seconds. Please do not refresh the page or click the 'Back' or 'Close' button of your browser."
      />
    );
  }

  return (
    <div className="container-setup">
      {isRegistrationOpen && (
        <div className="white-box-setup">
          <div className="step-indicator-registration">
            {renderStepIndicator(currentStep)}
          </div>

          <div className="content-setup">
            {renderStepContent(currentStep)}
          </div>
        </div>
      )}

      {isConnectionOpen && (
        <div className="white-box-setup">
          <ConnectionWizard />
        </div>
      )}
    </div>
  );
}

export default RegistrationWizard;
