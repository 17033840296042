import axios from 'axios';

export const handleFileClick = async (file, bot, user, setSelectedFile, setFileContent, setFileName, setFileContentType) => {
    console.log("File clicked:", file);
    try {
        const response = await fetch(`${window._env_.HITCH_API}/api/render/repo/${bot?.selectedVectorStore}/doc/${file.id}`, {
            headers: {
                Authorization: `Bearer ${user?.id_token}`
            }
        });

        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
            const binaryStr = reader.result;
            setFileContent(binaryStr);
            setFileName(file.name);
            setFileContentType(file.fileContentType);
        };
        reader.readAsBinaryString(blob);
    } catch (error) {
        setFileContent('Error loading document.');
        setFileName(file.name);
    }
    setSelectedFile(file);
};

export const handleSearchInputChange = (event, files, setSearchInput, setFilteredFiles) => {
    const query = event.target.value.toLowerCase();
    setSearchInput(query);
    const filtered = files.filter((file) =>
        file.name.toLowerCase().includes(query)
    );
    setFilteredFiles(filtered);
};

export const handleFileUploadClick = () => {
    document.getElementById('file-upload-input').click();
};

export const handleFileChange = async (event, bot, user, dashboardConnection, hitchDashCommand, isConnectionReady, setFiles, setLoading) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await fetch(`${window._env_.HITCH_API}/api/store/${bot?.selectedVectorStore}/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${user?.id_token}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to upload file');
        }

        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
            const result = await response.json();
        }

        if (bot && dashboardConnection && hitchDashCommand && isConnectionReady) {
            if (bot.selectedVectorStore) {
                hitchDashCommand('document', 'file', 'list', { 'RepositoryId': bot.selectedVectorStore }, filesList(setFiles, setLoading));
            }
        }

    } catch (error) {
        console.error('Error uploading file:', error);
    }
};

export const handlePopupAction = async (action, file, bot, user, setIsDeleteConfirmationOpen, setFileToDelete) => {
    const allowedFileTypes = ['PDF', 'TXT'];
    
    // Ensure fileType exists and is a string before using toUpperCase
    const fileType = file.fileContentType && typeof file.fileContentType === 'string' ? file.fileContentType.toUpperCase() : '';

    if (action === 'download') {
        if (!allowedFileTypes.includes(fileType)) {
            console.error(`Download of ${fileType} files is not allowed.`);
            return; // Exit if the file type is not allowed
        }

        try {
            const response = await fetch(`${window._env_.HITCH_API}/api/render/repo/${bot?.selectedVectorStore}/doc/${file.id}`, {
                headers: {
                    Authorization: `Bearer ${user?.id_token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to download file');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = file.name;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    } else if (action === 'delete') {
        setIsDeleteConfirmationOpen(true);
        setFileToDelete(file);
    }
};



export const handleConfirmDelete = (fileToDelete, setFiles, setFilteredFiles, setIsDeleteConfirmationOpen, bot, dashboardConnection, hitchDashCommand, isConnectionReady) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
    setFilteredFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
    setIsDeleteConfirmationOpen(false);
    if (bot && dashboardConnection && hitchDashCommand && isConnectionReady) {
        if (bot.selectedVectorStore) {
            hitchDashCommand('document', 'file', 'delete', {
                'RepositoryId': bot.selectedVectorStore,
                'FileId': fileToDelete.id
            });
        }
    }
};

export const handleModalClose = (setState) => {
    setState(false);
};

export const handleInfoOpen = (setState) => {
    setState(true);
};

export const handleInfoClose = (setState) => {
    setState(false);
};

export const filesList = (setFiles, setLoading, setFailCount) => (fileListData) => {
    if (fileListData && fileListData.stackTrace) {
        setFailCount((prevCount) => prevCount + 1);
        if (fileListData.message === "Invalid Repository Id") {
            // Handle invalid repository id
        }
        return;
    }
    if (fileListData === undefined) {
        return;
    }
    setFiles(fileListData || []);
    setFailCount(0);
    setLoading(false);
};
