import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useState } from "react";

function OpenAiMetadata({ formData, metadataKeys, handleAddMetadata, handleMetadataChange, handleRemoveMetadata, setFormData }) {
    const [localKeys, setLocalKeys] = useState({});

    const handleLocalKeyChange = (oldKey, newKey) => {
        setLocalKeys(prev => ({ ...prev, [oldKey]: newKey }));
    };

    const handleBlur = (oldKey) => {
        const newKey = localKeys[oldKey];
        if (newKey && newKey !== oldKey) {
            const newMetadata = { ...formData.metadata };
            newMetadata[newKey] = newMetadata[oldKey];
            delete newMetadata[oldKey];
            setFormData(prev => ({ ...prev, metadata: newMetadata }));
            setLocalKeys(prev => {
                const updated = { ...prev };
                delete updated[oldKey];
                return updated;
            });
        }
    };

    return (
        <Grid item xs={12}>
            <Typography variant="h6">Metadata</Typography>
            <Button variant="contained" startIcon={<FontAwesomeIcon icon={faPlus} />} onClick={handleAddMetadata} disabled={Object.keys(formData.metadata).length >= 16}>
                Add Metadata
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Key</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {metadataKeys.map(key => (
                            <TableRow key={key}>
                                <TableCell>
                                    <TextField
                                        value={localKeys[key] !== undefined ? localKeys[key] : key}
                                        onChange={(e) => handleLocalKeyChange(key, e.target.value)}
                                        // onBlur={() => handleBlur(key)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        value={formData.metadata[key]}
                                        onChange={(e) => handleMetadataChange(key, e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleRemoveMetadata(key)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
}

export default OpenAiMetadata;
