import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Tabs, Tab, Grid, Box, Paper } from '@mui/material';
import ConversationMainTab from './ConversationMainTab';
import ConversationAudit from '../ConversationAudit/ConversationAudit';
import MessageList from './MessageList';
import { useChatConnections } from '../../utils/ChatConnectionProvider';

function ConversationTabs({
  user,
  hitchUser,
  conversation,
  useChatGpt,
  setUseChatGpt,
  guid,
  messages,
  users,
  chatContainerRef,
  isHelpful,
  isNotHelpful,
  saveEditedMessage,
  setEditingMessage,
  setEditedMessageText,
  editedMessageText,
  editingMessage,
  overrideHeight,
  isTabletMobile,
  isMobile,
  isTablet,
  setName,
  isInChatDrawer,
  noHeader,
  onOpenLeftDrawer,
  onOpenMiniDrawer,
  setMessages,
  chatBoxRef
}) {
  const [selectedConvTab, setSelectedConvTab] = useState(0);
  const [dynamicTabs, setDynamicTabs] = useState([]);
  const [chatContainerHeight, setChatContainerHeight] = useState('87vh');
  const { isTyping } = useChatConnections();

  const theme = useTheme();
  const themeDividerColor = theme.palette.secondaryDivider[theme.palette.mode];
  const themeBackgroundColor = theme.palette.secondary[theme.palette.mode];
  const menuIconColor = theme.palette.secondaryText[theme.palette.mode];

  const handleTabChange = (event, newValue) => {
    setSelectedConvTab(newValue);
  };

  useEffect(() => {
    function updateChatContainerHeight() {
      let newHeight;

      if (chatBoxRef.current) {
        const chatBoxTop = chatBoxRef.current.getBoundingClientRect().top;
        const containerTop = chatContainerRef.current.getBoundingClientRect().top;
        newHeight = `${chatBoxTop - containerTop - 16}px`;
      } else {
        newHeight = '70vh';
      }

      setChatContainerHeight(newHeight);
    }

    updateChatContainerHeight();
    window.addEventListener('resize', updateChatContainerHeight);

    return () => {
      window.removeEventListener('resize', updateChatContainerHeight);
    };
  }, [chatBoxRef, chatContainerRef, isMobile, isTablet, isInChatDrawer, overrideHeight]);

  const wrapperStyle = {
    margin: isTabletMobile ? '0' : 'auto',
    maxWidth: '100%',
    minWidth: '100%',
    padding: isTabletMobile ? '0' : '0',
    backgroundColor: themeBackgroundColor,
    fontFamily: '"Poppins", sans-serif',
  };

  const createNewTab = () => {
    const timestamp = new Date().getTime();
    const newTab = {
      title: `${timestamp}`,
      content: `This is a new test: ${timestamp}`,
    };
    setDynamicTabs([...dynamicTabs, newTab]);
  };

  const createNewTabAltD = () => {
    const timestamp = new Date().getTime();
    const newTab = {
      title: `Audit Log`,
      content: <ConversationAudit conversationId={guid} />,
    };
    setDynamicTabs([...dynamicTabs, newTab]);
  };

  const handleCloseTab = (tabIndex) => {
    const newDynamicTabs = dynamicTabs.filter((_, index) => index !== tabIndex);
    setDynamicTabs(newDynamicTabs);
    if (selectedConvTab >= tabIndex && selectedConvTab > 0) {
      setSelectedConvTab(selectedConvTab - 1);
    }
  };

  return (
    <Box sx={wrapperStyle}>
      {!noHeader && (
        <Box display="flex" justifyContent="center" alignItems="center">
          {(isTabletMobile || isTablet) && (
            <IconButton
              onClick={onOpenMiniDrawer}
              style={{ position: 'absolute', left: 0, color: menuIconColor }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Tabs
            value={selectedConvTab}
            onChange={handleTabChange}
            sx={{
              backgroundColor: themeBackgroundColor,
              fontFamily: '"Poppins", sans-serif',
              '.MuiTabs-indicator': {
                backgroundColor: themeBackgroundColor,
              },
              '.MuiTab-root': {
                fontFamily: '"Poppins", sans-serif',
                color: 'grey',
              },
              '.Mui-selected': {
                color: 'grey',
                borderBottom: theme.palette.mode === 'dark' ? '1px solid #000000' : '1px solid #696969',
                fontFamily: '"Poppins", sans-serif',
              },
              '.MuiTab-textColorInherit': {
                opacity: 1,
              }
            }}
          >
            <ConversationMainTab
              conversation={conversation}
              setName={setName}
              onSelect={() => setSelectedConvTab(0)}
              selected={selectedConvTab === 0}
            />
            {dynamicTabs.map((tab, index) => (
              <Tab
                key={index}
                label={
                  <Box display="flex" alignItems="center">
                    {tab.title}
                    <IconButton
                      size="small"
                      onClick={() => handleCloseTab(index)}
                      sx={{ ml: 1 }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                }
              />
            ))}
          </Tabs>
        </Box>
      )}

      <div role="tabpanel" hidden={selectedConvTab !== 0}>
        <Grid item xs={12}>
          <Paper
            style={{
              backgroundColor: themeDividerColor,
              height: '1px',
              boxShadow: 'none',
              border: 'none'
            }}>
          </Paper>
        </Grid>
        <Box mt={2} />

        <Grid container spacing={0} item xs={12}>
          <MessageList
            messages={messages}
            user={hitchUser}
            hitchUser={hitchUser}
            users={users}
            chatContainerRef={chatContainerRef}
            isHelpful={isHelpful}
            isNotHelpful={isNotHelpful}
            saveEditedMessage={saveEditedMessage}
            createNewTab={createNewTab}
            setEditingMessage={setEditingMessage}
            setEditedMessageText={setEditedMessageText}
            editedMessageText={editedMessageText}
            editingMessage={editingMessage}
            defaultHeight={chatContainerHeight}
            isTabletMobile={isTabletMobile}
            isMobile={isMobile}
            isTablet={isTablet}
            isInChatDrawer={isInChatDrawer}
            isTyping={isTyping} // Pass isTyping to MessageList
            chatContainerHeight={chatContainerHeight}
          />
        </Grid>
      </div>

      {dynamicTabs.map((tab, index) => (
        <div key={index} role="tabpanel" hidden={selectedConvTab !== index + 1}>
          <div
            ref={chatContainerRef}
            style={{
              backgroundColor: themeBackgroundColor,
              padding: '0px',
              height: chatContainerHeight,
              overflowY: 'auto',
              color: 'white',
            }}
          >
            <Grid item xs={12}>
              <Paper style={{ backgroundColor: 'black', height: '1px', boxShadow: 'none', border: 'none' }}></Paper>
            </Grid>
            {tab.content}
          </div>
        </div>
      ))}
    </Box>
  );
}

export default ConversationTabs;
