import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@mui/material';

const ContextMenu = ({ anchorEl, handleClose, menuItems }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      MenuListProps={{ 'aria-labelledby': 'context-menu' }}
    >
      {menuItems.map((item, index) => (
        <MenuItem key={index} onClick={item.onClick}>
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

ContextMenu.propTypes = {
  anchorEl: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default ContextMenu;
