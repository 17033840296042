import { Button, ButtonGroup, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from "@mui/material";
import QuickbooksStep from "./QuickbooksStep";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrain } from "@fortawesome/pro-duotone-svg-icons";

function QbTraining({ hubConnection, sessionId, prompt, jsonl, updatePrompt, chatModel, tfLineCount, trainNow }) {

    function SendToTrainingFile(event) {
        if (hubConnection) {
            hubConnection.invoke("SendToTrainingFile", sessionId)
                .then(() => {
                })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    const isPromptTooShort = prompt ? prompt.length < 5 : true;

    return (
        <Grid className="qb_top_row" container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    multiline
                    fullWidth
                    rows={4}
                    value={prompt}
                    onChange={(value) => updatePrompt(value)}
                    label="Prompt" />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    multiline
                    fullWidth
                    value={jsonl}
                    disabled
                    rows={4}
                    label="Training Line" />
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                    <ButtonGroup>
                        <Button 
                            onClick={(e) => SendToTrainingFile(e)}
                            disabled={isPromptTooShort}>Add To Training File</Button>
                        <Button>Reset</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="flex-start">
                    <List>
                        <ListItem>
                            <ListItemText primary={`Current Model: ${chatModel}`}></ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={`Number Of Training Lines: ${tfLineCount}`}></ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemButton>
                                <ListItemIcon><FontAwesomeIcon icon={faTrain}/></ListItemIcon>
                                <ListItemText primary="Train Now" onClick={(e) => trainNow()} />
                            </ListItemButton>

                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default QbTraining;
