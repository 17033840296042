import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Typography, IconButton, Tooltip, Modal, useTheme, Box, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from "@mui/material";
import HitchTableV2 from "./HitchTableV2";
import ReactMarkdown from "react-markdown";
import MessageErrorV2 from "./MessageErrorV2";
import MessageLinkV2 from "./MessageLinkV2";
import MessageProductV2 from "./MessageProductV2";
import JSONTreeViewV2 from "./JSONTreeViewV2";
import { faTimes, faCancel, faCopy, faEdit, faExternalLinkAlt, faSend, faThumbsDown, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import MessageAdaptiveCardV2 from './MessageAdaptiveCardV2';

function MessageV2({
    editingMessage,
    editedMessageText,
    hoveredMessageId,
    index,
    message,
    messageIsHelpful,
    messageIsNotHelpful,
    saveEditedMessage,
    setEditedMessageText,
    setEditingMessage,
    setHoveredMessageId,
    startEditingMessage,
    // user,
    hitchUser,
    // users,
    createNewTab,
    isTabletMobile,
    isMobile,
    isTablet,
}) {
    // const relatedUser = users.find((u) => u.id === message.user);
    const isCurrentUserMessage = message.user === hitchUser?.userId;
    const isEditingCurrentMessage = editingMessage?.messageId === message.messageId;
    const originalText = message.text;
    const isFirstMessage = index === 0;
    const showCopyIcon = !(isFirstMessage && !isCurrentUserMessage);
    const [isLinkIconClicked, setIsLinkIconClicked] = useState(false);
    const [isThumbsUpClicked, setIsThumbsUpClicked] = useState(false);
    const [isThumbsDownClicked, setIsThumbsDownClicked] = useState(false);
    const [isSecondCopyClicked, setIsSecondCopyClicked] = useState(false);
    const [userAvatar, setUserAvatar] = useState(null);
    const paddingLeft = isMobile || isTablet ? '8px' : '32px';
    const [isHovered, setIsHovered] = useState(false);
    const theme = useTheme();
    const themeBackgroundColor = theme.palette.secondary[theme.palette.mode];
    const themeTextColor = theme.palette.secondaryText[theme.palette.mode];
    const themeListItemSelected = theme.palette.listItemBackground.selected[theme.palette.mode];
    const themeGlowingSmileyColor = theme.palette.glowingSmiley[theme.palette.mode];

    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
    const [negativeFeedback, setNegativeFeedback] = useState("");
    const [feedbackError, setfeedbackError] = useState(false); // New state for rename error


    const themeTextPrimaryColor = theme.palette.chatLeftMenuPrimaryText[theme.palette.mode];
    const themeListItemHovered = theme.palette.listItemBackground.hover[theme.palette.mode];
    const themeListItemSelectedHovered = theme.palette.listItemBackground.selected_hover[theme.palette.mode];
    const themeModalBackgroundColor = theme.palette.modalBackground[theme.palette.mode];
    const themeModalTextPrimaryColor = theme.palette.modalTextPrimary[theme.palette.mode];
    const themeModalTextSecondaryColor = theme.palette.modalTextSecondary[theme.palette.mode];

    // const { hitchUser } = useQualifierChat();
    const [accountId, setAccountId] = useState('');

    useEffect(() => {
        if(hitchUser) {
            // console.log('hitchUser', hitchUser);
            setAccountId(hitchUser.accountId);
        }
    }, [hitchUser]);


    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: {
            xs: '90%',
            sm: '80%',
            md: '70%',
            lg: '600px',
        },
        backgroundColor: themeModalBackgroundColor,//'#0D0D0D',
        color: themeModalTextSecondaryColor,
        borderColor: 'gray',
        borderStyle: 'solid',
        borderWidth: '1px',
        boxShadow: '0 4px 8px 0 rgba(255, 255, 255, 0.2)',
        p: 4,
        position: 'relative',
        textAlign: 'center',
        borderRadius: '10px',
        fontFamily: '"Poppins", sans-serif',
    };

    const saveButtonStyleRename = {
        fontFamily: 'Poppins, sans-serif',
        color: '#000000',
        backgroundColor: '#00EDED',
        '&:hover': {
            backgroundColor: '#00EDED', // Darker shade on hover
        },
        '&:active': {
            backgroundColor: '#00EDED', // Different shade when pressed
            boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.3)', // Optional: adds an inset shadow to simulate a pressed effect
        },
        marginLeft: '20px', // Increased spacing between buttons
    };

    const cancelButtonStyleRename = {
        color: 'white',
        backgroundColor: '#333',
        border: '1px solid #555',
        fontFamily: 'Poppins, sans-serif',
        '&:hover': {
            backgroundColor: '#333',//'rgba(255, 255, 255, 0.1)',
        },
    };


    const handleModalClose = () => {
        setIsFeedbackModalOpen(false); // Reset error state when modal opens
    };

    useEffect(() => {
        if (hitchUser && hitchUser.userId) {
            fetch(`${window._env_.HITCH_API}/api/accimage/${hitchUser.userId}.png`)
                .then(response => response.blob())
                .then(blob => {
                    const url = URL.createObjectURL(blob);
                    setUserAvatar(url);
                })
                .catch((error) => {
                    console.error('user picture error', error);
                });
        }
    }, [hitchUser]);


    const handleThumbsDownClick = () => {
        setIsFeedbackModalOpen(true);
    };

    const handleFeedbackChange = (event) => {
        setNegativeFeedback(event.target.value);
    };

    const handleFeedbackSubmit = () => {
        // Handle feedback submission logic here
        messageIsNotHelpful(message, negativeFeedback);
        setIsFeedbackModalOpen(false);
        setNegativeFeedback("");
    };

    const handleFeedbackCancel = () => {
        setIsFeedbackModalOpen(false);
        setNegativeFeedback("");
    };

    const parseMessageText = (text) => {
        let currentIndex = 0;

        if (Array.isArray(text)) {
            return text.map((section, index) => {
                const pattern = /(```[\s\S]*?```)/;
                const sections = section.split(pattern);

                const sectionElements = sections.map((s2, idx2) => {
                    if (s2.match(pattern)) {
                        currentIndex++;
                        return <CodeContainer key={currentIndex} code={s2} />;
                    } else {
                        let object = [];
                        try {
                            object = JSON.parse(s2);
                        } catch { }

                        if (object && object.rows && object.columns) {
                            try {
                                return (
                                    <HitchTableV2 object={object} />
                                );
                            } catch (error) {
                                console.error("Error creating DataGrid:", error);
                                return (
                                    <div key={idx2} style={{ padding: '4px', overflow: 'hidden' }}>
                                        <ReactMarkdown>{s2}</ReactMarkdown>
                                    </div>
                                );
                            }
                        } else {
                            return (
                                <div key={idx2} style={{ padding: '4px', overflow: 'hidden' }}>
                                    <ReactMarkdown>{s2}</ReactMarkdown>
                                </div>
                            );
                        }
                    }
                });

                return sectionElements;
            });
        }
    };

    const CodeContainer = ({ code }) => {
        const codeLanguageMatch = code.match(/```(\w+)/);
        const codeLanguage = codeLanguageMatch ? codeLanguageMatch[1] : '';

        const cleanedCode = code.replace(/```(\w+)([\s\S]*?)```/, '$2');

        const [isClicked, setIsClicked] = useState(false);

        const handleClick = () => {
            navigator.clipboard.writeText(cleanedCode);
            setIsClicked(true);
            setTimeout(() => {
                setIsClicked(false);
            }, 200);
        };

        return (
            <div
                style={{
                    position: 'relative',
                    maxWidth: '620px',
                    border: '1px solid #00EDED',
                    borderRadius: '5px',
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    margin: '8px 8px',
                }}
            >
                <div
                    style={{
                        background: '#00EDED',
                        color: 'black',
                        padding: '4px',
                        borderTopLeftRadius: '5px',
                        borderTopRightRadius: '5px',
                        fontFamily: 'Poppins, sans-serif',
                        fontSize: '12px',
                    }}
                >
                    {codeLanguage}
                </div>
                <pre
                    style={{
                        margin: '0',
                        marginTop: '-10px',
                        marginLeft: '14px',
                        marginBottom: '12px',
                        lineHeight: '1.2',
                    }}
                >
                    <code style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px' }}>{cleanedCode}</code>
                </pre>
                <div
                    style={{
                        position: 'absolute',
                        bottom: '0',
                        right: '0',
                        background: 'rgba(0, 0, 0, 0.7)',
                        padding: '8px',
                        borderRadius: '0 0 5px 0',
                    }}
                >
                    <Tooltip title="Copy code">
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{
                                '& svg': {
                                    fontSize: 14,
                                    color: isClicked ? '#00EDED' : '#FFFFFF',
                                    '&:hover': {
                                        color: '#00EDED',
                                    },
                                    '&:active': {
                                        backgroundColor: '#f0f0f0',
                                        borderRadius: '50%',
                                    },
                                }
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faCopy}
                                sx={{
                                    color: isClicked ? '#00EDED' : 'grey',
                                    '&:hover': {
                                        color: '#00EDED',
                                    },
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        );
    };

    return (
        <Box
            key={message.messageId}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: isHovered
                    ? message.user === hitchUser?.userId
                        ? themeListItemSelected
                        : themeListItemSelected
                    : message.user === hitchUser?.userId
                        ? themeBackgroundColor
                        : themeBackgroundColor,
                fontFamily: 'Poppins, sans-serif',
                paddingLeft: paddingLeft,
                paddingBottom: '8px',
                paddingTop: '8px',
                transition: 'background-color 0.3s',
            }}
        >
            {message.user !== accountId || message.type === 'user' ? (
                <Tooltip title={''}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'top',
                    }}>
                        <Avatar
                            src={userAvatar}
                            style={{
                                marginRight: '8px',
                                width: '38px',
                                height: '38px',
                            }}
                        />
                    </div>
                </Tooltip>
            ) : (
                <Tooltip title={''}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'top',
                    }}>
                        <Avatar
                            src='/img/glowingsmiley.svg'
                            style={{
                                marginRight: '8px',
                                width: '38px',
                                height: '38px',
                                backgroundColor: themeGlowingSmileyColor,
                                boxShadow: '0 0 8px 1px rgba(54, 103, 89, 0.8)'
                            }}
                        />
                    </div>
                </Tooltip>
            )}

            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    textAlign: 'left',
                    color: themeTextColor,
                    padding: '4px',
                    borderRadius: '10px',
                    width: '90%',
                    justifyContent: 'space-between',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                }}
                onMouseEnter={() => setHoveredMessageId(message.messageId)}
                onMouseLeave={() => setHoveredMessageId(null)}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div key={index} style={{ wordBreak: 'break-word' }}>
                        {message.text && parseMessageText(message.text)}
                        {message.message?.contentType && message.message?.contentType === 'Error' && message.message?.content} {
                            <MessageErrorV2 message={message} />
                        }
                        {message.contentType &&
                            (message.contentType === 'string' ||
                                message.contentType === 'text')
                            && (
                                <div style={{ padding: '4px', overflow: 'hidden' }}>
                                    <ReactMarkdown>{message.content}</ReactMarkdown>
                                </div>
                            )}
                        {message.contentType && message.contentType === 'JSON' && (
                            <div style={{ padding: '4px', overflow: 'hidden' }}>
                                {(() => {
                                    const payload = message.content;
                                    if (Array.isArray(payload)) {
                                        return <HitchTableV2 object={payload} />;
                                    } else {
                                        return <JSONTreeViewV2 data={payload} />;
                                    }
                                })()}
                            </div>
                        )}
                        {message.contentType && (message.contentType === 'Link' || message.contentType === 'link') && (
                            <div style={{ padding: '4px', overflow: 'hidden' }}>
                                <MessageLinkV2 message={message} />
                            </div>
                        )}
                        {message.contentType && (message.contentType === 'Product' || message.contentType === 'product') && (
                            <div style={{ padding: '4px', overflow: 'hidden' }}>
                                <MessageProductV2 message={message} />
                            </div>
                        )}
                        {message.contentType && message.contentType === 'map' && (
                            <div style={{ padding: '4px', overflow: 'hidden', width: '1160px' }}>
                            </div>
                        )}
                        {message.contentType && message.contentType === 'MessageAdaptiveCard' && (
                            <div style={{ padding: '4px', overflow: 'hidden', width: '1160px' }}>
                                <MessageAdaptiveCardV2 message={message} />
                            </div>
                        )}
                    </div>
                </div>

                
            </div>

            <Modal
                open={isFeedbackModalOpen}
                onClose={handleFeedbackCancel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <IconButton
                        aria-label="close"
                        onClick={handleModalClose}
                        sx={{
                            position: 'absolute',
                            right: 16,
                            top: 16,
                            color: themeModalTextSecondaryColor,
                        }}
                    >
                        <FontAwesomeIcon icon={faTimes} size="1x" />
                    </IconButton>
                    {/* Modal Title */}
                    <Typography variant="h6" style={{ color: themeModalTextSecondaryColor, fontFamily: '"Poppins", sans-serif', marginBottom: '20px' }}>
                        Provide additional feedback
                    </Typography>
                    <TextField
                        placeholder="What was the issue with the response? How could it be improved?"
                        value={negativeFeedback}
                        onChange={handleFeedbackChange}
                        style={{ marginTop: '32px' }}
                        multiline={true}
                        maxRows={5}
                        fullWidth
                        variant="outlined"
                        error={feedbackError}
                        helperText={feedbackError ? "Feedback is required" : ''}
                        InputLabelProps={{
                            style: {
                                color: 'grey',
                                //fontSize: '18px',
                                fontFamily: '"Poppins", sans-serif',
                            },
                        }}
                        InputProps={{
                            style: {
                                color: themeModalTextSecondaryColor,
                               // fontSize: '18px',
                                fontFamily: '"Poppins", sans-serif',
                            },
                            sx: {
                                '& .MuiOutlinedInput-notchedOutline': { borderColor: feedbackError ? 'red' : 'grey' },
                                '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: feedbackError ? 'red' : '#00EDED' },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: feedbackError ? 'red' : '#00EDED' },
                            },
                        }}
                    />
                    <Box display="flex" justifyContent="flex-end" marginTop="32px">
                        <Button onClick={handleFeedbackCancel} sx={cancelButtonStyleRename}>Cancel</Button>
                        <Button onClick={handleFeedbackSubmit} sx={saveButtonStyleRename}>Send Feedback</Button>
                    </Box>
                </Box>
            </Modal>



        </Box>
    );
}

export default MessageV2;
