import React, { useEffect, useState } from "react";
import { TextField, Button } from "@mui/material";

function SimplePropertyForm({ propertyName, property, onUpdate }) {
  const [editedValue, setEditedValue] = useState(property);

  useEffect(() => {
    setEditedValue(property);
  }, [property])

  const handleValueChange = (event) => {
    setEditedValue(event.target.value);
  };

  const handleUpdateClick = () => {
    onUpdate(propertyName, editedValue);
  };

  return (
    <div>
      <TextField
        label={propertyName}
        variant="outlined"
        fullWidth
        value={editedValue}
        onChange={handleValueChange}
      />
      <Button variant="contained" color="primary" onClick={handleUpdateClick}>
        Update
      </Button>
    </div>
  );
}

export default SimplePropertyForm;
