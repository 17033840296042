import React, { useState, useEffect } from 'react';
import { Box, Grid, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, FormControl, InputLabel, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useAdminHubConnections } from '../../utils/HubAdminConnectionProvider';
import { useHubConnections } from '../../utils/HubConnectionsProvider';

function UserAccountRoles({ user, editMode, loading, setLoading }) {

    const [roles, setRoles] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [roleToDelete, setRoleToDelete] = useState(null);
    const [newRole, setNewRole] = useState({ accountId: '', role: '' });
    const [accounts, setAccounts] = useState([]);
    const [accountRoles, setAccountRoles] = useState([]);

    const { adminConnection, hitchUser, isConnectionReady } = useAdminHubConnections();
    const {         
        defaultSnack,
        infoSnack,
        warningSnack,
        successSnack,
        errorSnack, 
    } = useHubConnections();

    useEffect(() => {
        if (adminConnection) {
            adminConnection.on('AccountUserRoles', (data) => {
                console.log('AccountUserRoles', data);
                setRoles(data);
                setLoading(false);
            });
            adminConnection.on('AccountRoles', (data) => {
                console.log('AccountRoles', data);
                setAccountRoles(data);
                setLoading(false);
            });
            adminConnection.on('Accounts', (data) => {
                console.log('Accounts', data);
                setAccounts(data);
            });

            getRoles();
            return () => {
                adminConnection.off('AccountUserRoles');
                adminConnection.off('AccountRoles');
                adminConnection.off('Accounts');
            };
        }
    }, [adminConnection]);

    function addUserToAccountWithRole(accountId, roleId) {
        adminConnection.invoke("AddUserToAccountWithRole", hitchUser.userId, user.id, accountId, roleId)
        .then(() => {
            infoSnack('Adding user to role');
            setLoading(true);
        })
        .catch(error => console.error("Error sending message:", error));
    }

    function removeUserFromAccountWithRole(roleId) {
        adminConnection.invoke("DeleteUserFromAccountWithRole", hitchUser.userId, roleId )
        .then(() => {
            infoSnack('Removing user from role');
            setLoading(true);
        })
        .catch(error => console.error("Error sending message:", error));
    }

    function getRoles() {
        adminConnection.invoke("GetAccountUserRoles", hitchUser.userId, user.id)
            .then(() => {
                console.log('i asked for userRoles');
                setLoading(true);
            })
            .catch(error => console.error("Error sending message:", error));

        adminConnection.invoke("GetAccounts", hitchUser.userId, '')
            .then(() => {
                console.log('i asked for accounts');
                setLoading(true);
            })
            .catch(error => console.error("Error sending message:", error));
    }

    function getAccountRoles(accountId) {
        adminConnection.invoke("GetAccountRoles", hitchUser.userId, accountId)
        .then(() => {
            console.log('i asked for accountRoles');
            setLoading(true);
        })
        .catch(error => console.error("Error sending message:", error));
    }

    const handleRemoveRole = (index) => {
        setRoleToDelete(index);
        setOpenDialog(true);
    };

    const confirmRemoveRole = () => {
        removeUserFromAccountWithRole(roleToDelete);
        // setRoles(prevState => prevState.filter((_, i) => i !== roleToDelete));
        setOpenDialog(false);
        setRoleToDelete(null);
    };

    const handleAddRole = () => {
        addUserToAccountWithRole(newRole.accountId, newRole.role);
        setNewRole({ accountId: '', role: '' });
        setOpenAddDialog(false);
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
        setNewRole({ accountId: '', role: '' });
    };

    const handleNewRoleChange = (e) => {
        const { name, value } = e.target;
        console.log(`name: ${name}, value: ${value}`);
        setNewRole(prevState => ({
            ...prevState,
            [name]: value
        }));

        if(name === 'accountId') {
            // LOAD ROLES FOR ACCOUNT
            getAccountRoles((value));
        }
        
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Account</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {roles.map((role, index) => (
                            <TableRow key={role.userRoleId}>
                                <TableCell sx={{ padding: '4px 8px' }}>{role.accountName}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{role.roleName}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    <IconButton color="secondary" onClick={() => handleRemoveRole(role.userRoleId)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <IconButton
                color="primary"
                onClick={handleOpenAddDialog}
                sx={{ position: 'absolute', top: 8, right: 8 }}
            >
                <AddIcon />
            </IconButton>

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this role?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmRemoveRole} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openAddDialog}
                onClose={handleCloseAddDialog}
                aria-labelledby="form-dialog-title"
                maxWidth="sm" // Set the maxWidth to 'sm' (small), 'md' (medium), 'lg' (large), or 'xl' (extra-large)
                fullWidth // Ensure the dialog takes the full width of the specified maxWidth
            >
                <DialogTitle id="form-dialog-title">Add New Role</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="account-select-label">Account</InputLabel>
                        <Select
                            labelId="account-select-label"
                            name="accountId"
                            value={newRole.accountId}
                            onChange={handleNewRoleChange}
                        >
                            {accounts.map(account => (
                                <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="role-select-label">Role</InputLabel>
                        <Select
                            labelId="role-select-label"
                            name="role"
                            value={newRole.role}
                            onChange={handleNewRoleChange}
                            disabled={!newRole.accountId}
                        >
                             {accountRoles?.map(role => (
                                <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                            ))} 
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddRole} color="primary" disabled={!newRole.accountId || !newRole.role}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default UserAccountRoles;
