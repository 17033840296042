import { Avatar, Box, Button, ButtonGroup, Divider, FormControl, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField, Tooltip, Typography } from "@mui/material";
import TrainingFile from "./TrainingFile";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faIceCream, faTransformerBolt } from "@fortawesome/pro-solid-svg-icons";
import AddStepModal from "./AddStepModal";
import StepListItem from "./StepListItem";
import TrainingTrainer from "./TrainingTrainer";
import TrainingOverview from "./TrainingOverview";
import TrainingChat from "./TrainingChat";
import Models from "./Models";

function TrainingEditor({ 
    conversation,
    fileName, 
    guid,
    hitchUser, 
    hubConnection, 
    messages,
    numLines, 
    outputType, 
    setOutputType, 
    sessionId, 
    setMessages,
    setSteps, 
    steps, 
    trainingLine, 
    setTrainingLine, 
    user }) {

    const [systemMessage, setSystemMessage] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [openStepper, setOpenStepper] = useState(false);
    const [selectedStep, setSelectedStep] = useState(null);
    const [reset, setReset] = useState(false);
    const [mode, setMode] = useState('');
    const [userMessage, setUserMessage] = useState('');

    // FROM CONVERSATION DEPENDENCIES
    const [chatBoxKey, setChatBoxKey] = useState(0);
    const [newMessage, setNewMessage] = useState('');
    const [useChatGpt, setUseChatGpt] = useState(false);
    
    const [isChatBoxActive, setIsChatBoxActive] = useState(false); // Add this state variable

    const chatContainerRef = useRef(null);
    
    const [editingMessage, setEditingMessage] = useState(null);
    const [editedMessageText, setEditedMessageText] = useState('');
    const [users, setUsers] = useState([]);

    useEffect(() => {
        console.log('hitchUser', hitchUser);
        console.log('systemMessage', systemMessage);
        if (hitchUser && (!systemMessage || systemMessage === '')) {
            setSystemMessage(defaultMessage());
        }
    }, [hitchUser, systemMessage])

    useEffect(() => {
        if (reset) {
            // hubConnection reset on server
        }
    }, [reset]);

    function savePromptOperation(payload) {
        // Check if payload has a step_id
        if (!payload || !payload.step_id) {
            console.error("Payload is missing step_id.");
            return;
        }

        if (hubConnection) {
            hubConnection.invoke("SavePromptOperation", sessionId)
                .then(() => {
                    // Do Nothing.  When Request is returned, it should update the page.

                    hubConnection.invoke("SelectPromptOperation", sessionId, null)
                        .then(() => {
                            // Do Nothing.  When Request is returned, it should update the page.
                        })
                        .catch(error => console.error("Error sending message:", error));

                })
                .catch(error => console.error("Error sending message:", error));
        }


        handleCloseModal();
    }

    function setTheStep(data) {
        console.log('setting step', data);
        // SetTheOperation 
        if (hubConnection) {
            hubConnection.invoke("SelectPromptOperation", sessionId, data)
                .then(() => {
                    // Do Nothing.  When Request is returned, it should update the page.
                    setSelectedStep(data);
                    setOpenStepper(true);
                })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    function runTraining() {
        if (hubConnection) {
            console.log('running training');
            hubConnection.invoke("ComputeLine", sessionId, systemMessage, userMessage)
                .then(() => {
                    // Do Nothing.  When Request is returned, it should update the page.
                })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    function testPromptRunner() {
        if (hubConnection) {
            console.log('sending the package to chat package runner for testing.');
            hubConnection.invoke("TestInPackageRunner", sessionId)
                .then(() => {
                    // Do Nothing.  When Request is returned, it should update the page.
                })
                .catch(error => console.error("Error sending message:", error));
        }
    }


    function formulateSystemMessage(data) {
        if (data && data.length > 0) {
            setSystemMessage(data);
        } else {
            setSystemMessage(defaultMessage());
        }
    }

    function defaultMessage() {
        var newDate = new Date().toLocaleString();
        return `{"UserId": "${hitchUser?.id}", "AccountId": "${hitchUser?.accountId}", "CurrentDateTime": "${newDate}", "AssistantName": "Hitchy", "Assistant Instructions": "You are a helpful assistant, and you are branded to help our customers.  You know the Truth, and the Truth sets us free." }`;
    }

    function addAStep() {
        console.log('add a step');
        setOpenStepper(true);
    }

    function handleCloseModal() {
        setOpenStepper(false);
    }

    // COPIED FROM CONVERSATION
    const handleSendMessage = () => {
        if (newMessage.content !== '') {   // if (newMessage.trim() !== '') {
            // Adjust logic here to use newMessagecontent
            if (hubConnection) {
                hubConnection.invoke("SendMessage", sessionId, newMessage.content, useChatGpt)
                    .then(() => {
                        // setLoading(true);
                    })
                    .catch(error => console.error("Error sending message:", error));
            }

            const newMessageToAdd = {
                text: newMessage.content,  //was writing to message instead of payload
                content: newMessage.content,  //was writing to message instead of payload
                contentType: 'string',
                type: 'user',  //is 'User' correct?  changed this from type: 'User'
                user: newMessage.user // Include the user's ID
            };
            // const newMessageToAdd = newUserMessage(newMessagecontent);
    
            setMessages(prevMessages => [...prevMessages, newMessageToAdd]);
    
            // Reset newMessage to initial structure
            setNewMessage({ content: '', contentType: 'String', user: null });
        }
    };

    const isHelpful = (messageId) => {
        // 
        if (hubConnection) {
            hubConnection.invoke("IsHelpful", sessionId, messageId)
                .then(() => {
                })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    const isNotHelpful = (messageId) => {
        // 
        if (hubConnection) {
            hubConnection.invoke("IsNotHelpful", sessionId, messageId)
                .then(() => {
                })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    const saveEditedMessage = () => {
        if (editingMessage) {
            editingMessage.text = [editedMessageText];
            setEditingMessage(null);
            setEditedMessageText('');
            console.log('editingMessage', editingMessage);
        }
    };



    return (
        <Grid
            item
            xs={12}
            sx={{
                backgroundColor: "#FFFFFF",
                marginTop: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
                color: "#333333"
            }}>
            <Grid container>
                <Grid item xs={12}>
                    <TrainingFile
                        fileName={fileName}
                        mode={mode}
                        numberLines={numLines}
                        setMode={setMode}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ margin: "10px" }} />
                </Grid>

                <TrainingOverview
                    addAStep={addAStep}
                    formulateSystemMessage={formulateSystemMessage}
                    mode={mode}
                    selectedStep={selectedStep}
                    setTheStep={setTheStep}
                    setUserMessage={setUserMessage}
                    steps={steps}
                    systemMessage={systemMessage}
                    userMessage={userMessage}
                />
                <TrainingTrainer
                    formulateSystemMessage={formulateSystemMessage}
                    runTraining={runTraining}
                    testPromptRunner={testPromptRunner}
                    systemMessage={systemMessage}
                    mode={mode}
                    userMessage={userMessage}
                    setUserMessage={setUserMessage}
                    trainingLine={trainingLine}
                    setTrainingLine={setTrainingLine} />
                {mode === 'chat' && (<TrainingChat
                    chatBoxKey={chatBoxKey}
                    chatContainerRef={chatContainerRef}
                    conversation={conversation}
                    editedMessageText={editedMessageText}
                    editingMessage={editingMessage}
                    guid={guid}
                    handleSendMessage={handleSendMessage}
                    hitchUser={hitchUser}
                    isChatBoxActive={isChatBoxActive}
                    isHelpful={isHelpful}
                    isNotHelpful={isNotHelpful}
                    messages={messages}
                    newMessage={newMessage}
                    saveEditedMessage={saveEditedMessage}
                    setEditingMessage={setEditingMessage}
                    setEditedMessageText={setEditedMessageText}
                    setNewMessage={setNewMessage}
                    setUseChatGpt={setUseChatGpt}
                    useChatGpt={useChatGpt}
                    user={user}
                    users={users}
                />)}
                {mode === 'model' && (
                    <Models
                        hubConnection={hubConnection}
                        sessionId={sessionId} />
                )}

            </Grid>
            <AddStepModal
                hubConnection={hubConnection}
                openStepper={openStepper}
                sessionId={sessionId}
                handleCloseModal={handleCloseModal}
                savePromptOperation={savePromptOperation}
                reset={reset}
                setReset={setReset}
            />
        </Grid>

    )
}

export default TrainingEditor;