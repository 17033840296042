import React, { createContext, useContext } from 'react';
import { useTheme } from '@mui/material/styles';

const FileListStyleContext = createContext();

export const FileListStyleProvider = ({ children }) => {
    const theme = useTheme();
    
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: '600px',
        backgroundColor: theme.palette.modalBackground[theme.palette.mode], // Use theme color
        color: theme.palette.modalTextSecondary[theme.palette.mode], // Use theme color for text
        border: '1px solid #2A2E39',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.6)', // Subtle shadow for depth
        p: 4,
        textAlign: 'center',
        borderRadius: '10px',
        fontFamily: '"Poppins", sans-serif',
    };

    const buttonStyle = {
        fontFamily: 'Poppins, sans-serif',
        color: '#000000',
        backgroundColor: '#00EDED',
        '&:hover': {
            backgroundColor: '#00EDED',
        },
        '&:active': {
            backgroundColor: '#00EDED',
            boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.3)',
        },
        marginLeft: '20px',
    };

    const cancelButtonStyleDelete = {
        color: 'white',
        backgroundColor: '#333',
        border: '1px solid #555',
        fontFamily: 'Poppins, sans-serif',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
    };

    const deleteButtonStyle = {
        fontFamily: 'Poppins, sans-serif',
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'darkred',
        },
        '&:active': {
            backgroundColor: 'firebrick',
            boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.3)',
        },
        marginLeft: '20px',
    };

    const value = {
        modalStyle,
        buttonStyle,
        cancelButtonStyleDelete,
        deleteButtonStyle,
        themeDividerColor: theme.palette.secondaryDivider[theme.palette.mode],
        themeModalTextSecondaryColor: theme.palette.modalTextSecondary[theme.palette.mode]
    };

    return (
        <FileListStyleContext.Provider value={value}>
            {children}
        </FileListStyleContext.Provider>
    );
};

export const useFileListStyles = () => useContext(FileListStyleContext);
