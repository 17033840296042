import { Autocomplete, Badge, Button, ButtonGroup, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { useEffect, useMemo, useState } from "react";
import MatrixPhoneForm from "./MatrixPhoneForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable } from "@fortawesome/pro-solid-svg-icons";
import MatrixItem from "./MatrixItem";

const darkTheme = createTheme({ palette: { mode: 'dark' } });

function MatrixPhone({ handlePhoneClose, hitchPhone, hitchCommand, hitchDiscovery, matrixRoutes }) {
    const [pairs, setPairs] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [selectedOperation, setSelectedOperation] = useState(null);
    const [advancedOpen, setAdvancedOpen] = useState(false);
    const [advanceCount, setAdvanceCount] = useState(0);
    const [discovered, setDiscovered] = useState({});

    useEffect(() => {
        console.log('matrixRoutes', matrixRoutes);
    }, [matrixRoutes]);

    useEffect(() => {
        if (hitchPhone) {
            console.log('Calling for Routes');
            hitchCommand('account', 'connection', 'route');
        }
    }, [hitchPhone]);

    const serviceOptions = useMemo(() => {
        const uniqueServices = Array.from(new Set(matrixRoutes.map(item => item.system)));
        return uniqueServices.map(service => ({ label: service }));
    }, [matrixRoutes]);

    const featureOptions = useMemo(() => {
        if (!selectedService) return [];
        const features = matrixRoutes
            .filter(route => route.system === selectedService)
            .map(route => route.operationType);

        const uniqueFeatures = Array.from(new Set(features)); // Remove duplicates
        return uniqueFeatures.map(feature => ({ label: feature }));
    }, [matrixRoutes, selectedService]);

    const operationOptions = useMemo(() => {
        if (!selectedService || !selectedFeature) return [];
        return matrixRoutes
            .filter(route => route.system === selectedService && route.operationType === selectedFeature)
            .map(route => ({ label: route.operationSubtype }));
    }, [matrixRoutes, selectedService, selectedFeature]);

    useEffect(() => {
        setAdvanceCount(pairs.length);
    }, [pairs]);

    const handleClear = () => {
        setSelectedService(null);
        setSelectedFeature(null);
        setSelectedOperation(null);
        setPairs([]);
    };

    function sendCommand() {
        // Convert pairs array to dictionary
        const pairsDictionary = pairs.reduce((acc, pair) => {
            acc[pair.name] = pair.value;
            return acc;
        }, {});

        console.log('Sending Command', {
            'selectedService': selectedService,
            'selectedFeature': selectedFeature,
            'selectedOperation': selectedOperation,
            'pairs': pairsDictionary
        });

        hitchCommand(selectedService, selectedFeature, selectedOperation, pairsDictionary);
        handlePhoneClose();
    }

    function sendDiscovery() {
        console.log('Sending Discovery', {
            'selectedService': selectedService,
            'selectedFeature': selectedFeature,
            'selectedOperation': selectedOperation
        });

        hitchDiscovery(selectedService, selectedFeature, selectedOperation);
    }

    function onOperationChange(event, newValue) {
        if(discovered) {
            console.log("discovered", discovered);
        }

        setSelectedOperation(newValue?.label);
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <MatrixItem elevation={24}>
                <Grid container alignItems='center' justifyContent='center'>
                    <Grid item xs={3} sx={{ mx: 1 }}>
                        <Autocomplete
                            disablePortal
                            id="serviceList"
                            options={serviceOptions}
                            value={selectedService}
                            sx={{ width: '100%' }}
                            onChange={(event, newValue) => {
                                setSelectedService(newValue?.label);
                                setSelectedFeature(null);
                                setSelectedOperation(null);
                            }}
                            renderInput={(params) => <TextField {...params} label="Service" />}
                        />
                    </Grid>

                    <Grid item xs={3} sx={{ mx: 1 }}>
                        <Autocomplete
                            disablePortal
                            id="featureList"
                            options={featureOptions}
                            value={selectedFeature}
                            sx={{ width: '100%' }}
                            onChange={(event, newValue) => {
                                setSelectedFeature(newValue?.label);
                                setSelectedOperation(null);
                            }}
                            renderInput={(params) => <TextField {...params} label="Feature" />}
                        />
                    </Grid>

                    <Grid item xs={3} sx={{ mx: 1 }}>
                        <Autocomplete
                            disablePortal
                            id="operationList"
                            options={operationOptions}
                            value={selectedOperation}
                            sx={{ width: '100%' }}
                            onChange={(event, newValue) => onOperationChange(event, newValue)}
                            renderInput={(params) => <TextField {...params} label="Operation" />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup>
                            <Button onClick={(e) => handlePhoneClose()}><Typography m={1}>Close</Typography></Button>
                            <Button onClick={handleClear}><Typography m={1}>Clear</Typography></Button>
                            <Button

                                onClick={(e) => setAdvancedOpen(!advancedOpen)}>
                                <Badge color="warning" badgeContent={advanceCount}>
                                    <FontAwesomeIcon icon={faTable} />
                                </Badge>
                                <Typography m={1}>Advanced</Typography>
                            </Button>
                            <Button onClick={(e) => sendCommand()}><Typography m={1}>Send</Typography></Button>

                        </ButtonGroup>
                    </Grid>
                    {advancedOpen && (
                        <Grid item xs={12}>
                            <Divider />
                            <Grid container mt={2} mb={2}>
                                <Grid item xs={3}>
                                    <ButtonGroup
                                        variant="outlined"
                                        orientation="vertical">
                                        <Button disabled><Typography m={1}>Load</Typography></Button>
                                        <Button disabled><Typography m={1}>Save</Typography></Button>
                                        <Button disabled><Typography m={1}>Delete</Typography></Button>

                                    </ButtonGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <MatrixPhoneForm
                                        pairs={pairs}
                                        setAdvanceCount={setAdvanceCount}
                                        setPairs={setPairs} />
                                </Grid>
                                <Grid item xs={3}>HA!</Grid>
                            </Grid>
                        </Grid>
                    )}

                </Grid>
            </MatrixItem>
        </ThemeProvider>
    );
}

export default MatrixPhone;