import React, { useState } from 'react';
import { Box, Button, Modal, Tab, TextField, IconButton, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import { useHubConnections } from '../../utils/HubConnectionsProvider';

function ConversationMainTab({ conversation, setName, onSelect, selected, tabStyle }) {
    const theme = useTheme();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editName, setEditName] = useState('');
    const [conversationNameError, setConversationNameError] = useState(false);

    const handleModalOpen = (name) => {
        setEditName(name);
        setIsModalOpen(true);
        setConversationNameError(false); // Reset error state when modal opens
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setConversationNameError(false); // Reset error state when modal opens
    };

    const handleSave = () => {
        if (!editName.trim()) { // Check if the input is empty or just spaces
            setConversationNameError(true); // Set error state to true
            return; // Prevent the modal from closing
        }
        //console.log('Chat to Rename GUID:', conversation.id);
        //console.log('Current Name:', editName);
        setName(editName, conversation.id); // Pass both the new name and the conversation ID
        handleModalClose();
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%', // Max width for smaller devices
        maxWidth: {
            xs: '90%', // max width on extra small devices
            sm: '80%', // max width on small devices
            md: '70%', // max width on medium devices
            lg: '600px', // max width on large devices and up
        },
        backgroundColor: theme.palette.modalBackground[theme.palette.mode],
        color: 'white',
        borderColor: 'gray',
        borderStyle: 'solid',
        borderWidth: '1px',
        boxShadow: '0 4px 8px 0 rgba(255, 255, 255, 0.2)',
        p: 4,
        position: 'relative',
        textAlign: 'center',
        borderRadius: '10px',
        fontFamily: '"Poppins", sans-serif',
        zIndex: '2600 !important', // Force zIndex to ensure the modal is on top
    };

    const saveButtonStyle = {
        fontFamily: 'Poppins, sans-serif',
        color: '#4A4A68',
        backgroundColor: '#00EDED',
        '&:hover': {
            backgroundColor: '#00EDED', // Darker shade on hover
        },
        '&:active': {
            backgroundColor: '#00EDED', // Different shade when pressed
            boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.3)', // Optional: adds an inset shadow to simulate a pressed effect
        },
        marginLeft: '20px', // Increased spacing between buttons
    };

    const cancelButtonStyle = {
        color: 'white',
        backgroundColor: '#333',
        border: '1px solid #555',
        fontFamily: 'Poppins, sans-serif',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
    };

    return (
        <>
            <Tab
                label={
                    <Box
                        display="flex"
                        alignItems="center"
                        onClick={() => handleModalOpen(conversation ? conversation.name : '')}
                    >
                        {conversation ? conversation.name : 'Untitled'}
                        <FontAwesomeIcon icon={faPencil} style={{ marginLeft: '16px' }} />
                    </Box>
                }
                style={tabStyle}
            />

            <Modal sx={{ zIndex: 2501 }} // Apply zIndex here
            open={isModalOpen} onClose={handleModalClose}>
                <Box sx={modalStyle}>
                    <IconButton
                        aria-label="close"
                        onClick={handleModalClose}
                        sx={{
                            position: 'absolute',
                            right: 16,
                            top: 16,
                            color: 'white',
                            zIndex: 2505,
                        }}
                    >
                        <FontAwesomeIcon icon={faTimes} size="1x" />
                    </IconButton>
                    <Typography variant="h6" style={{ color: 'white', fontFamily: '"Poppins", sans-serif', marginBottom: '20px' }}>
                        Rename Chat
                    </Typography>
                    <TextField
                        label="Chat Name"
                        value={editName}
                        style={{ marginTop: '32px' }}
                        onChange={(e) => {
                            setEditName(e.target.value);
                            setConversationNameError(false);
                        }}
                        fullWidth
                        variant="outlined"
                        error={conversationNameError === undefined} // Use the error state to control the TextField's error display
                        helperText={conversationNameError ? "Chat Name is required" : ''} // Display error message
                        InputLabelProps={{
                            style: {
                                color: 'grey',
                                fontSize: '18px',
                                fontFamily: '"Poppins", sans-serif',
                            },
                        }}
                        InputProps={{
                            style: {
                                color: 'white',
                                fontSize: '18px',
                                fontFamily: '"Poppins", sans-serif',
                            },
                            sx: {
                                '& .MuiOutlinedInput-notchedOutline': { borderColor: conversationNameError ? 'red' : 'grey' },
                                '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: conversationNameError ? 'red' : '#00EDED' },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: conversationNameError ? 'red' : '#00EDED' },
                            },
                        }}
                    />
                    <Box display="flex" justifyContent="flex-end" marginTop="32px">
                        <Button onClick={handleModalClose} sx={cancelButtonStyle}>Cancel</Button>
                        <Button onClick={handleSave} sx={saveButtonStyle}>Save</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

export default ConversationMainTab;
