import React, { useEffect } from 'react';
import paymentDetails from './paymentDetails.json';
import { useHubConnections } from '../utils/HubConnectionsProvider';

const NewPlanSummary = ({planData}) => {
  const nextPaymentDate = new Date();
  nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);

  
  return (
    <>
      <div style={{ marginBottom: '16px', textAlign: 'left' }}>
        <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Plan Details</p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>Plan Name:</p>
          <p>{planData?.newPlan}</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>Plan Price:</p>
          <p>{planData?.newPlanPrice} </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>Billing Cycle:</p>
          <p>Monthly</p>
        </div>
      </div>
      <hr style={{ width: '100%', borderTop: '1px solid rgba(255, 255, 255, 0.5)', marginBottom: '16px' }} />
      <div style={{ marginBottom: '16px', textAlign: 'left' }}>
        <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Payment Summary</p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>Total Due Today:</p>
          <p>{planData?.dueToday}</p>
        </div>
        <div style={{ fontStyle: 'italic', color: 'lightgrey', marginLeft: 'auto', marginRight: '0' }}>
          <p>+ Taxes where applicable</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
          <p>Next Payment Date:</p>
          <p>{planData?.nextPaymentDate}</p>
        </div>
      </div>
    </>
  );
};

export default NewPlanSummary;
