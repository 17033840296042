import React, { useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import { faBuilding, faChevronsLeft, faChevronsRight, faCircleNodes, faFilterList, faFolderGear, faFolderUser, faFunnelDollar, faPlug, faPuzzle, faRocket, faRulerTriangle, faSitemap, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { faTrash } from '@fortawesome/pro-duotone-svg-icons';

function AdminDrawer({
  setState,
  state,
  activeOrganization,
  setActiveOrganization,
  activeService,
  setActiveService,
  activeUser,
  setActiveUser
}) {
  useEffect(() => {
    // console.log('activeService', activeService);
  }, [activeService]);

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={() => setState(false)}
      onKeyDown={() => setState(false)}
    >
      <Box m={2}>
        <Typography variant='h6'>
          <IconButton onClick={() => setState(true)}><FontAwesomeIcon icon={faChevronsLeft} /></IconButton>
          Admin Menu</Typography>
      </Box>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="./cluster">
            <ListItemIcon>
              <FontAwesomeIcon icon={faCircleNodes} />
            </ListItemIcon>
            <ListItemText primary="Cluster" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="./services">
            <ListItemIcon>
              <FontAwesomeIcon icon={faPuzzle} />
            </ListItemIcon>
            <ListItemText primary="Services" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to="./plan">
            <ListItemIcon>
              <FontAwesomeIcon icon={faRulerTriangle} />
            </ListItemIcon>
            <ListItemText primary="Plans" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to="./offer">
            <ListItemIcon>
              <FontAwesomeIcon icon={faFunnelDollar} />
            </ListItemIcon>
            <ListItemText primary="Offers" />
          </ListItemButton>
        </ListItem>

        {activeService && (
          <>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={activeService.name} />
                <Tooltip title="Unselect Service">
                  <Button>
                    <FontAwesomeIcon icon={faTrash} onClick={(e) => setActiveService(null)} />
                  </Button>
                </Tooltip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="./features">
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFilterList} />
                </ListItemIcon>
                <ListItemText primary="Features" />
              </ListItemButton>
            </ListItem>
            <Divider />
          </>
        )}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="./organizations">
            <ListItemIcon>
              <FontAwesomeIcon icon={faSitemap} />
            </ListItemIcon>
            <ListItemText primary="Organizations" />
          </ListItemButton>
        </ListItem>
        {activeOrganization && (
          <>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={activeOrganization.name} />
                <Tooltip title="Unselect Organization">
                  <Button>
                    <FontAwesomeIcon icon={faTrash} onClick={(e) => setActiveOrganization(null)} />
                  </Button>
                </Tooltip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="./connections">
                <ListItemIcon>
                  <FontAwesomeIcon icon={faPlug} />
                </ListItemIcon>
                <ListItemText primary="Connections" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="./roles">
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFolderGear} />
                </ListItemIcon>
                <ListItemText primary="Roles" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton>
                {/* component={Link} to="./training" */}
                <ListItemIcon>
                  <FontAwesomeIcon icon={faRocket} />
                </ListItemIcon>
                <ListItemText primary="Training" />
              </ListItemButton>
            </ListItem>
            <Divider />
          </>
        )}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="./users">
            <ListItemIcon>
              <FontAwesomeIcon icon={faUsers} />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemButton>
        </ListItem>
        {activeUser && (
          <>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={activeUser.name} />
                <Tooltip title="Unselect User">
                  <Button>
                    <FontAwesomeIcon icon={faTrash} onClick={(e) => setActiveUser(null)} />
                  </Button>
                </Tooltip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="./userroles">
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFolderUser} />
                </ListItemIcon>
                <ListItemText primary="User Roles" />
              </ListItemButton>
            </ListItem>
            <Divider />
          </>
        )}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={'left'}>
        {/* <Button onClick={toggleDrawer('left', true)}></Button> */}
        <Drawer
          open={state}
          onClose={() => setState(false)}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
      <IconButton onClick={() => setState(true)} >
        <FontAwesomeIcon icon={faChevronsRight} />
      </IconButton>
    </div>
  )
}

export default AdminDrawer;