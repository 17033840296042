// JSONTreeView.jsx
import React from 'react';

const JSONTreeView = ({ data }) => {
  const renderTree = (node) => {
    if (typeof node === 'object' && node !== null) {
      return (
        <ul>
          {Object.entries(node).map(([key, value]) => (
            <li key={key}>
              <strong>{key}:</strong> {renderTree(value)}
            </li>
          ))}
        </ul>
      );
    }
    return <span>{String(node)}</span>;
  };

  return <div style={{ overflowY: 'auto', maxHeight: '400px' }}>{renderTree(data)}</div>;
};

export default JSONTreeView;
