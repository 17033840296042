import React, { useState, useEffect } from "react";
import { Box, Button, Grid, TextField, Tabs, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Collapse } from "@mui/material";
import AdminSearchGridV2 from "../AdminSearchGridV2";
import UserDetails from "./UserDetails";
import UserAccountRoles from "./UserAccountRoles";
import UserLeadRecords from "./UserLeadRecords";
import UserTenantRecords from "./UserTenantRecords";
import { useAdminHubConnections } from "../../utils/HubAdminConnectionProvider";
import CustomTab from "./CustomTab";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HitchBox from "../../components/hitchbox/HitchBox";
import { useHubConnections } from "../../utils/HubConnectionsProvider";

const Users = ({ activeUser, setActiveUser }) => {
    const [list, setList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [userTabValue, setUserTabValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [expandedDetails, setExpandedDetails] = useState(true);
    const [expandedRoles, setExpandedRoles] = useState(true);
    const [expandedLeads, setExpandedLeads] = useState(true);
    const [expandedTenants, setExpandedTenants] = useState(true);
    const [userToDelete, setUserToDelete] = useState(null);
    const { adminConnection, hitchUser, isConnectionReady } = useAdminHubConnections();
    const {
        defaultSnack,
        infoSnack,
        warningSnack,
        successSnack,
        errorSnack,
    } = useHubConnections();

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'User Name', width: 200 },
        { field: 'email', headerName: 'Email Address', width: 300 }
    ];

    useEffect(() => {
        if (adminConnection) {
            const subscribeToEvents = () => {
                adminConnection.on('Users', (data) => {
                    console.log('Users', data);
                    setLoading(true);
                    setList(data);
                });

                adminConnection.on('User', (data) => {
                    console.log('User', data);
                    const editingModel = data;
                    setSelectedUsers((prevUsers) => [...prevUsers, editingModel]);
                });

                adminConnection.on('AccountRoles', (data) => {
                    console.log('AccountRoles', data);
                });

                adminConnection.on('IdentityRecords', (data) => {
                    console.log('IdentityRecords', data);
                });

                adminConnection.on('Leads', (data) => {
                    console.log('Leads', data);
                });

                // Add other event subscriptions here
            };

            subscribeToEvents();

            adminConnection.invoke("GetUsers", hitchUser?.userId, searchTerm)
                .then(() => {
                    console.log('i asked for users');
                    setLoading(true);
                })
                .catch(error => console.error("Error sending message:", error));

            return () => {
                adminConnection.off('Users');
                adminConnection.off('User');
                // Unsubscribe from other events here
            };
        }
    }, [adminConnection]);

    const filter = (term) => {
        if (adminConnection) {
            adminConnection.invoke("GetUsers", hitchUser?.userId, term)
                .then(() => setLoading(true))
                .catch(error => console.error("Error sending message:", error));
        }
    };

    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        if (term) {
            filter(term);
        } else {
            setList([]); // Show full list when search is cleared
        }
    };

    const handleSearchFocus = () => {
        filter(''); // Show full list on focus
    };

    const handleRowClick = (params) => {
        console.log('Selected user:', params.row);
        const selectedUser = list.find(user => user.id === params.row.id);
        setSelectedUsers(prevUsers => [...prevUsers, selectedUser]);
        setSearchTerm(''); // Clear search term to hide the search list
        setList([]); // Hide the list after selection
        setUserTabValue(selectedUsers.length); // Switch to the newly added tab
    };

    const handleUserTabChange = (event, newValue) => {
        setUserTabValue(newValue);
    };

    const handleTabClose = (index) => {
        setSelectedUsers((prevUsers) => prevUsers.filter((_, i) => i !== index));
        if (userTabValue === index && selectedUsers.length > 1) {
            setUserTabValue(index === 0 ? 0 : index - 1);
        } else if (selectedUsers.length === 1) {
            setUserTabValue(0);
        }
    };

    const handleCloseSearch = () => {
        setList([]);
        setSearchTerm('');
    };

    function handleSave(user) {
        infoSnack('// TODO: HANDLE THIS SAVE FOR THE USER');
        if (user) {
            // adminConnection.invoke("SaveUser", hitchUser?.userId, user)
            //     .then(() => setLoading(true))
            //     .catch(error => console.error("Error sending message:", error));

            // setEditMode(false); 
        }
    };

    const handleCancel = () => {
        setEditMode(false);
    };

    const handleDelete = () => {

        adminConnection.invoke("DeleteUser", hitchUser?.userId, userToDelete)
            .then(() => { 
                infoSnack('User is being deleted');
                setUserToDelete(null);
            })
            .catch(error => console.error("Error sending message:", error));
    };

    const handleAddNew = () => {
        // Add new user logic here
    };

    function setDeleteTheUser(userId) {
        setUserToDelete(userId);
    }

    return (
        <HitchBox backgroundColor={"#004444"} color={"#00eded"}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={10}>
                    <TextField
                        label="Search Users"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onFocus={handleSearchFocus}
                        fullWidth
                        sx={{ backgroundColor: '#fff', color: '#000', borderColor: '#d3d3d3' }}
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={handleCloseSearch} sx={{ color: '#fff' }}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary" onClick={handleAddNew}>
                        Add New
                    </Button>
                </Grid>
                {list.length > 0 && (
                    <Grid item xs={12}>
                        <AdminSearchGridV2
                            columns={columns}
                            list={list}
                            handleRowClick={handleRowClick}
                            rowSelectionModel={[]}
                        />
                    </Grid>
                )}
                {selectedUsers.length > 0 && (
                    <Grid item xs={12}>
                        <Tabs
                            value={userTabValue}
                            onChange={handleUserTabChange}
                            aria-label="user tabs"
                            TabIndicatorProps={{ style: { backgroundColor: '#00eded' } }} // Change the color of the indicator
                        >
                            {selectedUsers.map((user, index) => (
                                <CustomTab
                                    key={user.id}
                                    label={user.name}
                                    onClose={() => handleTabClose(index)}
                                    sx={{
                                        color: userTabValue === index ? '#00eded' : '#000',
                                        '&.Mui-selected': {
                                            color: '#00eded',
                                        },
                                    }}
                                />
                            ))}
                        </Tabs>
                        {selectedUsers.map((user, index) => (
                            <Box key={user.id} hidden={userTabValue !== index}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                startIcon={<DeleteIcon />}
                                                onClick={() => setDeleteTheUser(user.id)}
                                            >
                                                Delete User
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={{ border: '1px solid #00eded', padding: '20px', borderRadius: '4px' }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Typography variant="h5" sx={{ marginBottom: '20px', color: '#00eded' }}>
                                                    User Details
                                                </Typography>
                                                <Box>
                                                    <IconButton
                                                        onClick={() => setExpandedDetails(!expandedDetails)}
                                                        aria-expanded={expandedDetails}
                                                        aria-label="show more"
                                                    >
                                                        {expandedDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </IconButton>
                                                    {editMode ? (
                                                        <>
                                                            <IconButton onClick={handleSave} color="primary">
                                                                <SaveIcon />
                                                            </IconButton>
                                                            <IconButton onClick={handleCancel} color="secondary">
                                                                <CancelIcon />
                                                            </IconButton>
                                                        </>
                                                    ) : (
                                                        <IconButton onClick={() => setEditMode(true)} color="primary">
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            </Grid>
                                            <Collapse in={expandedDetails} timeout="auto" unmountOnExit>
                                                <UserDetails user={user} editMode={editMode} />
                                            </Collapse>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={{ border: '1px solid #00eded', padding: '20px', borderRadius: '4px', marginBottom: '20px' }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Typography variant="h5" sx={{ marginBottom: '20px', color: '#00eded' }}>
                                                    Account Roles
                                                </Typography>
                                                <IconButton
                                                    onClick={() => setExpandedRoles(!expandedRoles)}
                                                    aria-expanded={expandedRoles}
                                                    aria-label="show more"
                                                >
                                                    {expandedRoles ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                            </Grid>
                                            <Collapse in={expandedRoles} timeout="auto" unmountOnExit>

                                                {/* USER ACCOUNT ROLES HERE */}
                                                <UserAccountRoles
                                                    user={user}
                                                    editMode={editMode}
                                                    loading={loading}
                                                    setLoading={setLoading} />

                                            </Collapse>
                                        </Box>
                                        <Box sx={{ border: '1px solid #00eded', padding: '20px', borderRadius: '4px', marginBottom: '20px' }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Typography variant="h5" sx={{ marginBottom: '20px', color: '#00eded' }}>
                                                    Lead Records
                                                </Typography>
                                                <IconButton
                                                    onClick={() => setExpandedLeads(!expandedLeads)}
                                                    aria-expanded={expandedLeads}
                                                    aria-label="show more"
                                                >
                                                    {expandedLeads ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                            </Grid>
                                            <Collapse in={expandedLeads} timeout="auto" unmountOnExit>

                                                {/* USER LEADS HERE */}
                                                <UserLeadRecords
                                                    user={user}
                                                    editMode={editMode}
                                                    loading={loading}
                                                    setLoading={setLoading} />


                                            </Collapse>
                                        </Box>
                                        <Box sx={{ border: '1px solid #00eded', padding: '20px', borderRadius: '4px' }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Typography variant="h5" sx={{ marginBottom: '20px', color: '#00eded' }}>
                                                    Tenant Records
                                                </Typography>
                                                <IconButton
                                                    onClick={() => setExpandedTenants(!expandedTenants)}
                                                    aria-expanded={expandedTenants}
                                                    aria-label="show more"
                                                >
                                                    {expandedTenants ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                            </Grid>
                                            <Collapse in={expandedTenants} timeout="auto" unmountOnExit>

                                                {/* USER TENANT HERE */}
                                                <UserTenantRecords
                                                    user={user}
                                                    editMode={editMode}
                                                    loading={loading}
                                                    setLoading={setLoading} />

                                            </Collapse>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}
                    </Grid>
                )}
            </Grid>
            <Dialog
                open={userToDelete !== null}
                onClose={() => setUserToDelete(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUserToDelete(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="error" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </HitchBox>
    );
};

export default Users;
