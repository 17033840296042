import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Collapse, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useAdminHubConnections } from '../../utils/HubAdminConnectionProvider';
import { useHubConnections } from '../../utils/HubConnectionsProvider';

function OrganizationConnections({organization}) {
    const { adminConnection, hitchUser, isConnectionReady } = useAdminHubConnections();
    const {         
        defaultSnack,
        infoSnack,
        warningSnack,
        successSnack,
        errorSnack, 
    } = useHubConnections();

    useEffect(() => {
        if (adminConnection) {
            adminConnection.on('Connections', (data) => {
                console.log('Connections', data);
                setConnections(data);
                // setLoading(false);
            });

            getConnections();
            return () => {
                adminConnection.off('Connections');
            };
        }
    }, [adminConnection]);

    function getConnections() {
        adminConnection.invoke("GetAccountConnections", hitchUser.userId, organization.id)
            .then(() => {
                console.log('i asked for Connections Users');
                // setLoading(true);
            })
            .catch(error => console.error("Error sending message:", error));
    }

    const [connections, setConnections] = useState([]);
    const [expanded, setExpanded] = useState({});

    useEffect(() => {
        // Use static dummy data for now
        const dummyConnections = [
            {
                id: "1",
                serviceId: "service1",
                name: "Connection One",
                clientId: "client1",
                accessToken: "token1",
                refreshToken: "refresh1",
                configuration: "config1",
                secret: "secret1",
                created: "2024-01-01",
                createdBy: "admin",
                updated: "2024-01-02",
                updatedBy: "admin",
                default: true
            },
            {
                id: "2",
                serviceId: "service2",
                name: "Connection Two",
                clientId: "client2",
                accessToken: "token2",
                refreshToken: "refresh2",
                configuration: "config2",
                secret: "secret2",
                created: "2024-02-01",
                createdBy: "admin",
                updated: "2024-02-02",
                updatedBy: "admin",
                default: false
            }
        ];
        setConnections(dummyConnections);
    }, []);

    const handleExpandClick = (id) => {
        setExpanded((prevExpanded) => ({
            ...prevExpanded,
            [id]: !prevExpanded[id]
        }));
    };

    return (
        <Box>
            {connections.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Service ID</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Default</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {connections.map((connection) => (
                                <React.Fragment key={connection.id}>
                                    <TableRow sx={{ '& .MuiTableCell-root': { padding: '8px' } }}>
                                        <TableCell>{connection.name}</TableCell>
                                        <TableCell>{connection.serviceId}</TableCell>
                                        <TableCell>{connection.created}</TableCell>
                                        <TableCell>{connection.default ? 'Yes' : 'No'}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => handleExpandClick(connection.id)}
                                                aria-expanded={expanded[connection.id]}
                                                aria-label="show more"
                                            >
                                                {expanded[connection.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '& .MuiTableCell-root': { padding: '8px' } }}>
                                        <TableCell style={{ padding: 0 }} colSpan={5}>
                                            <Collapse in={expanded[connection.id]} timeout="auto" unmountOnExit>
                                            <Box margin={0} sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 1 }}>
                                                    <Grid container spacing={2}>
                                                        {Object.keys(connection).map((key) => (
                                                            !['id', 'name', 'serviceId', 'created', 'default'].includes(key) && (
                                                                <Grid item xs={12} sm={6} key={key}>
                                                                    <Typography variant="body2"><strong>{key}:</strong> {connection[key]}</Typography>
                                                                </Grid>
                                                            )
                                                        ))}
                                                    </Grid>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>No Connections to Display</Typography>
            )}
        </Box>
    );
}

export default OrganizationConnections;
