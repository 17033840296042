import React, { useState, useEffect } from 'react';
import { useHubConnections } from '../../utils/HubConnectionsProvider';

function ConnectToApp({ guid, onNext }) {
  const [authUrl, setAuthUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const { dashboardConnection, hitchDashCommand, isConnectionReady } = useHubConnections();

  useEffect(() => {
    if (dashboardConnection && isConnectionReady) {
      dashboardConnection.on("ce55d5d0-4de5-4902-9758-3d043675beb5", setAuthPackage);
      hitchDashCommand('quickbooks', 'auth', 'auth-start', {}, () => { });
    }

    return () => {
      if (dashboardConnection) {
        dashboardConnection.off("ce55d5d0-4de5-4902-9758-3d043675beb5", setAuthPackage);
      }
    };
  }, [dashboardConnection, isConnectionReady, hitchDashCommand]);

  function setAuthPackage(data) {
    // console.log('package', data);
    setAuthUrl(data.url);
    setImageUrl(data.imageUrl); // Assuming data contains an imageUrl property
  }

  const handleConnect = () => {
    // Open the third-party app sign-in page in a popup window
    if (authUrl) {
      window.open(authUrl, 'popup', 'width=600,height=600,scrollbars=yes,resizable=yes');
      onNext();  // Proceed to the next step after connection
    }
  };

  useEffect(() => {
    if (authUrl) {
      // console.log(authUrl);
    }
  }, [authUrl]);

  return (
    <div>
      <h2>Connect to App</h2>
      {imageUrl ? (
        <img 
          src={imageUrl} 
          alt="Connect to App" 
          onClick={handleConnect} 
          style={{ cursor: 'pointer', maxWidth: '100%', height: 'auto' }}
        />
      ) : (
        <button onClick={handleConnect} disabled={!authUrl}>
          Click here to connect
        </button>
      )}
    </div>
  );
}

export default ConnectToApp;
