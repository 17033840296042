import { Route, Routes } from "react-router-dom";
import ExtensionsMain from "./ExtensionsMain";

function Extensions() {
    return(
        <Routes>
            <Route path="/" element={<ExtensionsMain />} />
            <Route path="/:serviceId" element={<ExtensionsMain />} />
        </Routes>

    )
}

export default Extensions;