import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import ReactFlow, {
    addEdge,
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    ReactFlowProvider,
    ControlButton,
} from 'reactflow';
import { nodes as initialNodes, edges as initialEdges } from './initial-elements';
import CustomNode from './CustomNode';
// import HitchServiceNode from './HitchServiceNode';
import { v4 as uuidv4 } from 'uuid';
import 'reactflow/dist/style.css';
import './Rules.css';
import { useReactFlow } from 'reactflow';
import HitchServiceNode from './HitchServiceNode';
import HitchEventNode from './HitchEventNode';
import HitchRuleNode from './HitchRuleNode';
import HitchConditionNode from './HitchConditionNode';
import HitchActionNode from './HitchActionNode';
import HitchConditionDependencyNode from './HitchConditionDependencyNode';
import Sidebar from './Sidebar';
import { calculateLayout } from './LayoutCalculator';
import updateRuleWithDiagramElements from './updateRuleWithDiagramElements';
import ActionFactory from './ActionFactory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-solid-svg-icons';
import ConditionFactory from './ConditionFactory';
import ActionDependencyFactory from './ActionDependencyFactory';
import ConditionDependencyFactory from './ConditionDependencyFactory';
import HitchActionDependencyNode from './HitchActionDependencyNode';
import { useHubConnections } from '../../utils/HubConnectionsProvider';

const nodeTypes = {
    rule: HitchRuleNode,
    event: HitchEventNode,
    condition: HitchConditionNode,
    action: HitchActionNode,
    actiondependency: HitchActionDependencyNode,
    conditiondependency: HitchConditionDependencyNode
};

const minimapStyle = {
    height: 120,
};

const proOptions = { hideAttribution: true };

function Rules({ dashboardHitchCommand, hubConnection }) {
    const { guid } = useParams(); // This hooks extracts params from the route
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [eventOptions, setEventOptions] = useState([]);
    const [data, setData] = useState({});
    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
    const [lastDraggedHandleType, setLastDraggedHandleType] = useState(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    const onInit = (rfInstance) => {
        console.log('flow loaded:', rfInstance);
        setReactFlowInstance(rfInstance); // Store the instance for later use
    };

    const handleNodeCallback = (service, feature, operation, payload) => {
        if(service === 'local' && feature === 'node' && operation === 'delete') {
            let payloadDetail = reactFlowInstance.getNode(payload);
            console.log('payloadDetail', payloadDetail);
           
            if (payloadDetail) { // Check if the node was found
                reactFlowInstance.deleteElements({
                    nodes: [payloadDetail]
                });
            }
        }
    };
    

    // Step 4: Setup SignalR listener for "Rule"
    useEffect(() => {
        if (!hubConnection) return;

        const handleRuleReceived = async (ruleData) => {
            console.log(ruleData);
            if (ruleData.nodes && ruleData.edges) {
                // Augment nodes with the callback
                const augmentedNodes = ruleData.nodes.map((node) => ({
                    ...node,
                    data: {
                        ...node.data,
                        onNodeCallback: handleNodeCallback, // Add the callback function here
                    },
                }));
    
                console.log('augmentedNodes', augmentedNodes);
                const updatedData = await calculateLayout({ ...ruleData, nodes: augmentedNodes });
                setNodes(updatedData.nodes);
                setEdges(updatedData.edges);
                setData(updatedData.rule);
            }
        };
    

        // COMMENT TO TEST LOCAL JSON, UNCOMMENT FOR SERVER VERSION
        hubConnection.on("Rule", handleRuleReceived);
        hubConnection.on("ConnectionUpdate", handleConnectionUpdate);

        // Cleanup listener when component unmounts or hubConnection changes
        return () => { 
            hubConnection.off("Rule", handleRuleReceived);
            hubConnection.off("ConnectionUpdate", handleConnectionUpdate);
        }
    }, [hubConnection, setNodes, setEdges]);

    function handleConnectionUpdate(data) {
        console.log('connectionUpdate', data);
        setEventOptions(data);
    }

    // Step 3: Fetch Rule Data
    useEffect(() => {
        if (dashboardHitchCommand && guid) {
            dashboardHitchCommand("account", "rule", "get", { "RuleId": guid });
            dashboardHitchCommand("account", "connection", "list", {});
        }
    }, [dashboardHitchCommand, guid]);

    async function SetPositions(updatedNodes, updatedEdges) {
        const layoutData = { nodes: updatedNodes, edges: updatedEdges, rule: data }; // Use the updated nodes and edges
        console.log('layoutData', layoutData);
        const updatedData = await calculateLayout(layoutData);
        setNodes(updatedData.nodes);
        setEdges(updatedData.edges);
        const updatedRule = updateRuleWithDiagramElements(updatedData);
        console.log(updatedRule);
        setData(updatedRule);
    }

    function saveData() {
        if(dashboardHitchCommand && guid) {
            var payload = { RuleBundle: {Rule: data, Nodes: nodes, Edges: edges} };
            console.log('payload', payload);
            //dashboardHitchCommand("account", "rule", "save", payload);
        }
    }

    // we are using a bit of a shortcut here to adjust the edge type
    // this could also be done with a custom edge for example
    const edgesWithUpdatedTypes = edges.map((edge) => {
        // Safely find the node and ensure `.data` is not undefined
        const sourceNode = nodes.find((node) => node.id === edge.source);
        const edgeType = sourceNode?.data?.selects?.[edge.sourceHandle] || 'default'; // Provide a fallback edge type

        return {
            ...edge,
            type: edgeType,
        };
    });

    const sidebarWidth = 350;

    const onDrop = useCallback((event) => {
        event.preventDefault();
        if (!reactFlowInstance) return; // Ensure instance is available
        const reactFlowBounds = event.currentTarget.getBoundingClientRect();
        const pointX = event.clientX - reactFlowBounds.left - sidebarWidth;
        const pointY = event.clientY - reactFlowBounds.top;
        const position = reactFlowInstance.project({
            x: pointX,
            y: pointY,
        });
        const eventId = event.dataTransfer.getData('application/reactflow');
        const eventOption = eventOptions.find(option => option.serviceId === eventId);
        if (!eventOption) return;
        const newNode = {
            id: uuidv4(),
            type: 'event',
            position,
            data: {
                name: eventOption.serviceName,
                serviceId: eventOption.serviceId,
                onNodeCallback: handleNodeCallback, // Attach the callback function here
            },
        };
        setNodes((nds) => nds.concat(newNode));
    }, [setNodes, eventOptions, reactFlowInstance]);

    const onConnectStart = useCallback((event, { nodeId, handleId }) => {
        setLastDraggedHandleType(handleId);
    }, []);

    const onConnectEnd = useCallback(async (event) => {
        if (!reactFlowInstance || !lastDraggedHandleType) return;

        const position = reactFlowInstance.project({
            x: event.clientX,
            y: event.clientY,
        });

        let nodeType;
        let nodeColor;
        if (lastDraggedHandleType.startsWith('action-')) {
            nodeType = 'action';
            nodeColor = '#00ccbb';
        } else if (lastDraggedHandleType.startsWith('condition-')) {
            nodeType = 'condition';
            nodeColor = '#ff9000';
        } else if (lastDraggedHandleType.startsWith('event-')) {
            nodeType = 'event';
            nodeColor = '#000099';
        } else if (lastDraggedHandleType.startsWith('conditiondependency-')) {
            nodeType = 'conditiondependency';
            nodeColor = '#005555';
        } else if (lastDraggedHandleType.startsWith('actiondependency-')) {
            nodeType = 'actiondependency';
            nodeColor = '#005555';
        } else {
            // Handle unexpected case
            console.error('Unexpected lastDraggedHandleType:', lastDraggedHandleType);
            return;
        }
        const sourceNodeId = lastDraggedHandleType.replace('event-', '').replace('action-', '').replace('condition-', '').replace('conditiondependency-', '').replace('actiondependency-', '');
        let newNode = {
            id: uuidv4(),
            type: nodeType,
            position,
            data: { label: `New ${nodeType}`},
        };
        if (nodeType == "action") {
            if (data) {
                newNode = ActionFactory(data.accountId, data.id);
                // add to newNode.data

            } else {
                console.log('ERROR!  rule data is missing from state!');
            }
        }
        if (nodeType == "condition") {
            if (data) {
                newNode = ConditionFactory(data.accountId, data.id);
                 // add to newNode.data
            } else {
                console.log('ERROR!  rule data is missing from state!');
            }
        }
        if (nodeType == "conditiondependency") {
            if (data) {
                newNode = ConditionDependencyFactory(data.accountId, data.id, sourceNodeId);
                 // add to newNode.data
            } else {
                console.log('ERROR!  rule data is missing from state!');
            }
        }
        if (nodeType == "actiondependency") {
            if (data) {
                newNode = ActionDependencyFactory(data.accountId, data.id, sourceNodeId);
                 // add to newNode.data
            } else {
                console.log('ERROR!  rule data is missing from state!');
            }
        }
        newNode = {
            ...newNode,
            data: {
                ...newNode.data,
                onNodeCallback: handleNodeCallback,
            },
        };
        const newNodes = [...nodes, newNode]; // Create a new array with the new node
        const newEdge = {
            id: `e${sourceNodeId}-${newNode.id}`,
            source: sourceNodeId,
            sourceHandle: lastDraggedHandleType,
            target: newNode.id,
            style: { stroke: nodeColor, strokeWidth: 5 },
            markerEnd: {
                type: "arrowclosed",
                width: 10,
                height: 10,
                color: nodeColor
            }
        };
        const newEdges = [...edges, newEdge]; // Create a new array with the new edge
        await SetPositions(newNodes, newEdges);
    }, [reactFlowInstance, nodes, edges, lastDraggedHandleType, data]);

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    return (
        <ReactFlowProvider>
            <div className='drew-container' onDrop={onDrop} onDragOver={onDragOver}>
                <Sidebar eventOptions={eventOptions} />
                <ReactFlow
                    nodes={nodes}
                    edges={edgesWithUpdatedTypes}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onConnectStart={onConnectStart}
                    onConnectEnd={onConnectEnd}
                    onInit={onInit}
                    fitView
                    proOptions={proOptions}
                    nodesDraggable
                    attributionPosition="top-right"
                    nodeTypes={nodeTypes}
                    className='drew'
                >
                    <MiniMap style={minimapStyle} zoomable pannable />
                    <Controls style={{marginBottom: 100}} showInteractive={false}>
                        <ControlButton onClick={() => saveData()}>
                            <FontAwesomeIcon icon={faSave} />
                        </ControlButton>
                    </Controls>
                    <Background color="#aaa" gap={16} />
                </ReactFlow>

            </div>
        </ReactFlowProvider>
    );
}

export default Rules;