import { faAdd } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, Divider, FormControl, Grid, List, ListItem, ListItemText, TextField, Tooltip, Typography } from "@mui/material";
import { deepOrange, green } from '@mui/material/colors';
import StepListItem from "./StepListItem";

function TrainingOverview( {
    addAStep,
    formulateSystemMessage,
    mode,
    selectedStep,
    setTheStep,
    setUserMessage,
    steps,
    systemMessage,
    userMessage
} ) {
    return (
        <>
            {mode==='' && (
                <>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                multiline
                                value={systemMessage}
                                onChange={(e) => formulateSystemMessage(e.target.value)}
                                label="System Message"
                                rows={4}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ margin: "10px" }} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                multiline
                                value={userMessage}
                                onChange={(e) => setUserMessage(e.target.value)}
                                label="User Message"
                                rows={4}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ margin: "10px" }} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box
                            sx={{
                                width: '100%',        // Full width
                                bgcolor: '#000000',   // Background color
                                color: '#FFFFFF',     // Text color
                                display: 'flex',      // Using Flexbox
                                justifyContent: 'center', // Center content horizontally
                                alignItems: 'center', // Center content vertically (if needed)
                                minHeight: '200px'
                            }}
                        >
                            <Grid container sx={{
                                width: '100%',        // Full width
                                bgcolor: '#000000',   // Background color
                                color: '#FFFFFF',     // Text color
                                display: 'flex',      // Using Flexbox
                                justifyContent: 'center', // Center content horizontally
                                alignItems: 'center', // Center content vertically (if needed)
                            }}>
                                <Grid item xs={12} sx={{
                                    width: '100%',        // Full width
                                    bgcolor: '#000000',   // Background color
                                    color: '#FFFFFF',     // Text color
                                    display: 'flex',      // Using Flexbox
                                    justifyContent: 'center', // Center content horizontally
                                    alignItems: 'center', // Center content vertically (if needed)
                                }}>
                                    <Typography>Prompt Operations</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{
                                    width: '100%',        // Full width
                                    bgcolor: '#000000',   // Background color
                                    color: '#FFFFFF',     // Text color
                                    display: 'flex',      // Using Flexbox
                                    justifyContent: 'center', // Center content horizontally
                                    alignItems: 'center', // Center content vertically (if needed)
                                    marginTop: '10px'
                                }}>
                                    <Tooltip title="Add A Prompt Operation Step">
                                        <Avatar
                                            sx={{ bgcolor: deepOrange[500] }}
                                            variant="rounded"
                                            onClick={(e) => addAStep()}>
                                            <FontAwesomeIcon icon={faAdd} />
                                        </Avatar>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <List>
                            {steps && steps.map(step => (
                                <StepListItem
                                    step={step}
                                    key={step.step_id}
                                    selectedStep={selectedStep}
                                    setSelectedStep={setTheStep}
                                />
                            ))}
                            {!steps && (
                                <ListItem>
                                    <ListItemText>No Steps Defined.</ListItemText>
                                </ListItem>
                            )}
                        </List>
                    </Grid>
                </>
            )}
        </>
    )
}

export default TrainingOverview;