import { AppBar, Box, Button, InputAdornment, InputBase, IconButton, Modal, Toolbar, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SkuResults from "./SkuResults";
import { useEffect, useState } from "react";
import { useHubConnections } from "../../utils/HubConnectionsProvider";
import { useAdminHubConnections } from "../../utils/HubAdminConnectionProvider";
import initialResults from './skus.json';

function SkuModal({ handleSkuSelect, handleSkuModalClose, skuModalOpen, onSkuSelect, style }) {

    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);

    const {
        defaultSnack,
        infoSnack,
        warningSnack,
        errorSnack
    } = useHubConnections();

    const { adminConnection, hitchAdminCommand, hitchUser } = useAdminHubConnections();

    function handleSearchChange(event) {
        setSearchTerm(event.target.value);
    }

    function handleSearchKeyPress(event) {
        if (event.key === 'Enter') {
            callForSearch();
        }
    }

    function handleSearchClick() {
        callForSearch();
    }

    function callForSearch() {
        if (adminConnection) {
            console.log('Calling for Offer List');
            hitchAdminCommand('marketing', 'sku', 'search',
                {
                    "searchTerm": searchTerm
                });
        }
    }



    useEffect(() => {
        if (adminConnection) {
            adminConnection.on("9d7c4f4c-84f9-48c6-99d4-4fe202cc2e7b", (data) => {
                console.log('skus', data);
                if (data.StackTraceString) {
                    errorSnack(`Error: ${data.Message}`);
                } else {
                    setResults(data);
                }
            });
            callForSearch();
        }

        return () => {
            if (adminConnection) {
                adminConnection.off("9d7c4f4c-84f9-48c6-99d4-4fe202cc2e7b");
            }
        };
    }, [adminConnection]);



    return (
        <Modal open={skuModalOpen} onClose={handleSkuModalClose}>
            <Box sx={style}>
                <AppBar position="static" sx={{ bgcolor: '#00eded' }}>
                    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}> {/* Added justification */}
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                            Search for SKU
                        </Typography>
                        <div sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <InputBase
                                sx={{ ml: 1, flex: 1, width: '100%', minWidth: 150, bgcolor: '#00abab', padding: '10px 16px' }}
                                placeholder="Search SKUs"
                                inputProps={{ 'aria-label': 'search SKUs' }}
                                value={searchTerm}
                                onChange={handleSearchChange}
                                onKeyPress={handleSearchKeyPress}
                                endAdornment={ // Add endAdornment for icon placement
                                    <InputAdornment position="end" onClick={handleSearchClick}>
                                        <IconButton type="submit" sx={{ p: 1 }}>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </Toolbar>
                </AppBar>
                <SkuResults
                    results={results}
                    onSkuSelect={onSkuSelect} />
            </Box>
        </Modal>
    );
}

export default SkuModal;
