import React from 'react';
import { CircularProgress, Typography, Box } from '@mui/material';

function SplashScreen({ message, details }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // Full viewport height
        width: '100vw',  // Full viewport width
        backgroundColor: 'black', // Slightly increase the opacity for better coverage
        color: '#fff',
        textAlign: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9999,
      }}
    >
      <CircularProgress sx={{ color: '#00EDED', mb: 2 }} />
      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
        {message}
      </Typography>
      {details && (
        <Typography variant="body2" sx={{ color: '#ccc', mt: 1 }}>
          {details}
        </Typography>
      )}
    </Box>
  );
}

export default SplashScreen;
