import { MarkerType, Position } from 'reactflow';

export const nodes = [
  {
    id: 'org-account',
    type: 'user',
    data: {
      label: 'Org Account',
      selects: {}, // Ensure this data is consistent with what your CustomNode expects
    },
    position: { x: 400, y: 0 },
  },
  {
    id: 'eb67e313-3c9e-440e-914b-c9eb7d365b9a',
    type: 'hitchy',
    data: {
      label: 'Hitchy',
      selects: {},
    },
    position: { x: 400, y: 100 },
  },
  {
    id: 'cfo',
    type: 'custom',
    data: {
      label: 'CFO',
      selects: {},
    },
    position: { x: 200, y: 200 },
  },
  {
    id: 'cto',
    type: 'custom',
    data: {
      label: 'CTO',
      selects: {},
    },
    position: { x: 400, y: 200 },
  },
  {
    id: 'coo',
    type: 'custom',
    data: {
      label: 'COO',
      selects: {},
    },
    position: { x: 600, y: 200 },
  },
  {
    id: 'accounting',
    type: 'custom',
    data: {
      label: 'Accounting',
      selects: {},
    },
    position: { x: 100, y: 300 },
  },
  {
    id: 'legal',
    type: 'custom',
    data: {
      label: 'Legal',
      selects: {},
    },
    position: { x: 300, y: 300 },
  },
  {
    id: 'general-accounting',
    type: 'custom',
    data: {
      label: 'General Accounting',
      selects: {},
    },
    position: { x: 50, y: 400 },
  },
  {
    id: 'reporting',
    type: 'custom',
    data: {
      label: 'Reporting',
      selects: {},
    },
    position: { x: 150, y: 400 },
  },
  {
    id: 'compliance',
    type: 'custom',
    data: {
      label: 'Compliance',
      selects: {},
    },
    position: { x: 250, y: 400 },
  },
];

export const edges = [
  { id: 'e-org-ceo', source: 'org-account', target: 'eb67e313-3c9e-440e-914b-c9eb7d365b9a', type: 'smoothstep' },
  { id: 'e-ceo-cfo', source: 'eb67e313-3c9e-440e-914b-c9eb7d365b9a', target: 'cfo', type: 'smoothstep' },
  { id: 'e-ceo-cto', source: 'eb67e313-3c9e-440e-914b-c9eb7d365b9a', target: 'cto', type: 'smoothstep' },
  { id: 'e-ceo-coo', source: 'eb67e313-3c9e-440e-914b-c9eb7d365b9a', target: 'coo', type: 'smoothstep' },
  { id: 'e-cfo-accounting', source: 'cfo', target: 'accounting', type: 'smoothstep' },
  { id: 'e-cfo-legal', source: 'cfo', target: 'legal', type: 'smoothstep' },
  { id: 'e-accounting-general', source: 'accounting', target: 'general-accounting', type: 'smoothstep' },
  { id: 'e-accounting-reporting', source: 'accounting', target: 'reporting', type: 'smoothstep' },
  { id: 'e-accounting-compliance', source: 'accounting', target: 'compliance', type: 'smoothstep' },
];
