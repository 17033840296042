import React from 'react';
import { Typography, Box } from '@mui/material';
import HubspotForm from 'react-hubspot-form';

function RequestDemoForm() {
  return (
    <Box sx={{ marginBottom: '20px' }}>
      
      <HubspotForm
        portalId='43615722'
        //formId='da690e99-ae8b-4438-afeb-edf4bcb5cc62'\
        formId='0a5075c9-2fb9-4e4d-bf3b-fba83cf1d617'
        onSubmit={() => console.log('Submit!')}
        onReady={(form) => console.log('Form ready!')}
        loading={<div>Loading...</div>}
      />
    </Box>
  );
}

export default RequestDemoForm;
