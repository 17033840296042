import {
  Grid,
  Tabs,
  Tab,
  Box
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useAdminHubConnections } from "../utils/HubAdminConnectionProvider";
import HitchBox from "../components/hitchbox/HitchBox";
import ClusterActorSearch from "./ClusterActorSearch";
import Nodes from "./Nodes"; // Import the Nodes component
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

function Clusters() {
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [editingModel, setEditingModel] = useState(null);
  const { adminConnection, hitchUser, isConnectionReady } = useAdminHubConnections();
  const [actors, setActors] = useState([]);

  const [accountId, setAccountId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [connectionId, setConnectionId] = useState("");
  const [featureId, setFeatureId] = useState("");
  const [operationId, setOperationId] = useState("");
  const [actorId, setActorId] = useState("");

  const [nodes, setNodes] = useState([]);

  const [accounts, setAccounts] = useState([]);
  const [services, setServices] = useState([]);
  const [connections, setConnections] = useState([]);
  const [features, setFeatures] = useState([]);
  const [operations, setOperations] = useState([]);

  const [tabIndex, setTabIndex] = useState(0);

  const navigate = useNavigate(); // Initialize useNavigate

  const columns = [
    {
      field: "actorId",
      headerName: "Actor Id",
      width: 300,
    },
    {
      field: "actorType",
      headerName: "Actor Type",
      width: 150,
    },
    {
      field: "nodeName",
      headerName: "Node Name",
      width: 150,
    },
    {
      field: "actorAddress",
      headerName: "Address",
      width: 150,
    },
    {
      field: "birthTime",
      headerName: "Birth Time",
      width: 150,
    },
    {
      field: "role",
      headerName: "Role",
      width: 150,
    },
  ];

  useEffect(() => {
    console.log('isConnectionReady', isConnectionReady);
    console.log('adminConnection', adminConnection);
  }, [isConnectionReady, adminConnection]);

  useEffect(() => {
    if (adminConnection) {
      const subscribeToEvents = () => {
        adminConnection.on("ActorSearchResult", setActorSearchResult);
        adminConnection.on("Services", (data) => setServices(data));
        adminConnection.on("Connections", (data) => setConnections(data));
        adminConnection.on("Features", (data) => setFeatures(data));
        adminConnection.on("Operations", (data) => setOperations(data));
        adminConnection.on("Accounts", (data) => setAccounts(data));
        adminConnection.on("Nodes", (data) => setNodes(data));
        adminConnection.on("ClusterList", (data) => {
          setLoading(true);
          setList(data);
        });

        adminConnection.on("Cluster", (data) => {
          setEditingModel(data);
        });
        adminConnection.on("Registry", (data) => {
          // console.log('Registry', data);
        });

        // Add other event subscriptions here
      };

      subscribeToEvents();

      // adminConnection
      //   .invoke("GetClusters", hitchUser?.userId, searchTerm)
      //   .then(() => {
      //     setLoading(true);
      //   })
      //   .catch((error) => console.error("Error sending message:", error));

      adminConnection
        .invoke("GetServices", hitchUser?.userId, null)
        .then(() => {
          console.log("Services on the way");
        })
        .catch((error) => console.error("Error fetching services!", error));

      adminConnection
        .invoke("GetAccounts", hitchUser?.userId, null)
        .then(() => {
          console.log("Accounts on the way");
        })
        .catch((error) => console.error("Error fetching accounts!", error));

      return () => {
        adminConnection.off("ActorSearchResult", setActorSearchResult);
        adminConnection.off("ClusterList");
        adminConnection.off("Cluster");
        adminConnection.off("Registry");
        adminConnection.off("Nodes");
        adminConnection.off("Services");
        adminConnection.off("Connections");
        adminConnection.off("Features");
        adminConnection.off("Operations");
        adminConnection.off("Accounts");
        // Unsubscribe from other events here
      };
    }
  }, [adminConnection, hitchUser?.userId, searchTerm]);

  useEffect(() => {
    if (adminConnection && serviceId) {
      adminConnection
        .invoke("GetFeatures", serviceId)
        .then(() => {
          console.log("Features on the way");
        })
        .catch((error) => console.error("Error fetching features!", error));


      adminConnection
        .invoke("GetOperations", hitchUser?.userId, serviceId)
        .then(() => {
          console.log("Operations on the way");
        })
        .catch((error) => console.error("Error fetching operations!", error));
    }
  }, [adminConnection, serviceId, hitchUser?.userId]);

  useEffect(() => {
    if (adminConnection && accountId) {
      adminConnection
        .invoke("GetConnections", hitchUser?.userId, serviceId, accountId)
        .then(() => {
          console.log("Connections on the way");
        })
        .catch((error) => console.error("Error fetching account connections!", error));
    }
  }, [adminConnection, serviceId, accountId, hitchUser?.userId]);


  useEffect(() => {
    if (nodes) {
      console.log('nodes', nodes);
    }
  }, [nodes]);

  const search = () => {
    if (adminConnection) {
      adminConnection
        .invoke("GetActors", hitchUser?.userId, {
          accountId: accountId,
          serviceId: serviceId,
          connectionId: connectionId,
          featureId: featureId,
          operationId: operationId,
          actorId: actorId,
        })
        .then(() => {
          console.log("On its way");
        })
        .catch((error) => console.error("Error Searching!", error));
    }
  };

  const handleRowClick = (params) => {
    navigate(`/admin/actors/${params.id}`);
  };

  const setActorSearchResult = (data) => {
    console.log("the Actors", data);
    setActors(data);
  };

  const getRowId = (row) => row.actorId;

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <HitchBox backgroundColor={"#004444"} color={"#00eded"}>
      <Grid container spacing={2} mt={3}>
        <Grid item xs={12}>
          <ClusterActorSearch
            accountId={accountId}
            accounts={accounts}
            actorId={actorId}
            connectionId={connectionId}
            connections={connections}
            featureId={featureId}
            features={features}
            operationId={operationId}
            operations={operations}
            search={search}
            serviceId={serviceId}
            services={services}
            setAccountId={setAccountId}
            setActorId={setActorId}
            setConnectionId={setConnectionId}
            setServiceId={setServiceId}
            setFeatureId={setFeatureId}
            setOperationId={setOperationId} />
        </Grid>
        <Grid item xs={12}>
          <Tabs value={tabIndex} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tab label="Nodes" />
            <Tab label="Actors" />
          </Tabs>
          <Box p={3}>
            {tabIndex === 0 && (
              <Nodes
                accountId={accountId}
                accounts={accounts}
                connectionId={connectionId}
                connections={connections}
                search={search}
                serviceId={serviceId}
                services={services}
                nodes={nodes}
                setAccountId={setAccountId}
                setConnectionId={setConnectionId}
                setServiceId={setServiceId}
              />
            )}
            {tabIndex === 1 && (
              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={actors}
                  columns={columns}
                  getRowId={getRowId}
                  loading={loading}
                  rowSelectionModel={rowSelectionModel}
                  onRowClick={handleRowClick}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  checkboxSelection={false} // Disable checkbox selection
                  disableSelectionOnClick
                  disableColumnMenu // Disable column menu
                  disableColumnSelector // Disable column selector
                />
              </div>
            )}

          </Box>
        </Grid>
      </Grid>
    </HitchBox>
  );
}

export default Clusters;
