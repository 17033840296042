import { Drawer } from "@mui/material";
import ExtensionsDrawerList from "./ExtensionsDrawerList";

function ExtensionsMenu({drawerOpen, setDrawerOpen, serviceId}) {
    return (
        <Drawer open={drawerOpen} >
            <ExtensionsDrawerList setDrawerOpen={setDrawerOpen} serviceId={serviceId} />
        </Drawer>
    );
}

export default ExtensionsMenu;