import { v4 as uuidv4 } from 'uuid';

function ConditionFactory(accountId, ruleId) {
    const position = {
        x: 0,
        y: 0,
    };

    const newId = uuidv4();

    return {
        id: newId,
        type: 'condition',
        position,
        data: { 
            id: newId,
            name: 'New Condition',
            accountId: accountId,
            ruleId: ruleId,
            conditionExpression: '',
            conditionDependency: []
        }
    };
}

export default ConditionFactory;