import * as React from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, TextField, Divider, Button } from '@mui/material';
import { Handle, Position } from 'reactflow';
import ConditionalLambda from './ConditionalLambda';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function HitchConditionNode({ id, data, serviceName, serviceId, featureId, operationId }) {
  const [expanded, setExpanded] = React.useState(false);
  const [selectedFeatureId, setSelectedFeatureId] = React.useState(featureId);
  const [selectedOperationId, setSelectedOperationId] = React.useState(operationId);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: data?.name || '',
    conditionExpression: data?.conditionExpression || '',
  });

  function conditionLambdaOnClose() {
    setModalOpen(false);
  }

  const handleOpenModal  = () => {
    setModalOpen(true);
  };

  // Update formData when data changes
  React.useEffect(() => {
    if (data) {
      setFormData(prevFormData => ({
        ...prevFormData,
        name: data.name,
        conditionExpression: data.conditionExpression,
      }));
    }
  }, [data]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleFeatureChange = (event) => {
    setSelectedFeatureId(event.target.value);
  };

  const handleOperationChange = (event) => {
    setSelectedOperationId(event.target.value);
  };

  // Handle changes to the name field
  const handleNameChange = (event) => {
    const newName = event.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      name: newName,
    }));
  };

  // Handle changes to the lambda (ruleExpression) field
  const handleLambdaChange = (event) => {
    const newLambda = event.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      conditionExpression: newLambda,
    }));
  };

  const handleDeleteNode = (nodeId) => {
    console.log('delete!', nodeId);
    data.onNodeCallback('local', 'node', 'delete', nodeId);
  };

  return (
    <>
      <div className="custom-node__header">
        Condition: <strong>{formData?.name}</strong>
      </div>

      <div className="custom-node__body">
        <TextField
          id="txtFileName"
          label="Name"
          fullWidth
          value={formData.name}
          onChange={handleNameChange} // Add onChange event handler
        />

        <Divider />
        <TextField
          className="lambda"
          id="outlined-multiline-static"
          label="Lambda"
          multiline
          rows={4}
          value={formData.conditionExpression}
          onChange={handleLambdaChange} // Add onChange event handler
        />
        <Divider />
        <Button variant='contained' onClick={() => handleOpenModal()}>Lambda Editor</Button>
        <Button variant="contained" color="error" onClick={() => handleDeleteNode(id)}>
          Delete
        </Button>
        <Handle type="target" position={Position.Left} className="custom-handle connection-handle-top" id={id} />
        <Handle type="source" position={Position.Right} className="custom-handle dependency-handle-left" id={`conditiondependency-${id}`} />
      </div>

      <ConditionalLambda 
        open={isModalOpen}
        onClose={conditionLambdaOnClose}
      />
    </>
  );
}
