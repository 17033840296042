import React, { useState, useRef, useEffect } from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import './AddPlan.css';
import { useHubConnections } from '../utils/HubConnectionsProvider';
import BillingPeriod from './BillingPeriod';
import RegistrationDivider from './RegistrationDivider';
import PlanSelector from './PlanSelector';
import { modalContentStyle, arrowIconStyle, buttonStyle, primaryButtonStyle, secondaryButtonStyle } from './AddPlanStyles';


function AddPlan({ selectedPlanId, setSelectedPlanId, onBack, onNext, onClose, plansData, isFirstStep, planData}) {
  const [message, setMessage] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [isAccessCodeActive, setIsAccessCodeActive] = useState(false);
  const accessCodeInputRef = useRef(null);
  
  const handleAccessCodeChange = (e) => {
    setAccessCode(e.target.value);
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogText, setDialogText] = useState('');
  const [renderMe, setRenderMe] = useState(0);

  const { dashboardConnection, hitchUser, hitchDashCommand, infoSnack } = useHubConnections();


  useEffect(() => {
    console.log('AddPlan component mounted');
    return () => {
      console.log('AddPlan component unmounted');
    };
  }, []);

  const openDialog = (title, message) => {
    setDialogTitle(title);
    setDialogText(message);
    setIsDialogOpen(true);
  };

  const handleSelectLockedBox = (plan) => {
    if (plan.isLocked) {
      selectPlan(plan.id);
      setIsAccessCodeActive(true);
      setIsButtonDisabled(true); // Disable the button when the plan is locked
    } else {
      selectPlan(plan.id);
      setIsButtonDisabled(false); // Enable the button when the plan is not locked
    }
  };

  const handleButtonClick = (plan) => {
    
    console.log('button clicked');
    if (plan.mustContact || (plan.isCurrent && plan.isCustom)) {
      handleContactSupport(plan);
    } else if (plan.isCustom && !plan.isCurrent) {
      // If plan is custom and not current, contact sales
      handleContactSales(plan);
    } else {
      console.log('calling handleChoosePlan');
      // Otherwise, choose the plan
      //handleChoosePlan(plan);
      onNext({  });
    }
  };

  const handleChoosePlan = (plan) => {

    hitchDashCommand('marketing', 'offer', 'claim', {
      theOfferId: plan.id
    });

    infoSnack('Submitting Plan to back end...');
  };

  const handleContactSales = (plan) => {
    const title = `You chose plan: ${plan.title}`;
    const message = 'Contacting Sales...';
    openDialog(title, message);
  };

  const handleContactSupport = (plan) => {
    const title = `You chose plan: ${plan.title}`;
    const message = 'Contacting Support...';
    openDialog(title, message);
  };

  const selectPlan = (planId) => {
    setSelectedPlanId(planId);
  };

  const [billingPeriod, setBillingPeriod] = useState('Monthly'); // State for billing period

  const handleBillingPeriodChange = (e) => {
    setBillingPeriod(e.target.value);
  };

  const formatRate = (plan) => {
    const asterisk = plan.freeTrialDays ? '*' : '';
    return `${billingPeriod === 'Annually' ? plan.usdAnnualRate : plan.usdMonthlyRate}${asterisk}${billingPeriod === 'Annually' ? '/yr' : '/mo'}`;
  };

  const handleBackClick = () => {
    if (isFirstStep) {
      window.location.href = '/dashboard'; // Change this to the desired URL
    } else {
      // Go back to the previous step
      onBack();
    }
  };

  function handleAccessCode() {
    if (dashboardConnection) {
      console.log('B4 plansData', plansData);
      console.log('Calling for Access Code');
      hitchDashCommand('marketing', 'offer', 'fetch', {
        offerCode: accessCode
      });
      console.log('AF plansData', plansData);
    } else {
      console.log('there is no dashboard connection.  WHY??');
    }
  }

  useEffect(() => {
    console.log('plansData', plansData);
  }, [plansData]);

  return (
    <div style={modalContentStyle}>
      
      <BillingPeriod setBillingPeriod={setBillingPeriod} billingPeriod={billingPeriod} />
      <RegistrationDivider />
      <PlanSelector 
        plansData={plansData}
        selectedPlanId={selectedPlanId}
        setSelectedPlanId={setSelectedPlanId}
        handleAccessCode={handleAccessCode}
        handleSelectLockedBox={handleSelectLockedBox}
        handleButtonClick={handleButtonClick}
        handleAccessCodeChange={handleAccessCodeChange}
        accessCodeInputRef={accessCodeInputRef}
        isAccessCodeActive={isAccessCodeActive}
        accessCode={accessCode}
        formatRate={formatRate}
        billingPeriod={billingPeriod}
        buttonStyle={buttonStyle}
        arrowIconStyle={arrowIconStyle}
        openDialog={openDialog}
        renderMe={renderMe}
      />

      <div className="message-container">
        {message && <p className="message">{message}</p>}
      </div>
      <DialogActions className="dialog-actions-custom" style={{ marginTop: '-16px' }}>
        <Button
          variant="outlined"
          style={secondaryButtonStyle}
          onClick={handleBackClick}
        >
          <FontAwesomeIcon icon={faArrowLeft} fontSize="smaller" /> Back
        </Button>
      </DialogActions>
      <Dialog
        open={isDialogOpen}
        onClose={onClose}
        aria-labelledby="access-code-dialog-title"
        aria-describedby="access-code-dialog-description"
      >
        <DialogTitle id="access-code-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="access-code-dialog-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setIsDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddPlan;