import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

const HitchStyledDataGrid = styled(DataGrid)(({ theme }) => ({
  //fontFamily: '"Poppins", sans-serif',
  '& .fixed-background': {
    backgroundColor: theme.palette.fixedGridBackground.default,
    '&:hover': {
      backgroundColor: 'theme.palette.fixedGridBackground.hover[theme.palette.mode]',
    },
    '&.MuiSelected': {
      backgroundColor: theme.palette.fixedGridBackground.selected[theme.palette.mode],
      '&:hover': {
        backgroundColor: theme.palette.fixedGridBackground.selected_hover[theme.palette.mode],
      },
    }
  },
  '& .deactivated-row': {
    fontStyle: 'italic',
  },
  '& .role-link, & .action-link': {
    color: theme.palette.link[theme.palette.mode],
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '& .MuiDataGrid-cell': {
    borderColor: theme.palette.gridCellBorderColor[theme.palette.mode],
    border: '1px',
    color: '#000', // Custom style
    fontSize: '14px', // Custom style
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
    color: '#d3d3d3', // Custom style
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#333', // Custom style
    borderRadius: '0px' // Ensure no border radius
  },
  '& .MuiDataGrid-footerContainer': {
    backgroundColor: '#333', // Custom style
    borderRadius: '0px' // Ensure no border radius
  },
  '& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIconButton': {
    opacity: 1,
    color: theme.palette.link[theme.palette.mode],
  },
  '& .MuiDataGrid-iconSeparator': {
    opacity: 1,
    color: theme.palette.gridColumnHeaders[theme.palette.mode]
  },
  '& .MuiDataGrid-footerContainer': {
    backgroundColor: theme.palette.gridColumnHeaders[theme.palette.mode],
    color: theme.palette.gridColumnHeadersText[theme.palette.mode],
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.gridColumnHeaders[theme.palette.mode],
    color: theme.palette.gridColumnHeadersText[theme.palette.mode],
    fontSize: 16,
    borderRadius: '0px' // Ensure no border radius
  },
  '& .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row': {
    borderBottom: '1px solid #000 !important',
  },
  '& .MuiDataGrid-row': {
    '&.paid-row': {
      backgroundColor: '#4CAF50',
    },
    '&.pending-row': {
      backgroundColor: '#FFC107',
    },
    '&.unpaid-row': {
      backgroundColor: '#FF5722',
    },
    '&.past-due-row': {
      backgroundColor: '#FFC107',
    },
  },
  borderRadius: '0px', // Ensure no border radius for the entire grid
}));

export default HitchStyledDataGrid;
