import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import userManager from '../../services/authService';

const SigninOidc = () => {
    const navigate = useNavigate();
    const [hitchUser, setHitchUser] = useState(null);

    useEffect(() => {
        async function handleRedirectCallback() {
            try {
                const user = await userManager.signinRedirectCallback();
                console.log('signinRedirectCallback completed successfully', user);

                if (user && user.id_token) {
                    console.log('Fetching user from API...');
                    const response = await fetch(`${window._env_.HITCH_API}/api/ephesians_1_3`, {
                        headers: {
                            'Authorization': `Bearer ${user.id_token}`
                        }
                    });

                    if (response.ok) {
                        const data = await response.json();
                        console.log('Hitch user data:', data);
                        setHitchUser(data);
                        const redirectTo = sessionStorage.getItem('redirectTo') || '/app';
                        sessionStorage.removeItem('redirectTo');
                        navigate(redirectTo);
                    } else if (response.status === 404) {
                        navigate('/app/registration');
                    } else {
                        console.error('Failed to fetch hitchUser:', response.statusText);
                    }
                } else {
                    const redirectTo = sessionStorage.getItem('redirectTo') || '/app';
                    sessionStorage.removeItem('redirectTo');
                    navigate(redirectTo);
                }
            } catch (error) {
                console.error('Error occurred during signin redirect callback:', error);
                navigate('/error');
            }
        }

        handleRedirectCallback();
    }, [navigate]);

    return <div>Processing login...</div>;
};

export default SigninOidc;
