import React, { useState, useEffect } from "react";
import { Box, Button, Grid, TextField, Tabs, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Collapse } from "@mui/material";
import AdminSearchGridV2 from "../AdminSearchGridV2";
import ServiceDetails from "./ServiceDetails";
import ServiceConnections from "./ServiceConnections";
import ServiceFeatures from "./ServiceFeatures";
import ServiceOperations from "./ServiceOperations";
import { useAdminHubConnections } from "../../utils/HubAdminConnectionProvider";
import CustomTab from "../User/CustomTab";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HitchBox from "../../components/hitchbox/HitchBox";

const Services = ({ activeService, setActiveService }) => {
    const [list, setList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedServices, setSelectedServices] = useState([]);
    const [serviceTabValue, setServiceTabValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [expandedDetails, setExpandedDetails] = useState(true);
    const [expandedConnections, setExpandedConnections] = useState(true);
    const [expandedFeatures, setExpandedFeatures] = useState(true);
    const [expandedOperations, setExpandedOperations] = useState(true);
    const { adminConnection, hitchUser, isConnectionReady } = useAdminHubConnections();

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Service Name', width: 300 }
    ];

    useEffect(() => {
        if (adminConnection) {
            const subscribeToEvents = () => {
                adminConnection.on('Services', (data) => {
                    setLoading(true);
                    setList(data);
                });

                adminConnection.on('Service', (data) => {
                    const editingModel = data;
                    setSelectedServices((prevServices) => [...prevServices, editingModel]);
                });

                // Add other event subscriptions here
            };

            subscribeToEvents();

            adminConnection.invoke("GetServices", hitchUser?.userId, searchTerm)
                .then(() => {
                    console.log('getting services');
                    setLoading(true);
                })
                .catch(error => console.error("Error sending message:", error));

            return () => {
                adminConnection.off('Services');
                adminConnection.off('Service');
                // Unsubscribe from other events here
            };
        }
    }, [adminConnection]);

    const filter = (term) => {
        if (adminConnection) {
            adminConnection.invoke("GetServices", hitchUser?.userId, term)
                .then(() => setLoading(true))
                .catch(error => console.error("Error sending message:", error));
        }
    };

    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        if (term) {
            filter(term);
        } else {
            setList([]); // Show full list when search is cleared
        }
    };

    const handleSearchFocus = () => {
        filter(''); // Show full list on focus
    };

    const handleRowClick = (params) => {
        console.log('Selected service:', params.row);
        const selectedService = list.find(service => service.id === params.row.id);
        setSelectedServices(prevServices => [...prevServices, selectedService]);
        setSearchTerm(''); // Clear search term to hide the search list
        setList([]); // Hide the list after selection
        setServiceTabValue(selectedServices.length); // Switch to the newly added tab
    };

    const handleServiceTabChange = (event, newValue) => {
        setServiceTabValue(newValue);
    };

    const handleTabClose = (index) => {
        setSelectedServices((prevServices) => prevServices.filter((_, i) => i !== index));
        if (serviceTabValue === index && selectedServices.length > 1) {
            setServiceTabValue(index === 0 ? 0 : index - 1);
        } else if (selectedServices.length === 1) {
            setServiceTabValue(0);
        }
    };

    const handleCloseSearch = () => {
        setList([]);
        setSearchTerm('');
    };

    const handleSave = () => {
        // Simulate save
        alert("Service details updated successfully");
        setEditMode(false);
    };

    const handleCancel = () => {
        setEditMode(false);
    };

    const handleDelete = () => {
        // Simulate service deletion
        alert("Service deleted successfully");
        setOpenDeleteDialog(false);
    };

    const handleAddNew = () => {
        // Add new service logic here
    };

    return (
        <HitchBox backgroundColor={"#004444"} color={"#00eded"}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={10}>
                    <TextField
                        label="Search Services"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onFocus={handleSearchFocus}
                        fullWidth
                        sx={{ backgroundColor: '#fff', color: '#000', borderColor: '#d3d3d3' }}
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={handleCloseSearch} sx={{ color: '#fff' }}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary" onClick={handleAddNew}>
                        Add New
                    </Button>
                </Grid>
                {list.length > 0 && (
                    <Grid item xs={12}>
                        <AdminSearchGridV2
                            columns={columns}
                            list={list}
                            handleRowClick={handleRowClick}
                            rowSelectionModel={[]}
                        />
                    </Grid>
                )}
                {selectedServices.length > 0 && (
                    <Grid item xs={12}>
                        <Tabs
                            value={serviceTabValue}
                            onChange={handleServiceTabChange}
                            aria-label="service tabs"
                            TabIndicatorProps={{ style: { backgroundColor: '#00eded' } }} // Change the color of the indicator
                        >
                            {selectedServices.map((service, index) => (
                                <CustomTab
                                    key={service.id}
                                    label={service.name}
                                    onClose={() => handleTabClose(index)}
                                    sx={{
                                        color: serviceTabValue === index ? '#00eded' : '#000',
                                        '&.Mui-selected': {
                                            color: '#00eded',
                                        },
                                    }}
                                />
                            ))}
                        </Tabs>
                        {selectedServices.map((service, index) => (
                            <Box key={service.id} hidden={serviceTabValue !== index}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                startIcon={<DeleteIcon />}
                                                onClick={() => setOpenDeleteDialog(true)}
                                            >
                                                Delete Service
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={{ border: '1px solid #00eded', padding: '20px', borderRadius: '4px' }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Typography variant="h5" sx={{ marginBottom: '20px', color: '#00eded' }}>
                                                    Service Details
                                                </Typography>
                                                <Box>
                                                    <IconButton
                                                        onClick={() => setExpandedDetails(!expandedDetails)}
                                                        aria-expanded={expandedDetails}
                                                        aria-label="show more"
                                                    >
                                                        {expandedDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </IconButton>
                                                    {editMode ? (
                                                        <>
                                                            <IconButton onClick={handleSave} color="primary">
                                                                <SaveIcon />
                                                            </IconButton>
                                                            <IconButton onClick={handleCancel} color="secondary">
                                                                <CancelIcon />
                                                            </IconButton>
                                                        </>
                                                    ) : (
                                                        <IconButton onClick={() => setEditMode(true)} color="primary">
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            </Grid>
                                            <Collapse in={expandedDetails} timeout="auto" unmountOnExit>
                                                <ServiceDetails service={service} editMode={editMode} />
                                            </Collapse>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={{ border: '1px solid #00eded', padding: '20px', borderRadius: '4px', marginBottom: '20px' }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Typography variant="h5" sx={{ marginBottom: '20px', color: '#00eded' }}>
                                                    Connections
                                                </Typography>
                                                <IconButton
                                                    onClick={() => setExpandedConnections(!expandedConnections)}
                                                    aria-expanded={expandedConnections}
                                                    aria-label="show more"
                                                >
                                                    {expandedConnections ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                            </Grid>
                                            <Collapse in={expandedConnections} timeout="auto" unmountOnExit>
                                                <ServiceConnections />
                                            </Collapse>
                                        </Box>
                                        <Box sx={{ border: '1px solid #00eded', padding: '20px', borderRadius: '4px', marginBottom: '20px' }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Typography variant="h5" sx={{ marginBottom: '20px', color: '#00eded' }}>
                                                    Features
                                                </Typography>
                                                <IconButton
                                                    onClick={() => setExpandedFeatures(!expandedFeatures)}
                                                    aria-expanded={expandedFeatures}
                                                    aria-label="show more"
                                                >
                                                    {expandedFeatures ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                            </Grid>
                                            <Collapse in={expandedFeatures} timeout="auto" unmountOnExit>
                                                <ServiceFeatures />
                                            </Collapse>
                                        </Box>
                                        <Box sx={{ border: '1px solid #00eded', padding: '20px', borderRadius: '4px' }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Typography variant="h5" sx={{ marginBottom: '20px', color: '#00eded' }}>
                                                    Operations
                                                </Typography>
                                                <IconButton
                                                    onClick={() => setExpandedOperations(!expandedOperations)}
                                                    aria-expanded={expandedOperations}
                                                    aria-label="show more"
                                                >
                                                    {expandedOperations ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                            </Grid>
                                            <Collapse in={expandedOperations} timeout="auto" unmountOnExit>
                                                <ServiceOperations />
                                            </Collapse>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}
                    </Grid>
                )}
            </Grid>
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this service?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="error" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </HitchBox>
    );
};

export default Services;
