import React, { useState, useEffect } from 'react';
import {
    Stack,
    Button,
    Typography,
    Box,
    Alert,
} from '@mui/material';
import './carolina.css';
import CommonSense from './Images/carolina-amesty-logo-common-sense.jpg';
import './stuff/FontAwesome/css/font-awesome.css';  // Ensure these files are in the public directory
import './stuff/bootstrap/css/bootstrap.min.css';
import './stuff/bootstrap/css/bootstrap-grid.min.css';
import './stuff/bootstrap/css/bootstrap-reboot.min.css';
import './stuff/smart-cart/minicart-theme.css';
import './stuff/popup-overlay-plugin/style.css';
import './stuff/dropdown/css/style.css';
import './stuff/formstyler/jquery.formstyler.css';
import './stuff/formstyler/jquery.formstyler.theme.css';
import './stuff/datepicker/jquery.datetimepicker.min.css';
import './stuff/theme/css/style.css';
import './stuff/recaptcha.css';
import './stuff/mbr-additional.css';
import './stuff/mobirise2.css';

import AiChatDrawer from '../chat-system/AiChatDrawer.jsx';
import carolinaTheme from './carolina-theme.js';

function CarolinaAmesty() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const openDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
    };


    return (
        <>

            <link href='https://fonts.googleapis.com/css?family=Jost' rel='stylesheet'></link>
            {/* CAROLINA CAMPAIGN PAGE */}
            <section data-bs-version="5.1" className="menu menu3 cid-t22ZgNBidg" data-once="menu" id="menu3-3">
                <nav className="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg">
                    <div className="container-fluid">
                        <div className="navbar-brand">
                            <span className="navbar-logo">
                                <a href="index.html">
                                    <Box
                                        component="img"
                                        src={CommonSense}
                                        alt="Carolina Amesty for State Representative"
                                        sx={{
                                            height: { xs: '4rem', sm: '6rem' }, // responsive height
                                            maxWidth: '100%', // ensures it doesn't overflow
                                            objectFit: 'contain', // scales the image appropriately
                                        }}
                                    />
                                </a>
                            </span>
                        </div>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-target="#navbarSupportedContent" data-bs-target="#navbarSupportedContent" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <div className="hamburger">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav nav-dropdown" data-app-modern-menu="true">
                                <Alert severity="warning">This is a demo page, not authorized by any candidate, for demonstration purposes only.</Alert>
                            </ul>
                            <div className="icons-menu">
                                <a className="iconfont-wrapper" href="https://www.facebook.com/electcarolina" target="_blank" rel="noopener noreferrer">
                                    <span className="p-2 mbr-iconfont socicon-facebook socicon"></span>
                                </a>
                                <a className="iconfont-wrapper" href="https://www.instagram.com/electcarolina/" target="_blank" rel="noopener noreferrer">
                                    <span className="p-2 mbr-iconfont socicon-instagram socicon"></span>
                                </a>
                                <a className="iconfont-wrapper" href="https://twitter.com/carolina_amesty" target="_blank" rel="noopener noreferrer">
                                    <span className="p-2 mbr-iconfont socicon-twitter socicon"></span>
                                </a>
                            </div>
                            <div className="navbar-buttons mbr-section-btn">
                                <a className="btn btn-primary display-7" href="https://secure.anedot.com/bc68eb50-48f2-4eac-a745-70c1d4129293/ed194c22-d7b2-48e8-a56e-05bd1206a220" target="_blank" rel="noopener noreferrer">
                                    <span className="fa fa-arrow-circle-o-right mbr-iconfont mbr-iconfont-btn"></span>CONTRIBUTIONS
                                </a>
                            </div>
                        </div>
                    </div>
                </nav>
            </section>
            <section data-bs-version="5.1" className="header1 cid-t230F826W5" id="header1-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-7"></div>
                    </div>
                </div>
                <div className="mbr-section-btn">
                    <a className="btn one btn-primary display-4" target="_blank" href="https://secure.anedot.com/bc68eb50-48f2-4eac-a745-70c1d4129293/ed194c22-d7b2-48e8-a56e-05bd1206a220">DONATE NOW</a>
                    <a className="btn btn-secondary display-4" onClick={(e) => openDrawer()}>ASK OUR AI!</a>
                </div>
            </section>

            {/* END CAROLINA CAMPAIGN PAGE */}

            {/* Render the ChatDrawer component */}
            <AiChatDrawer 
                isOpen={drawerOpen} 
                onClose={closeDrawer} 
                theme={carolinaTheme}
                mode={'carolina'}
                defaultPlaceholder='How Can Carolina Help You Today?' /> 
        </>
    );
}

export default CarolinaAmesty;
