import React, { useState, useEffect } from 'react';
import { Avatar, Tooltip, useTheme, Box, IconButton, Modal, Typography, TextField, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faTimes } from '@fortawesome/pro-solid-svg-icons';
import HitchTable from './HitchTable';
import ReactMarkdown from "react-markdown";
import JSONTreeView from './JSONTreeView';
import MessageError from './MessageError';
import MessageLink from './MessageLink';
import MessageProduct from './MessageProduct';
import MessageAdaptiveCard from './MessageAdaptiveCard';
import MarkdownRenderer from './MarkdownRenderer';

function Message({
    editingMessage,
    editedMessageText,
    hoveredMessageId,
    index,
    message,
    messageIsHelpful,
    messageIsNotHelpful,
    saveEditedMessage,
    setEditedMessageText,
    setEditingMessage,
    setHoveredMessageId,
    startEditingMessage,
    user,
    hitchUser,
    users,
    createNewTab,
    isTabletMobile,
    isMobile,
    isTablet,
}) {
    const relatedUser = users?.find((u) => u.id === message.user);
    const isCurrentUserMessage = message.user === hitchUser?.userId;
    const isEditingCurrentMessage = editingMessage?.messageId === message.messageId;
    const originalText = message.text;
    const isFirstMessage = index === 0;
    const showCopyIcon = !(isFirstMessage);
    const [isLinkIconClicked, setIsLinkIconClicked] = useState(false);
    const [isThumbsUpClicked, setIsThumbsUpClicked] = useState(false);
    const [isThumbsDownClicked, setIsThumbsDownClicked] = useState(false);
    const [isSecondCopyClicked, setIsSecondCopyClicked] = useState(false);
    const [userAvatar, setUserAvatar] = useState(null);
    const paddingLeft = isMobile || isTablet ? '8px' : '32px';
    const [isHovered, setIsHovered] = useState(false);
    const theme = useTheme();
    const themeBackgroundColor = theme.palette.secondary[theme.palette.mode];
    const themeTextColor = theme.palette.secondaryText[theme.palette.mode];
    const themeListItemSelected = theme.palette.listItemBackground.selected[theme.palette.mode];
    const themeGlowingSmileyColor = theme.palette.glowingSmiley[theme.palette.mode];

    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
    const [negativeFeedback, setNegativeFeedback] = useState("");
    const [feedbackError, setFeedbackError] = useState(false);

    const themeTextPrimaryColor = theme.palette.chatLeftMenuPrimaryText[theme.palette.mode];
    const themeListItemHovered = theme.palette.listItemBackground.hover[theme.palette.mode];
    const themeListItemSelectedHovered = theme.palette.listItemBackground.selected_hover[theme.palette.mode];
    const themeModalBackgroundColor = theme.palette.modalBackground[theme.palette.mode];
    const themeModalTextPrimaryColor = theme.palette.modalTextPrimary[theme.palette.mode];
    const themeModalTextSecondaryColor = theme.palette.modalTextSecondary[theme.palette.mode];

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: {
            xs: '90%',
            sm: '80%',
            md: '70%',
            lg: '600px',
        },
        backgroundColor: themeModalBackgroundColor,
        color: themeModalTextSecondaryColor,
        borderColor: 'gray',
        borderStyle: 'solid',
        borderWidth: '1px',
        boxShadow: '0 4px 8px 0 rgba(255, 255, 255, 0.2)',
        p: 4,
        position: 'relative',
        textAlign: 'center',
        borderRadius: '10px',
        fontFamily: '"Roboto", sans-serif',
    };

    const saveButtonStyleRename = {
        fontFamily: 'Roboto, sans-serif',
        color: '#000000',
        backgroundColor: '#00EDED',
        '&:hover': {
            backgroundColor: '#00EDED',
        },
        '&:active': {
            backgroundColor: '#00EDED',
            boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.3)',
        },
        marginLeft: '20px',
    };

    const cancelButtonStyleRename = {
        color: 'white',
        backgroundColor: '#333',
        border: '1px solid #555',
        fontFamily: 'Roboto, sans-serif',
        '&:hover': {
            backgroundColor: '#333',
        },
    };

    const handleModalClose = () => {
        setIsFeedbackModalOpen(false);
    };

    useEffect(() => {
        if (hitchUser && hitchUser.userId) {
            fetch(`${window._env_.HITCH_API}/api/accimage/${hitchUser.userId}.png`)
                .then(response => response.blob())
                .then(blob => {
                    const url = URL.createObjectURL(blob);
                    setUserAvatar(url);
                })
                .catch((error) => {
                    console.error('user picture error', error);
                });
        }
    }, [hitchUser]);

    const handleThumbsDownClick = () => {
        setIsFeedbackModalOpen(true);
    };

    const handleFeedbackChange = (event) => {
        setNegativeFeedback(event.target.value);
    };

    const handleFeedbackSubmit = () => {
        messageIsNotHelpful(message, negativeFeedback);
        setIsFeedbackModalOpen(false);
        setNegativeFeedback("");
    };

    const handleFeedbackCancel = () => {
        setIsFeedbackModalOpen(false);
        setNegativeFeedback("");
    };

    const parseMessageText = (text) => {

        //{console.log('in parseMessageText', text)}
        let currentIndex = 0;

        if (Array.isArray(text)) {

            return text.map((section, index) => {
                const pattern = /(```[\s\S]*?```)/;
                const sections = section.split(pattern);

                const sectionElements = sections.map((s2, idx2) => {
                    if (s2.match(pattern)) {
                        currentIndex++;

                        return <CodeContainer key={currentIndex} code={s2} />;
                    } else {
                        let object = [];
                        try {
                            object = JSON.parse(s2);
                        } catch { }

                        if (object && object.rows && object.columns) {
                            try {
                                return (
                                    <HitchTable object={object} />
                                );
                            } catch (error) {
                                console.error("Error creating DataGrid:", error);
                                return (
                                    <div key={idx2} style={{ padding: '4px', overflow: 'hidden' }}>
                                        <ReactMarkdown>{s2}</ReactMarkdown>
                                    </div>
                                );
                            }
                        } else {
                            return (
                                <div key={idx2} style={{ padding: '4px', overflow: 'hidden' }}>
                                    <ReactMarkdown>{s2}</ReactMarkdown>
                                </div>
                            );
                        }
                    }
                });

                return sectionElements;
            });
        }
    };

    const CodeContainer = ({ code }) => {
        const codeLanguageMatch = code.match(/```(\w+)/);
        const codeLanguage = codeLanguageMatch ? codeLanguageMatch[1] : '';

        const cleanedCode = code.replace(/```(\w+)[\s\S]*?\n([\s\S]*)```/, '$2');

        const [isClicked, setIsClicked] = useState(false);

        const handleClick = () => {
            navigator.clipboard.writeText(cleanedCode);
            setIsClicked(true);
            setTimeout(() => {
                setIsClicked(false);
            }, 200);
        };

        return (
            <div
                style={{
                    position: 'relative',
                    maxWidth: '620px',
                    border: '1px solid #00EDED',
                    borderRadius: '5px',
                    overflowX: 'auto',
                    whiteSpace: 'pre',
                    margin: '8px 8px',
                    backgroundColor: '#2d2d2d',
                }}
            >
                <div
                    style={{
                        background: '#00EDED',
                        color: 'black',
                        padding: '4px',
                        borderTopLeftRadius: '5px',
                        borderTopRightRadius: '5px',
                        fontFamily: 'Roboto, sans-serif',
                        fontSize: '12px',
                        textTransform: 'uppercase',
                    }}
                >
                    {codeLanguage}
                </div>
                <pre
                    style={{
                        margin: '0',
                        padding: '12px',
                        lineHeight: '1.5',
                        color: '#f8f8f2',
                        fontFamily: 'monospace',
                        fontSize: '14px',
                    }}
                >
                    <code>{cleanedCode}</code>
                </pre>
                <div
                    style={{
                        position: 'absolute',
                        bottom: '8px',
                        right: '8px',
                        background: 'rgba(0, 0, 0, 0.7)',
                        padding: '4px',
                        borderRadius: '3px',
                    }}
                >
                    <Tooltip title="Copy code">
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{
                                '& svg': {
                                    fontSize: 14,
                                    color: isClicked ? '#00EDED' : '#FFFFFF',
                                    '&:hover': {
                                        color: '#00EDED',
                                    },
                                    '&:active': {
                                        backgroundColor: '#f0f0f0',
                                        borderRadius: '50%',
                                    },
                                },
                            }}
                        >
                            <FontAwesomeIcon icon={faCopy} />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        );
    };


    return (
        <Box
            key={message.messageId}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: isHovered
                    ? message.user === hitchUser?.userId
                        ? themeListItemHovered
                        : themeListItemHovered
                    : message.user === hitchUser?.userId
                        ? themeBackgroundColor
                        : themeBackgroundColor,
                fontFamily: 'Roboto, sans-serif',
                fontSize: '16px',
                paddingLeft: paddingLeft,
                paddingBottom: '8px',
                paddingTop: '8px',
                transition: 'background-color 0.3s',
                position: 'relative', // Make sure the message box is relatively positioned
            }}
        >
            {message.user === hitchUser?.userId || message.type === 'user' ? (
                <Tooltip title={relatedUser?.handle}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'top',
                    }}>
                        <Avatar
                            src={userAvatar}
                            style={{
                                marginRight: '8px',
                                width: '38px',
                                height: '38px',
                            }}
                        />
                    </div>
                </Tooltip>
            ) : (
                <Tooltip title={relatedUser?.handle}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'top',
                    }}>
                        <Avatar
                            src='/img/glowingsmiley.svg'
                            style={{
                                marginRight: '8px',
                                width: '38px',
                                height: '38px',
                                backgroundColor: themeGlowingSmileyColor,
                                boxShadow: '0 0 16px 1px rgba(54, 103, 89, 0.8)'
                            }}
                        />
                    </div>
                </Tooltip>
            )}

            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    textAlign: 'left',
                    color: themeTextColor,
                    padding: '4px',
                    borderRadius: '10px',
                    width: '90%',
                    justifyContent: 'space-between',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    lineHeight: '1.7',
                    letterSpacing: '0.25px',
                    fontFamily: '"Poppins", sans-serif',
                    fontSize: '16px',
                }}
                onMouseEnter={() => setHoveredMessageId(message.messageId)}
                onMouseLeave={() => setHoveredMessageId(null)}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div key={index} style={{ wordBreak: 'break-word' }}>
                        {message.content && parseMessageText(message.content)}
                        {message.message?.contentType && message.message?.contentType === 'Error' && message.message?.content && (
                            <MessageError message={message} />
                        )}
                        {message.contentType &&
                            (message.contentType === 'string' ||
                                message.contentType === 'text')
                            && (

                                <MarkdownRenderer content={message.content} />
                            )}
                        {message.contentType && message.contentType === 'JSON' && (
                            <div style={{ padding: '4px', overflow: 'hidden' }}>
                                {(() => {
                                    const payload = message.content;
                                    if (Array.isArray(payload)) {
                                        return <HitchTable object={payload} />;
                                    } else {
                                        return <JSONTreeView data={payload} />;
                                    }
                                })()}
                            </div>
                        )}
                        {message.contentType && (message.contentType === 'Link' || message.contentType === 'link') && (
                            <div style={{ padding: '4px', overflow: 'hidden' }}>
                                <MessageLink message={message} />
                            </div>
                        )}
                        {message.contentType && (message.contentType === 'Product' || message.contentType === 'product') && (
                            <div style={{ padding: '4px', overflow: 'hidden' }}>
                                <MessageProduct message={message} />
                            </div>
                        )}
                        {message.contentType && message.contentType === 'map' && (
                            <div style={{ padding: '4px', overflow: 'hidden', width: '1160px' }}>
                            </div>
                        )}
                        {message.contentType && message.contentType === 'MessageAdaptiveCard' && (
                            <div style={{ padding: '4px', overflow: 'hidden', width: '1160px' }}>
                                <MessageAdaptiveCard message={message} />
                            </div>
                        )}
                    </div>
                </div>


            </div>


            {isHovered && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: '-8px',
                        right: {
                            xs: '8px', // for extra-small screens (mobile)
                            sm: '10px', // for small screens (tablets)
                            md: '12px', // for medium screens (small desktops)
                            lg: '8%',   // for large screens (large desktops)
                        },
                        backgroundColor: themeModalBackgroundColor,
                        borderRadius: '4px',
                        border: '1px solid #333',
                        padding: '4px 16px 4px 16px',
                        zIndex: 1000,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title="Copy message" placement="bottom">
                        <IconButton
                            size="m"
                            onClick={() => {
                                const textToCopy = message.content ? message.content : message.text.join('\n');
                                navigator.clipboard.writeText(textToCopy);
                                setIsSecondCopyClicked(true);
                                setTimeout(() => setIsSecondCopyClicked(false), 200);
                            }}
                            sx={{
                                '& svg': {
                                    fontSize: 16,
                                    color: isSecondCopyClicked ? '#00EDED' : '#FFFFFF',
                                    '&:hover': {
                                        color: '#00EDED',
                                    },
                                },
                            }}
                        >
                            <FontAwesomeIcon icon={faCopy} />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}



            <Modal
                open={isFeedbackModalOpen}
                onClose={handleFeedbackCancel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <IconButton
                        aria-label="close"
                        onClick={handleModalClose}
                        sx={{
                            position: 'absolute',
                            right: 16,
                            top: 16,
                            color: themeModalTextSecondaryColor,
                        }}
                    >
                        <FontAwesomeIcon icon={faTimes} size="1x" />
                    </IconButton>
                    <Typography variant="h6" style={{ color: themeModalTextSecondaryColor, fontFamily: '"Roboto", sans-serif', marginBottom: '20px' }}>
                        Provide additional feedback
                    </Typography>
                    <TextField
                        placeholder="What was the issue with the response? How could it be improved?"
                        value={negativeFeedback}
                        onChange={handleFeedbackChange}
                        style={{ marginTop: '32px' }}
                        multiline
                        maxRows={5}
                        fullWidth
                        variant="outlined"
                        error={feedbackError}
                        helperText={feedbackError ? "Feedback is required" : ''}
                        InputLabelProps={{
                            style: {
                                color: 'grey',
                                fontFamily: '"Roboto", sans-serif',
                            },
                        }}
                        InputProps={{
                            style: {
                                color: themeModalTextSecondaryColor,
                                fontFamily: '"Roboto", sans-serif',
                            },
                            sx: {
                                '& .MuiOutlinedInput-notchedOutline': { borderColor: feedbackError ? 'red' : 'grey' },
                                '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: feedbackError ? 'red' : '#00EDED' },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: feedbackError ? 'red' : '#00EDED' },
                            },
                        }}
                    />
                    <Box display="flex" justifyContent="flex-end" marginTop="32px">
                        <Button onClick={handleFeedbackCancel} sx={cancelButtonStyleRename}>Cancel</Button>
                        <Button onClick={handleFeedbackSubmit} sx={saveButtonStyleRename} disabled={!negativeFeedback.trim()}>Send Feedback</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default Message;
