import { Box, Fade, Modal } from "@mui/material";

function HitchModal({ open, onClose, children }) {
    return (
        <Modal open={open} onClose={onClose} closeAfterTransition>
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        bgcolor: 'background.paper',
                        overflow: 'auto',
                    }}
                >
                    {children}
                </Box>
            </Fade>
        </Modal>
    );
}

export default HitchModal;