import confetti from 'canvas-confetti';

export function confettiDrop() {
    confetti({
        particleCount: 100,
        spread: 160,
        origin: { y: 0.6 }
    });
}

export function continuousConfettiDrop(duration = 15 * 1000) {
    const end = Date.now() + duration;

    const colors = ['#00eded', '#ffffff'];

    (function frame() {
        confetti({
            particleCount: 2,
            angle: 60,
            spread: 55,
            origin: { x: 0 },
            colors: colors
        });
        confetti({
            particleCount: 2,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
            colors: colors
        });

        if (Date.now() < end) {
            requestAnimationFrame(frame);
        }
    }());
}
