import React, { useEffect, useMemo, useState } from 'react';
import { IconButton, Collapse, Typography, Button, ListItem, ListItemButton, ListItemIcon, ListItemText, Avatar, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Handle, Position, useReactFlow } from 'reactflow';
import { styled } from '@mui/material/styles';
import SfoSelect from './SfoSelect';
import { DynamicForm } from '@mui/icons-material';
import { useHubConnections } from '../../utils/HubConnectionsProvider';

export default function HitchConditionDependencyNode({ id, data }) {
  const [service, setService] = useState(data.service || '');
  const [featureName, setFeatureName] = useState(data.featureName || '');
  const [operationName, setOperationName] = useState(data.operationName || '');

  const [serviceOptions, setServiceOptions] = useState([]);
  const [featureOptions, setFeatureOptions] = useState([]);
  const [operationOptions, setOperationOptions] = useState([]);

  const [dynamicFormFields, setDynamicFormFields] = useState([]);
  const [localData, setLocalData] = useState({});


  const reactFlowInstance = useReactFlow();
  const [ruleAttached, setRuleAttached] = useState(false);
  const [routes, setRoutes] = useState([]);
  const {
    dashboardConnection,
    jwtToken,
    hitchUser,
    user,
    isAuthenticated,
    setHitchUser,
    dashStarted,
    setSessionId,
    loading,
    conversationHistories,
    matrixRoutes,
    started,
    hitchCommand,
    setMessages,
    messages,
    conversation,
    users,
    hitchDashCommand
  } = useHubConnections();

  useEffect(() => {
    if (dashboardConnection) {
      // COMMENT TO TEST LOCAL JSON, UNCOMMENT FOR SERVER VERSION
      dashboardConnection.on("RouteUpdate", handleRouteUpdate);
      dashboardConnection.on("MatrixRoutes", setMatrixRoutes);

      hitchDashCommand('account', 'connection', 'route');

      // Cleanup listener when component unmounts or hubConnection changes
      return () => {
        dashboardConnection.off("RouteUpdate", handleRouteUpdate);
        dashboardConnection.off("MatrixRoutes", setMatrixRoutes);
      }
    }
  }, [dashboardConnection]);

  function setMatrixRoutes(data) {
    setRoutes(data);
  }

  useEffect(() => {
    const serviceMap = new Map();
    routes
      .forEach(r => {
        if (!serviceMap.has(r.serviceId)) {
          serviceMap.set(r.serviceId, { value: r.serviceId, label: r.system });
        }
      });
    const serviceOptionsUpdate = Array.from(serviceMap.values());

    // For unique feature options
    const featuresMap = new Map();
    routes
      .filter(r => r.serviceId === localData.serviceId)
      .forEach(r => {
        if (!featuresMap.has(r.featureId)) {
          featuresMap.set(r.featureId, { value: r.featureId, label: r.operationType });
        }
      });
    const featureOptionsUpdate = Array.from(featuresMap.values());

    // For unique operation options
    const operationsMap = new Map();
    routes
      .filter(r => r.serviceId === localData.serviceId && r.featureId === localData.featureId)
      .forEach(r => {
        if (!operationsMap.has(r.operationId)) {
          operationsMap.set(r.operationId, { value: r.operationId, label: r.operationSubtype });
        }
      });
    const operationOptionsUpdate = Array.from(operationsMap.values());
    setServiceOptions(serviceOptionsUpdate);
    setFeatureOptions(featureOptionsUpdate);
    setOperationOptions(operationOptionsUpdate);
  }, [routes, localData.serviceId, localData.featureId]);

  useEffect(() => {
    // Call checkRuleAttachment and update state based on its return value
    const ruleIsAttached = checkRuleAttachment(); // This now uses the reactFlowInstance directly
    setRuleAttached(ruleIsAttached);
  }, [reactFlowInstance, id]); // Depend on reactFlowInstance and id to re-check when they change

  const handleRouteUpdate = (data) => {
    console.log('Route Update', data);
  }

  const handleServiceChange = (event) => {
    const newServiceId = event.target.value;
    // Update localData in an immutable way
    setLocalData(prevData => ({
      ...prevData,
      serviceId: newServiceId,
      featureId: '',
      operationId: ''
    }));
  };

  const handleFeatureChange = (event) => {
    const newFeatureId = event.target.value;
    // Update localData in an immutable way
    setLocalData(prevData => ({
      ...prevData,
      featureId: newFeatureId,
      // Optionally reset operationId when feature changes
      operationId: ''
    }));
  };

  const handleOperationChange = (event) => {
    const newOperationId = event.target.value;
    // Update localData in an immutable way
    setLocalData(prevData => ({
      ...prevData,
      operationId: newOperationId
    }));
  };

  const checkRuleAttachment = () => {
    const edges = reactFlowInstance.getEdges();
    const isAttached = edges.some(edge => edge.source === id || edge.target === id);
    return isAttached;
  };

  const handleDeleteNode = (nodeId) => {
    console.log('delete!', nodeId);
    data.onNodeCallback('local', 'node', 'delete', nodeId);
  };

  useEffect(() => {
    if (data) {
      setLocalData(data);
    }
  }, [data]);

  return (
    <>
    {!localData.serviceId && (
      <>
        <div className="custom-node__header">
          <Typography>Select A Service</Typography>
        </div>
        <div className="custom-node__body">
        <SfoSelect
          options={serviceOptions}
          handleChange={handleServiceChange}
          localId={localData.serviceId}
          nodeId={id}
          label={'Service Name'}
        />
        </div>
      </>
    )}
    {localData.serviceId && (
      <>
      <div className="custom-node__header">
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Avatar
                src={`${window._env_.HITCH_API}/api/accimage/${localData.serviceId}.png`}
              />
            </ListItemIcon>
            <ListItemText primary={localData.serviceName} />
          </ListItemButton>
        </ListItem>
      </div>
      <div className="custom-node__body">
        <SfoSelect
          options={featureOptions}
          handleChange={handleFeatureChange}
          localId={localData.featureId}
          nodeId={id}
          label={'Feature Name'}
        />
        <SfoSelect
          options={operationOptions}
          handleChange={handleOperationChange}
          localId={localData.operationId}
          nodeId={id}
          label={'Operation Name'}
        />
        <DynamicForm localData={localData} setLocalData={setLocalData} />
        <Divider />
        <Button variant="contained" color="error" onClick={() => handleDeleteNode(id)}>
          Delete
        </Button>
        <Handle type="target" position={Position.Left}  className="custom-handle dependency-handle-left" id={id} />
      </div>
      </>
      )}
    </>
  );
}
