import { Grid, ListItem, Typography, FormControl, FormControlLabel, TextField, Switch, Box, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignalStream, faSignalStreamSlash } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from 'react';
import { faSignalSlash, faSignalStrong } from "@fortawesome/pro-solid-svg-icons";
import HitchBox from "../../../components/hitchbox/HitchBox";
import OperationSelectionModel from "../../OperationSelectionModal/OperationSelectionModal";
import { camelCase } from 'lodash';

function GitHubFileSource() {
    const [formData, setFormData] = useState({
        id: "3e9b91cc-041a-430b-9b73-9fa795d1f56a",
        accountId: "b287f0f3-cf3e-4e7b-94b1-296dfe0b1f6f",
        created: "2024-05-05T04:51:33.4997037Z",
        createdBy: "eb2d7430-040a-4562-bf92-40990b19ae50",
        name: "Untitled store",
        updated: "2024-05-05T04:51:33.4997037Z",
        updatedBy: "eb2d7430-040a-4562-bf92-40990b19ae50",
        entity: "RepoSource",
        fileType: "files",
        foreignKeyContainer: "",
        pushToSource: true,
        putFileOperation: "f9843b26-7060-4c5f-8aa7-059f28204dd2",
        deleteFileOperation: "aadc4af4-84ae-4cfa-a34f-c703e7f97e14",
        putMetadataOperation: "a0713302-5b2e-47d6-a85c-26858f06c21f",
        deleteMetadataOperation: "5302ded9-602e-4f21-a4d2-42caf22ccc82",
        pullFromSource: false,
        listOperation: null,
        getOperation: null,
        repositoryId: "f4eac604-c688-4131-a03b-7a8bcce28bcc",
    });

    const [connectedOperations, setConnectedOperations] = useState({
        put: false,
        delete: false,
        putMetadata: false,
        deleteMetadata: false,
        list: false,
        get: false,
    });

    const [openSelector, setOpenSelector] = useState(false);
    const [currentOperation, setCurrentOperation] = useState('');

    const handleToggleSwitch = (key) => {
        setFormData(prev => ({ ...prev, [key]: !prev[key] }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleOperationClick = (operation) => {
        // console.log(`${operation} clicked`);
        setCurrentOperation(operation);
        setOpenSelector(true);
        // Implement the logic for the operation
    };

    const camelCaseOperation = (service, feature, operation) => {
        return camelCase(`${service}_${feature}_${operation}`);
    };

    function onSelectedOperation(service, feature, operation) {
        let selection = camelCaseOperation(service, feature, operation);
        // console.log('onSelectedOperation', selection);
        setFormData(prev => ({ ...prev, [currentOperation]: selection }));
        setOpenSelector(false);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">Let's Connect this store to Git Hub.</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </FormControl>
                <FormControl fullWidth>
                    <TextField
                        label="Git Clone Address"
                        name="foreignKeyContainer"
                        value={formData.foreignKeyContainer}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <HitchBox backgroundColor='#003333'>
                    <FormControlLabel
                        control={<Switch checked={formData.pushToSource} onChange={() => handleToggleSwitch('pushToSource')} />}
                        label="Send To GitHub"
                    />
                    {["putFileOperation", "deleteFileOperation", "putMetadataOperation", "deleteMetadataOperation"].map(op => (
                        <Box key={op} display="flex" alignItems="center" onClick={() => handleOperationClick(op)}>
                            <Typography variant="subtitle1" style={{ marginRight: 8 }}>{`${camelCase(op.replace('Operation', ''))} Operation`}</Typography>
                            <IconButton disabled={!formData[op]} >
                                <FontAwesomeIcon icon={connectedOperations[op.replace('Operation', '')] ? faSignalStrong : faSignalSlash} />
                            </IconButton>
                            <Typography>{connectedOperations[op.replace('Operation', '')] ? "CONNECTED" : "NOT CONNECTED"}</Typography>
                        </Box>
                    ))}
                </HitchBox>
            </Grid>
            <Grid item xs={6}>
                <HitchBox backgroundColor='#003333'>
                    <FormControlLabel
                        control={<Switch checked={formData.pullFromSource} onChange={() => handleToggleSwitch('pullFromSource')} />}
                        label="Pull From GitHub"
                    />
                    {["listOperation", "getOperation"].map(op => (
                        <Box key={op} display="flex" alignItems="center" onClick={() => handleOperationClick(op)}>
                            <Typography variant="subtitle1" style={{ marginRight: 8 }}>{`${camelCase(op.replace('Operation', ''))} Operation`}</Typography>
                            <IconButton disabled={!formData[op]}>
                                <FontAwesomeIcon icon={connectedOperations[op.replace('Operation', '')] ? faSignalStrong : faSignalSlash} />
                            </IconButton>
                            <Typography>{connectedOperations[op.replace('Operation', '')] ? "CONNECTED" : "NOT CONNECTED"}</Typography>
                        </Box>
                    ))}
                </HitchBox>
            </Grid>

            <OperationSelectionModel open={openSelector} setOpen={setOpenSelector} selected={onSelectedOperation} />
        </Grid>
    );
}

export default GitHubFileSource;
