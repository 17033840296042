import React from "react";
import { Box, Button, ButtonGroup, CircularProgress, circularProgressClasses } from "@mui/material";
import { styled } from '@mui/material/styles';
import { TextField } from "@mui/material";
import { Grid } from "@mui/material";
import HitchSpinner from "../Spinner/HitchSpinner";

function QbPrompt({ prompt, updatePrompt, busy, executePrompt, stopExecution, reset, gptResponse }) {
    return (
        <Grid className="qb_top_row" container spacing={2} alignItems="center" justifyContent="center"> {/* Center vertically and horizontally */}
            <Grid item xs={12}>
                <TextField
                    multiline
                    fullWidth
                    rows={4}
                    value={prompt}
                    onChange={(event) => updatePrompt(event.target.value)}
                    label="Prompt"
                />
            </Grid>
            <HitchSpinner visible={busy} />
            <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                    <ButtonGroup>
                        {!busy && (<Button color="error" variant="contained" onClick={reset}>Reset</Button>)}
                        {!busy && (<Button variant="contained" onClick={executePrompt}>Run Prompt</Button>)}
                        {busy && (<Button variant="contained" color="error" onClick={stopExecution}>Stop Execution</Button>)}
                    </ButtonGroup>
                </Grid>
            </Grid>
            {
                gptResponse && (
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            fullWidth
                            rows={4}
                            value={gptResponse}
                            label="GPT Response"
                        />
                    </Grid>
                )
            }
        </Grid>
    )
}

export default QbPrompt;
