import { FormControlLabel, FormGroup, Grid, ListItem, Switch, Typography } from "@mui/material";
import HitchBox from "../../components/hitchbox/HitchBox";
import NewSource from "./New/NewSource";
import { useEffect } from "react";
import OpenAiFileSource from "./OpenAi/OpenAiFileSource";

function DisplaySource({selectedSource, setSelectedSource}) {

    useEffect(() => {
        // console.log('selectedSource', selectedSource);
    }, [selectedSource]);

    return (
        <Grid container>
            {selectedSource && selectedSource.serviceId == 'e59d1ba9-5b71-4f5a-9205-9ce82cbf2dfc' && (<OpenAiFileSource selectedSource={selectedSource} setSelectedSource={setSelectedSource} />)}

            {/* <Grid item xs={12}>
                <Typography variant="h6">Selected: {selectedSource.id}</Typography>
            </Grid>
            <Grid item xs={12}>
                
            <FormGroup>
                <FormControlLabel control={<Switch defaultChecked />} label="Inbound" />
                <FormControlLabel control={<Switch />} label="Outbound" />
            </FormGroup>
                
            </Grid> */}
            
        </Grid>
        
    );
}

function SourceDetails({selectedSource, setSelectedSource}) {
    return (
        <HitchBox backgroundColor='#005555' color='#FFFF00'>
            {selectedSource && selectedSource !== 'new' && (<DisplaySource selectedSource={selectedSource} setSelectedSource={setSelectedSource} />)}
            {selectedSource && selectedSource === 'new' && (<NewSource />)}
        </HitchBox>
    );
}

export default SourceDetails;