import { Stack, Typography, Box, Button, useMediaQuery } from '@mui/material';
import React from 'react';

function Integrations()  {

    
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up('sm') && theme.breakpoints.down('md'));

    return (
        <Box bgcolor="#ffffff"
        justifyContent="center" 
        sx={{ 
            paddingX: { xs: 1, sm: 6, md: 15 },
            paddingY: { xs: 4, sm: 11, md: 8 }
        }}
        >
            <Stack 
                direction={{ xs: 'column', sm: 'row' }}
                spacing={isMobile ? 1 : (isTablet ? 1 : 1)}
                justifyContent={{ xs: 'flex-start', sm: 'space-evenly' }}
                sx={{ 
                    paddingX: { xs: 1, sm: 0, md: 0 } ,
                    ...(isMobile ? {} : { borderTop: '1px solid #F3F4F6' }), // Conditionally apply border  
                    
                }}
            >
                <Box 
                    bgcolor="#ffffff"
                    flex={isDesktop ? 5 : 6}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: { xs: 3, sm: 3, md: 4 }, // Adjusted top margin
                      paddingBottom: { xs: 3, sm: 2, md: 4 }, // Adjusted top margin    
                    }}
                >
                    <Box bgcolor="#ffffff">
                    <Typography
                    variant="h2"
                    sx={{
                        fontWeight: '600',
                        fontSize: { xs: '36px', sm: '40px', md: '54px' },
                        color: "#111827",
                        letterSpacing: '-1.5px', // Adjust the value based on your preference
                        marginBottom: { xs: 1, sm: 1, md: 1 },
                        lineHeight: 1.15, // Adjusted line height
                    }}
                    >
                        Easy integrations
                    </Typography>
                        <Typography 
                            variant="subtitle1"
                            sx={{
                                fontSize: { xs: '18px', sm: '20px', md: '28px' }, // Adjusted font size
                                fontFamily: 'Inter, sans-serif',
                                color:"#111827" ,
                                lineHeight: 1.25, // Adjusted line height
                                }}
                        >
                            Connect Hitch.AI with your business tools to start leveraging the data they hold today. 
                        </Typography>
                        
                    </Box>
                </Box>


                
                <Box
                    flex={isDesktop ? 7 : 6}
                    textAlign="center"
                    height='80%'
                    sx={{
                        display: 'flex-start',
                        justifyContent: 'center',
                    }}
                    >
                       
                    <img
                      src={'/img/IntegrationLogos.svg'}
                      alt="Integration Logos"
                      style={{
                        width: '100%',
                        height: 'auto', // Ensure the aspect ratio is maintained
                        maxHeight: '80%', // Limit the maximum height
                      }}
                    />
                    </Box>
            </Stack>
        </Box>
    );
}

export default Integrations;