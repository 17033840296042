import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, Typography, Button } from '@mui/material';
import { useAdminHubConnections } from '../utils/HubAdminConnectionProvider';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

function Nodes({
  accountId,
  connectionId,
  serviceId,
  nodes
}) {
  const { adminConnection, hitchUser } = useAdminHubConnections();

  const columns = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'version', headerName: 'Version', width: 150 },
    { field: 'replicas', headerName: 'Replicas', width: 100 },
    { field: 'roleList', headerName: 'Roles', width: 300 }, 
    { field: 'accountId', headerName: 'Account', width: 300 }
  ];

  const navigate = useNavigate();

  useEffect(() => {
    if (adminConnection) {
      adminConnection
        .invoke('GetNodes', hitchUser?.userId)
        .then(() => {
          // Nodes fetched successfully
        })
        .catch((error) => console.error('Error fetching nodes:', error));

      return () => {
        // Unsubscribe from other events here
      };
    }
  }, [adminConnection, hitchUser?.userId, accountId, connectionId, serviceId]);

  const gotoNode = (nodeId) => {
    navigate(`/admin/nodes/${nodeId}`);
  };

  const createNewNode = () => {
    const newNodeId = uuidv4();
    navigate(`/admin/nodes/${newNodeId}`);
  };

  return (
    <Grid container spacing={2} mt={3}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" gutterBottom>
              Nodes
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={createNewNode}>
              New
            </Button>
          </Grid>
        </Grid>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={nodes}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            onRowClick={(params) => gotoNode(params.id)}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default Nodes;