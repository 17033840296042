import { faExpand } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import AccountSearchDetail from "./AccountSearchDetail";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons";

function AccountSearch({ account, accountSearchResults, expanded, handleChange, hubConnection, sessionId, setTheAccount }) {
    return (
        <Accordion
            expanded={expanded === 'accountPanel'}
            onChange={handleChange('accountPanel')}>
            <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faExpand} />}
                area-controls="panelh-account"
                id="panelh-account"
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Account Selection
                </Typography>
                <Typography sx={{ color: 'text.primary' }}>
                    {account === null ? 'No Account Selected' : (
                        <>
                            {account.name} <FontAwesomeIcon icon={faTrash} onClick={(e) => setTheAccount(null)} />
                        </>
                    )}
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    backgroundColor: "#EEEEFF",
                    marginTop: "0px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    color: "#333333"
                }}>
                <Typography>
                    <AccountSearchDetail
                        accountSearchResults={accountSearchResults}
                        hubConnection={hubConnection}
                        sessionId={sessionId}
                        setTheAccount={setTheAccount} />
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}

export default AccountSearch;