import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AdminGrid from "../AdminGrid";
import { HubConnectionBuilder } from "@microsoft/signalr";

function UserRoles({ hubConnection, sessionId, activeUser }) {
    const [list, setList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [editingModel, setEditingModel] = useState(null);

    const columns = [
        { field: "id", headerName: "ID", width: 300 },
        { field: "name", headerName: "UserRole Name", width: 150, editable: true }
    ];

    useEffect(() => {
        if (!hubConnection) return;

        const subscribeToEvents = () => {
            hubConnection.on("UserRoleList", (data) => {
                setLoading(true);
                // ("UserRoleList received:", data);
                setList(data);
            });

            hubConnection.on("UserRole", (data) => {
                setEditingModel(data);
            });

            // Add other event subscriptions here
        };

        subscribeToEvents();

        hubConnection
            .invoke("GetUserRoleList", sessionId, activeUser.id, searchTerm)
            .then(() => {
                setLoading(true);
            })
            .catch((error) => console.error("Error sending message:", error));

        return () => {
            hubConnection.off("UserRoleList");
            hubConnection.off("UserRole");
            // Unsubscribe from other events here
        };
    }, [hubConnection, sessionId, activeUser.id, searchTerm]);

    const filter = () => {
        if (!hubConnection) return;

        hubConnection
            .invoke("GetUserRoleList", sessionId, activeUser, searchTerm)
            .then(() => {
                setLoading(true);
            })
            .catch((error) => console.error("Error sending message:", error));
    };

    const handleRowClick = (params) => {
        setRowSelectionModel([params.id]);
        // setActiveOrganization(params.row);
    };

    return (
        <Box sx={{ height: "65vh", marginLeft: "40px", marginRight: "40px" }}>
            <Grid container spacing={2}>
                <AdminGrid
                    columns={columns}
                    editingModel={editingModel}
                    filter={filter}
                    handleRowClick={handleRowClick}
                    hubConnection={hubConnection}
                    list={list}
                    rowSelectionModel={rowSelectionModel}
                    searchTerm={searchTerm}
                    sessionId={sessionId}
                    setEditingModel={setEditingModel}
                    setSearchTerm={setSearchTerm}
                    title={"User Role"}
                />
            </Grid>
        </Box>
    );
}

export default UserRoles;