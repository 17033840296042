import React from 'react';
import { Grid, Typography, TextField, Box, FormControlLabel, Checkbox, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import BotOrgChartStyles from './BotOrgChartStyles';

function BotBasics({
    bot,
    allowAnonymous,
    enabled, // New field for 'enabled'
    editData,
    editMode,
    expandedSection,
    handleAllowAnonymousChange,
    handleEnabledChange, // New handler for 'enabled'
    handleDeployCodeChange,
    handleChange,
    setExpandedSection
}) {
    const {
        themePrimaryTextColor,
        themeSecondaryBackgroundColor,
        themeSecondaryTextColor,
        formFields
    } = BotOrgChartStyles();

    return (
        <Accordion id="section1" elevation={0} expanded={expandedSection.section1} onChange={() => setExpandedSection({ ...expandedSection, section1: !expandedSection.section1 })}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: themeSecondaryTextColor }} />} sx={{ backgroundColor: themeSecondaryBackgroundColor }}>
                <Typography variant="h6" sx={{ color: themeSecondaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Bot Basics</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: themeSecondaryBackgroundColor }}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {editMode ? (
                                <TextField
                                    fullWidth
                                    name="name"
                                    label="Name"
                                    variant="outlined"
                                    value={editData.name ?? ''}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        style: {
                                            color: themePrimaryTextColor,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: '14px',
                                            fontFamily: '"Poppins", sans-serif',
                                        },
                                    }}
                                    InputProps={{
                                        style: {
                                            color: themeSecondaryTextColor,
                                            fontSize: '16px',
                                            fontFamily: '"Poppins", sans-serif',
                                        },
                                        sx: {
                                            background: themeSecondaryBackgroundColor,
                                            height: '40px',
                                            color: themeSecondaryTextColor,
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                ...formFields.botName.outlineStyle,
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                ...formFields.botName.outlineStyleActive,
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                ...formFields.botName.outlineStyleActive
                                            },
                                        }
                                    }}
                                    sx={{ marginBottom: '16px' }}
                                    required
                                    error={!!formFields.botName.error}
                                    helperText={formFields.botName.error}
                                    autoComplete="off"
                                />
                            ) : (
                                <>
                                    <Typography sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Name:</Typography>
                                    <Typography sx={{ color: themeSecondaryTextColor, fontFamily: '"Poppins", sans-serif', marginLeft: '8px', fontWeight: '300' }}>{bot.name}</Typography>
                                </>
                            )}
                        </Grid>

                        {/* New Grid item for Allow Anonymous */}
                        <Grid item xs={12} md={6}>
                            {editMode ? (
                                <>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={allowAnonymous}
                                                onChange={handleAllowAnonymousChange}
                                                name="allowAnonymous"
                                                color="primary"
                                                sx={{
                                                    color: themePrimaryTextColor,
                                                    '&.Mui-checked': { color: themePrimaryTextColor },
                                                }}
                                            />
                                        }
                                        label="Allow Anonymous"
                                        sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}
                                    />
                                    {allowAnonymous && (
                                        <TextField
                                            fullWidth
                                            name="deployCode"
                                            label="Deploy Code"
                                            variant="outlined"
                                            value={editData.deployCode ?? ''}
                                            onChange={handleDeployCodeChange}
                                            InputLabelProps={{
                                                style: {
                                                    color: themePrimaryTextColor,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '14px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    color: themeSecondaryTextColor,
                                                    fontSize: '16px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                },
                                                sx: {
                                                    background: themeSecondaryBackgroundColor,
                                                    height: '40px',
                                                    color: themeSecondaryTextColor,
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        ...formFields.botName.outlineStyle,
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        ...formFields.botName.outlineStyleActive,
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        ...formFields.botName.outlineStyleActive
                                                    },
                                                }
                                            }}
                                            sx={{ marginBottom: '16px' }}
                                            autoComplete="off"
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={allowAnonymous}
                                                name="allowAnonymous"
                                                color="primary"
                                                disabled={!editMode}
                                                sx={{
                                                    color: themePrimaryTextColor,
                                                    '&.Mui-checked': { color: themePrimaryTextColor },
                                                    '&.Mui-disabled': { color: themePrimaryTextColor },
                                                }}
                                            />
                                        }
                                        label={<span style={{ color: themePrimaryTextColor }}>Allow Anonymous</span>}
                                        sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}
                                    />
                                    {allowAnonymous && (
                                        <Typography sx={{ color: themeSecondaryTextColor, fontFamily: '"Poppins", sans-serif', marginTop: '8px' }}>
                                            Deploy Code: {bot.deployCode ?? 'N/A'}
                                        </Typography>
                                    )}
                                </>
                            )}
                        </Grid>

                        {/* New Grid item for Enabled */}
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={enabled}
                                        onChange={handleEnabledChange}
                                        name="enabled"
                                        color="primary"
                                        sx={{
                                            color: themePrimaryTextColor,
                                            '&.Mui-checked': { color: themePrimaryTextColor },
                                        }}
                                    />
                                }
                                label="Enabled"
                                sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {editMode ? (
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    inputProps={{ maxLength: 512 }}
                                    value={editData.description ?? ''}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        style: {
                                            color: themePrimaryTextColor,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: '14px',
                                            fontFamily: '"Poppins", sans-serif',
                                        },
                                    }}
                                    InputProps={{
                                        style: {
                                            color: themePrimaryTextColor,
                                            fontSize: '16px',
                                            fontFamily: '"Poppins", sans-serif',
                                        },
                                        sx: {
                                            background: themeSecondaryBackgroundColor,
                                            color: themePrimaryTextColor,
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                ...formFields.botName.outlineStyle,
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                ...formFields.botName.outlineStyleActive,
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                ...formFields.botName.outlineStyleActive
                                            },
                                        }
                                    }}
                                    sx={{ marginBottom: '8px' }}
                                    autoComplete="off"
                                />
                            ) : (
                                <>
                                    <Typography sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Description:</Typography>
                                    <Typography sx={{ color: themeSecondaryTextColor, fontFamily: '"Poppins", sans-serif', fontWeight: '300' }}>{bot.description}</Typography>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export default BotBasics;
