import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Avatar, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

const Sidebar = ({ eventOptions }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [mobileOpen, setMobileOpen] = useState(true);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // Sort eventOptions by name before rendering
    const sortedEventOptions = eventOptions.sort((a, b) => {
        // Assuming 'name' is a string. Adjust toLowerCase() if you want case-insensitive sorting.
        return a.name.localeCompare(b.name);
    });

    const drawer = (
        <div>
            <div className="event-options">
                {sortedEventOptions.map((evt) => {
                    return (
                        <ListItem
                            key={evt.serviceId}
                            disablePadding
                            className="draggable-event"
                            draggable
                            onDragStart={(e) => {
                                e.dataTransfer.setData('application/reactflow', evt.serviceId);
                                e.dataTransfer.effectAllowed = 'move';
                            }}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Avatar
                                        src={`${window._env_.HITCH_API}/api/accimage/${evt.serviceId}.png`}
                                        style={{ cursor: 'select' }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={evt.name} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </div>
        </div>
    );

    return (
        <>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: isMobile ? 'block' : 'none' }}
            >
                <MenuIcon />
            </IconButton>
            <Drawer
                variant={isMobile ? 'temporary' : 'permanent'}
                open={isMobile ? mobileOpen : true}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{ width: isMobile ? 'auto' : 240, flexShrink: 0, '& .MuiDrawer-paper': { width: isMobile ? 'auto' : 240, boxSizing: 'border-box' } }}
            >
                {drawer}
            </Drawer>
        </>
    );
};

export default Sidebar;

