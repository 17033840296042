import React from 'react';

function ConnectionSetupInProgress({ onNext }) {
  return (
    <div>
      <h2>Connection Setup in Progress</h2>
      <p>Please complete the connection in the popup window that was opened.</p>
      <button onClick={onNext}>Next</button>
    </div>
  );
}

export default ConnectionSetupInProgress;
