import { Step } from "@mui/material";
import { StepLabel } from "@mui/material";
import { Stepper } from "@mui/material";
import { AppBar, Tab, Tabs } from "@mui/material";
import './Quickbooks.css';
import { Grid } from "@mui/material";

function QbTabBar({ tabValue, setTabValue, a11yProps }) {
    const steps = [
        { name: 'Prompt', value: 0 },
        { name: 'Steps', value: 1 },
        { name: 'Test', value: 2 },
        { name: 'Train', value: 3 }
    ];

    return (
        <Grid container spacing={2}>
            <Grid item xs></Grid>
            <Grid item xs={8}>
                <Stepper activeStep={tabValue}>
                    {steps.map((step) => (
                        <Step key={step.value}>
                            <StepLabel onClick={(e) => setTabValue(step.value)}>{step.name}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
            <Grid item xs></Grid>
        </Grid>
    )
}

export default QbTabBar;
