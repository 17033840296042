import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Paper, Stack, Typography, styled, useMediaQuery, useTheme, useScrollTrigger, Zoom, Fab } from '@mui/material';
import ImageCarousel from '../imagecarousel/ImageCarousel';
import ProductCards from '../productcards/ProductCards';
import './Home.css';
import DemoChat from '../demochat/DemoChat';
import { useState } from 'react';
import Banner from '../banner/Banner';
import Hero from '../hero/Hero';
import LogoClouds from '../logoclouds/LogoClouds';
import AskOnTheGo from '../askonthego/AskOnTheGo';
import Features from '../features/Features';
import Testimonials from '../testimonials/Testimonials';
import Integrations from '../integrations/Integrations';
import CTAs from '../ctas/CTAs';
import FooterMain from '../footer/FooterMain';
import ErrorBoundary from '../../utils/ErrorBoundary';
import NavigationBar from '../naviagationbar/NavigationBar';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ScrollTop = (props) => {
  const { window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <Fab
        onClick={handleClick}
        color="primary"
        size="small"
        aria-label="scroll back to top"
        style={{
          position: 'fixed',
          bottom: '2%',
          right: '50%',
          transform: 'translateX(50%)',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#18181B', // Dark blue with opacity
          opacity: 0.8, // Opacity value
          '&:hover': {
            backgroundColor: 'rgba(21, 101, 192, 0.8)', // Darker blue with opacity on hover
          },
        }}
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </Zoom>
  );
};


function Home() {


  return (
    <Box
      backgroundColor="#000000"
    >
      {/* stack jsx on top of each other */}
      <Stack sx={{ maxWidth: '1584px', margin: '0 auto' }}>
        <header>
          <ErrorBoundary fallback={<p>Something went wrong</p>}>
            <NavigationBar />
          </ErrorBoundary>
        </header>
        <div id="back-to-top-anchor" /> {/* Add an anchor for scrolling to the top */}
        {/* <DemoChat setImages={setImages} /> */}
        {/*<Banner />*/}
        <Hero />
        <Features />
        {/*<Testimonials /> {/* Display the logoclouds component */}
        <Integrations /> {/* Display the logoclouds component */}
        {/*<CTAs /> {/* Display the logoclouds component */}
        <AskOnTheGo />
        <FooterMain />
        <ScrollTop />
      </Stack>
    </Box>
  );
}

export default Home;
