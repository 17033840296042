import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

function Bump() {
    const navigate = useNavigate();

    useEffect(() => {
        // console.log('Redirecting...');
        navigate(`/app/conversation/${v4()}`);
    }, []);

    return (
        <h1>Redirecting...</h1>
    );
}

export default Bump;