import React, { useState, useEffect } from 'react';
import ConnectionWizard from './ConnectionWizard';
import CloseIcon from '@mui/icons-material/Close';
import CancelPrompt from './CancelPrompt'; // Import your CancelPrompt component

function MyModal({ onClose, children }) {
  const [showCancelPrompt, setShowCancelPrompt] = useState(false);

  const handleCancelPromptOpen = () => {
    setShowCancelPrompt(true);
  };

  const handleCancelPromptClose = () => {
    setShowCancelPrompt(false);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1100, // This should be higher than other elements
        overflowY: 'auto',
      }}
    >
      <div
        style={{
          position: 'relative',
          backgroundColor: 'rgba(0, 0, 0, 0.8)', // Slightly transparent black
          padding: '24px',
          borderRadius: '40px',
          boxShadow: '0 0 5px rgba(255, 255, 255, 0.6)',
          width: isMobile ? '95%' : '80%', // Wider on mobile
          maxWidth: '1100px',
          minWidth: '300px',
          maxHeight: '90vh',
          overflowY: 'auto',
          zIndex: 1101,
          margin: isMobile ? '10px auto' : 'auto', // Less margin on mobile
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          {/* Your business name and/or logo */}
          <img src="/path-to-your-logo.png" alt="Your Business Logo" style={{ height: '50px' }} />
          <h1 style={{ color: 'white', margin: '10px 0' }}>Your Business Name</h1>
        </div>
        <button
          onClick={handleCancelPromptOpen}
          style={{
            position: 'absolute',
            top: '10px', // Adjusted for better visibility
            right: '10px', // Adjusted for better visibility
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            zIndex: 1102, // Ensure it's above other modal content
          }}
        >
          <CloseIcon style={{ color: 'white', fontSize: '24px' }} />
        </button>
        {children}
        {showCancelPrompt && (
          <CancelPrompt open={showCancelPrompt} onClose={handleCancelPromptClose} />
        )}
      </div>
    </div>
  );
}

function OpenConnectionWizard() {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {isModalOpen && (
        <MyModal onClose={closeModal}>
          <ConnectionWizard />
        </MyModal>
      )}
    </div>
  );
}

export default OpenConnectionWizard;
