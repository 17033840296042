import { DataGrid, GridFooterContainer, useGridApiContext, useGridSelector, GridPagination, gridPageCountSelector, gridPageSelector, } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import './HitchTable.css';
import { IconButton, Tooltip } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { CSVLink } from 'react-csv';

function processPayload(payload) {
  const allKeys = new Set();
  payload.forEach(item => {
    Object.keys(item).forEach(key => {
      if (item[key] != null) {
        allKeys.add(key);
      }
    });
  });

  const columns = Array.from(allKeys).map(key => ({
    field: key,
    headerName: key.replace(/([A-Z])/g, ' $1').trim()
  }));

  const rows = payload.map((item, index) => {
    const row = { id: index };
    allKeys.forEach(key => {
      row[key] = item[key] || '';
    });
    return row;
  });

  return { columns, rows };
}

export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* Move the GridPagination to the left */}

      <GridPagination />

    </div>
  );
}

function CustomFooter() {
  return (
    <GridFooterContainer>
      <CustomPagination />
    </GridFooterContainer>
  );
}

function HitchTable({ object }) {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    if (object.length > 0) {
      const newColumns = Object.keys(object[0]).map((key) => ({
        field: key,
        headerName: key, // You can customize the header name if needed
        width: 150, // You can customize the width as per your requirements
        editable: true, // If you want columns to be editable
      }));

      setColumns(newColumns);
    }
    else {
      setColumns([]);
    }
    setRows(object);

  }, [object]);


  return (

    <div
      style={{
        position: 'relative',
        maxWidth: '100%',
        border: '1px solid #00EDED', // Add border to this container
        borderRadius: '5px',
        overflowX: 'auto', // Enable horizontal scrollbar
        whiteSpace: 'nowrap', // Prevent text wrapping
        margin: '8px 8px', // Add 8 pixels of margin above and below
        // Relative positioning to contain the copy icon
        position: 'relative',
      }}
    >
      <div
        style={{
          // Header for the code container
          background: '#00EDED',
          color: 'black',
          padding: '4px',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          fontFamily: 'Poppins, sans-serif', // Apply the Poppins font to the header
          fontSize: '12px',
        }}
      >
        <br></br>
      </div>
      <pre
        style={{
          margin: '0',
          marginTop: '10px',
          marginLeft: '14px',
          marginBottom: '12px',
          lineHeight: '1.2',
        }}
      >
        {/* Wrap the code section in a code container */}
        <code style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px' }}>

          <DataGrid
            rows={object.rows || rows}
            columns={object.columns || columns}
            getRowId={(row) => {
              if (row.id) {
                return row.id; 
              }
              if(row.Id) {
                return row.Id;
              }
              if(row.accountId) {
                return row.accountId;
              }
            }}
            className="hitchgrid" // Apply custom styles to the DataGrid
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            slots={{
              footer: CustomFooter,
            }}
          />
        </code>
      </pre>
      <div
        style={{
          position: 'absolute', // Absolute positioning to fix the copy icon
          bottom: '0',
          right: '0',
          background: 'transparent',
          padding: '22px',
          borderRadius: '0 0 5px 0', // Adjust as needed
        }}
      >
        <CSVLink data={csvData} filename={'data.csv'} style={{ marginLeft: '10px' }}>
          <Tooltip title="Download" placement="bottom">
            <IconButton
              sx={{
                '& svg': {
                  fontSize: 20,
                }
              }}>
              <SaveAltIcon
                sx={{
                  color: 'white',
                  '&:hover': {
                    color: '#00EDED',
                  },
                }} />
            </IconButton>
          </Tooltip>
        </CSVLink>
      </div>
    </div>

  );
};
export default HitchTable;