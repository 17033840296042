import * as React from 'react';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';

export default function LinearColor({requestCancel}) {
  return (
    <Grid container>
      {/* <Grid xs="3">
        <CircularProgress />
      </Grid>
      <Grid xs="6">
        <Typography>Hitchy is working</Typography>
      </Grid>
      <Grid xs="3">
        <Button onClick={requestCancel()}>Cancel This Run</Button>
      </Grid> */}
    </Grid>
    
  );
}