import React from 'react';
import { Grid, Typography, TextField, Box, FormControlLabel, Checkbox, MenuItem, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import BotOrgChartStyles from './BotOrgChartStyles';

function BotConfiguration({
    bot,
    editData,
    editMode,
    expandedSection,
    formFields,
    handleChange,
    handleJsonModeChange,
    jsonMode,
    models,
    setExpandedSection
}) {

    const {
        themePrimaryTextColor,
        themeSecondaryBackgroundColor,
        themeSecondaryTextColor,
        fixedWidthStyle
    } = BotOrgChartStyles();

    return (
        <Accordion id="section2" elevation={0} expanded={expandedSection.section2} onChange={() => setExpandedSection({ ...expandedSection, section2: !expandedSection.section2 })}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />} sx={{ backgroundColor: themeSecondaryBackgroundColor }}>
                <Typography variant="h6" sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Bot Configuration</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: themeSecondaryBackgroundColor }}>
                <Box>
                    <Grid container spacing={2}>
                        {editMode ? (
                            <>
                                // InitialWelcomeMessage
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        minRows={4}
                                        maxRows={12}
                                        name="initialWelcomeMessage"
                                        label="Initial Welcome Message"
                                        variant="outlined"
                                        inputProps={{ maxLength: 256000 }}
                                        value={editData.initialWelcomeMessage ?? ''}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            style: {
                                                color: themePrimaryTextColor,
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontSize: '14px',
                                                fontFamily: '"Poppins", sans-serif',
                                            },
                                        }}
                                        InputProps={{
                                            style: {
                                                color: themePrimaryTextColor,
                                                fontSize: '16px',
                                                fontFamily: '"Poppins", sans-serif',
                                            },
                                            sx: {
                                                background: themeSecondaryBackgroundColor,
                                                color: themePrimaryTextColor,
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    ...formFields.botSpecialInstructions.outlineStyle,
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    ...formFields.botSpecialInstructions.outlineStyleActive,
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    ...formFields.botSpecialInstructions.outlineStyleActive
                                                },
                                            }
                                        }}
                                        sx={{ marginBottom: '16px' }}
                                        required
                                        error={!!formFields.initialWelcomeMessage.error}
                                        helperText={formFields.initialWelcomeMessage.error}
                                        autoComplete="off"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        minRows={4}
                                        maxRows={12}
                                        name="standardInstructions"
                                        label="Standard Instructions"
                                        variant="outlined"
                                        inputProps={{ maxLength: 256000 }}
                                        value={editData.standardInstructions ?? ''}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            style: {
                                                color: themePrimaryTextColor,
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontSize: '14px',
                                                fontFamily: '"Poppins", sans-serif',
                                            },
                                        }}
                                        InputProps={{
                                            style: {
                                                color: themePrimaryTextColor,
                                                fontSize: '16px',
                                                fontFamily: '"Poppins", sans-serif',
                                            },
                                            sx: {
                                                background: themeSecondaryBackgroundColor,
                                                color: themePrimaryTextColor,
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    ...formFields.botSpecialInstructions.outlineStyle,
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    ...formFields.botSpecialInstructions.outlineStyleActive,
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    ...formFields.botSpecialInstructions.outlineStyleActive
                                                },
                                            }
                                        }}
                                        sx={{ marginBottom: '16px' }}
                                        required
                                        error={!!formFields.botSpecialInstructions.error}
                                        helperText={formFields.botSpecialInstructions.error}
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        name="assistantId"
                                        label="Assistant ID"
                                        variant="outlined"
                                        value={editData.assistantId ?? ''}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            style: {
                                                color: themePrimaryTextColor,
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontSize: '14px',
                                                fontFamily: '"Poppins", sans-serif',
                                            },
                                        }}
                                        InputProps={{
                                            style: {
                                                color: themePrimaryTextColor,
                                                fontSize: '16px',
                                                fontFamily: '"Poppins", sans-serif',
                                                ...fixedWidthStyle,
                                            },
                                            sx: {
                                                background: themeSecondaryBackgroundColor,
                                                height: '40px',
                                                color: themePrimaryTextColor,
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    ...formFields.assistantId.outlineStyle,
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    ...formFields.assistantId.outlineStyleActive,
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    ...formFields.assistantId.outlineStyleActive
                                                }
                                            }
                                        }}
                                        sx={{ marginBottom: '0px', paddingBottom: '0px' }}
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        name="model"
                                        label="Model"
                                        variant="outlined"
                                        select
                                        fullWidth
                                        value={editData.model ?? ''}
                                        style={{ textAlign: 'left' }}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            style: {
                                                color: themePrimaryTextColor,
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontSize: '16px',
                                                fontFamily: '"Poppins", sans-serif',
                                            },
                                        }}
                                        InputProps={{
                                            style: {
                                                color: themePrimaryTextColor,
                                                fontSize: '16px',
                                                fontFamily: '"Poppins", sans-serif',
                                                ...fixedWidthStyle,
                                            },
                                            sx: {
                                                background: themeSecondaryBackgroundColor,
                                                height: '40px',
                                                color: themePrimaryTextColor,
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    ...formFields.botModel.outlineStyle,
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    ...formFields.botModel.outlineStyleActive,
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    ...formFields.botModel.outlineStyleActive,
                                                },
                                                '.MuiSvgIcon-root ': {
                                                    fill: "rgba(255, 255, 255, 0.7) !important",
                                                }
                                            },
                                        }}
                                        sx={{ marginBottom: '0px', paddingBottom: '0px' }}
                                        helperText={' '}
                                        FormHelperTextProps={{ style: { fontSize: '0.50rem', lineHeight: 'normal', fontFamily: 'Poppins, sans-serif' } }}
                                        autoComplete="off"
                                    >
                                        <MenuItem value=""><em>--Select--</em></MenuItem>
                                        {models.length > 0 ? (
                                            models.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.id}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem value="" disabled>Loading...</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={jsonMode}
                                                onChange={handleJsonModeChange}
                                                name="jsonMode"
                                                color="primary"
                                                sx={{
                                                    color: themePrimaryTextColor,
                                                    '&.Mui-checked': { color: themePrimaryTextColor },
                                                }}
                                            />
                                        }
                                        label="Enable JSON mode"
                                        sx={{
                                            color: themePrimaryTextColor,
                                            marginTop: '-16px',
                                            marginBottom: '-16px',
                                            fontFamily: '"Poppins", sans-serif',
                                        }}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Initial Welcome Message:</Typography>
                                    <Typography sx={{ color: themeSecondaryTextColor, fontFamily: '"Poppins", sans-serif', fontWeight: '300' }}>{bot.initialWelcomeMessage}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Standard Instructions:</Typography>
                                    <Typography sx={{ color: themeSecondaryTextColor, fontFamily: '"Poppins", sans-serif', fontWeight: '300' }}>{bot.standardInstructions}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Assistant ID:</Typography>
                                    <Typography sx={{ color: themeSecondaryTextColor, fontFamily: '"Poppins", sans-serif', fontWeight: '300' }}>{bot.openAiAssistantId}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Model:</Typography>
                                    <Typography sx={{ color: themeSecondaryTextColor, fontFamily: '"Poppins", sans-serif', fontWeight: '300' }}>{bot.model}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={jsonMode}
                                                onChange={handleJsonModeChange}
                                                name="jsonMode"
                                                color="primary"
                                                disabled={!editMode}
                                                sx={{
                                                    color: themePrimaryTextColor,
                                                    '&.Mui-checked': { color: themePrimaryTextColor },
                                                    '&.Mui-disabled': { color: themePrimaryTextColor },
                                                }}
                                            />
                                        }
                                        label={<span style={{ color: themePrimaryTextColor }}>Enable JSON mode</span>}
                                        sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export default BotConfiguration;