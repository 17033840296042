import React, { useRef, useEffect, useState } from 'react';
import {
  FormControl,
  OutlinedInput,
  IconButton,
  InputAdornment,
  useTheme,
  Tooltip, 
  Paper
} from '@mui/material';
import { Send, Stop } from '@mui/icons-material';

function ChatBoxV2({ 
  newMessage, 
  handleSendMessage, 
  setNewMessage, 
  isChatBoxActive, 
  hitchUser,
  leftConversationDrawerOpen,
  isMobile,
  isInChatDrawer, // New prop to indicate if the ChatBox is in the chat drawer
  drawerHeight, // New prop for the height of the chat drawer (could also be overrideHeight)
  maxWidth // Default maxWidth, can also be passed as a prop if needed
}) {
  const theme = useTheme(); // Access the theme object
  const themeBackgroundColor = theme.palette.primary[theme.palette.mode];
  const themeBackgroundTextColor = theme.palette.primaryText[theme.palette.mode];
  const themePrimaryButtonColor = theme.palette.primaryButton[theme.palette.mode];
  const themePrimaryBorderColor = theme.palette.primaryBorder[theme.palette.mode];
  const themeIconDisabledColor = theme.palette.iconDisabled[theme.palette.mode];
  const themeIconActiveColor = theme.palette.iconActive[theme.palette.mode];
  const themeStopButtonColor = theme.palette.stopButton[theme.palette.mode];
  const themeStopButtonActiveColor = theme.palette.stopButtonActive[theme.palette.mode];
  const themeTextboxOutline = theme.palette.textboxOutline[theme.palette.mode];

  const inputRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMessageEmpty = !newMessage.content;
  const parsedMaxWidth = typeof maxWidth === 'string' ? parseInt(maxWidth, 10) : NaN;
  
  const maxWidthValue = parseInt(maxWidth, 10); // '1584px' becomes 1584

  // Dynamically adjust the bottom position based on whether it's in the chat drawer
  const chatBoxBottom = isInChatDrawer ? `calc(100vh - ${drawerHeight} - 4vh)` : '56px';

  let calculatedLeft, calculatedRight;
  if (isInChatDrawer && windowWidth > maxWidthValue) {
    const excessWidth = windowWidth - maxWidthValue + 56; // Adjust for 4px margin on both sides
    calculatedLeft = calculatedRight = `${(excessWidth / 2)}px`; // Center within maxWidth and subtract 4px
  } else {
    calculatedLeft = isMobile ? '8px' : '32px';
    calculatedRight = isMobile ? '8px' : '32px';
  }

  // Calculate the ChatBox style similar to contentShiftStyle
  const chatBoxStyle = {
    position: 'fixed',
    bottom: chatBoxBottom,  //todo: make dynamic for chatdrawer and chat conversation
    backgroundColor: themeBackgroundColor, 
    padding: '0px 12px',
    borderRadius: '10px',
    border: `1px solid ${themePrimaryBorderColor}`,
    width: 'auto',
    maxWidth: 'maxWidth', // No need to parse this for CSS
    left: `calc(${calculatedLeft} + 24px)`, // Shift right by 24px
    right: `calc(${calculatedRight} + 24px)`, // Shift right by 24px
    zIndex: 1000,
    '&:hover, &:focus-within': {
      borderColor: theme.palette.textboxOutline.hover[theme.palette.mode],
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSendMessage(newMessage); // Pass the entire object or extract the required parts
    //console.log('ChatBox:handleSubmit:', newMessage);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      setNewMessage(prevMessage => ({
        ...prevMessage,
        content: prevMessage.content + '\n'
      }));
    } else if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!isMessageEmpty) {
        handleSendMessage(newMessage);
      }
    }
  };
  
  // Use useEffect to focus the input when isChatBoxActive becomes true
  useEffect(() => {
    if (isChatBoxActive && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isChatBoxActive]);

  return (
    <Paper sx={chatBoxStyle}>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <FormControl fullWidth={true}>
          <OutlinedInput
            placeholder="How can you help me with my business?"
            style={{ 
              width: '100%', 
              color: themeBackgroundTextColor,
              fontFamily: '"Poppins", sans-serif', // Apply the Poppins font family
              padding: '16px 12px', // Adjust the padding (top, right, bottom, left)
            }}
            id="chat-box"
            autoFocus={isChatBoxActive} // Use autoFocus conditionally
            multiline={true}
            maxRows={2}
            value={newMessage.content} // Change value to newMessage.content
            onChange={(e) => {
              const newMessageObject = {
                content: e.target.value,
                contentType: 'string',
                user: hitchUser?.id
              };
              setNewMessage(newMessageObject);
            }}
            onKeyDown={handleKeyDown} // Handle Enter and Shift + Enter key press
            endAdornment={
              <InputAdornment position='end'>
                <Tooltip title="Submit prompt">
                  <IconButton
                    type="submit"
                    sx={{
                      color: isMessageEmpty ? themeIconDisabledColor : themePrimaryButtonColor, // Apply the dynamic color
                      '&:hover': {
                        backgroundColor: isMessageEmpty ? 'transparent' : 'rgba(114, 216, 197, 0.1)',
                        color: isMessageEmpty ? themeIconDisabledColor : themePrimaryButtonColor,
                      },
                      '&:active': {
                        backgroundColor: isMessageEmpty ? 'transparent' : 'rgba(114, 216, 197, 0.2)',
                        color: isMessageEmpty ? themeIconDisabledColor : themeIconActiveColor,
                      },
                      '&.Mui-disabled': {
                        color: themeIconDisabledColor,
                      },
                    }}
                    disabled={isMessageEmpty} // Disable the button when the message is empty
                  >
                    <Send />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Stop action">
                  <IconButton
                    sx={{
                      color: themeIconDisabledColor, // Adjust color as needed
                      '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.1)',
                        color: themeStopButtonColor, // Adjust hover color as needed
                      },
                      '&:active': {
                        backgroundColor: 'rgba(255, 0, 0, 0.2)',
                        color: themeStopButtonActiveColor, // Adjust active color as needed
                      },
                    }}
                    onClick={() => {
                      // console.log("Stop action triggered");
                      // Add your stop action logic here
                    }}
                  >
                    <Stop />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            }
            inputRef={inputRef} // Attach the ref to the input element
            sx={{
              background: themeBackgroundColor,
              color: themeBackgroundTextColor,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Remove the border
              },
              '& textarea': {
                resize: 'vertical-reverse',
              },
            }}
          />
        </FormControl>
      </form>
    </Paper>
  );
}

export default ChatBoxV2;
