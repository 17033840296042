import React, { useEffect, useState } from 'react';
import { Grid, Typography, TextField, Button, Link, CircularProgress, Box } from '@mui/material';
import { useQualifierChat } from './QualifierChatProvider';
import { useTheme } from '@mui/material/styles';

const AccessCodePage = () => {
  const [accessCode, setAccessCode] = useState('');
  const [codeSubmitted, setCodeSubmitted] = useState(false);
  const [checkingCode, setCheckingCode] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);

  const theme = useTheme(); // Access the theme to use in the media query
  const themePrimaryTextColor = theme.palette.primaryText[theme.palette.mode];
  const themeTextboxOutlineHoverColor = '#00EDED'; // Use the desired color directly
  const themePrimaryButtonColor = theme.palette.primaryButton[theme.palette.mode];
  const primaryButtonTextColor = theme.palette.primaryButtonText[theme.palette.mode];
  const instructionTextColor = '#B0B0B0'; // Light gray color for instructions
  const { qualifierHub, hitchUser } = useQualifierChat();

  const handleSubmit = () => {
    console.log('Access Code Submitted:', accessCode);
    setCheckingCode(true);
    submitIt();

    const timeoutId = setTimeout(() => {
      if (checkingCode) {
        setAccessDenied(true);
        setCheckingCode(false);
      }
    }, 5000);

    return () => clearTimeout(timeoutId);
  };

  const handleResend = () => {
    console.log('Resend code');
    // Add logic to resend the code
  };

  useEffect(() => {
    if (qualifierHub) {


      const handleDenied = () => {
        console.log("Access denied");
        setAccessDenied(true);
        setCheckingCode(false);
      };

      qualifierHub.on("Denied", handleDenied);

      return () => {
        qualifierHub.off("Denied", handleDenied);
      };
    }
  }, [qualifierHub]);

  function submitIt() {
    if (qualifierHub && hitchUser) {
      qualifierHub.invoke("AccessCode", hitchUser.clickFunnelsContactId, accessCode)
        .then(() => {
          // Do nothing here, wait for the response handlers to process
        })
        .catch(error => {
          console.error("AccessCode error:", error);
          setCheckingCode(false);
        });
    }
  }

  const fixedWidthStyle = {
    width: '150px', // Adjust the width as needed
  };

  const buttonStyle = {
    backgroundColor: themePrimaryButtonColor,
    color: primaryButtonTextColor,
    height: '40px',
    padding: '4px 20px',
    fontSize: '0.80rem',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontFamily: '"Poppins", sans-serif',
    '&:hover': {
      backgroundColor: themePrimaryButtonColor,
    },
    '&:active': {
      backgroundColor: themePrimaryButtonColor,
    },
    '&.Mui-disabled': {
      backgroundColor: themePrimaryButtonColor,
      color: primaryButtonTextColor,
    },
  };

  if (checkingCode) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '100vh' }}
      >
        <Box textAlign="center">
          <CircularProgress sx={{ color: '#00EDED' }} />
          <Typography variant="h4" mt={2}>
            Checking your code
          </Typography>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      {!codeSubmitted ? (
        <>
          <Grid item xs={12} mt={6}>
            <Typography variant="h5" align="center">
              Enter Your Access Code
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2} container justifyContent="center">
            <Grid item style={{ maxWidth: '300px' }}>
              <Typography variant="body1" align="center" sx={{ color: instructionTextColor }}>
                We've sent an email to your inbox. Check it out for your verification code and enter it below.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2} container justifyContent="center">
            <Grid item>
              <TextField
                variant="outlined"
                value={accessCode}
                onChange={(e) => setAccessCode(e.target.value)}
                autoComplete="off"
                InputProps={{
                  sx: {
                    color: themePrimaryTextColor,
                    fontSize: '16px',
                    fontFamily: '"Poppins", sans-serif',
                    textAlign: 'center',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: themeTextboxOutlineHoverColor,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: themeTextboxOutlineHoverColor,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: themeTextboxOutlineHoverColor,
                    },
                  },
                }}
                inputProps={{
                  maxLength: 6,
                  style: {
                    textAlign: 'center',
                  },
                }}
                style={fixedWidthStyle}
                error={accessDenied}
                helperText={accessDenied ? "Sorry, that is incorrect." : ""}
                FormHelperTextProps={{
                  sx: {
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                  },
                }}
              />

            </Grid>
          </Grid>
          <Grid item xs={12} mt={2} container justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                sx={buttonStyle}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={1} container justifyContent="center">
            <Grid item>
              <Link
                href="#"
                onClick={handleResend}
                sx={{
                  fontFamily: '"Poppins", sans-serif',
                  fontSize: '0.80rem',
                  color: '#00EDED',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Resend Code
              </Link>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item xs={12} mt={6}>
          <Typography variant="h5" align="center">
            Access Granted
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default AccessCodePage;
