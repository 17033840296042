import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Tabs, Tab, Grid, Box, Paper } from '@mui/material';
import AiConversationAudit from './AiConversationAudit';
import AiConversationMainTab from './AiConversationMainTab';
import AiMessageList from './AiMessageList';
import { useQualifierConnections } from '../../utils/QualifierConnectionProvider';

function AiConversationTabs({
  // hitchUser,
  conversation,
  guid,
  messages,
  // users,
  chatContainerRef,
  isHelpful,
  isNotHelpful,
  saveEditedMessage,
  setEditingMessage,
  setEditedMessageText,
  editedMessageText,
  editingMessage,
  overrideHeight,
  isTabletMobile,
  isMobile,
  isTablet,
  isInChatDrawer,
  chatBoxRef,
  theme
}) {
  const [selectedConvTab, setSelectedConvTab] = useState(0);
  const [dynamicTabs, setDynamicTabs] = useState([]);
  const [chatContainerHeight, setChatContainerHeight] = useState('87vh');
  const { isTyping } = useQualifierConnections();

  // const theme = useTheme();
  const themeDividerColor = theme.palette.secondaryDivider[theme.palette.mode];
  const themeBackgroundColor = theme.palette.secondary[theme.palette.mode];
  const menuIconColor = theme.palette.secondaryText[theme.palette.mode];
  console.log('theme.palette.mode', theme.palette.mode);
  const handleTabChange = (event, newValue) => {
    setSelectedConvTab(newValue);
  };

  useEffect(() => {
    function updateChatContainerHeight() {
      let newHeight;

      if (chatBoxRef.current) {
        const chatBoxTop = chatBoxRef.current.getBoundingClientRect().top;
        const containerTop = chatContainerRef.current.getBoundingClientRect().top;
        newHeight = `${chatBoxTop - containerTop - 16}px`;
      } else {
        newHeight = '70vh';
      }

      setChatContainerHeight(newHeight);
    }

    updateChatContainerHeight();
    window.addEventListener('resize', updateChatContainerHeight);

    return () => {
      window.removeEventListener('resize', updateChatContainerHeight);
    };
  }, [chatBoxRef, chatContainerRef, isMobile, isTablet, isInChatDrawer, overrideHeight]);

  const wrapperStyle = {
    margin: isTabletMobile ? '0' : 'auto',
    maxWidth: '100%',
    minWidth: '100%',
    padding: isTabletMobile ? '0' : '0',
    backgroundColor: themeBackgroundColor,
    fontFamily: '"Poppins", sans-serif',
  };

  const createNewTab = () => {
    const timestamp = new Date().getTime();
    const newTab = {
      title: `${timestamp}`,
      content: `This is a new test: ${timestamp}`,
    };
    setDynamicTabs([...dynamicTabs, newTab]);
  };

  const createNewTabAltD = () => {
    const timestamp = new Date().getTime();
    const newTab = {
      title: `Audit Log`,
      content: <AiConversationAudit conversationId={guid} />,
    };
    setDynamicTabs([...dynamicTabs, newTab]);
  };

  const handleCloseTab = (tabIndex) => {
    const newDynamicTabs = dynamicTabs.filter((_, index) => index !== tabIndex);
    setDynamicTabs(newDynamicTabs);
    if (selectedConvTab >= tabIndex && selectedConvTab > 0) {
      setSelectedConvTab(selectedConvTab - 1);
    }
  };

  return (
    <Box sx={wrapperStyle}>
      <div role="tabpanel" hidden={selectedConvTab !== 0}>
        <Grid item xs={12}>
          <Paper
            style={{
              backgroundColor: themeDividerColor,
              height: '1px',
              boxShadow: 'none',
              border: 'none'
            }}>
          </Paper>
        </Grid>
        <Box mt={2} />

        <Grid container spacing={0} item xs={12}>
          <AiMessageList
            messages={messages}
            // user={hitchUser}
            // hitchUser={hitchUser}
            // users={users}
            chatContainerRef={chatContainerRef}
            isHelpful={isHelpful}
            isNotHelpful={isNotHelpful}
            saveEditedMessage={saveEditedMessage}
            createNewTab={createNewTab}
            setEditingMessage={setEditingMessage}
            setEditedMessageText={setEditedMessageText}
            editedMessageText={editedMessageText}
            editingMessage={editingMessage}
            defaultHeight={chatContainerHeight}
            isTabletMobile={isTabletMobile}
            isMobile={isMobile}
            isTablet={isTablet}
            isInChatDrawer={isInChatDrawer}
            isTyping={isTyping} // Pass isTyping to MessageList
            chatContainerHeight={chatContainerHeight}
            theme={theme}
          />
        </Grid>
      </div>

      {dynamicTabs.map((tab, index) => (
        <div key={index} role="tabpanel" hidden={selectedConvTab !== index + 1}>
          <div
            ref={chatContainerRef}
            style={{
              backgroundColor: themeBackgroundColor,
              padding: '0px',
              height: chatContainerHeight,
              overflowY: 'auto',
              color: 'white',
            }}
          >
            <Grid item xs={12}>
              <Paper style={{ backgroundColor: 'black', height: '1px', boxShadow: 'none', border: 'none' }}></Paper>
            </Grid>
            {tab.content}
          </div>
        </div>
      ))}
    </Box>
  );
}

export default AiConversationTabs;
