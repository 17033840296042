import React, { useState } from 'react';
import { Typography, TextField, Button } from '@mui/material';

const ChangePaymentMethodDialog = () => {
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');

  const handleSave = () => {
    // Implement the logic to save the new payment method
    console.log('New Payment Method:', { cardNumber, expiryDate, cvv });
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>Change Payment Method</Typography>
      <TextField
        label="Card Number"
        value={cardNumber}
        onChange={(e) => setCardNumber(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Expiry Date"
        value={expiryDate}
        onChange={(e) => setExpiryDate(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="CVV"
        value={cvv}
        onChange={(e) => setCvv(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        style={{ marginTop: '16px' }}
      >
        Save
      </Button>
    </div>
  );
};

export default ChangePaymentMethodDialog;
