import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
  IconButton, Tabs, Tab, Grid,
  Box, Paper,
} from '@mui/material';
import ConversationMainTabV2 from './ConversationMainTabV2';
import ConversationAuditV2 from './ConversationAudit/ConversationAuditV2'; // Adjust the path as necessary
import MessageListV2 from './MessageListV2';

function ConversationTabsV2({
  hitchUser,
  conversation,
  useChatGpt,
  setUseChatGpt,
  guid,
  messages,
  // users,
  chatContainerRef,
  isHelpful,
  isNotHelpful,
  saveEditedMessage,
  setEditingMessage,
  setEditedMessageText,
  editedMessageText,
  editingMessage,
  overrideHeight,
  isTabletMobile,
  isMobile,
  isTablet,
  setName,
  isInChatDrawer,
  noHeader
}) {
  const [selectedConv2Tab, setSelectedConv2Tab] = useState(0);
  const [dynamicTabs, setDynamicTabs] = useState([]); // State for dynamically created tabs
  const [chatContainerHeight, setChatContainerHeight] = useState('84vh');  //this gets overwritten below

  const theme = useTheme();
  const themeDividerColor = theme.palette.secondaryDivider[theme.palette.mode];
  const themeBackgroundColor = theme.palette.secondary[theme.palette.mode];

  const handleTabChange = (event, newValue) => {
    // console.log('Tab changed to:', newValue);
    setSelectedConv2Tab(newValue);
  };

  useEffect(() => {
    function updateChatContainerHeight() {
      let newHeight;
      if (isInChatDrawer && overrideHeight) {
        // Directly use overrideHeight if isInChatDrawer is true
        newHeight = overrideHeight;
      } else if (isMobile) {
        // If it's a mobile device
        const screenHeightThresholdMobileXsm = 500; // Example threshold for smaller mobile devices
        const screenHeightThresholdMobileSm = 600; // Example threshold for smaller mobile devices
        const screenHeightThresholdMobileMd = 800; // Example threshold for medium mobile devices

        if (window.innerHeight < screenHeightThresholdMobileXsm) {
          // For very small devices
          newHeight = '50vh';
        } else if (window.innerHeight <= screenHeightThresholdMobileSm && window.innerHeight > screenHeightThresholdMobileXsm) {
          // For medium devices, greater than or equal to 600 and less than 800
          newHeight = '58vh';
        } else if (window.innerHeight <= screenHeightThresholdMobileMd && window.innerHeight > screenHeightThresholdMobileSm) {
          // For medium devices, greater than or equal to 600 and less than 800
          newHeight = '64vh';
        } else {
          // For larger devices, greater than or equal to 800
          newHeight = '72vh';
        }
      } else if (isTablet) {
        // If it's a tablet
        newHeight = '77vh';
      } else {
        // For laptops and monitors, adjust based on the window height
        // You might need to adjust this threshold based on your needs
        const screenHeightThreshold = 1200; // Example threshold, adjust as needed
        newHeight = window.innerHeight < screenHeightThreshold ? '74vh' : '81vh';
      }

      // Assuming chatContainerRef.current is the element whose height you want to update
      if (chatContainerRef.current) {
        chatContainerRef.current.style.height = newHeight;
      }
    }

    updateChatContainerHeight();
    window.addEventListener('resize', updateChatContainerHeight);

    return () => {
      window.removeEventListener('resize', updateChatContainerHeight);
    };
  }, [isInChatDrawer, isMobile, isTablet, overrideHeight, chatContainerRef]);


  // Handle creating a new dynamic tab
  const createNewTab = () => {
    const timestamp = new Date().getTime(); // Get the current timestamp
    const newTab = {
      title: `${timestamp}`,
      content: `This is a new test: ${timestamp}`,
    };
    setDynamicTabs([...dynamicTabs, newTab]); // Add the new tab to the state
  };

  // Updated createNewTab function to include MyDataGrid component
  const createNewTabAltD = () => {
    const timestamp = new Date().getTime(); // For unique key and title
    const newTab = {
      title: `Audit Log`,
      content: <ConversationAuditV2 conversationId={guid} />, // Replace with dynamic ID if needed
    };
    setDynamicTabs([...dynamicTabs, newTab]);
    // console.log('Adding new tab, dynamicTabs:', dynamicTabs);
  };

  // Function to close a tab
  const handleCloseTab = (tabIndex) => {
    const newDynamicTabs = dynamicTabs.filter((_, index) => index !== tabIndex);
    setDynamicTabs(newDynamicTabs);
    // Ensure that the selectedConv2Tab index is updated appropriately
    if (selectedConv2Tab >= tabIndex && selectedConv2Tab > 0) {
      setSelectedConv2Tab(selectedConv2Tab - 1);
    }
  };


  const wrapperStyle = {
    margin: '0px' ,
    maxWidth: '100%', 
    minWidth: '100%',
    padding: '0px',
    backgroundColor: themeBackgroundColor, 
    fontFamily: '"Poppins", sans-serif',
  };


  return (
    <Box sx={wrapperStyle}>
      <div role="tabpanel" hidden={selectedConv2Tab !== 0}>
        <Grid container spacing={0} item xs={12} pb={5} mt={2} mb={0} ml={0} mr={0}>
          <MessageListV2
            messages={messages}
            user={hitchUser}
            hitchUser={hitchUser}
            // users={users}
            chatContainerRef={chatContainerRef}
            isHelpful={isHelpful}
            isNotHelpful={isNotHelpful}
            saveEditedMessage={saveEditedMessage}
            createNewTab={createNewTab}
            setEditingMessage={setEditingMessage}
            setEditedMessageText={setEditedMessageText}
            editedMessageText={editedMessageText}
            editingMessage={editingMessage}
            defaultHeight={chatContainerHeight} 
            isTabletMobile={isTabletMobile}
            isMobile={isMobile}
            isTablet={isTablet}
            isInChatDrawer={isInChatDrawer}
          />
        </Grid>
      </div>

      {/* Render the content of the dynamically created tabs */}
      {dynamicTabs.map((tab, index) => (
        <div key={index} role="tabpanel" hidden={selectedConv2Tab !== index + 1}>
          <div
            //ref={chatContainerRef}
            style={{
              backgroundColor: themeBackgroundColor, 
              padding: '0px',
              margin: '0px',
              height: chatContainerHeight,
             // overflowY: 'auto',
              color: 'white',
            }}
          >
            <Grid item xs={12}>
              <Paper style={{ backgroundColor: 'black', height: '1px', boxShadow: 'none', border: 'none' }}></Paper>
            </Grid>
            {tab.content}

          </div>

        </div>
      ))}

    </Box>
  );
}

export default ConversationTabsV2;
