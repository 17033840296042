import React, { createContext, useContext, useRef, useState, useEffect } from 'react';
import { Popover, Box, Avatar, Typography, Grid, Card, CardActionArea, CardMedia, CardContent, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useHubConnections } from '../../utils/HubConnectionsProvider';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


function AppMenuPopover({ accountId, anchorEl, onClose }) {
    const { dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady } = useHubConnections();
    const isOpen = Boolean(anchorEl);
    const navigate = useNavigate();
    const theme = useTheme();
    const themeBackgroundColor = theme.palette.modalBackground[theme.palette.mode];
    const themeSecondaryBackgroundColor = theme.palette.modalSecondaryBackground[theme.palette.mode];
    const themeTextPrimaryColor = theme.palette.modalTextPrimary[theme.palette.mode];
    const themeTextSecondaryColor = theme.palette.modalTextSecondary[theme.palette.mode];
    const themeListItemHovered = theme.palette.listItemBackground.hover[theme.palette.mode];
    const themeListItemSelected = theme.palette.listItemBackground.selected[theme.palette.mode];
    const themeListItemSelectedHovered = theme.palette.listItemBackground.selected_hover[theme.palette.mode];
    const themeListItemDivider = theme.palette.listItemDivider[theme.palette.mode];
    const themeLink = theme.palette.modalLink[theme.palette.mode];

    const handleAppClick = (route) => {
        onClose();
        navigate(route);
    };


  const [apps, setApps] = useState([]);
  const [filteredApps, setFilteredApps] = useState([]);
  const [expandedApp, setExpandedApp] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listItems, setListItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);


  useEffect(() => {
    // if(!dashboardConnection || !isConnectionReady) {
    //   setStartOnce();
    // }
    if (dashboardConnection && isConnectionReady) {
      dashboardConnection.on("ConnectionUpdate", (data) => {
        callForAvailables();
      });

      dashboardConnection.on("7ca387cb-6ff9-4a13-8dff-2fdb954cbf89", (data) => {
        setApps(data);
      });

      dashboardConnection.on("31f2e265-5336-490d-b5f3-b33b85c79dd1", (data) => {
        setListItems(data);
      });

      callForConnections();
      callForAvailables();
    } 

    return () => {
      if (dashboardConnection) {
        dashboardConnection.off("ConnectionUpdate");
        dashboardConnection.off("7ca387cb-6ff9-4a13-8dff-2fdb954cbf89");
        dashboardConnection.off("31f2e265-5336-490d-b5f3-b33b85c79dd1");
      }
    };
  }, [dashboardConnection, isConnectionReady]);

  useEffect(() => {
    if (apps) {
      setFilteredApps(apps.filter(app => app.name.toLowerCase().includes(searchTerm ? searchTerm.toLowerCase() : '')));
    }
  }, [apps, searchTerm]);

  function callForConnections() {
    if (dashboardConnection) {
      hitchDashCommand('account', 'connection', 'list', {
        "theAccountId": accountId
      }, () => {
        // console.log('Message sent successfully');
      });
    }
  }

  function buildServicePackage(serviceId, serviceName) {
    let thePackage = {
      TheServiceId: serviceId,
      ConnectionName: `New ${serviceName}`
    };
    return thePackage;
  }

  function callForAvailables() {
    if (dashboardConnection) {
      hitchDashCommand('account', 'connection', 'available-services', {});
    }
  }

  const contentRef = useRef(null);

useEffect(() => {
    if (contentRef.current) {
        contentRef.current.scrollTop = 0;
    }
}, [isOpen]); // Triggered when the popover opens

  return (
    <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        sx={{
            '.MuiPaper-root': {
                backgroundColor: themeBackgroundColor,
                color: themeTextPrimaryColor,
                maxWidth: ['100vw', '32vw'], // 100% of viewport width on smaller screens, 32vw on larger screens
maxHeight: ['100vh', '80vh'], // Adjust accordingly
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column', // Aligns the title and grid vertically
                alignItems: 'center',
                justifyContent: 'flex-start',
                position: 'relative', // Needed for absolute positioning of the close button
                
            }
        }}
    >
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>

        <Box ref={contentRef} sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: themeBackgroundColor,
            borderColor: 'gray',
            borderStyle: 'solid',
            borderWidth: '1px',
            color: themeTextSecondaryColor,
            padding: 2,
        }}>
            <Typography variant="h6" sx={{ alignSelf: 'flex-start', m: 2 }}>
                My Favorite Apps
            </Typography>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                {apps.map((app, index) => (
                    <Grid item key={index} sx={{
                        width: 128,
                        height: 160,
                    }}>
                        <Box sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: themeSecondaryBackgroundColor,
                            borderRadius: '5px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '&:hover': {
                                backgroundColor: "#303336",
                            },
                        }} onClick={() => navigate(`/app/extensions/${app.connectionId}`)}>
                            <Avatar src={`${window._env_.HITCH_API}/api/accimage/${app.serviceId}.png`} alt={app.name} />
                            <Typography variant="caption" sx={{ mt: 1 }}>
                                {app.name}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    </Popover>
);
}

export default AppMenuPopover;