import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider, DialogActions, Button, Alert } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import NewPlanSummary from './NewPlanSummary';
import ChangePlanSummary from './ChangePlanSummary';

function PaymentAndSummary({ onBack, isFirstStep, onSubscribe, thePlanData }) {
  const darkTheme = createTheme({ palette: { mode: 'dark' } });

  const baseButtonStyle = {
    borderRadius: '24px',
    height: '36px',
    padding: '4px 10px',
    fontSize: '0.75rem',
    fontFamily: 'Poppins, sans-serif',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    width: '144px',
    marginTop: '36px',
    marginBottom: '-8px',
  };

  const primaryButtonStyle = {
    ...baseButtonStyle,
    backgroundColor: '#00EDED',
    color: 'black',
    cursor: 'pointer',
  };

  const secondaryButtonStyle = {
    ...baseButtonStyle,
    backgroundColor: '#000',
    borderColor: 'white',
    color: 'white',
  };

  const handleBackClick = () => {
    if (isFirstStep) {
      window.location.href = '/dashboard';
    } else {
      onBack();
    }
  };

  const renderSummary = () => {
    if (thePlanData?.currentPlan) {
      return <ChangePlanSummary planData={thePlanData} />;
    } else {
      return <NewPlanSummary planData={thePlanData} />;
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'black', width: '100%', maxWidth: '750px', margin: '0 auto' }}>
        <div style={{ width: '100%', color: 'white' }}>

          <p style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Poppins, sans-serif', marginBottom: '16px', textAlign: 'center' }}>Checkout Summary</p>
          <hr style={{ width: '100%', borderTop: '1px solid rgba(255, 255, 255, 0.5)', marginBottom: '16px' }} />
          {renderSummary()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <DialogActions style={{ justifyContent: isFirstStep ? 'center' : 'space-between' }}>
            {!isFirstStep && (
              <Button variant="outlined" onClick={handleBackClick} style={secondaryButtonStyle}>
                <FontAwesomeIcon icon={faArrowLeft} fontSize="smaller" /> Back
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={() => {
                console.log('Next button clicked!');
                onSubscribe();
              }}
              style={isFirstStep ? { ...primaryButtonStyle, marginTop: '24px' } : primaryButtonStyle}
            >
              Next <FontAwesomeIcon icon={faArrowRight} fontSize="smaller" />
            </Button>

          </DialogActions>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default PaymentAndSummary;
