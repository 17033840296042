import { faExpand } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ServiceSearchDetail from "./ServiceSearchDetail";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons";

function ServiceSearch({ 
        expanded, 
        handleChange, 
        hubConnection, 
        service, 
        serviceSearchResults, 
        sessionId, 
        setTheService }) {
    return (
        <Accordion
            expanded={expanded === 'servicePanel'}
            onChange={handleChange('servicePanel')}>
            <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faExpand} />}
                area-controls="anelh-service"
                id="anelh-service"
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Service Selection
                </Typography>
                <Typography sx={{ color: 'text.primary' }}>
                    {service === null ? 'No Service Selected' : (
                        <>
                            {service.name} <FontAwesomeIcon icon={faTrash} onClick={(e) => setTheService(null)} />
                        </>
                    )}
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    backgroundColor: "#EEEEFF",
                    marginTop: "0px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    color: "#333333"
                }}>
                <Typography>
                    <ServiceSearchDetail
                        serviceSearchResults={serviceSearchResults}
                        hubConnection={hubConnection}
                        sessionId={sessionId}
                        setTheService={setTheService} />
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}

export default ServiceSearch;