import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PdfViewer({ pdfUrl, bearerToken, pdfData }) {
    const [pdfFile, setPdfFile] = useState();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(window.innerWidth < 600 ? 0.6 : 1.0); // Smaller scale on mobile

    useEffect(() => {
        const handleResize = () => {
            const newScale = window.innerWidth < 600 ? 0.7 : 1.0;
            setScale(newScale);
        };
    
        handleResize(); // Run on initial load
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    useEffect(() => {
        const fetchPdf = async () => {
            if (pdfUrl && bearerToken) {
                try {
                    const response = await fetch(`${window._env_.HITCH_API}/api/Render/pdf/${pdfUrl}`, {
                        method: 'GET',
                        headers: new Headers({
                            'Authorization': `Bearer ${bearerToken}`
                        }),
                        mode: 'cors',
                        cache: 'default',
                    });
                    const blob = await response.blob();
                    setPdfFile(URL.createObjectURL(blob));
                } catch (error) {
                    console.error('Error fetching PDF:', error);
                }
            }
        };

        fetchPdf();
    }, [pdfUrl, bearerToken]);

    useEffect(() => {
        if (pdfData) {
            const binaryStringToArrayBuffer = (binaryString) => {
                const len = binaryString.length;
                const bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }
                return bytes.buffer;
            };

            const arrayBuffer = binaryStringToArrayBuffer(pdfData);
            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
            setPdfFile(URL.createObjectURL(blob));
        }
    }, [pdfData]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1); // Reset to first page whenever a new document is loaded
    };

    const handleChange = (event, value) => {
        setPageNumber(value);
    };

    const zoomIn = () => setScale(scale + 0.1);

    const zoomOut = () => scale > 0.1 && setScale(scale - 0.1);

    const downloadPdf = () => {
        const link = document.createElement("a");
        link.href = pdfFile;
        link.download = "downloaded.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const printPdf = () => {
        const win = window.open(pdfFile, '_blank');
        win.focus();
        win.print();
    };

    return (
        <div>
            {/* Controls Stack for horizontal layout on all devices */}
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" alignItems="center" sx={{ marginBottom: 2 }}>
                {/* Individual Stack for zoom and download controls to ensure they stay in a row */}
                <Stack direction="row" spacing={1}>
                    <IconButton onClick={zoomOut} color="primary" size="large">
                        <RemoveIcon />
                    </IconButton>
                    <IconButton onClick={zoomIn} color="primary" size="large">
                        <AddIcon />
                    </IconButton>
                    <IconButton onClick={downloadPdf} color="primary" size="large">
                        <DownloadIcon />
                    </IconButton>
                </Stack>
                {/* Pagination controls, separate Stack to ensure horizontal layout */}
                {numPages > 1 && (
                    <Pagination
                        count={numPages}
                        page={pageNumber}
                        onChange={handleChange}
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                        showFirstButton
                        showLastButton
                        sx={{ mt: { xs: 2, sm: 0 } }} // Add margin-top only on xs screens
                    />
                )}
            </Stack>
            
            <div style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '80vh', maxWidth: '100%' }}>
    <Document file={pdfFile} loading="Loading PDF..." onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} scale={scale} width={window.innerWidth * 0.9} />
    </Document>
</div>

            
            {/* Repeat Pagination at the bottom, if desired */}
            {numPages > 1 && (
                <Stack justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: 2 }}>
                    <Pagination
                        count={numPages}
                        page={pageNumber}
                        onChange={handleChange}
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                        showFirstButton
                        showLastButton
                    />
                </Stack>
            )}
        </div>
    );
}

export default PdfViewer;