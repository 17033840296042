import React, { useState, useEffect } from 'react';
import { TextField, Box, Button, Divider } from '@mui/material';
import { Handle, Position } from 'reactflow';

export default function HitchRuleNode({ id, data }) {
  const [expanded, setExpanded] = useState(false);
  // Initialize formData with data or default values to ensure controlled components
  const [formData, setFormData] = useState({
    name: data?.name || '',
    ruleExpression: data?.ruleExpression || '',
  });

  const handleDeleteNode = (nodeId) => {
    console.log('delete!', nodeId);
    data.onNodeCallback('local', 'node', 'delete', nodeId);
  };

  // Update formData when data changes
  useEffect(() => {
    if (data) {
      setFormData(prevFormData => ({
        ...prevFormData,
        name: data.name,
        ruleExpression: data.ruleExpression,
      }));
    }
  }, [data]);

  // Handle changes to the name field
  const handleNameChange = (event) => {
    const newName = event.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      name: newName,
    }));
  };

  // Handle changes to the lambda (ruleExpression) field
  const handleLambdaChange = (event) => {
    const newLambda = event.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      ruleExpression: newLambda,
    }));
  };

  return (
    <>
      <div className="custom-node__header">
        Rule: <strong>{formData.name}</strong>
      </div>
      <div className="custom-node__body">
        <Box>
          <TextField
            id="txtFileName"
            label="Name"
            fullWidth
            value={formData.name}
            onChange={handleNameChange} // Add onChange event handler
          />

          <Divider />

          <TextField
            className="lambda"
            id="outlined-multiline-static"
            label="Lambda"
            multiline
            fullWidth
            rows={4}
            value={formData.ruleExpression}
            onChange={handleLambdaChange} // Add onChange event handler
          />
          <Divider />
          <Button variant='contained'>Lambda Editor</Button>
          <Handle type="target" position={Position.Left} className="custom-handle event-handle-top" id={id} />
          {/* This one should be action */}
          <Handle type="source" position={Position.Bottom} className="custom-handle action-handle-top" id={`action-${id}`} />

          <Button variant="contained" color="error" onClick={() => handleDeleteNode(id)}>
            Delete
          </Button>
          {/* This one should be condition */}
          <Handle type="source" position={Position.Right} className="custom-handle connection-handle-top" id={`condition-${id}`} />

        </Box>
      </div>
    </>
  );
}
