import { Divider, FilledInput, FormHelperText, Grid, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import QuickbooksStep from "./QuickbooksStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteRight, faMagnifyingGlass, faMessageBot, faPlus, faSquareQuestion, faTransformerBolt, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { Box } from "@mui/system";
import { faReceipt } from "@fortawesome/pro-solid-svg-icons";
import { useEffect } from "react";

function QuickbooksSteps({
    steps,
    editStep,
    deleteStep,
    selectStep,
    addStep,
    selectedStep,
    updateStep,
    entity,
    entities,
    handleChange,
    columns,
    setEntity,
    setQuery,
    query,
    reset,
    rows,
    runQuery,

    hubConnection,
    sessionId,
    outputOptions,
    outputType,
    allResults,
    setTransformer,
    setTransformerSteps,
    transformerSteps,
    transformer,
    finalStep,
    setFinalStep,

    handleMessage,
    botAssist,
    runIfTrue,
    setRunIfTrue
}) {

    function newQuery(e) {
        addStep("Query");
    }

    function newReport(e) {
        addStep("Report");
    }

    function newTransform(e) {
        addStep("Transform");
    }

    function newAuthenticate(e) {
        addStep("Authenticate");
    }

    function newDisconnect(e) {
        addStep("Disconnect");
    }

    function icon(name) {
        if (name === "Query") {
            return faMagnifyingGlass;
        }
        if (name === "Report") {
            return faReceipt;
        }

        if (name === "Transform") {
            return faTransformerBolt;
        }

        return faSquareQuestion;
    }

    useEffect(() => {
        console.log('steps changed.  Did we render?');
    }, [steps]);

    return (
        <Grid className="qb_top_row2" container justifyContent="center" alignItems="top">
            <Grid item xs={4} alignItems="top">
                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <nav aria-label="main mailbox folders">
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton onClick={(e) => selectStep(null)}>
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </ListItemIcon>
                                    <ListItemText primary="Add A Step" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton onClick={(e) => botAssist()}>
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faMessageBot} />
                                    </ListItemIcon>
                                    <ListItemText primary="Bot Assist" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </nav>
                    <Divider />
                    <nav aria-label="secondary mailbox folders">
                        <List>
                            {steps && steps.map((step) => (
                                <ListItem disablePadding>
                                    <ListItemButton onClick={(e) => selectStep(step.step_id)}>
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={icon(step.operation_type)} />
                                        </ListItemIcon>
                                        <ListItemText primary={step.name} />
                                        <ListItemIcon>
                                            <Tooltip title="Delete step">
                                                <FontAwesomeIcon icon={faTrash} onClick={(e) => deleteStep(step.step_id)} />
                                            </Tooltip>
                                        </ListItemIcon>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </nav>
                </Box>
            </Grid>
            <Grid item xs={8} marginRight={0} justifyContent="center" alignItems="top">
                {selectedStep === null && (
                    <Grid container justifyContent="center" alignItems="top" spacing={1}>
                        <Grid item xs={12} justifyContent="left">
                            <FilledInput
                                fullWidth
                                id="filled-adornment-weight"
                                endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputAdornment>}
                                aria-describedby="filled-weight-helper-text"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                            <FormHelperText id="filled-weight-helper-text">Step Type</FormHelperText>
                        </Grid>
                        <Grid item xs={12} justifyContent="left">
                            <List>
                                <ListItemButton onClick={(e) => newTransform(e)}>
                                    <ListItem>
                                        <ListItemText secondary="Transformer" primary="Transform Step"></ListItemText>
                                    </ListItem>
                                </ListItemButton>
                                <ListItemButton onClick={(e) => newAuthenticate(e)}>
                                    <ListItem>
                                        <ListItemText secondary="Quickbooks" primary="Request For Authentication"></ListItemText>
                                    </ListItem>
                                </ListItemButton>
                                <ListItemButton onClick={(e) => newDisconnect(e)}>
                                    <ListItem>
                                        <ListItemText secondary="Quickbooks" primary="Disconnect Quickbooks"></ListItemText>
                                    </ListItem>
                                </ListItemButton>
                                <ListItemButton onClick={(e) => newQuery(e)}>
                                    <ListItem>
                                        <ListItemText secondary="Quickbooks" primary="Query Step"></ListItemText>
                                    </ListItem>
                                </ListItemButton>
                                <ListItemButton onClick={(e) => newReport(e)}>
                                    <ListItem>
                                        <ListItemText secondary="Quickbooks" primary="Report Step"></ListItemText>
                                    </ListItem>
                                </ListItemButton>
                            </List>
                        </Grid>
                    </Grid>
                )}
                {selectedStep && (
                    <Grid container justifyContent="center" alignItems="top" spacing={1}>
                        <Grid item xs={12} justifyContent="left">
                            <QuickbooksStep
                                step={selectedStep}
                                editStep={editStep}
                                deleteStep={deleteStep}
                                updateStep={updateStep}
                                entity={entity}
                                entities={entities}
                                handleChange={handleChange}
                                columns={columns}
                                setEntity={setEntity}
                                setQuery={setQuery}
                                query={query}
                                reset={reset}
                                rows={rows}
                                runQuery={runQuery}

                                hubConnection={hubConnection}
                                sessionId={sessionId}
                                outputOptions={outputOptions}
                                outputType={outputType}
                                allResults={allResults}
                                setTransformer={setTransformer}
                                setTransformerSteps={setTransformerSteps}
                                transformerSteps={transformerSteps}
                                transformer={transformer}
                                finalStep={finalStep}
                                setFinalStep={setFinalStep}
                                handleMessage={handleMessage}
                                runIfTrue={runIfTrue}
                                setRunIfTrue={setRunIfTrue}
                            />
                        </Grid>
                    </Grid>
                )}
                {/* <Grid container justifyContent="center" alignItems="top" spacing={1}>
                    {steps.map((step) => (
                        <Grid item xs={12} md={4}>
                            <QuickbooksStep step={step} editStep={editStep} deleteStep={deleteStep} />
                        </Grid>
                    ))}
                </Grid> */}
            </Grid>
        </Grid>
    )
}

export default QuickbooksSteps;
