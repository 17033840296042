import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Divider, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import ProductCard from './ProductCard';

function MessageProduct({ message }) {
    const [payload, setPayload] = React.useState({});
    const [hover, setHover] = React.useState(false);

    console.log('message', message);

    const cardStyle = {
        maxWidth: 345,
        color: '#fff',
        backgroundColor: '#000000', // Dark grey background
        //border: '1px solid #00EDED', // Normal border
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#00000', // Same background color on hover
            //boxShadow: '0px 0px 12px 2px rgba(255,255,255,0.2)', // Subtle glow effect
            //border: '2px solid #00EDED', // Thicker border on hover
        }
    };

    const iconStyle = {
        marginLeft: 4,
        fontSize: '0.8em',
        verticalAlign: 'middle',
        color: hover ? '#00EDED' : '#2E8B57', // Color changes based on hover state
        transition: 'color 0.2s ease, transform 0.2s ease' // Smooth transition for hover effects
    };


    const tooltipTitle = payload?.NewWindow ? "Open In New Window" : "";

    const cardContent = (
        <CardActionArea
            onClick={(e) => clicked()}>
                 {payload?.ImageUrl && (
                              <CardMedia
                              sx={{
                                paddingTop: '56.25%', // 16:9 aspect ratio (adjust as needed)
                                backgroundSize: 'contain', // Ensure the image fits without cropping
                              }}
                              image={payload?.ImageUrl}
                              title={payload?.DisplayText}
                            />
                    )}
            <CardContent>
                <Typography color="text.secondary" sx={{ color: 'white' }}>
                    {payload?.DisplayText}
                    {payload?.NewWindow && (
                        <FontAwesomeIcon
                            icon={faExternalLinkAlt}
                            style={{
                                ...iconStyle,
                                color: hover ? '#00EDED' : '#00EDED', // Change color on hover
                                transform: hover ? 'scale(1.1)' : 'scale(1)' // Optional: Slightly increase size on hover
                            }}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        />
                    )}
                </Typography>
            </CardContent>
        </CardActionArea>
    );

    React.useEffect(() => {
        if (message.content) {
            try {
                let theObject = JSON.parse(message.content);
                setPayload(theObject);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }
    }, [message]);
    

    function clicked() {
        console.log('clicked', payload);
        if (payload && payload.Url) {
            if (payload.NewWindow) {
                // Open in a new window/tab
                window.open(payload.Url, '_blank');
            } else {
                // Open in the same window
                window.location.href = payload.Url;
            }
        }
    }
    
    if (message.contentType === "Product" || message.contentType === "product") {
        return (
            <Grid container spacing={3} m={1} justify="center" alignItems="center">
              <Grid item xs={12}>
                <Typography>Coffee Shops near you...</Typography>
              </Grid>
              <Grid item xs={3}>
                <ProductCard 
                    title="Alex's Good Coffee" 
                    description="Alex's Good Coffee, with its eye-catching blue and yellow exterior, stands as a beacon of warmth and community spirit in the heart of the city. Here, coffee enthusiasts gather under cheerful umbrellas to savor rich, artisanal blends amidst the unique backdrop of a well-loved hockey net and stick. This cozy corner spot not only serves exceptional coffee but also embodies the local love for sports and camaraderie."
                    img="/img/demo/alex.png" />
              </Grid>
              <Grid item xs={3}>
                <ProductCard 
                    title="Sam's Sweets / Micah's Coffeehouse" 
                    description="Sam's Sweets delights visitors with its charming pink facade and an enchanting array of delicious ice creams, making it a winter wonderland for little girls and families alike. Micah's Coffeehouse, with its vibrant orange and blue exterior, offers a cozy urban retreat where teenagers and coffee aficionados can enjoy a warm cup amidst the snowy setting, while staying connected on their phones."
                    img="/img/demo/sam.png" />
              </Grid>
              <Grid item xs={3}>
                <ProductCard 
                    title="Zacha-roo's" 
                    description="Zacharoo's, nestled beside an amusement park, captivates with its bold orange and black theme and panoramic views of roller coasters. The aroma of freshly brewed coffee and homemade pastries fills the air, promising a delightful treat for visitors. A blend of cozy ambiance, vintage arcade games, and a lively terrace makes it an ideal spot for families and thrill-seekers alike to unwind and indulge."
                    img="/img/demo/zach.png" />
              </Grid>
            </Grid>
          );
    } else {
        return (<></>)
    }
}

export default MessageProduct;