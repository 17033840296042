import React, { useState, useEffect } from 'react';
import RegistrationWizard from './RegistrationWizard';
import CloseIcon from '@mui/icons-material/Close';
import CancelPrompt from './CancelPrompt'; // Import your CancelPrompt component

function MyModal({ onClose, children }) {
  const [showCancelPrompt, setShowCancelPrompt] = useState(false);

  const handleCancelPromptOpen = () => {
    setShowCancelPrompt(true);
  };

  const handleCancelPromptClose = () => {
    setShowCancelPrompt(false);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
}, []);


  return (
    <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'radial-gradient(circle, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.9))',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1100, // This should be higher than other elements
      overflowY: 'auto',
    }}
    >
      <div
        style={{
          position: 'relative',
          backgroundColor: 'black',
          padding: '24px',
          borderRadius: '40px',
          boxShadow: '0 0 4px #333',
          width: isMobile ? '95%' : '80%', // Wider on mobile
          maxWidth: '1100px',
          minWidth: '300px',
          maxHeight: '90vh',
          minHeight: '400px',
          overflowY: 'auto',
          zIndex: 1101,
          margin: isMobile ? '10px auto' : 'auto', // Less margin on mobile
          position: 'relative',
      }}
      >
        <button
          onClick={handleCancelPromptOpen}
          style={{
            position: 'absolute',
            top: '10px', // Adjusted for better visibility
            right: '10px', // Adjusted for better visibility
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            zIndex: 1102, // Ensure it's above other modal content
          }}
        >
          <CloseIcon style={{ color: 'white', fontSize: '24px', marginRight: '12px' }} />
        </button>
        {children}
        {showCancelPrompt && (
          <CancelPrompt open={showCancelPrompt} onClose={handleCancelPromptClose} />
        )}
      </div>
    </div>
  );
}

function OpenRegistrationWizard() {
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    console.log('Open Registration Wizard component mounted');
    return () => {
      console.log('Open Registration Wizard component unmounted');
    };
  }, []);

  useEffect(() => {
    console.log('isModalOpen', isModalOpen);
  }, [isModalOpen]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {isModalOpen && (
        <MyModal onClose={closeModal}>
          <RegistrationWizard />
        </MyModal>
      )}
    </div>
  );
}

export default OpenRegistrationWizard;