import React, { useState, useEffect } from "react";
import AiChatDrawer from "../chat-system/AiChatDrawer";
import virtualSalesTheme from "./VirtualSalesTheme";
import Header from "./Header";
import Sidebar from "./Sidebar";
import MainContent from "./MainContent";
import "./VirtualSales.css";

function VirtualSales() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [mainContent, setMainContent] = useState(null);
    
    // const signalR = useSignalR({
    //     onCartUpdate: (data) => setCartItems(data),
    //     onMainContentUpdate: (content) => setMainContent(content)
    // });

    function closeDrawer() {
        setDrawerOpen(false);
    }

    function openDrawer() {
        setDrawerOpen(true);
    }

    function handleChatMessage(message) {
        setMainContent(message);
    }

    return (
        <div className="virtual-sales-container">
            <Header openChat={openDrawer} />
            <div className="content">
                <Sidebar cartItems={cartItems} />
                <MainContent content={mainContent} />
            </div>
            <AiChatDrawer 
                isOpen={drawerOpen} 
                onClose={closeDrawer} 
                theme={virtualSalesTheme}
                mode={'sales'}
                defaultPlaceholder='Tell Virtual Sales Exactly What You Need!' 
                onMessage={handleChatMessage} // handle chat messages
            />
        </div>
    );
}

export default VirtualSales;
