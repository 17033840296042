import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Breadcrumbs, Button, Chip, Collapse, IconButton, Paper, TableCell, TableContainer, TableRow, Typography, tableCellClasses } from "@mui/material";
import { Link as MuiLink } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useHubConnections } from "../../utils/HubConnectionsProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faDuck } from "@fortawesome/pro-solid-svg-icons";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import HitchBox from "../../components/hitchbox/HitchBox";
import ActorRegistry from "./ActorRegistry";

import * as signalR from '@microsoft/signalr';


function Node() {
    const clusterId = 'dfbe8ce6-2404-4b86-9f36-e035afa548bb';
    const { nodename } = useParams();
    const [nodeData, setNodeData] = useState({});
    const [startOnce, setStartOnce] = useState(false);
    const {
        jwtToken,
        hitchUser,
        user
    } = useHubConnections();
    const [debugConnection, setDebugConnection] = useState(null);

    const [isConnectionReady, setIsConnectionReady] = useState(false);

    useEffect(() => {
        if (nodename && user) {
            // Fetch user avatar
            fetch(`${window._env_.HITCH_API}/api/hitch_deployment/${nodename}`, {
                headers: {
                    'Authorization': `Bearer ${user.id_token}`
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();  // Convert to JSON if response was ok
                    } else {
                        throw new Error('Failed to fetch');  // Throw an error if response not ok
                    }
                })
                .then(data => {
                    // console.log('Node Data:', data);  // Log the data
                    setNodeData(data);
                })
                .catch((error) => {
                    // console.log('Cluster Data:', error);
                });
        }
    }, [nodename, user]);

    useEffect(() => {
        if (jwtToken && hitchUser && !startOnce) {
            // console.log('hitchUser.userId (starting debugConnection)', hitchUser.userId);

            let isMounted = true; // Track mounted status
            setStartOnce(true);

            const connection = new signalR.HubConnectionBuilder()
                .withUrl(`${window._env_.HITCH_API}/8675309?sessionId=${clusterId}`, {
                    accessTokenFactory: () => jwtToken
                })
                .withAutomaticReconnect()
                .build();

            const startConnection = async () => {
                try {
                    await connection.start();
                    // console.log('Cluster connection established.');
                    setDebugConnection(connection);
                    setIsConnectionReady(true);
                    // fetchInitialData(connection);
                    // connection.on("KingEntry", handleKingEntry);
                    // connection.on("DropKing", handleDropKing);
                } catch (error) {
                    if (isMounted) {
                        console.error('Connection failed to start:', error);
                        setStartOnce(false);
                        setIsConnectionReady(false);
                    }
                }
            };
            startConnection();

            // Cleanup
            return () => {
                isMounted = false;
                // connection.off("KingEntry", handleKingEntry);
                // connection.off("DropKing", handleDropKing);
                connection.stop().then(() => {
                    // console.log('Cluster Connection stopped')
                }
                );
                setIsConnectionReady(false);
                setStartOnce(false);
            };
        }
    }, [jwtToken, hitchUser]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));


    const [open, setOpen] = useState({});

    const handleOpen = (roleId) => {
        setOpen(prevOpen => ({
            ...prevOpen,
            [roleId]: !prevOpen[roleId]
        }));
    };

    // Fetch data using useEffect as already implemented...

    return (
        <Box m={3}>
            <HitchBox backgroundColor={'#FFFFFF'} color={'#000000'}>
                <Breadcrumbs>
                    <MuiLink underline="hover" color="inherit" href="/app/cluster">Node List</MuiLink>
                </Breadcrumbs>
            </HitchBox>
            <TableContainer component={Paper}>
                <Table aria-label="node roles table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell />
                            <StyledTableCell>Role</StyledTableCell>
                            <StyledTableCell align="right">Unscheduled Actors</StyledTableCell>
                            <StyledTableCell align="right">Scheduled Actors</StyledTableCell>
                            <StyledTableCell align="right">Online Actors</StyledTableCell>
                            <StyledTableCell align="right">Total Actors</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nodeData?.report?.roles?.map((role, index) => (
                            <React.Fragment key={index}>
                                <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    <StyledTableCell>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => handleOpen(index)}
                                        >
                                            {open[index] ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
                                        </IconButton>
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {role.role}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{role.unscheduledActors}</StyledTableCell>
                                    <StyledTableCell align="right">{role.scheduledActors}</StyledTableCell>
                                    <StyledTableCell align="right">{role.onlineActors}</StyledTableCell>
                                    <StyledTableCell align="right">{role.totalActors}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                        <Collapse in={open[index]} timeout="auto" unmountOnExit>
                                            <Box sx={{ margin: 1 }}>
                                                <Typography variant="h6" gutterBottom component="div">
                                                    Node Details
                                                </Typography>
                                                <Table size="small" aria-label="node details">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>Node</StyledTableCell>
                                                            <StyledTableCell align="right">Scheduled Actors</StyledTableCell>
                                                            <StyledTableCell align="right">Online Actors</StyledTableCell>
                                                            <StyledTableCell align="right">Total Actors</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {role.nodes.map((node, nodeIndex) => (
                                                            <StyledTableRow key={nodeIndex}>
                                                                <StyledTableCell component="th" scope="row">
                                                                    {node.node}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="right">{node.scheduledActors}</StyledTableCell>
                                                                <StyledTableCell align="right">{node.onlineActors}</StyledTableCell>
                                                                <StyledTableCell align="right">{node.totalActors}</StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Collapse>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Accordion>
                <AccordionSummary><Typography variant="h6">Events</Typography></AccordionSummary>
                <AccordionDetails>
                    <TableContainer component={Paper}>
                        <Table aria-label="node roles table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Event</StyledTableCell>
                                    <StyledTableCell>Accepted</StyledTableCell>
                                    <StyledTableCell>ActorId</StyledTableCell>
                                    <StyledTableCell>Before</StyledTableCell>
                                    <StyledTableCell>After</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {nodeData?.event?.map((evt, index) => (
                                    <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                        <StyledTableCell>{evt.remarks}</StyledTableCell>
                                        <StyledTableCell>{evt.accept}</StyledTableCell>
                                        <StyledTableCell>{evt.inbound.actorId}</StyledTableCell>
                                        <StyledTableCell>{evt.existing?.nodeName} / {evt.existing?.actorAddress}</StyledTableCell>
                                        <StyledTableCell>{evt.inbound?.nodeName} / {evt.inbound?.actorAddress}</StyledTableCell>
                                    </StyledTableRow>
                                ))};
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary><Typography variant="h6">Registry</Typography></AccordionSummary>
            </Accordion>
            <AccordionDetails>
                <ActorRegistry nodeData={nodeData} debugConnection={debugConnection} />
            </AccordionDetails>
        </Box>
    );


}

export default Node;