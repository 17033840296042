import React, { useEffect, useState } from 'react';
import { Grid, Container, Typography, Button } from "@mui/material";
import ConversationV2 from '../ConversationV2/ConversationV2';
import FooterHitchAIV2 from '../ConversationV2/footerV2/FooterHitchAIV2'; // Ensure correct import path
import { useQualifierChat } from './QualifierChatProvider';
import AccessCodePage from './AccessCodePage'; // Corrected the import to match the previous code
import { useTheme } from '@mui/material/styles';
import { confettiDrop, continuousConfettiDrop } from '../components/confetti/confetti'; // Import the confetti functions
import Offer from './Offer';

function Qualifier() {
    const theme = useTheme(); // Access the theme to use in the media query
    const themeBackgroundColor = theme.palette.secondary[theme.palette.mode];
    const { authorized, emotion, hitchUser, qualifierHub, status } = useQualifierChat();
    const [showOffer, setShowOffer] = useState(false);
    const [offerData, setOfferData] = useState({ price: 100.00, features: ["Access to your Quickbooks data via our chat interface", "Specialized queries from past M&A over your finances", "Advice on how to position your company into a better position for M&A"] });
    const [lead, setLead] = useState({});
    useEffect(() => {
        if (qualifierHub) {
            console.log('qual hub armed.');
            qualifierHub.on("63d91559-1df4-4678-b514-55e173ca6271", handleOffer);
            qualifierHub.on("a56cc11a-cfa6-4a4e-8755-4ecdfca3ed1c", hello);
            qualifierHub.on("PresentOffer", handleOffer);
            // Add the OfferPayment method listener
            qualifierHub.on("OfferPayment", handleOfferPayment);
            qualifierHub.on("28841020-528d-48fd-917c-158e0e57f648", handleLead);

            function fetchLead() {
                console.log('asking for the lead');
                qualifierHub.invoke("HitchCommand", hitchUser.id, 'marketing', 'lead', 'fetch', {
                    HitchLeadId: hitchUser.id
                });
            }

            fetchLead();

            return () => {
                if (qualifierHub) {
                    qualifierHub.off("63d91559-1df4-4678-b514-55e173ca6271", handleOffer);
                    qualifierHub.off("PresentOffer", handleOffer);
                    qualifierHub.off("OfferPayment", handleOfferPayment);
                    qualifierHub.off("a56cc11a-cfa6-4a4e-8755-4ecdfca3ed1c", hello);
                    qualifierHub.off("28841020-528d-48fd-917c-158e0e57f648", handleLead);
                    console.log('qual hub disarmed.');
                }
            }
        }
    }, [qualifierHub]);

    function handleLead(data) {
        console.log('theLead', data);
        setLead(data);
    }

    function acceptIt() {
        console.log('accepting offer');
        if (qualifierHub) {
            let offerId = '3CEC8E80-DCF8-4132-B427-456466A58778';

            qualifierHub.invoke("HitchCommand", hitchUser.id, 'marketing', 'offer', 'claim', {
                theOfferId: offerId,
                theLeadId: hitchUser.id
            });
        }
        setShowOffer(false);
        confettiDrop();
    }

    function declineIt() {
        setShowOffer(false);
        confettiDrop();
    }

    function hello(data) {
        console.log('this is unexpected (a56cc11a-cfa6-4a4e-8755-4ecdfca3ed1c)', data);
        handleOffer(data);
    }
    function handleOffer(data) {
        console.log('handleOffer', data);
        continuousConfettiDrop();
        setOfferData(data);
        // setOfferData({ price: 100.00, features: ["Access to your Quickbooks data via our chat interface", "Specialized queries from past M&A over your finances", "Advice on how to position your company into a better position for M&A"] });
        setShowOffer(true);
    }

    function doTheOffer() {
        if (qualifierHub) {
            let offerId = '3CEC8E80-DCF8-4132-B427-456466A58778';
            qualifierHub.invoke("HitchCommand", hitchUser.id, 'marketing', 'offer', 'present', {
                theOfferId: offerId
            });
        }
    }


    // Handle the payment link and redirect the parent page
    function handleOfferPayment(paymentLink) {
        console.log('handleOfferPayment', paymentLink);
        window.parent.location.href = paymentLink;
    }

    return (
        <Grid container>
            {!status && !hitchUser && (
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ minHeight: '100vh' }} // Ensure the grid takes up the full viewport height
                >
                    <Grid item xs={12} mt={6}>
                        <Typography variant="h4" align="center">
                            Your AI Advisor will be right with you.
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <h1>{emotion}</h1>
            {!authorized && hitchUser && (
                <Container sx={{
                    minWidth: '100%',
                    maxWidth: 'none',
                    minHeight: '100vh',
                    maxHeight: 'none',
                    mt: '0px',
                    backgroundColor: themeBackgroundColor
                }}>
                    <AccessCodePage />
                </Container>
            )}

            {status && authorized && !showOffer && (
                <Container sx={{
                    minWidth: '100%',
                    maxWidth: 'none',
                    minHeight: '100vh',
                    maxHeight: 'none',
                    mt: '0px',
                    backgroundColor: themeBackgroundColor
                }}>
                    <ConversationV2
                        noHistory={true}
                        redirectPath={'qualifier'}
                        shouldFocusChatBox
                        overrideSessionId
                        maxWidth
                        overrideHeight
                        isInChatDrawer={null}
                        drawerHeightValue={null}
                        noHeader={true}
                    />
                </Container>
            )}

            {showOffer && (
                <Container sx={{ mt: 4 }}>
                    <Offer acceptIt={acceptIt} declineIt={declineIt} offer={offerData} lead={hitchUser} />
                </Container>
            )}

            <FooterHitchAIV2 /> {/* Add the footer component here */}

            {/* Add buttons to trigger the confetti effects for demonstration purposes
            <Grid container justifyContent="center" mt={2} spacing={2}>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={() => doTheOffer()}>
                        Simulate Offer Presentation
                    </Button>
                </Grid>
            </Grid> */}
        </Grid>
    );
}

export default Qualifier;
