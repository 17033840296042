import { faDuck, faGlassWhiskeyRocks } from "@fortawesome/pro-duotone-svg-icons";
import { faBuilding, faImage, faMagnifyingGlass, faPuzzlePiece, faTreePalm } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, ButtonGroup, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, OutlinedInput, Switch, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import AdminGrid from "../AdminGrid";
import TrainingHeader from "./TrainingHeader";

function FeatureSearchDetail({ 
        account, 
        connection, 
        featureSearchResults, 
        hubConnection, 
        service, 
        sessionId, 
        setTheFeature 
    }) {
    const [searchTerm, setSearchTerm] = useState('');

    function handleSearchFeature() {
        if (hubConnection) {
            hubConnection.invoke("SearchFeature", sessionId, searchTerm, account?.id, service?.id, connection?.id)
                .then(() => {
                    // Do Nothing.  When Request is returned, it should update the page.
                })
                .catch(error => console.error("Error sending message:", error));
        }
    }



    function clickDefault(e) {
        if (!e.target.checked) {
            setSearchTerm('');
        }
    }

    return (
        <Box sx={{ height: '27vh', marginLeft: '20px', marginRight: '20px' }}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <OutlinedInput
                            id="txtFeatureSearch"
                            label="Feature Search"
                            variant="standard"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleSearchFeature}
                                        edge="end"
                                    >
                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {featureSearchResults !== null && (
                        <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}>
                            {featureSearchResults.map((e) => {
                            return (
                                <div key={e.id}>
                                    <ListItem onClick={(et) => setTheFeature(e.id)}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FontAwesomeIcon icon={faBuilding} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={e.name} secondary={`Feature Id: ${e.id}`} />
                                    </ListItem>
                                    <Divider />
                                </div>
                            )
                            }
                            )}
                        </List>
                    )}
                </Grid>
                <Grid item xs={6} sx={{

                    marginTop: "50px",
                    paddingLeft: "20px",
                    paddingBottom: "0px",
                    color: "#333333"
                }}>
                    <Grid container>
                        <FormGroup>
                            <FormControlLabel control={<Switch defaultChecked />} checked={searchTerm === ''} onclick={(e) => clickDefault(e)} label="Global" />
                        </FormGroup>
                    </Grid>

                </Grid>
            </Grid>
        </Box>
    );
}

export default FeatureSearchDetail;