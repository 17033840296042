import { AppBar, Box, Button, Grid, IconButton, Modal, Toolbar, Typography } from "@mui/material";
import HitchModal from "./HitchModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FamilyRestroom } from "@mui/icons-material";
import { faClose, faMarsAndVenus } from "@fortawesome/pro-solid-svg-icons";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import FormulaBuilder from "./FormulaBuilder";

function ConditionalLambda({ open, onClose }) {
    return (
        <HitchModal open={open} onClose={onClose}>
            <AppBar position="static" sx={{ backgroundColor: '#00AA99' }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <FontAwesomeIcon icon={faMarsAndVenus} />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Condition Builder
                    </Typography>
                    <IconButton onClick={onClose}>
                        <FontAwesomeIcon icon={faClose} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Grid container>
                <Grid xs={4}>
                    <Box sx={{ minHeight: 180, flexGrow: 1, maxWidth: 300 }}>
                        <TreeView
                            aria-label="file system navigator"
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                        >
                            <TreeItem nodeId="1" label="Applications">
                                <TreeItem nodeId="2" label="Calendar" />
                            </TreeItem>
                            <TreeItem nodeId="5" label="Documents">
                                <TreeItem nodeId="10" label="OSS" />
                                <TreeItem nodeId="6" label="MUI">
                                    <TreeItem nodeId="8" label="index.js" />
                                </TreeItem>
                            </TreeItem>
                        </TreeView>
                    </Box>
                </Grid>
                <Grid xs={8}>
                    <FormulaBuilder />
                </Grid>
            </Grid>
        </HitchModal>
    );
}

export default ConditionalLambda;