import { useEffect, useState } from "react";
import { useHubConnections } from "../../utils/HubConnectionsProvider";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import HitchBox from "../../components/hitchbox/HitchBox";
import HitchTreeView from "../../components/hitchtreeview/HitchTreeView";

function MenuAdmin() {
    const { accountId, dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady } = useHubConnections();
    const [menus, setMenus] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on("74a08e24-21ff-43f5-b58a-cc0a825d93c6", (data) => {
                console.log('menus', data);
                setMenus(data);
            });
            callForMenus();
        }

        return () => {
            if (dashboardConnection) {
                dashboardConnection.off("74a08e24-21ff-43f5-b58a-cc0a825d93c6");
            }
        };
    }, [dashboardConnection, isConnectionReady]);

    function callForMenus() {
        if (dashboardConnection) {
            console.log('Calling for Menu List');
            hitchDashCommand('account', 'menu', 'list', {});
        }
    }

    function updateData(data) {
        hitchDashCommand('account', 'menu', 'patch', {
            menus: data
        });
        console.log('updateData', data);
    }


    return (
        <Grid container>
            <Grid xs item></Grid>
            <Grid xs={9} item>
                <Grid container>
                    <Grid xs={12} item>
                        <HitchBox backgroundColor="#000033" color="#FFFFFF">
                            <Typography variant="h4">Menus Header Here</Typography>
                        </HitchBox>
                    </Grid>
                    <Grid xs={12} item>
                        <HitchBox backgroundColor="#330000" color="#FFFFFF">
                            <Typography variant="h4">Menus Grid Here: {menus.length} records.</Typography>
                        </HitchBox>
                    </Grid>
                    <Grid xs={12} item>
                        <HitchBox backgroundColor="#003300" color="#FFFFFF">
                            <HitchTreeView data={menus} accountId={accountId} updateData={updateData}/>
                        </HitchBox>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs item></Grid>
        </Grid>
    )
}

export default MenuAdmin;