// ProfileDetails.jsx
import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useSettingsStyles } from "../common/SettingsStyleProvider";

const ProfileDetails = ({ hitchUser, formatPhoneNumber, themePrimaryTextColor }) => {
    const { font, dividerStyle } = useSettingsStyles();

    useEffect(() => {
        if(hitchUser) {
            console.log('hitchUser', hitchUser);
        }
    }, [hitchUser]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'grid', gridTemplateColumns: '6fr 6fr', gridGap: '4px', marginLeft: '24px' }}>
                <div>
                    <Typography variant="body1" gutterBottom style={{
                        color: themePrimaryTextColor,
                        fontFamily: font,
                    }}>
                        First Name:
                    </Typography>
                </div>
                <div>
                    <Typography variant="body1" gutterBottom style={{
                        color: themePrimaryTextColor,
                        fontFamily: font,
                    }}>
                        {hitchUser?.firstName || '\u00A0'}
                    </Typography>
                </div>
                <div>
                    <Typography variant="body1" gutterBottom style={{
                        color: themePrimaryTextColor,
                        fontFamily: font,
                    }}>
                        Last Name:
                    </Typography>
                </div>
                <div>
                    <Typography variant="body1" gutterBottom style={{
                        color: themePrimaryTextColor,
                        fontFamily: font,
                    }}>
                        {hitchUser?.lastName || '\u00A0'}
                    </Typography>
                </div>
                <div>
                    <Typography variant="body1" gutterBottom style={{
                        color: themePrimaryTextColor,
                        fontFamily: font,
                    }}>
                        Phone:
                    </Typography>
                </div>
                <div>
                    <Typography variant="body1" gutterBottom style={{
                        color: themePrimaryTextColor,
                        fontFamily: font,
                    }}>
                        {formatPhoneNumber(hitchUser?.defaultPhoneNumber) || '\u00A0'}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default ProfileDetails;
