import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import HubspotForm from 'react-hubspot-form';

const ContactForm = forwardRef((props, ref) => {
  return (
    <Box ref={ref} sx={{ marginBottom: '20px' }}>
      <div ref={ref}>
      <HubspotForm
        portalId='43615722'
        formId='72723086-74f0-4e6b-86d9-e2d74776b1f8'
        onSubmit={() => console.log('Submit!')}
        onReady={(form) => console.log('Form ready!')}
        loading={<div>Loading...</div>}
      />
      </div>
    </Box>
  );
});

export default ContactForm;
