import React, { useState } from 'react';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    Divider,
    Tooltip,
    Typography,
    Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faFile } from '@fortawesome/pro-regular-svg-icons';
import { faMessageBot, faMessagePlus } from '@fortawesome/pro-solid-svg-icons';
import BotEditModal from '../BotEditModal/BotEditModal'; // Import the new modal component

const drawerWidth = 88;

const StyledListItem = styled(ListItem)(({ theme, selected, open }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 0',
    backgroundColor: selected && open ? '#202736 !important' : 'transparent',
    borderLeft: selected && open ? `4px solid ${theme.palette.primary.main}` : 'none',
    '&:hover svg': {
        filter: 'brightness(1.5)',
    },
}));

const CenteredListItemIcon = styled(ListItemIcon)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 'auto', // To prevent default padding
});

const commonIconStyles = {
    fontSize: '20px', // Smaller font size for the icons
    transition: 'fill 0.3s, filter 0.3s',
};

const labelStyles = (theme) => ({
    mt: 1,
    fontWeight: 'normal',
    color: theme.palette.chatLeftMenuPrimaryText[theme.palette.mode],
    textDecoration: 'none',
    fontFamily: "Poppins, sans-serif",
});

function MiniVariantDrawer({ open, onClose, onSelect, onNewChat, drawerOpen, drawerRef, isTabletMobile, isTablet, selectedTab, leftDrawerOpen }) {
    const theme = useTheme();
    const themeBackgroundColor = theme.palette.chatLeftMenuBackground[theme.palette.mode];
    const themeTextPrimaryColor = theme.palette.chatLeftMenuPrimaryText[theme.palette.mode];
    const themeDividerColor = theme.palette.secondaryDivider[theme.palette.mode];
    const lightGreyColor = '#ffffff';

    const [isBotModalOpen, setBotModalOpen] = useState(false);

    const handleSelect = (tab) => {
        onSelect(tab);
    };

    const handleNewChatClick = () => {
        onNewChat();
        if (isTabletMobile || isTablet) {
            onClose();
        }
    };

    const handleBotClick = () => {
        setBotModalOpen(true);
    };

    const handleBotModalClose = () => {
        setBotModalOpen(false);
    };

    return (
        <>
            <Drawer
                variant="persistent"
                open={open}
                ref={drawerRef}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    overflow: 'hidden',
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        top: '64px',
                        height: 'calc(100% - 64px)',
                        backgroundColor: themeBackgroundColor,
                        color: themeTextPrimaryColor,
                        overflow: 'hidden',
                        borderRight: theme.palette.mode === 'dark' ? '1px solid #333333' : '1px solid #D9D9D9',
                        zIndex: 2500,
                    },
                }}
            >
                <List>
                    <Tooltip title="New Chat" placement="right">
                        <StyledListItem button onClick={handleNewChatClick}>
                            <CenteredListItemIcon>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                        backgroundColor: 'transparent',
                                        borderRadius: '50%',
                                        padding: '4px',
                                        color: themeTextPrimaryColor,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faMessagePlus} style={{ ...commonIconStyles, color: '#00EDED' }} />
                                    <Typography variant="caption" sx={labelStyles(theme)}>New Chat</Typography>
                                </Box>
                            </CenteredListItemIcon>
                        </StyledListItem>
                    </Tooltip>
                    <Divider sx={{ backgroundColor: themeDividerColor }} />
                    <Tooltip title="Conversation History" placement="right">
                        <StyledListItem
                            button
                            onClick={() => handleSelect('history')}
                            selected={selectedTab === 'history'}
                            open={leftDrawerOpen}
                        >
                            <CenteredListItemIcon>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                        backgroundColor: 'transparent',
                                        borderRadius: '50%',
                                        padding: '4px',
                                        color: theme.palette.chatLeftMenuPrimaryText[theme.palette.mode],
                                    }}
                                >
                                    <FontAwesomeIcon icon={faClock} style={{ ...commonIconStyles, color: lightGreyColor }} />
                                    <Typography variant="caption" sx={labelStyles(theme)}>History</Typography>
                                </Box>
                            </CenteredListItemIcon>
                        </StyledListItem>
                    </Tooltip>
                    <Tooltip title="Document List" placement="right">
                        <StyledListItem
                            button
                            onClick={() => handleSelect('files')}
                            selected={selectedTab === 'files'}
                            open={leftDrawerOpen}
                        >
                            <CenteredListItemIcon>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                        backgroundColor: 'transparent',
                                        borderRadius: '50%',
                                        padding: '4px',
                                        color: themeTextPrimaryColor,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFile} style={{ ...commonIconStyles, color: lightGreyColor }} />
                                    <Typography variant="caption" sx={labelStyles(theme)}>Documents</Typography>
                                </Box>
                            </CenteredListItemIcon>
                        </StyledListItem>
                    </Tooltip>
                    {/* Commenting out the Bots menu option for now */}
                    
                    <Tooltip title="Bots" placement="right">
                        <StyledListItem button onClick={handleBotClick}>
                            <CenteredListItemIcon>
                                <Box
                                    display="flex"
                                    flexDirection="column" 
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                        backgroundColor: 'transparent',
                                        borderRadius: '50%',
                                        padding: '4px',
                                        color: themeTextPrimaryColor,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faMessageBot} style={{ ...commonIconStyles, color: lightGreyColor }} />
                                    <Typography variant="caption" sx={labelStyles(theme)}>Bots</Typography>
                                </Box>
                            </CenteredListItemIcon>
                        </StyledListItem>
                    </Tooltip>
                    
                </List>
            </Drawer>
            {/* Commenting out the BotEditModal for now */}
            
            <BotEditModal open={isBotModalOpen} onClose={handleBotModalClose} />
            
        </>
    );
}

export default MiniVariantDrawer;
