import React, { useEffect, useState } from 'react';
import { Button, Box, List, ListItem, ListItemIcon, ListItemText, Avatar, Typography, Divider, useTheme, IconButton } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useThemeContext } from '../../ThemeContext'; // import your theme context
import { faShieldAlt, faFileContract, faMoon, faQuestionCircle, faUserPlus, faExchange, faSignOut, faTimes, faCogs, faUserCog, faFileInvoiceDollar, faCreditCard, faUser, faCog } from '@fortawesome/free-solid-svg-icons';
import { faArrowProgress, faDuck, faHardDrive, faMessageBot } from '@fortawesome/pro-solid-svg-icons';
import { Dialog, DialogTitle, RadioGroup, FormControlLabel, Radio, DialogContent, DialogActions } from '@mui/material';
import { useHubConnections } from '../../utils/HubConnectionsProvider';
import menuConfig from './menuConfig.json';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';

function MobileMenuDrawer({ userAvatar, navigate, onInviteTeamMembersClick, onClose, account }) {
    const { dashboardConnection, isConnectionReady, hitchUser, hitchDashCommand } = useHubConnections();
    const [menuItems, setMenuItems] = useState([]);
    const [themeModalOpen, setThemeModalOpen] = useState(false);
    const location = useLocation();

    const theme = useTheme();
    const { mode, setMode } = useThemeContext();
    const themeBackgroundColor = theme.palette.modalBackground[theme.palette.mode];
    const themeTextSecondaryColor = theme.palette.modalTextSecondary[theme.palette.mode];
    const themeListItemHovered = theme.palette.listItemBackground.hover[theme.palette.mode];
    const themeListItemDivider = theme.palette.listItemDivider[theme.palette.mode];
    const themeModalLink = theme.palette.modalLink[theme.palette.mode];

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on("11ba8c1f-e0a9-4ae7-a601-e8e187243f6d", handleMenu);
            hitchDashCommand('account', 'menu', 'get', { "menuName": "main" });

            return () => {
                dashboardConnection.off("11ba8c1f-e0a9-4ae7-a601-e8e187243f6d", handleMenu);
            };
        }
    }, [dashboardConnection, isConnectionReady]);

    const handleMenu = (data) => {
        if (data.menuType === 'main') {
            setMenuItems(data.items);
        }
    };

    const handleOpenThemeModal = () => setThemeModalOpen(true);
    const handleCloseThemeModal = () => setThemeModalOpen(false);

    const handleModeChange = () => {
        setMode(mode === 'light' ? 'dark' : 'light');
        onClose();
    };

    const handleListItemClick = (action) => {
        handleMenuItemClick(action);
        onClose();
    };

    const handleMenuItemClick = (action) => {
        let targetUrl;
        let subMenuState;

        switch (action) {
            case 'switchOrganizations':
                targetUrl = '/app/account/my-organizations';
                break;
            case 'inviteTeamMember':
                onInviteTeamMembersClick();
                return;
            case 'contactSupport':
                    targetUrl = '/app/account/contact-support';
                    break;
            case 'openTerms':
                window.open("/terms-of-use", "_blank");
                return;
            case 'openPrivacy':
                window.open("/privacy-policy", "_blank");
                return;
            case 'rules':
                targetUrl = '/app/rules';
                break;
            case 'openMenus':
                targetUrl = '/app/menus';
                break;
            case 'openBots':
                targetUrl = '/app/bots';
                break;
            case 'openStorage':
                targetUrl = '/app/storage';
                break;
            case 'manageProfile':
                targetUrl = '/app/account/profile';
                break;
            case 'accountDashboard':
                targetUrl = '/app/account/dashboard';
                break;
            case 'manageOrganization':
                targetUrl = '/app/account/manage-organization';
                break;
            case 'managePlan':
                targetUrl = '/app/account/manage-plan';
                break;
            case 'viewBilling':
                targetUrl = '/app/account/billing';
                break;
            default:
                console.log("Unknown action:", action);
                return;
        }

        if (targetUrl) {
            onClose();
            navigate(targetUrl, { state: { selectedSubMenu: subMenuState } });
        }
    };


    const handleSignOut = () => {
        console.log("Signing out...");
    };

    const hasMatchingRole = (userRoles, itemRoles) => {
        return itemRoles.some(role => userRoles.includes(role));
    };

    const filteredMenuItems = menuItems.filter(item => {
        if (item.roles) {
            return hasMatchingRole(hitchUser.roles, item.roles);
        }
        return true;
    });

    function ThemeSelectionDialog({ open, onClose, currentMode, setMode }) {
        const handleChange = (event) => {
            setMode(event.target.value);
        };

        return (
            <Dialog onClose={onClose} open={open}>
                <DialogTitle>Select Theme Mode</DialogTitle>
                <DialogContent>
                    <RadioGroup value={currentMode} onChange={handleChange}>
                        <FormControlLabel value="light" control={<Radio />} label="Light" />
                        <FormControlLabel value="dark" control={<Radio />} label="Dark" />
                        <FormControlLabel value="pastel" control={<Radio />} label="Pastel" />
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }

    const iconMapper = {
        SwapHorizIcon: <FontAwesomeIcon icon={faExchange} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
        PersonAddIcon: <FontAwesomeIcon icon={faUserPlus} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
        SupportIcon: <FontAwesomeIcon icon={faQuestionCircle} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
        ModeNightIcon: <FontAwesomeIcon icon={faMoon} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
        SignOutIcon: <FontAwesomeIcon icon={faSignOut} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
        SettingsIcon: <FontAwesomeIcon icon={faCogs} style={{ color: themeModalLink, fontSize: '1.1em' }} />,
        TimesIcon: <FontAwesomeIcon icon={faTimes} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
        TermsIcon: <FontAwesomeIcon icon={faFileContract} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
        PrivacyIcon: <FontAwesomeIcon icon={faShieldAlt} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
        MenusIcon: <FontAwesomeIcon icon={faDuck} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
        StorageIcon: <FontAwesomeIcon icon={faHardDrive} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
        BotIcon: <FontAwesomeIcon icon={faMessageBot} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
        WorkflowIcon: <FontAwesomeIcon icon={faArrowProgress} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
        ManageAccountIcon: <FontAwesomeIcon icon={faUserCog} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
        BillingHistoryIcon: <FontAwesomeIcon icon={faFileInvoiceDollar} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
        ManageSubscriptionIcon: <FontAwesomeIcon icon={faCreditCard} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
        ManageProfileIcon: <FontAwesomeIcon icon={faUser} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
        SettingsIcon: <FontAwesomeIcon icon={faCog} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    };

    return (
        <Box
            role="presentation"
            sx={{
                backgroundColor: themeBackgroundColor,
                color: themeTextSecondaryColor,
                height: 'auto', // Allows the box to grow with the content
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box sx={{
                display: 'flex', justifyContent: 'flex-end',
                padding: '8px 8px 0 8px',
            }}>
                <IconButton onClick={onClose}>
                    {iconMapper['TimesIcon']}
                </IconButton>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '0 16px 16px 16px'
            }}>
                <Typography variant='h6' component="div">
                    {account ? account.name : "Loading..."}
                </Typography>
            </Box>
            <Divider sx={{ width: '100%', bgcolor: themeListItemDivider, height: '1px' }} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '16px 16px 16px 16px'
            }}>
                <Avatar
                    alt="User Avatar"
                    src={userAvatar || "/static/images/avatar/default.jpg"}
                    sx={{
                        width: 50,
                        height: 50,
                        backgroundColor: "#FFF",
                        border: "2px solid white",
                    }}
                />
                <Typography variant="body1" sx={{ mt: 2 }}>
                    {hitchUser ? hitchUser.name : "Loading..."}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                    {hitchUser ? hitchUser.email : "Loading..."}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ mt: 1, cursor: 'pointer', color: themeModalLink }}
                    onClick={() => {
                        onClose(); // Close the popover
                        navigate('/app/account/profile'); // Navigate directly to the Manage Profile page
                    }}
                >
                    Manage Profile
                </Typography>
            </Box>
            <Divider sx={{ width: '100%', bgcolor: themeListItemDivider, height: '1px' }} />
            <Box>
                <Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <List style={{ padding: 0 }}>
                            <ListItem button onClick={() => handleListItemClick('accountDashboard')}>
                                <ListItemIcon style={{ minWidth: '30px', marginRight: '8px', transform: 'translateY(2px)' }}>
                                    <DashboardIcon
                                        sx={{
                                            fontSize: '18px',
                                            marginRight: .5,
                                            color: themeModalLink,
                                            verticalAlign: 'baseline',
                                            transform: 'translateY(-2px)' // Slightly raise the icon
                                        }} />
                                </ListItemIcon>
                                <ListItemText sx={{ color: themeModalLink }} primary="My Account" style={{ marginLeft: 0 }} />
                            </ListItem>
                        </List>
                    </Box>
                    <Divider sx={{ width: '100%', bgcolor: themeListItemDivider, height: '1px' }} />
                    {filteredMenuItems.length > 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                px: 2,
                                marginTop: '12px',
                                marginBottom: '0px',
                            }}
                        >
                            <Typography sx={{ color: themeTextSecondaryColor, textAlign: 'left' }}>
                                Quick Actions
                            </Typography>
                        </Box>
                    )}
                </Box>


            </Box>
            <List>
                {filteredMenuItems.map((item, index) => (
                    <ListItem button key={index} onClick={() => handleListItemClick(item.action)}>
                        <ListItemIcon style={{ minWidth: '30px', marginLeft: '8px', marginRight: '8px' }}>
                            {iconMapper[item.icon]}
                        </ListItemIcon>
                        <ListItemText primary={item.label} />
                    </ListItem>
                ))}
                {menuConfig.showDarkModeToggle && hitchUser.roles.includes('HitchAdmin') && (
                    <ListItem button onClick={() => setTimeout(handleOpenThemeModal, 10)}>
                        <ListItemIcon style={{ minWidth: '30px', marginLeft: '8px', marginRight: '8px' }}>
                            <FontAwesomeIcon icon={faMoon} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />
                        </ListItemIcon>
                        <ListItemText primary="Change Theme" />
                    </ListItem>
                )}
                <Divider sx={{ width: '100%', bgcolor: themeListItemDivider, height: '1px', marginTop: filteredMenuItems.length === 0 ? '8px' : 0 }} />
                <ListItem button onClick={handleSignOut}>
                    <ListItemIcon style={{ minWidth: '30px', marginLeft: '8px', marginRight: '8px' }}>
                        {iconMapper['SignOutIcon']}
                    </ListItemIcon>
                    <ListItemText primary="Sign Out" />
                </ListItem>
            </List>
            <ThemeSelectionDialog open={themeModalOpen} onClose={handleCloseThemeModal} currentMode={mode} setMode={setMode} />
        </Box>
    );
}

export default MobileMenuDrawer;
