import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import HitchBox from "../components/hitchbox/HitchBox";
import { useAdminHubConnections } from "../utils/HubAdminConnectionProvider";
import { useEffect, useState } from "react";

function Actor() {
    const { actorId } = useParams();
    const { adminConnection, hitchUser } = useAdminHubConnections();
    const [actorRegistry, setActorRegistry] = useState({});
    const [actorDetails, setActorDetails] = useState({});
  
    useEffect(() => {
      if (adminConnection && hitchUser?.userId && actorId) {
        const subscribeToEvents = () => { 
          adminConnection.on('ActorRegistry', handleActorRegistry);
          adminConnection.on('ActorDetails', handleActorDetails);  
        };
        subscribeToEvents();
        
        adminConnection
          .invoke('GetActor', hitchUser?.userId, actorId)
          .catch((error) => console.error('Error fetching actor:', error));
  
        return () => {
          adminConnection.off('ActorRegistry', handleActorRegistry);
          adminConnection.off('ActorDetails', handleActorDetails);
        };
      }
    }, [adminConnection, hitchUser?.userId, actorId]);
  
    function handleActorRegistry(data) {
        console.log('handleActorRegistry', data);
    }

    function handleActorDetails(data) {
        console.log('handleActorDetails', data);
    }

    // OUTBOUND SIGNAL R: GetActor
    // INBOUND SIGNAL R: ActorRegistry
    // INBOUND SIGNAL R: ActorDetails

    return (
        <HitchBox backgroundColor='#003333' color='#FFFFFF'>
            <Typography variant="h6">Actor: {actorId}</Typography>
        </HitchBox>
    )
};

export default Actor;