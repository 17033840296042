import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import QuickSearchToolbar from "./QuickSearchToolbar";
import { useState, useEffect } from "react";
import HitchBox from "../../components/hitchbox/HitchBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/pro-solid-svg-icons";

function ActorRegistry({ debugConnection, nodeData }) {
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState(nodeData?.registration ?? []);
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuRow, setMenuRow] = useState(null);

    useEffect(() => {
        setRows(nodeData?.registration ?? []);
    }, [nodeData]);

    useEffect(() => {
        if (debugConnection) {
            // connection.on("KingEntry", handleKingEntry);
            // connection.on("DropKing", handleDropKing);

            return () => {
                // connection.off("KingEntry", handleKingEntry);
                // connection.off("DropKing", handleDropKing);
            };
        }
    }, [debugConnection]);

    const handleSearch = (event) => {
        const searchValue = event.target.value;
        setSearchText(searchValue);

        const filteredRows = nodeData?.registration.filter((row) => {
            return row.actorId.toLowerCase().includes(searchValue.toLowerCase());
        });

        setRows(filteredRows);
    };

    const handleMenuOpen = (event, row) => {
        setAnchorEl(event.currentTarget);
        setMenuRow(row);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuRow(null);
    };

    const handleMenuClick = (option) => {
        if (menuRow) {
            switch (option) {
                case 'Schedule':
                    schedule(menuRow.actorId);
                    break;
                case 'Take Offline':
                    takeOffline(menuRow.actorId);
                    break;
                case 'Ping':
                    ping(menuRow.actorId);
                    break;
                case 'Propagate':
                    propagate(menuRow.actorId);
                    break;
                default:
                    break;
            }
        }
        handleMenuClose();
    };

    const schedule = (actorId, nodeName) => {
        // console.log(`Scheduling ${actorId} to ${nodeName}`);
        debugConnection
            .invoke("UiSchedule", actorId, nodeName)
            .then(() => {
            // console.log(`Actor ${actorId} has been scheduled on ${nodeName}.`);
            })
            .catch((error) => console.error("Error sending message:", error));
    };

    const takeOffline = (actorId) => {
        // console.log(`Taking offline ${actorId}`);
        debugConnection
            .invoke("UiTakeOffline", actorId)
            .then(() => {
            // console.log(`Actor ${actorId} has been requested to go offline.`);
            })
            .catch((error) => console.error("Error sending message:", error));
    };

    const ping = (actorId) => {
        // console.log(`Pinging ${actorId}`);
        debugConnection
            .invoke("UiPing", actorId)
            .then(() => {
            // console.log(`Actor ${actorId} has been pinged.`);
            })
            .catch((error) => console.error("Error sending message:", error));
    };

    const propagate = (actorId) => {
        // console.log(`Propagating ${actorId}`);
        debugConnection
            .invoke("UiPropagate", actorId)
            .then(() => {
            // console.log(`Actor ${actorId} has been propagated.`);
            })
            .catch((error) => console.error("Error sending message:", error));
    };

    const columns = [
        {
            field: 'actorId',
            headerName: 'Actor Id',
            width: 350,
            editable: false,
            filterable: true
        },
        {
            field: 'nodeName',
            headerName: 'Node',
            width: 200,
            editable: false,
            filterable: true
        },
        {
            field: 'actorAddress',
            headerName: 'Address',
            width: 300,
            editable: false,
            filterable: true
        },
        {
            field: 'role',
            headerName: 'Role',
            width: 200,
            editable: false,
            filterable: true
        },
        {
            field: 'actions',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(event) => handleMenuOpen(event, params.row)}
                >
                    <FontAwesomeIcon icon={faGear} style={{ color: '#ffffff' }} />
                </IconButton>
            )
        }
    ];

    return (
        <HitchBox sx={{ height: 800, width: '100%' }} backgroundColor="#333">
            <DataGrid
                rows={rows}  // Use the filtered rows state here
                columns={columns}
                getRowId={(row) => row.actorId}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10
                        }
                    }
                }}
                pageSizeOptions={[10, 25, 100]}
                checkboxSelection
                disableRowSelectionOnClick
                slots={{
                    toolbar: QuickSearchToolbar,
                }}
                slotProps={{
                    toolbar: {
                        value: searchText,
                        onChange: handleSearch,
                    },
                }}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}>
                <MenuItem onClick={() => handleMenuClick('Schedule')}>Schedule</MenuItem>
                <MenuItem onClick={() => handleMenuClick('Take Offline')}>Take Offline</MenuItem>
                <MenuItem onClick={() => handleMenuClick('Ping')}>Ping</MenuItem>
                <MenuItem onClick={() => handleMenuClick('Propagate')}>Propagate</MenuItem>
            </Menu>
        </HitchBox>
    )
}

export default ActorRegistry;
