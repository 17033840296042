import { Box, Button, Modal, Typography } from "@mui/material";
import PromptOperationStepper from "./PromptOperationStepper";
import { useEffect, useState } from "react";
import SelectService from "./SelectService";
import SelectFeature from "./SelectFeature";
import SelectOperation from "./SelectOperation";
import Payload from "./Payload";
import FinalizePromptOperation from "./FinalizePromptOperation";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const steps = ['System', 'Feature', 'Operation', 'Payload'];
const descriptions = ['The system of record for this step.', 'What feature will the be using.', 'What operation on that feature?', 'The payload for the operation', 'Let\'s test this'];

function AddStepModal({ handleCloseModal, hubConnection, openStepper, sessionId, savePromptOperation, reset, setReset }) {
    const [activeStep, setActiveStep] = useState(0);
    const [availableServices, setAvailableServices] = useState([]);
    const [availableFeatures, setAvailableFeatures] = useState([]);
    const [availableOperations, setAvailableOperations] = useState([]);
    const [payload, setPayload] = useState(null);
    const [selectedServiceId, setSelectedServiceId] = useState(null);
    const [payloadResponse, setPayloadResponse] = useState(null);

    useEffect(() => {
        if(reset) {
            setActiveStep(0);
            setAvailableServices([]);
            setAvailableFeatures([]);
            setAvailableOperations([]);
            setPayload(null);
            setSelectedServiceId(null);
            setPayloadResponse(null);
            setReset(false);
        }
    }, [reset])

    useEffect(() => {
        if (hubConnection && openStepper) { // Check the loading state
            hubConnection
                .invoke('GetSystems', sessionId)
                .then(() => {
                    console.log('Get Systems AWAY!');
                })
                .catch((error) => console.error('Error sending message:', error));
        }
    }, [hubConnection, openStepper]); // Include loading in the dependency array



    useEffect(() => {
        console.log('hubConnection', hubConnection);
        if (hubConnection) {
            const subscribeToEvents = () => {
                hubConnection.on('PromptState', (data) => {
                    handlePromptState(data);
                });
            };

            subscribeToEvents();
            return () => {
                hubConnection.off('PromptState');
                // Unsubscribe from other events here
            };
        }
    }, [hubConnection]);

    function setPayloadToSignalR(data) {
        if(hubConnection && payload) { // Check the loading state
            hubConnection
                .invoke('SetPayloadDefinition', sessionId, data)
                .then(() => {
                    
                })
                .catch((error) => console.error('Error sending message:', error));
        }
    }

    function handleSave() {
        console.log('handleSave called');
        savePromptOperation(payload);
    }


    const isStepOptional = (step) => {
        return false;
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    // GetSystems

    const handleReset = () => {
        setActiveStep(0);
    };

    function handlePromptState(data) {
        console.log('PromptState', data);
        setAvailableServices(data.services);
        setAvailableFeatures(data.features);
        setAvailableOperations(data.featureOperations);
        setSelectedServiceId(data.selectedSystem);
        setPayload(data.contentForUi);
        console.log('data?.payloadResponse', data?.payloadResponse);
        setPayloadResponse(data.contentResponse);
    }

    function submitServiceId(serviceId) {
        if (hubConnection) {
            // 1. Set System to this service.

            // 2. Update Back end.
            hubConnection
                .invoke('SetTheSystem', sessionId, serviceId)
                .then(() => {

                    // 3. Call for Features.
                    hubConnection
                        .invoke('GetFeatures', sessionId)
                        .then(() => {
                            // 4. Advance Stepper.
                            handleNext();
                        })
                        .catch((error) => console.error('Error sending message:', error));

                })
                .catch((error) => console.error('Error sending message:', error));
        }
    }

    function submitFeatureId(featureId) {
        if (hubConnection) {
            // 1. Set System to this service.

            // 2. Update Back end.
            hubConnection
                .invoke('SetTheFeature', sessionId, featureId)
                .then(() => {

                    // 3. Call for Features.
                    hubConnection
                        .invoke('GetOperations', sessionId)
                        .then(() => {
                            // 4. Advance Stepper.
                            handleNext();
                        })
                        .catch((error) => console.error('Error sending message:', error));

                })
                .catch((error) => console.error('Error sending message:', error));
        }
    }

    function submitOperationId(operationId) {
        if (hubConnection) {
            // 1. Set System to this service.

            // 2. Update Back end.
            hubConnection
                .invoke('SetTheOperation', sessionId, operationId)
                .then(() => {

                    // 3. Call for Features.
                    hubConnection
                        .invoke('GetPayloadDefinition', sessionId)
                        .then(() => {
                            // 4. Advance Stepper.
                            handleNext();
                        })
                        .catch((error) => console.error('Error sending message:', error));

                })
                .catch((error) => console.error('Error sending message:', error));
        }
    }

    function runTest() {
        if (hubConnection) {
            hubConnection
                .invoke('TestThePromptOperation', sessionId)
                .then(() => {
                })
                .catch((error) => console.error('Error sending message:', error));
        }
    }

    return (
        <Modal
            open={openStepper}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <PromptOperationStepper
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    steps={steps}
                />
                {activeStep === steps.length && (

                    <>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - Let's review...
                        </Typography>

                        <FinalizePromptOperation
                            selectedServiceId={selectedServiceId}
                            payload={payload}
                            runTest={runTest}
                            payloadResponse={payloadResponse} />

                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button color="success" onClick={handleSave}>Save</Button>
                            <Button color="error" onClick={handleReset}>Reset</Button>
                        </Box>
                    </>
                )}
                {activeStep !== steps.length && (
                    <>
                        <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>

                        {/* Steps Here */}
                        {activeStep === 0 && (<SelectService availableServices={availableServices} submitServiceId={submitServiceId} />)}
                        {activeStep === 1 && (<SelectFeature availableFeatures={availableFeatures} submitFeatureId={submitFeatureId} />)}
                        {activeStep === 2 && (<SelectOperation availableOperations={availableOperations} submitOperationId={submitOperationId} />)}
                        {activeStep === 3 && (<Payload payload={payload} setPayload={setPayloadToSignalR} />)}
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                    Skip
                                </Button>
                            )}

                            <Button onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Box>
                    </>
                )}
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {steps[activeStep]}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {descriptions[activeStep]}
                </Typography>
            </Box>
        </Modal>
    )
}

export default AddStepModal;