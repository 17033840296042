import React from 'react';
import './NavigationBar.css'; // Create this CSS file for styling
import HitchLogo from './HitchSoftwareLogo.svg';
import ErrorBoundary from '../../utils/ErrorBoundary';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Box, Menu, MenuItem, styled, Modal, Backdrop } from '@mui/material';
import { Toolbar } from '@mui/material';
import { Typography } from '@mui/material';
import { Avatar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import ContactModal from '../contact/ContactModal'; // Import ContactModal
import ContactForm from '../contact/ContactForm'; // Import ContactForm
import { Button } from '@mui/material';

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: "black",
  height: "64px",
});

const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  gap: "20px",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  // Add margin-right to create space between items
  "& > *:not(:last-child)": {
    marginRight: "20px",
  },
  // Add margin-right to the Avatar component
  "& .avatar": {
    marginRight: "16px", // Adjust the value as needed
  },
}));


const UserBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    display: "none",
  }
}));

const theme = createTheme();

function NavigationBar() {
  //const [open, setOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const open = Boolean(menuAnchorEl); // Menu open state based on whether anchorEl is set

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget); // Set the anchor element
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null); // Clear the anchor element
  };
  const navigateToContactUs = () => {
    window.open('/letstalk', '_blank'); // Opens in a new tab
  };

  const indiegogoUrl = "https://igg.me/at/hitch-ai"; // Replace with your actual campaign link

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <StyledToolbar>
          <Box className="logo-container">
            <ErrorBoundary marker={'Link To Hitch Software Home'} fallback={<p>***ZING!***</p>}>
              <Link to="/" className="logo" sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={HitchLogo} alt="Logo" className="logo-image" sx={{ marginRight: 1 }} />

              </Link>
            </ErrorBoundary>
          </Box>
          <Icons>
            <ErrorBoundary marker={'Link To Test'} fallback={<p>The Lets Talk Link</p>}>
              <Typography
                variant="h6"
                sx={{ fontFamily: 'Poppins, sans-serif', fontSize: '1.1rem', marginBottom: -.75 }}
                onClick={navigateToContactUs}
                style={{ cursor: 'pointer' }}
              >
                Contact Us
              </Typography>
            </ErrorBoundary>

            
            <ErrorBoundary
              marker={'Link To Platforms'}
              fallback={<p>Take Me To Platforms</p>}
            >

              <Link to="/app">
                <Avatar className="avatar"
                  sx={{ width: "30px", height: "30px" }}
                >
                  <PersonIcon />
                </Avatar>
              </Link>
            </ErrorBoundary>
          </Icons>
          <UserBox onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
            <Avatar
              sx={{ width: "40px", height: "40px", background: "transparent" }}>
              <MenuIcon />
            </Avatar>
          </UserBox>
        </StyledToolbar>
        <Menu
          id="demo-positioned-menu"
          anchorEl={menuAnchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              backgroundColor: '#0D0D0D',
              width: '224px',
            },
          }}
        >
          {/*<MenuItem >Features</MenuItem>
        <MenuItem >Pricing</MenuItem>
        <MenuItem >Careers</MenuItem>*/}
          <MenuItem>
            <Typography
              variant="h6"
              sx={{
                color: 'white',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '1.1rem',
                marginBottom: -.75
              }}
              onClick={navigateToContactUs}
              style={{ cursor: 'pointer' }}
            >
              Contact Us
            </Typography>

          </MenuItem>
          

          <MenuItem>
            <ErrorBoundary
              marker={'Link To Platforms'}
              fallback={<p>Take Me To Platforms</p>}
            >
              <Link
                to="/app" // Make sure the "to" prop is set to the correct URL
                style={{
                  textDecoration: 'none',
                  color: 'white',
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: '1.1rem',
                  display: 'block',
                  marginBottom: -0.75, // You might want to use a positive value
                  cursor: 'pointer',
                }}
              >
                Sign In
              </Link>
            </ErrorBoundary>
          </MenuItem>
        </Menu>


      </AppBar>
    </ThemeProvider>
  );
}

export default NavigationBar;
