import { Grid, Typography } from "@mui/material";
import * as signalR from '@microsoft/signalr';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import isValidGUID from "../../utils/IsValidGuid";

function Debug({ user, isAuthenticated, hitchUser, jwtToken }) {
    const [start, setStart] = useState(false);
    const [hubConnection, setHubConnection] = useState(null);
    const [sessionId, setSessionId] = useState('');
    const { guid } = useParams();
    const history = useNavigate();

    useEffect(() => {
        let isMounted = true; // Track if the component is mounted
        const startConnection = async () => {
            if (jwtToken && start) {
                // console.log(`attempting to connect to debug hub session ${sessionId}`);
                const connection = new signalR.HubConnectionBuilder()
                    .withUrl(`${window._env_.HITCH_API}/debugHub?sessionId=${sessionId}`, {
                        accessTokenFactory: () => jwtToken
                    })
                    .withAutomaticReconnect()
                    .build();

                try {
                    await connection.start();
                    // console.log('signalR connected');
                    connection.on("DebugLine", handleDebugLine);
                    // Set connection only if component is still mounted
                    if (isMounted) {
                        //setStart(false);
                        setHubConnection(connection);
                        setUpSubscriptions(connection);

                    }
                } catch (error) {
                    console.error('Dashboard Hub connection error:', error);
                    // Handle error
                }
            }
        };
        startConnection();
        return () => {
            isMounted = false; // Set it to false on unmount
            if (hubConnection) {
                if (hubConnection) {
                    hubConnection.off("DebugLine", handleDebugLine);
                    hubConnection.stop();
                }
            }
        };
    }, [jwtToken, start]);

    useEffect(() => {
        if (user && isAuthenticated && jwtToken && !hubConnection) {
            // console.log('set start = true');
            setStart(true);
        }
        else {
            // console.log('set start = false');
            if (user) {
                // console.log('user is present');
            } else {
                // console.log('user is not present');
            }

            if (isAuthenticated) {
                // console.log('isAuthenticated == true');
            } else {
                // console.log('isAuthenticated == false');
            }

            if (jwtToken) {
                // console.log('jwtToken is present');
            } else {
                // console.log('jwtToken is not present');
            }

            if (hubConnection) {
                // console.log('hubConnection is present');
            } else {
                // console.log('hubConnection is not present');
            }
            setStart(false);
        }
    }, [user, isAuthenticated, jwtToken, hubConnection]);

    useEffect(() => {
        if (guid && isValidGUID(guid)) {
            setSessionId(guid);
        } else {
            const newGuid = uuidv4();
            history(`/admin/debug/${newGuid}`);
            setSessionId(newGuid);
        }
    }, [guid]);

    function setUpSubscriptions(connection) {
        if (connection) {
            connection.on("Ancestry", handleAncestry);
        }
    }
    // GetAncestry

    function getAncestry(onSuccess, onError) {
        if (hubConnection) {
            hubConnection.invoke("GetAncestry", sessionId)
                .then(() => {
                    if (onSuccess && typeof onSuccess === 'function') {
                        onSuccess();
                    }
                })
                .catch(error => {
                    if (onError && typeof onError === 'function') {
                        onError(error);
                    } else {
                        errorHandler(error);
                    }
                });
        }
    }

    function requestAncestry() {
        getAncestry(() => {
            // User-defined success function
            // setLoading(true);
            // console.log('Ancestry requested successfully');
        });
    }

    function errorHandler(error) {
        console.error("SignalR Error", error);
    }

    function handleAncestry(data) {
        // console.log('ancestry', data);
    }

    function handleDebugLine(line) {
        // console.log(line);
    }

    return (
        <Grid
            container
            spacing={2}
            mt={1}
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"} // Add this line to center horizontally
            style={{ textAlign: "center" }}
        >
            <Grid item xs={12}>
                <Typography variant="h3">Debugging Hub</Typography>
            </Grid>

        </Grid>
    );

}

export default Debug;