import { Box, Button, ButtonGroup, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";


function QuickbooksQuery({ columns, entity, entities, setEntity, setQuery, query, reset, rows, runQuery, handleChange }) {

    return (
        <Grid className="qb_top_row" container spacing={2}>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel className="selectEntityLabel" id="quickbooks-entity-label" variant="filled">Quickbookds Entity</InputLabel>
                    <Select
                        labelId="quickbooks-entity-label"
                        id="quickbooks-entity-select"
                        variant="filled"
                        value={entity}
                        label="Entity"
                        className="selectEntity"
                        onChange={handleChange}
                    >
                        {entities.map((entityItem) => (
                            <MenuItem key={entityItem.name} value={entityItem.name}>
                                {entityItem.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="outlined-multiline-static"
                    label="Query"
                    multiline
                    variant="filled"
                    className="code_editor"
                    fullWidth
                    rows={2}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
                <Grid container justifyContent="flex-end">
                    <ButtonGroup>
                        <Button variant="contained" color="success" className="selectEntityLabel" onClick={runQuery}>Run</Button>
                        <Button variant="contained" color="error" className="selectEntityLabel" onClick={reset}>Reset</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        className="hitchGrid"
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </Box>
            </Grid>
        </Grid>

    )
}

export default QuickbooksQuery;
