import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, IconButton, Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useAdminHubConnections } from '../../utils/HubAdminConnectionProvider';
import { useHubConnections } from '../../utils/HubConnectionsProvider';

function UserLeadRecords({ user, editMode, loading, setLoading }) {
    const { adminConnection, hitchUser, isConnectionReady } = useAdminHubConnections();
    const {         
        defaultSnack,
        infoSnack,
        warningSnack,
        successSnack,
        errorSnack, 
    } = useHubConnections();

    useEffect(() => {
        if (adminConnection && isConnectionReady) {
            adminConnection.on('Leads', (data) => {
                console.log('Leads', data);
                setLeads(data);
                setLoading(false);
            });

            getLeads();
            return () => {
                adminConnection.off('Leads');
            };
        }
    }, [adminConnection, isConnectionReady]);

    function getLeads() {
        adminConnection.invoke("GetUserLeadRecords", hitchUser.userId, user.id)
            .then(() => {
                console.log('i asked for userRoles');
                setLoading(true);
            })
            .catch(error => console.error("Error sending message:", error));
    }


    const [expanded, setExpanded] = useState({});
    const [leads, setLeads] = useState([]);

    useEffect(() => {
        // Use static dummy data for now
        const dummyLeads = [
            {
                id: "1",
                name: "Lead One",
                created: "2024-01-01",
                createdBy: "admin",
                updated: "2024-01-02",
                updatedBy: "admin",
                accountId: "12345",
                firstName: "Alice",
                lastName: "Smith",
                email: "alice@example.com",
                phoneNumber: "987-654-3210",
                identityServerUserId: "lead1",
                userId: "user1",
                leadSource: "Website",
                leadSourceFileName: "source1.csv",
                leadStatus: "New",
                doNotMarket: false,
                doNotMarketDate: null,
                clickFunnelsContactId: "cf1",
                organizationName: "Org One"
            },
            {
                id: "2",
                name: "Lead Two",
                created: "2024-02-01",
                createdBy: "admin",
                updated: "2024-02-02",
                updatedBy: "admin",
                accountId: "67890",
                firstName: "Bob",
                lastName: "Jones",
                email: "bob@example.com",
                phoneNumber: "123-456-7890",
                identityServerUserId: "lead2",
                userId: "user2",
                leadSource: "Referral",
                leadSourceFileName: "source2.csv",
                leadStatus: "Contacted",
                doNotMarket: true,
                doNotMarketDate: "2024-02-03",
                clickFunnelsContactId: "cf2",
                organizationName: "Org Two"
            }
        ];
        setLeads(dummyLeads);
    }, []);

    const handleExpandClick = (id) => {
        setExpanded((prevExpanded) => ({
            ...prevExpanded,
            [id]: !prevExpanded[id]
        }));
    };

    return (
        <Box>
            {leads.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Updated</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Lead Status</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {leads.map((lead) => (
                                <React.Fragment key={lead.id}>
                                    <TableRow>
                                        <TableCell sx={{ padding: '4px 8px' }}>{lead.id}</TableCell>
                                        <TableCell sx={{ padding: '4px 8px' }}>{lead.created}</TableCell>
                                        <TableCell sx={{ padding: '4px 8px' }}>{lead.updated}</TableCell>
                                        <TableCell sx={{ padding: '4px 8px' }}>{lead.email}</TableCell>
                                        <TableCell sx={{ padding: '4px 8px' }}>{lead.leadStatus}</TableCell>
                                        <TableCell sx={{ padding: '4px 8px' }}>
                                            <IconButton
                                                onClick={() => handleExpandClick(lead.id)}
                                                aria-expanded={expanded[lead.id]}
                                                aria-label="show more"
                                            >
                                                {expanded[lead.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ padding: 0 }} colSpan={6}>
                                            <Collapse in={expanded[lead.id]} timeout="auto" unmountOnExit>
                                            <Box margin={0} sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 1 }}>
                                                    <Grid container spacing={2}>
                                                        {Object.keys(lead).map((key) => (
                                                            !['id', 'created', 'updated', 'email', 'leadStatus'].includes(key) && (
                                                                <Grid item xs={12} sm={6} key={key}>
                                                                    <Typography variant="body2"><strong>{key}:</strong> {lead[key]}</Typography>
                                                                </Grid>
                                                            )
                                                        ))}
                                                    </Grid>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>No Records to Display</Typography>
            )}
        </Box>
    );
}

export default UserLeadRecords;
