import { UserManager, WebStorageStateStore, Log } from 'oidc-client';

const settings = {
  authority: window._env_.REACT_APP_OIDC_AUTHORITY,
  client_id: window._env_.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: `${window.location.origin}/callback`,
  response_type: 'code',
  scope: 'openid profile matrix offline_access',
  post_logout_redirect_uri: window.location.origin,
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  monitorSession: false,
  automaticSilentRenew: true,
  checkSessionInterval: 2000,
  includeIdTokenInSilentRenew: true
};

Log.logger = console;
Log.level = Log.ERROR;

const userManager = new UserManager(settings);

userManager.events.addUserLoaded((user) => {
  // console.log('User loaded:', user);
});

userManager.events.addSilentRenewError((error) => {
  // console.error('Silent renew error:', error);
});

userManager.events.addAccessTokenExpired(() => {
  // console.warn('Access token expired');
});

export default userManager;
