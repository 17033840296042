import { Stack, Typography, Box } from '@mui/material';
import React from 'react';
import './askonthego.css';

function AskOnTheGo()  {
    
    return (
        <Box 
            bgcolor="#00c2c2"//#00BDBD"
        >
           
            <Stack 
                direction={{ xs: 'column', md: 'row' }}
                spacing={2}
                justifyContent={{ xs: 'flex-start', sm: 'space-evenly' }}
                sx={{ marginX: { xs: 1, sm: 4, md: 13 } }}
            >
                <Box 
                    //bgcolor="blue" 
                    flex={6}  
                    alignItems="center" 
                    textAlign="left"
                    sx={{ paddingLeft: { xs: 1, sm: 2, md: 1 },
                    paddingRight: { xs: 1, sm: 2, md: 12 },
                    display: 'flex',
                 }}
                >
                    <Box //bgcolor="green"
                    >
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: '600', // Bold
                            fontSize: { xs: '32px', sm: '48px', md: '48px' }, // Adjusted font size
                            marginTop: { xs: '56px', sm: '88px', md: '0px' }, // Adjusted top margin
                            marginBottom: { xs: '16px', sm: '16px', md: '16px' }, // Adjusted top margin
                            color:"#FFFFFF" ,
                            letterSpacing: '-1.5px', // Adjust the value based on your preference
                            lineHeight: 1.15, // Adjusted line height
                        }}
                    >
                        Ask questions on the go with our web app.
                    </Typography>
                        <Typography 
                            variant="subtitle1"
                            sx={{
                                fontSize: { xs: '18px', sm: '28px', md: '27px' }, // Adjusted font size
                                fontFamily: 'Inter, sans-serif',
                                //marginBottom: { xs: '32px', sm: '32px', md: '180px' }, // Adjusted top margin
                                color:"#FFFFFF" ,
                                letterSpacing: '1.15px', // Adjust the value based on your preference
                                lineHeight: 1.32, // Adjusted line height
                                marginBottom: { xs: '24px', sm: '32px', md: '0px' }, // Adjusted top margin
                            }}
                        >
                            No need to download an app.  Simply save the website as a shortcut on your mobile device and access it anytime.
                        </Typography>
                        
                    </Box>
                </Box>


                <Box 
                   // bgcolor="orange" 
                    flex={{ xs: 12, md: 6 }}
                    display="flex"         /* establish flex container */
                    flexDirection="column"  /* make main axis vertical */
                    justifyContent="center" /* center items vertically, in this case */
                    alignItems="center"    /* center items horizontally, in this case */
                    >
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={1}
                        sx={{ marginX: { xs: 0, sm: 4, md: 0 } }}
                        >
                        <Box
                        
                        flex={{ xs: 12, md: 6 }}
                        textAlign="center"
                        //className=".mobile-image-go"
                        //bgcolor="steelblue"
                       // width={{ xs: '298px', sm: '320px', md: '340px' }}
                        height={{ xs: '622px', sm: '640px', md: '625px' }}
                        //marginTop={{ xs: '0px', sm: '0px', md: '88px' }}
                        //marginBottom= {{ xs: '80px', sm: '80px', md: '74px' }} // Adjusted top margin
                        //borderRadius={'30px'}
                        //sx={{ margin: 'auto' }}
                    >
                        <img
                        src={'/img/MobileOnTheGo1.svg'}
                        alt="Mobile On The Go 1"
                        />
                    </Box>
                        <Box
                        
                            flex={{ xs: 12, md: 6 }}
                            textAlign="center"
                            //className=".mobile-image-go"
                           // bgcolor="steelblue"
                           // width={{ xs: '298px', sm: '320px', md: '340px' }}
                            height={{ xs: '622px', sm: '640px', md: '625px' }}
                            //paddingTop={{ xs: '0px', sm: '0px', md: '30%' }}
                            //paddingBottom={{ xs: '0px', sm: '0px', md: '0px' }}
                            //marginBottom= {{ xs: '80px', sm: '80px', md: '74px' }} // Adjusted top margin
                            //borderRadius={'30px'}
                            //sx={{ margin: 'auto' }}
                            display="flex"
                            alignItems="flex-end"
                        >
                            <img
                            src={'/img/MobileOnTheGo2.svg'}
                            alt="Mobile On The Go 2"
                            />
                        </Box>
                </Stack>
                </Box>
            </Stack>
        </Box>
    );
}

export default AskOnTheGo;