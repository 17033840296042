import { Typography, List, ListItem, ListItemAvatar, ListItemText, Avatar, Box } from "@mui/material";
import HitchBox from "../../components/hitchbox/HitchBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from 'react';
import { useHubConnections } from "../../utils/HubConnectionsProvider";
import { useParams } from "react-router-dom";

function SourceSelector({ guid, setSelectedSource }) {
    const { dashboardConnection, hitchDashCommand, isConnectionReady, defaultSnack, infoSnack } = useHubConnections();
    const [connectedSources, setConnectedSources] = useState([]);

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on("d9ebd6e0-4d16-4a03-8e4d-1d1056385562", handleSources);
            hitchDashCommand('document', 'repo-source', 'list', { RepositoryId: guid }, () => {});
        }

        return () => {
            if (dashboardConnection) {
                dashboardConnection.off("d9ebd6e0-4d16-4a03-8e4d-1d1056385562", handleSources);
            }
        };
    }, [dashboardConnection, isConnectionReady, hitchDashCommand]);

    function handleSources(data) {
        setConnectedSources(data);
    }

    const handleSourceClick = (source) => {
        // console.log("Source clicked:", source);
        setSelectedSource(source);
    };

    const connectNewSource = () => {
        setSelectedSource('new');
    };

    return (
        <HitchBox backgroundColor='#004444' color='#FFFF00'>
            <Box maxHeight={200} overflow="auto">
                <List>
                    <ListItem button onClick={connectNewSource}>
                        <ListItemAvatar>
                            <Avatar>
                                <FontAwesomeIcon icon={faPlus} />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Connect Source" />
                    </ListItem>
                    {connectedSources.map((source) => (
                        <ListItem button key={source.id} onClick={() => handleSourceClick(source)}>
                            <ListItemAvatar>
                                <Avatar src={`${window._env_.HITCH_API}/api/accimage/${source.serviceId}.png`} />
                            </ListItemAvatar>
                            <ListItemText primary={source.name} />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </HitchBox>
    );
}

export default SourceSelector;
