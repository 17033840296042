import React, { useState, useEffect } from 'react';
import { Button, MenuItem, Select, InputLabel, FormControl, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useHubConnections } from '../../utils/HubConnectionsProvider';

function OperationSelectionModel({ open, setOpen, selected }) {
  const { dashboardConnection, isConnectionReady, hitchDashCommand } = useHubConnections();

  const [matrixRoutes, setMatrixRoutes] = useState([]);
  const [service, setService] = useState('');
  const [feature, setFeature] = useState('');
  const [operation, setOperation] = useState('');

  useEffect(() => {
    if (dashboardConnection && isConnectionReady) {
      dashboardConnection.on("72d23de7-efb6-45c1-b67a-e06bd00d4739", setMatrixRoutes);
      callForRoutes();
    }

    return () => {
      if (dashboardConnection) {
        dashboardConnection.off("72d23de7-efb6-45c1-b67a-e06bd00d4739");
      }
    };
  }, [dashboardConnection, isConnectionReady]);

  useEffect(() => {
    if (service) {
      callForRoutesService(service);
    } else {
      callForRoutes();
    }
  }, [service]);

  useEffect(() => {
    if (feature) {
      callForRoutesFeature(feature);
    } else {
      if(service) {
        callForRoutesService(service);
      } else {
        callForRoutes();
      }
    }
  }, [feature]);

  function callForRoutes() {
    hitchDashCommand('account', 'connection', 'route');
  }

  function callForRoutesService(serviceId) {
    // Find the route for the given serviceId
    const route = matrixRoutes.find(route => route.system === serviceId);

    if (route) {
      hitchDashCommand('account', 'connection', 'route', {
        TheServiceId: route.serviceId,
      });
    } else {
      console.error('No matching route found for serviceId:', serviceId);
    }
  }


  function callForRoutesFeature(featureId) {
    const route = matrixRoutes.find(route => route.operationType === featureId);

    if(route) {
      hitchDashCommand('account', 'connection', 'route', {
        TheFeatureId: route.featureId
      });
    }
    
    
  }

  const handleClose = () => setOpen(false);
  const handleAdd = () => {
    selected(service, feature, operation);
    setOpen(false); // Close modal after add
  };

  // Dynamically generate dropdown options
  const serviceOptions = Array.from(new Set(matrixRoutes.map(item => item.system))).map(system => ({ label: system, value: system }));
  const featureOptions = service ? Array.from(new Set(matrixRoutes.filter(item => item.system === service).map(item => item.operationType))) : [];
  const operationOptions = feature ? Array.from(new Set(matrixRoutes.filter(item => item.system === service && item.operationType === feature).map(item => item.operationSubtype))) : [];

  return (
    <div>
      {/* <Button variant="outlined" onClick={() => setOpen(true)}>Open Modal</Button> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Item</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Service</InputLabel>
            <Select value={service} label="Service" onChange={(e) => { setService(e.target.value); setFeature(''); setOperation(''); }}>
              <MenuItem value="">Not Selected</MenuItem>
              {serviceOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" disabled={!service}>
            <InputLabel>Feature</InputLabel>
            <Select value={feature} label="Feature" onChange={(e) => { setFeature(e.target.value); setOperation(''); }}>
              <MenuItem value="">Not Selected</MenuItem>
              {featureOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" disabled={!feature}>
            <InputLabel>Operation</InputLabel>
            <Select value={operation} label="Operation" onChange={(e) => setOperation(e.target.value)}>
              <MenuItem value="">Not Selected</MenuItem>
              {operationOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAdd} disabled={!service || !feature || !operation}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default OperationSelectionModel;
