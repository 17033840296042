import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import SendIcon from '@mui/icons-material/Send';
import './AddPlan.css';

function PlanSelector({ plansData, selectedPlanId, setSelectedPlanId, handleAccessCode, handleSelectLockedBox, handleButtonClick, handleAccessCodeChange, accessCodeInputRef, isAccessCodeActive, accessCode, formatRate, billingPeriod, buttonStyle, arrowIconStyle, openDialog }) {
    useEffect(() => {
        console.info('plansData', plansData);
    }, [plansData]);

    const renderSubtitle = (subtitle) => {
        if (!subtitle) return null;

        const [boldPart, regularPart] = subtitle.split(':');
        return (
            <p className="not-locked-subtitle">
                <span className="subtitle-bold">{boldPart}:</span>
                <span className="subtitle-regular">{regularPart}</span>
            </p>
        );
    };

    return (
        <div className="plan-boxes-container-subscribe">
            {plansData?.map((plan) => (
                <div key={plan.id} className={`plan-box-container ${selectedPlanId === plan.id ? '' : 'non-selected-plan-box-subscribe'}`}>
                    {plan.isCurrent && <div className="current-box">Current</div>}
                    {plan.isMostPopular && <div className="popular-box">Most Popular</div>}
                    {plan.paidWaitingList && <div className="waiting-box">Waiting List</div>}
                    <div
                        className={`plan-box-subscribe ${selectedPlanId === plan.id ? 'selected' : ''}`}
                        onClick={() => handleSelectLockedBox(plan)}
                    >
                        {plan.isLocked && (
                            <div className="unlock-overlay-subscribe" style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                                <p style={{ color: 'white' }}>Access Code needed to unlock this plan</p>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <input
                                        type="text"
                                        id="access-code"
                                        ref={accessCodeInputRef}
                                        autoFocus={isAccessCodeActive}
                                        placeholder="Enter Code"
                                        style={{
                                            color: 'white',
                                            backgroundColor: 'black',
                                            width: '126px',
                                            height: '32px',
                                            border: '2px solid #333333',
                                            borderRadius: '4px',
                                            textAlign: 'center',
                                            marginLeft: '72px',
                                            marginRight: '8px',
                                            marginTop: '8px',
                                            zIndex: 1200,
                                        }}
                                        onChange={handleAccessCodeChange}
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            pointerEvents: 'none',
                                        }}
                                        onClick={() => {
                                            accessCodeInputRef.current.blur();
                                        }}
                                    ></div>
                                    <Button
                                        className="custom-button access-code-button"
                                        sx={{
                                            borderRadius: '50%',
                                            width: '40px',
                                            height: '40px',
                                            marginLeft: '-16px',
                                            top: '8px',
                                            cursor: 'pointer',
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            outline: 'none',
                                        }}
                                        disabled={!accessCode}
                                        onClick={handleAccessCode}
                                    >
                                        <SendIcon
                                            style={{
                                                color: accessCode ? '#00eded' : 'grey',
                                                zIndex: 1200,
                                            }}
                                        />
                                    </Button>
                                </div>
                            </div>
                        )}

                        <h3 className={plan.isLocked ? 'locked-subscribe' : 'not-locked-title'}>{plan.title}</h3>

                        {plan.isCustom ? (
                            <p className={`custom-plan-text-subscribe ${plan.isLocked ? 'locked-subscribe' : ''}`}
                                style={{
                                    marginBottom: plan.freeTrialDays ? '32px' : '8px',
                                }}
                            >
                                Custom
                            </p>
                        ) : (
                            <div className="plan-rate-container-subscribe">
                                <p className={`plan-rate-subscribe ${plan.isLocked ? 'locked-subscribe' : 'not-locked-rate'}`}>{formatRate(plan)}</p>
                                <p className={`billing-period-text-subscribe ${plan.isLocked ? 'locked-subscribe' : ''}`}>
                                    {billingPeriod === 'Annually' ? 'billed annually' : 'billed monthly'}
                                </p>
                            </div>
                        )}

                        {plan.freeTrialDays && !plan.isCustom && (
                            <p className={`small-font-subscribe ${plan.isLocked ? 'locked-subscribe' : ''}`}>*First {plan.freeTrialDays} days free</p>
                        )}

                        <hr className={`plan-divider-subscribe ${plan.isLocked ? 'locked-subscribe' : ''}`} />

                        {!plan.isLocked && (
                            <button
                                className={`choose-plan-button-subscribe ${plan.isCurrent && !plan.isCustom ? 'no-pointer' : ''}`}
                                style={buttonStyle(plan, selectedPlanId)}
                                onClick={() => handleButtonClick(plan)}
                                disabled={plan.isCurrent && !plan.isCustom}
                            >
                                <div className="button-content">
                                    <div className="button-label">
                                        {plan.isCurrent && plan.isCustom ? "Contact Us" :
                                            plan.mustContact ? "Contact Us" :
                                                plan.isCurrent && !plan.isCustom ? " " :
                                                    plan.isCustom ? "Contact Sales" : "Choose Plan"}
                                    </div>
                                    {plan.isCurrent && !plan.isCustom ? null : (
                                        <FontAwesomeIcon icon={faArrowRight} style={arrowIconStyle(selectedPlanId === plan.id)} className="arrow-icon" />
                                    )}
                                </div>
                            </button>
                        )}
                        {renderSubtitle(plan.subtitle)}
                        {plan.detailsAvailable && (
                            <div>
                                <ul>
                                    {plan.details.map(detail => (
                                        <li key={detail} className={plan.isLocked ? 'locked-subscribe' : ''}>
                                            <FontAwesomeIcon icon={faCheck} />
                                            {detail}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default PlanSelector;
