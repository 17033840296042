import React, { useState, useEffect } from "react";
import { Box, Button, ButtonGroup, Grid, TextField } from "@mui/material";
import AdminGrid from "./AdminGrid";

function Roles({ hubConnection, sessionId, activeOrganization }) {
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [editingModel, setEditingModel] = useState(null);

  useEffect(() => {
    if (hubConnection && activeOrganization) {  
      const subscribeToEvents = () => {
        hubConnection.on('AccountRoleList', (data) => {
          setLoading(true);
          setList(data);
        });

        hubConnection.on('AccountRole', (data) => {
          setEditingModel(data);
        });

        // Add other event subscriptions here
      };

      subscribeToEvents();
      hubConnection.invoke("GetAccountRoles", sessionId, activeOrganization.id, searchTerm)
        .then(() => {
          setLoading(true);
        })
        .catch(error => console.error("Error sending message:", error));

      return () => {
        hubConnection.off('AccountRoleList');
        hubConnection.off('AccountRole');
        // Unsubscribe from other events here
      };
    }
  }, [hubConnection, activeOrganization]);

  const filter = () => {
    if (hubConnection) {
      hubConnection.invoke("GetAccountRoles", sessionId, activeOrganization.id, searchTerm)
        .then(() => {
          setLoading(true);
        })
        .catch(error => console.error("Error sending message:", error));  
    }
  };

  const handleRowClick = (params) => {
    setRowSelectionModel([params.id]);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 300
    },
    {
      field: 'name',
      headerName: 'Role Name',
      width: 150,
      editable: true,
    }
  ];

  return (
    <Box sx={{ height: '65vh', marginLeft: '40px', marginRight: '40px' }}>
      <Grid container spacing={2}>
        <AdminGrid
          columns={columns}
          editingModel={editingModel}
          filter={filter}
          handleRowClick={handleRowClick}
          hubConnection={hubConnection}
          list={list}
          rowSelectionModel={rowSelectionModel}
          searchTerm={searchTerm}
          sessionId={sessionId}
          setEditingModel={setEditingModel}
          setSearchTerm={setSearchTerm}
          title={"AccountRole"}
        />
      </Grid>
    </Box>
  );
}

export default Roles;