import { Accordion, AccordionDetails, AccordionSummary, Badge, Divider, Grid, Typography } from "@mui/material";
import HitchBox from "../../components/hitchbox/HitchBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-solid-svg-icons";

function BotInteraction() {

    return (
        <HitchBox backgroundColor="#00EDED" color="#000000">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">Hitch.AI Bot Interaction</Typography>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faExpand} />}
                            aria-controls="conversation-content"
                            id="conversation-header"
                        >
                            Conversation: {'123-12-1231'}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="h2">Conversation Details Here</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faExpand} />}
                            aria-controls="bot-content"
                            id="bot-header"
                        >
                            Bot: {'Hitchy'}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="h2">Bot Details Here</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faExpand} />}
                            aria-controls="run-content"
                            id="run-header"
                        >
                            Run: {'ABC-123'}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="h2">Run Details Here</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faExpand} />}
                            aria-controls="status-content"
                            id="status-header"
                        >
                            Status: {'run-completed'}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="h2">Status History Here</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faExpand} />}
                            aria-controls="bot-content"
                            id="bot-header"
                        >
                            Calls<Badge>0</Badge>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="h2">Call Logs Here</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </HitchBox>

    );
}

export default BotInteraction;