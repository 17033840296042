import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const TypeAhead = ({ outputType, outputOptions, outputTypeChanged, label }) => {
  return (
    <div>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={outputType}
        fullWidth
        label={label}
        onChange={outputTypeChanged}
      >
        {outputOptions?.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default TypeAhead;
