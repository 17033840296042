import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ListItem, IconButton, ListItemText, Modal, Button, Box, TextField, Typography,
  Tooltip
} from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PopupMenu from './PopupMenu'; // Ensure this component accepts an `onAction` prop
import { Snackbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useThemeContext } from '../../ThemeContext'; // import your theme context
import { createTheme, ThemeProvider } from '@mui/material/styles';

function ConversationHistory({
  conversationHistory,
  deleteConversation,
  redirectPath,
  selectedChatId,
  onClose,
  setName,
  menuOptions // Accept menuOptions as a prop
}) {
  const newChat = useNavigate();
  const theme = useTheme();
  const { mode, setMode } = useThemeContext(); // Using the theme context
  const [chatToRename, setChatToRename] = useState(null);
  const [currentName, setCurrentName] = useState(""); // State to hold the current conversation name for renaming
  const [renameError, setRenameError] = useState(false); // New state for rename error
  const [conversationNameError, setConversationNameError] = useState(false); // State to track conversation name error

  const [maxHeight, setMaxHeight] = useState('calc(100vh - 100px)');
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [hoveredItemIndex, setHoveredItemIndex] = useState(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false); // Add state for the delete confirmation modal
  const [itemToDelete, setItemToDelete] = useState(null); // Track the item to delete
  const [isLinkIconClicked, setIsLinkIconClicked] = useState(false);
  const [clickedDeleteIcons, setClickedDeleteIcons] = useState({});
  const [chatTitleToDelete, setChatTitleToDelete] = useState(null);
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const themeBackgroundColor = theme.palette.chatLeftMenuBackground[theme.palette.mode];
  const themeTextPrimaryColor = theme.palette.chatLeftMenuPrimaryText[theme.palette.mode];
  const themeListItemHovered = theme.palette.listItemBackground.hover[theme.palette.mode];
  const themeListItemSelected = theme.palette.listItemBackground.selected[theme.palette.mode];
  const themeListItemSelectedHovered = theme.palette.listItemBackground.selected_hover[theme.palette.mode];
  const isTabletMobile = useMediaQuery(theme.breakpoints.down('md'));
  const themeModalBackgroundColor = theme.palette.modalBackground[theme.palette.mode];
  const themeModalTextPrimaryColor = theme.palette.modalTextPrimary[theme.palette.mode];
  const themeModalTextSecondaryColor = theme.palette.modalTextSecondary[theme.palette.mode];

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: {
      xs: '90%',
      sm: '80%',
      md: '70%',
      lg: '600px',
    },
    backgroundColor: themeModalBackgroundColor,//'#0D0D0D',
    color: themeModalTextSecondaryColor,
    borderColor: '#2A2E39',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.6)', // Subtle shadow for depth
    p: 4,
    position: 'relative',
    textAlign: 'center',
    borderRadius: '10px',
    fontFamily: '"Poppins", sans-serif',
    zIndex: 2501,
  };

  const cancelButtonStyleDelete = {
    color: 'white',
    backgroundColor: '#333',
    border: '1px solid #555',
    fontFamily: 'Poppins, sans-serif',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  };

  const deleteButtonStyle = {
    fontFamily: 'Poppins, sans-serif',
    color: 'white',
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: 'darkred', // Darker shade on hover
    },
    '&:active': {
      backgroundColor: 'firebrick', // Different shade when pressed
      boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.3)', // Optional: adds an inset shadow to simulate a pressed effect
    },
    marginLeft: '20px', // Increased spacing between buttons
  };

  const saveButtonStyleRename = {
    fontFamily: 'Poppins, sans-serif',
    color: '#000000',
    backgroundColor: '#00EDED',
    '&:hover': {
      backgroundColor: '#00EDED', // Darker shade on hover
    },
    '&:active': {
      backgroundColor: '#00EDED', // Different shade when pressed
      boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.3)', // Optional: adds an inset shadow to simulate a pressed effect
    },
    marginLeft: '20px', // Increased spacing between buttons
  };

  const cancelButtonStyleRename = {
    color: 'white',
    backgroundColor: '#333',
    border: '1px solid #555',
    fontFamily: 'Poppins, sans-serif',
    '&:hover': {
      backgroundColor: '#333',//'rgba(255, 255, 255, 0.1)',
    },
  };

  useEffect(() => {
    // ... your existing code ...
  }, [conversationHistory]);

  const calculateMaxHeight = () => {
    const windowHeight = window.innerHeight;
    const headerHeight = 161; // Adjust this value according to your header's height
    const newMaxHeight = `${windowHeight - headerHeight}px`;
    setMaxHeight(newMaxHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', calculateMaxHeight);
    calculateMaxHeight();

    return () => {
      window.removeEventListener('resize', calculateMaxHeight);
    };
  }, [conversationHistory]);

  const listItemHeaderStyle = {
    height: '30px',
    alignItems: 'flex-start',
    paddingLeft: '8px',
  };

  const listItemHeaderTextStyle = {
    fontSize: '10px !important',
    textAlign: 'left',
    fontWeight: 'bold',
  };

  const listItemStyle = {
    height: '30px',
    alignItems: 'center',
    paddingLeft: '8px',
    display: 'flex',
    backgroundColor: themeBackgroundColor,
    borderRadius: '4px',
    transition: 'background-color 0.3s',
    cursor: 'pointer', // Add this line  
    '&:hover': {
      backgroundColor:
        hoveredItemIndex === selectedItemIndex ? themeListItemSelectedHovered : themeListItemHovered,
    },
  };

  const listItemTextStyle = {
    fontSize: '12px',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
    position: 'relative',
    paddingRight: '30px',
    fontFamily: '"Poppins", sans-serif'
  };

  const iconStyle = {
    fontSize: '14px',
    color: themeTextPrimaryColor,
    marginRight: '8px',
  };

  const handleListItemClick = (index) => {
    if (conversationHistory && conversationHistory[index].type !== 'header') {
      newChat(`${redirectPath}/${conversationHistory[index].guid}`);
      if (isTabletMobile) onClose(); // Close the drawer if it's in mobile view
    }
    setSelectedItemIndex(index);
  };

  const handleListItemMouseEnter = (index) => {
    setHoveredItemIndex(index);
  };

  const handleListItemMouseLeave = () => {
    setHoveredItemIndex(null);
  };

  const isHovered = (index) => hoveredItemIndex === index;

  const handleDeleteClick = (chat) => {
    setItemToDelete(chat);
    setChatTitleToDelete(chat.title); // Assuming each chat has a 'title' property
    setIsDeleteConfirmationOpen(true);
  };



  const handleRenameModalClose = () => {
    setIsRenameDialogOpen(false); // Reset error state when modal opens
  };

  const handleDeleteModalClose = () => {
    setIsDeleteConfirmationOpen(false); // Close delete confirmation modal
};


  const handleDeleteIconClick = (index, item, e) => {
    e.stopPropagation();
    setItemToDelete(item); // Set the item to delete
    setIsDeleteConfirmationOpen(true); // Open the delete confirmation modal

    // Update the click state for the specific icon
    setClickedDeleteIcons(prevState => ({
      ...prevState,
      [index]: true
    }));

    // Reset the click state after a short delay
    setTimeout(() => {
      setClickedDeleteIcons(prevState => ({
        ...prevState,
        [index]: false
      }));
    }, 200);
  };

  const fadeOverlayStyle = {
    content: '""',
    position: 'absolute',
    right: '0',
    top: '0',
    bottom: '0',
    width: '30px',
    background: theme.palette.mode === 'dark'
      ? 'linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)'
      : 'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(220, 220, 220, 1) 100%)' // Fading to grey for light mode
  };

  const isSelected = (chatId) => chatId === selectedChatId;

  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false); // State for tracking the rename dialog

  const handlePopupAction = (action, item) => {
    switch (action) {
      case 'delete':
        setItemToDelete(item);
        setChatTitleToDelete(item.text); // Assuming 'text' is the property for the chat title
        setIsDeleteConfirmationOpen(true);
        break;
      case 'rename':
        setChatToRename(item.guid); // This ensures we are setting the correct chat's GUID for renaming
        setCurrentName(item.text); // This sets the current name in the input field of the rename dialog
        setIsRenameDialogOpen(true); // Open the rename dialog
        break;
      default:
        break;
    }
  };

  const handleConfirmRename = () => {
    console.log('Chat to Rename GUID:', chatToRename);
    console.log('Current Name:', currentName);

    if (!currentName.trim()) {
      setRenameError(true); // Show error if the name is empty or only whitespace
      return;
    }

    setName(currentName, chatToRename); // Pass the correct GUID for the chat to be renamed
    setIsRenameDialogOpen(false); // Close the rename dialog
    setCurrentName(''); // Clear the input field
    setRenameError(false); // Reset error state
  };

  const handleConfirmDelete = () => {
    if (itemToDelete) {
      // console.log('LOOK AT ME', itemToDelete);
      deleteConversation(itemToDelete.guid);
      setIsDeleteConfirmationOpen(false);
      setIsSuccessSnackbarOpen(true); // Set to true to show the snackbar
    }
  };

  const dialogTheme = createTheme({
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            maxWidth: '600px', // Control the max width of the dialog
            width: '100%', // Use a percentage or fixed size
            backgroundColor: '#FFFFFF',
            color: 'white',
            borderColor: 'gray',
            borderStyle: 'solid',
            borderWidth: '1px',
            boxShadow: '0 4px 8px 0 rgba(255, 255, 255, 0.2)',
            p: 4,
            position: 'relative',
            textAlign: 'center',
            borderRadius: '10px',
            fontFamily: '"Poppins", sans-serif',
          },
        },
      },
    },
  });

  return (
    <Box
      style={{
        backgroundColor: themeBackgroundColor,
        color: themeTextPrimaryColor,
        maxHeight: maxHeight,
        overflowY: 'auto',
        marginTop: '-8px',
        paddingRight: 0, // Ensure no padding on the right
        zIndex: 2500, // Ensure higher z-index
      }}>
      {conversationHistory &&
        conversationHistory.map((item, index) => {
          // Log item.guid and selectedChatId for debugging
          //console.log('Rendering Chat Item - GUID:', item.guid);
          //console.log('Selected Chat ID:', selectedChatId);

          return (
            <Tooltip title={item.updated} placement="top-start" key={index}>
              <div key={index}>
                {item.type === 'header' ? (
                  <ListItem sx={listItemHeaderStyle}>
                    <ListItemText
                      primary={item.text}
                      primaryTypographyProps={{ style: listItemTextStyle }}
                      sx={{
                        '&::after': fadeOverlayStyle,
                      }}
                    />
                  </ListItem>
                ) : (
                  <ListItem
                    sx={{
                      ...listItemStyle,
                      display: 'flex',
                      justifyContent: 'space-between', // Spread the name and ellipsis apart
                      alignItems: 'center',
                      paddingRight: '0px', // Adjust if needed for more space
                      backgroundColor: isSelected(item.guid) ? themeListItemSelected : 'transparent',
                    }}
                    onClick={() => handleListItemClick(index)}
                    onMouseEnter={() => handleListItemMouseEnter(index)}
                    onMouseLeave={handleListItemMouseLeave}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, overflow: 'hidden' }}>
                      <ChatBubbleOutlineIcon sx={iconStyle} />
                      <ListItemText
                        primary={item.text}
                        primaryTypographyProps={{ style: listItemTextStyle }}
                        sx={{ '&::after': fadeOverlayStyle, overflow: 'hidden', textOverflow: 'ellipsis' }}
                      />
                    </Box>

                    {(isSelected(item.guid) || isHovered(index)) && (
                      <PopupMenu
                        onAction={(action) => handlePopupAction(action, item)}
                        item={item}
                        setChatToRename={setChatToRename}
                        menuOptions={menuOptions}
                        isVisible={isSelected(item.guid) || isHovered(index)}
                      />
                    )}
                  </ListItem>

                )}
              </div>
            </Tooltip>
          );
        })}



      <Modal
        open={isRenameDialogOpen}
        onClose={handleRenameModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 2501 }}
      >
        <Box sx={modalStyle}>
          <IconButton
            aria-label="close"
            onClick={handleRenameModalClose}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: themeModalTextSecondaryColor,
            }}
          >
            <FontAwesomeIcon icon={faTimes} size="1x" />
          </IconButton>
          {/* Modal Title */}
          <Typography variant="h6" style={{ color: themeModalTextSecondaryColor, fontFamily: '"Poppins", sans-serif', marginBottom: '20px' }}>
            Rename Chat
          </Typography>
          <TextField
            label="Chat Name"
            value={currentName}
            style={{ marginTop: '32px' }}
            onChange={(e) => setCurrentName(e.target.value)}
            fullWidth
            variant="outlined"
            error={renameError}
            helperText={renameError ? "Chat Name is required" : ''}
            InputLabelProps={{
              style: {
                color: 'grey',
                fontSize: '18px',
                fontFamily: '"Poppins", sans-serif',
              },
            }}
            InputProps={{
              style: {
                color: themeModalTextSecondaryColor,
                fontSize: '18px',
                fontFamily: '"Poppins", sans-serif',
              },
              sx: {
                '& .MuiOutlinedInput-notchedOutline': { borderColor: renameError ? 'red' : 'grey' },
                '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: renameError ? 'red' : '#00EDED' },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: renameError ? 'red' : '#00EDED' },
              },
            }}
          />
          <Box display="flex" justifyContent="flex-end" marginTop="32px">
            <Button onClick={handleRenameModalClose} sx={cancelButtonStyleRename}>Cancel</Button>
            <Button onClick={handleConfirmRename} sx={saveButtonStyleRename}>Save</Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
        sx={{ zIndex: 2501 }}
      >
        <Box sx={modalStyle}>
          <IconButton
            aria-label="close"
            onClick={handleDeleteModalClose}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: 'white',
            }}
          >
            <FontAwesomeIcon icon={faTimes} size="1x" />
          </IconButton>
          {/* Modal Title */}
          <Typography variant="h6" style={{ color: 'white', fontFamily: '"Poppins", sans-serif', marginBottom: '20px' }}>
            Confirm Delete
          </Typography>
          <Typography id="delete-modal-description" sx={{ mt: 2, fontFamily: '"Poppins", sans-serif' }}>
            <Typography component="p" sx={{ mb: 1 }}> {/* Add margin bottom here */}
              Are you sure you want to delete the chat:
            </Typography>
            {chatTitleToDelete && (
              <Typography component="p">
                "<Typography component="span" style={{ fontWeight: 'bold' }}>
                  {chatTitleToDelete}
                </Typography>"?
              </Typography>
            )}
            {!chatTitleToDelete && <Typography component="p">?</Typography>}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button onClick={() => setIsDeleteConfirmationOpen(false)} style={cancelButtonStyleDelete}>
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} style={deleteButtonStyle}>Delete</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default ConversationHistory;