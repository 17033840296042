import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignalBars, faSignalSlash } from '@fortawesome/pro-duotone-svg-icons';

function QbStatus({ hubConnection }) {
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        const updateConnectionStatus = () => {
            if (hubConnection) {
                setIsConnected(hubConnection.state === "Connected");
            } else {
                setIsConnected(false);
            }
        };
        // Initial check
        updateConnectionStatus();

        // Cleanup
        return () => {
            hubConnection?.off('stateChanged', updateConnectionStatus);
        };
    }, [hubConnection]);

    return (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={8} style={{ textAlign: 'center' }}>
                {isConnected ? (
                    <>
                        Connected <FontAwesomeIcon icon={faSignalBars} color="green" />
                    </>
                ) : (
                    <>
                        Not Connected <FontAwesomeIcon icon={faSignalSlash} color="red" />
                    </>
                )}
            </Grid>
        </Grid>
    );
}

export default QbStatus;
