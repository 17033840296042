import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Collapse, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function ServiceOperations() {
    const [operations, setOperations] = useState([]);
    const [expanded, setExpanded] = useState({});

    useEffect(() => {
        // Use static dummy data for now
        const dummyOperations = [
            {
                id: "1",
                name: "Operation One",
                created: "2024-01-01",
                createdBy: "admin",
                updated: "2024-01-02",
                updatedBy: "admin"
            },
            {
                id: "2",
                name: "Operation Two",
                created: "2024-02-01",
                createdBy: "admin",
                updated: "2024-02-02",
                updatedBy: "admin"
            }
        ];
        setOperations(dummyOperations);
    }, []);

    const handleExpandClick = (id) => {
        setExpanded((prevExpanded) => ({
            ...prevExpanded,
            [id]: !prevExpanded[id]
        }));
    };

    return (
        <Box>
            {operations.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Updated</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {operations.map((operation) => (
                                <React.Fragment key={operation.id}>
                                    <TableRow sx={{ '& .MuiTableCell-root': { padding: '8px' } }}>
                                        <TableCell>{operation.name}</TableCell>
                                        <TableCell>{operation.created}</TableCell>
                                        <TableCell>{operation.updated}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => handleExpandClick(operation.id)}
                                                aria-expanded={expanded[operation.id]}
                                                aria-label="show more"
                                            >
                                                {expanded[operation.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '& .MuiTableCell-root': { padding: '8px' } }}>
                                        <TableCell style={{ padding: 0 }} colSpan={4}>
                                            <Collapse in={expanded[operation.id]} timeout="auto" unmountOnExit>
                                            <Box margin={0} sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 1 }}>
                                                    <Grid container spacing={2}>
                                                        {Object.keys(operation).map((key) => (
                                                            !['id', 'name', 'created', 'updated'].includes(key) && (
                                                                <Grid item xs={12} sm={6} key={key}>
                                                                    <Typography variant="body2"><strong>{key}:</strong> {operation[key]}</Typography>
                                                                </Grid>
                                                            )
                                                        ))}
                                                    </Grid>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>No Operations to Display</Typography>
            )}
        </Box>
    );
}

export default ServiceOperations;
