import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PdfViewer from './PdfViewer'; // Assuming PdfViewer is in the same directory
import CloseIcon from '@mui/icons-material/Close';

function PdfViewerModal({ pdfUrl, bearerToken, open, onClose }) {
  // Modal style
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll', // To enable scrolling if the PDF is larger than the modal
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="pdf-viewer-modal-title"
      aria-describedby="pdf-viewer-modal-description"
    >
      <Box sx={style}>
        {/* Close Button */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <CloseIcon />
        </IconButton>

        {/* PdfViewer Component */}
        <PdfViewer pdfUrl={pdfUrl} bearerToken={bearerToken} />
      </Box>
    </Modal>
  );
}

export default PdfViewerModal;
