import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import ChangePaymentMethodDialog from './ChangePaymentMethodDialog'; // Ensure this path is correct

const PaymentMethod = ({ paymentMethod, titleStyle }) => {
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);

  const handleOpenPaymentDialog = () => {
    setIsPaymentDialogOpen(true);
  };

  const handleClosePaymentDialog = () => {
    setIsPaymentDialogOpen(false);
  };

  const buttonStyle = {
    backgroundColor: '#00EDED',
    color: 'black',
    height: '28px',
    padding: '4px 10px',
    fontSize: '0.75rem',
    fontFamily: '"Poppins", sans-serif',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  };

  const detailItemStyle = {
    marginBottom: '4px',
    marginLeft: '8px',
  };

  const flexContainerStyle = {
    display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '16px',
      '@media (max-width: 768px)': {
        flexDirection: 'column',
      },
  };

  const leftSectionStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };

  const rightSectionStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    '@media (max-width: 768px)': {
      alignItems: 'flex-start', // Align to the left on small screens
      marginTop: '8px', // Add margin to separate from the above text
    },
  };

  const paperPropsStyle = {
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '10px',
    border: '1px solid white',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    maxWidth: '600px',
    width: '80%',
    margin: 'auto',
    position: 'relative',
    width: '95%',
    maxWidth: '1500px',
    minWidth: '300px',
    fontFamily: '"Poppins", sans-serif',
  };

  const dialogTitleStyle = {
    background: 'black',
    color: 'white',
    fontFamily: '"Poppins", sans-serif',
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '8px',
    right: '8px',
    color: 'white',
  };

  const dialogContentStyle = {
    background: 'black',
    color: 'white',
    fontFamily: '"Poppins", sans-serif',
  };

  return (
    <div>
      <h2 style={titleStyle}>Payment Method</h2>
      <div style={flexContainerStyle}>
        <div style={leftSectionStyle}>
          <p style={detailItemStyle}>Card: {paymentMethod.cardBrand} ending in {paymentMethod.last4}</p>
          <p style={detailItemStyle}>Expires: {paymentMethod.expMonth}/{paymentMethod.expYear}</p>
          <p style={detailItemStyle}>Billing Address: {paymentMethod.billingAddress}</p>
        </div>
        <div style={rightSectionStyle}>
          <Button onClick={handleOpenPaymentDialog} style={buttonStyle}>
            <EditIcon fontSize="smaller" /> Update Card
          </Button>
        </div>
      </div>
      <Dialog onClose={handleClosePaymentDialog} open={isPaymentDialogOpen} PaperProps={{ style: paperPropsStyle }}>
        <div style={{ padding: '20px', backgroundColor: 'black', color: 'white' }}>
          <DialogTitle id="change-payment-dialog-title" style={dialogTitleStyle}>
            <IconButton aria-label="close" onClick={handleClosePaymentDialog} style={closeButtonStyle}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={dialogContentStyle}>
            <ChangePaymentMethodDialog />
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default PaymentMethod;
