import { Stack, Typography, Box, Button, Modal, Backdrop } from '@mui/material';
import React, { useState } from 'react';
import { PlayCircleOutline } from '@mui/icons-material';
//import Register from '../../Registration/Register';
import LoginPage from '../login/login';
import './hero.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


function Hero() {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const openSignUp = () => {
        // Replace '/signup-newsletter' with the actual path where SignUpNewsletter is rendered
        window.open('/signup-joinourjourney', '_blank');
    };

    const openRequestDemo = () => {
        // Replace '/signup-newsletter' with the actual path where SignUpNewsletter is rendered
        window.open('/request-demo', '_blank');
    };

    return (
        <Box
            className="hero-bg-large"
        >

            <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={2}
                justifyContent={{ xs: 'flex-start', sm: 'space-evenly' }}
                sx={{ marginX: { xs: 1, sm: 4, md: 7, lg: 13 } }}
            >
                <Box
                    //bgcolor="#4B4B4B" 
                    flex={7}
                    alignItems="center"
                    textAlign="left"
                    sx={{ paddingX: { xs: 1, sm: 2, md: 2 } }}
                >
                    <Box //bgcolor="#4B4B4B"
                    >
                        <Typography
                            variant="h2"
                            sx={{
                                fontWeight: 'bold', // Bold
                                fontFamily: 'Red Hat Display, sans-serif',
                                fontSize: { xs: '40px', sm: '56px', md: '64px' }, // Adjusted font size
                                marginTop: { xs: '40px', sm: '80px', md: '200px' }, // Adjusted top margin
                                marginBottom: { xs: '16px', sm: '16px', md: '24px' }, // Adjusted top margin
                                color: "#00EDED"
                            }}
                        >
                            Data Insights at Your Fingertips.
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontSize: { xs: '18px', sm: '20px', md: '20px' }, // Adjusted font size
                                fontFamily: 'Inter, sans-serif',
                                marginBottom: { xs: '32px', sm: '32px', md: '36px' }, // Adjusted top margin
                                color: "#FFFFFF"
                            }}
                        >
                            Unlock actionable insights with our platform, turning raw data into a strategic asset for innovation and success.
                        </Typography>

                        <Box
                            sx={{ marginBottom: { xs: 10, md: 0 } }}
                        >
                            <Stack
                                direction='row'
                                spacing={4}
                                justifyContent={{ xs: 'space-evenly', sm: 'flex-start' }}
                                sx={{ marginX: { xs: 1, sm: 0 } }}
                            >
                                {/*commment these out againcommment these out again
                                <Button
                                    variant="contained"
                                    color="seafoamGreen"
                                    sx={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: '600', // Semi-Bold
                                        fontSize: '18px',
                                        textTransform: 'none', // Prevents uppercase transformation
                                        width: { xs: '144px', md: "152px" },
                                        height: { xs: '64px', md: "60px" },
                                        borderRadius: '8px',
                                        marginBottom: { xs: '64px', md: '0px' }, // Adjusted top margin
                                        color: '#4A4A68', // Set the text color to yellow
                                    }}
                                    onClick={handleOpenModal} // Open the modal on button click
                                >
                                    Get Started
                                </Button>
                                <Button
                                    size="large"
                                    color="seafoamGreen"
                                    startIcon={<PlayCircleOutline />}
                                    sx={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: '600', // Semi-Bold
                                        fontSize: '18px',
                                        textTransform: 'none', // Prevents uppercase transformation
                                        width: { xs: '184px', md: "200px" },
                                        height: { xs: '64px', md: "60px" },
                                        borderRadius: '8px',
                                        marginBottom: { xs: '64px', md: '0px' }, // Adjusted top margin
                                    }}
                                    onClick={openRequestDemo}
                                >
                                    Request Demo
                                </Button>*/}
                                {/*commment these out againcommment these out again*/}



                                <Button
                                    variant="contained"
                                    sx={{
                                        fontFamily: 'Poppins, sans-serif', // Updated font family
                                        fontWeight: '600', // Semi-Bold
                                        fontSize: '18px',
                                        textTransform: 'none', // Prevents uppercase transformation
                                        width: { xs: '240px', md: "240px" }, // Widened button to fit the longer text
                                        height: { xs: '64px', md: "60px" },
                                        borderRadius: '8px',
                                        marginBottom: { xs: '64px', md: '0px' }, // Adjusted top margin
                                        color: '#000080', // Deep navy blue text color
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.2)', // Enhanced shadow for more depth
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '0 16px', // Add padding for better spacing
                                        background: 'linear-gradient(45deg, #00EDED 30%, #5FEEEE 90%)', // Gradient background
                                        '&:hover': {
                                            background: 'linear-gradient(45deg, #5FEEEE 30%, #00EDED 90%)', // Inverse gradient on hover
                                            boxShadow: '0px 12px 24px rgba(75, 75, 75, 0.6)', // Even more pronounced shadow on hover
                                        },
                                    }}
                                    onClick={openRequestDemo}
                                >
                                    Request Demo <ChevronRightIcon sx={{ marginLeft: '8px' }} />
                                </Button>



                            </Stack>


                        </Box>



                    </Box>
                </Box>
                <Box
                    //bgcolor="#4B4B4B" 
                    flex={5}
                    alignItems="center"
                    textAlign="center"
                    paddingLeft={2}
                    paddingRight={2}
                >
                    <Box
                        className="mobile-image"
                        //bgcolor="green"
                        width={{ xs: '298px', sm: '320px', md: '340px' }}
                        height={{ xs: '622px', sm: '640px', md: '704px' }}
                        marginTop={{ xs: '0px', sm: '0px', md: '88px' }}
                        marginBottom={{ xs: '80px', sm: '80px', md: '74px' }} // Adjusted top margin
                        borderRadius={'30px'}
                        sx={{ margin: 'auto' }}
                    />
                </Box>
            </Stack>

            {/* Modal for Registration component */}


            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="login-modal"
                BackdropComponent={Backdrop}
                BackdropProps={{
                    invisible: false,
                    sx: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust if you want a different backdrop opacity
                    }
                }}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{
                    display: 'flex', // Enables flexbox properties
                    flexDirection: 'column', // Stacks children vertically
                    justifyContent: 'center', // Centers content vertically in the box
                    alignItems: 'center', // Centers content horizontally in the box
                    outline: 'none',
                    maxHeight: '60vh', // Adjust the height to make the modal less tall
                    width: { xs: '90%', sm: '80%', md: '70%', lg: '60%', xl: '50%' }, // Adjust based on viewport width
                    maxWidth: '1200px', // Prevents the modal from going full width on larger screens
                    overflowY: 'auto', // Allows scrolling for overflow content
                    borderRadius: '16px', // Optional: rounded corners
                    backgroundColor: '#0C0C0D',
                    borderColor: 'gray', // A border color that stands out on a black background
                    borderStyle: 'solid',
                    borderWidth: '1px', // Thickness of the border
                    boxShadow: '0 4px 8px 0 rgba(255, 255, 255, 0.2)', // A subtle white glow for depth
                    p: 0, // Adjust padding as needed
                    zIndex: 1300, // Ensure modal is above the backdrop
                    color: 'white',
                }}>
                    <LoginPage />
                </Box>
            </Modal>



        </Box>
    );
}

export default Hero;