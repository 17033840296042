import React from 'react';
import {
    AvatarGroup, Box, Typography, Drawer, styled, Avatar, ImageList, ImageListItem, List, ListItemAvatar, ListItemText, Divider, IconButton, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import { ListItem } from '@mui/material';
import { KeyboardDoubleArrowRight as CloseIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import FileListV2 from './FileListV2'; // Import the FileList component

const drawerWidth = 320; // Adjust drawer width as needed
const navHeaderHeight = '64px'; // Adjust this to match your navigation header height

const DrawerBox = styled(Box)(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: drawerWidth,
        boxSizing: 'border-box',
        top: navHeaderHeight, // Position the drawer below the navigation header
    },
}));

function RightConversationDrawerV2({ rightDrawerOpen, setRightDrawerOpen, selectedChat }) {
    return (
        <DrawerBox>
            <Drawer
                variant="persistent"
                anchor="right"
                open={rightDrawerOpen}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Box
                    bgcolor="lightcoral"
                    color="black"
                    p={2}
                    sx={{ minHeight: '100vh' }}
                >
                    <Box display="flex" alignItems="center" mb={2}>
                        <IconButton onClick={() => setRightDrawerOpen(false)} style={{ marginRight: '8px' }}>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" fontWeight={100}>Online Friends</Typography>
                    </Box>
                    
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Online Friends</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AvatarGroup max={5}>
                                <Avatar alt="Remy Sharp" src="https://material-ui.com/static/images/avatar/1.jpg" />
                                <Avatar alt="Travis Howard" src="https://material-ui.com/static/images/avatar/2.jpg" />
                                <Avatar alt="Cindy Baker" src="https://material-ui.com/static/images/avatar/3.jpg" />
                                <Avatar alt="Agnes Walker" src="" />
                                <Avatar alt="Trevor Henderson" src="https://material-ui.com/static/images/avatar/5.jpg" />
                            </AvatarGroup>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Latest Photos</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ImageList cols={3} rowHeight={100} gap={5}>
                                <ImageListItem>
                                    <img src="https://material-ui.com/static/images/image-list/breakfast.jpg" alt="" />
                                </ImageListItem>
                                <ImageListItem>
                                    <img src="https://material-ui.com/static/images/image-list/burgers.jpg" alt="" />
                                </ImageListItem>
                            </ImageList>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography>Latest Conversations</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt="Remy Sharp" src="https://material-ui.com/static/images/avatar/1.jpg" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="Brunch this weekend?"
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    Ali Connors
                                                </Typography>
                                                {" — I'll be in your neighborhood doing errands this…"}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt="Travis Howard" src="https://material-ui.com/static/images/avatar/2.jpg" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="Summer BBQ"
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    to Scott, Alex, Jennifer
                                                </Typography>
                                                {" — Wish I could come, but I'm out of town this…"}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt="Cindy Baker" src="https://material-ui.com/static/images/avatar/3.jpg" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="Oui Oui"
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    Sandra Adams
                                                </Typography>
                                                {' — Do you have Paris recommendations? Have you ever…'}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography>Files</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FileListV2 selectedChat={selectedChat} />
                        </AccordionDetails>
                    </Accordion>

                </Box>
            </Drawer>
        </DrawerBox>
    );
}

export default RightConversationDrawerV2;
