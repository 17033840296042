import { faCheck, faChevronsDown, faExpand, faFilePdf, faSquareChevronDown, faWifi, faWifiExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Autocomplete, Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Divider,
    FilledInput, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Snackbar, TextField,
    Typography, setRef, styled, ThemeProvider, createTheme
} from "@mui/material";
import FormControl from '@mui/material/FormControl';
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState, useRef } from "react";
import './Docusign.css';
//import styled from "styled-components";
import React from 'react';
import { Tab, Tabs, } from '@mui/material';
import { TabContext } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import PdfViewerModal from "../PdfViewer/PdfViewerModal";
import { useHubConnections } from "../../utils/HubConnectionsProvider";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    typography: {
        fontFamily: '"Poppins", sans-serif',
        h5: {
            fontWeight: 500, // Medium font weight for h5 headings
        },
        body1: {
            fontSize: '1rem', // Standard body text size
        },
        button: {
            textTransform: 'none', // Avoid uppercase for a modern look
            fontWeight: 400, // Regular font weight for buttons
        },
        // Add more custom styles as needed
    },
});


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .fixed-background': {
        backgroundColor: '#0C0C0D', // Fixed background color for all rows
        '&:hover': {
            backgroundColor: '#18181B', // Change color on hover
        },
        '&.MuiSelected': {
            backgroundColor: '#303336', // Change color when selected
            '&:hover': {
                backgroundColor: '#36393C', // Change color on hover when selected
            },
        }
    },
    /* Add your custom styling for deactivated rows here */
    '& .deactivated-row': {
        fontStyle: 'italic',
        /* You can adjust other styling properties as needed */
    },
    /* Define styles for links */
    '& .role-link, & .action-link': {
        color: '#00EDED',
        textDecoration: 'none', // Remove default underlines
        '&:hover': {
            textDecoration: 'underline', // Underline on hover
        },

    },
    // Style for filter icons

    '& .MuiDataGrid-cell': {
        borderColor: '#1a1a1a',
        border: '2',
        fontFamily: '"Poppins", sans-serif', // Apply to cells
    },
    '& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIconButton': {
        opacity: 1,
        color: "#00EDED"
    },
    '& .MuiDataGrid-iconSeparator': {
        opacity: 1,
        color: "#fff"
    },
    '& .MuiDataGrid-footerContainer': {
        backgroundColor: '#050506',
        color: 'white',
        fontFamily: '"Poppins", sans-serif', // Apply to cells
    },
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#050506',
        color: "#fff",
        fontSize: 16,
        fontFamily: '"Poppins", sans-serif', // Apply to cells
    },
    '& .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row': {
        borderBottom: '1px solid #000 !important',
    },
    '& .MuiDataGrid-row': {
        '&.paid-row': {
            backgroundColor: '#4CAF50', // Green for paid
        },
        '&.pending-row': {
            backgroundColor: '#FFC107', // Yellow for pending
        },
        '&.unpaid-row': {
            backgroundColor: '#FF5722', // Red for unpaid
        },
        '&.past-due-row': {
            backgroundColor: '#FFC107', // Yellow for past due
        },
    },
}));


function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Docusign({ sessionId }) {
    const { dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady, jwtToken } = useHubConnections();
    const [showSendForm, setShowSendForm] = useState(false);
    const [connected, setConnected] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [templateId, setTemplateId] = useState('');
    const [templates, setTemplates] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [ccName, setCcName] = useState('');
    const [ccEmail, setCcEmail] = useState('');
    const [sageSalesOrderNumber, setSageSalesOrderNumber] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [userEmail, setUserEmail] = useState('');
    const [referenceId, setReferenceId] = useState('');
    const [accountId, setAccountId] = useState('');
    const [envelopes, setEnvelopes] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [inputAccount, setInputAccount] = useState('');
    const [accountError, setAccountError] = useState(false);
    const [documentError, setDocumentError] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [template, setTemplate] = useState(null);
    const [inputTemplate, setInputTemplate] = useState('');

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
        typography: {
            fontFamily: "'Poppins', sans-serif",
            h5: {
                fontFamily: "'Poppins', sans-serif", // Apply Poppins specifically to h5 variants
                fontWeight: 600, // Optional: Adjust the weight as needed
            },
            // Define other styles as needed
        },
    });

    const columns = [
        {
            field: 'referenceId',
            headerName: 'Sage Sales Order Number',//'Reference Id',
            width: 300,
            editable: true,
            sortable: true
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 300,
            editable: false,
            sortable: true
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 144,
            editable: false,
            sortable: true,
        },
        {
            field: 'pdf',
            headerName: "",
            width: 100,
            sortable: false,
            editable: false,
            filterable: false, // Disable filtering for the PDF column
            renderCell: (params) => (
                <>
                    {params.row.status === 'completed' && (
                        <FontAwesomeIcon
                            icon={faFilePdf}
                            onClick={() => {
                                console.log('params.row.docusignEnvelopeId', params.row.docusignEnvelopeId);
                                setPdfUrl(params.row.docusignEnvelopeId);
                                setOpen(true);
                            }}
                            // Adjusting the color to something more readable and increasing the size
                            style={{ cursor: 'pointer', color: '#00EDED', fontSize: '24px' }}
                        />
                    )}
                </>
            )
        },
        {
            field: 'statusDateTime',
            type: 'dateTime',
            headerName: 'Last Update',
            description: 'Last Status Update Date and Time (UTC).',
            sortable: true,
            editable: false,
            width: 250,
            valueGetter: (params) => {
                return params.value ? new Date(params.value) : null;
            }
        }
    ];

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [nameError, setNameError] = useState('');
    const [nameOutlineStyle, setNameOutlineStyle] = useState({ borderColor: 'grey' });
    const nameRef = useRef(null);

 
    const handleNameChange = (e) => {
        const value = e.target.value;
        setName(value);
        // Add validation logic as needed
    };

    // Simulated validation function (replace with your actual validation logic)
    const validateName = (name) => {
        if (!name.trim()) {
            setNameError('This field is required');
            setNameOutlineStyle({ borderColor: 'red' });
        } else {
            setNameError('');
            setNameOutlineStyle({ borderColor: 'grey' });
        }
    };

    const [emailError, setEmailError] = useState('');
    const [emailOutlineStyle, setEmailOutlineStyle] = useState({ borderColor: 'grey' });
    const emailRef = useRef(null);

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
      setIsFocused(true); // Assuming this will trigger your seafoam green border
    };
    
    useEffect(() => {
        if (connected) {
            nameRef.current.focus(); // Focus when connected changes to true
        }
    }, [connected]); // Depend on the connected state

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        // Add validation logic as needed
    };

    // Simulated validation function (replace with your actual validation logic)
    const validateEmail = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!email.trim()) {
            setEmailError('This field is required');
            setEmailOutlineStyle({ borderColor: 'red' });
        } else if (!emailRegex.test(email)) {
            // Check if the email matches the pattern
            setEmailError('Invalid email format');
            setEmailOutlineStyle({ borderColor: 'red' });
        } else {
            setEmailError('');
            setEmailOutlineStyle({ borderColor: 'grey' });
        }
    };


    const [ccNameError, setCcNameError] = useState('');
    const [ccNameOutlineStyle, setCcNameOutlineStyle] = useState({ borderColor: 'grey' });
    const ccNameRef = useRef(null);



    const handleCcNameChange = (e) => {
        const value = e.target.value;
        setCcName(value);
        // Add validation logic as needed
    };

    const validateCcName = (name) => {
        if (!name && ccEmail) {
            // CC Name is required if CC Email is filled
            setCcNameError('CC Name is required if CC Email is provided');
            setCcNameOutlineStyle({ borderColor: 'red' });
        } else {
            setCcNameError('');
            setCcNameOutlineStyle({ borderColor: 'grey' });
        }
    };


    const [ccEmailError, setCcEmailError] = useState('');
    const [ccEmailOutlineStyle, setCcEmailOutlineStyle] = useState({ borderColor: 'grey' });
    const ccEmailRef = useRef(null);

    useEffect(() => {
        // Auto-focus on component mount
        ccEmailRef.current?.focus();
    }, []);

    const handleCcEmailChange = (e) => {
        const value = e.target.value;
        setCcEmail(value);
        // Add validation logic as needed
    };

    // Validation function for CC Email Address
    const validateCcEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email && !emailRegex.test(email)) {
            setCcEmailError('Invalid email format');
            setCcEmailOutlineStyle({ borderColor: 'red' });
        } else if (!email && ccName) {
            // CC Email is required if CC Name is filled
            setCcEmailError('CC Email is required if CC Name is provided');
            setCcEmailOutlineStyle({ borderColor: 'red' });
        } else {
            setCcEmailError('');
            setCcEmailOutlineStyle({ borderColor: 'grey' });
        }
    };


    const [sageSalesOrderNumberError, setSageSalesOrderNumberError] = useState('');
    const [sageSalesOrderNumberOutlineStyle, setSageSalesOrderNumberOutlineStyle] = useState({ borderColor: 'grey' });
    const sageSalesOrderNumberRef = useRef(null);

    useEffect(() => {
        // Auto-focus on component mount
        sageSalesOrderNumberRef.current?.focus();
    }, []);

    const handleSageSalesOrderNumberChange = (e) => {
        const value = e.target.value;
        setSageSalesOrderNumber(value);
        // Add validation logic as needed
    };

    // Simulated validation function (replace with your actual validation logic)
    const validateSageSalesOrderNumber = (email) => {
        if (!ccEmail.trim()) {
            setSageSalesOrderNumberError('This field is required');
            setSageSalesOrderNumberOutlineStyle({ borderColor: 'red' });
        } else {
            setSageSalesOrderNumberError('');
            setSageSalesOrderNumberOutlineStyle({ borderColor: 'grey' });
        }
    };


    const buttonStyle = ({
        backgroundColor: '#00EDED', // Darker grey when disabled
        color: 'black', // Light grey text when disabled
        height: '40px',
        padding: '4px 10px',
        fontFamily: '"Poppins", sans-serif',
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        '&:hover': {
            backgroundColor: '#64b5f6', // Slightly different blue for hover when enabled, remains the same when disabled
        },
    });

    const buttonStyleSecondary = {
        backgroundColor: '#333333', // Seafoam green
        borderColor: 'white',
        color: 'white', // Black font color
        height: '40px', // Adjusted height
        padding: '4px 10px', // Adjusted padding (top & bottom, left & right)
        fontSize: '1rem', // Decreased font size
        display: 'flex', // Add flex display
        alignItems: 'center', // Align content vertically
        gap: '4px', // Add gap between icon and text
        '&:hover': {
            backgroundColor: '#333', // Lighter shade for hover state
        },
    };

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            console.log('sending request for auth logged-in');
            dashboardConnection.on('b0919dc4-a65a-467b-a207-fb2a69920537', gotit);
            dashboardConnection.on('947cd3e7-c2a2-47bb-bda3-a0cbf3774497', authStart);
            dashboardConnection.on('440663ef-a680-4020-9720-7d7130dd6ac6', (data) => {
                if (data && data.envelopeTemplates) {
                    setTemplates(data.envelopeTemplates);
                } else {
                    setTemplates([]);
                }
            });
            dashboardConnection.on("bdc4265f-6dbe-4a32-be0c-c83dbbf61da6", (data) => {
                if (data) {
                    if (data.accounts) {
                        setAccounts(data?.accounts);
                        // Find the default account and set accountId
                        const defaultAccount = data.accounts.find(account => account.is_default);
                        if (defaultAccount && accountId === '') {
                            setAccountId(defaultAccount.account_id);
                        }
                    }
                    setUserEmail(data.email);
                }

            });
            dashboardConnection.on("4470e237-9547-45bd-9c64-bfb88a13e86d", (data) => {
                if (data) {
                    console.log("4470e237-9547-45bd-9c64-bfb88a13e86d", data);
                    setEnvelopes(data);
                }
            });

            hitchDashCommand('docusign', 'auth', 'logged-in');

            return () => {
                dashboardConnection.off("b0919dc4-a65a-467b-a207-fb2a69920537", gotit);
                dashboardConnection.off('947cd3e7-c2a2-47bb-bda3-a0cbf3774497', authStart);
                dashboardConnection.off("bdc4265f-6dbe-4a32-be0c-c83dbbf61da6");
                dashboardConnection.off("440663ef-a680-4020-9720-7d7130dd6ac6");
                dashboardConnection.off("4470e237-9547-45bd-9c64-bfb88a13e86d");
            };
        }
    }, [dashboardConnection, isConnectionReady]);

    useEffect(() => {
        if (connected) {
            console.log('requesting user-info');
            hitchDashCommand('docusign', 'auth', 'user-info');
        }
    }, [connected]);

    useEffect(() => {
        if (connected && accountId !== '') {
            hitchDashCommand('docusign', 'envelope', 'list');
            hitchDashCommand('docusign', 'template', 'list', {
                "docusignAccountId": accountId
            });
        }
    }, [connected, accountId])


    useEffect(() => {
        if (accounts.length > 0) {
            const defaultAccount = accounts.find(account => account.is_default);
            if (defaultAccount) {
                setSelectedAccount(defaultAccount); // Set the selectedAccount state to the default account
                setAccountId(defaultAccount.account_id); // Ensure accountId is also set for consistency
            }
        }
    }, [accounts]);

    // Define the getRowClassName function to return a fixed class for all rows
    const getRowClassName = () => 'fixed-background';

    const handleExpansion = () => {
        setShowSendForm((prevExpanded) => !prevExpanded);
    };

    const gotit = (data) => {
        console.log('gotit', data);
        setConnected(data);
    };

    const getConnection = () => {
        console.log('requesting link');
        hitchDashCommand('docusign', 'auth', 'auth-start');
    };

    const authStart = (data) => {
        console.log('received auth package', data);
        if (data) {
            let theData = JSON.parse(data);
            window.open(theData.Url, '_blank', 'noopener,noreferrer');
        }
    };

    const sendEnvelope = () => {
        const formIsValid = validateAllFields();

        if (!formIsValid) {
            console.log("Validation failed, not sending.");
            return;
        }

        var envelope = buildEnvelopeRequest();
        hitchDashCommand('docusign', 'signature', 'send-template', envelope, () => {
            clearEnvelope();
            setSnackMessage(`Document Sent To ${envelope.signerEmailAddress}`);
            setSnackOpen(true);
        });
    };


    const clearEnvelope = () => {
        setName('');
        setEmail('');
        setReferenceId('');

    };

    const clearAll = () => {
        setName('');
        setEmail('');
        setCcName('');
        setCcEmail('');
        setSageSalesOrderNumber('');
        setTemplate(null);
        setInputTemplate('');
        setReferenceId('');
        setNameError('');
        setEmailError('');
        setCcNameError('');
        setCcEmailError('');
        setSageSalesOrderNumberError('');
        setAccountError(false);
        setDocumentError(false);
        // Reset styles
        setNameOutlineStyle({ borderColor: 'grey' });
        setEmailOutlineStyle({ borderColor: 'grey' });
        setCcNameOutlineStyle({ borderColor: 'grey' });
        setCcEmailOutlineStyle({ borderColor: 'grey' });
    };



    const buildEnvelopeRequest = () => {
        let envelope = {
            docusignAccountId: accountId,
            signerEmailAddress: email,
            signerName: name,
            cCEmailAddress: ccEmail,
            cCName: ccName,
            templateId: templateId,
            referenceId: referenceId
        };
        console.log('building the template:', envelope);
        return envelope;
    };

    const tabStyle = {
        color: 'white', // Change the text color here
        fontFamily: '"Poppins", sans-serif',
        '&.MuiSelected': {
            color: '#00EDED', // Color of the tab text when selected
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)', // Optional: change the focus color
        },
    };

    // Style for helper text
    const helperTextStyle = {
        fontSize: '0.75rem', // Adjust the font size as needed
        fontFamily: '"Poppins", sans-serif',
        lineHeight: 'normal', // Adjust the line height as needed
    };

    const checkFormValidity = () => {
        const isAccountValid = selectedAccount !== null;
        const isRecipientNameValid = name.trim() !== '';
        const isRecipientEmailValid = email.trim() !== '' && !emailError;
        const isDocumentValid = template !== null;
        // Both CC fields must be valid or empty
        const isCcValid = (!ccName && !ccEmail) || (ccName && ccEmail && !ccEmailError && !ccNameError);

        return isAccountValid && isRecipientNameValid && isRecipientEmailValid && isDocumentValid && isCcValid;
    };


    //useEffect(() => {
    //    setIsFormValid(checkFormValidity());
    //}, [selectedAccount, name, email, template, emailError, ccName, ccNameError, ccEmail, ccEmailError, documentError]);

    const overlayStyle = {
        position: 'absolute',
        top: '116px',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'grey',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2, // Ensure it's above everything else
        pointerEvents: 'all', // Make sure it can capture click events and prevent interactions below it
    };

    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
    });


    const [searchQuery, setSearchQuery] = useState('');
    const [filteredEnvelopes, setFilteredEnvelopes] = useState(envelopes);


    useEffect(() => {
        if (!searchQuery) {
            setFilteredEnvelopes(envelopes); // If the search query is empty, show all rows
            return;
        }
        const lowercasedQuery = searchQuery.toLowerCase();
        const filtered = envelopes.filter((envelope) =>
            Object.values(envelope).some((value) =>
                String(value).toLowerCase().includes(lowercasedQuery)
            )
        );
        setFilteredEnvelopes(filtered);
    }, [searchQuery, envelopes]);


    const validateAllFields = () => {
        // Assume all fields are valid initially
        let valid = true;

        // Validate each field individually

        // Add validation for account selection
        if (!selectedAccount) {
            setAccountError(true); // Assuming you use a boolean for accountError state
            // Optionally, you can also use a specific error message similar to other fields if you have a method like setAccountErrorMessage
            valid = false;
        } else {
            setAccountError(false); // Clear any account error if the account is selected
        }

        // Example for name and email, extend this to include your other validations
        if (!name.trim()) {
            setNameError('This field is required');
            setNameOutlineStyle({ borderColor: 'red' });
            valid = false; // Mark as invalid if any field fails validation
        } else {
            setNameError('');
            setNameOutlineStyle({ borderColor: 'grey' });
        }

        if (!email.trim()) {
            setEmailError('This field is required');
            setEmailOutlineStyle({ borderColor: 'red' });
            valid = false;
        } else {
            // Use the existing validateEmail function to check for format
            validateEmail(email);
            if (emailError) valid = false;
        }

        // Validation for CC Email and CC Name
        if (ccEmail.trim() && !ccName.trim()) {
            setCcNameError('CC Name is required if CC Email is provided');
            setCcNameOutlineStyle({ borderColor: 'red' });
            valid = false;
        } else {
            setCcNameError('');
            setCcNameOutlineStyle({ borderColor: 'grey' });
        }

        if (ccName.trim() && !ccEmail.trim()) {
            setCcEmailError('CC Email is required if CC Name is provided');
            setCcEmailOutlineStyle({ borderColor: 'red' });
            valid = false;
        } else {
            // This additional else block ensures that when ccName is cleared,
            // the error state for ccEmail is also reset if ccEmail is now empty or valid.
            if (!ccName.trim() && !ccEmail.trim()) {
                // If both are now empty, reset any error states for CC Email.
                setCcEmailError('');
                setCcEmailOutlineStyle({ borderColor: 'grey' });
            } else if (ccEmail.trim()) {
                // If CC Email is not empty, validate its format again.
                validateCcEmail(ccEmail);
                if (ccEmailError) valid = false;
            }
        }

        // Add validation for document selection
        if (!template) { // Assuming 'template' is null or undefined when not selected
            setDocumentError('A document must be selected'); // Assuming you have a setDocumentError method
            valid = false;
        } else {
            setDocumentError(''); // Clear any error if the document is selected
        }

        // Include similar checks for CC Name and CC Email, ensuring you call validateCcName and validateCcEmail as needed

        // Return the overall validity
        return valid;
    };

    const searchableColumns = ['referenceId', 'email', 'status', 'statusDateTime'];

    useEffect(() => {
        if (!searchQuery) {
            setFilteredEnvelopes(envelopes); // If the search query is empty, show all rows
            return;
        }
        const lowercasedQuery = searchQuery.toLowerCase();
        const filtered = envelopes.filter((envelope) =>
            searchableColumns.some((column) => {
                const value = envelope[column];
                // Special handling for dates
                if (column === 'statusDateTime') {
                    // Assuming your date is stored in a format that can be converted to a Date object
                    const dateValue = value ? new Date(value).toLocaleString() : '';
                    return dateValue.toLowerCase().includes(lowercasedQuery);
                }
                // For all other columns, just check if the string includes the query
                return String(value).toLowerCase().includes(lowercasedQuery);
            })
        );
        setFilteredEnvelopes(filtered);
    }, [searchQuery, envelopes]);



    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ width: '100%', bgcolor: '#121212', position: 'relative', paddingBottom: 2 }}>
                <Grid sx={{ bgcolor: '#000' }} container spacing={2} alignItems="center">
                    <Grid item xs={12} sx={{ ml: 2, mt: 3, mb: 3 }}>
                        {!connected && (
                            <IconButton onClick={getConnection} color="red">
                                <FontAwesomeIcon icon={faWifiExclamation} color="red" />
                                <Typography variant="body1" sx={{
                                    fontFamily: '"Poppins", sans-serif', ml: 1, color: 'red'
                                }}>
                                    Not Connected (Click to connect)
                                </Typography>
                            </IconButton>
                        )}
                        {connected && (
                            <Box display="flex" alignItems="center">
                                <FontAwesomeIcon icon={faWifi} style={{ color: '#00A000' }} />
                                <Typography variant="body1" sx={{
                                    ml: 1,
                                    fontFamily: '"Poppins", sans-serif', color: '#00A000'
                                }}>
                                    Connected as {userEmail}
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>

                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: '#000' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="Docusign Tabs"
                            sx={{
                                '.MuiTab-root': { // Styles for all tabs
                                    color: 'white', // Default color for unselected tabs
                                    fontFamily: '"Poppins", sans-serif',
                                },
                                '.Mui-selected': { // This targets the selected tab specifically
                                    color: '#00EDED', // Color for the selected tab
                                },
                                '.MuiTabs-indicator': {
                                    backgroundColor: '#00EDED', // Indicator color
                                },
                            }}
                        >
                            <Tab label="Send Document" value="1" {...a11yProps(0)} sx={tabStyle} disabled={!connected} />


                            <Tab label="View History" value="2" {...a11yProps(1)} sx={tabStyle} disabled={!connected} />
                        </Tabs>

                    </Box>
                    <TabPanel value={value} index="1">
                        <Box sx={{ p: 2, bgcolor: '#121212', color: 'white', borderRadius: 2, boxShadow: 1 }}>
                            {!connected && (
                                <div style={overlayStyle}>
                                    <Typography variant="h6"sx={{
                                    ml: 1,
                                    fontFamily: '"Poppins", sans-serif', color: 'blue'
                                }}>Connection Needed</Typography>
                                </div>
                            )}
                            <Grid xs={12} item>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                mb: 3,
                                                fontFamily: "'Poppins', sans-serif",
                                                fontWeight: 400, // Use a regular weight for a lighter appearance
                                                fontSize: '1.4rem' // Adjust the font size as needed, this example uses 1.25rem for a smaller size
                                            }}
                                        >
                                            Send Document For Signature
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} alignItems="center">
                                    {/* Assuming you want the account dropdown to take full width on smaller screens and half on larger screens like other fields */}
                                    <Grid item xs={12}>
                                        {connected && (
                                            <Autocomplete
                                                labelId="account-select-label"
                                                value={selectedAccount}
                                                onChange={(event, newValue) => {
                                                    setSelectedAccount(newValue);
                                                    if (newValue) {
                                                        setAccountId(newValue.account_id); // Store the selected account ID when an account is selected
                                                        setAccountError(false); // Clear the account error when a valid account is selected
                                                    } else {
                                                        setAccountId(''); // Clear the account ID if the selection is cleared
                                                        // Do not set the error here - it will be managed upon form submission/validation
                                                    }
                                                }}
                                                inputValue={inputAccount}
                                                onInputChange={(event, newInputValue) => {
                                                    setInputAccount(newInputValue);
                                                }}
                                                isOptionEqualToValue={(option, value) => option.account_id === value.account_id}
                                                id="account-select"
                                                options={accounts}
                                                getOptionLabel={(option) => option ? option.account_name : ''}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Account"
                                                        required
                                                        variant="outlined"
                                                        error={accountError}
                                                        helperText={accountError ? "Please select an account." : ""}
                                                        style={{ marginLeft: '8px' }}
                                                        InputLabelProps={{
                                                            style: {
                                                                color: 'grey',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                fontSize: '18px',
                                                                fontFamily: '"Poppins", sans-serif',
                                                            },
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            style: {
                                                                color: 'white',
                                                                fontSize: '18px',
                                                                fontFamily: '"Poppins", sans-serif',
                                                            },
                                                            sx: {
                                                                '& .MuiOutlinedInput-notchedOutline': nameOutlineStyle,
                                                                '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                                                                '.MuiSvgIcon-root ': {
                                                                    fill: "rgba(255, 255, 255, 0.7) !important",
                                                                }
                                                            },
                                                        }}
                                                    />
                                                )}
                                                autoSelect
                                                autoHighlight
                                            />

                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="filled-basic"
                                            inputRef={nameRef}
                                            onFocus={handleFocus}
                                            label="Recipient Name"
                                            variant="outlined"
                                            fullWidth
                                            value={name}
                                            onChange={(event) => {
                                                const newName = event.target.value;
                                                setName(newName);

                                                // If you want to clear the error as soon as the user starts typing (optional):
                                                if (newName.trim() && nameError) {
                                                    setNameError('');
                                                    setNameOutlineStyle({ borderColor: 'grey' }); // Reset the outline to default
                                                }
                                                // Note: You might want to move validation to a separate function that's called on form submission
                                            }}
                                            //onBlur={() => validateName(name)} // Trigger validation on blur
                                            InputLabelProps={{
                                                style: {
                                                    color: 'grey',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '18px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    color: 'white',
                                                    fontSize: '18px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                }, // Adjust text color and width
                                                sx: {
                                                    '& .MuiOutlinedInput-notchedOutline': {borderColor: nameOutlineStyle},
                                                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                                                },
                                            }}
                                            sx={{ m: 1 }} // Adjust margin and width as needed
                                            required
                                            error={!!nameError}
                                            helperText={nameError}
                                            FormHelperTextProps={{ style: helperTextStyle }}
                                            autoComplete="off"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="filled-basic"
                                            ref={emailRef}
                                            label="Recipient Email Address"
                                            variant="outlined"
                                            fullWidth
                                            value={email}
                                            onChange={(event) => {
                                                const newEmail = event.target.value;
                                                setEmail(newEmail);

                                                // Clear the error if the user starts correcting the specific issue
                                                if (emailError === 'This field is required' && newEmail.trim()) {
                                                    // Clear the error if it was about the field being empty and now it's not
                                                    setEmailError('');
                                                    setEmailOutlineStyle({ borderColor: 'grey' }); // Reset the outline to default
                                                } else if (emailError === 'Invalid email format' && validateEmailFormat(newEmail)) {
                                                    // Clear the error if it was about invalid format and now the format is correct
                                                    setEmailError('');
                                                    setEmailOutlineStyle({ borderColor: 'grey' }); // Reset the outline to default
                                                }
                                                // Note: The actual validation function that checks the format needs to be defined elsewhere
                                                // Example validation function (you might already have something similar)
                                                // This function only checks the format, does not set any states
                                            }}
                                            //onBlur={() => validateEmail(email)} // Trigger validation on blur
                                            InputLabelProps={{
                                                style: {
                                                    color: 'grey',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '18px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    color: 'white',
                                                    fontSize: '18px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                }, // Adjust text color and width
                                                sx: {
                                                    '& .MuiOutlinedInput-notchedOutline': emailOutlineStyle,
                                                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                                                },
                                            }}
                                            sx={{ m: 1 }} // Adjust margin and width as needed
                                            required
                                            error={!!emailError}
                                            helperText={emailError}
                                            FormHelperTextProps={{ style: helperTextStyle }}
                                            autoComplete="off"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="filled-basic"
                                            ref={ccNameRef}
                                            label="CC Name"
                                            variant="outlined"
                                            fullWidth
                                            value={ccName}
                                            onChange={(event) => {
                                                const newCcName = event.target.value;
                                                setCcName(newCcName);

                                                // Clear the error if the CC Email is filled and the user starts entering a CC Name
                                                if (ccNameError && newCcName.trim()) {
                                                    setCcNameError('');
                                                    setCcNameOutlineStyle({ borderColor: 'grey' }); // Reset the outline to default
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: 'grey',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '18px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    color: 'white',
                                                    fontSize: '18px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                }, // Adjust text color and width
                                                sx: {
                                                    '& .MuiOutlinedInput-notchedOutline': ccNameOutlineStyle,
                                                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                                                },
                                            }}
                                            sx={{ m: 1 }} // Adjust margin and width as needed
                                            error={!!ccNameError}
                                            helperText={ccNameError}
                                            FormHelperTextProps={{ style: helperTextStyle }}
                                            autoComplete="off"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="filled-basic"
                                            ref={ccEmailRef}
                                            label="CC Email Address"
                                            variant="outlined"
                                            fullWidth
                                            value={ccEmail}
                                            onChange={(event) => {
                                                const newCcEmail = event.target.value;
                                                setCcEmail(newCcEmail);

                                                // Clear the error if the user starts correcting the specific issue
                                                if (ccEmailError === 'This field is required' && newCcEmail.trim()) {
                                                    // Clear the error if it was about the field being empty and now it's not
                                                    setCcEmailError('');
                                                    setCcEmailOutlineStyle({ borderColor: 'grey' }); // Reset the outline to default
                                                } else if (ccEmailError === 'Invalid email format' && validateEmailFormat(newCcEmail)) {
                                                    // Clear the error if it was about invalid format and now the format is correct
                                                    setCcEmailError('');
                                                    setCcEmailOutlineStyle({ borderColor: 'grey' }); // Reset the outline to default
                                                }
                                                // Note: You may need to call validateCcEmail() if you're validating CC Email format elsewhere
                                            }}
                                            //onBlur={() => validateCcEmail(ccEmail)} // Trigger validation on blur
                                            InputLabelProps={{
                                                style: {
                                                    color: 'grey',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '18px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    color: 'white',
                                                    fontSize: '18px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                }, // Adjust text color and width
                                                sx: {
                                                    '& .MuiOutlinedInput-notchedOutline': ccEmailOutlineStyle,
                                                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                                                },
                                            }}
                                            sx={{ m: 1 }} // Adjust margin and width as needed
                                            error={!!ccEmailError}
                                            helperText={ccEmailError}
                                            FormHelperTextProps={{ style: helperTextStyle }}
                                            autoComplete="off"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="filled-basic"
                                            ref={sageSalesOrderNumberRef}
                                            label="Sage Sales Order Number"
                                            variant="outlined"
                                            fullWidth
                                            value={referenceId}
                                            onChange={(e => {
                                                setReferenceId(e.target.value);
                                            })}
                                            //onBlur={() => validateSageSalesOrderNumber(sageSalesOrderNumber)} // Trigger validation on blur
                                            InputLabelProps={{
                                                style: {
                                                    color: 'grey',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '18px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                },
                                            }}
                                            InputProps={{
                                                style: {
                                                    color: 'white',
                                                    fontSize: '18px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                }, // Adjust text color and width
                                                sx: {
                                                    '& .MuiOutlinedInput-notchedOutline': sageSalesOrderNumberOutlineStyle,
                                                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                                                },
                                            }}
                                            sx={{ m: 1 }} // Adjust margin and width as needed
                                            error={!!sageSalesOrderNumberError}
                                            helperText={sageSalesOrderNumberError}
                                            FormHelperTextProps={{ style: helperTextStyle }}
                                            autoComplete="off"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            value={template}
                                            onChange={(event, newValue) => {
                                                setTemplate(newValue);
                                                if (newValue) {
                                                    setTemplateId(newValue.templateId); // Store the selected template ID
                                                    setDocumentError(''); // Clear the document selection error when a valid document is selected
                                                } else {
                                                    // Don't set an error if they blank it out, just clear the selection
                                                    setTemplateId(''); // Clear the template ID as well
                                                }
                                            }}
                                            inputValue={inputTemplate}
                                            onInputChange={(event, newInputValue) => {
                                                setInputTemplate(newInputValue);
                                            }}
                                            isOptionEqualToValue={(option, value) => option.templateId === value.templateId}
                                            id="template-autocomplete"
                                            options={templates}
                                            getOptionLabel={(option) => option ? option.name : ''}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Document"
                                                    required
                                                    variant="outlined"
                                                    // Keep or remove the error prop based on when you decide to validate this field
                                                    error={documentError}
                                                    helperText={documentError ? "Please select a document." : ""}
                                                    style={{ marginLeft: '8px' }}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: 'grey',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            fontSize: '18px',
                                                            fontFamily: '"Poppins", sans-serif',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        style: {
                                                            color: 'white',
                                                            fontSize: '18px',
                                                            fontFamily: '"Poppins", sans-serif',
                                                        },
                                                        sx: {
                                                            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'grey' },
                                                            '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#00EDED' },
                                                            '.MuiSvgIcon-root ': {
                                                                fill: "rgba(255, 255, 255, 0.7) !important",
                                                            }
                                                        },
                                                    }}
                                                />
                                            )}
                                            autoSelect
                                            autoHighlight
                                        />

                                    </Grid>
                                </Grid>
                                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mt: 2 }}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => sendEnvelope()}
                                        tabIndex={0} // Ensure this is before the tabIndex of the Reset button
                                        style={buttonStyle}
                                    >
                                        <SendIcon fontSize="smaller" /> Send
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={() => clearAll()}
                                        style={buttonStyleSecondary}
                                        tabIndex={1} // This follows the Send button in tab order
                                    >
                                        <CancelIcon fontSize="smaller" /> Reset
                                    </Button>
                                </Box>
                            </Grid>
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index="2">

                        <TextField
                            id="search-field"
                            label="Search"
                            variant="outlined"
                            fullWidth
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            sx={{ mb: 2 }} // Add some margin bottom for spacing
                            InputProps={{
                                style: {
                                    color: 'white', // Adjust as needed
                                    fontFamily: '"Poppins", sans-serif',
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searchQuery && (
                                            <IconButton
                                                aria-label="clear search"
                                                onClick={() => setSearchQuery('')}
                                                edge="end"
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                                // Apply custom styles for focus
                                sx: {
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'grey', // Default border color
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#00EDED', // Seafoam green on hover
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#00EDED', // Seafoam green on focus
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: 'grey', // Adjust as needed
                                    fontFamily: '"Poppins", sans-serif',
                                },
                            }}
                        />

                        <Box sx={{ height: '70vh', width: '100%' }}>
                            <StyledDataGrid
                                rows={filteredEnvelopes}
                                columns={columns}
                                getRowClassName={getRowClassName}
                                sx={{
                                    '& .MuiDataGrid-cell:focus-within': {
                                        outline: 'none', // Removes cell focus outline
                                    },
                                    '& .MuiDataGrid-cell:focus': {
                                        outline: 'none', // Additional removal of focus styles if needed
                                    },
                                    // Include other style overrides here
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 25]}
                                //checkboxSelection
                                disableRowSelectionOnClick
                            />
                        </Box>
                    </TabPanel>
                </TabContext><Snackbar
                    open={snackOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackOpen(false)}
                    message={snackMessage}
                    action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackOpen(false)}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />
            </Box>
            <PdfViewerModal
                pdfUrl={pdfUrl}
                bearerToken={jwtToken}
                open={open}
                onClose={() => setOpen(false)} />
        </ThemeProvider>
    );
}

function validateEmailFormat(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}

export default Docusign;