import { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AdminGrid from "./AdminGrid";
import { faDuck } from "@fortawesome/pro-duotone-svg-icons";
import { faMagnifyingGlass, faPuzzlePiece } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, TextField } from "@mui/material";

function Features({ hubConnection, sessionId }) {
    const [list, setList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [editingModel, setEditingModel] = useState(null);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 300
        },
        {
            field: 'name',
            headerName: 'Feature Name',
            width: 150,
            editable: true,
        }
    ];

    useEffect(() => {
        if (!hubConnection) return;

        const subscribeToEvents = () => {
            hubConnection.on('FeatureList', (data) => {
                setLoading(true);
                // console.log('FeatureList received:', data);
                setList(data);
            });

            hubConnection.on('Feature', (data) => {
                setEditingModel(data);
            });

            // Add other event subscriptions here
        };

        subscribeToEvents();

        hubConnection.invoke("GetFeatures", sessionId, searchTerm)
            .then(() => {
                setLoading(true);
            })
            .catch(error => console.error("Error sending message:", error));

        return () => {
            hubConnection.off('FeatureList');
            hubConnection.on('Feature');
            // Unsubscribe from other events here
        };
    }, [hubConnection]);

    const filter = () => {
        if (!hubConnection) return;
        hubConnection.invoke("GetFeatures", sessionId, searchTerm)
            .then(() => {
                setLoading(true);
            })
            .catch(error => console.error("Error sending message:", error));
    };

    const handleRowClick = (params) => {
        setRowSelectionModel([params.id]);
    };

    return (
        <Box sx={{ height: '65vh', marginLeft: '40px', marginRight: '40px' }}>
            <Grid container spacing={2}>
                <AdminGrid
                    columns={columns}
                    editingModel={editingModel}
                    filter={filter}
                    handleRowClick={handleRowClick}
                    hubConnection={hubConnection}
                    list={list}
                    rowSelectionModel={rowSelectionModel}
                    searchTerm={searchTerm}
                    sessionId={sessionId}
                    setEditingModel={setEditingModel}
                    setSearchTerm={setSearchTerm}
                    title={"Feature"}
                />
            </Grid>
        </Box>
    );
}

export default Features;