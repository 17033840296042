import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useChatConnections } from '../../utils/ChatConnectionProvider';
import Message from './Message';
import MessageBusy from './MessageBusy';

function MessageList({
  messages,
  user,
  hitchUser,
  users,
  chatContainerRef,
  isHelpful,
  isNotHelpful,
  createNewTab,
  saveEditedMessage,
  setEditingMessage,
  setEditedMessageText,
  editedMessageText,
  editingMessage,
  isTabletMobile,
  isMobile,
  isTablet,
  isInChatDrawer,
  chatContainerHeight
}) {
  const theme = useTheme();
  const themeBackgroundColor = theme.palette.secondary[theme.palette.mode];
  const { isTyping } = useChatConnections();

  const [editingWidth, setEditingWidth] = useState(null);
  const [hoveredMessageId, setHoveredMessageId] = useState(null);

  const startEditingMessage = (message) => {
    setEditingMessage(message);
    setEditedMessageText(message.text ? message.text.join('\n') : '');

    const currentWidth = chatContainerRef.current.offsetWidth + 'px';
    setEditingWidth(currentWidth);
  };

  function messageIsHelpful(message) {
    isHelpful(message.messageId);
  }

  function messageIsNotHelpful(message) {
    isNotHelpful(message.messageId);
  }

  // Scroll to the bottom when messages change
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages, isTyping]);

  return (
    <Box
      ref={chatContainerRef}
      sx={{
        backgroundColor: themeBackgroundColor,
        height: chatContainerHeight,
        overflowY: 'auto',
        width: '100%',
      }}
    >
      {messages.map((message, index) => (
        <Message
          key={index}
          editedMessageText={editedMessageText}
          editingMessage={editingMessage}
          hoveredMessageId={hoveredMessageId}
          index={index}
          message={message}
          messageIsHelpful={messageIsHelpful}
          messageIsNotHelpful={messageIsNotHelpful}
          saveEditedMessage={saveEditedMessage}
          setEditedMessageText={setEditedMessageText}
          setEditingMessage={setEditingMessage}
          setHoveredMessageId={setHoveredMessageId}
          startEditingMessage={startEditingMessage}
          user={user}
          hitchUser={hitchUser}
          users={users}
          createNewTab={createNewTab}
          isTabletMobile={isTabletMobile}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      ))}
      {isTyping && (
        <MessageBusy isMobile={isMobile} isTablet={isTablet} />
      )}
    </Box>
  );
}

export default MessageList;
