import React, { useEffect } from 'react';
import { Grid, Typography, Box, FormControlLabel, Checkbox, Select, MenuItem, FormControl, InputLabel, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import StoreDocumentsGrid from '../BotAdmin/StoreDocumentsGrid';
import BotOrgChartStyles from './BotOrgChartStyles';

function BotFileManagement({
    editData,
    editMode,
    expandedSection,
    formFields,
    handleFileSearchChange,
    handleVectorStoreChange,
    setExpandedSection,
    vectorStores,
    useVectorStore,
    setUseVectorStore
}) {
    const {
        themePrimaryTextColor,
        themeSecondaryBackgroundColor
    } = BotOrgChartStyles();


    return (
        <Accordion id="section5" elevation={0} expanded={expandedSection.section5} onChange={() => setExpandedSection({ ...expandedSection, section5: !expandedSection.section5 })}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />} sx={{ backgroundColor: themeSecondaryBackgroundColor }}>
                <Typography variant="h6" sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>File Management & Storage</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: themeSecondaryBackgroundColor }}>
                <Box>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <>
                                {editMode && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={editData?.fileSearch}
                                                onChange={handleFileSearchChange}
                                                name="fileSearch"
                                                color="primary"
                                                // disabled={!editMode}
                                                sx={{
                                                    color: themePrimaryTextColor,
                                                    '&.Mui-checked': { color: themePrimaryTextColor },
                                                    '&.Mui-disabled': { color: themePrimaryTextColor },
                                                }}
                                            />
                                        }
                                        label={<span style={{ color: themePrimaryTextColor }}>Enable Hitch Document Storage</span>}
                                        sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}
                                    />
                                )}
                            </>
                        </Grid>


                        {editData?.fileSearch && (
                            <>
                                <Grid item xs={12} md={6}>
                                    {editMode && editData?.fileSearch && (
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel
                                                id="vector-store-label"
                                                sx={{
                                                    color: themePrimaryTextColor,
                                                    '&.Mui-focused': {
                                                        color: themePrimaryTextColor
                                                    },
                                                    fontFamily: '"Poppins", sans-serif'
                                                }}
                                            >
                                                Select a Hitch Document Storage
                                            </InputLabel>
                                            <Select
                                                labelId="vector-store-label"
                                                id="vectorStore"
                                                name="vectorStore"
                                                value={editData?.selectedVectorStore}
                                                onChange={handleVectorStoreChange}
                                                label="Select a Hitch Document Storage"
                                                sx={{
                                                    color: themePrimaryTextColor,
                                                    fontSize: '16px',
                                                    fontFamily: '"Poppins", sans-serif',
                                                    background: themeSecondaryBackgroundColor,
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: formFields.vectorStore.outlineStyle.borderColor
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: formFields.vectorStore.outlineStyleActive.borderColor
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: formFields.vectorStore.outlineStyleActive.borderColor
                                                    },
                                                    '.MuiSvgIcon-root': {
                                                        fill: 'rgba(255, 255, 255, 0.7) !important'
                                                    }
                                                }}
                                            >
                                                <MenuItem value="">
                                                    <em>--Select--</em></MenuItem>
                                                {vectorStores.map((store) => (
                                                    <MenuItem key={store.id} value={store.id}>
                                                        {store.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                    {!editMode && editData?.fileSearch && (
                                        <FormControl fullWidth variant="outlined">
                                            <Typography sx={{
                                                    color: themePrimaryTextColor,
                                                    '&.Mui-focused': {
                                                        color: themePrimaryTextColor
                                                    },
                                                    fontFamily: '"Poppins", sans-serif'
                                                }} variant='h6'>Store: {editData?.selectedVectorStore}</Typography>
                                        </FormControl>
                                    )}

                                </Grid>

                                <Grid item xs={12}>
                                    <StoreDocumentsGrid
                                        path={editData?.selectedVectorStore}
                                        handleUpload={(event) => console.log("Handle upload", event)}
                                        handleView={(id) => console.log("Handle view", id)}
                                        handleDownload={(id) => console.log("Handle download", id)}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export default BotFileManagement;
