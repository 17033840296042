import { Avatar, Box, Divider, Grid, Tooltip, Typography } from "@mui/material";

function SelectService({ availableServices, submitServiceId }) {

    function selectService(serviceId) {
        console.log('serviceId', serviceId);
        submitServiceId(serviceId);
    }

    return (
        <Box>
            <Typography>Select A Service</Typography>
            <Divider />

            {/* Grid container with centered content */}
            <Grid container spacing={2} justifyContent="center">
                {availableServices && availableServices.map((svc) => (
                    <Grid item xs={12} sm={6} md={4} lg={2} xl={2} key={svc.id} justifyContent="center">
                        <Tooltip title={svc.name}>
                            <Avatar 
                                src={`${window._env_.HITCH_API}/api/accimage/${svc.id}.png`}
                                onClick={() => selectService(svc.id)}
                                style={{ cursor: 'pointer' }}
                            />
                        </Tooltip>
                        <Typography variant="caption" align="center">{svc.name}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default SelectService;
