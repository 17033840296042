import React, { useEffect, useState } from 'react';
import { Typography, IconButton, TextField, Select, MenuItem, InputLabel, FormControl, Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import HitchRoleEditor from './HitchRoleEditor';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import HitchMenuItem from './HitchMenuItem';
import { useModal } from '../../utils/HitchModalContext/HitchModalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-solid-svg-icons';
import { faRotateLeft } from '@fortawesome/pro-duotone-svg-icons';

const HitchTreeView = ({ accountId, data, updateData }) => {
    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState(null);
    const [workingData, setWorkingData] = useState([]);
    const { requestConfirm } = useModal();

    useEffect(() => {
        // console.log('data is', data);
        setWorkingData(data);
    }, [data]);

    useEffect(() => {
        // console.log('working data updated', workingData);
    }, [workingData]);

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleSelect = (event, nodeId) => {
        setSelected(nodeId);
    };

    const handleAddMenu = () => {
        const newMenuData = newMenu();
        setWorkingData(prevData => [...prevData, newMenuData]);
    };

    const handleAddRole = (menuType, itemLabel) => {
        const newRole = 'new_role'; // This could be a default role or something more dynamic
        const updatedData = workingData.map(menu => {
            if (menu.menuType === menuType) {
                return {
                    ...menu, 
                    items: menu.items.map(item => {
                        if (item.label === itemLabel) {
                            return { ...item, roles: [...item.roles, newRole] };
                        }
                        return item;
                    })
                };
            }
            return menu;
        });
        setWorkingData(updatedData);
    };
    

    const handleUpdateRole = (menuType, itemLabel, oldRole, newRole) => {
        const updatedData = workingData.map(menu => {
            if (menu.menuType === menuType) {
                return {
                    ...menu, 
                    items: menu.items.map(item => {
                        if (item.label === itemLabel) {
                            const updatedRoles = item.roles.map(role => role === oldRole ? newRole : role);
                            return { ...item, roles: updatedRoles };
                        }
                        return item;
                    })
                };
            }
            return menu;
        });
        setWorkingData(updatedData);
    };
    

    const handleDeleteRole = (menuType, itemLabel, roleToDelete) => {
        const updatedData = workingData.map(menu => {
            if (menu.menuType === menuType) {
                return {
                    ...menu, 
                    items: menu.items.map(item => {
                        if (item.label === itemLabel) {
                            const filteredRoles = item.roles.filter(role => role !== roleToDelete);
                            return { ...item, roles: filteredRoles };
                        }
                        return item;
                    })
                };
            }
            return menu;
        });
        setWorkingData(updatedData);
    };
    

    function deleteMenu(menuType) {
        requestConfirm("Are you sure?", () => {
            const filteredData = workingData.filter(menu => menu.menuType !== menuType);
            // Update the state with the filtered data
            setWorkingData(filteredData);
            // console.log("Menu removed");
        });
    }

    const newMenu = () => {
        return {
            accountId: accountId,
            menuType: 'new_menu',
            items: [
                {
                    label: uuidv4(),
                    action: 'actionHere',
                    icon: 'newIcon',
                    roles: [
                        'admin'
                    ]
                }
            ]
        };
    }

    const newItem = () => {
        return {
            label: uuidv4(),
            action: 'actionHere',
            icon: 'newIcon',
            roles: [
                'admin'
            ]

        }
    }

    const handleDeleteItem = (menuType, itemLabel) => {
        // Confirm the action with the user
        requestConfirm("Are you sure?", () => {
            // Map over the workingData to update the items array of the matched menu
            const updatedData = workingData.map(menu => {
                if (menu.menuType === menuType) {
                    // Filter out the item to delete
                    const updatedItems = menu.items.filter(item => item.label !== itemLabel);
                    return { ...menu, items: updatedItems };
                }
                return menu;
            });
            // Update the state with the filtered data
            setWorkingData(updatedData);
            // console.log("Item removed");
        });
    };


    const handleUpdateItem = (itemLabel, field, newValue) => {
        const updatedData = workingData.map(menu => {
            const updatedItems = menu.items.map(item => {
                if (item.label === itemLabel) {
                    return { ...item, [field]: newValue };
                }
                return item;
            });
            return { ...menu, items: updatedItems };
        });
        setWorkingData(updatedData);
    };

    function saveData() {
        updateData(workingData);
    }

    function revertData() {
        setWorkingData(data);
    }

    function handleUpdateMenuType(menuType, newLabel) {
        const updatedData = workingData.map(menu => {
            if (menu.menuType === menuType) {
                return { ...menu, menuType: newLabel };
            }
            return menu;
        });
        setWorkingData(updatedData);
    }

    function handleDeleteMenu(menuType) {
        // console.log('removing menuType', menuType);
        requestConfirm("Are you sure?", () => {
            const filteredData = workingData.filter(menu => menu.menuType !== menuType);
            setWorkingData(filteredData);
            // console.log("Menu removed");
        });
    }

    const handleAddItem = (menuType) => {
        // console.log(`adding a new menu item to menu ${menuType}`);
        const newMenuItem = newItem(); // Create a new menu item
        const updatedData = workingData.map(menu => {
            if (menu.menuType === menuType) {
                return { ...menu, items: [...menu.items, newMenuItem] }; // Add the new item to the menu
            }
            return menu;
        });
        setWorkingData(updatedData); // Update the state with the new data
    };

    const renderTree = (nodes) =>
        nodes.map((menu) => (
            <HitchMenuItem
                key={menu.menuType}
                nodeId={menu.menuType}
                isDeleteable={true}
                label={menu.menuType}
                initialValue={menu.menuType}
                onSave={(newLabel) => handleUpdateMenuType(menu.menuType, newLabel)}
                onDelete={() => handleDeleteMenu(menu.menuType)}
                onChild={() => handleAddItem(menu.menuType)}
                addChild={true}
                isEditable={true}
            >
                {menu.items.map((item) => (
                    <HitchMenuItem
                        key={`${menu.menuType}-${item.label}`} // Ensure key is unique
                        nodeId={`${menu.menuType}-${item.label}`} // Make nodeId unique by combining menuType and item.label
                        label={item.label}
                        isDeleteable={true}
                        onDelete={() => handleDeleteItem(menu.menuType, item.label)}
                    >
                        <HitchMenuItem
                            nodeId={`${menu.menuType}-${item.label}-action`} // Adjust nodeId to be unique
                            label='Action'
                            initialValue={item.action}
                            isEditable={true}
                            onSave={(value) => handleUpdateItem(item.label, 'action', value)} />

                        <HitchMenuItem
                            nodeId={`${menu.menuType}-${item.label}-icon`} // Adjust nodeId to be unique
                            label={'Icon'}
                            initialValue={item.icon}
                            isEditable={true}
                            onSave={(value) => handleUpdateItem(item.label, 'icon', value)} />

                        <HitchMenuItem
                            nodeId={`${menu.menuType}-${item.label}-label`} // Adjust nodeId to be unique
                            label={'Label'}
                            initialValue={item.label}
                            isEditable={true}
                            onSave={(value) => handleUpdateItem(item.label, 'label', value)} />

                        <HitchRoleEditor
                            roles={item.roles}
                            menuType={menu.menuType}
                            itemLabel={item.label}
                            onAddRole={handleAddRole}
                            onUpdateRole={handleUpdateRole}
                            onDeleteRole={handleDeleteRole}
                        />

                        {/* <TreeItem
                            nodeId={`${menu.menuType}-${item.label}-roles`} // Adjust nodeId to be unique
                            label="Roles"
                            style={{ fontStyle: 'italic', color: 'gray' }}
                        >
                            {item.roles && item.roles.length > 0 ? (
                                item.roles.map((role, index) => (
                                    <TreeItem key={`${menu.menuType}-${item.label}-role-${index}`} nodeId={`${menu.menuType}-${item.label}-role-${index}`} label={role} />
                                ))
                            ) : (
                                <TreeItem key={`${menu.menuType}-${item.label}-no-roles`} nodeId={`${menu.menuType}-${item.label}-no-roles`} label="No roles" />
                            )}
                        </TreeItem> */}
                    </HitchMenuItem>
                ))}
            </HitchMenuItem>
        ));

    return (
        <div>
            <Typography variant="h5">Menu Tree</Typography>
            <TreeView
                defaultCollapseIcon={<span>-</span>}
                defaultExpandIcon={<span>+</span>}
                expanded={expanded}
                onNodeToggle={handleToggle}
                selected={selected}
                onNodeSelect={handleSelect}
            >
                {renderTree(workingData)}
            </TreeView>

            <div style={{ marginTop: '20px' }}>
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddMenu}>
                    Add Menu
                </Button>
                <Button variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faSave} />} onClick={saveData}>
                    Apply Menus
                </Button>
                <Button variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faRotateLeft} />} onClick={revertData}>
                    Revert
                </Button>
            </div>
        </div>
    );
};

export default HitchTreeView;
