import {
    Grid,
    Modal,
    Box,
} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ChatBoxV2 from './ChatBoxV2';
import LinearColorV2 from "./LinearColorV2";
import ConversationTabsV2 from './ConversationTabsV2';
import LeftConversationDrawerV2 from './LeftConversationDrawerV2';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import isValidGUID from '../utils/IsValidGuid';
import RightConversationDrawerV2 from './RightConversationDrawerV2';
import { useQualifierChat } from '../Qualifier/QualifierChatProvider';

function ConversationV2({ noHistory, redirectPath, shouldFocusChatBox, maxWidth, overrideHeight, isInChatDrawer, drawerHeightValue, noHeader }) {
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [tabValueV2, setTabValueV2] = useState('history');
    const [leftConversationDrawerOpen, setLeftConversationDrawerOpen] = useState(false);
    const [hitchPhone, setHitchPhone] = useState(false);
    const chatContainerRef = useRef(null);
    const history = useNavigate();
    const [chatSessionId, setChatSessionId] = useState('');
    const [useChatGpt, setUseChatGpt] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [isChatBoxActive, setIsChatBoxActive] = useState(false);
    const [editingMessage, setEditingMessage] = useState(null);
    const [editedMessageText, setEditedMessageText] = useState('');
    const theme = useTheme();
    const themeBackgroundColor = theme.palette.secondary[theme.palette.mode];
    const themeSecondaryTextColor = theme.palette.secondaryText[theme.palette.mode];
    const isTabletMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    // const {
    //     dashboardConnection,
    //     chatConnection,
    //     setChatConnection,
    //     jwtToken,
    //     hitchUser,
    //     user,
    //     isAuthenticated,
    //     setHitchUser,
    //     dashStarted,
    //     setSessionId,
    //     loading,
    //     conversationHistories,
    //     matrixRoutes,
    //     started,
    //     hitchCommand,
    //     setMessages,
    //     messages,
    //     conversation,
    //     users
    // } = useHubConnections();
    const {
        conversation,
        guid,
        hitchCommand,
        hitchUser,
        leadId,
        messages,
        parentUrl,
        qualifierHub,
        setMessages
    } = useQualifierChat();

    const iconButtonStyle = {
        position: 'absolute',
        top: isMobile ? '60px' : isTablet ? '74px' : '76px',
        zIndex: 2,
    };

    const handleLeftConversationDrawerToggle = () => {
        if (noHistory) {
            setLeftConversationDrawerOpen(false);
        } else {
            setLeftConversationDrawerOpen(!leftConversationDrawerOpen);
        }
        // console.log('handleLeftConversationDrawerToggle - leftConversationDrawerOpen:', leftConversationDrawerOpen);
    };

    const [chatBoxKey, setChatBoxKey] = useState(0);

    const focusChatBoxInput = () => {
        setIsChatBoxActive(true);
        setChatBoxKey(prevKey => prevKey + 1);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
        setIsChatBoxActive(false);
    };

    const openSidebar = () => {
        setSidebarOpen(true);
        setIsChatBoxActive(false);
    };

    const requestCancel = () => {
        hitchCommand("chat", "thread", "cancel");
    };


    useEffect(() => {
        focusChatBoxInput();
    }, []);

    useEffect(() => {
        if (shouldFocusChatBox) {
            focusChatBoxInput();
        }
    }, [shouldFocusChatBox]);

    useEffect(() => {
        setLeftConversationDrawerOpen(!isTabletMobile);
        if (noHistory) {
            setLeftConversationDrawerOpen(false);
        }
    }, [isTabletMobile]);

    useEffect(() => {
        if (chatContainerRef.current) {
            const element = chatContainerRef.current;
            element.scrollTop = element.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'h' && event.altKey) {
                // console.log('Alt-H was pressed');
                setHitchPhone(true);
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    function handlePhoneClose() {
        setHitchPhone(false);
    }

    function errorHandler(error) {
        console.error("SignalR Error", error);
    }

    const handleSendMessage = () => {
        if (newMessage.content !== '') {
            if(!hitchUser) {
                return;
            }
            hitchCommand('chat', 'conversation', 'message-add', newMessage, () => { });
            const newMessageToAdd = {
                text: newMessage.content,
                content: newMessage.content,
                contentType: 'text',
                type: 'user',
                user: hitchUser.uuid
            };
            setMessages(prevMessages => [...prevMessages, newMessageToAdd]);
            setNewMessage({ content: '', contentType: 'text', user: null });
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValueV2(newValue);
    };

    const deleteConversation = (conversationId) => {
        hitchCommand('chat', 'conversation', 'delete', { deleteSessionId: conversationId });
    };

    const setName = (conversationName, conversationId) => {
        if (qualifierHub) {
            qualifierHub.invoke("HitchCommand", conversationId, 'chat', 'conversation', 'set-name', { newName: conversationName })
                .then(() => {
                    // console.log('yay');
                })
                .catch(error => {
                    // console.error('boo', error);
                });
        } else {
            console.error('Chat session is dead yo.');
        }
    };

    const isHelpful = (messageId) => {
        hitchCommand('chat', 'conversation', 'message-helpful', messageId);
    };

    const isNotHelpful = (messageId) => {
        hitchCommand('chat', 'conversation', 'message-not-helpful', messageId);
    };

    const saveEditedMessage = () => {
        if (editingMessage) {
            editingMessage.text = [editedMessageText];
            setEditingMessage(null);
            setEditedMessageText('');
        }
    };

    

    const supportStyle = {
        backgroundColor: themeBackgroundColor,
        //width: `calc(100vw)`,
        //height: 'calc(100vh)',
    };

    return (
        <div style={supportStyle}>
            <Grid item xs={12} style={{ backgroundColor: themeBackgroundColor, display: 'flex', alignItems: 'center', maxWidth: maxWidth }}>
               
                     <ConversationTabsV2
                            // user={user}
                            hitchUser={hitchUser}
                            conversation={conversation}
                            useChatGpt={useChatGpt}
                            setUseChatGpt={setUseChatGpt}
                            guid={guid}
                            messages={messages}
                            // users={users}
                            chatContainerRef={chatContainerRef}
                            isHelpful={isHelpful}
                            isNotHelpful={isNotHelpful}
                            saveEditedMessage={saveEditedMessage}
                            setEditingMessage={setEditingMessage}
                            setEditedMessageText={setEditedMessageText}
                            setName={setName}
                            editedMessageText={editedMessageText}
                            editingMessage={editingMessage}
                            isTabletMobile={isTabletMobile}
                            isMobile={isMobile}
                            isTablet={isTablet}
                            overrideHeight={overrideHeight}
                            maxWidth={maxWidth}
                            isInChatDrawer={isInChatDrawer}
                            noHeader={noHeader}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ backgroundColor: themeBackgroundColor, display: 'flex', alignItems: 'center', maxWidth: maxWidth }}>
                        <ChatBoxV2
                            key={chatBoxKey}
                            newMessage={newMessage}
                            handleSendMessage={handleSendMessage}
                            setNewMessage={setNewMessage}
                            isChatBoxActive={isChatBoxActive}
                            hitchUser={hitchUser}
                            leftConversationDrawerOpen={leftConversationDrawerOpen}
                            isMobile={isTabletMobile}
                            isInChatDrawer={isInChatDrawer}
                            drawerHeight={drawerHeightValue}
                            maxWidth={maxWidth}
                        />
                        {loading &&
                            <LinearColorV2
                                requestCancel={requestCancel} />
                        }

            </Grid>
        </div>
    );
}

export default ConversationV2;
