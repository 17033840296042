import { Avatar, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";

function StepListItem({ step, selectedStep, setSelectedStep }) {
    console.log('step', step);

    return (
        <ListItemButton
            alignItems="flex-start"
            selected={selectedStep === step.step_id}
            onClick={(evt) => setSelectedStep(step.step_id)}>
            <ListItemAvatar>
                <Avatar
                    alt={`${step.system}`}
                    src={`${window._env_.HITCH_API}/api/accimage/${step._serviceId}.png`}
                />
            </ListItemAvatar>
            <ListItemText
                primary={step.system}
                secondary={
                    <>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                        >
                            {step.operation_type} - {step.operation_subtype}
                        </Typography>
                        {` — ${step.step_id}`}
                    </>
                }>
            </ListItemText>
        </ListItemButton>
    )
}

export default StepListItem;