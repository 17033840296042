import { faDuck, faMagnifyingGlass, faPuzzlePiece } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonGroup, Grid, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import AdminGrid from "./AdminGrid";

function Connections({ hubConnection, sessionId, activeOrganization }) {
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [editingModel, setEditingModel] = useState(null);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 300,
    },
    {
      field: "name",
      headerName: "Connection Name",
      width: 150,
      editable: true,
    },
  ];

  useEffect(() => {
    if (hubConnection) {
      const subscribeToEvents = () => {
        hubConnection.on("ConnectionList", (data) => {
          setLoading(true);
          // console.log("ConnectionList received:", data);
          setList(data);
        });

        hubConnection.on("Connection", (data) => {
          setEditingModel(data);
        });

        // Add other event subscriptions here
      };

      subscribeToEvents();

      hubConnection
        .invoke("GetConnections", sessionId, activeOrganization.id, searchTerm)
        .then(() => {
          setLoading(true);
        })
        .catch((error) => console.error("Error sending message:", error));

      return () => {
        hubConnection.off("ConnectionList");
        hubConnection.off("Connection");
        // Unsubscribe from other events here
      };
    }
  }, [hubConnection]);

  const filter = () => {
    if (hubConnection) {
      hubConnection
        .invoke("GetConnections", sessionId, activeOrganization.id, searchTerm)
        .then(() => {
          setLoading(true);
        })
        .catch((error) => console.error("Error sending message:", error));
    }
  };

  const handleRowClick = (params) => {
    setRowSelectionModel([params.id]);
  };

  return (
    <Box sx={{ height: "65vh", marginLeft: "40px", marginRight: "40px" }}>
      <Grid container spacing={2}>
        <AdminGrid
          columns={columns}
          editingModel={editingModel}
          filter={filter}
          handleRowClick={handleRowClick}
          hubConnection={hubConnection}
          list={list}
          rowSelectionModel={rowSelectionModel}
          searchTerm={searchTerm}
          sessionId={sessionId}
          setEditingModel={setEditingModel}
          setSearchTerm={setSearchTerm}
          title={"Connection"}
        />
      </Grid>
    </Box>
  );
}

export default Connections;