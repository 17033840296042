import React, { createContext, useContext, useState, useEffect } from 'react';
import userManager from '../services/authService';
import * as signalR from '@microsoft/signalr';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';


const HubConnectionsContext = createContext();

export const HubConnectionsProvider = ({ children }) => {
    const [account, setAccount] = useState({});
    const [dashboardConnection, setDashboardConnection] = useState(null);
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [jwtToken, setJwtToken] = useState(null);
    const [hitchUser, setHitchUser] = useState(null);
    const [isConnectionReady, setIsConnectionReady] = useState(false);
    const [sessionId, setSessionId] = useState();
    const [started, setStarted] = useState(false);
    const [startOnce, setStartOnce] = useState(false);
    const [notRegistered, setNotRegistered] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [noSubscription, setNoSubscription] = useState(false);
    const [hitchUserId, setHitchUserId] = useState(null);


    // Snackbar functions
    function defaultSnack(message) {
        enqueueSnackbar(message, { variant: "default" });
    }
    function infoSnack(message) {
        enqueueSnackbar(message, { variant: "info" });
    }
    function warningSnack(message) {
        enqueueSnackbar(message, { variant: "warning" });
    }
    function successSnack(message) {
        enqueueSnackbar(message, { variant: "success" });
    }
    function errorSnack(message) {
        enqueueSnackbar(message, { variant: "error" });
    }

    // Listen to userManager events
    useEffect(() => {
        const handleUserLoaded = async (loadedUser) => {
            // Handle user loaded event here
            console.log('User loaded event:', loadedUser);
            setUser(loadedUser); // Update user state
            setJwtToken(loadedUser.id_token);
            // Optionally fetch additional data or trigger actions
        };

        // Add event listener
        userManager.events.addUserLoaded(handleUserLoaded);

        // Clean up event listener on unmount
        return () => {
            userManager.events.removeUserLoaded(handleUserLoaded);
        };
    }, []);

    useEffect(() => {
        console.log('hitchUser', hitchUser);
        if(hitchUser && hitchUser.id !== hitchUserId) {
            setHitchUserId(hitchUser.userId);
        }
    }, [hitchUser]);

    useEffect(() => {
        console.log('hitchUserId', hitchUserId);
    }, [hitchUserId]);

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.invoke("AccountRequest")
                .then(() => { })
                .catch((error) => console.error("AccountRequest error:", error));
        }
    }, [dashboardConnection, isConnectionReady]);

    useEffect(() => {

    }, [notRegistered]);


    useEffect(() => {
        if (account && !account.subscriptionId) {
            setNoSubscription(true);
        }
        if (account && account.subscriptionId) {
            setNoSubscription(false);
        }
    }, [account]);

    // Authentication and User State Initialization
    useEffect(() => {
        async function checkAuthentication() {
            try {
                const authenticated = await userManager.getUser();
                if (authenticated) {
                    // Check if the token has expired
                    if (authenticated.expires_in < 0) {
                        console.log('Attempting to refresh token...');
                        try {
                            // Attempt to use the refresh token to get a new access token
                            const refreshed = await userManager.signinSilent(); // This method varies based on your userManager implementation
                            console.log('Token refreshed successfully');
                            setUser(refreshed);
                            setIsAuthenticated(true);
                            setJwtToken(refreshed.id_token);
                        } catch (refreshError) {
                            console.error('Error refreshing token:', refreshError);
                            //redirectToLogin();
                        }
                    } else {
                        // Token is still valid
                        setUser(authenticated);
                        setIsAuthenticated(true);
                        setJwtToken(authenticated.id_token);
                    }
                }
            } catch (error) {
                console.error('Error checking authentication:', error);
                // redirectToLogin();
            }
        }

        function redirectToLogin() {
            console.log('Redirecting to login...');
            const currentAddress = `${window.location.pathname}${window.location.search}`;
            window.location.href = `/login?redirect=${encodeURIComponent(currentAddress)}`;
        }

        checkAuthentication();
    }, []);

    // Fetch Hitch User
    useEffect(() => {
        if (user && user.id_token) {
            fetch(`${window._env_.HITCH_API}/api/ephesians_1_3`, {
                headers: {
                    'Authorization': `Bearer ${user.id_token}`
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status === 404) {
                        setNotRegistered(true);
                        return Promise.reject('User not registered.');
                    } else {
                        return Promise.reject('Failed to fetch hitchUser.');
                    }
                })
                .then(data => {
                    setHitchUser(data);
                    if (dashboardConnection) {
                        fetchInitialData(dashboardConnection);
                    }
                    if (!data.accountId || data.accountId === '00000000-0000-0000-0000-000000000000') {
                        setNotRegistered(true);
                    }
                })
                .catch(error => {
                    if (error === "") {

                    }

                    console.error('Error getting account metadata:', error);
                });
        }
    }, [user]);

    // Dashboard Connection Initialization
    useEffect(() => {
        if (jwtToken && hitchUserId && !startOnce) {
            let isMounted = true; // Track mounted status
            setStartOnce(true);

            const connection = new signalR.HubConnectionBuilder()
                .withUrl(`${window._env_.HITCH_API}/sessionHub?sessionId=${hitchUserId}&chatMode=dash`, {
                    accessTokenFactory: () => jwtToken
                })
                .withAutomaticReconnect()
                .build();

            const startConnection = async () => {
                try {
                    await connection.start();
                    setDashboardConnection(connection);
                    setIsConnectionReady(true);
                    fetchInitialData(connection); // Assuming fetchInitialData is async
                    connection.on('exception', exceptionUi);
                    connection.on("UpdateAccount", setAccount);
                    // HANDLER FOR GET 
                    connection.on('7c286f12-37fd-4085-a8e6-53955e1c8d07', inboundUser); // USER 
                    connection.on('5177a0fa-23ca-49cd-9c0e-f9d31490a27c', inboundAccount);
                    connection.on('0cbdf1b2-ecfb-4aed-82fe-130591037a0e', inboundAccount);

                } catch (error) {
                    if (isMounted) {
                        console.error('Connection failed to start:', error);
                        setStartOnce(false);
                        setIsConnectionReady(false);
                    }
                }
            };

            startConnection();

            // Cleanup
            return () => {
                console.log('DISCONNECTING!');
                isMounted = false; // Indicate component has unmounted
                connection.off('exception', exceptionUi);
                connection.off("UpdateAccount", setAccount);
                connection.off('7c286f12-37fd-4085-a8e6-53955e1c8d07', inboundUser);
                connection.off('5177a0fa-23ca-49cd-9c0e-f9d31490a27c', inboundAccount);
                connection.off('0cbdf1b2-ecfb-4aed-82fe-130591037a0e', inboundAccount);
                connection.stop().then(() => console.log('Connection stopped'));
                setIsConnectionReady(false);
                setStartOnce(false);
            };
        }
    }, [jwtToken, hitchUserId]); //, startOnce]);


    function inboundUser(data) {
        console.log('inboundUser', data);
        if (hitchUser) {
            setHitchUser(prevState => ({
                ...prevState,
                defaultPhoneNumber: data.defaultPhoneNumber,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email
            }));
        }
    }

    function inboundAccount(data) {
        console.log('inboundAccount', data);
        if (hitchUser) {
            setHitchUser(prevState => ({
                ...prevState,
                accountId: data.id,
                accountName: data.name
            }));
        }
    }

    useEffect(() => {
        if (sessionId === null) {
            console.log('cookie crap is running.  Is this impacting?');
            const cookieName = 'hitchvisitor';  // Name of the cookie to store sessionId
            let sessionId = document.cookie.replace(/(?:(?:^|.*;\s*)${cookieName}\s*\=\s*([^;]*).*$)|^.*$/, "$1");
            if (!sessionId && !isAuthenticated) {
                sessionId = uuidv4();
                document.cookie = `${cookieName}=${sessionId}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
            }
            setSessionId(sessionId);
        }
    }, [isAuthenticated, sessionId]);

    function fetchInitialData(connection) {
        if (connection && isConnectionReady) {
            connection.invoke("AccountRequest")
                .then(() => { })
                .catch((error) => console.error("AccountRequest error:", error));

            connection.invoke("UserRequest")
                .then(() => { })
                .catch((error) => console.error("UserRequest error:", error));
        } else {
            console.error('attempt to send to hitch command without an active connection!');
        }
    }

    function exceptionUi(data) {
        errorSnack(data.message);
    }

    function hitchDashCommand(service, feature, operation, payload = {}, onSuccess, onError) {
        const payloadWithToken = { ...payload };

        console.log('isConnectionReady', isConnectionReady);

        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.invoke("HitchCommand", hitchUser.userId, service, feature, operation, payloadWithToken)
                .then(() => {
                    if (onSuccess && typeof onSuccess === 'function') {
                        onSuccess();
                    }
                })
                .catch(error => {
                    if (onError && typeof onError === 'function') {
                        onError(error);
                    } else {
                        console.error(`Dash connection not established. service: ${service} / feature: ${feature} / operation: ${operation}`);
                        console.error("HitchCommand error:", error);
                    }
                });
        } else {
            console.error(`Dash connection not established. service: ${service} / feature: ${feature} / operation: ${operation}`);
        }
    }

    const [tacoData, setTacoData2] = useState([]);

    function setTacoData(data) {
        console.log('Taco Data Set => ', data);
        setTacoData2(data);
    }

    const value = {
        account,
        dashboardConnection,
        jwtToken,
        hitchUser,
        user,
        isAuthenticated,
        setHitchUser,
        isConnectionReady,
        setSessionId,
        started,
        hitchDashCommand,
        setStartOnce,
        defaultSnack,
        infoSnack,
        warningSnack,
        successSnack,
        errorSnack,
        notRegistered,
        setAccount,
        noSubscription,
        tacoData,
        setTacoData
    };

    return (
        <HubConnectionsContext.Provider value={value}>
            {children}
        </HubConnectionsContext.Provider>
    );
};

export const useHubConnections = () => useContext(HubConnectionsContext);
