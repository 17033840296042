import { Button, ButtonGroup, Card, CardActionArea, CardActions, CardContent, CardMedia, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import QuickbooksQuery from "./QuickbooksQuery";
import QbTransformer from "./QbTransformer";
import { useEffect } from "react";
import { useState } from "react";
import QbTransformerSteps from "./QbTransformerSteps";
import QbResults from "./QbResults";
import RunIfSteps from "./RunIfSteps";

function QuickbooksStep(
    {
        step,
        editStep,
        deleteStep,
        updateStep,
        entity,
        entities,
        handleChange,
        columns,
        setEntity,
        setQuery,
        query,
        reset,
        rows,
        runQuery,

        hubConnection,
        sessionId,
        outputOptions,
        outputType,
        allResults,
        setTransformer,
        setTransformerSteps,
        transformerSteps,
        transformer,
        finalStep,
        setFinalStep,
        runIfTrue,
        setRunIfTrue,

        handleMessage
    }) {

    const [lastEntity, setLastEntity] = useState('');
    const [render, setRender] = useState(false);

    const copyToClipboard = (stepId) => {
        // Create a textarea element to temporarily hold the value
        const textArea = document.createElement("textarea");
        textArea.value = `{${stepId}}`;

        // Append the textarea to the document
        document.body.appendChild(textArea);

        // Select the text within the textarea
        textArea.select();

        // Copy the selected text to the clipboard
        document.execCommand("copy");

        // Remove the textarea element
        document.body.removeChild(textArea);

        // Optionally, provide some feedback to the user
        // alert(`Step ID "${step.step_id}" copied to clipboard`);
        handleMessage(`Step ID "${stepId}" copied to clipboard`);
    };
    useEffect(() => {
        if (entity !== lastEntity) {
            //updateStep({ ...step, name: `${entity} Query` });
            setLastEntity(entity);
        }
    }, [entity]);

    useEffect(() => {
        if (render) {
            setRender(false);
        }
        else {
            setRender(true);
        }
    }, [step])

    useEffect(() => {
        if (finalStep) {
            //updateStep({ ...step, name: `Transformation to Chat` });
        } else {
            //updateStep({ ...step, name: `Internal Transformation` });
        }
    }, [finalStep]);

    function addStep(event) {
        const newStep = {
            variable: '',
            lambda: ''
        };

        // Create a copy of the transformerSteps array and add the new step
        const updatedSteps = [...transformerSteps, newStep];

        // Update the state with the new array containing the new step
        setTransformerSteps(updatedSteps);
    }

    function addIf(event) {
        const newStep = {
            variable: '',
            lambda: ''
        };

        // Create a copy of the transformerSteps array and add the new step
        const updatedIfs = [...runIfTrue, newStep];

        // Update the state with the new array containing the new step
        setRunIfTrue(updatedIfs);
    }

    function handleAliasChange(index, value) {
        // Create a copy of the transformerSteps array
        const updatedSteps = [...transformerSteps];
        // Update the alias for the specific step
        updatedSteps[index].variable = value;
        // Update the state with the new array
        setTransformerSteps(updatedSteps);
    }

    function handleIfAliasChange(index, value) {
        // Create a copy of the transformerSteps array
        const updatedIfs = [...runIfTrue];
        // Update the alias for the specific step
        updatedIfs[index].variable = value;
        // Update the state with the new array
        setRunIfTrue(updatedIfs);
    }

    function handleExpressionChange(index, value) {
        // Create a copy of the transformerSteps array
        const updatedSteps = [...transformerSteps];
        // Update the expression for the specific step
        updatedSteps[index].lambda = value;
        // Update the state with the new array
        setTransformerSteps(updatedSteps);
    }

    function handleIfExpressionChange(index, value) {
        // Create a copy of the transformerSteps array
        const updatedIfs = [...runIfTrue];
        // Update the expression for the specific step
        updatedIfs[index].lambda = value;
        // Update the state with the new array
        setRunIfTrue(updatedIfs);
    }

    function removeStep(index) {
        // Create a copy of the transformerSteps array and remove the step at the given index
        const updatedSteps = [...transformerSteps];
        updatedSteps.splice(index, 1); // Remove the step
        console.log('number 6');
        setTransformerSteps(updatedSteps);
    }

    function removeIfStep(index) {
        // Create a copy of the transformerSteps array and remove the step at the given index
        const updatedIfs = [...runIfTrue];
        updatedIfs.splice(index, 1); // Remove the step
        setRunIfTrue(updatedIfs);
    }

    function makeFinal() {
        setFinalStep(!finalStep);
    }

    function addTransformation() {
        // Generate a new GUID for step_id


        // Construct the JSON object for the transformation
        const transformation = {
            step_id: step.step_id,
            system: "Quickbooks",
            operation_type: "Transform",
            transformer_operations: [],
            run_if_true: [],
            transformer_output: transformer,
            production_type: outputType,
            returnMe: finalStep,
            name: step.name
        };

        // Populate transformer_operations based on transformerSteps
        transformerSteps.forEach((step, index) => {
            if (step.variable && step.lambda) {
                transformation.transformer_operations.push({ Variable: step.variable, Lambda: step.lambda });
            }
        });

        runIfTrue.forEach((step, index) => {
            if (step.variable && step.lambda) {
                transformation.run_if_true.push({ Variable: step.variable, Lambda: step.lambda });
            }
        });

        // TODO: PUMP THIS TO SERVER *************************************************
        if (hubConnection) {
            console.log(`transformation`, transformation);
            hubConnection.invoke("AddTransformer", sessionId, transformation);
            console.log('number 7');
            // setTransformerSteps([]);
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    id="txt_step_id"
                    label="Step Id"
                    fullWidth
                    variant="outlined"
                    value={step.step_id} />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="txt_operation"
                    label="Operation"
                    fullWidth
                    variant="outlined"
                    value={step.operation_type} />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="txt_step_name"
                    label="Step Name"
                    fullWidth
                    variant="outlined"
                    onChange={(e) => updateStep({ ...step, name: e.target.value })}
                    value={step.name} />
            </Grid>
            <QbResults
                allResults={allResults}
                copyToClipboard={copyToClipboard}
            />
            {/* UPDATE THIS FOR ITS OWN PROPERTIES */}
            <RunIfSteps
                addIf={addIf}
                handleAliasChange={handleIfAliasChange}
                handleExpressionChange={handleIfExpressionChange}
                removeStep={removeIfStep}
                runIfTrue={runIfTrue}
            />

            <QbTransformerSteps
                addStep={addStep}
                handleAliasChange={handleAliasChange}
                handleExpressionChange={handleExpressionChange}
                removeStep={removeStep}
                transformerSteps={transformerSteps}
            />

            {step?.operation_type === 'Query' && (
                <Grid item xs={12}>
                    <QuickbooksQuery
                        columns={columns}
                        entity={entity}
                        entities={entities}
                        setEntity={setEntity}
                        setQuery={setQuery}
                        query={query}
                        reset={reset}
                        rows={rows}
                        runQuery={runQuery}
                        handleChange={handleChange} />
                </Grid>
            )}
            {step?.operation_type === 'Transform' && (
                <Grid item xs={12}>
                    <QbTransformer
                        step={step}
                        hubConnection={hubConnection}
                        sessionId={sessionId}
                        outputOptions={outputOptions}
                        outputType={outputType}
                        allResults={allResults}
                        setTransformer={setTransformer}
                        setTransformerSteps={setTransformerSteps}
                        transformerSteps={transformerSteps}
                        transformer={transformer}
                        finalStep={finalStep}
                        setFinalStep={setFinalStep}
                        handleMessage={handleMessage}
                        runIfTrue={runIfTrue}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                    <ButtonGroup>
                        <Button variant={'contained'} onClick={makeFinal} color={finalStep ? 'success' : 'primary'}>{finalStep ? 'THIS WILL SEND TO CHAT' : 'THIS WILL NOT SEND TO CHAT'} (Click to change)</Button>
                        <Button onClick={addTransformation}>Add Transformation</Button>
                        <Button>Reset</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            {/* <Grid item xs={12}>
                <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image={`/img/${(step.queryEntity === null) ? ((step.report_name) ? step.report_name : 'Transformer') : step.queryEntity}.png`}
                            alt={step.queryEntity}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5">{step.step_id}</Typography>
                            <Typography variant="body2" color="text.secondary">
                                {step.query}
                                {step.transformer_output}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <ButtonGroup>
                            <Button size="small" color="primary" onClick={(e) => { editStep(step.step_id); }}>
                                Edit
                            </Button>
                            <Button size="small" color="primary" onClick={(e) => { deleteStep(step.step_id); }}>
                                Remove
                            </Button>
                            <Button size="small" color="primary" onClick={copyToClipboard}>
                                Copy Step Id to Clipboard
                            </Button>
                        </ButtonGroup>
                    </CardActions>
                </Card>
            </Grid> */}
        </Grid>
    )
}

export default QuickbooksStep;
