import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const cardStyle = {
  width: '45%',
  margin: '0 10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '200px',
  borderRadius: '12px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  padding: '16px',
  backgroundColor: '#2b2b2b',
  color: '#ffffff',
};

const cardContentStyle = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const ChangePlanSummary = ({planData}) => {
  const nextPaymentDate = new Date();
  nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '16px' }}>
        <Card style={cardStyle}>
          <CardContent style={cardContentStyle}>
            <Typography variant="h6" component="div" gutterBottom>Current Plan</Typography>
            <Typography variant="body2" component="div">Plan Name: {planData.currentPlan}</Typography>
            <Typography variant="body2" component="div">Price: {planData.currentPlanPrice} / Month</Typography>
            <Typography variant="body2" component="div">Billing Cycle: Monthly</Typography>
          </CardContent>
        </Card>
        <FontAwesomeIcon icon={faArrowRight} fontSize="2xl" style={{ margin: '0 20px', color: '#00EDED' }} />
        <Card style={cardStyle}>
          <CardContent style={cardContentStyle}>
            <Typography variant="h6" component="div" gutterBottom>New Plan</Typography>
            <Typography variant="body2" component="div">Plan Name: {planData.newPlan}</Typography>
            <Typography variant="body2" component="div">Price: {planData.newPlanPrice} / Month</Typography>
            <Typography variant="body2" component="div">Billing Cycle: Monthly</Typography>
          </CardContent>
        </Card>
      </div>
      <hr style={{ width: '100%', borderTop: '1px solid rgba(255, 255, 255, 0.5)', marginBottom: '16px' }} />
      <div style={{ marginBottom: '16px', textAlign: 'left' }}>
        <Typography variant="h6" component="div">Payment Summary</Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
          <Typography variant="body2" component="div">Total Due Today:</Typography>
          <Typography variant="body2" component="div">{planData.dueToday}</Typography>
        </div>
        <div style={{ fontStyle: 'italic', color: 'lightgrey', textAlign: 'right', marginTop: '5px' }}>
          <Typography variant="body2" component="div">+ Taxes where applicable</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', marginTop: '16px' }}>
          <Typography variant="body2" component="div">Next Payment Date:</Typography>
          <Typography variant="body2" component="div">{planData.nextPaymentDate}</Typography>
        </div>
      </div>
    </>
  );
};

export default ChangePlanSummary;
