import React, { useEffect, useState } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    Box,
    Typography,
    IconButton,
    ListItemIcon,
    Divider,
    InputAdornment,
    OutlinedInput,
    Link,
    CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import UploadIcon from '@mui/icons-material/Upload';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import PopupMenu from '../Conversation/PopupMenu'; // Adjust the path as necessary
import { useHubConnections } from '../../utils/HubConnectionsProvider';
import FileViewerModal from '../FileViewer/FileViewerModal';
import { useChatConnections } from '../../utils/ChatConnectionProvider';
import {
    handleFileClick,
    handleSearchInputChange,
    handleFileUploadClick,
    handleFileChange,
    handlePopupAction,
    handleConfirmDelete,
    handleModalClose,
    handleInfoOpen,
    handleInfoClose,
    filesList
} from './FileListHandlers';
import {
    DeleteConfirmationModal,
    InfoModal
} from './FileListModals';
import { FileListStyleProvider, useFileListStyles } from './FileListStyleProvider';
import { getFileIcon } from './FileIcon'; // Import the getFileIcon function

function FileList({ selectedChat }) {
    const theme = useTheme();
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [started, setStarted] = useState(false);
    const [fileContent, setFileContent] = useState(null);
    const [fileContentType, setFileContentType] = useState(null);
    const [fileName, setFileName] = useState(''); 
    const [searchInput, setSearchInput] = useState('');
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);
    const [isInfoOpen, setIsInfoOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null); // New state for error
    const navigate = useNavigate();
    const { user, hitchUser, dashboardConnection, hitchDashCommand, isConnectionReady, getToken } = useHubConnections();
    const { conversation } = useChatConnections();
    const [failCount, setFailCount] = useState(0);
    const [bot, setBot] = useState(null);
    const [filteredFiles, setFilteredFiles] = useState([]);

    const allowedFileTypes = [
        'application/pdf', 'text/plain', 'text/x-c', 'text/x-csharp', 'text/x-c++', 
        'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
        'text/html', 'text/x-java', 'application/json', 'text/markdown', 'text/x-php', 
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', 
        'text/x-python', 'text/x-script.python', 'text/x-ruby', 'text/x-tex', 
        'text/css', 'text/javascript', 'application/x-sh', 'application/typescript'
    ];

    useEffect(() => { 
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on("8df1b111-2b65-4f11-a8e3-a9f268b90875", (response) => {
                //console.log("Files received from service:", response); // Log to verify response
                if (response && response.length > 0) {
                    setFiles(response);
                    setLoading(false); // Stop loading if files are received
                } else {
                    setFiles([]); // Ensure empty array if no files are returned
                    setLoading(false);
                    setError('No documents found.'); // Set an error if no files are returned
                }
            });
            dashboardConnection.on("6ed4f39c-66fe-491a-8a06-aab443a31555", setBot);

            // Set a timeout to stop loading if it takes too long
            const timeoutId = setTimeout(() => {
                if (loading) {
                    setLoading(false);
                    setError('The documents are taking longer than expected to load. Please refresh or try again shortly.');
                }
            }, 10000); // 10 seconds timeout

            return () => {
                clearTimeout(timeoutId);
                if (dashboardConnection) {
                    dashboardConnection.off("8df1b111-2b65-4f11-a8e3-a9f268b90875", filesList);
                    dashboardConnection.off("6ed4f39c-66fe-491a-8a06-aab443a31555", setBot);
                }
            };
        }
    }, [dashboardConnection, isConnectionReady, hitchDashCommand, loading]);

    useEffect(() => {
        if (hitchUser && conversation && dashboardConnection && hitchDashCommand && isConnectionReady) {
            let botId = hitchUser.accountId;
            if (conversation.activeBotId) {
                botId = conversation.activeBotId;
            }
            hitchDashCommand('chat', 'bot', 'get', { 'theBotId': botId });
        }
    }, [hitchUser, conversation, dashboardConnection, hitchDashCommand, isConnectionReady]);

    useEffect(() => {
        if (bot && dashboardConnection && hitchDashCommand && isConnectionReady && !started) {
            if (bot.selectedVectorStore) {
                hitchDashCommand('document', 'file', 'list', { 'RepositoryId': bot.selectedVectorStore }, filesList(setFiles, setLoading, setFailCount, setError));
                if (!started) {
                    setStarted(true);
                    setLoading(true); // Start loading when fetching files
                }
            }
        }
    }, [bot, dashboardConnection, hitchDashCommand, isConnectionReady, started]);

    const themeBackgroundColor = theme.palette.chatLeftMenuBackground[theme.palette.mode];
    const themeTextColor = theme.palette.chatLeftMenuText[theme.palette.mode];
    const themeSecondaryTextColor = theme.palette.chatLeftMenuSecondaryText[theme.palette.mode];
    const themeFontFamily = theme.palette.typography.fontFamilyMain;
    const themeLinkColor = theme.palette.link[theme.palette.mode];
    const themeDividerColor = theme.palette.secondaryDivider[theme.palette.mode];
    const themeModalTextSecondaryColor = theme.palette.modalTextSecondary[theme.palette.mode];

    useEffect(() => {
        const filtered = files.filter(file => {
            const fileType = file.fileContentType && typeof file.fileContentType === 'string' ? file.fileContentType : '';
            //console.log("Processing file with MIME type:", fileType); // Log each file type being processed
            return allowedFileTypes.includes(fileType);
        });

        //console.log("Filtered files array:", filtered); // Log to verify filtered files
        setFilteredFiles(filtered); // Set the filtered files
    }, [files]);

    return (
        <FileListStyleProvider>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: themeBackgroundColor, overflow: 'hidden', fontFamily: "Poppins, sans-serif" }}>
                <Box sx={{ marginTop: '4px', paddingX: 0, flexShrink: 0 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h7" sx={{ paddingLeft: 1, fontFamily: "Poppins, sans-serif", color: themeTextColor, flexGrow: 1 }}>
                            Document List
                        </Typography>
                        <IconButton onClick={() => handleInfoOpen(setIsInfoOpen)} sx={{ color: themeTextColor }}>
                            <FontAwesomeIcon icon={faCircleInfo} style={{ fontSize: '.85rem' }} />
                        </IconButton>
                    </Box>
                    <Divider sx={{ marginTop: '4px', backgroundColor: themeDividerColor }} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginY: '16px' }}>
                        <Link
                            component="button"
                            variant="body2"
                            underline="hover"
                            onClick={handleFileUploadClick}
                            sx={{ marginRight: 1, display: 'flex', alignItems: 'center', cursor: 'pointer', fontFamily: "Poppins, sans-serif", color: themeLinkColor, textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                        >
                            <UploadIcon sx={{ color: themeLinkColor, marginRight: 0.5 }} />
                            Upload New Document
                        </Link>
                        <input
                            id="file-upload-input"
                            type="file"
                            accept=".pdf,.txt,.c,.cs,.cpp,.doc,.docx,.html,.json,.pptx,.py,.css,.js,.ts"  // Restrict file types
                            style={{ display: 'none' }}
                            onChange={(event) => handleFileChange(event, bot, user, dashboardConnection, hitchDashCommand, isConnectionReady, setFiles, setLoading)}
                        />
                    </Box>
                    <OutlinedInput
                        placeholder="Search Documents"
                        fullWidth
                        value={searchInput}
                        onChange={(event) => handleSearchInputChange(event, files, setSearchInput, setFilteredFiles)}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon
                                    sx={{
                                        color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.54)' : '#808080'
                                    }}
                                />
                            </InputAdornment>
                        }
                        sx={{
                            fontFamily: themeFontFamily,
                            color: themeTextColor,
                            height: '40px',
                            borderRadius: 0,
                            maxWidth: '400px', // Set a max-width for the input
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderTop: theme.palette.mode === 'dark' ? '2px solid #333333' : '1px solid #D9D9D9',
                                borderBottom: theme.palette.mode === 'dark' ? '2px solid #333333' : '1px solid #D9D9D9',
                                borderLeft: theme.palette.mode === 'dark' ? '0px solid #333333' : '0px solid #D9D9D9',
                                borderRight: theme.palette.mode === 'dark' ? '0px solid #333333' : '0px solid #D9D9D9'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: themeLinkColor,
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: themeLinkColor,
                            },
                        }}
                    />
                    {loading ? (
                        <Box sx={{ paddingX: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                            <CircularProgress size={24} sx={{ color: themeTextColor }} />
                            <Typography variant="body2" sx={{ fontFamily: "Poppins, sans-serif", color: themeTextColor, marginLeft: 1 }}>
                                Loading documents...
                            </Typography>
                        </Box>
                    ) : error ? (
                        <Typography variant="body2" sx={{ paddingX: 2, fontFamily: "Poppins, sans-serif", color: themeSecondaryTextColor, marginTop: 2, marginBottom: 2 }}>
                            {error}
                        </Typography>
                    ) : filteredFiles.length > 0 ? (
                        <Typography variant="body2" sx={{
                            paddingX: 1, 
                            fontSize: '0.825rem',
                            fontFamily: "Poppins, sans-serif", color: themeTextColor, marginTop: 2, marginBottom: 2
                        }}>
                            Click any document below to view.
                        </Typography>
                    ) : (
                        <Typography variant="body2" sx={{ paddingX: 1, fontFamily: "Poppins, sans-serif", color: themeSecondaryTextColor, marginTop: 2, marginBottom: 2 }}>
                            No files found. Start by uploading your first document.
                        </Typography>
                    )}
                </Box>

                <Box sx={{ marginBottom: "16px", flexShrink: 1, overflowY: 'auto', backgroundColor: themeBackgroundColor, minHeight: '14vh', maxHeight: '64vh', paddingY: 0, paddingX: 1, borderRadius: 1 }}>
                    <List sx={{ padding: 0 }}>
                        {filteredFiles.map((file, index) => (
                            <React.Fragment key={index}>
                                <ListItem sx={{ paddingY: 0.5, cursor: 'pointer', '&:hover': { backgroundColor: 'transparent' }, paddingLeft: 0, paddingRight: 0 }} onClick={() => handleFileClick(file, bot, user, setSelectedFile, setFileContent, setFileName, setFileContentType)}>
                                    <ListItemIcon sx={{ minWidth: '24px', marginLeft: 0 }}>
                                        {getFileIcon(file?.name)} 
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={file.name}
                                        primaryTypographyProps={{
                                            sx: {
                                                fontFamily: "Poppins, sans-serif",
                                                fontSize: '0.875rem', 
                                                color: themeSecondaryTextColor,
                                                textDecoration: 'none',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                '&:hover': { textDecoration: 'underline', color: themeLinkColor }
                                            }
                                        }}
                                    />
                                    <PopupMenu
                                        onAction={(action) => handlePopupAction(action, file, bot, user, setIsDeleteConfirmationOpen, setFileToDelete)}
                                        item={file}
                                        menuOptions={[
                                            { label: "Download", action: "download", icon: "faDownload" },
                                            { label: "Delete", action: "delete", icon: "faTrash" }
                                        ]} 
                                    />
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
                <FileViewerModal
                    open={Boolean(selectedFile)}
                    onClose={() => handleModalClose(setSelectedFile, setFileContent, setFileName)}
                    fileContent={fileContent}
                    fileContentType={fileContentType}
                    fileName={fileName} 
                />
                <DeleteConfirmationModal
                    open={isDeleteConfirmationOpen}
                    onClose={() => handleModalClose(setIsDeleteConfirmationOpen)}
                    fileToDelete={fileToDelete}
                    onConfirmDelete={() => handleConfirmDelete(fileToDelete, setFiles, setFilteredFiles, setIsDeleteConfirmationOpen, bot, dashboardConnection, hitchDashCommand, isConnectionReady)}
                />
                <InfoModal
                    open={isInfoOpen}
                    onClose={() => handleInfoClose(setIsInfoOpen)}
                />
            </Box>
        </FileListStyleProvider>
    );
}

export default FileList;
