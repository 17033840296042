import React, { useState } from 'react';
import ConnectToApp from './ConnectToApp';
import ConnectionSetupInProgress from './ConnectionSetupInProgress';
import ConnectionSuccess from './ConnectionSuccess';
import AnalyzingConnection from './AnalyzingConnection';
import ConnectionReady from './ConnectionReady';
import './ConnectionWizard.css';
import stepsData from './connectionSteps.json'; 

function ConnectionWizard() {
  const [step, setStep] = useState(1);
  const [showCancelPrompt, setShowCancelPrompt] = useState(false);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleCancel = () => {
    setShowCancelPrompt(true);
  };

  const handleCloseCancelPrompt = () => {
    setShowCancelPrompt(false);
  };

  const handleConfirmCancel = () => {
    setShowCancelPrompt(false);
    window.location.href = '/';
  };

  const componentMap = {
    ConnectToApp,
    ConnectionSetupInProgress,
    ConnectionSuccess,
    AnalyzingConnection,
    ConnectionReady,
  };

  const renderStepContent = (currentStep) => {
    const Component = componentMap[currentStep.navigation];
    return (
      <Component
        onNext={handleNext}
        onBack={handleBack}
        onCancel={handleCancel}
      />
    );
  };

  const renderStepIndicator = (currentStep) => {
    return (
      <div className="step-indicator-connection">
        {stepsData.map((step, index) => (
          <div key={step.stepId} className="step-item-connection">
            <div
              className={`step-circle-connection ${step.stepId === currentStep.stepId ? 'active' : ''}`}
            >
              {step.stepId}
            </div>
            <div className="step-label-connection">{step.label}</div>
            {index < stepsData.length - 1 && <div className="step-line-connection" />}
          </div>
        ))}
      </div>
    );
  };

  const orderedStepsData = stepsData.sort((a, b) => a.stepId - b.stepId);
  const currentStepIndex = step - 1;
  const totalSteps = orderedStepsData.length;
  const currentStep = orderedStepsData[currentStepIndex];

  return (
    <div className="container-setup">
      <div className="white-box-setup">
        <div className="step-indicator-connection">
          {renderStepIndicator(currentStep)}
        </div>

        <div className="content-setup">
          {renderStepContent(currentStep)}
        </div>
      </div>
    </div>
  );
}

export default ConnectionWizard;
