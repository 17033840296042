import { AppBar, Avatar, Button, Grid, Modal, Paper, TextField, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHubConnections } from "../../utils/HubConnectionsProvider";

function EditConnection({ sessionId }) {
    const { dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady } = useHubConnections();
    const { guid } = useParams();
    const [account, setAccount] = useState('');
    const [routes, setRoutes] = useState([]);
    const [configuration, setConfiguration] = useState({});
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [newPropName, setNewPropName] = useState('');
    const [newPropValue, setNewPropValue] = useState('');
    const [validationError, setValidationError] = useState('');

    
    useEffect(() => {
        if (dashboardConnection && isConnectionReady && guid && hitchUser) {
            dashboardConnection.on("ee7b5c3b-3359-4870-8a12-31e3b03901d8", (data) => {
                // console.log('ee7b5c3b-3359-4870-8a12-31e3b03901d8', data);
                setAccount(data);
            });
            dashboardConnection.on("72d23de7-efb6-45c1-b67a-e06bd00d4739", (data) => {
                // PULL THIS FROM API
                // console.log("72d23de7-efb6-45c1-b67a-e06bd00d4739", data);
                setRoutes(data);
            });
            dashboardConnection.on("7b1c3e23-2ed9-4839-b805-1f0c51425774", (data) => {
                // console.log("7b1c3e23-2ed9-4839-b805-1f0c51425774", data);
                setConfiguration(data);
                setLoading(false);
            });
            setLoading(true);
            hitchDashCommand('account', 'connection', 'get', {
                TheAccountId: hitchUser.accountId,
                TheConnectionId: guid
            });
            hitchDashCommand('account', 'connection', 'route', {
                TheConnectionId: guid
            });
            hitchDashCommand('account', 'connection', 'get-configuration', {
                TheConnectionId: guid
            });
            return (() => {
                dashboardConnection.off("ee7b5c3b-3359-4870-8a12-31e3b03901d8");
                dashboardConnection.off("72d23de7-efb6-45c1-b67a-e06bd00d4739");
                dashboardConnection.off("7b1c3e23-2ed9-4839-b805-1f0c51425774");
            });
        }
    }, [dashboardConnection, isConnectionReady, guid, hitchUser])

    // Function to handle configuration changes
    const handleConfigChange = (key, value) => {
        setConfiguration(prev => ({ ...prev, [key]: value }));
    };

    // Function to save configuration
    const saveConfiguration = () => {
        if (dashboardConnection && isConnectionReady) {
            hitchDashCommand('account', 'connection', 'update-configuration', {
                TheConnectionId: guid,
                ConfigurationBody: configuration
            });

            // Replace this with the actual command to send the updated configuration to the server
            // console.log('Saving configuration:', configuration);
        } else {
            console.error("SignalR is not connected");
        }
    };

    // Function to revert configuration to its initial state
    const revertConfiguration = () => {
        hitchDashCommand('account', 'connection', 'get-configuration', {
            TheConnectionId: guid
        });
        setLoading(true);
        // You will need to store the initial configuration separately if you want to implement revert functionality
    };

    const validateNewProp = () => {
        if (!newPropName || /\s/.test(newPropName) || !isNaN(newPropName.charAt(0))) {
            setValidationError('Invalid key name. Ensure no spaces, starts with a letter, and is not blank.');
            return false;
        }
        if (configuration.hasOwnProperty(newPropName)) {
            setValidationError('Key already exists.');
            return false;
        }
        setValidationError('');
        return true;
    };

    const handleAddNewProp = () => {
        if (validateNewProp()) {
            setConfiguration(prev => ({ ...prev, [newPropName]: newPropValue }));
            handleCloseModal();
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setNewPropName('');
        setNewPropValue('');
        setValidationError('');
    };

    return (
        <Grid container spacing={2} direction="column">
            {/* Top Banner */}
            <Grid item>
                <AppBar position="static" color="default">
                    <Toolbar>
                        <Avatar src={`${window._env_.HITCH_API}/api/accimage/${account.serviceId}.png`} sx={{ marginRight: 2 }} />
                        <Typography variant="h6" component="div" flexGrow={1}>
                            Service Name: {account.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Grid>

            {/* Configuration Section */}
            <Grid item spacing={2}>

                <Paper sx={{ padding: 2 }}>
                    <Typography variant="h6">Configuration</Typography>
                    <Grid container spacing={0}> {/* Add this line */}
                        {Object.entries(configuration).map(([key, value]) => (
                            <Grid item xs={4} key={key}> {/* Change xs={12} to xs={6} */}
                                <TextField
                                    fullWidth
                                    label={key}
                                    value={value}
                                    onChange={(e) => handleConfigChange(key, e.target.value)}
                                    margin="normal"
                                />
                            </Grid>
                        ))}
                    </Grid> {/* Close the Grid container here */}
                    <Button onClick={() => setModalOpen(true)} variant="contained" color="primary">Add Configuration Property</Button>
                    <Button onClick={saveConfiguration} variant="contained" color="primary">Save</Button>
                    <Button onClick={revertConfiguration} variant="outlined" color="secondary">Revert</Button>
                </Paper>

            </Grid>

            {/* Tokens, Events */}
            <Grid item container spacing={2}>
                <Grid item xs={6}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6">Tokens</Typography>
                        <Typography>User (Exp: 10:04 AM)</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6">Events</Typography>
                        <Typography>Auth Event</Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Modal open={modalOpen} onClose={handleCloseModal}>
                <Paper sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: 4, width: 400 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Add New Property</Typography>
                    <TextField fullWidth label="Property Name" value={newPropName} onChange={(e) => setNewPropName(e.target.value)} margin="normal" error={!!validationError} helperText={validationError} />
                    <TextField fullWidth label="Value" value={newPropValue} onChange={(e) => setNewPropValue(e.target.value)} margin="normal" />
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={6}>
                            <Button fullWidth onClick={handleAddNewProp} variant="contained" color="primary">Add</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth onClick={handleCloseModal} variant="outlined" color="secondary">Cancel</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>

            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
        </Grid>
    );
}

export default EditConnection;