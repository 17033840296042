import { Box, IconButton, Modal } from "@mui/material"
import BotOrgChartDetail from "./BotOrgChartDetail"
import CloseIcon from '@mui/icons-material/Close'; // For close button in modal
import { useEffect, useState } from "react";


function BotDetailModal({botId, handleCloseModal }){
    useEffect(() => {
        // console.log('botId is ', botId);
    }, [botId]);
    return (
    <Modal
        open={botId !== null}
        onClose={handleCloseModal}
        aria-labelledby="bot-org-chart-detail-modal"
        aria-describedby="bot-org-chart-detail-description"
      >
          <BotOrgChartDetail botId={botId} onClose={handleCloseModal} />
        
      </Modal>)
};
export default BotDetailModal