import React, { createContext, useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

// Context creation
const HitchModalContext = createContext();

export const useModal = () => useContext(HitchModalContext);

// Update the name of the ModalProvider to HitchModalProvider
export const HitchModalProvider = ({ children }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmText, setConfirmText] = useState('');
    const [onConfirm, setOnConfirm] = useState(() => () => {});

    const requestConfirm = (text, confirmCallback) => {
        setConfirmText(text);
        setOnConfirm(() => confirmCallback);
        setModalOpen(true);
    };

    const handleClose = () => setModalOpen(false);
    const handleConfirm = () => {
        onConfirm();
        handleClose();
    };

    return (
        <HitchModalContext.Provider value={{ requestConfirm }}>
            {children}
            <Dialog open={modalOpen} onClose={handleClose}>
                <DialogTitle>{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{confirmText}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        No
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </HitchModalContext.Provider>
    );
};

