import { Typography } from "@mui/material";
import { useSettingsStyles } from "../common/SettingsStyleProvider";

function OrgLabel({label, data }) {
    const {font, themePrimaryTextColor} = useSettingsStyles();

    return (
        <>
            <div>
                <Typography variant="body1" gutterBottom style={{
                    color: themePrimaryTextColor, textAlign: 'right', marginRight: '8px',
                    fontFamily: font,
                }}>
                    {label}:
                </Typography>
            </div>
            <div>
                <Typography variant="body1" gutterBottom style={{
                    color: themePrimaryTextColor,
                    fontFamily: font,
                }}>
                    {data}
                </Typography>
            </div>
        </>

    );
}

export default OrgLabel;