import { useTheme } from '@mui/material/styles';
import UserNode from './UserNode';
import HitchyNode from './HitchyNode';
import HitchBotNode from './HitchBotNode';
import ServiceNode from './ServiceNode';

const BotOrgChartStyles = () => {
    const theme = useTheme();

    const themePrimaryTextColor = theme.palette.primaryText[theme.palette.mode];

    const themeSecondaryBackgroundColor = theme.palette.secondary[theme.palette.mode];
    const themeSecondaryTextColor = theme.palette.secondaryText[theme.palette.mode];

    const themeBackgroundColor = theme.palette.primary[theme.palette.mode];
    const themePrimaryButtonColor = theme.palette.primaryButton[theme.palette.mode];
    const themePrimaryButtonTextColor = theme.palette.primaryButtonText[theme.palette.mode];
    const themeSecondaryButtonColor = theme.palette.secondaryButton[theme.palette.mode];
    const themeSecondaryButtonTextColor = theme.palette.secondaryButtonText[theme.palette.mode];
    const themeSecondaryDividerColor = theme.palette.secondaryDivider[theme.palette.mode];
    const themeSecondaryButtonBorderColor = theme.palette.secondaryButtonBorder[theme.palette.mode];
    const themeTextboxOutlineColor = theme.palette.textboxOutline[theme.palette.mode];
    const themeTextboxOutlineHoverColor = theme.palette.textboxOutline.hover[theme.palette.mode];
    const themeLeftMenuBackgroundColor = theme.palette.secondary[theme.palette.mode];
    const themeLeftMenuPrimaryTextColor = theme.palette.chatLeftMenuPrimaryText[theme.palette.mode];
    const themeNavBackgroundColor = theme.palette.secondary[theme.palette.mode];

    const bottomRowStyle = {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    };

    const buttonStyle = {
        backgroundColor: themePrimaryButtonColor,
        color: themePrimaryButtonTextColor,
        height: '28px',
        padding: '4px 10px',
        fontSize: '0.80rem',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        fontFamily: '"Poppins", sans-serif',
    };

    const buttonStyleSecondary = {
        backgroundColor: themeSecondaryButtonColor,
        borderColor: themeSecondaryButtonBorderColor,
        color: themeSecondaryButtonTextColor,
        height: '28px',
        padding: '4px 10px',
        fontSize: '0.75rem',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        fontFamily: '"Poppins", sans-serif',
    };

    const deleteButtonStyle = {
        height: '28px',
        padding: '4px 10px',
        fontSize: '0.75rem',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        fontFamily: '"Poppins", sans-serif',
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'darkred', // Darker shade on hover
        },
        '&:active': {
            backgroundColor: 'firebrick', // Different shade when pressed
            boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.3)', // Optional: adds an inset shadow to simulate a pressed effect
        },
    };

    const dividerStyle = {
        borderTop: `1px solid ${themeSecondaryDividerColor}`,
        margin: '0px 0px 0px 0px',
    };

    const titleStyle = {
        textTransform: 'uppercase',
        fontSize: '16px',
        fontFamily: '"Poppins", sans-serif',
        marginBottom: '8px',
    };

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '8px',
        marginBottom: '8px',
    };

    const formFields = {
        botName: { outlineStyle: { borderColor: themeTextboxOutlineColor }, outlineStyleActive: { borderColor: themeTextboxOutlineHoverColor }, error: '' },
        botDescription: { outlineStyle: { borderColor: themeTextboxOutlineColor }, outlineStyleActive: { borderColor: themeTextboxOutlineHoverColor }, error: '' },
        botSpecialInstructions: { outlineStyle: { borderColor: themeTextboxOutlineColor }, outlineStyleActive: { borderColor: themeTextboxOutlineHoverColor }, error: '' },
        assistantId: { outlineStyle: { borderColor: themeTextboxOutlineColor }, outlineStyleActive: { borderColor: themeTextboxOutlineHoverColor }, error: '' },
        botModel: { outlineStyle: { borderColor: themeTextboxOutlineColor }, outlineStyleActive: { borderColor: themeTextboxOutlineHoverColor }, error: '' },
        temperature: { outlineStyle: { borderColor: themeTextboxOutlineColor }, outlineStyleActive: { borderColor: themeTextboxOutlineHoverColor }, error: '' },
        topP: { outlineStyle: { borderColor: themeTextboxOutlineColor }, outlineStyleActive: { borderColor: themeTextboxOutlineHoverColor }, error: '' },
        vectorStore: { outlineStyle: { borderColor: themeTextboxOutlineColor }, outlineStyleActive: { borderColor: themeTextboxOutlineHoverColor }, error: '' },
        initialWelcomeMessage: { outlineStyle: { borderColor: themeTextboxOutlineColor }, outlineStyleActive: { borderColor: themeTextboxOutlineHoverColor }, error: '' }
    };

    const fixedWidthStyle = {
        width: '350px',
    };

    const modalStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        height: '70%',
        fontFamily: '"Poppins", sans-serif',
        backgroundColor: themeBackgroundColor,
        color: themePrimaryTextColor,
        position: 'fixed', // Position fixed to center the modal
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)', // Translate to center the modal
        boxSizing: 'border-box',
        borderColor: 'gray',
        borderStyle: 'solid',
        borderWidth: '1px',
        boxShadow: '0 4px 8px 0 rgba(255, 255, 255, 0.2)',
        borderRadius: '10px',
        p: 4,
    };

    const headerStyle = {
        backgroundColor: themeNavBackgroundColor,
        padding: '10px',
        textAlign: 'center',
        position: 'sticky',
        top: 0,
        zIndex: 1000
    };

    const headerRowStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        paddingRight: theme.spacing(2), // Add some padding to the right if necessary
    };

    const thirdRowStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    };

    const expandCollapseButtonStyle = {
        marginLeft: 'auto', // This will push the button to the right
        color: themePrimaryTextColor
    };

    const bodyStyle = {
        display: 'flex',
        height: '100%'
    };

    const menuStyle = {
        flex: 1,
        backgroundColor: themeLeftMenuBackgroundColor,
        borderRight: '1px solid #ccc',
        padding: '10px',
        boxSizing: 'border-box'
    };

    const menuListStyle = {
        listStyleType: 'none',
        padding: 0
    };

    const menuItemStyle = {
        marginBottom: '10px'
    };

    const menuLinkStyle = {
        textDecoration: 'none',
        color: themeLeftMenuPrimaryTextColor,
        fontWeight: 'normal'
    };

    const contentStyle = {
        flex: 3,
        padding: '20px',
        overflowY: 'auto',
        boxSizing: 'border-box',
        backgroundColor: themeSecondaryBackgroundColor, // Set the background color here
        height: '100%', // Fill the remaining height
    };

    const nodeTypes = {
        user: UserNode,
        account: HitchyNode,
        hitchbot: HitchBotNode,
        service: ServiceNode,
        connection: ServiceNode
    };

    const minimapStyle = {
        height: 120,
    };



    return {
        theme,
        themePrimaryTextColor,
        themeSecondaryBackgroundColor,
        themeSecondaryTextColor,
        fixedWidthStyle,
        themeBackgroundColor,
        themePrimaryButtonColor,
        themePrimaryButtonTextColor,
        themeSecondaryButtonColor,
        themeSecondaryButtonTextColor,
        themeSecondaryDividerColor,
        themeSecondaryButtonBorderColor,
        themeTextboxOutlineColor,
        themeTextboxOutlineHoverColor,
        themeLeftMenuBackgroundColor,
        themeLeftMenuPrimaryTextColor,
        themeNavBackgroundColor,
        bottomRowStyle,
        buttonStyle,
        buttonStyleSecondary,
        deleteButtonStyle,
        dividerStyle,
        titleStyle,
        buttonContainerStyle,
        formFields,
        modalStyle,
        headerStyle,
        headerRowStyle,
        thirdRowStyle,
        expandCollapseButtonStyle,
        bodyStyle,
        menuStyle,
        menuListStyle,
        menuItemStyle,
        menuLinkStyle,
        contentStyle
    };
};

export default BotOrgChartStyles;
export const nodeWidth = 172;
export const nodeHeight = 36;
export const nodeTypes = {
    user: UserNode,
    account: HitchyNode,
    hitchbot: HitchBotNode,
    service: ServiceNode,
    connection: ServiceNode
};
export const minimapStyle = {
    height: 120,
};
