import React, { useEffect, useState } from 'react';
import { Grid, Autocomplete, TextField, ButtonGroup, Button, Accordion, AccordionSummary, AccordionDetails, Typography, Pagination, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams, useNavigate } from 'react-router-dom';
// import { Document, Page } from 'react-pdf';
import { pdfjs, Document, Page } from 'react-pdf';
import { v4 as uuidv4 } from 'uuid';
import * as signalR from '@microsoft/signalr';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './SageTester.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faDownload, faFileExcel, faServer } from '@fortawesome/pro-duotone-svg-icons';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import useWindowDimensions from '../../../utils/getWindowDimensions/getWindowDimensions';
import isValidGUID from '../../../utils/IsValidGuid';

pdfjs.GlobalWorkerOptions.workerSrc = '/js/pdf/pdf.worker.js';

function SageTester({ user, isAuthenticated, shouldFocusChatBox }) {
    const [reportName, setReportName] = useState(null);
    const [reportOptions, setReportOptions] = useState('');
    const [reportPrompt, setReportPrompt] = useState('');
    const [jsonString, setJsonString] = useState('');
    const [resultExpanded, setResultExpanded] = useState(false);
    const [pdfSource, setPdfSource] = useState(null);
    const [exportType, setExportType] = useState('PDF');
    const [started, setStarted] = useState(false);
    const [flash, setFlash] = useState(false);
    const [hubConnection, setHubConnection] = useState(null);
    const [sessionId, setSessionId] = React.useState('');
    const { guid } = useParams();
    const [reports, setReports] = useState([]);
    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfWidth, setPdfWidth] = useState(900);
    const history = useNavigate();

    const { height, width } = useWindowDimensions();

    useEffect(() => {
        console.log('Checking conditions to start SignalR:');
        if (!started && isAuthenticated && isValidGUID(sessionId)) {
            const jwtToken = user.id_token;
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(`${window._env_.HITCH_API}/sageTestHub?sessionId=${sessionId}`, {
                    accessTokenFactory: () => jwtToken
                })
                .withAutomaticReconnect()
                .build();

            connection.start()
                .then(() => {
                    console.log("SignalR connected!");
                    setHubConnection(connection);
                    setStarted(true);
                })
                .catch(error => {
                    console.error("SignalR connection error:", error);
                    if (error?.statusCode === 401 || error?.status === 401 || error.message === 'login_required') {
                        const currentAddress = `${window.location.pathname}${window.location.search}`;
                        window.location.href = `/login?redirect=${encodeURIComponent(currentAddress)}`;
                    }
                });

            connection.on("Hi", handleHi);
            connection.on("ReportList", handleReportList);
            connection.on("JsonLineAdded", handleJsonLineAdded);
            connection.on("PromptComplete", handlePromptComplete);
            connection.on("ShowPdf", handleShowPdf);

            return () => {
                if (hubConnection) {
                    hubConnection.off("Hi", handleHi);
                    hubConnection.off("ReportList", handleReportList);
                    hubConnection.off("JsonLineAdded", handleJsonLineAdded);
                    hubConnection.off("PromptComplete", handlePromptComplete);
                    hubConnection.off("ShowPdf", handleShowPdf);
                    hubConnection.stop();
                }
            };
        }
    }, [started, hubConnection, isAuthenticated, guid]);

    // Escape double quotes in reportOptions using JSON.stringify
    const escapedReportOptions = JSON.stringify(reportOptions);

    useEffect(() => {
        // Escape double quotes in reportOptions using JSON.stringify
        const escapedReportOptions = JSON.stringify(reportOptions);
        // Escape double quotes in reportPrompt using JSON.stringify
        const escapedReportPrompt = JSON.stringify(reportPrompt);
        // Escape the entire assistant message object using JSON.stringify
        const escapedAssistantMessage = JSON.stringify({
            "system": "Sage",
            "method": "Hitch.AI.Sage.Abstract.Messages.Report.SO_SalesRecapByWhseProdLine",
            "requestObject": {
                "CompanyCode": "APD",
                "Module": "S/O",
                "ReportName": "`${reportName}`",
                "Options": "`${escapedReportOptions}`"
            }
        });

        setJsonString(`{
        "messages": [
            {
                "role": "system",
                "content": "Then you will know the truth, and the truth will set you free. Systems Available: Sage. The Current Hitch.AI User Id is ${sessionId}."
            },
            {
                "role": "user",
                "content": ${escapedReportPrompt}
            },
            {
                "role": "assistant",
                "content": "${escapedAssistantMessage}"
            }
        ]
    }`);
    }, [reportOptions, reportPrompt, reportName, sessionId]);

    useEffect(() => {
        if (guid && isValidGUID(guid)) {
            setSessionId(guid);
            setStarted(false);
        } else {
            const newGuid = uuidv4();
            history(`/app/sage/${newGuid}`);
            setSessionId(newGuid);
        }
    }, [guid]);

    const handleHi = (hi) => {
        flashIt();
    };

    function flashIt() {
        // Set a state variable to trigger the flashing effect
        setFlash(true);

        // Clear the state after a delay to stop the flashing effect
        setTimeout(() => {
            setFlash(false);
        }, 1000); // Adjust the delay as needed (in milliseconds)
    }

    const handleReportList = (reports) => {
        setReports(reports);
    }

    useEffect(() => {
        setResultExpanded(pdfSource !== null);
    }, [pdfSource]);


    function onDocumentLoadSuccess(pdf) {
        console.log('pdf', pdf);
        setNumPages(pdf.numPages);
    }

    function handleJsonLineAdded(value) {
        flashIt();
        setReportOptions('');
        setReportName('');
        setJsonString('');
        setReportPrompt('');
    }

    function handlePromptComplete(value) {
        flashIt();
        setJsonString(value);
        var jsonObject = JSON.parse(value);
        setReportName(jsonObject.requestObject.ReportName);
        setReportOptions(jsonObject.requestObject.Options);
    }

    function updateReportValue(theReportName) {
        setReportName(theReportName);

        const selectedOptions = reports.find(r => r.reportName === theReportName)?.options;
        setReportOptions(selectedOptions);
    }

    const handlePdfPageChange = (event, value) => {
        setPageNumber(value);
    };

    function handleShowPdf(reportId) {
        var location = `${window._env_.HITCH_API}/api/render/pdf/${reportId}`;
        console.log('handleShowPdf', location)
        setPdfSource(location);
    }

    function runPrompt() {
        console.log(`Running Prompt: ${reportPrompt}`);
        if (hubConnection) {
            hubConnection.invoke("RunPrompt", sessionId, reportPrompt)
                .then(() => {
                    //setLoading(true);
                    console.log('Prompt Sent To The Server.');
                    flashIt();
                })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    function addToTrainingSet() {
        console.log(`//TODO: ADD TO TRAINING SET: ${jsonString}`);
        if (hubConnection) {
            hubConnection.invoke("AddJsonLine", sessionId, jsonString)
                .then(() => {
                    //setLoading(true);
                    console.log('JsonString Sent To The Server.');
                    flashIt();
                })
                .catch(error => console.error("Error sending message:", error));
        }

    }

    const handleReportFetch = async () => {
        console.log(`Submitting Request to Sage.`);
        if (hubConnection) {
            hubConnection.invoke("RunMethod", sessionId, reportName, reportOptions, exportType)
                .then(() => {
                    //setLoading(true);
                    console.log('Sage Method Sent To The Server.');
                    flashIt();
                })
                .catch(error => console.error("Error sending message:", error));
        }
    };
    const flashClass = flash ? 'flash' : '';

    return (
        <Grid container spacing={3} direction="column" backgroundColor={'#999'} className={flashClass}>
            <Grid item sx={{
                paddingRight: "20px"
            }}>
                <TextField
                    style={{ color: 'white', backgroundColor: '#777' }}
                    label="Enter a Prompt"
                    multiline
                    fullWidth
                    rows={4}
                    value={reportPrompt}
                    onChange={(e) => setReportPrompt(e.target.value)}
                />
            </Grid>
            <Grid item sx={{
                paddingRight: "20px"
            }}>
                <TextField
                    style={{ color: 'white', backgroundColor: '#777' }}
                    label="Enter an export Type"
                    multiline
                    fullWidth
                    rows={4}
                    value={exportType}
                    onChange={(e) => setExportType(e.target.value)}
                />
            </Grid>
            <Grid
                item
                container
                justifyContent="flex-end"
                sx={{
                    paddingRight: "20px"
                }}>
                <ButtonGroup>
                    <Button color="success" variant="contained" onClick={runPrompt}>Run Prompt</Button>
                </ButtonGroup>
            </Grid>
            <Grid item sx={{
                paddingRight: "20px"
            }}>
                <Autocomplete
                    freeSolo
                    style={{ color: 'white', backgroundColor: '#777' }}
                    id="free-solo-2-demo"
                    disableClearable
                    options={reports.map((option) => option?.reportName)}
                    onChange={(event, newValue) => updateReportValue(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select a report"
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item sx={{
                paddingRight: "20px"
            }}>
                <TextField
                    style={{ color: 'white', backgroundColor: '#777' }}
                    label="Report Options"
                    multiline
                    fullWidth
                    rows={4}
                    value={reportOptions}
                    onChange={(e) => setReportOptions(e.target.value)}
                />
            </Grid>

            <Grid
                item
                container
                justifyContent="flex-end"
                sx={{
                    paddingRight: "20px"
                }}>
                <ButtonGroup>
                    <Button color="success" variant="contained" onClick={handleReportFetch}>Submit</Button>
                    <Button color="error" variant="contained" >Cancel</Button>
                    <Button color="warning" variant="contained" onClick={() => { setReportName(null); setReportOptions(''); setPdfSource(null); }}>Reset</Button>
                </ButtonGroup>
            </Grid>
            {pdfSource && (
                <Grid item>
                    <Accordion expanded={resultExpanded}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>Results</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{ display: 'none' }}>
                                    <ButtonGroup variant="contained" fullWidth sx={{color: 'black'}} aria-label="outlined primary button group">
     
                                        <Button>Download <FontAwesomeIcon icon={faDownload} /></Button>
                                        <Button>Open As PDF<FontAwesomeIcon icon={faArrowUpRightFromSquare} /></Button>
                                        <Button>Export To Excel <FontAwesomeIcon icon={faFileExcel} /></Button>
                                        <Button>To Server <FontAwesomeIcon icon={faServer} /></Button>
                                        <Button>Schedule Run<FontAwesomeIcon icon={faCalendar} /></Button>
                                    </ButtonGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <Pagination count={numPages} fullWidth page={pageNumber} onChange={handlePdfPageChange} />
                                    <Typography>Page: {pageNumber} of {numPages}</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    
                                        <Document
                                            file={pdfSource}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                            onLoadError={(error) => {
                                                console.log('pdf load error', error);
                                            }}
                                        >
                                            <Page 
                                                pageNumber={pageNumber}
                                                width={900}
                                                renderMode="canvas" />

                                        </Document>
                                    
                                </Grid>

                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                </Grid>
            )}
            <Grid item sx={{
                paddingRight: "20px"
            }}>
                <TextField
                    style={{ color: 'white', backgroundColor: '#777' }}
                    label="JsonString"
                    multiline
                    fullWidth
                    rows={4}
                    value={jsonString}
                    onChange={(e) => setJsonString(e.target.value)}
                />
            </Grid>

            <Grid
                item
                container
                justifyContent="flex-end"
                sx={{
                    paddingRight: "20px",
                    paddingBottom: "20px"
                }}>
                <ButtonGroup>
                    <Button color="success" variant="contained" onClick={addToTrainingSet}>Add To Training Set</Button>
                </ButtonGroup>
            </Grid>

        </Grid>
    );
}

export default SageTester;
