import { ExpandMore } from "@mui/icons-material";
import { Collapse, FormControl, Grid, Input, InputAdornment, InputLabel } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";

const excludedFields = ['accountId', 'userId', 'step_id', 'system', 'operation_type', 'operationName', 'serviceId', 'featureId', 'operationId', 'depends_on', 'return_me', 'errorMessages', 'serviceName', 'onNodeCallback'];

function DynamicForm({ localData, setLocalData }) {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div style={{ maxWidth: '175px' }}>
            <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >
                <ExpandMoreIcon />
            </ExpandMore>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {Object.keys(localData).map(key => {
                    if (!excludedFields.includes(key)) {
                        return (
                            <FormControl fullWidth variant="standard" key={key}>
                                <InputLabel htmlFor={key} sx={{ color: 'white' }}> {/* Override text color here */}
                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                </InputLabel>
                                <Input
                                    id={key}
                                    value={localData[key] ?? ''}
                                    onChange={(e) => setLocalData({ ...localData, [key]: e.target.value })}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <FontAwesomeIcon icon={faPencil} />
                                        </InputAdornment>
                                    }
                                    sx={{ color: 'white', '& .MuiInput-input': { color: 'white' } }} // Override text color for the input and its content
                                />
                            </FormControl>
                        );
                    }
                    return null;
                })}
            </Collapse>
        </div>
    );
}

export default DynamicForm;
