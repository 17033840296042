import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, Checkbox, FormControlLabel, FormGroup, IconButton } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SmsIcon from '@mui/icons-material/Sms';
import { useTheme, useMediaQuery } from '@mui/material';
import { useSettingsStyles } from '../common/SettingsStyleProvider';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useHubConnections } from '../../../utils/HubConnectionsProvider';
import SettingsMenu from '../SettingsMenu/SettingsMenu';

function NotificationPreferences() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);

  const {
    font,
    themeBackgroundColor,
    themePrimaryTextColor,
    defaultOutlineStyle,
    activeOutlineStyle,
    errorOutlineStyle,
    supportStyle,
    boxStyle,
    buttonStyle,
    buttonStyleSecondary,
    dividerStyle,
    headerStyle,
    titleStyle,
    buttonContainerStyle,
    initialState,
    containerStyle,
    helperTextStyle,
    fixedWidthStyle,
    greetingContainerStyle,
    setIsDrawerOpen,
  } = useSettingsStyles();

  const { hitchUser, dashboardConnection, errorSnack, hitchDashCommand, isConnectionReady, infoSnack, successSnack } = useHubConnections();


  const themeTextColor = '#FFFFFF'; // Bright white for better contrast
  const themeCardBackground = `linear-gradient(145deg, #24434D, #24434D)`;

  // Initial state for notification settings
  const [settings, setSettings] = useState({
    messagesFromSupport: {
      email: true,
      sms: false,
      inApp: true,
    },
    jobCompletion: {
      email: true,
      sms: true,
      inApp: true,
    },
    employeeMessages: {
      email: true,
      sms: false,
      inApp: true,
    },
    thirdPartyFailures: {
      email: true,
      sms: true,
      inApp: true,
    },
    billingIssues: {
      email: true,
      sms: true,
      inApp: true,
    },
  });

  // Handler for changing notification settings
  const handleChange = (category, method) => (event) => {
    setSettings({
      ...settings,
      [category]: {
        ...settings[category],
        [method]: event.target.checked,
      },
    });
  };

  const notificationCategories = [
    {
      title: 'Messages from Support',
      key: 'messagesFromSupport',
    },
    {
      title: 'Job Completion',
      key: 'jobCompletion',
    },
    {
      title: 'Messages from Employees',
      key: 'employeeMessages',
    },
    {
      title: 'Third-Party Connection Failures',
      key: 'thirdPartyFailures',
    },
    {
      title: 'Billing Issues',
      key: 'billingIssues',
    },
  ];

  useEffect(() => {
    if (!isMobile) {
      setIsDrawerOpen(true);  // Open the drawer when not in mobile view
    }
  }, [isMobile, setIsDrawerOpen]);

  return (
    <Box sx={{ display: 'flex' }}>
      <SettingsMenu hitchUser={hitchUser} isDrawerOpen={drawerOpen} setIsDrawerOpen={setDrawerOpen} />
      <Box sx={{ flexGrow: 1, marginLeft: !isMobile && drawerOpen ? '240px' : '0px' }}>
        {isMobile && !drawerOpen && (
          <IconButton
            onClick={() => setDrawerOpen(true)}
            size="small"
            sx={{
              color: 'white',
              backgroundColor: '#18181B',
              opacity: 0.8,
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
              margin: '10px',
              zIndex: 1000,
              position: 'fixed',
              top: '66px',
              left: '16px',
              '&:hover': {
                backgroundColor: 'rgba(21, 101, 192, 0.8)',
              },
            }}
          >
            <KeyboardDoubleArrowRight />
          </IconButton>
        )}

        <Box sx={supportStyle}>
          <Box sx={boxStyle}>
            <Box sx={headerStyle}>
              <Typography variant="h6" sx={titleStyle}>Notification Settings</Typography>
            </Box>
      <Grid container spacing={3}>
        {notificationCategories.map((category, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Paper
              sx={{
                padding: 2,
                background: themeCardBackground,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '10px', // Rounded corners for a modern look
                boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)', // Stronger, layered shadow for elevated look
                transition: 'transform 0.2s, box-shadow 0.2s', // Smooth hover effect
                '&:hover': {
                  transform: 'translateY(-5px)', // Lift the card on hover
                  boxShadow: '0px 15px 35px rgba(0, 0, 0, 0.35)', // Increase shadow on hover
                },
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  right: '0',
                  bottom: '0',
                  borderRadius: '10px', // Match the card's border radius
                  boxShadow: 'inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // Subtle inner glow for depth
                  pointerEvents: 'none', // Prevents interaction with the pseudo-element
                },
              }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  marginBottom: 2, 
                  color: themeTextColor,
                  fontWeight: 'bold', // Increase font weight for better readability
                  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)', // Subtle text shadow for added contrast
                }}
              >
                {category.title}
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={settings[category.key].email}
                      onChange={handleChange(category.key, 'email')}
                      icon={<EmailIcon />}
                      checkedIcon={<EmailIcon />}
                    />
                  }
                  label="Email"
                  sx={{ color: themeTextColor }} // Ensure label text is also readable
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={settings[category.key].sms}
                      onChange={handleChange(category.key, 'sms')}
                      icon={<SmsIcon />}
                      checkedIcon={<SmsIcon />}
                    />
                  }
                  label="SMS"
                  sx={{ color: themeTextColor }} // Ensure label text is also readable
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={settings[category.key].inApp}
                      onChange={handleChange(category.key, 'inApp')}
                      icon={<NotificationsIcon />}
                      checkedIcon={<NotificationsIcon />}
                    />
                  }
                  label="In-App"
                  sx={{ color: themeTextColor }} // Ensure label text is also readable
                />
              </FormGroup>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
    </Box>
      </Box>
    </Box>
  );
}

export default NotificationPreferences;
