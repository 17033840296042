import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button, TextField } from '@mui/material';
import { Upload } from '@mui/icons-material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash, faDownload } from "@fortawesome/pro-solid-svg-icons";
import { useHubConnections } from '../../utils/HubConnectionsProvider';
import DeleteConfirmModal from './DeleteConfirmModal';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';

function StoreDocumentsGrid({ path, handleUpload, handleView, handleDownload }) {
    const theme = useTheme();
    const { guid } = useParams();

    const themeBackgroundColor = theme.palette.secondary[theme.palette.mode];
    const themePrimaryTextColor = theme.palette.primaryText[theme.palette.mode];
    const themeSecondaryTextColor = theme.palette.secondaryText[theme.palette.mode];
    const themePrimaryButtonColor = theme.palette.primaryButton[theme.palette.mode];
    const themePrimaryButtonTextColor = theme.palette.primaryButtonText[theme.palette.mode];
    const themeTextboxOutlineColor = theme.palette.textboxOutline[theme.palette.mode];
    const themeTextboxOutlineHoverColor = theme.palette.textboxOutline.hover[theme.palette.mode];

    const { dashboardConnection, hitchDashCommand, isConnectionReady } = useHubConnections();
    const [searchTerm, setSearchTerm] = useState('');
    const [fileToDelete, setFileToDelete] = useState(null);
    const [files, setFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [failCount, setFailCount] = useState(0);

    const filteredFiles = Array.isArray(files) ? files.filter(file =>
        file?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    ) : [];

    function filesList(fileListData) {
        if (fileListData && fileListData.stackTrace) {
            // console.log('server exception', fileListData);
            setFailCount(failCount + 1);
            if (fileListData.message === "Invalid Repository Id") {
                // console.log('this needs to be investigated. In the meantime, we will reload the files now.');
                loadFromServer();
            }
            return;
        }
        console.log("filesList", fileListData);
        setFiles(fileListData || []);
        setFailCount(0);
    }

    const handleClose = () => {
        setOpen(false);
        setFileToDelete(null);
    };

    const handleDeleteFile = (fileId) => {
        setFileToDelete(fileId);
        setOpen(true);
    };

    const confirmDelete = () => {
        hitchDashCommand('document', 'file', 'delete', { 'RepositoryId': guid, 'FileId': fileToDelete }, () => { });
        setOpen(false);
    };

    const loadFromServer = () => {
        if (failCount < 5) {
            console.log('attempting to load files from server', guid);
            hitchDashCommand('document', 'file', 'list', { 'RepositoryId': guid }, filesList);
        } else {
            console.log('failCount >= 5 so. . . Not trying to load.');
        }
    }

    const supportStyle = {
        backgroundColor: themeBackgroundColor,
        color: themePrimaryTextColor,
    };

    const boxStyle = {
        backgroundColor: themeBackgroundColor,
        color: themePrimaryTextColor,
        maxWidth: '1000px',
        width: '100%',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingBottom: '16px',
        boxSizing: 'border-box',
        borderRadius: '5px',
        fontSize: '14px',
    };

    const containerStyle = {
        paddingBottom: '8px',
        display: 'flex',
        alignItems: 'center',
        fontFamily: '"Poppins", sans-serif',
    };

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '8px',
    };

    const buttonStyle = {
        backgroundColor: themePrimaryButtonColor,
        color: themePrimaryButtonTextColor,
        height: '28px',
        padding: '4px 10px',
        fontSize: '0.80rem',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        fontFamily: '"Poppins", sans-serif',
    };

    useEffect(() => {
        if (dashboardConnection && isConnectionReady && guid) {
            dashboardConnection.on("8df1b111-2b65-4f11-a8e3-a9f268b90875", filesList);
            console.log('trying to get from server!');
            loadFromServer();
        }

        return () => {
            if (dashboardConnection) {
                dashboardConnection.off("8df1b111-2b65-4f11-a8e3-a9f268b90875", filesList);
            }
        };
    }, [dashboardConnection, isConnectionReady, guid, hitchDashCommand]);

    // useEffect(() => {
    //     if (path) {
    //         loadFromServer();
    //     }
    // }, [path]);

    return (
        <div style={supportStyle}>
            <div style={boxStyle}>
                <div style={containerStyle}>
                    <TextField
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search Uploaded Files..."
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            style: {
                                color: themePrimaryTextColor,
                                fontFamily: '"Poppins", sans-serif',
                            },
                        }}
                        InputProps={{
                            style: {
                                color: themePrimaryTextColor,
                                fontFamily: '"Poppins", sans-serif',
                                background: themeBackgroundColor,
                            },
                            sx: {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: themeTextboxOutlineColor,
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: themeTextboxOutlineHoverColor,
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: themeTextboxOutlineHoverColor,
                                    borderWidth: '2px',
                                },
                            },
                        }}
                    />
                </div>
                <div style={buttonContainerStyle}>
                    <Button
                        variant="contained"
                        component="label"
                        startIcon={<Upload />}
                        style={buttonStyle}
                    >
                        Upload File
                        <input
                            type="file"
                            hidden
                            onChange={handleUpload}
                        />
                    </Button>
                </div>
                <TableContainer component={Paper} sx={{ backgroundColor: themeBackgroundColor, marginTop: '16px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Name</TableCell>
                                <TableCell sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Date</TableCell>
                                <TableCell sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredFiles.map((file) => (
                                <TableRow key={file.id}>
                                    <TableCell sx={{ color: themeSecondaryTextColor, fontFamily: '"Poppins", sans-serif' }}>{file.name}</TableCell>
                                    <TableCell sx={{ color: themeSecondaryTextColor, fontFamily: '"Poppins", sans-serif' }}>{file.date}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDownload(file.id)} sx={{ color: themePrimaryTextColor }}>
                                            <FontAwesomeIcon icon={faDownload} />
                                        </IconButton>
                                        <IconButton onClick={() => handleView(file.id)} sx={{ color: themePrimaryTextColor }}>
                                            <FontAwesomeIcon icon={faEye} />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteFile(file.id)} sx={{ color: themePrimaryTextColor }}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <DeleteConfirmModal
                    title="File Delete"
                    content="Are you sure you want to delete this file? This action is not reversible!"
                    labelNo="No"
                    actionNo={handleClose}
                    labelYes="Yes"
                    actionYes={confirmDelete}
                    open={open}
                    onClose={handleClose}
                />
            </div>
        </div>
    );
}

export default StoreDocumentsGrid;
