import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDown, faDuck, faTrash, faUp } from "@fortawesome/pro-solid-svg-icons";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';


function MatrixPhoneForm({ pairs, setPairs }) {

    const handleRemovePair = index => {
        const newPairs = pairs.filter((_, i) => i !== index);
        setPairs(newPairs);
    };

    const handleAddPair = (type) => {
        const newPair = { name: '', type: type, value: type === 'Dictionary' ? {} : '' };
        setPairs([...pairs, newPair]);
    };

    const movePair = (index, direction) => {
        const newPairs = [...pairs];
        if (direction === 'up' && index > 0) {
            [newPairs[index], newPairs[index - 1]] = [newPairs[index - 1], newPairs[index]];
        } else if (direction === 'down' && index < pairs.length - 1) {
            [newPairs[index], newPairs[index + 1]] = [newPairs[index + 1], newPairs[index]];
        }
        setPairs(newPairs);
    };

    const handleChange = (index, key, value) => {
        const newPairs = [...pairs];
        newPairs[index][key] = value;
        setPairs(newPairs);
    };

    const renderValueInput = (pair, index) => {
        switch (pair.type) {
            case 'Text':
                return <TextField value={pair.value} onChange={(e) => handleChange(index, 'value', e.target.value)} />;
            case 'Number':
                return <TextField type="number" value={pair.value} onChange={(e) => handleChange(index, 'value', e.target.value)} />;
            case 'Boolean':
                return (
                    <Select value={pair.value} onChange={(e) => handleChange(index, 'value', e.target.value)}>
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>
                    </Select>
                );
            case 'Dictionary':
                return <Button>OBJECT</Button>;
            case 'Date':
                return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            value={pair.value}
                            onChange={(newValue) => handleChange(index, 'value', newValue)}
                        />
                    </LocalizationProvider>
                );
            default:
                return null;
        }
    };
    return (
        <Grid container spacing={2}>
            {/* Render existing pairs */}
            {pairs.map((pair, index) => (
                <React.Fragment key={index}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            value={pair.name}
                            onChange={(e) => handleChange(index, 'name', e.target.value)}
                            label="Name"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        {renderValueInput(pair, index)}
                    </Grid>
                    <Grid item>
                        {index > 0 && (
                            <IconButton onClick={() => movePair(index, 'up')}>
                                <FontAwesomeIcon icon={faUp} />
                            </IconButton>
                        )}
                        {index < pairs.length - 1 && (
                            <IconButton onClick={() => movePair(index, 'down')}>
                                <FontAwesomeIcon icon={faDown} />
                            </IconButton>
                        )}
                        <IconButton onClick={() => handleRemovePair(index)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                    </Grid>
                </React.Fragment>
            ))}

            {/* Buttons to add new pairs */}
            <Grid item xs={12}>
                <Button onClick={() => handleAddPair('Text')}>Add Text</Button>
                <Button onClick={() => handleAddPair('Number')}>Add Number</Button>
                <Button onClick={() => handleAddPair('Boolean')}>Add Boolean</Button>
                <Button onClick={() => handleAddPair('Date')}>Add Date</Button>
                {/* <Button onClick={() => handleAddPair('Dictionary')}>Add Dictionary</Button> */}
            </Grid>
        </Grid>
    );
}

export default MatrixPhoneForm;