import React, { useRef, useEffect } from 'react';
import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function LocalMetadata({ formData, handleAddMetadata, handleMetadataChange, handleRemoveMetadata, setFormData }) {
    const inputRefs = useRef({});

    const handleLocalKeyChange = (oldKey, newKey) => {
        if (oldKey !== newKey && !formData.factsToMeet.hasOwnProperty(newKey)) {
            setFormData(prev => {
                const { [oldKey]: oldValue, ...rest } = prev.factsToMeet;
                return {
                    ...prev,
                    factsToMeet: {
                        ...rest,
                        [newKey]: oldValue,
                    },
                };
            });
        }
    };

    const handleKeyChange = (oldKey, e) => {
        const newKey = e.target.value;
        handleLocalKeyChange(oldKey, newKey);
    };

    useEffect(() => {
        // Ensure the focus remains on the active input when the component re-renders
        Object.values(inputRefs.current).forEach(ref => {
            if (ref && ref.focused) {
                ref.focus();
            }
        });
    }, [formData]);

    return (
        <Grid item xs={12}>
            <Typography variant="h6">Metadata</Typography>
            <Button
                variant="contained"
                startIcon={<FontAwesomeIcon icon={faPlus} />}
                onClick={handleAddMetadata}
                disabled={formData && formData.factsToMeet && Object.keys(formData?.factsToMeet)?.length >= 16}
            >
                Add Metadata
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Key</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {formData && formData.factsToMeet && Object.entries(formData.factsToMeet).map(([key, value]) => (
                            <TableRow key={key}>
                                <TableCell>
                                    <TextField
                                        inputRef={ref => inputRefs.current[key] = ref} // Store ref
                                        value={key}
                                        onChange={(e) => handleKeyChange(key, e)}
                                        // onBlur={() => handleLocalKeyChange(key, key)} // Handle blur event
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        value={value}
                                        onChange={(e) => handleMetadataChange(key, e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleRemoveMetadata(key)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
}

export default LocalMetadata;
