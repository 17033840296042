import { Box, Checkbox, Grid, Paper, Tooltip } from '@mui/material';
import './Trainer.css';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill1 } from '@fortawesome/pro-light-svg-icons';
import { faMoneyBill } from '@fortawesome/pro-solid-svg-icons';
import { useEffect } from 'react';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function Trainer({ user, isAuthenticated, hubConnection }) {

    useEffect(() => {
        // Subscribe to events when the component mounts
        const subscribeToEvents = () => {
            if (hubConnection) {
                // Example subscription
                hubConnection.on('EventName', (data) => {
                    // Handle the event
                    console.log('Event received:', data);
                });

                // Add other event subscriptions here
            }
        };

        // Unsubscribe from events when the component unmounts
        const unsubscribeFromEvents = () => {
            if (hubConnection) {
                // Example unsubscription
                hubConnection.off('EventName');

                // Add other event unsubscriptions here
            }
        };

        subscribeToEvents();

        // The function returned from useEffect will run when the component unmounts
        return unsubscribeFromEvents;
    }, [hubConnection]); // Re-run this effect if hubConnection changes

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid className='qb_top_row' sx={{ textAlign: 'center' }} item xs={12}>
                    <Tooltip title="Quickbooks">
                        <Checkbox {...label}
                            icon={<FontAwesomeIcon icon={faMoneyBill1} pull="left" size="4x" />}
                            checkedIcon={<FontAwesomeIcon icon={faMoneyBill} pull="right" size="4x" />} />
                    </Tooltip>
                </Grid>
                <Grid item xs={4}>
                    <Item>xs=4</Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>xs=4</Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>xs=8</Item>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Trainer;