import { createTheme } from '@mui/material/styles';
import carolina from './carolina.json'; // Make sure the path is correct


const carolinaTheme = createTheme(carolina);

export default carolinaTheme;
//78DEAB: seafoam green
//00FFFF: cyan
//00EDED: powerpoint seafoam green
//90F8E2
//D3D3D3: light grey Hitchy text
//F3F5FF: menu hover color