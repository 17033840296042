import React, { useEffect } from 'react';
import {
    Box,
    Typography,
    Modal,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import PdfViewer from '../PdfViewer/PdfViewer';

const FileViewerModal = ({ open, onClose, fileContent, fileName, fileContentType }) => {
    const theme = useTheme();

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '600px',
        height: '80vh',
        backgroundColor: theme.palette.modalBackground[theme.palette.mode],
        color: theme.palette.modalTextSecondary[theme.palette.mode],
        borderColor: 'gray',
        borderStyle: 'solid',
        borderWidth: '1px',
        boxShadow: '0 4px 8px 0 rgba(255, 255, 255, 0.2)',
        p: 4,
        paddingTop: '40px', // Adjust padding to give space for the close icon
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        zIndex: 2501,
    };
    
    const headerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '24px', // Add padding to give space for the close icon
        marginBottom: '16px',
    };
    

    const contentStyle = {
        flex: 1,
        padding: '16px',
        backgroundColor: 'white',
        color: 'black',
        borderRadius: '5px',
        textAlign: 'left',
        overflow: 'auto',
        boxSizing: 'border-box',
        whiteSpace: 'pre-wrap', // Preserve whitespace and line breaks
        fontFamily: 'Courier New, Courier, monospace', // Use a standard monospace font
        fontSize: '14px', // Adjust font size
        lineHeight: '1.5', // Adjust line height
        WebkitFontSmoothing: 'antialiased', // Font smoothing for better clarity (CamelCase)
        MozOsxFontSmoothing: 'grayscale', // Font smoothing for macOS (CamelCase)
        textRendering: 'optimizeLegibility', // Optimize text rendering for legibility
    };
    
    

    useEffect(() => {
        if (fileContentType) {
            console.log('fileContentType', fileContentType);
        }
    }, [fileContentType]);

    const unsupportedMessage = (
        <Typography variant="body1" sx={{ color: 'red', textAlign: 'center', marginTop: '20%' }}>
            Oops! This file type isn't supported yet. While we're working on it, you can download it for viewing as another option.
        </Typography>
    );

    const supportedFileTypes = [
        'text/x-c',
        'text/x-csharp',
        'text/x-c++',
        'text/x-java',
        'text/html',
        'application/json',
        'text/markdown',
        'text/x-php',
        'text/x-python',
        'text/x-script.python',
        'text/x-ruby',
        'text/x-tex',
        'text/plain',
        'text/css',
        'text/javascript',
        'application/typescript',
    ];

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 2501 }}
        >
            <Box sx={modalStyle}>
                <Box sx={headerStyle}>
                    <Typography variant="h6" sx={{ fontFamily: '"Poppins", sans-serif', fontWeight: 'bold' }}>
                        {fileName}
                    </Typography>
                    <IconButton
    onClick={onClose}
    sx={{
        color: theme.palette.modalTextSecondary[theme.palette.mode],
        position: 'absolute',
        top: { xs: 4, sm: 8 }, // Smaller spacing on extra small screens
        right: { xs: 4, sm: 8 }, // Smaller spacing on extra small screens
        zIndex: 2502,
        '&:hover': {
            backgroundColor: 'transparent',
        }
    }}
>
    <CloseIcon />
</IconButton>


                </Box>
                <Box sx={contentStyle}>
                    {fileContent && fileContentType === 'application/pdf' ? (
                        <PdfViewer pdfData={fileContent} />
                    ) : (
                        supportedFileTypes.includes(fileContentType) ? (
                            <pre>{fileContent}</pre>
                        ) : (
                            unsupportedMessage
                        )
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default FileViewerModal;
