import { Grid, Typography } from "@mui/material";
import ChatBox from "../../hitchy/Conversation/ChatBox";
import ConversationTabs from "../../hitchy/Conversation/ConversationTabs";
import { useState } from "react";

function TrainingChat({
    chatBoxKey,
    chatContainerRef,
    conversation,
    editedMessageText,
    editingMessage,
    guid,
    handleSendMessage,
    hitchUser,
    isChatBoxActive,
    isHelpful,
    isNotHelpful,
    messages,
    newMessage,
    saveEditedMessage,
    setEditingMessage,
    setEditedMessageText,
    setNewMessage,
    setUseChatGpt,
    useChatGpt,
    user,
    users }) {


    const contentShiftStyle = {
        marginLeft: 16,
        transition: 'margin 0.3s',
        width: '900px',
        backgroundColor: 'black',
        marginTop: '56px'
    };
    const [overrideHeight, setOverrideHeight] = useState(700);

    return (
        <Grid container mr={4}>
            <Grid item lg={12} style={contentShiftStyle}>
                <ConversationTabs
                    user={user}
                    hitchUser={hitchUser}
                    conversation={conversation}
                    useChatGpt={useChatGpt}
                    setUseChatGpt={setUseChatGpt}
                    guid={guid}
                    messages={messages}
                    users={users}
                    chatContainerRef={chatContainerRef}
                    isHelpful={isHelpful}
                    isNotHelpful={isNotHelpful}
                    saveEditedMessage={saveEditedMessage}
                    setEditingMessage={setEditingMessage}
                    setEditedMessageText={setEditedMessageText}
                    editedMessageText={editedMessageText}
                    editingMessage={editingMessage}
                    overrideHeight={overrideHeight}
                />

                <ChatBox
                    key={chatBoxKey}
                    newMessage={newMessage}
                    handleSendMessage={handleSendMessage}
                    setNewMessage={setNewMessage}
                    isChatBoxActive={isChatBoxActive}
                    hitchUser={hitchUser}
                />
            </Grid>
        </Grid>
    )
}

export default TrainingChat;