import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useAdminHubConnections } from '../../utils/HubAdminConnectionProvider';
import { useHubConnections } from '../../utils/HubConnectionsProvider';

function AccountRoles({ organizationId }) {
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);
    const [newRole, setNewRole] = useState('');
    const [openAddRoleDialog, setOpenAddRoleDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [expanded, setExpanded] = useState({});
    const [roleToDelete, setRoleToDelete] = useState(null);

    const { adminConnection, hitchUser, isConnectionReady } = useAdminHubConnections();
    const {         
        defaultSnack,
        infoSnack,
        warningSnack,
        successSnack,
        errorSnack, 
    } = useHubConnections();

    useEffect(() => {
        if (adminConnection) {
            adminConnection.on('AccountRoles', (data) => {
                console.log('IdentityRecords', data);
                setRoles(data);
                // setLoading(false);
            });

            getAccountRoles();
            return () => {
                adminConnection.off('AccountRoles');
            };
        }
    }, [adminConnection]);

    function getAccountRoles() {
        adminConnection.invoke("GetAccountRoles", hitchUser.userId, organizationId)
            .then(() => {
                console.log('i asked for Account Roles');
                // setLoading(true);
            })
            .catch(error => console.error("Error sending message:", error));
    }

    useEffect(() => {
        // Fetch roles and users for the organization
        // For now, use dummy data
        const dummyRoles = [
            { id: 'role1', name: 'Admin' },
            { id: 'role2', name: 'User' }
        ];
        setRoles(dummyRoles);

        const dummyUsers = [
            { id: 'user1', name: 'John Doe', role: 'Admin' },
            { id: 'user2', name: 'Jane Smith', role: 'User' }
        ];
        setUsers(dummyUsers);
    }, [organizationId]);

    const handleAddRole = () => {
        // Simulate adding a role
        const newRoleObject = { id: `role${roles.length + 1}`, name: newRole };
        setRoles(prevRoles => [...prevRoles, newRoleObject]);
        setNewRole('');
        setOpenAddRoleDialog(false);
    };

    const handleRoleChange = (e) => {
        setNewRole(e.target.value);
    };

    const handleOpenDeleteDialog = (roleId) => {
        setRoleToDelete(roleId);
        setOpenDeleteDialog(true);
    };

    const handleDeleteRole = () => {
        // Simulate role deletion
        setRoles(prevRoles => prevRoles.filter(role => role.id !== roleToDelete));
        setOpenDeleteDialog(false);
        setRoleToDelete(null);
    };

    const handleExpandClick = (roleId) => {
        setExpanded((prevExpanded) => ({
            ...prevExpanded,
            [roleId]: !prevExpanded[roleId]
        }));
    };

    return (
        <Box sx={{ position: 'relative' }}>
            {roles.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Role Name</TableCell>
                                <TableCell>Actions</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {roles.map((role) => (
                                <React.Fragment key={role.id}>
                                    <TableRow sx={{ '& .MuiTableCell-root': { padding: '8px' } }}>
                                        <TableCell>{role.name}</TableCell>
                                        <TableCell>
                                            <IconButton color="error" onClick={() => handleOpenDeleteDialog(role.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => handleExpandClick(role.id)}
                                                aria-expanded={expanded[role.id]}
                                                aria-label="show more"
                                            >
                                                {expanded[role.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '& .MuiTableCell-root': { padding: '8px' } }}>
                                        <TableCell style={{ padding: 0 }} colSpan={3}>
                                            <Collapse in={expanded[role.id]} timeout="auto" unmountOnExit>
                                                <Box margin={0} sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 1 }}>
                                                    <TableContainer component={Paper}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>User Name</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {users.filter(user => user.role === role.name).map(user => (
                                                                    <TableRow key={user.id} sx={{ '& .MuiTableCell-root': { padding: '8px' } }}>
                                                                        <TableCell>{user.name}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>No Roles to Display</Typography>
            )}
            <IconButton
                onClick={() => setOpenAddRoleDialog(true)}
                color="primary"
                sx={{ position: 'absolute', top: 8, right: 8 }}
            >
                <AddIcon />
            </IconButton>

            <Dialog
                open={openAddRoleDialog}
                onClose={() => setOpenAddRoleDialog(false)}
                aria-labelledby="add-role-dialog-title"
                aria-describedby="add-role-dialog-description"
            >
                <DialogTitle id="add-role-dialog-title">{"Add New Role"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="add-role-dialog-description">
                        Please enter the name of the new role.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Role Name"
                        type="text"
                        fullWidth
                        value={newRole}
                        onChange={handleRoleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAddRoleDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddRole} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure you want to delete this role?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteRole} color="error" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default AccountRoles;
