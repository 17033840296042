import React, { useEffect, useState, useRef } from "react";
import * as AdaptiveCards from "adaptivecards";

function MessageAdaptiveCard({ message }) {
    const [payload, setPayload] = useState({});
    const adaptiveCardRef = useRef(null); // Reference to the container where the card will be rendered

    useEffect(() => {
        if (message.content) {
            try {
                // Parse the message content to JSON
                let theObject = JSON.parse(message.content);
                setPayload(theObject);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }
    }, [message]);

    useEffect(() => {
        if (Object.keys(payload).length) {
            // Initialize a new AdaptiveCard instance
            var adaptiveCard = new AdaptiveCards.AdaptiveCard();

            // Set Host Config here if needed
            adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({
                fontFamily: "'Poppins', sans-serif",
                // Other host config options
            });

            // Parse the payload
            adaptiveCard.parse(payload);

            // Render the card to an HTML element
            var renderedCard = adaptiveCard.render();

            // Clear the container before appending new content
            if (adaptiveCardRef.current) {
                adaptiveCardRef.current.innerHTML = '';
                adaptiveCardRef.current.appendChild(renderedCard);
            }
        }
    }, [payload]);

    return (
        <>
            <div ref={adaptiveCardRef} />
        </>
    );
}

export default MessageAdaptiveCard;
