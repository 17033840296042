import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function ServiceFeatures() {
    const [features, setFeatures] = useState([]);
    const [operations, setOperations] = useState([]);
    const [newFeature, setNewFeature] = useState('');
    const [openAddFeatureDialog, setOpenAddFeatureDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [expanded, setExpanded] = useState({});
    const [featureToDelete, setFeatureToDelete] = useState(null);

    useEffect(() => {
        // Use static dummy data for now
        const dummyFeatures = [
            {
                id: "1",
                name: "Feature One",
                created: "2024-01-01",
                createdBy: "admin",
                updated: "2024-01-02",
                updatedBy: "admin"
            },
            {
                id: "2",
                name: "Feature Two",
                created: "2024-02-01",
                createdBy: "admin",
                updated: "2024-02-02",
                updatedBy: "admin"
            }
        ];
        setFeatures(dummyFeatures);

        const dummyOperations = [
            { id: 'operation1', name: 'Operation One', featureId: '1' },
            { id: 'operation2', name: 'Operation Two', featureId: '1' },
            { id: 'operation3', name: 'Operation Three', featureId: '2' }
        ];
        setOperations(dummyOperations);
    }, []);

    const handleExpandClick = (id) => {
        setExpanded((prevExpanded) => ({
            ...prevExpanded,
            [id]: !prevExpanded[id]
        }));
    };

    const handleAddFeature = () => {
        // Simulate adding a feature
        const newFeatureObject = { id: `feature${features.length + 1}`, name: newFeature, created: new Date().toISOString(), createdBy: 'admin', updated: new Date().toISOString(), updatedBy: 'admin' };
        setFeatures(prevFeatures => [...prevFeatures, newFeatureObject]);
        setNewFeature('');
        setOpenAddFeatureDialog(false);
    };

    const handleFeatureChange = (e) => {
        setNewFeature(e.target.value);
    };

    const handleOpenDeleteDialog = (featureId) => {
        setFeatureToDelete(featureId);
        setOpenDeleteDialog(true);
    };

    const handleDeleteFeature = () => {
        // Simulate feature deletion
        setFeatures(prevFeatures => prevFeatures.filter(feature => feature.id !== featureToDelete));
        setOpenDeleteDialog(false);
        setFeatureToDelete(null);
    };

    return (
        <Box sx={{ position: 'relative' }}>
            {features.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Feature Name</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Updated</TableCell>
                                <TableCell>Actions</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {features.map((feature) => (
                                <React.Fragment key={feature.id}>
                                    <TableRow sx={{ '& .MuiTableCell-root': { padding: '8px' } }}>
                                        <TableCell>{feature.name}</TableCell>
                                        <TableCell>{feature.created}</TableCell>
                                        <TableCell>{feature.updated}</TableCell>
                                        <TableCell>
                                            <IconButton color="error" onClick={() => handleOpenDeleteDialog(feature.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => handleExpandClick(feature.id)}
                                                aria-expanded={expanded[feature.id]}
                                                aria-label="show more"
                                            >
                                                {expanded[feature.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '& .MuiTableCell-root': { padding: '8px' } }}>
                                        <TableCell style={{ padding: 0 }} colSpan={5}>
                                            <Collapse in={expanded[feature.id]} timeout="auto" unmountOnExit>
                                                <Box margin={0} sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 1 }}>
                                                    <TableContainer component={Paper}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Operation Name</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {operations.filter(operation => operation.featureId === feature.id).map(operation => (
                                                                    <TableRow key={operation.id} sx={{ '& .MuiTableCell-root': { padding: '8px' } }}>
                                                                        <TableCell>{operation.name}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>No Features to Display</Typography>
            )}
            <IconButton
                onClick={() => setOpenAddFeatureDialog(true)}
                color="primary"
                sx={{ position: 'absolute', top: 8, right: 8 }}
            >
                <AddIcon />
            </IconButton>

            <Dialog
                open={openAddFeatureDialog}
                onClose={() => setOpenAddFeatureDialog(false)}
                aria-labelledby="add-feature-dialog-title"
                aria-describedby="add-feature-dialog-description"
            >
                <DialogTitle id="add-feature-dialog-title">{"Add New Feature"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="add-feature-dialog-description">
                        Please enter the name of the new feature.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Feature Name"
                        type="text"
                        fullWidth
                        value={newFeature}
                        onChange={handleFeatureChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAddFeatureDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddFeature} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure you want to delete this feature?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteFeature} color="error" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
}

export default ServiceFeatures;
