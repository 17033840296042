import React, { useState, useEffect } from 'react';
import './login.css'; // Import your CSS file
import ArrowLarge from './arrow_med_offwhite.svg'; // Import the SVG file
import HitchTitle from './SquareHitchSoftwareLogo.svg'; // Import the SVG file
import Divider from '@mui/material/Divider';
import { faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import MSLogin from './microsoft_login.svg'; // Import the SVG file
import GoogleLogin from './google_login.svg'; // Import the SVG file
import ArrowTopLeft from './top_left.svg'; // Adjust the path as needed
import ArrowBottomLeft from './bottom_left.svg'; // Adjust the path as needed
import { useHubConnections } from '../../utils/HubConnectionsProvider';

function LoginPanel() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleMicrosoftLoginClick = () => {
        const returnUrl = `${window.location.origin}/callback`;
        const fullUrl = `/login_microsoft`;
        window.location.href = fullUrl;
    };

    const handleGoogleLoginClick = () => {
        const returnUrl = `${window.location.origin}/callback`;
        const fullUrl = `/login_google`;
        window.location.href = fullUrl;
    };

    const {
        hitchUser,
        user,
    } = useHubConnections();

    useEffect(() => {
        // console.log("hitchUser:", hitchUser);
        // console.log("user:", user);
    }, [hitchUser, user]);

    return (
        <div className="login-panel">
            {isMobile && (
                <img src={ArrowTopLeft} alt="Top Left Arrow" className="arrow-top-left" />
            )}

            <div className="login-content">
                <img src={HitchTitle} alt="Hitch Title" className="hitch-title-image" />
                <Divider className="custom-divider" />
                <h2 className="login-register">Login / Register</h2>
                <div className="icon-row">
                    {/* Microsoft login */}
                    <div className="icon-wrapper" onClick={handleMicrosoftLoginClick}>
                        <img src={MSLogin} alt="Microsoft login" style={{ cursor: 'pointer' }} />
                    </div>
                    {/* Google login */}
                    <div className="icon-wrapper" onClick={handleGoogleLoginClick}>
                        <img src={GoogleLogin} alt="Google login" style={{ cursor: 'pointer' }} />
                    </div>
                </div>
            </div>

            {isMobile && (
                <img src={ArrowBottomLeft} alt="Bottom Left Arrow" className="arrow-bottom-left" />
            )}
        </div>
    );
}

function LoginPage() {
    return (
        <div className="login-page">
            <div className="left-image">
                <img src={ArrowLarge} alt="Arrow Large" />
            </div>
            <LoginPanel />
        </div>
    );
}

export default LoginPage;
