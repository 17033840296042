import React from 'react';
import { AppBar, Toolbar, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import RequestDemoForm from '../requestdemo/RequestDemoForm'; // Import ContactForm
import FooterMain from '../footer/FooterMain';
import { Link } from 'react-router-dom';

const RequestDemoPage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isExtraWide = useMediaQuery('(min-width:1920px)'); // Custom breakpoint for extra wide screens

    // Determine margin based on device size
    let horizontalMargin = 16;
    if (isMobile) {
        horizontalMargin = 4; // Smaller margin for mobile
    } else if (isTablet) {
        horizontalMargin = 8; // Medium margin for tablet
    } else if (isExtraWide) {
        horizontalMargin = 48; // Larger margin for extra wide screens
    }

    return (
        <Box style={{
            display: 'flex', flexDirection: 'column',
            backgroundColor: 'white', color: 'black', minHeight: '100vh'
        }}>
            <Box style={{ flex: 1 }}>
                <AppBar position="sticky" color="default" elevation={0}>
                    <Toolbar sx={{ marginTop: 1, marginBottom: 1 }}>
                        <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                            <img src={'/img/hitch.png'} alt="Hitch Logo" style={{ width: '80px', height: '80px', marginRight: '10px' }} />
                            <Typography variant="h6">Request Demo</Typography>
                        </Link>
                    </Toolbar>
                </AppBar>
                <Box style={{
                    backgroundColor: 'black',
                    color: 'white',
                    width: '100%',
                    padding: '28px 0',
                    marginBottom: '28px'

                }}>
                    <Typography variant="body1" style={{ textAlign: 'center' }}>

                    </Typography>
                </Box>

                



                <Box
                    //marginBottom="24px"
                    marginLeft={horizontalMargin}
                    marginRight={horizontalMargin}
                    marginTop={4}
                    marginBottom={4}
                    textAlign="left"
                >
                    <RequestDemoForm />
                </Box>
            </Box>
            <FooterMain />
        </Box>
    );
};

export default RequestDemoPage;
