import { ExpandMore } from '@mui/icons-material';
import { Avatar, Button, ButtonGroup, Collapse, Grid, ListItem, ListItemButton, ListItemIcon, ListItemText, Select, Typography } from '@mui/material';
import SelectLastTenModal from './SelectLastTenModal';
import React, { useState, useEffect, useMemo } from 'react';
import { Handle, useReactFlow, Position } from 'reactflow';
import { useHubConnections } from '../../utils/HubConnectionsProvider';
import SfoSelect from './SfoSelect';
import DynamicForm from './DynamicForm';

function HitchEventNode({ id, data }) {

  const [service, setService] = useState(data.service || '');
  const [featureName, setFeatureName] = useState(data.featureName || '');
  const [operationName, setOperationName] = useState(data.operationName || '');

  const [featureOptions, setFeatureOptions] = useState([]);
  const [operationOptions, setOperationOptions] = useState([]);

  const [dynamicFormFields, setDynamicFormFields] = useState([]);
  const [localData, setLocalData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lastTen, setLastTen] = useState([]);


  const reactFlowInstance = useReactFlow();
  const [ruleAttached, setRuleAttached] = useState(false);
  const [routes, setRoutes] = useState([]);
  const {
    dashboardConnection,
    jwtToken,
    hitchUser,
    user,
    isAuthenticated,
    setHitchUser,
    dashStarted,
    setSessionId,
    loading,
    conversationHistories,
    matrixRoutes,
    started,
    hitchCommand,
    setMessages,
    messages,
    conversation,
    users,
    hitchDashCommand,
    hitchCommandWithResponseHandling 
  } = useHubConnections();

  useEffect(() => {
    if (dashboardConnection) {
      // COMMENT TO TEST LOCAL JSON, UNCOMMENT FOR SERVER VERSION
      dashboardConnection.on("RouteUpdate", handleRouteUpdate);
      dashboardConnection.on("MatrixRoutes", setMatrixRoutes);

      hitchDashCommand('account', 'connection', 'route');

      // Cleanup listener when component unmounts or hubConnection changes
      return () => {
        dashboardConnection.off("RouteUpdate", handleRouteUpdate);
        dashboardConnection.off("MatrixRoutes", setMatrixRoutes);
      }
    }
  }, [dashboardConnection]);

  function setMatrixRoutes(data) {
    setRoutes(data);
  }

  useEffect(() => {
    // For unique feature options
    const featuresMap = new Map();
    routes
      .filter(r => r.serviceId === localData.serviceId)
      .forEach(r => {
        if (!featuresMap.has(r.featureId)) {
          featuresMap.set(r.featureId, { value: r.featureId, label: r.operationType });
        }
      });
    const featureOptionsUpdate = Array.from(featuresMap.values());

    // For unique operation options
    const operationsMap = new Map();
    routes
      .filter(r => r.serviceId === localData.serviceId && r.featureId === localData.featureId)
      .forEach(r => {
        if (!operationsMap.has(r.operationId)) {
          operationsMap.set(r.operationId, { value: r.operationId, label: r.operationSubtype });
        }
      });
    const operationOptionsUpdate = Array.from(operationsMap.values());

    setFeatureOptions(featureOptionsUpdate);
    setOperationOptions(operationOptionsUpdate);
  }, [routes, localData.serviceId, localData.featureId]);

  useEffect(() => {
    // Call checkRuleAttachment and update state based on its return value
    const ruleIsAttached = checkRuleAttachment(); // This now uses the reactFlowInstance directly
    setRuleAttached(ruleIsAttached);
  }, [reactFlowInstance, id]); // Depend on reactFlowInstance and id to re-check when they change

  const handleRouteUpdate = (data) => {
    console.log('Route Update', data);
  }

  const handleFeatureChange = (event) => {
    const newFeatureId = event.target.value;
    // Update localData in an immutable way
    setLocalData(prevData => ({
      ...prevData,
      featureId: newFeatureId,
      // Optionally reset operationId when feature changes
      operationId: ''
    }));
  };

  const handleOperationChange = (event) => {
    const newOperationId = event.target.value;
    // Update localData in an immutable way
    setLocalData(prevData => ({
      ...prevData,
      operationId: newOperationId
    }));
    getEventInfo(newOperationId);
  };

  const checkRuleAttachment = () => {
    const edges = reactFlowInstance.getEdges();
    const isAttached = edges.some(edge => edge.source === id || edge.target === id);
    return isAttached;
  };

  const handleDeleteNode = (nodeId) => {
    console.log('delete!', nodeId);
    data.onNodeCallback('local', 'node', 'delete', nodeId);
  };

  useEffect(() => {
    if (data) {
      setLocalData(data);
    }
  }, [data]);

  function getEventInfo(newOperationId) {
    hitchCommandWithResponseHandling('4da8cac8-2592-49fa-b6bc-67cee0651b2f', 'dashboard', 'account', 'eventing', 'event-info', { OperationId: newOperationId },
      (response) => {
          console.log("Received response:", response);
          setLastTen(response.lastTen); // Assume response.lastTen is the array you want to display
          setIsModalOpen(true); // Open the modal
      });
  }

  function getOptions(payload = {}) {
      var systemName = routes.find(r => r.serviceId === localData.serviceId)?.system;
      console.log('localData.featureId', localData.featureId);
      console.log('localData', localData);
      var featureName = routes.find(r => r.featureId === localData.featureId)?.operationType;
      var operationName = routes.find(r => r.operationId === localData.operationId)?.operationSubtype;    

      hitchCommandWithResponseHandling(localData.operationId, 'dashboard', systemName, featureName, operationName, payload,
      (response) => {
          console.log("Received response:", response);
          // Handle the response here
      });
  }

  return (
    <>
      <div className="custom-node__header">
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Avatar
                src={`${window._env_.HITCH_API}/api/accimage/${localData.serviceId}.png`}
              />
            </ListItemIcon>
            <ListItemText primary={localData.serviceName} />
          </ListItemButton>
        </ListItem>
      </div>
      <div className="custom-node__body">
        <SfoSelect
          options={featureOptions}
          handleChange={handleFeatureChange}
          localId={localData.featureId}
          nodeId={id}
          label={'Feature Name'}
        />
        <SfoSelect
          options={operationOptions}
          handleChange={handleOperationChange}
          localId={localData.operationId}
          nodeId={id}
          label={'Operation Name'}
        />
        <DynamicForm localData={localData} setLocalData={setLocalData} />
        <ButtonGroup>
          <Button variant="contained" color="error" onClick={() => handleDeleteNode(id)}>
            Delete
          </Button>
        </ButtonGroup>
        <SelectLastTenModal
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          lastTen={lastTen}
          onSelect={(selectedRow) => {
            console.log('Selected:', selectedRow);
            setIsModalOpen(false);
            // Here you can update localData with the selectedRow's data while preserving serviceId, operationId, and featureId
            setLocalData(prevData => ({
              ...prevData,
              ...selectedRow, // Make sure to only update what's necessary, or adjust accordingly
            }));
          }}
        />
        <Handle type="source" position={Position.Right} className="custom-handle event-handle-top" id={id} />

      </div>
    </>
  );
}

export default HitchEventNode;
