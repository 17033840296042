import React, { useState } from 'react';
import { Box, Stack, Typography, Modal, Backdrop, Link } from '@mui/material';
import './FooterMain.css';
import ContactModal from '../contact/ContactModal'; // Import ContactModal
import ContactForm from '../contact/ContactForm'; // Import ContactForm
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function FooterMain() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  const handleOpenModal = (event) => {
    event.preventDefault(); // Prevent default link behavior
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Inline style for the footer links
  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    transition: 'text-decoration 0.3s ease-in-out',
    cursor: 'pointer',
    fontSize: '.9rem',
    fontFamily: 'Poppins, sans-serif',
  };

  // Function to handle navigation to home
  const navigateToHome = () => {
    navigate('/');
  };

  // Function to handle navigation to different routes
  const handleNavigate = (path) => {
    navigate(path);
  };


  return (
    <Box
      bgcolor="black"
      paddingTop={{ xs: 9, sm: 9, md: 9 }}
      paddingBottom={{ xs: 6, sm: 4, md: 4 }}
      paddingLeft={{ xs: 3, sm: 8, md: 10.5 }}
    >
      <Stack spacing={3}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={navigateToHome} // Add click event to navigate to home
        >
          <img src={'/img/hitch.png'} alt="Logo" style={{ width: '49px', height: '49px', marginRight: '4px' }} />

          <Typography
            variant="h6"
            sx={{
              fontFamily: 'Benchnine, sans-serif',
              fontSize: '1.280rem',
              color: 'white',
              marginBottom: -.64,
            }}
          >
            SOFTWARE
          </Typography>
        </Box>


        <Box
          sx={{
            fontFamily: 'Benchnine, sans-serif',
            fontSize: '1.10rem',
          }}
          className="footermain-links"
        >
          <Stack direction="row" spacing={4}>
          <Box>
  <Typography
    style={linkStyle}
    onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
    onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
  >
    <a href="/letstalk" target="_blank" rel="noopener noreferrer">
      Contact Us
    </a>
  </Typography>
</Box>
<Box>
  <Typography
    style={linkStyle}
    onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
    onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
  >
    <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
      Privacy Policy
    </a>
  </Typography>
</Box>
<Box>
  <Typography
    style={linkStyle}
    onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
    onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
  >
    <a href="/tos" target="_blank" rel="noopener noreferrer">
      Terms of Service
    </a>
  </Typography>
</Box>

          </Stack>
        </Box>
        <Box>
          <Typography sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: '0.8rem',
            marginBottom: -0.75,
            color: 'white'
          }}>
            &#169; 2024 Hitch Software, LLC. All rights reserved
          </Typography>
        </Box>
      </Stack>
      {/* Modal for Contact Us */}
      <ContactModal isOpen={isModalOpen} handleClose={handleCloseModal}>
        <ContactForm />
      </ContactModal>
    </Box>
  );
}

export default FooterMain;