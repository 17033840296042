import { Box, Grid, CircularProgress, circularProgressClasses } from "@mui/material";
import "./HitchSpinner.css";
function HitchSpinner({ visible }) {

    return (
        <Grid item xs={12}>
            {visible &&
                (
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center"> {/* Center horizontally */}
                                <Box sx={{ position: 'relative' }}>
                                    <CircularProgress
                                        variant="indeterminate"
                                        disableShrink
                                        sx={{
                                            color: (theme) => (theme.palette.mode === 'light' ? '#000000' : '#308fe8'),
                                            animationDuration: '550ms',
                                            [`& .${circularProgressClasses.circle}`]: {
                                                strokeLinecap: 'round',
                                            },
                                        }}
                                        size={80}
                                        thickness={4}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center" className="underLogo"> {/* Center horizontally */}
                                {/* Add an image underneath */}
                                <img src="/img/HitchLogo.png" className="loader" alt="Your Image" style={{ maxWidth: '100%' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    );
}

export default HitchSpinner;