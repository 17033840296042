import React, { useState, useEffect } from "react";
import { Grid, Paper, TextField, Button, InputAdornment, Typography, ButtonGroup, Card, CardActionArea, CardMedia, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDuck } from "@fortawesome/pro-duotone-svg-icons";
import { v4 as uuidv4 } from 'uuid';
import * as signalR from '@microsoft/signalr';
import { useNavigate } from "react-router-dom";
import HitchBox from "../hitchbox/HitchBox";

import "./DemoChat.css";

const TopChat = styled(Paper)`
    color: #FFFFFF;
    background-color: #000909;
    height: 25vh;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    border: 4px solid #FFFFFF; /* White border with 4px width */
    border-radius: 10px; /* Curved corners */
`;
const MiddleAction = styled(Paper)`
    color: #FFFFFF;
    background-color: #000000;
    height: 10vh;
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
`;
const BottomInput = styled(Paper)`
    color: #FFFFFF;
    background-color: #333333;
    height: 8vh;
`;

const BottomInputBox = styled(TextField)`
    color: #FFFFFF !important;
    background-color: #333333;
    & .MuiOutlinedInput-input {
        color: #FFFFFF; /* Change the text color to white */
    }
`;

const WhiteFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: #FFFFFF; /* Change the FontAwesome icon color to white */
`;


function DemoChat({ 
    allowChat, 
    answers, 
    guid, 
    hubConnection, 
    inputValue, 
    messageToType, 
    setImages, 
    setInputValue }) {
    
    const [typedMessage, setTypedMessage] = useState("");
    
    const [answerDisplay, setAnswerDisplay] = useState([]);
    const [showAnswer, setShowAnswer] = useState(false);
    
    
    
    const history = useNavigate();

    useEffect(() => {
        // RESET ANIMATION
        setAnswerDisplay(answers);
        // ANIMATION RUN
    }, [answers]);

    const typeMessage = (message) => {
        let index = 0;
        let prevMsg = '';
        const typingInterval = setInterval(() => {
            
            if (message && index < message.length) {
                setShowAnswer(false);
                prevMsg = prevMsg + message[index];
                setTypedMessage(prevMsg);
                index++;
            } else {
                clearInterval(typingInterval);
                setShowAnswer(true);
            }
        }, 100); // Adjust the typing speed here (e.g., 100ms per character)
    };


    // Start typing when the component mounts
    useEffect(() => {
        if(messageToType) {
            typeMessage(messageToType);
        }
    }, [messageToType]);

    function answerQuestion(questionId, answer) {
        if (hubConnection) {
            hubConnection.invoke("AnswerQuestion", guid, questionId, answer)
                .then(() => {
                })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    function freeTextAnswer(event) {
        if (hubConnection) {
            const qId = answers[0]?.questionId;

            hubConnection.invoke("AnswerQuestion", guid, qId, inputValue)
                .then(() => {
                })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    useEffect(() => {
        if(allowChat) {
            setInputValue('');
        }
    }, [allowChat]);

    return (
        <Grid container spacing={3} style={{ marginTop: '16px' }} justifyContent="center" alignItems="stretch">
            <Grid item xs={12} md={12}>
                <TopChat>
                    <Typography variant="h4" style={{ fontFamily: 'Courier New' }}>
                        {typedMessage}
                    </Typography>
                </TopChat>
            </Grid>
            {answerDisplay && showAnswer && answerDisplay.length > 1 && (
                <Grid item xs={12} md={12} style={{ marginTop: '20px' }}>
                    <MiddleAction>
                        {/* Center the cards horizontally and vertically */}
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            {answerDisplay.map((ans) => (

                                // <Grid item xs={4}  justifyContent="center" alignItems="center">
                                <Card sx={{ maxWidth: 345 }} key={ans.value} className="answer-item" justifyContent="center" alignItems="center">
                                    <CardActionArea>
                                        {/* <CardMedia
                                                component="img"
                                                height="140"
                                                image={ans.image}
                                                alt={ans.value}
                                                onClick={(e) => answerQuestion(ans.questionId, ans.value)}
                                            /> */}
                                        <CardContent onClick={(e) => answerQuestion(ans.questionId, ans.value)}>
                                            <Typography variant="h4">{ans.value}</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>

                                // </Grid>
                            ))}
                        </Grid>
                    </MiddleAction>
                </Grid>
            )}

             {allowChat && (
                <Grid item xs={12} md={12}>
                    <BottomInput>
                        {/* Input chat box */}
                        <BottomInputBox
                            fullWidth
                            multiline
                            rows={3}
                            variant="outlined"
                            placeholder="Enter Your Code..."
                            value={inputValue}
                            onChange={(e) => {
                                setInputValue(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end" onClick={(e) => freeTextAnswer(e)}><WhiteFontAwesomeIcon icon={faDuck} /></InputAdornment>
                            }}
                        />
                    </BottomInput>
                </Grid>
            )} 
        </Grid>
    );
}

export default DemoChat;