import React from 'react';
import { Box, FormControlLabel, IconButton, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import HitchBox from "../../components/hitchbox/HitchBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignalSlash, faSignalStrong, faTrash } from "@fortawesome/pro-solid-svg-icons";

function StoreOperationManager({ connectedOperations, formData, handleOperationClick, handleToggleSwitch, handleDisconnect }) {
    const getStatus = (operation) => {
        return formData[operation] ? formData[operation] : "NOT CONNECTED";
    };

    const getIcon = (operation) => {
        return formData[operation] ? faSignalStrong : faSignalSlash;
    };

    const isConnected = (operation) => {
        return formData[operation] ? true : false;
    };

    return (
        <>
            <HitchBox backgroundColor={formData.pushToSource ? '#00AAAA' : '#002222'} mb={2}>
                <FormControlLabel
                    control={<Switch checked={formData.pushToSource} onChange={() => handleToggleSwitch('pushToSource')} />}
                    label="Send To Open AI"
                />
                <Box display="flex" flexDirection="column">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Operation</TableCell>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>putFile</TableCell>
                                <TableCell>
                                    {isConnected('putFileOperation') && (
                                        <IconButton>
                                            <FontAwesomeIcon icon={getIcon('putFileOperation')} />
                                        </IconButton>
                                    )}
                                    {!isConnected('putFileOperation') && (
                                        <IconButton
                                            onClick={() => handleOperationClick('putFileOperation')}>
                                            <FontAwesomeIcon icon={getIcon('putFileOperation')} />
                                        </IconButton>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {getStatus('putFileOperation')}
                                        {isConnected('putFileOperation') && (
                                            <IconButton onClick={() => handleDisconnect('putFileOperation')}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </IconButton>
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>deleteFile</TableCell>
                                <TableCell>
                                    {isConnected('deleteFileOperation') && (
                                        <IconButton>
                                            <FontAwesomeIcon icon={getIcon('deleteFileOperation')} />
                                        </IconButton>
                                    )}
                                    {!isConnected('deleteFileOperation') && (
                                        <IconButton
                                            onClick={() => handleOperationClick('deleteFileOperation')}>
                                            <FontAwesomeIcon icon={getIcon('deleteFileOperation')} />
                                        </IconButton>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {getStatus('deleteFileOperation')}
                                        {isConnected('deleteFileOperation') && (
                                            <IconButton onClick={() => handleDisconnect('deleteFileOperation')}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </IconButton>
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>putMetadata</TableCell>
                                <TableCell>
                                    {isConnected('putMetadataOperation') && (
                                        <IconButton>
                                            <FontAwesomeIcon icon={getIcon('putMetadataOperation')} />
                                        </IconButton>
                                    )}
                                    {!isConnected('putMetadataOperation') && (
                                        <IconButton
                                            onClick={() => handleOperationClick('putMetadataOperation')}>
                                            <FontAwesomeIcon icon={getIcon('putMetadataOperation')} />
                                        </IconButton>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {getStatus('putMetadataOperation')}
                                        {isConnected('putMetadataOperation') && (
                                            <IconButton onClick={() => handleDisconnect('putMetadataOperation')}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </IconButton>
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>deleteMetadata</TableCell>
                                <TableCell>
                                    {isConnected('deleteMetadataOperation') && (
                                        <IconButton>
                                            <FontAwesomeIcon icon={getIcon('deleteMetadataOperation')} />
                                        </IconButton>
                                    )}
                                    {!isConnected('deleteMetadataOperation') && (
                                        <IconButton
                                            onClick={() => handleOperationClick('deleteMetadataOperation')}>
                                            <FontAwesomeIcon icon={getIcon('deleteMetadataOperation')} />
                                        </IconButton>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {getStatus('deleteMetadataOperation')}
                                        {isConnected('deleteMetadataOperation') && (
                                            <IconButton onClick={() => handleDisconnect('deleteMetadataOperation')}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </IconButton>
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </HitchBox>
            <Box sx={{ height: '10px' }} />
            <HitchBox backgroundColor={formData.pullFromSource ? '#00AAAA' : '#002222'} mb={2}>
                <FormControlLabel
                    control={<Switch checked={formData.pullFromSource} onChange={() => handleToggleSwitch('pullFromSource')} />}
                    label="Pull From Open AI"
                />
                <Box display="flex" flexDirection="column">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Operation</TableCell>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>list</TableCell>
                                <TableCell>
                                    {isConnected('listOperation') && (
                                        <IconButton>
                                            <FontAwesomeIcon icon={getIcon('listOperation')} />
                                        </IconButton>
                                    )}
                                    {!isConnected('listOperation') && (
                                        <IconButton
                                            onClick={() => handleOperationClick('listOperation')}>
                                            <FontAwesomeIcon icon={getIcon('listOperation')} />
                                        </IconButton>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {getStatus('listOperation')}
                                        {isConnected('listOperation') && (
                                            <IconButton onClick={() => handleDisconnect('listOperation')}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </IconButton>
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>get</TableCell>
                                <TableCell>
                                    {isConnected('getOperation') && (
                                        <IconButton>
                                            <FontAwesomeIcon icon={getIcon('getOperation')} />
                                        </IconButton>
                                    )}
                                    {!isConnected('getOperation') && (
                                        <IconButton
                                            onClick={() => handleOperationClick('getOperation')}>
                                            <FontAwesomeIcon icon={getIcon('getOperation')} />
                                        </IconButton>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {getStatus('getOperation')}
                                        {isConnected('getOperation') && (
                                            <IconButton onClick={() => handleDisconnect('getOperation')}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </IconButton>
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </HitchBox>
        </>
    );
}

export default StoreOperationManager;
