import { Box, List, ListItem, ListItemText, Typography, TextField, IconButton, InputAdornment } from "@mui/material";
import { useEffect } from "react";

function SkuResults({ onSkuSelect, results }) {

    useEffect(() => {
        
    }, [results]);

    return (
        <Box sx={{ width: '100%' }}>
            <List dense={true}> {/* Set dense for compact display */}
                {results.map((result) => (
                    <ListItem
                        key={result.id}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => onSkuSelect(result.id)}
                    >
                        <ListItemText
                            primary={
                                <Typography variant="body2">
                                    {result.skuNumber} - {result.name}
                                </Typography>
                            }
                            secondary={<Typography variant="caption">${result.unitPrice.toFixed(2)}</Typography>}
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}

export default SkuResults;
