import React, { useState, useEffect } from "react";
import { Grid, Paper, TextField, Button, InputAdornment, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDuck } from "@fortawesome/pro-duotone-svg-icons";
import { v4 as uuidv4 } from 'uuid';
import * as signalR from '@microsoft/signalr';
import { useNavigate, useParams } from "react-router-dom";
import isValidGUID from "../../utils/IsValidGuid";

const TopChat = styled(Paper)`
    color: #FFFFFF;
    background-color: #000909;
    height: 25vh;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    border: 4px solid #FFFFFF; /* White border with 4px width */
    border-radius: 10px; /* Curved corners */
`;
const MiddleAction = styled(Paper)`
    color: #FFFFFF;
    background-color: #000000;
    height: 25vh;
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
`;
const BottomInput = styled(Paper)`
    color: #FFFFFF;
    background-color: #333333;
    height: 25vh;
`;

const BottomInputBox = styled(TextField)`
    color: #FFFFFF !important;
    background-color: #333333;
    & .MuiOutlinedInput-input {
        color: #FFFFFF; /* Change the text color to white */
    }
`;

const WhiteFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: #FFFFFF; /* Change the FontAwesome icon color to white */
`;

function Wizard({ user, isAuthenticated }) {
    const [hubConnection, setHubConnection] = useState(null);
    const [typedMessage, setTypedMessage] = useState("");
    const [sessionId, setSessionId] = React.useState('');
    const [hitchUser, setHitchUser] = React.useState(null);
    // const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const { guid } = useParams()
    const [started, setStarted] = useState(false);
    const messageToType = "Hello, this is a test message."; // Change this to your desired message
    const history = useNavigate();
    
    useEffect(() => {
        if (guid && isValidGUID(guid)) {
            setSessionId(guid); 
            setStarted(false);
        } else {
            const newGuid = uuidv4();
            history(`/app/wizard/${newGuid}`);
            setSessionId(newGuid);
        }
    }, [guid]);

    useEffect(() => {
        console.log('Checking conditions to start SignalR:');
        if (!started && isAuthenticated && isValidGUID(sessionId)) {
            const jwtToken = user.id_token;
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(`${window._env_.HITCH_API}/wizardHub?sessionId=${sessionId}`, {
                     accessTokenFactory: () => jwtToken
                 })
                .withAutomaticReconnect()
                .build();

            connection.start()
                .then(() => {
                    console.log("SignalR connected!");
                    setHubConnection(connection);
                    setStarted(true);
                })
                .catch(error => {
                    console.error("SignalR connection error:", error);
                    if (error?.statusCode === 401 || error?.status === 401 || error.message === 'login_required') {
                        const currentAddress = `${window.location.pathname}${window.location.search}`;
                        window.location.href = `/login?redirect=${encodeURIComponent(currentAddress)}`;
                    }
                });

            connection.on("BotMessage", handleBotMessage);

            return () => {
                if (hubConnection) {
                    hubConnection.off("BotMessage", handleBotMessage);
                    hubConnection.stop();
                }
            };
        }
    }, [started, hubConnection, isAuthenticated, guid]);

    const typeMessage = (message) => {
        let index = 0;
        let prevMsg = '';
        const typingInterval = setInterval(() => {
            if (index < message.length) {
                prevMsg = prevMsg + message[index];
                setTypedMessage(prevMsg);
                index++;
            } else {
                clearInterval(typingInterval);
            }
        }, 100); // Adjust the typing speed here (e.g., 100ms per character)
    };

    function handleBotMessage(data) {
        console.log('handleBotMessage', data);
        setTypedMessage(data)
    }

    // Start typing when the component mounts
    useEffect(() => {
        typeMessage('Welcome to Hitch.AI, the greatest AI Platform on the planet.');
    }, [messageToType]);

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="stretch">
            <Grid item xs={12} md={12}>
                <TopChat>
                    <Typography variant="h6" style={{ fontFamily: 'Poppins' }}>
                        {typedMessage}
                    </Typography>
                </TopChat>
            </Grid>
            <Grid item xs={12} md={12}>
                <MiddleAction>
                    {/* Buttons or information */}
                    <Button variant="contained" color="primary">
                        Button 1
                    </Button>
                    <Button variant="contained" color="secondary">
                        Button 2
                    </Button>
                </MiddleAction>
            </Grid>
            <Grid item xs={12} md={12}>
                <BottomInput>
                    {/* Input chat box */}
                    <BottomInputBox
                        fullWidth
                        multiline
                        rows={3}
                        variant="outlined"
                        placeholder="Type your message..."
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><WhiteFontAwesomeIcon icon={faDuck} /></InputAdornment>
                        }}
                    />
                </BottomInput>
            </Grid>
        </Grid>
    );
}

export default Wizard;
