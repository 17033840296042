import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Box } from '@mui/material';

const MarkdownRenderer = ({ content }) => {
    const formatMarkdown = (content) => {
        return content
            .replace(/\\n\\n/g, '\n\n')
            .replace(/\\n/g, '\n')
            .replace(/(\d+\.\s[^\n]+)/g, '$1\n')
            .replace(/(\S)(\n)(?=\S)/g, '$1\n\n')
            .replace(/(\n)(?=\d+\.\s)/g, '\n\n');
    };

    const sanitizeInput = (input) => {
        return input.replace(/`/g, '\\`'); // Escape backticks
    };

    const formattedContent = formatMarkdown(content);
    const sanitizedContent = sanitizeInput(formattedContent);

    return (
        <Box
            sx={{
                padding: '4px',
                overflow: 'hidden',
            }}
        >
            {sanitizedContent ? (
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                        p: ({ node, ...props }) => (
                            <p
                                style={{
                                    marginTop: node.position.start.line === 1 ? '0em' : '1em',
                                }}
                                {...props}
                            />
                        ),
                        ol: ({ node, ...props }) => (
                            <ol style={{ paddingLeft: '20px' }} {...props} />
                        ),
                        ul: ({ node, ...props }) => (
                            <ul style={{ paddingLeft: '20px' }} {...props} />
                        ),
                    }}
                >
                    {sanitizedContent}
                </ReactMarkdown>
            ) : (
                <p>Error rendering content</p>
            )}
        </Box>
    );
};

export default MarkdownRenderer;
