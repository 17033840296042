import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Popover, Box, Avatar, Typography, Divider, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faFileContract, faMoon, faQuestionCircle, faUserPlus, faExchange, faSignOut, faUserCog, faFileInvoiceDollar, faCreditCard, faUser, faCog } from '@fortawesome/free-solid-svg-icons';
import { faArrowProgress, faDuck, faGears, faHardDrive, faMessageBot } from '@fortawesome/pro-solid-svg-icons';
import { useThemeContext } from '../../ThemeContext';
import { useHubConnections } from '../../utils/HubConnectionsProvider';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';

import DonutLargeIcon from '@mui/icons-material/DonutLarge';

function UserMenuPopover({ anchorEl, onClose, userAvatar, navigate, onInviteTeamMembersClick, onContactSupportClick }) {
  const theme = useTheme();
  const { mode, setMode } = useThemeContext();

  const themeBackgroundColor = theme.palette.modalBackground[theme.palette.mode];
  const themeTextSecondaryColor = theme.palette.modalTextSecondary[theme.palette.mode];
  const themeListItemHovered = theme.palette.listItemBackground.hover[theme.palette.mode];
  const themeListItemDivider = theme.palette.listItemDivider[theme.palette.mode];
  const themeLink = theme.palette.modalLink[theme.palette.mode];
  const [menuItems, setMenuItems] = useState([]);

  const { dashboardConnection, isConnectionReady, hitchUser, hitchDashCommand } = useHubConnections();

  useEffect(() => {
    if (dashboardConnection && isConnectionReady) {
      dashboardConnection.on("11ba8c1f-e0a9-4ae7-a601-e8e187243f6d", handleMenu);
      hitchDashCommand('account', 'menu', 'get', { "menuName": "main" }, () => { });

      return () => {
        dashboardConnection.off("11ba8c1f-e0a9-4ae7-a601-e8e187243f6d", handleMenu);
      };
    }
  }, [dashboardConnection, isConnectionReady]);

  const handleMenu = (data) => {
    if (data.menuType === 'main') {
      setMenuItems(data.items);
    }
  };

  const iconMapper = {
    SwapHorizIcon: <FontAwesomeIcon icon={faExchange} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    PersonAddIcon: <FontAwesomeIcon icon={faUserPlus} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    SupportIcon: <FontAwesomeIcon icon={faQuestionCircle} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    ModeNightIcon: <FontAwesomeIcon icon={faMoon} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    SignOutIcon: <FontAwesomeIcon icon={faSignOut} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    TermsIcon: <FontAwesomeIcon icon={faFileContract} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    PrivacyIcon: <FontAwesomeIcon icon={faShieldAlt} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    RulesIcon: <FontAwesomeIcon icon={faGears} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    MenusIcon: <FontAwesomeIcon icon={faDuck} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    StorageIcon: <FontAwesomeIcon icon={faHardDrive} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    BotIcon: <FontAwesomeIcon icon={faMessageBot} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    WorkflowIcon: <FontAwesomeIcon icon={faArrowProgress} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    ManageAccountIcon: <FontAwesomeIcon icon={faUserCog} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    BillingHistoryIcon: <FontAwesomeIcon icon={faFileInvoiceDollar} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    ManageSubscriptionIcon: <FontAwesomeIcon icon={faCreditCard} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    ManageProfileIcon: <FontAwesomeIcon icon={faUser} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
    SettingsIcon: <FontAwesomeIcon icon={faCog} style={{ color: themeTextSecondaryColor, fontSize: '1.1em' }} />,
  };

  const handleMenuItemClick = (action) => {
    let targetUrl;
    let subMenuState;

    switch (action) {
        case 'switchOrganizations':
            targetUrl = '/app/account/my-organizations';
            break;
        case 'inviteTeamMember':
            onClose();
            onInviteTeamMembersClick();
            return;
        case 'contactSupport':
            targetUrl = '/app/account/contact-support';
            break;
        case 'openTerms':
            onClose();
            window.open("/terms-of-use", "_blank");
            return;
        case 'openPrivacy':
            onClose();
            window.open("/privacy-policy", "_blank");
            return;
        case 'rules':
            targetUrl = '/app/rules';
            break;
        case 'openMenus':
            targetUrl = '/app/menus';
            break;
        case 'openBots':
            targetUrl = '/app/bots';
            break;
        case 'openStorage':
            targetUrl = '/app/storage';
            break;
        case 'manageOrganization':
            targetUrl = '/app/account/manage-organization';
            break;
        case 'managePlan':
            targetUrl = '/app/account/manage-subscription';
            break;
        case 'viewBilling':
            targetUrl = '/app/account/billing';
            break;
        default:
            onClose();
            console.log("Unknown action:", action);
            return;
    }

    if (targetUrl) {
        onClose();
        navigate(targetUrl, { state: { selectedSubMenu: subMenuState } });
    }
};


  const linkStyle = {
    color: themeLink,
    cursor: 'pointer',
    textDecoration: 'none',
    fontFamily: '"Poppins", sans-serif',
    //textAlign: 'center', // Center align the text
    display: 'block', // Ensure the link takes up the full width
    width: '100%', // Set the width to 100% for proper centering
  };

  const linkStylecenter = {
    color: themeLink,
    cursor: 'pointer',
    textDecoration: 'none',
    fontFamily: '"Poppins", sans-serif',
    textAlign: 'center', // Center align the text
    display: 'block', // Ensure the link takes up the full width
    width: '100%', // Set the width to 100% for proper centering
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const selectedSubMenuParam = queryParams.get('selectedSubMenu');

    if (selectedSubMenuParam) {
      handleMenuItemClick(selectedSubMenuParam);
    }
  }, [navigate]);

  const filteredMenuItems = menuItems.filter(item => {
    if (item.roles) {
      return item.roles.some(role => hitchUser.roles.includes(role));
    }
    return true;
  });

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: themeBackgroundColor,
        borderColor: '#2A2E39',
        borderStyle: 'solid',
        borderWidth: '1px',
        color: themeTextSecondaryColor,
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.6)', // Subtle shadow for depth
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
          <Avatar
            alt="User Avatar"
            src={userAvatar || "/static/images/avatar/default.jpg"}
            sx={{
              backgroundColor: '#757575',
              border: "2px solid black",
              width: 50,
              height: 50,
            }}
          />
          <Box sx={{ marginLeft: 2, textAlign: 'left' }}>
            <Typography variant="body1" sx={{ fontFamily: '"Poppins", sans-serif' }}>
              {hitchUser ? hitchUser.name : "Loading..."}
            </Typography>
            <Typography variant="body2" sx={{ fontFamily: '"Poppins", sans-serif' }} gutterBottom>
              {hitchUser ? hitchUser.email : "Loading..."}
            </Typography>

            <Typography
              variant="body1"
              style={linkStyle}
              onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
              onMouseOut={(e) => e.target.style.textDecoration = 'none'}
              onClick={() => {
                onClose(); // Close the popover
                navigate('/app/account/profile'); // Navigate directly to the Manage Profile page
              }}
              sx={{ fontSize: '14px', px: 0, py: 0 }}
            >
              Manage Profile
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ width: '100%', bgcolor: themeListItemDivider, height: '2px', marginBottom: '8px' }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginBottom: '12px'
          }}
        >
          <Typography
            variant="body2"
            style={linkStylecenter}
            onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
            onMouseOut={(e) => e.target.style.textDecoration = 'none'}
            onClick={() => {
              onClose(); // Close the popover
              navigate('/app/account/dashboard');
            }}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '14px', px: 0, }}
          >
            <DashboardIcon
              sx={{
                fontSize: '18px',
                marginRight: .5,
                color: themeLink,
                verticalAlign: 'baseline',
                transform: 'translateY(4px)' // Slightly raise the icon
              }}
            />
            My Account
          </Typography>
        </Box>
        <Divider sx={{ width: '100%', bgcolor: themeListItemDivider, height: '2px', marginBottom: '12px' }} />
        {filteredMenuItems.length > 0 && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', px: 2, marginTop: '4px', marginBottom: '4px' }}>
              <Typography sx={{ color: themeTextSecondaryColor, textAlign: 'left', fontFamily: '"Poppins", sans-serif' }}>
                Quick Actions
              </Typography>
            </Box>
            <List component="nav" sx={{ width: '100%', paddingTop: 0, paddingBottom: 0 }}>
              {filteredMenuItems.map((item, index) => (
                <React.Fragment key={index}>
                  <ListItem button onClick={() => handleMenuItemClick(item.action)} sx={{ height: '48px', '&:hover': { backgroundColor: themeListItemHovered } }}>
                    <ListItemIcon sx={{ marginRight: '-16px' }}>
                      {iconMapper[item.icon]}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.label}
                      sx={{ margin: 0 }}
                      primaryTypographyProps={{ sx: { fontFamily: '"Poppins", sans-serif', fontSize: '1rem' } }}
                    />
                  </ListItem>
                  {index < filteredMenuItems.length - 1 && <Divider sx={{ bgcolor: themeListItemDivider }} />}
                </React.Fragment>
              ))}
            </List>
          </>
        )}
        <Divider sx={{ width: '100%', bgcolor: themeListItemDivider, height: '2px', marginTop: filteredMenuItems.length === 0 ? '12px' : 0 }} />
        <List component="nav" sx={{ width: '100%', paddingTop: 0, paddingBottom: 0 }}>
          <ListItem button onClick={() => { onClose(); navigate('/logout'); }} sx={{ height: '48px', '&:hover': { backgroundColor: themeListItemHovered } }}>
            <ListItemIcon sx={{ marginRight: '-16px' }}>
              {iconMapper['SignOutIcon']}
            </ListItemIcon>
            <ListItemText primary="Sign Out" sx={{ margin: 0 }} primaryTypographyProps={{ sx: { fontFamily: '"Poppins", sans-serif', fontSize: '1rem' } }} />
          </ListItem>
        </List>
      </Box>
    </Popover>
  );
}

export default UserMenuPopover;
