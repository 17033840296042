import { v4 as uuidv4 } from 'uuid';

function ActionDependencyFactory(accountId, ruleId, actionId) {
    const position = {
        x: 0,
        y: 0,
    };

    const newId = uuidv4();

    return {
        id: newId,
        type: 'actiondependency',
        position,
        data: { 
            id: newId,
            name: 'New Action Dependency',
            accountId: accountId,
            actionId: actionId,
            ruleId: ruleId,
            serviceId: '',
            featureId: '',
            operationId: '',
            actionDependencyProperty: []
        }
    };
}

export default ActionDependencyFactory;