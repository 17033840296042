
import { faComments, faDisplayChartUpCircleCurrency, faDuck, faLineColumns, faMicrochipAi, faPeoplePulling } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button, ButtonGroup, Grid, Icon, IconButton, Typography } from "@mui/material";
import { deepOrange, green } from '@mui/material/colors';

function TrainingFile({
    fileName, numberLines, mode, setMode
}) {
    function handleClick(modeToGoto) {
        setMode(modeToGoto);
    }
    return (
        <Grid container>

            <Grid item xs={8}>
                <Typography variant="h5">File: {fileName} &nbsp;
                <Badge badgeContent={numberLines} max={99999} color="info">
                    <FontAwesomeIcon icon={faLineColumns} swapOpacity pull="left" />
                </Badge>
                </Typography>
            </Grid>
            <Grid item xs={4} style={{ justifyContent: 'flex-end' }}>
                {(mode === 'trainingLine' && (
                    <ButtonGroup>
                        <Button variant='outlined' onClick={(e => handleClick(''))} >
                            <IconButton >
                                <Icon>
                                    <FontAwesomeIcon icon={faPeoplePulling} />
                                </Icon>
                            </IconButton>
                            <Typography>Trainer</Typography>
                        </Button>
                        <Button variant='outlined' onClick={(e => handleClick('chat'))} >
                            <IconButton >
                                <Icon>
                                    <FontAwesomeIcon icon={faComments} />
                                </Icon>
                            </IconButton>
                            <Typography>Chat</Typography>
                        </Button>
                        <Button variant='outlined' onClick={(e => handleClick('model'))} >
                            <IconButton >
                                <Icon>
                                    <FontAwesomeIcon icon={faMicrochipAi} />
                                </Icon>
                            </IconButton>
                            <Typography>Models</Typography>
                        </Button>
                    </ButtonGroup>
                ))}
                {(mode === '' && (
                    <ButtonGroup>
                        <Button variant='outlined' onClick={(e => handleClick('trainingLine'))} >
                            <IconButton >
                                <Icon>
                                    <FontAwesomeIcon icon={faDisplayChartUpCircleCurrency} />
                                </Icon>
                            </IconButton>
                            <Typography>Lines</Typography>
                        </Button>
                        <Button variant='outlined' onClick={(e => handleClick('chat'))} >
                            <IconButton >
                                <Icon>
                                    <FontAwesomeIcon icon={faComments} />
                                </Icon>
                            </IconButton>
                            <Typography>Chat</Typography>
                        </Button>
                        <Button variant='outlined' onClick={(e => handleClick('model'))} >
                            <IconButton >
                                <Icon>
                                    <FontAwesomeIcon icon={faMicrochipAi} />
                                </Icon>
                            </IconButton>
                            <Typography>Models</Typography>
                        </Button>
                    </ButtonGroup>
                ))}
                {(mode === 'chat' && (
                    <ButtonGroup>
                        <Button variant='outlined' onClick={(e => handleClick(''))} >
                            <IconButton >
                                <Icon>
                                    <FontAwesomeIcon icon={faPeoplePulling} />
                                </Icon>
                            </IconButton>
                            <Typography>Trainer</Typography>
                        </Button>
                        <Button variant='outlined' onClick={(e => handleClick('trainingLine'))} >

                            <IconButton >
                                <Icon>
                                    <FontAwesomeIcon icon={faDisplayChartUpCircleCurrency} />
                                </Icon>
                            </IconButton>
                            <Typography>Lines</Typography>
                        </Button>
                        <Button variant='outlined' onClick={(e => handleClick('model'))} >
                            <IconButton >
                                <Icon>
                                    <FontAwesomeIcon icon={faMicrochipAi} />
                                </Icon>
                            </IconButton>
                            <Typography>Models</Typography>
                        </Button>
                    </ButtonGroup>
                ))}
                {(mode === 'model' && (
                    <ButtonGroup>
                        <Button variant='outlined' onClick={(e => handleClick(''))} >
                            <IconButton >
                                <Icon>
                                    <FontAwesomeIcon icon={faPeoplePulling} />
                                </Icon>
                            </IconButton>
                            <Typography>Trainer</Typography>
                        </Button>
                        <Button variant='outlined' onClick={(e => handleClick('trainingLine'))} >

                            <IconButton >
                                <Icon>
                                    <FontAwesomeIcon icon={faDisplayChartUpCircleCurrency} />
                                </Icon>
                            </IconButton>
                            <Typography>Lines</Typography>
                        </Button>
                        <Button variant='outlined' onClick={(e => handleClick('chat'))} >
                            <IconButton >
                                <Icon>
                                    <FontAwesomeIcon icon={faComments} />
                                </Icon>
                            </IconButton>
                            <Typography>Chat</Typography>
                        </Button>
                    </ButtonGroup>
                ))}
            </Grid>
        </Grid>
    )
}
export default TrainingFile;