import { faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import JsonView from "react18-json-view";
import JsonToCsvGrid from "../../components/JsonToCsvGrid/JsonToCsvGrid";

function QbTester({ packageResult, runTest }) {
    console.log('packageResult', packageResult);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button onClick={runTest}>Run Test</Button>
            </Grid>
            {packageResult && packageResult.map((item) => {
                console.log('item', item.contentType);
                return (
                    <>
                        {item.contentType === 'JSON' && (
                            <JsonToCsvGrid jsonData={item.content} />
                        )}
                        {item.contentType === 'string' && (
                            <Grid container key={item.messageId}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Payload"
                                        value={item.content}
                                        multiline
                                        rows={8}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </>
                );
            })}
        </Grid>
    );
}

export default QbTester;


// PackageResult