import React from 'react';

export const getFileIcon = (fileName) => {
    if (!fileName) {
        return <img src="/img/TxtIcon.svg" alt="Txt Icon" style={{ fill: '#fff', width: '18px', height: '18px' }} />;
    }
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
        case 'pdf':
            return <img src="/img/PdfIcon.svg" alt="Pdf Icon" style={{ fill: '#ff0000', width: '18px', height: '18px' }} />;
        case 'doc':
        case 'docx':
            return <img src="/img/DocIcon.svg" alt="Doc Icon" style={{ fill: '#00f', width: '18px', height: '18px' }} />;
        case 'xls':
        case 'xlsx':
            return <img src="/img/XlsIcon.svg" alt="Xls Icon" style={{ fill: '#0f0', width: '18px', height: '18px' }} />;
        case 'txt':
            return <img src="/img/TxtIcon.svg" alt="Txt Icon" style={{ fill: '#fff', width: '18px', height: '18px' }} />;
        default:
            return <img src="/img/TxtIcon.svg" alt="Txt Icon" style={{ fill: '#fff', width: '18px', height: '18px' }} />; // Default icon
    }
};
