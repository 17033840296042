import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function ConnectionReady() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/app'); // Navigate to the home page after a delay
    }, 3000); // 3 seconds delay

    return () => clearTimeout(timer); // Cleanup the timer if the component is unmounted
  }, [navigate]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <h2>Connection Ready</h2>
      <p>Ready, let's get started!</p>
    </div>
  );
}

export default ConnectionReady;
