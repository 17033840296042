import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, Divider, FormControl, Grid, Skeleton, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

function TrainingTrainer({
    formulateSystemMessage,
    runTraining,
    setUserMessage,
    systemMessage,
    trainingLine,
    setTrainingLine,
    mode,
    testPromptRunner,
    userMessage }) {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(trainingLine) {
            setLoading(false);
        }
    }, [trainingLine]);

    const isUserMessageEmpty = (message) => {
        return message.trim() === "";
    };

    function computeTraining() {
        setLoading(!loading);
        runTraining();
    }


    return (
        <>
            {mode === 'trainingLine' && (
                <Grid item xs={12} marginRight={5} marginLeft={3}>
                    <Grid container spacing={1} >
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    multiline
                                    value={systemMessage}
                                    onChange={(e) => formulateSystemMessage(e.target.value)}
                                    label="System Message"
                                    rows={4}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} margin={2}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    multiline
                                    value={userMessage}
                                    onChange={(e) => setUserMessage(e.target.value)}
                                    label="User Message"
                                    rows={4}
                                />
                            </FormControl>
                        </Grid>
                        <Grid container justifyContent="center"> {/* Center the content horizontally */}
                            <Grid item xs={12} margin={2}>
                                <FormControl fullWidth>
                                    <ButtonGroup fullWidth margin={4}>
                                    <Button
                                        disabled={isUserMessageEmpty(userMessage)}
                                        onClick={(e) => computeTraining()}
                                    >
                                        Compute
                                    </Button>
                                    <Button
                                        disabled={isUserMessageEmpty(userMessage)}
                                    >
                                        Variation on User Message
                                    </Button>
                                    <Button
                                        disabled={isUserMessageEmpty(userMessage)}
                                        onClick={(e) => testPromptRunner()}
                                    >
                                        Test In Prompt Package Runner
                                    </Button>
                                    </ButtonGroup>
                                </FormControl>
                                <Divider />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {!loading && (
                                <FormControl fullWidth>
                                    <TextField
                                        multiline
                                        value={trainingLine}

                                        label="Training Line"
                                        rows={4}
                                    />
                                </FormControl>
                            )}
                            {loading && (
                                <Grid container justifyContent="center">
                                    <Grid item xs={10}>
                                    <FormControl fullWidth>
                                        <FontAwesomeIcon 
                                            icon={faSpinner} 
                                            spin
                                            size="6x"
                                             />
                                    </FormControl>
                                    </Grid>
                                </Grid>
                                
                            )}
                        </Grid>
                    </Grid>

                </Grid>
            )}
        </>
    );
}

export default TrainingTrainer;