import React, { useEffect, useState } from "react";
import { Typography, List, ListItem, Divider } from "@mui/material";
import SimplePropertyForm from "./SimplePropertyForm";
import ComplexObjectForm from "./ComplexObjectForm";

function Payload({ payload, setPayload }) {
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [editedPayload, setEditedPayload] = useState(payload); // Define payload state

  useEffect(() => {
    setEditedPayload(payload);
  }, [payload])

  const handlePropertyClick = (property) => {
    setSelectedProperty(property);
  };

  function onUpdate(propertyName, data) {
    if (propertyName && editedPayload.hasOwnProperty(propertyName)) {
      // Clone the payload object to avoid mutating the state directly
      const updatedPayload = { ...editedPayload };

      // Update the selected property with the new data
      updatedPayload[propertyName] = data;

      // Update the state with the modified payload
      setEditedPayload(updatedPayload);
      setPayload(updatedPayload);
    }
  }

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1 }}>
        <List>
          {editedPayload &&
            Object.keys(editedPayload).map((property) => (
              <ListItem
                key={property}
                button
                onClick={() => handlePropertyClick(property)}
              >
                {property}
              </ListItem>
            ))}
        </List>
      </div>
      <Divider orientation="vertical" flexItem />
      <div style={{ flex: 2 }}>
        <Typography variant="h5">Edit Property</Typography>
        {selectedProperty && (
          <div>
            {/* Check the type of the selected property and render the appropriate form field */}
            {typeof editedPayload[selectedProperty] === "object" ? (
              <ComplexObjectForm
                property={editedPayload[selectedProperty]}
                propertyName={selectedProperty}
                onUpdate={onUpdate}
              />
            ) : (
              <SimplePropertyForm
                property={editedPayload[selectedProperty]}
                propertyName={selectedProperty}
                onUpdate={onUpdate}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Payload;
