import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import ChartRenderer from '../Charts/ChartRenderer';

function ViewRechart() {
    const lineChartConfig = {
        type: 'line',
        width: 800,
        height: 400,
        xAxisKey: 'date',
        yAxisKey: 'value',
        lines: [{ dataKey: 'value', stroke: '#8884d8' }],
      };
      
      const barChartConfig = {
        type: 'bar',
        width: 800,
        height: 400,
        xAxisKey: 'category',
        yAxisKey: 'value',
        bars: [{ dataKey: 'value', fill: '#8884d8' }],
      };
      
      const pieChartConfig = {
        type: 'pie',
        width: 800,
        height: 400,
        pies: [{ dataKey: 'value', fill: '#8884d8' }],
      };
      
      const areaChartConfig = {
        type: 'area',
        width: 800,
        height: 400,
        xAxisKey: 'date',
        yAxisKey: 'value',
        areas: [{ dataKey: 'value', fill: '#8884d8', stroke: '#82ca9d' }],
      };
      
      const chartData = [
        { date: '2023-09-01', value: 10, category: 'A' },
        { date: '2023-09-02', value: 15, category: 'B' },
        // Add more data points here
      ];
  // Sample data for the line chart\

  const chartDefinition = {
    width: 600,
    height: 400,
    legend: true,
    tooltip: {
        separator: ":",
        offset: 10,
        filterNull: true
    },
    data : [
        { name: 'Jan', uv: 400, pv: 2400, amt: 2400 },
        { name: 'Feb', uv: 300, pv: 1398, amt: 2210 },
        { name: 'Mar', uv: 200, pv: 9800, amt: 2290 },
        { name: 'Apr', uv: 278, pv: 3908, amt: 2000 },
        { name: 'May', uv: 189, pv: 4800, amt: 2181 },
        { name: 'Jun', uv: 239, pv: 3800, amt: 2500 },
      ]
  };

  const renderTooltip = () => {
    if (chartDefinition.tooltip) {
      return (
        <Tooltip
          separator={chartDefinition.tooltip.separator}
          offset={chartDefinition.tooltip.offset}
          filterNull={chartDefinition.tooltip.filterNull}
        />
      );
    }
    return null;
  };

  const renderLegend = () => {
    if (chartDefinition.legend) {
      return <Legend />;
    }
    return null;
  };


  return (
    <div>
      <h1>Charts Example</h1>
      <ChartRenderer config={lineChartConfig} data={chartData} />
      <ChartRenderer config={barChartConfig} data={chartData} />
      <ChartRenderer config={pieChartConfig} data={chartData} />
      <ChartRenderer config={areaChartConfig} data={chartData} />
    </div>

  );
}

export default ViewRechart;



