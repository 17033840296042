import React from 'react';
import {
  LineChart,
  BarChart,
  ComposedChart,
  AreaChart,
  PieChart,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  RadialBarChart,
  ScatterChart,
  FunnelChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  Bar,
  Area,
  Pie,
  Radar,
  RadialBar,
  Scatter,
  Funnel,
} from 'recharts';

const ChartRenderer = ({ config, data }) => {
  const { type, width, height, xAxisKey, yAxisKey, lines, bars, pies, areas, radar, radialBars, scatter, funnel } =
    config;

  const renderChart = () => {
    switch (type) {
      case 'line':
        return (
          <LineChart width={width || 600} height={height || 300} data={data}>
            {xAxisKey && <XAxis dataKey={xAxisKey} />}
            {yAxisKey && <YAxis />}
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            {renderLines()}
          </LineChart>
        );
      case 'bar':
        return (
          <BarChart width={width || 600} height={height || 300} data={data}>
            {xAxisKey && <XAxis dataKey={xAxisKey} />}
            {yAxisKey && <YAxis />}
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            {renderBars()}
          </BarChart>
        );
      case 'composed':
        return (
          <ComposedChart width={width || 600} height={height || 300} data={data}>
            {xAxisKey && <XAxis dataKey={xAxisKey} />}
            {yAxisKey && <YAxis />}
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            {renderLines()}
            {renderBars()}
          </ComposedChart>
        );
      case 'area':
        return (
          <AreaChart width={width || 600} height={height || 300} data={data}>
            {xAxisKey && <XAxis dataKey={xAxisKey} />}
            {yAxisKey && <YAxis />}
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            {renderAreas()}
          </AreaChart>
        );
      case 'pie':
        return (
          <PieChart width={width || 600} height={height || 300}>
            <Pie dataKey={pies[0].dataKey} data={data} fill="#8884d8" label />
          </PieChart>
        );
      case 'radar':
        return (
          <RadarChart width={width || 600} height={height || 300} data={data}>
            <PolarGrid />
            <PolarAngleAxis dataKey="subject" />
            <PolarRadiusAxis />
            {renderRadar()}
          </RadarChart>
        );
      case 'radialBar':
        return (
          <RadialBarChart width={width || 600} height={height || 300} data={data}>
            {renderRadialBars()}
          </RadialBarChart>
        );
      case 'scatter':
        return (
          <ScatterChart width={width || 600} height={height || 300}>
            {xAxisKey && <XAxis dataKey={xAxisKey} type="number" />}
            {yAxisKey && <YAxis dataKey={yAxisKey} type="number" />}
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            {renderScatter()}
          </ScatterChart>
        );
      case 'funnel':
        return (
          <FunnelChart width={width || 600} height={height || 300}>
            {renderFunnel()}
          </FunnelChart>
        );
      default:
        return null;
    }
  };

  const renderLines = () => {
    return lines.map((line, index) => (
      <Line
        key={index}
        type={line.type || 'monotone'}
        dataKey={line.dataKey}
        stroke={line.stroke || '#8884d8'}
        activeDot={{ r: 8 }}
      />
    ));
  };

  const renderBars = () => {
    return bars.map((bar, index) => (
      <Bar key={index} dataKey={bar.dataKey} fill={bar.fill || '#8884d8'} />
    ));
  };

  const renderAreas = () => {
    return areas.map((area, index) => (
      <Area
        key={index}
        type={area.type || 'monotone'}
        dataKey={area.dataKey}
        fill={area.fill || '#8884d8'}
        stroke={area.stroke || undefined}
      />
    ));
  };

  const renderRadar = () => {
    return radar.map((item, index) => (
      <Radar key={index} dataKey={item.dataKey} stroke="#8884d8" fill="#8884d8" />
    ));
  };

  const renderRadialBars = () => {
    return radialBars.map((item, index) => (
      <RadialBar
        key={index}
        dataKey={item.dataKey}
        fill="#8884d8"
        background
        clockWise
      />
    ));
  };

  const renderScatter = () => {
    return scatter.map((item, index) => (
      <Scatter key={index} dataKey={item.dataKey} fill="#8884d8" />
    ));
  };

  const renderFunnel = () => {
    return funnel.map((item, index) => (
      <Funnel key={index} dataKey={item.dataKey} fill="#8884d8" />
    ));
  };

  return <div>{renderChart()}</div>;
};

export default ChartRenderer;
