import React, { useState, useEffect } from 'react';
import {
  Avatar,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Button,
  ButtonGroup,
  Box
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import OperationSelectionModel from '../../OperationSelectionModal/OperationSelectionModal';
import VectorSourceSelect from './VectorSourceSelect';
import { camelCase } from 'lodash';
import OpenAiMetadata from './OpenAiMetadata';
import StoreOperationManager from '../StoreOperationManager';
import { useHubConnections } from '../../../utils/HubConnectionsProvider';
import { useParams } from 'react-router-dom';



function OpenAiFileSource({ selectedSource, setSelectedSource, sourceId }) {
  const { dashboardConnection, hitchDashCommand, isConnectionReady, defaultSnack, successSnack, infoSnack, hitchUser } = useHubConnections();

  const [formData, setFormData] = useState({
    id: uuidv4(),
    accountId: '',
    created: new Date().toISOString(),
    createdBy: null,
    name: 'New Store',
    updated: '',
    updatedBy: '',
    entity: 'RepoSource',
    fileType: 'assistants',
    foreignKeyContainer: '',
    pushToSource: true,
    putFileOperation: 'openAi_file_upload',
    deleteFileOperation: 'openAi_file_delete',
    putMetadataOperation: null,
    deleteMetadataOperation: null,
    pullFromSource: false,
    listOperation: null,
    getOperation: null,
    repositoryId: null,
    serviceId: 'e59d1ba9-5b71-4f5a-9205-9ce82cbf2dfc',
    metadata: { somevalue: 'ok' },
  });
  const { guid } = useParams();
  const [metadataKeys, setMetadataKeys] = useState(Object.keys(formData.metadata));
  const [connectedOperations, setConnectedOperations] = useState({
    put: false,
    delete: false,
    putMetadata: false,
    deleteMetadata: false,
    list: false,
    get: false,
  });
  const [selectedVectorStore, setSelectedVectorStore] = useState('');
  const [openSelector, setOpenSelector] = useState(false);
  const [currentOperation, setCurrentOperation] = useState('');

  useEffect(() => {
    if (guid && formData && formData.repositoryId !== guid) {
      setFormData((prev) => ({ ...prev, repositoryId: guid }));
      if (formData.serviceId != 'e59d1ba9-5b71-4f5a-9205-9ce82cbf2dfc') {
        setFormData((prev) => ({ ...prev, serviceId: 'e59d1ba9-5b71-4f5a-9205-9ce82cbf2dfc' }));
      }
    }
  }, [guid, formData]);

  function setFormData2(data) {
    setFormData(data);
    successSnack('Data refreshed.');
  }

  useEffect(() => {
    if (dashboardConnection && isConnectionReady) {
      dashboardConnection.on("6a5056aa-9769-4c3b-b7e0-a30606a93795", setFormData2);
      dashboardConnection.on("9785b33f-9b38-48ff-906a-5ae38e6b7f14", setFormData2);
      dashboardConnection.on("bdeaf2d3-abb5-44e8-b22a-66f8d24718d4", setFormData2);
      dashboardConnection.on("dcc6f808-0831-4f8d-9bb5-37123791caa6", formDeleted);

      if (selectedSource) {
        setFormData2(selectedSource);
      }
    }

    return () => {
      if (dashboardConnection) {
        dashboardConnection.off("6a5056aa-9769-4c3b-b7e0-a30606a93795", setFormData2);
        dashboardConnection.off("9785b33f-9b38-48ff-906a-5ae38e6b7f14", setFormData2);
        dashboardConnection.off("bdeaf2d3-abb5-44e8-b22a-66f8d24718d4", setFormData2);
        dashboardConnection.off("dcc6f808-0831-4f8d-9bb5-37123791caa6", formDeleted);
      }
    };
  }, [dashboardConnection, isConnectionReady, hitchDashCommand, sourceId]);

  useEffect(() => {
    if(hitchUser) {
      setFormData((prev) => ({ ...prev, accountId: hitchUser.accountId }));
      setFormData((prev) => ({ ...prev, createdBy: hitchUser.userId }));
    }
  }, [hitchUser])

  useEffect(() => {
    if (selectedSource && selectedSource.foreignKeyContainer) {
      setSelectedVectorStore(selectedSource.foreignKeyContainer);
    }
  }, [selectedSource]);

  useEffect(() => {
    setMetadataKeys(Object.keys(formData.metadata));
  }, [formData.metadata]);

  const handleToggleSwitch = (key) => {
    setFormData((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleFileTypeChange = (event) => {
    setFormData((prev) => ({ ...prev, fileType: event.target.value }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleOperationClick = (operation) => {
    setCurrentOperation(operation);
    setOpenSelector(true);
  };

  const handleAddMetadata = () => {
    if (Object.keys(formData.metadata).length < 16) {
      const newKey = `key${Object.keys(formData.metadata).length + 1}`;
      setFormData((prev) => ({
        ...prev,
        metadata: { ...prev.metadata, [newKey]: '' },
      }));
      setMetadataKeys((prev) => [...prev, newKey]);
    }
  };

  const handleMetadataChange = (key, value) => {
    setFormData((prev) => ({
      ...prev,
      metadata: { ...prev.metadata, [key]: value },
    }));
  };

  const handleRemoveMetadata = (key) => {
    const newMetadata = { ...formData.metadata };
    delete newMetadata[key];
    setFormData((prev) => ({ ...prev, metadata: newMetadata }));
    setMetadataKeys((prev) => prev.filter((k) => k !== key));
  };

  const camelCaseOperation = (service, feature, operation) => {
    return `${camelCase(service)}_${camelCase(feature)}_${camelCase(operation)}`;
  };

  function onSelectedOperation(service, feature, operation) {
    const selection = camelCaseOperation(service, feature, operation);
    setFormData((prev) => ({ ...prev, [currentOperation]: selection }));
    setOpenSelector(false);
  }

  function handleDisconnect(operation) {
    setFormData((prev) => ({ ...prev, [operation]: null }));
  }

  const handleVectorStoreChange = (id, name) => {
    setSelectedVectorStore(id);
    setFormData((prev) => ({ ...prev, foreignKeyContainer: id, name: name }));
  };

  const handleSave = () => {
    if (dashboardConnection) {
      defaultSnack('Save Called Here');
      formData.repositoryId = guid;

      console.log('formData', formData);
      if (selectedSource) {
        hitchDashCommand('document', 'repo-source', 'update', { formData: formData }, () => { });
      } else {
        hitchDashCommand('document', 'repo-source', 'create', { formData: formData }, () => { });
      }
    }
  };

  function doTheDelete() {
    defaultSnack('delete call here');
    hitchDashCommand('document', 'repo-source', 'delete', { repositoryId: formData.repositoryId, sourceId: formData.id }, () => { });
  }

  const handleCancel = () => {
    defaultSnack('Cancelled.');
    setSelectedSource(null);
    hitchDashCommand('document', 'repo-source', 'list', { RepositoryId: guid }, () => {});
  };

  function formDeleted(data) {
    // console.log('formDeleted', data);
    if(data) {
      defaultSnack('Source has been deleted.');
      setSelectedSource(null);
      hitchDashCommand('document', 'repo-source', 'list', { RepositoryId: guid }, () => {});
    }
  }

  const handlePushNow = () => {
    // Logic for the "Push Now" button
    defaultSnack('Push Now Called Here');
    hitchDashCommand('document', 'repo-source', 'push', { repositoryId: formData.repositoryId, sourceId: formData.id }, () => { });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} display="flex" alignItems="center">
        <Avatar src={`${window._env_.HITCH_API}/api/accimage/e59d1ba9-5b71-4f5a-9205-9ce82cbf2dfc.png`} />
        <Typography variant="h6" style={{ marginLeft: 8, flexGrow: 1 }}>
          OpenAi
        </Typography>
        <Button variant="contained" color="primary" onClick={handlePushNow} style={{ marginLeft: 'auto' }}>
          Push Now
        </Button>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            label="Id"
            name="id"
            value={formData.id}
            onChange={handleInputChange}
            fullWidth
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            fullWidth
          />
        </FormControl>
        {formData.fileType === 'assistants' && (
          <VectorSourceSelect
            selectedVectorStore={selectedVectorStore}
            setSelectedVectorStore={handleVectorStoreChange}
          />
        )}
        {formData.fileType !== 'assistants' && (
          <FormControl fullWidth>
            <TextField
              label="Foreign Key Container"
              name="foreignKeyContainer"
              value={formData.foreignKeyContainer}
              onChange={handleInputChange}
              fullWidth
            />
          </FormControl>
        )}
        <FormControl>
          <FormLabel>File Type</FormLabel>
          <RadioGroup row value={formData.fileType} onChange={handleFileTypeChange}>
            <FormControlLabel value="batch" control={<Radio />} label="Batch" />
            <FormControlLabel value="assistants" control={<Radio />} label="Assistant/Messages" />
            <FormControlLabel value="fine-tune" control={<Radio />} label="Fine Tuning" />
            <FormControlLabel value="vision" control={<Radio />} label="Vision" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <StoreOperationManager
          connectedOperations={connectedOperations}
          formData={formData}
          handleOperationClick={handleOperationClick}
          handleToggleSwitch={handleToggleSwitch}
          handleDisconnect={handleDisconnect}
        />
      </Grid>
      <OpenAiMetadata
        formData={formData}
        metadataKeys={metadataKeys}
        handleAddMetadata={handleAddMetadata}
        handleMetadataChange={handleMetadataChange}
        handleRemoveMetadata={handleRemoveMetadata}
        setFormData={setFormData}
      />
      <OperationSelectionModel open={openSelector} setOpen={setOpenSelector} selected={onSelectedOperation} />
      <Grid item xs={12} display="flex" justifyContent="flex-end" spacing={2}>
        <ButtonGroup>
          <Button variant="contained" color="primary" onClick={handleSave} style={{ marginRight: 8 }}>
            Save
          </Button>
          <Button variant="contained" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          {selectedSource && (
            <Button variant="contained" color="secondary" onClick={doTheDelete}>
              Delete
            </Button>
          )}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}

export default OpenAiFileSource;
