import React from 'react';
import { Box } from '@mui/material';
import HitchStyledDataGrid from '../components/hitchgrid/hitchStyledDataGrid';

const AdminSearchGridV2 = ({ columns, list, handleRowClick, rowSelectionModel }) => {
    return (
        <Box sx={{ height: 400, width: '100%', backgroundColor: '#fff' }}>
            <HitchStyledDataGrid
                rows={list}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                onRowClick={(params) => {
                    console.log('Row clicked:', params);
                    handleRowClick(params);
                }}
                getRowId={(row) => row.id} // Ensure this line is added to properly map the row IDs
            />
        </Box>
    );
};

export default AdminSearchGridV2;
