import React from 'react';

const DefaultContent = () => {
    return (
        <div style={{ width: '100%' }}>
            <h2>Welcome to Virtual Sales</h2>
            <p>Ask me anything about our products, get detailed information, or request a demo!</p>
        </div>
    );
}

export default DefaultContent;
