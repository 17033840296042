import React from 'react';
import { Grid, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import {ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import BotOrgChartStyles from './BotOrgChartStyles';


function BotMetadata({  
    bot,
    expandedSection,
    setExpandedSection
}) {

    const {
        themePrimaryTextColor,
        themeSecondaryBackgroundColor,
        themeSecondaryTextColor
    } = BotOrgChartStyles();

    return (
        <Accordion id="section6" elevation={0} expanded={expandedSection.section6} onChange={() => setExpandedSection({ ...expandedSection, section6: !expandedSection.section6 })}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />} sx={{ backgroundColor: themeSecondaryBackgroundColor }}>
                <Typography variant="h6" sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Metadata</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: themeSecondaryBackgroundColor }}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>ID:</Typography>
                            <Typography sx={{ color: themeSecondaryTextColor, fontFamily: '"Poppins", sans-serif', fontWeight: '300' }}>{bot.id}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Created By:</Typography>
                            <Typography sx={{ color: themeSecondaryTextColor, fontFamily: '"Poppins", sans-serif', fontWeight: '300' }}>{bot.createdBy}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Created:</Typography>
                            <Typography sx={{ color: themeSecondaryTextColor, fontFamily: '"Poppins", sans-serif', fontWeight: '300' }}>{bot.created}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Updated By:</Typography>
                            <Typography sx={{ color: themeSecondaryTextColor, fontFamily: '"Poppins", sans-serif', fontWeight: '300' }}>{bot.updatedBy}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Updated:</Typography>
                            <Typography sx={{ color: themeSecondaryTextColor, fontFamily: '"Poppins", sans-serif', fontWeight: '300' }}>{bot.updated}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export default BotMetadata;