import React, { useState, useEffect, useRef } from 'react';
import { DialogActions, Button, TextField, MenuItem } from '@mui/material';
import employeesData from '../hitchy/DomainFiles/numberOfEmployees.json';
import industriesData from '../hitchy/DomainFiles/industries.json';
import annualRevenueData from '../hitchy/DomainFiles/annualRevenue.json';
import countriesData from '../hitchy/DomainFiles/countries.json';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import './RegistrationWizard.css';
import { useNavigate } from 'react-router-dom';
import CancelPrompt from './CancelPrompt'; // Adjust the path as per your directory structure
import Autocomplete from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
const darkTheme = createTheme({ palette: { mode: 'dark' } });


function AddOrganization({ organizationName, setOrganizationName,
  organizationUrl, setOrganizationUrl,
  employees, setEmployees,
  industry, setIndustry,
  annualRevenue, setAnnualRevenue,
  country, setCountry,
  onBack, onNext,
  isFirstStep,
}) {

  const [inputIndustry, setInputIndustry] = useState('');
  const [inputEmployees, setInputEmployees] = useState('');
  const [inputAnnualRevenue, setInputAnnualRevenue] = useState('');
  const [inputCountries, setInputCountries] = useState('');
  const industryRef = useRef(null);
  const [showCancelPrompt, setShowCancelPrompt] = useState(false);
  const navigate = useNavigate();
  const organizationNameRef = useRef(null);
  const [isOrganizationNameActive, setisOrganizationNameActive] = useState(true); // Add this state variable
  const organizationUrlRef = useRef(null);
  const [isOrganizationUrlActive, setisOrganizationUrlActive] = useState(true); // Add this state variable


  const [isIndustryLabelShrunk, setIsIndustryLabelShrunk] = useState(false);
  const [isAnnualRevenueLabelShrunk, setIsAnnualRevenueLabelShrunk] = useState(false);
  const [isEmployeesLabelShrunk, setIsEmployeesLabelShrunk] = useState(false);
  const [isCountryLabelShrunk, setIsCountryLabelShrunk] = useState(false);


  const [organizationNameOutlineStyle, setOrganizationNameOutlineStyle] = useState({
    borderColor: 'white', // Default white outline color
  });

  const [organizationNameOutlineStyleActive, setOrganizationNameOutlineStyleActive] = useState({
    borderColor: '#00EDED', // Default white outline color
  });

  const [organizationNameError, setOrganizationNameError] = useState('');


  const [organizationUrlOutlineStyle, setOrganizationUrlOutlineStyle] = useState({
    borderColor: 'white', // Default white outline color
  });

  const [organizationUrlOutlineStyleActive, setOrganizationUrlOutlineStyleActive] = useState({
    borderColor: '#00EDED', // Default white outline color
  });

  const [organizationUrlError, setOrganizationUrlError] = useState('');



  const [industryOutlineStyle, setIndustryOutlineStyle] = useState({
    borderColor: 'white', // Default white outline color
  });

  const [industryOutlineStyleActive, setIndustryOutlineStyleActive] = useState({
    borderColor: '#00EDED', // Default white outline color
  });

  const [industryError, setIndustryError] = useState('');


  const [annualRevenueOutlineStyle, setAnnualRevenueOutlineStyle] = useState({
    borderColor: 'white', // Default white outline color
  });

  const [annualRevenueOutlineStyleActive, setAnnualRevenueOutlineStyleActive] = useState({
    borderColor: '#00EDED', // Default white outline color
  });

  const [annualRevenueError, setAnnualRevenueError] = useState('');


  const [employeesOutlineStyle, setEmployeesOutlineStyle] = useState({
    borderColor: 'white', // Default white outline color
  });

  const [employeesOutlineStyleActive, setEmployeesOutlineStyleActive] = useState({
    borderColor: '#00EDED', // Default white outline color
  });

  const [employeesError, setEmployeesError] = useState('');

  const [countryOutlineStyle, setCountryOutlineStyle] = useState({
    borderColor: 'white', // Default white outline color
  });

  const [countryOutlineStyleActive, setCountryOutlineStyleActive] = useState({
    borderColor: '#00EDED', // Default white outline color
  });

  const [countryError, setCountryError] = useState('');

  // Define the base styles for the buttons
  const baseButtonStyle = {
    borderRadius: '24px',
    height: '36px',
    padding: '4px 10px',
    fontSize: '0.75rem',
    fontFamily: 'Poppins, sans-serif',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    width: '144px',
    marginTop: '36px',
    marginBottom: '-8px',
  };

  // Define styles for the primary button
  const primaryButtonStyle = {
    ...baseButtonStyle,
    backgroundColor: '#00EDED',
    color: 'black',
    cursor: 'pointer'
  };

  // Define styles for the secondary button
  const secondaryButtonStyle = {
    ...baseButtonStyle,
    backgroundColor: '#000',
    borderColor: 'white',
    color: 'white',
  };

  // Container style for centering
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Align items in the center horizontally
    justifyContent: 'center', // Align items in the center vertically
    textAlign: 'center',
    marginTop: '0px',
    //border: '3px solid cyan'
  };

  const subtitleContainerStyle = {
    textAlign: 'center',
    width: '100%',
    display: 'block',
    marginBottom: '16px',
  };

  const subtitleStyle = {
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins, sans-serif',
    marginBottom: '16px',
    color: 'white',
    marginLeft: '0px',
  };

  // Adjusted InputProps style
  const inputPropsStyle = {
    color: 'white',
    fontSize: '14px',
    width: '300px',
    fontFamily: 'Poppins, sans-serif',
  };

  // Flex container style
  const flexContainerStyle = {
    display: 'flex',
    gap: '16px',
    alignItems: 'flex-start', // Align items at their top edge
    marginBottom: '-36px',
    width: '300px',
  };


  const textFieldContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100px', // Adjust this based on your TextField + error message height
    justifyContent: 'space-between',
  };

  // Style for helper text
  const helperTextStyle = {
    fontSize: '0.50rem', // Adjust the font size as needed
    lineHeight: 'normal', // Adjust the line height as needed
    fontFamily: 'Poppins, sans-serif',
  };

  const dropDownStyles = {
    textField: {
      ".MuiList-root": {
        border: "1px solid #18181B",
        borderRadius: "0%",
        backgroundColor: "#0D0D0D",
        padding: "0%",
        margin: "0%",
      },
    },
  };

  useEffect(() => {
    if (isOrganizationNameActive) {
      organizationNameRef.current?.focus();
    }
  }, [isOrganizationNameActive]);

  const handleOrganizationNameChange = (e) => {
    const value = e.target.value;
    setOrganizationName(value);

    if (value.trim() === '') {
      setOrganizationNameError('Organization Name is required.'); // Show error when it's empty
      setOrganizationNameOutlineStyle({ borderColor: 'red' });
      setOrganizationNameOutlineStyleActive({ borderColor: 'red' });
    } else {
      setOrganizationNameError(''); // Clear the error when it's not empty
      setOrganizationNameOutlineStyle({ borderColor: 'white' });
      setOrganizationNameOutlineStyleActive({ borderColor: '#00EDED' });
    }
  };

  const handleOrganizationUrlChange = (e) => {
    setOrganizationUrl(e.target.value);

  };

  const handleOrganizationUrlBlur = () => {
    let value = organizationUrl.trim();
    // Check if the value already starts with 'http://' or 'https://'
    if (!value.startsWith('http://') && !value.startsWith('https://')) {
      // Only prepend 'https://' if it's not already there
      value = `https://${value}`;
      setOrganizationUrl(value);
    }
  };

  const handleIndustryChange = (newValue) => {
    const industryValue = newValue ? newValue.value.trim() : '';
    setIndustry(industryValue);

    if (newValue.value.trim() === '') {
      // If input is cleared, set the border to red and show error message
      setIndustryError('Phone Type is required.');
      setIndustryOutlineStyle({ borderColor: 'red' });
      setIndustryOutlineStyleActive({ borderColor: 'red' });
    } else {
      // If there is input, keep the border white and remove error message
      console.log("Not Trimmed Industry set to:", newValue.value); // For debugging
      setIndustryError('');
      setIndustryOutlineStyle({ borderColor: 'white' });
      setIndustryOutlineStyleActive({ borderColor: '#00EDED' });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Tab' && industryRef.current) {
      const activeOption = industriesData.find(
        (option) => option.label === industryRef.current.textContent
      );
      if (activeOption) {
        setIndustry(activeOption.value);
      }
    }
  };


  const handleAnnualRevenueChange = (e) => {
    const value = e.target.value;
    setAnnualRevenue(value);
    setIsAnnualRevenueLabelShrunk(true); // Shrink label when a selection is made

    if (value.trim() === '') {
      // If input is cleared, set the border to red and show error message
      setAnnualRevenueError('Phone Type is required.');
      setAnnualRevenueOutlineStyle({ borderColor: 'red' });
      setAnnualRevenueOutlineStyleActive({ borderColor: 'red' });
    } else {
      // If there is input, keep the border white and remove error message
      setAnnualRevenueError('');
      setAnnualRevenueOutlineStyle({ borderColor: 'white' });
      setAnnualRevenueOutlineStyleActive({ borderColor: '#00EDED' });
    }
  };

  //USED 20231125
  const handleEmployeesChange = (e) => {
    const value = e.target.value;
    setEmployees(value);
    setIsEmployeesLabelShrunk(true); // Shrink label when a selection is made

    if (value.trim() === '') {
      // If input is cleared, set the border to red and show error message
      setEmployeesError('Phone Type is required.');
      setEmployeesOutlineStyle({ borderColor: 'red' });
      setEmployeesOutlineStyleActive({ borderColor: 'red' });
    } else {
      // If there is input, keep the border white and remove error message
      setEmployeesError('');
      setEmployeesOutlineStyle({ borderColor: 'white' });
      setEmployeesOutlineStyleActive({ borderColor: '#00EDED' });
    }
  };

  //USED 20231125
  const handleCountryChange = (e) => {
    const value = e.target.value;
    setCountry(value);
    setIsCountryLabelShrunk(true); // Shrink label when a selection is made

    if (value.trim() === '') {
      // If input is cleared, set the border to red and show error message
      setCountryError('Country is required.');
      setCountryOutlineStyle({ borderColor: 'red' });
      setCountryOutlineStyleActive({ borderColor: 'red' });
    } else {
      // If there is input, keep the border white and remove error message
      setCountryError('');
      setCountryOutlineStyle({ borderColor: 'white' });
      setCountryOutlineStyleActive({ borderColor: '#00EDED' });
    }
  };

  const handleCancel = () => {
    setShowCancelPrompt(true); // Show the cancel prompt
  };

  const handleCloseCancelPrompt = () => {
    setShowCancelPrompt(false); // Hide the prompt without doing anything
  };

  const handleConfirmCancel = () => {
    setShowCancelPrompt(false); // Hide the prompt and perform cancellation actions
    // Navigate to the desired URL or perform other actions
    window.location.href = '/';
  };


  const handleNextClick = () => {
    let hasError = false;

    // Validate organization name
    if (!organizationName.trim()) {
      setOrganizationNameError('Organization Name is required.');
      setOrganizationNameOutlineStyle({ borderColor: 'red' });
      setOrganizationNameOutlineStyleActive({ borderColor: 'red' });
      hasError = true;
    } else {
      setOrganizationNameError('');
      setOrganizationNameOutlineStyle({ borderColor: 'white' });
      setOrganizationNameOutlineStyleActive({ borderColor: '#00EDED' });
    }

    // Validate first name
    //if (!organizationUrl.trim()) {
    //  setOrganizationUrlError('Organization Url is required.');
    //  setOrganizationUrlOutlineStyle({ borderColor: 'red' });
    //  setOrganizationUrlOutlineStyleActive({ borderColor: 'red' });
    //  hasError = true;
    // } else {
    //  setOrganizationUrlError('');
    //   setOrganizationUrlOutlineStyle({ borderColor: 'white' });
    //   setOrganizationUrlOutlineStyleActive({ borderColor: '#00EDED' });
    // }


    if (!industry || industry.trim() === '') {
      setIndustryError('Annual Revenue is required.');
      setIndustryOutlineStyle({ borderColor: 'red' });
      setIndustryOutlineStyleActive({ borderColor: 'red' });
      hasError = true;
    } else {
      setIndustryError('');
      setIndustryOutlineStyle({ borderColor: 'white' });
      setIndustryOutlineStyleActive({ borderColor: '#00EDED' });
    }


    if (!annualRevenue || annualRevenue.trim() === '') {
      setAnnualRevenueError('Annual Revenue is required.');
      setAnnualRevenueOutlineStyle({ borderColor: 'red' });
      setAnnualRevenueOutlineStyleActive({ borderColor: 'red' });
      hasError = true;
    } else {
      setAnnualRevenueError('');
      setAnnualRevenueOutlineStyle({ borderColor: 'white' });
      setAnnualRevenueOutlineStyleActive({ borderColor: '#00EDED' });
    }


    if (!employees || employees.trim() === '') {
      setEmployeesError('Employee is required.');
      setEmployeesOutlineStyle({ borderColor: 'red' });
      setEmployeesOutlineStyleActive({ borderColor: 'red' });
      hasError = true;
    } else {
      setEmployeesError('');
      setEmployeesOutlineStyle({ borderColor: 'white' });
      setEmployeesOutlineStyleActive({ borderColor: '#00EDED' });
    }

    if (!country || country.trim() === '') {
      setCountryError('Country is required.');
      setCountryOutlineStyle({ borderColor: 'red' });
      setCountryOutlineStyleActive({ borderColor: 'red' });
      hasError = true;
    } else {
      setCountryError('');
      setCountryOutlineStyle({ borderColor: 'white' });
      setCountryOutlineStyleActive({ borderColor: '#00EDED' });
    }

    // Only proceed if there are no errors
    if (!hasError) {
      onNext({ organizationName, organizationUrl, employees, industry, annualRevenue, country });
    }
  };


  const handleBackClick = () => {
    if (isFirstStep) {
      // Handle the case when it's the first step (e.g., go back to the dashboard)
      // You can modify this behavior based on your application logic
      window.location.href = '/dashboard'; // Change this to the desired URL
    } else {
      // Go back to the previous step
      onBack();
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div style={containerStyle}>
        <div style={subtitleContainerStyle}>
          <p style={subtitleStyle}>We need some information related to your organization.</p>
        </div>
        <div>
          <TextField
            ref={organizationNameRef}
            autoFocus={isOrganizationNameActive}
            name="organizationName"
            label="Organization Name"
            variant="outlined"
            fullWidth
            value={organizationName}
            onChange={handleOrganizationNameChange}
            style={{ marginTop: '8px' }}
            InputLabelProps={{
              style: {
                color: 'rgba(255, 255, 255, 0.7)',
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                fontFamily: 'Poppins, sans-serif',
              },
            }}
            InputProps={{
              style: {
                color: 'white',
                fontSize: '16px',
                width: '300px',
                fontFamily: 'Poppins, sans-serif',
              },
              sx: {
                background: 'black',
                height: '40px',
                color: 'black',
                '& .MuiOutlinedInput-notchedOutline': {
                  ...organizationNameOutlineStyle,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  ...organizationNameOutlineStyleActive,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  ...organizationNameOutlineStyleActive
                },
              },
            }}
            sx={{
              marginBottom: '0px',
            }}
            required
            error={!!organizationNameError}
            helperText={organizationNameError || ' '}
            FormHelperTextProps={{ style: helperTextStyle }}
            autoComplete="off"
          />
        </div>
        <div>
          <TextField
            ref={organizationUrlRef}
            name="organizationUrl"
            label="Organization Website"
            variant="outlined"
            fullWidth
            value={organizationUrl}
            onChange={handleOrganizationUrlChange}
            onBlur={handleOrganizationUrlBlur} // Handle on blur to prepend https
            placeholder="example.com" // Placeholder text
            style={{ marginTop: '8px' }}
            InputLabelProps={{
              style: {
                color: 'rgba(255, 255, 255, 0.7)',
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                fontFamily: 'Poppins, sans-serif',
              },
            }}
            InputProps={{
              style: {
                color: 'white',
                fontSize: '16px',
                width: '300px',
                fontFamily: 'Poppins, sans-serif',
              },
              sx: {

                background: 'black',
                height: '40px',
                color: 'black',
                fontFamily: 'Poppins, sans-serif',
                '& .MuiOutlinedInput-notchedOutline': {
                  ...organizationUrlOutlineStyle,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  ...organizationUrlOutlineStyleActive,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  ...organizationUrlOutlineStyleActive
                },
              },
            }}
            sx={{
              marginBottom: '8px',
            }}
            error={!!organizationUrlError}
            helperText={organizationUrlError || ' '}
            FormHelperTextProps={{ style: helperTextStyle }}
            autoComplete="off"
          />
        </div>

        <div>
          <Autocomplete
            value={employeesData.find(ind => ind.value === employees) || null}
            onChange={(event, newValue) => {
              setEmployees(newValue ? newValue.value : '');
            }}
            inputValue={inputEmployees}
            onInputChange={(event, newInputValue) => {
              setInputEmployees(newInputValue);
            }}
            id="employees-autocomplete"
            options={employeesData}
            getOptionLabel={(option) => option ? option.label : ''}
            style={{ width: '100%', marginBottom: '8px' }}
            autoSelect
            autoHighlight
            renderInput={(params) => (
              <TextField
                {...params}
                label="# of Employees"
                variant="outlined"
                error={!!employeesError}
                helperText={employeesError || ' '}
                InputLabelProps={{
                  style: {
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '16px',
                    fontFamily: 'Poppins, sans-serif',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: inputPropsStyle,
                  sx: {
                    background: 'black',
                    height: '40px',
                    color: 'white',
                    fontFamily: 'Poppins, sans-serif',
                    '& .MuiOutlinedInput-notchedOutline': {
                      ...employeesOutlineStyle,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      ...employeesOutlineStyleActive,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      ...employeesOutlineStyleActive
                    },
                    '.MuiSvgIcon-root ': {
                      fill: "rgba(255, 255, 255, 0.7) !important",
                    }
                  },
                }}
                required
                FormHelperTextProps={{ style: helperTextStyle }}
                autoComplete="off"
              />
            )}
          />
        </div>
        <div>
          <Autocomplete
            value={industriesData.find(ind => ind.value === industry) || null}
            onChange={(event, newValue) => {
              setIndustry(newValue ? newValue.value : '');
            }}
            inputValue={inputIndustry}
            onInputChange={(event, newInputValue) => {
              setInputIndustry(newInputValue);
            }}
            id="industry-autocomplete"
            options={industriesData}
            getOptionLabel={(option) => option ? option.label : ''}
            style={{ width: '100%', marginBottom: '8px' }}
            autoSelect
            autoHighlight
            renderInput={(params) => (
              <TextField
                {...params}
                label="Industry"
                variant="outlined"
                error={!!industryError}
                helperText={industryError || ' '}
                InputLabelProps={{
                  style: {
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '16px',
                    fontFamily: 'Poppins, sans-serif',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: inputPropsStyle,
                  sx: {
                    background: 'black',
                    height: '40px',
                    color: 'white',
                    fontFamily: 'Poppins, sans-serif',
                    '& .MuiOutlinedInput-notchedOutline': {
                      ...industryOutlineStyle,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      ...industryOutlineStyleActive,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      ...industryOutlineStyleActive
                    },
                    '.MuiSvgIcon-root ': {
                      fill: "rgba(255, 255, 255, 0.7) !important",
                    }
                  },
                }}
                required
                FormHelperTextProps={{ style: helperTextStyle }}
                autoComplete="off"
              />
            )}
          />
        </div>
        <div>
          <Autocomplete
            value={annualRevenueData.find(ind => ind.value === annualRevenue) || null}
            onChange={(event, newValue) => {
              setAnnualRevenue(newValue ? newValue.value : '');
            }}
            inputValue={inputAnnualRevenue}
            onInputChange={(event, newInputValue) => {
              setInputAnnualRevenue(newInputValue);
            }}
            id="annualRevenue-autocomplete"
            options={annualRevenueData}
            getOptionLabel={(option) => option ? option.label : ''}
            style={{ width: '100%', marginBottom: '8px',
            fontFamily: 'Poppins, sans-serif', }}
            autoSelect
            autoHighlight
            renderInput={(params) => (
              <TextField
                {...params}
                label="Annual Revenue"
                variant="outlined"
                error={!!annualRevenueError}
                helperText={annualRevenueError || ' '}
                InputLabelProps={{
                  style: {
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '16px',
                    fontFamily: 'Poppins, sans-serif',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: inputPropsStyle,
                  sx: {
                    background: 'black',
                    height: '40px',
                    color: 'white',
                    fontFamily: 'Poppins, sans-serif',
                    '& .MuiOutlinedInput-notchedOutline': {
                      ...annualRevenueOutlineStyle,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      ...annualRevenueOutlineStyleActive,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      ...annualRevenueOutlineStyleActive
                    },
                    '.MuiSvgIcon-root ': {
                      fill: "rgba(255, 255, 255, 0.7) !important",
                    }
                  },
                }}
                required
                FormHelperTextProps={{ style: helperTextStyle }}
                autoComplete="off"
              />
            )}
          />
        </div>
        <div>
          <Autocomplete
            value={countriesData.find(ind => ind.value === country) || null}
            onChange={(event, newValue) => {
              setCountry(newValue ? newValue.value : '');
            }}
            inputValue={inputCountries}
            onInputChange={(event, newInputValue) => {
              setInputCountries(newInputValue);
            }}
            id="countries-autocomplete"
            options={countriesData}
            getOptionLabel={(option) => option ? option.label : ''}
            style={{ width: '100%', marginBottom: '8px' }}
            autoSelect
            autoHighlight
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                variant="outlined"
                error={!!countryError}
                helperText={countryError || ' '}
                InputLabelProps={{
                  style: {
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '16px',
                    fontFamily: 'Poppins, sans-serif',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: inputPropsStyle,
                  sx: {
                    background: 'black',
                    height: '40px',
                    color: 'white',
                    fontFamily: 'Poppins, sans-serif',
                    '& .MuiOutlinedInput-notchedOutline': {
                      ...countryOutlineStyle,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      ...countryOutlineStyleActive,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      ...countryOutlineStyleActive
                    },
                    '.MuiSvgIcon-root ': {
                      fill: "rgba(255, 255, 255, 0.7) !important",
                    }
                  },
                }}
                required
                FormHelperTextProps={{ style: helperTextStyle }}
                autoComplete="off"
              />
            )}
          />
        </div>
        <DialogActions style={{ marginTop: '-16px', justifyContent: isFirstStep ? 'center' : 'space-between' }}>
          {!isFirstStep && (
            <Button variant="outlined" onClick={handleBackClick} style={secondaryButtonStyle}>
              <FontAwesomeIcon icon={faArrowLeft} fontSize="smaller" /> Back
            </Button>
          )}
          <Button variant="outlined" onClick={handleNextClick} style={isFirstStep ? { ...primaryButtonStyle, marginTop: '24px' } : primaryButtonStyle}>
            Next <FontAwesomeIcon icon={faArrowRight} fontSize="smaller" />
          </Button>
        </DialogActions>
        <CancelPrompt
          open={showCancelPrompt}
          onClose={handleCloseCancelPrompt}
          onConfirm={handleConfirmCancel}
        />
      </div>
    </ThemeProvider>
  );
}

export default AddOrganization;
