import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { useState } from "react";
import OpenAiFileSource from "../OpenAi/OpenAiFileSource";
import GitHubFileSource from "../Git/GitHubFileSource";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackspace } from "@fortawesome/pro-solid-svg-icons";

const menuData = [
    {
        'id': 'e59d1ba9-5b71-4f5a-9205-9ce82cbf2dfc',
        'primary': 'Open Ai',
        'secondary': '(Vector, Assistant, Fine-Tune, Image)'
    },
    {
        'id': 'c5e92791-b9e7-4d7e-ae22-90cce4ec0b43',
        'primary': 'GitHub',
        'secondary': '(Version Control)'
    }
];

function SelectSource({ setSelectedSource }) {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6">Sweet.  Let's connect ourselves a file store!</Typography>
            </Grid>
            <Grid item xs={12}>
                <List>
                    {menuData.map((menuItem, index) => {
                        return (
                            <ListItem key={index} onClick={(e) => setSelectedSource(menuItem.id)}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Avatar src={`${window._env_.HITCH_API}/api/accimage/${menuItem.id}.png`} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={menuItem.primary}
                                    secondary={menuItem.secondary}>
                                </ListItemText>
                            </ListItem>
                        );
                    })}
                </List>
            </Grid>

        </Grid>
    );
}

function NewSource() {
    const [selectedSource, setSelectedSource] = useState(null);

    return (
        <Box>
            {!selectedSource && (<SelectSource setSelectedSource={setSelectedSource} />)}
            {selectedSource && selectedSource === 'e59d1ba9-5b71-4f5a-9205-9ce82cbf2dfc' && <OpenAiFileSource setSelectedSource={setSelectedSource} />}
            {selectedSource && selectedSource === 'c5e92791-b9e7-4d7e-ae22-90cce4ec0b43' && <GitHubFileSource setSelectedSource={setSelectedSource} />}
            {selectedSource && (<div onClick={(e) => setSelectedSource(null)}><Typography variant="h6"><FontAwesomeIcon icon={faBackspace} /> Pick a different source.</Typography></div>)}
        </Box>
    );
}

export default NewSource;