import { Box, TextField } from "@mui/material";
import HitchBox from "../../components/hitchbox/HitchBox";

function QuickSearchToolbar(props) {
    return (
        <HitchBox backgroundColor='#777'
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <Box component="span" sx={{ mr: 1 }}>🔍</Box>,
                }}
                sx={{
                    width: '100%',
                }}
            />
        </HitchBox>
    );
}

export default QuickSearchToolbar;