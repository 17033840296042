import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import {
  faMagnifyingGlass,
  faPuzzlePiece
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AdminSearchHeader({
  editingModel,
  filter,
  hubConnection,
  searchTerm,
  sessionId,
  setSearchTerm,
  title,
  makeANewOne
}) {
  // const makeANewOne = () => {
  //   if (hubConnection && title) {
  //     hubConnection
  //       .invoke(`New${title}`, sessionId)
  //       .then(() => {})
  //       .catch(error => console.error("Error sending message:", error));
  //   } else {
  //     // TODO: ERROR HERE BECAUSE SIGNAL R IS MISSING
  //   }
  // };

  return (
    <>
      {/* {!editingModel && ( */}
        <Grid container sx={{ paddingTop: "10px" }}>
          <Grid item xs={12}>
            <Typography variant="h4">{`${title}s`}</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              backgroundColor: "#FFFFFF",
              paddingTop: "10px",
              paddingBottom: "10px",
              color: "#333333"
            }}
          >
            <TextField
              fullWidth
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              label="Search Term"
            />
          </Grid>

          <Grid
            item
            xs={4}
            sx={{
              backgroundColor: "#FFFFFF",
              paddingBottom: "10px",
              color: "#000000"
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%" // Ensure the Box takes the full height of the grid item
              }}
            >
              <ButtonGroup fullWidth sx={{ marginRight: "5px" }}>
                <Button onClick={e => filter()}>
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                  &nbsp;&nbsp;Filter
                </Button>
                <Button variant="contained" onClick={e => makeANewOne()}>
                  <FontAwesomeIcon icon={faPuzzlePiece} />
                  &nbsp;&nbsp;Add {title}
                </Button>
              </ButtonGroup>
            </Box>
          </Grid>
        </Grid>
      {/* )} */}
    </>
  );
}

export default AdminSearchHeader;