import React from 'react';
import { Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

function SelectLastTenModal({ open, handleClose, lastTen, onSelect }) {
  console.log('lastTen', lastTen);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        <Typography variant="h6" component="h2">
          Select an Event
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="right">Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lastTen.map((row) => (
                <TableRow
                  key={row.step_id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => onSelect(row)}
                  hover
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell component="th" scope="row">
                    {row.step_id}
                  </TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
}

export default SelectLastTenModal;
