// Admin.js
import React, { useEffect, useState } from 'react';
import userManager from '../services/authService';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography
} from '@mui/material';
import { Route, Routes, useNavigate } from 'react-router-dom';
import * as signalR from '@microsoft/signalr';
import { v4 as uuidv4 } from 'uuid';
import Bump from '../hitchy/Bump/Bump';
import Users from './User/Users';
import AdminDrawer from './AdminDrawer';
import Cluster from './Cluster';
import Services from './Service/Services';
import Features from './Features';
import Roles from './Roles';
import Connections from './Connections';
import Organizations from './Organization/Organizations';
import UserRoles from './User/UserRoles';
import Training from './Training/Training';
import ServiceStatus from './ServiceStatus/ServiceStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHammer } from '@fortawesome/pro-duotone-svg-icons';
import Debug from './Debug/Debug';
import Offer from './Offer/Offer';
import OfferDetail from './Offer/OfferDetail';
import { HubAdminConnectionsProvider, useAdminHubConnections } from '../utils/HubAdminConnectionProvider';
import User from './User';
import Node from './Node';
import Actor from './Actor';
import Plan from './Plan/Plan';

function Admin() {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [started, setStarted] = useState(false);
  const [hitchUser, setHitchUser] = useState(null);
  const [sessionId, setSessionId] = useState(uuidv4());
  const [jwtToken, setJwtToken] = useState(null);
  const [hubConnection, setHubConnection] = useState(null);
  const [start, setStart] = useState(false);
  const [userAvatar, setUserAvatar] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState('cluster');
  const [activeOrganization, setActiveOrganization] = useState(null);
  const [activeService, setActiveService] = useState(null);
  const [activeUser, setActiveUser] = useState(null);
  const [state, setState] = useState(false);



  useEffect(() => {
    async function checkAuthentication() {
      try {
        const authenticated = await userManager.getUser();
        if (authenticated.expires_in < 0) {
          const currentAddress = `${window.location.pathname}${window.location.search}`;
          window.location.href = `/login?redirect=${encodeURIComponent(currentAddress)}`;
        }

        setUser(authenticated);
        setIsAuthenticated(authenticated != null);
        if (authenticated) {
          setJwtToken(authenticated.id_token);
          setStart(true);
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        const currentAddress = `${window.location.pathname}${window.location.search}`;
        window.location.href = `/login?redirect=${encodeURIComponent(currentAddress)}`;
      }
    }
    checkAuthentication();
  }, []);

  useEffect(() => {
    if (user && user.id_token) {
      // console.log('user changed', user);
    }
  }, [user]);



  useEffect(() => {
    if (hitchUser) {
      setUserAvatar(`${window._env_.HITCH_API}/api/accimage/${hitchUser.id}.png`);
    }
  }, [hitchUser]);

  // useEffect(() => {
  //   let isMounted = true;

  //   const startConnection = async () => {
  //     if (jwtToken && start) {
  //       const connection = new signalR.HubConnectionBuilder()
  //         .withUrl(`${window._env_.HITCH_API}/hitchAdminHubX?sessionId=${sessionId}`, {
  //           accessTokenFactory: () => jwtToken
  //         })
  //         .withAutomaticReconnect()
  //         .build();

  //       try {
  //         await connection.start();

  //         if (isMounted) {
  //           setStart(false);
  //           setHubConnection(connection);
  //         }
  //       } catch (error) {
  //         console.error('Dashboard Hub connection error:', error);
  //       }
  //     }
  //   };

  //   startConnection();

  //   return () => {
  //     isMounted = false;
  //     if (hubConnection) {
  //       hubConnection.stop();
  //     }
  //   };
  // }, [jwtToken, start]);

  const handleWhomAmI = (whoAmIResponse) => {
    setHitchUser(whoAmIResponse);
  };

  return (
    <HubAdminConnectionsProvider>
      <Box sx={{ my: 2 }}>
        <AdminDrawer
          setState={setState}
          state={state}
          activeOrganization={activeOrganization}
          setActiveOrganization={setActiveOrganization}
          activeService={activeService}
          setActiveService={setActiveService}
          activeUser={activeUser}
          setActiveUser={setActiveUser}
        />
        <AppBar className="admin">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              sx={{ mr: 2 }}
              onClick={(e) => setState(true)}
            >
              <FontAwesomeIcon icon={faHammer} />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: 'none', sm: 'block' }
              }}
            >
              Hitch Platform Administration
            </Typography>
          </Toolbar>
        </AppBar>
        <Routes>
          <Route exact path="/debug" element={<Debug hitchUser={hitchUser} isAuthenticated={isAuthenticated} jwtToken={jwtToken} user={user} />} />
          <Route path="/debug/:guid" element={<Debug hitchUser={hitchUser} isAuthenticated={isAuthenticated} jwtToken={jwtToken} user={user} />} />
          <Route path="/cluster" element={<Cluster hubConnection={hubConnection} sessionId={sessionId} />} />

          <Route path="/services" element={<Services hubConnection={hubConnection} sessionId={sessionId} setActiveService={setActiveService} />} />
          <Route path="/features" element={<Features hubConnection={hubConnection} sessionId={sessionId} />} />
          <Route path="/organizations" element={<Organizations hubConnection={hubConnection} sessionId={sessionId} setActiveOrganization={setActiveOrganization} />} />
          <Route path="/connections" element={<Connections hubConnection={hubConnection} sessionId={sessionId} activeOrganization={activeOrganization} />} />
          <Route path="/roles" element={<Roles hubConnection={hubConnection} sessionId={sessionId} activeOrganization={activeOrganization} />} />
          <Route path="/users" element={<Users hubConnection={hubConnection} sessionId={sessionId} setActiveUser={setActiveUser} />} />
          <Route path="/users/:userId" element={<User />} />
          <Route path="/userroles" element={<UserRoles hubConnection={hubConnection} sessionId={sessionId} activeOrganization={activeOrganization} />} />
          <Route path="/training" element={<Training hubConnection={hubConnection} sessionId={sessionId} activeOrganization={activeOrganization} />} />
          <Route path="/servicestatus" element={<ServiceStatus hubConnection={hubConnection} sessionId={sessionId} activeOrganization={activeOrganization} />} />
          <Route path="/offer" element={<Offer />} />
          <Route path="/offer/:guid" element={<OfferDetail />} />
          <Route path="/plan" element={<Plan />} />
          <Route path="/nodes/:nodeId" element={<Node />} />
          <Route path="/actors/:actorId" element={<Actor />} />
          <Route path="/bump" element={<Bump jwtToken={jwtToken} />} />
        </Routes>
      </Box>
    </HubAdminConnectionsProvider>
  );
}

export default Admin;
