import { Button, Typography } from "@mui/material";
import { useState } from "react";
import HitchBox from "../components/hitchbox/HitchBox";
import ExtensionsMenu from "./ExtensionsMenu";
import { useParams } from "react-router-dom";

function ExtensionsMain() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { serviceId } = useParams();

    function drawerStatus(value) {
        // console.log('was', drawerOpen);
        setDrawerOpen(value);
    }
    return (
    <HitchBox 
        backgroundColor={"#FF0000"}
        color={"#FFFF00"}>
            
            <Button onClick={(e) => drawerStatus(true)}>Hi</Button>
            <ExtensionsMenu 
                drawerOpen={drawerOpen}
                setDrawerOpen={drawerStatus}
                serviceId={serviceId} />

    </HitchBox>)
}

export default ExtensionsMain;