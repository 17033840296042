import { useMemo } from "react";

function Select({ value, handleId, nodeId, options, onChange }) {
    const memoizedOptions = useMemo(() => options ? options.map((option) => (
        <option key={option.value} value={option.value}>
            {option.label}
        </option>
    )) : [], [options]);

    return (
        <div className="custom-node__select">
            <select
                className="nodrag"
                onChange={(evt) => onChange(evt, nodeId, handleId)}
                value={value}
                aria-label="Select Edge Type"
            >
                {memoizedOptions}
            </select>
        </div>
    );
}

export default Select;