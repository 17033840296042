import { Card, CardContent, Chip, Grid, Typography } from "@mui/material";
import userManager from '../../services/authService';
import * as signalR from '@microsoft/signalr';
import { v4 as uuidv4 } from 'uuid';
import { setAuthHeader } from '../../utils/axiosHeaders';
import { useEffect, useRef, useState } from "react";
import { useHubConnections } from "../../utils/HubConnectionsProvider";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-solid-svg-icons";


function Cluster() {
    const clusterId = 'dfbe8ce6-2404-4b86-9f36-e035afa548bb';
    const [debugConnection, setDebugConnection] = useState(null);
    const highlightTimeouts = useRef({});
    const [startOnce, setStartOnce] = useState(false);
    const [isConnectionReady, setIsConnectionReady] = useState(false);
    const [highlightUpdate, setHighlightUpdate] = useState({});
    const [kingNodes, setKingNodes] = useState([]);
    const previousKingNodes = useRef(kingNodes);
    const [clusterData, setClusterData] = useState([]);
    const {
        jwtToken,
        hitchUser,
        user
    } = useHubConnections();

    const goDude = useNavigate();

    useEffect(() => {
        // console.log('hi');
        if (user) {
            // Fetch user avatar
            fetch(`${window._env_.HITCH_API}/api/hitch_deployment`, {
                headers: {
                    'Authorization': `Bearer ${user.id_token}`
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();  // Convert to JSON if response was ok
                    } else {
                        throw new Error('Failed to fetch');  // Throw an error if response not ok
                    }
                })
                .then(data => {
                    // console.log('Cluster Data:', data);  // Log the data
                    setClusterData(data);
                })
                .catch((error) => {
                    // console.log('Cluster Data:', error);
                });
        }
    }, [user]);  // Dependency array with user object


    useEffect(() => {
        if (jwtToken && hitchUser && !startOnce) {
            // console.log('hitchUser.userId (starting debugConnection)', hitchUser.userId);

            let isMounted = true; // Track mounted status
            setStartOnce(true);

            const connection = new signalR.HubConnectionBuilder()
                .withUrl(`${window._env_.HITCH_API}/8675309?sessionId=${clusterId}`, {
                    accessTokenFactory: () => jwtToken
                })
                .withAutomaticReconnect()
                .build();

            const startConnection = async () => {
                try {
                    await connection.start();
                    // console.log('Cluster connection established.');
                    setDebugConnection(connection);
                    setIsConnectionReady(true);
                    // fetchInitialData(connection);
                    connection.on("KingEntry", handleKingEntry);
                    connection.on("DropKing", handleDropKing);
                } catch (error) {
                    if (isMounted) {
                        console.error('Connection failed to start:', error);
                        setStartOnce(false);
                        setIsConnectionReady(false);
                    }
                }
            };
            startConnection();

            // Cleanup
            return () => {
                isMounted = false;
                connection.off("KingEntry", handleKingEntry);
                connection.off("DropKing", handleDropKing);
                connection.stop().then(() => {
                    // console.log('Cluster Connection stopped')
                });
                setIsConnectionReady(false);
                setStartOnce(false);
            };
        }
    }, [jwtToken, hitchUser]);

    useEffect(() => {
        previousKingNodes.current = kingNodes;
    }, [kingNodes]);

    useEffect(() => {
        // Iterate over kingNodes to find any that need highlighting or updating
        kingNodes.forEach(node => {
            const key = node.nodeName + 'lastContact';
            const prevNode = previousKingNodes.current.find(n => n.nodeName === node.nodeName);
            const prevValue = prevNode ? formatDate(prevNode.lastContact) : null;
            const currentValue = formatDate(node.lastContact);

            if (prevValue !== currentValue && prevValue !== null) {
                if (highlightTimeouts.current[key]) {
                    clearTimeout(highlightTimeouts.current[key]);
                    delete highlightTimeouts.current[key];
                }

                setHighlightUpdate(prev => ({ ...prev, [key]: true }));

                highlightTimeouts.current[key] = setTimeout(() => {
                    setHighlightUpdate(prev => {
                        const newHighlight = { ...prev };
                        delete newHighlight[key];
                        return newHighlight;
                    });
                    delete highlightTimeouts.current[key];
                }, 3000);
            }
        });
    }, [kingNodes]); // Depend on kingNodes so it only runs when they update

    const sortKingNodes = nodes => {
        return nodes.sort((a, b) => new Date(b.birth) - new Date(a.birth));
    };

    function handleKingEntry(data) {
        setKingNodes(prevNodes => {
            const index = prevNodes.findIndex(node => node.nodeName === data.nodeName);
            let newNodes;
            if (index > -1) {
                newNodes = [...prevNodes];
                newNodes[index] = data;
            } else {
                newNodes = [...prevNodes, data];
            }
            return sortKingNodes(newNodes);
        });
    }

    function handleDropKing(data) {
        // console.log('Dropping King:', data);
        setKingNodes(prevNodes => {
            const index = prevNodes.findIndex(node => node.nodeName === data.nodeName);
            if (index > -1) {
                const newNodes = [...prevNodes];
                newNodes.splice(index, 1);
                return newNodes;
            }
            return prevNodes;
        });
    }


    function formatDate(dateTimeStr) {
        const options = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(new Date(dateTimeStr));
    }

    const chipStyles = {
        data: {
            bgcolor: '#8B0000', // dark red
            color: '#FFFFFF', // white text
        },
        account: {
            bgcolor: '#00008B', // deep blue
            color: '#FFFF00', // yellow
        },
        user: {
            bgcolor: '#00008B', // deep blue
            color: '#FFFF00', // yellow
        },
        chat: {
            bgcolor: '#228B22', // white background
            color: '#FFFFFF', // hunter green text
        },
        openai: {
            bgcolor: '#228B22', // white background
            color: '#FFFFFF', // hunter green text
        },
        web: {
            bgcolor: '#CC5500', // burnt orange
            color: '#FFFF00', // yellow
        },
        docusign: {
            bgcolor: '#102e45', // light blue
            color: '#fffb00', // Yellow
        },
        office365: {
            bgcolor: '#102e45', // light blue
            color: '#fffb00', // Yellow
        },
        quickbooks: {
            bgcolor: '#102e45', // light blue
            color: '#fffb00', // Yellow
        }
    };


    function RoleChip({ role }) {
        const handleClick = () => {
            // console.log(`Clicked on role: ${role}`);
        };
        const style = chipStyles[role] || { bgcolor: '#e0e0e0', color: '#000000' };

        return <Chip
            label={role}
            onClick={handleClick}
            sx={{ ...style, borderRadius: '4px' }}
        />;
    }

    function formatRoles(roles) {
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {roles.map((role, index) => (
                    <RoleChip key={index} role={role} />
                ))}
            </div>
        );
    }

    const getNodeDataDisplay = (node, field) => {
        const key = node.nodeName + field;
        let value = node[field];
        if (field === 'lastContact') {
            value = formatDate(value);
        }

        return (
            <Typography
                variant="body1"
                style={{
                    fontWeight: highlightUpdate[key] ? 'bold' : 'normal',
                    transition: 'font-weight 0.3s ease'
                }}>
                {value}
            </Typography>
        );
    };

    function OpenNode(nodeName) {
        // console.log(`go to ${nodeName}`);
        goDude(`/app/cluster/${nodeName}`);
    }

    return (
        <div>
            <Typography variant="h4">Hitch.AI Cluster Nodes 2</Typography>
            <Grid container>
                {clusterData.map((node, index) => (
                    <Grid item key={index}>
                        <Card key={index} style={{ margin: 10 }} onClick={(e) => OpenNode(node.nodeName)}>
                            <CardContent>
                                <Typography variant="h5">{node.isLeader ? (<FontAwesomeIcon icon={faStar} />) : (<></>)}{node.nodeName}</Typography>
                                <Typography variant="body1">Unscheduled Actor Count: {node?.report?.unscheduledActors}</Typography>
                                <Typography variant="body1">Scheduled Actor Count: {node?.report?.scheduledActors}</Typography>
                                <Typography variant="body1">Online Actor Count: {node?.report?.onlineActors}</Typography>
                                <Typography variant="body1">Total Actor Count: {node?.report?.totalActors}</Typography>
                                {/* {node.roles.map((role, idx) => (

                                ))} */}
                                {/* <Typography variant="body1">Actor Count: {getNodeDataDisplay(node, 'actorCount')}</Typography>
                                <Typography variant="body1">Born: {formatDate(node.birth)}</Typography>
                                <Typography variant="body1">Update: {getNodeDataDisplay(node, 'lastContact')}</Typography>
                                <Typography variant="body1">Leader: {node.myLeaders}</Typography>
                                <Typography variant="body1">Node Roles: {formatRoles(node.nodeRoles)}</Typography>
                                <Typography variant="body1">Version: {node.nodeVersion}</Typography> */}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export default Cluster;