import { Button, FormControl, TextField } from "@mui/material";
import { useState } from "react";

function Models({hubConnection, sessionId}) {

    const [modelName, setModelName] = useState('ft:gpt-3.5-turbo-0613:hitch-software::8Z1zNWKS');
    
    function applyModel(data) {
        console.log('applyData');
        if(hubConnection) {
            hubConnection.invoke("ApplyModel", sessionId, modelName)
            .then(() => {
            })
            .catch(error => console.error("Error sending message:", error));
        } else {
            console.log('hubConnection is null');
        }
    }

    return (
        <>
            <TextField 
                fullWidth 
                label="Models" 
                id="fullWidth"
                value={modelName}
                onChange={(e) => setModelName(e.target.value)}
            />
            <Button 
                variant="contained"
                onClick={(e) => applyModel(e)}>Apply It!</Button>
        </>
    )
}

export default Models;