import { Typography } from "@mui/material";
import HitchBox from "../components/hitchbox/HitchBox";
import { useParams } from "react-router-dom";
import { useAdminHubConnections } from "../utils/HubAdminConnectionProvider";
import { useEffect, useState } from "react";

function User() {

    const { userId } = useParams();
    const { adminConnection, hitchUser, isConnectionReady } = useAdminHubConnections();
    const { user, setUser } = useState({});

    useEffect(() => {
        if (adminConnection) {
            const subscribeToEvents = () => {
                adminConnection.on('User', setUser);
            };

            subscribeToEvents();

            adminConnection.invoke("GetUser", hitchUser?.userId, userId)
                .then(() => {
                    console.log('i asked for the user');
                })
                .catch(error => console.error("Error sending message:", error));

            return () => {
                adminConnection.off('User', setUser);
                // Unsubscribe from other events here
            };
        }
    }, [adminConnection]);


    useEffect(() => {
        if(user) {
            console.log('user', user);
        }
    }, [user]);

    return (
        <HitchBox backgroundColor="#333333" color="#CCCCCC">
            <Typography variant="h3">User {userId}</Typography>
        </HitchBox>
    )
}

export default User;