import React, { useState, useEffect } from 'react';
import { TextField, Grid, Box, Typography } from '@mui/material';
import './Users.css';

function UserDetails({ user, editMode }) {
    const [userDetails, setUserDetails] = useState(user);

    useEffect(() => {
        setUserDetails(user);
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <Box className="userDetailsContainer">
            <Grid container spacing={2} direction="column">
                {Object.keys(userDetails).map((key, index) => (
                    <Grid item xs={12} key={index}>
                        {editMode ? (
                            <TextField
                                fullWidth
                                label={key}
                                name={key}
                                value={userDetails[key] || ''}
                                onChange={handleChange}
                                disabled={key === 'id'}
                                InputLabelProps={{
                                    style: { color: '#d3d3d3', fontWeight: 'bold' }
                                }}
                                inputProps={{
                                    style: { color: '#fff' }
                                }}
                                className="userDetailsTextField"
                                variant="outlined"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#d3d3d3',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#fff',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#00eded',
                                        },
                                        '&.Mui-disabled': {
                                            '& fieldset': {
                                                borderColor: '#d3d3d3', // Light grey border for disabled state
                                            },
                                            '& input': {
                                                color: '#d3d3d3', // Light grey text for disabled state
                                            }
                                        }
                                    },
                                }}
                            />
                        ) : (
                            <Typography variant="body2" sx={{ color: '#d3d3d3' }}>
                                <strong>{key}:</strong> {userDetails[key]}
                            </Typography>
                        )}
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default UserDetails;
