import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Papa from 'papaparse';

function JsonToCsvGrid({ jsonData }) {
  const [csvData, setCsvData] = useState('');
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  // Function to convert JSON to CSV
  const convertToJsonToCSV = () => {
    try {
      const csv = Papa.unparse(jsonData);
      setCsvData(csv);
    } catch (error) {
      console.error("Error converting JSON to CSV:", error);
      console.error("jsonData", jsonData);
    }
  };

  // Function to parse CSV and set rows and columns for the grid
  const parseCsv = () => {
    Papa.parse(csvData, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: function (results) {
        if (results.data.length > 0) {
          setColumns(
            Object.keys(results.data[0]).map((key) => ({
              field: key,
              headerName: key,
              flex: 1,
            }))
          );
          setRows(results.data);
        }
      },
    });
  };

  useEffect(() => {
    convertToJsonToCSV();
  }, [jsonData]);

  useEffect(() => {
    if (csvData) {
      parseCsv();
    }
  }, [csvData]);

  return (
    <div style={{ height: 400, width: '100%', backgroundColor: '#000000' }}>
      <h1>Data Grid</h1>
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
}

export default JsonToCsvGrid;
