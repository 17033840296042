import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  Container,
  Tooltip,
  IconButton,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Drawer,
  useTheme,
} from '@mui/material';
import {
  Route,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faGripHorizontal } from '@fortawesome/free-solid-svg-icons';
import MenuIcon from '@mui/icons-material/Menu';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Extensions from '../../extensions/Extensions';
import ExtensionsRoute from '../../extensions/ExtensionsRoute';
import Conversations from '../Conversations/Conversations';
import NotFound from '../../components/notfound/NotFound';
import Landing from '../Landing/Landing';
import Conversation from '../Conversation/Conversation';
import SettingsMenu from '../AccountSettings/SettingsMenu/SettingsMenu';
import { SettingsStyleProvider, useSettingsStyles } from '../AccountSettings/common/SettingsStyleProvider';
import AccountDashboard from '../AccountSettings/Dashboard/AccountDashboard';
import ManageProfile from '../AccountSettings/Profile/ManageProfile';
import ListOrganizations from '../AccountSettings/Organization/ListOrganizations'; 
import ManageOrganization from '../AccountSettings/Organization/ManageOrganization'; 
import ManageSubscription from '../AccountSettings/Subscription/ManagePlan'; 
import ListBillingHistory from '../AccountSettings/Subscription/ListBillingHistory'; // Import the component
import NotificationPreferences from '../AccountSettings/Notification/NotificationPreferences'; // Import the component
import AppList from '../AccountSettings/Apps/AppList'; // Import the component
import ContactSupport from '../AccountSettings/HelpCenter/ContactSupport'; // Import the component
import ReleaseNotes from '../AccountSettings/HelpCenter/ReleaseNotes'; // Import the component
import InviteTeamMember from '../AccountSettings/Organization/InviteTeamMember';
import ViewRechart from '../Viewers/ViewRechart';
import SageTester from '../Sage/Interface/SageTester';
import Bump from '../Bump/Bump';
import ServiceDirectory from '../ServiceDirectory/ServiceDirectory';
import UserMenuPopover from './UserMenuPopover';
import AppMenuPopover from './AppMenuPopover';
import RegistrationWizard from '../../Registration/OpenRegistrationWizard';
import SubscriptionWizard from '../../Registration/SubscriptionWizard';
import Footer from '../../components/footer/FooterHitchAI';
import BotAdmin from '../BotAdmin/BotAdmin';
import Wizard from '../Wizard/Wizard';
import Quickbooks from '../Quickbooks/Quickbooks';
import Flow from '../Flow/Flow';
import BotOrgChart from '../BotOrgChart/BotOrgChart';
import BotOrgChartDetail from '../BotOrgChart/BotOrgChartDetail';
import Trainer from '../Trainer/Trainer';
import MobileMenuDrawer from './MobileMenuDrawer';
import Rules from '../Rules/Rules';
import RuleList from '../Rules/RuleList';
import { useHubConnections } from '../../utils/HubConnectionsProvider';
import Docusign from '../Docusign/Docusign';
import EditConnection from '../Connection/EditConnection';
import MenuAdmin from '../MenuAdmin/MenuAdmin';
import BotInteraction from '../BotInteraction/BotInteraction';
import Cluster from '../Cluster/Cluster';
import Node from '../Cluster/Node';
import VectorStore from '../BotAdmin/VectorStore';
import StoreDetails from '../BotAdmin/StoreDetails';
import TheQc from '../TheQc/TheQc';
import ConnectionWizard from '../ConnectionWizard/OpenConnectionWizard'; // Import the ConnectionWizard component
import SplashScreenOnboarding from '../../Registration/WelcomeOnboarding';

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function Dashboard(props) {
  const theme = useTheme();
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const {
    dashboardConnection,
    jwtToken,
    hitchUser,
    user,
    isAuthenticated,
    isConnectionReady,
    started,
    notRegistered,
    setAccount,
    account,
    noSubscription
  } = useHubConnections();
  const themeBackgroundColor = theme.palette.navBackground[theme.palette.mode];
  const themeTextPrimaryColor = theme.palette.navPrimaryText[theme.palette.mode];
  const themeLinkColor = theme.palette.navLink[theme.palette.mode];
  const modalBackgroundColor = theme.palette.modalBackground[theme.palette.mode];
  const modalTextPrimaryColor = theme.palette.modalTextPrimary[theme.palette.mode];

  const [anchorElChats, setAnchorElChats] = useState(null);
  const [sessionId, setSessionId] = useState(null);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [accountAvatar, setAccountAvatar] = useState(null);
  const [userAvatar, setUserAvatar] = useState(null);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [shouldFocusChatBox, setShouldFocusChatBox] = useState(false);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const [userAppMenuAnchorEl, setAppMenuAnchorEl] = useState(null);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [menuPopoverOpen, setMenuPopoverOpen] = useState(false);
  const [inviteFormOpen, setInviteFormOpen] = useState(false);
  const [chatHover, setChatHover] = useState(false);
  const chatSelected = location.pathname === "/app/conversation";
  const [isHovered, setIsHovered] = useState(false);


  useEffect(() => {
    if (notRegistered) {
      console.log('not registered');
      navigate('/app/registration');
    }
  }, [notRegistered]);

  useEffect(() => {
    if (noSubscription) {
      console.log('no subscription');
      // navigate('/app/registration');
    }
  }, [noSubscription]);

  const handleOpenChats = (event) => {
    setAnchorElChats(event.currentTarget);
  };

  const handleCloseChats = () => {
    setAnchorElChats(null);
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleOpenAppMenu = (event) => {
    setAppMenuAnchorEl(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setUserMenuAnchorEl(null);
  };

  const handleCloseAppMenu = () => {
    setAppMenuAnchorEl(null);
  };

  const handleOpenInviteForm = () => {
    setInviteFormOpen(true);
  };

  const handleCloseInviteForm = () => {
    setInviteFormOpen(false);
  };


  const handleHomeClick = () => {
    navigate('/app/conversation');
  };

  const handleDocusignClick = () => {
    navigate('/app/docusign');
  }

  const getUserInitials = (name) => {
    return name
      .split(' ')
      .map((n) => n[0])
      .join('')
      .toUpperCase();
  };

  function dashboardHitchCommand(service, feature, operation, payload = {}, onSuccess, onError) {
    if (dashboardConnection && isConnectionReady) {
      dashboardConnection.invoke("HitchCommand", hitchUser.userId, service, feature, operation, payload)
        .then(() => {
          if (onSuccess && typeof onSuccess === 'function') {
            onSuccess();
          }
        })
        .catch(error => {
          if (onError && typeof onError === 'function') {
            onError(error);
          } else {
            errorHandler(error);
          }
        });
    }
  }

  function errorHandler(error) {
    console.error("SignalR Error", error);
  }

  function handleAccountSwitch(data) {
    let userId = data.accountId;
    if (hitchUser && hitchUser.accountId !== account?.id) {
      if (dashboardConnection && isConnectionReady) {
        dashboardConnection.invoke("AccountRequest")
          .then(() => {
          })
          .catch(error => {
            errorHandler(error);
          });
      }
    }
  }

  useEffect(() => {
    if (dashboardConnection && isConnectionReady) {
      dashboardConnection.on("UpdateUser", handleAccountSwitch);
      dashboardConnection.on("UpdateAccount", handleAccountUpdate);
      return () => {
        dashboardConnection.off("UpdateUser", handleAccountSwitch);
        dashboardConnection.off("UpdateAccount", handleAccountUpdate);
      };
    }
  }, [dashboardConnection, isConnectionReady]);

  useEffect(() => {
    if (hitchUser) {
      setUserAvatar(`${window._env_.HITCH_API}/api/accimage/${hitchUser.userId}.png`);

      if (hitchUser.accountId && !account && dashboardConnection && isConnectionReady) {
        dashboardConnection.invoke("SwitchAccount", hitchUser.accountId)
          .then(() => {
            console.log('YES');
            setAccountAvatar(`${window._env_.HITCH_API}/api/accimage/${hitchUser.accountId}.png`);
          })
          .catch(error => console.error("Error sending message:", error));
      }
    }
  }, [hitchUser]);

  useEffect(() => {
    if (account && account.id) {
      setAccountAvatar(`${window._env_.HITCH_API}/api/accimage/${account.id}.png`);
    }
  }, [account]);

  function handleAccountUpdate(data) {
    setAccount(data);
  }

  function LogoutDialog(props) {
    return (
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to log out?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            No
          </Button>
          <Button
            onClick={() => {
              props.onClose();
              window.location.href = '/logout';
            }}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar position="sticky">
          <Toolbar sx={{ backgroundColor: themeBackgroundColor, color: themeTextPrimaryColor }}>
            <Avatar
              alt="A"
              src={accountAvatar || "/img/hitchlogo.png"}
              sx={{ marginRight: 1 }}
            />
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Typography
                variant='h6'
                component="div"
                sx={{ fontFamily: '"Poppins", sans-serif' }}
              >
                {account ? account.name : "Hitch Software"}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                display: { xs: 'flex', sm: 'none' },
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open chat"
                onClick={handleHomeClick}
                sx={{ marginRight: 3 }}
              >
                <FontAwesomeIcon icon={faComment} style={{ color: 'inherit', fontSize: '.8em' }} />
              </IconButton>
              {hitchUser?.roles.includes('HitchAdmin') && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open app menu"
                  onClick={handleOpenAppMenu}
                  sx={{ marginRight: 3 }}
                >
                  <FontAwesomeIcon icon={faGripHorizontal} style={{ color: 'inherit', fontSize: '.8em' }} />
                </IconButton>
              )}
            </Box>

            <Box
              sx={{
                display: { xs: 'none', sm: 'block' },
                alignItems: 'center',
                '& a, & button': {
                  mx: 2,
                }
              }}
            >
              <Link
                to="/app/conversation"
                onMouseEnter={() => setChatHover(true)}
                onMouseLeave={() => setChatHover(false)}
                style={{
                  textDecoration: chatHover || chatSelected ? 'underline' : 'none',
                  color: themeLinkColor,
                  fontSize: '14px',
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: '500',
                  transition: 'text-decoration 0.3s ease-in-out',
                  display: 'inline-flex',
                  alignItems: 'center',
                  transform: 'translateY(2px)'
                }}
              >
                <FontAwesomeIcon icon={faComment} style={{ marginRight: '5px', color: 'inherit', fontSize: '1em' }} />
                Chat
              </Link>
              {hitchUser?.roles.includes('HitchAdmin') && (
                <>
                  &nbsp;|&nbsp;
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open app menu"
                    onClick={handleOpenAppMenu}
                    sx={{
                      marginLeft: 3,
                      marginRight: 3,
                    }}
                  >
                    <FontAwesomeIcon icon={faGripHorizontal} style={{ color: themeLinkColor, fontSize: '.8em' }} />
                  </IconButton>
                </>
              )}
            </Box>

            <Box sx={{ width: '48px', display: { xs: 'none', sm: 'block' } }} />
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 0, display: { xs: 'block', sm: 'none' }, marginTop: '4px' }}
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>

            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Tooltip title={hitchUser ? hitchUser.name : "Loading..."}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt="User Avatar"
                    src={userAvatar || "/static/images/avatar/default.jpg"}
                    sx={{
                      backgroundColor: '#757575',
                      border: "2px solid black",
                      width: 40, // Ensure the avatar has a fixed width
                      height: 40, // Ensure the avatar has a fixed height
                    }}
                  />
                </IconButton>
              </Tooltip>

              <UserMenuPopover
                anchorEl={userMenuAnchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleCloseUserMenu}
                userAvatar={userAvatar}
                hitchUser={hitchUser}
                navigate={navigate}
                onInviteTeamMembersClick={handleOpenInviteForm}
              />

              <AppMenuPopover
                accountId={account?.id}
                anchorEl={userAppMenuAnchorEl}
                onClose={handleCloseAppMenu}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                userAvatar={userAvatar}
                hitchUser={hitchUser}
                navigate={navigate}
                onInviteTeamMembersClick={handleOpenInviteForm}
              />

              {inviteFormOpen && (
                <InviteTeamMember
                  open={inviteFormOpen}
                  onClose={handleCloseInviteForm}
                />
              )}
              
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Container sx={{ minWidth: '100%', maxWidth: '100%', padding: '0px' }}>
        <Box sx={{ p: 0, m: 0, width: '100%', maxWidth: '100%' }}>
          <Routes>
            <Route path="/" element={<Bump />} />
            <Route exact path="/bots" element={<BotOrgChart user={user} isAuthenticated={isAuthenticated} />} />
            <Route path="/bots/:guid" element={<BotOrgChartDetail />} />
            <Route exact path="/storage" element={<VectorStore />} />
            <Route path="/storage/:guid" element={<StoreDetails />} />
            <Route path="/chart" element={<ViewRechart />} />
            <Route path="/cluster" element={<Cluster />} />
            <Route path="/cluster/:nodename" element={<Node />} />
            <Route path="/connection/:guid" element={<EditConnection dashboardHitchCommand={dashboardHitchCommand} hitchUser={hitchUser} hubConnection={dashboardConnection} sessionId={sessionId} />} />
            <Route path="/conversations" element={<Conversations user={user} isAuthenticated={isAuthenticated} />} />
            <Route exact path="/conversation" element={<Conversation redirectPath={'/app/conversation'} user={user} isAuthenticated={isAuthenticated} shouldFocusChatBox={shouldFocusChatBox} />} />
            <Route path="/conversation/:guid" element={<Conversation redirectPath={'/app/conversation'} user={user} isAuthenticated={isAuthenticated} />} />
            <Route path="/conversation/:guid/interaction" element={<BotInteraction />} />
            <Route path="/dash/:guid" element={<Landing user={user} isAuthenticated={isAuthenticated} />} />
            <Route path="/directory" element={<ServiceDirectory user={user} isAuthenticated={isAuthenticated} />} />
            <Route path="/docusign" element={<Docusign sessionId={sessionId} />} />
            <Route path="/extensions" element={<ExtensionsRoute />}>
              <Route path="/extensions" element={<Extensions />} />
              <Route path="/extensions/*" element={<Extensions />} />
            </Route>
            <Route path="/flow" element={<Flow user={user} isAuthenticated={isAuthenticated} />} />
            <Route path="/InviteTeamMember" element={<InviteTeamMember user={user} isAuthenticated={isAuthenticated} />} />
            <Route path="/menus" element={<MenuAdmin />} />
            <Route exact path="/quickbooks" element={<Quickbooks user={user} isAuthenticated={isAuthenticated} />} />
            <Route path="/quickbooks/:guid" element={<Quickbooks user={user} isAuthenticated={isAuthenticated} />} />
            <Route path="/registration" element={<RegistrationWizard user={user} isAuthenticated={isAuthenticated} />} />
            <Route path="/rules" element={<RuleList dashboardHitchCommand={dashboardHitchCommand} hubConnection={dashboardConnection} sessionId={sessionId} />} />
            <Route exact path="/rule" element={<RuleList dashboardHitchCommand={dashboardHitchCommand} hubConnection={dashboardConnection} sessionId={sessionId} />} />
            <Route path="/rule/:guid" element={<Rules dashboardHitchCommand={dashboardHitchCommand} hubConnection={dashboardConnection} sessionId={sessionId} />} />
            <Route path="/subscribe" element={<SubscriptionWizard user={user} isAuthenticated={isAuthenticated} />} />
            <Route exact path="/sage" element={<SageTester user={user} isAuthenticated={isAuthenticated} />} />
            <Route path="/sage/:guid" element={<SageTester user={user} isAuthenticated={isAuthenticated} />} />
            <Route path="/account" element={<SettingsMenu account={account} dashboardHitchCommand={dashboardHitchCommand} hitchUser={hitchUser} hubConnection={dashboardConnection} isAuthenticated={isAuthenticated} user={user} />} />

            <Route path="/account/dashboard" element={
              <SettingsStyleProvider>
                <AccountDashboard account={account} dashboardHitchCommand={dashboardHitchCommand} hitchUser={hitchUser} hubConnection={dashboardConnection} isAuthenticated={isAuthenticated} user={user} />
              </SettingsStyleProvider>
            } />
            
            <Route path="/account/profile" element={
              <SettingsStyleProvider>
                <ManageProfile account={account} dashboardHitchCommand={dashboardHitchCommand} hitchUser={hitchUser} hubConnection={dashboardConnection} isAuthenticated={isAuthenticated} user={user} />
              </SettingsStyleProvider>
            } />

            <Route path="/account/my-organizations" element={
              <SettingsStyleProvider>
                <ListOrganizations account={account} dashboardHitchCommand={dashboardHitchCommand} hitchUser={hitchUser} hubConnection={dashboardConnection} isAuthenticated={isAuthenticated} user={user} />
              </SettingsStyleProvider>
            } />
            <Route path="/account/manage-organization" element={
              <SettingsStyleProvider>
                <ManageOrganization account={account} dashboardHitchCommand={dashboardHitchCommand} hitchUser={hitchUser} hubConnection={dashboardConnection} isAuthenticated={isAuthenticated} user={user} />
              </SettingsStyleProvider>
            } />

            <Route path="/account/manage-plan" element={
              <SettingsStyleProvider>
                <ManageSubscription account={account} dashboardHitchCommand={dashboardHitchCommand} hitchUser={hitchUser} hubConnection={dashboardConnection} isAuthenticated={isAuthenticated} user={user} />
              </SettingsStyleProvider>
            } />
            
            <Route path="/account/billing" element={
              <SettingsStyleProvider>
                <ListBillingHistory account={account} dashboardHitchCommand={dashboardHitchCommand} hitchUser={hitchUser} hubConnection={dashboardConnection} isAuthenticated={isAuthenticated} user={user} />
              </SettingsStyleProvider>
            } />
            
            <Route path="/account/notification-preferences" element={
              <SettingsStyleProvider>
                <NotificationPreferences account={account} dashboardHitchCommand={dashboardHitchCommand} hitchUser={hitchUser} hubConnection={dashboardConnection} isAuthenticated={isAuthenticated} user={user} />
              </SettingsStyleProvider>
            } />
            
            <Route path="/account/app-list" element={
              <SettingsStyleProvider>
                <AppList account={account} dashboardHitchCommand={dashboardHitchCommand} hitchUser={hitchUser} hubConnection={dashboardConnection} isAuthenticated={isAuthenticated} user={user} />
              </SettingsStyleProvider>
            } />
            
            <Route path="/account/contact-support" element={
              <SettingsStyleProvider>
                <ContactSupport account={account} dashboardHitchCommand={dashboardHitchCommand} hitchUser={hitchUser} hubConnection={dashboardConnection} isAuthenticated={isAuthenticated} user={user} />
              </SettingsStyleProvider>
            } />
            
            <Route path="/account/releases" element={
              <SettingsStyleProvider>
                <ReleaseNotes account={account} dashboardHitchCommand={dashboardHitchCommand} hitchUser={hitchUser} hubConnection={dashboardConnection} isAuthenticated={isAuthenticated} user={user} />
              </SettingsStyleProvider>
            } />

            

            <Route path="/account/switch-organizations" element={<ListOrganizations />} />

            <Route path="/welcome" element={<SplashScreenOnboarding account={account} dashboardHitchCommand={dashboardHitchCommand} hitchUser={hitchUser} hubConnection={dashboardConnection} isAuthenticated={isAuthenticated} user={user} />} />
            
            <Route path="/trainer" element={<Trainer user={user} isAuthenticated={isAuthenticated} hubConnection={dashboardConnection} />} />
            <Route path="/trainer/:guid" element={<Trainer user={user} isAuthenticated={isAuthenticated} hubConnection={dashboardConnection} />} />
            <Route path="/wizard" element={<Wizard user={user} isAuthenticated={isAuthenticated} />} />
            <Route path="/wizard/:guid" element={<Wizard user={user} isAuthenticated={isAuthenticated} />} />
            <Route path="/the-qc" element={<TheQc />} />
            <Route path="/the-qc/:guid" element={<TheQc />} />
            <Route path="/connectme" element={<ConnectionWizard user={user} isAuthenticated={isAuthenticated} />} />
            <Route path="/*" element={<NotFound user={user} isAuthenticated={isAuthenticated} />} />
          </Routes>
          <Footer />
        </Box>
      </Container>
      <LogoutDialog open={openLogoutDialog} onClose={() => setOpenLogoutDialog(false)} />
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        sx={{
          '& .MuiDrawer-paper': {
            bgcolor: modalBackgroundColor,
            color: modalTextPrimaryColor,
            width: '300px'
          }
        }}
      >
        
        <MobileMenuDrawer
          userAvatar={userAvatar}
          hitchUser={hitchUser}
          navigate={navigate}
          onInviteTeamMembersClick={handleOpenInviteForm}
          onClose={handleDrawerClose}
          account={account}
        />
        
      </Drawer>
    </React.Fragment>
  );
}

export default Dashboard;