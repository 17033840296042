import { faTaco } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, Button, ButtonGroup, Divider, Grid, Tooltip, Typography } from "@mui/material";

function SelectOperation({ availableOperations, submitOperationId }) {

    function sOperation(operationId) {
        console.log('operationId', operationId);
        submitOperationId(operationId);
    }

    return (
        <Box>
            <Typography>Select A Operation</Typography>
            <Divider />

            {/* Grid container with centered content */}
            <Grid container spacing={2} justifyContent="center">
            <ButtonGroup>
                {availableOperations && availableOperations.map((svc) => (
                    <Button 
                        variant="outlined" 
                        startIcon={<FontAwesomeIcon icon={faTaco} />}
                        onClick={() => sOperation(svc.id)}
                        key={svc.id}>
                        {svc.name}
                    </Button>
                ))}
                </ButtonGroup>
            </Grid>
        </Box>
    );
}

export default SelectOperation;
