import React, { useState, useEffect } from 'react';
import { TextField, Grid, Box, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

function ServiceDetails({ service, editMode, setEditMode, handleSave, handleCancel }) {
    const [serviceDetails, setServiceDetails] = useState(service);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    useEffect(() => {
        setServiceDetails(service);
    }, [service]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setServiceDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <Box className="serviceDetailsContainer">
            
            <Grid container spacing={2} direction="column">
                {Object.keys(serviceDetails).map((key, index) => (
                    <Grid item xs={12} key={index}>
                        {editMode ? (
                            <TextField
                                fullWidth
                                label={key}
                                name={key}
                                value={serviceDetails[key] || ''}
                                onChange={handleChange}
                                disabled={key === 'id'}
                                InputLabelProps={{
                                    style: { color: '#d3d3d3', fontWeight: 'bold' }
                                }}
                                inputProps={{
                                    style: { color: '#fff' }
                                }}
                                variant="outlined"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#d3d3d3',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#fff',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#00eded',
                                        },
                                        '&.Mui-disabled': {
                                            '& fieldset': {
                                                borderColor: '#d3d3d3', // Light grey border for disabled state
                                            },
                                            '& input': {
                                                color: '#d3d3d3', // Light grey text for disabled state
                                            }
                                        }
                                    },
                                }}
                            />
                        ) : (
                            <Typography variant="body2" sx={{ color: '#d3d3d3' }}>
                                <strong>{key}:</strong> {serviceDetails[key]}
                            </Typography>
                        )}
                    </Grid>
                ))}
            </Grid>
            
        </Box>
    );
}

export default ServiceDetails;
