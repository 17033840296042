import React, { useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function ConnectionSuccess({ onNext }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onNext();
    }, 3000); // Move to the next step after 3 seconds

    return () => clearTimeout(timer); // Cleanup the timer if the component is unmounted
  }, [onNext]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <CheckCircleIcon style={{ color: 'green', fontSize: '48px' }} />
      <h2>Connection Successful</h2>
      <p>You have successfully signed in!</p>
    </div>
  );
}

export default ConnectionSuccess;
