import React, { useEffect, useState } from 'react';
import userManager from '../../services/authService';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as signalR from '@microsoft/signalr';
import Widget from '../Widget/Widget';
import { useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// import '/node_modules/react-grid-layout/css/styles.css';
// import '/node_modules/react-resizable/css/styles.css';
import { Box, Button, ButtonGroup, Modal, Typography } from '@mui/material';
import isValidGUID from '../../utils/IsValidGuid';

const ResponsiveGridLayout = WidthProvider(Responsive);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const breakpoints = {lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0};
const cols = {lg: 12, md: 10, sm: 6, xs: 4, xxs: 2};

function Landing(props) {
    const [widgets, setWidgets] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [user, setUser] = React.useState(null);
    const [sessionId, setSessionId] = React.useState('');
    const [hitchUser, setHitchUser] = React.useState(null);
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [hubConnection, setHubConnection] = useState(null);
    const [started, setStarted] = useState(false);
    const { guid } = useParams();
    const history = useNavigate();
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleOpenConfirm = () => setOpenConfirm(true);
    const handleCloseConfirm = () => setOpenConfirm(false);
    const [currentWidgetID, setCurrentWidgetID] = React.useState(null);
    const [openAttach, setOpenAttach] = React.useState(false);

    const handleLayoutChange = (currentLayout) => {
        if(hubConnection) {
            hubConnection.send("UpdateLayout", currentLayout);
        }
    };

    useEffect(() => {
        if (guid && isValidGUID(guid)) {
            setSessionId(guid);
        } else {
            const newGuid = uuidv4();
            history(`/app/dash/${newGuid}`);
            setSessionId(newGuid);
        }
    }, [guid]);

    useEffect(() => {
        console.log('Checking conditions to start SignalR:');
        console.log('isAuthenticated:', isAuthenticated);
        console.log('started:', started);
        if (isAuthenticated && !started) {
            const jwtToken = user.id_token;
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(`${window._env_.HITCH_API}/landingHub?sessionId=${sessionId}`, {
                //.withUrl(`https://hitch.ngrok.io/landingHub?sessionId=${sessionId}`, {
                    accessTokenFactory: () => jwtToken
                })
                .withAutomaticReconnect()
                .build();

            connection.start()
                .then(() => {
                    console.log("SignalR connected!");
                    setHubConnection(connection);
                    setStarted(true);
                })
                .catch(error => {
                    console.error("SignalR connection error:", error);
                    if (error?.statusCode === 401 || error?.status === 401 || error.message === 'login_required') {
                        const currentAddress = `${window.location.pathname}${window.location.search}`;
                        console.log('For I am doing the redirect');
                        window.location.href = `/login?redirect=${encodeURIComponent(currentAddress)}`;
                    }
                });
            connection.on("UpdateWidget", (updatedWidget) => {
                // Update the widget in your state here
                setWidgets(prevWidgets => prevWidgets.map(widget => widget.id === updatedWidget.id ? updatedWidget : widget));
            });

            connection.on("DeleteWidget", (widgetId) => {
                // Remove the widget from your state here
                setWidgets(prevWidgets => prevWidgets.filter(widget => widget.id !== widgetId));
            });

            connection.on("InitialWidgets", (initialWidgets) => {
                setWidgets(initialWidgets);
            });

            const addWidget = () => {
                const newWidget = {
                    id: Date.now(),
                    content: "New Widget",
                    layout: { x: 0, y: 0, w: 1, h: 2 }
                };
                connection.send("AddWidget", newWidget);
            };


            return () => {
                if (hubConnection) {
                    // hubConnection.off("ConversationUpdate", handleConversationUpdate);
                    // hubConnection.off("WhoAmIResponse", handleWhomAmI);
                    // hubConnection.stop();
                }
            };
        }
    }, [hubConnection, isAuthenticated, started]);

    useEffect(() => {
        async function checkAuthentication() {
            try {
                const authenticated = await userManager.getUser();
                // console.log('authenticated.expires_in', authenticated.expires_in);
                if (authenticated.expires_in < 0) {
                    const currentAddress = `${window.location.pathname}${window.location.search}`;
                    console.log('Tis I doing the redirect');
                    window.location.href = `/login?redirect=${encodeURIComponent(currentAddress)}`;
                }

                setUser(authenticated);
                // console.log('user', authenticated);
                setIsAuthenticated(authenticated != null);
            } catch (error) {
                console.error('Error checking authentication:', error);
                const currentAddress = `${window.location.pathname}${window.location.search}`;
                console.log('Nay, it is me doing the redirect');
                window.location.href = `/login?redirect=${encodeURIComponent(currentAddress)}`;
            }
        }
        checkAuthentication();
        console.log('isAuthenticated', isAuthenticated);
    }, [isAuthenticated]);

    const addWidget = () => {
        const newWidget = {
            id: Date.now(),
            content: "New Widget",
            layout: { x: 0, y: 0, w: 1, h: 2 }
        };
        setWidgets(prevWidgets => [...prevWidgets, newWidget]);
    };

    const removeWidget = (id) => {
        setWidgets(prevWidgets => prevWidgets.filter(widget => widget.id !== id));
        setCurrentWidgetID(null); // reset the current widget ID after removal
    };


    return (
        <div>
            <button onClick={() => setIsEditMode(!isEditMode)}>
                {isEditMode ? "Switch to Display Mode" : "Switch to Edit Mode"}
            </button>

            {isEditMode && <button onClick={addWidget}>Add Widget</button>}

            <ResponsiveGridLayout 
                className="layout" 
                isDraggable={isEditMode} 
                isResizable={isEditMode}
                breakpoints={breakpoints}
                cols={cols}
                onLayoutChange={handleLayoutChange}
            >
                {widgets.map(widget => (
                    <div key={widget.id} data-grid={widget.layout}>
                        <Widget
                            id={widget.id}
                            content={widget.content}
                            isEditMode={isEditMode}
                            removeWidget={() => {
                                setCurrentWidgetID(widget.id); 
                                setOpenConfirm(true);
                            }}
                            conversationId={widget.ConversationId}
                            setOpenAttach={setOpenAttach}
                            setOpenConfirm={setOpenConfirm}
                            setCurrentWidgetID={setCurrentWidgetID}
                        />
                    </div>
                ))}
            </ResponsiveGridLayout>

            <Modal
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Are you sure?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to remove this widget?
                    </Typography>
                    <ButtonGroup variant="contained">
                        <Button onClick={() => {
                            removeWidget(currentWidgetID);
                            handleCloseConfirm();
                        }}>Yes</Button>
                        <Button variant="outlined" onClick={handleCloseConfirm}>No</Button>
                    </ButtonGroup>
                </Box>
            </Modal>

            <Modal
                open={openAttach}
                onClose={() => setOpenAttach(false)}
                aria-labelledby="modal-modal-title-attach"
                aria-describedby="modal-modal-description-attach"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title-attach" variant="h6" component="h2">
                        Attach Conversation
                    </Typography>
                    <Typography id="modal-modal-description-attach" sx={{ mt: 2 }}>
                        Implement your modal to attach a conversation here.
                    </Typography>
                    <button onClick={() => setOpenAttach(false)}>Close</button>
                </Box>
            </Modal>


        </div>
    );
}

export default Landing;