import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, TextField, MenuItem, useTheme, useMediaQuery, Divider, IconButton } from '@mui/material';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import releaseNotesData from './releaseNotesData.json';
import { useSettingsStyles } from '../common/SettingsStyleProvider';
import SettingsMenu from '../SettingsMenu/SettingsMenu';  // Import the SettingsMenu component
import { useHubConnections } from '../../../utils/HubConnectionsProvider';

const SectionTitle = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: '14px',
  marginTop: '16px',
  marginBottom: '8px',
  fontFamily: '"Poppins", sans-serif',
  color: theme.palette.gridColumnHeadersText[theme.palette.mode],
  letterSpacing: '0.25px',
  lineHeight: '1.7',
}));

const LabelText = styled(Typography)(({ theme }) => ({
  marginBottom: '4px',
  fontFamily: '"Poppins", sans-serif',
  color: theme.palette.primaryText[theme.palette.mode],
  fontSize: '16px',
  lineHeight: '1.7',
  letterSpacing: '0.25px',
}));

const ValueText = styled('div')(({ theme, fontSize, fontWeight }) => ({
  fontWeight: fontWeight || 300,
  fontSize: fontSize || 'inherit',
  color: theme.palette.secondaryText[theme.palette.mode],
  fontFamily: '"Poppins", sans-serif',
  lineHeight: '1.7',
  letterSpacing: '0.25px',
  marginBottom: '16px',
}));

function ReleaseNotes() {
  const theme = useTheme();
  const drawerWidth = 240;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const {
    font,
    themeBackgroundColor,
    themePrimaryTextColor,
    defaultOutlineStyle,
    activeOutlineStyle,
    errorOutlineStyle,
    supportStyle,
    boxStyle,
    buttonStyle,
    buttonStyleSecondary,
    dividerStyle,
    headerStyle,
    titleStyle,
    buttonContainerStyle,
    setIsDrawerOpen,
    initialState,
    containerStyle,
    helperTextStyle,
    fixedWidthStyle,
    greetingContainerStyle
} = useSettingsStyles();

const { hitchUser, dashboardConnection, isConnectionReady } = useHubConnections();

const [selectedRelease, setSelectedRelease] = useState(null);

useEffect(() => {
  if (!isMobile) {
    setIsDrawerOpen(true);  // Open the drawer when not in mobile view
  }
}, [isMobile, setIsDrawerOpen]);

  useEffect(() => {
    if (releaseNotesData.releases.length > 0) {
      setSelectedRelease(releaseNotesData.releases[0]);
    }
  }, []);

  const handleSelectRelease = (event) => {
    const version = event.target.value;
    const release = releaseNotesData.releases.find(r => r.version === version);
    setSelectedRelease(release);
  };

  if (!selectedRelease) {
    return <div>Loading...</div>;
  }

  const textFieldStyle = {
    textAlign: 'left',
    marginBottom: '20px',
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.secondary[theme.palette.mode],
      height: '40px',
      color: theme.palette.primaryText[theme.palette.mode],
      fontFamily: '"Poppins", sans-serif',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primaryText[theme.palette.mode],
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.link[theme.palette.mode],
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.link[theme.palette.mode],
      },
      '.MuiSvgIcon-root': {
        fill: theme.palette.primaryText[theme.palette.mode],
      },
    },
  };

  const contentStyle = {
    paddingLeft: '20px',
    fontFamily: '"Poppins", sans-serif',
    lineHeight: '1.7',
    letterSpacing: '0.25px',
  };

  const bulletPointStyle = {
    marginBottom: '8px',
    color: theme.palette.primaryText[theme.palette.mode],
    fontSize: '16px',
    fontFamily: '"Poppins", sans-serif',
    lineHeight: '1.7',
    letterSpacing: '0.25px',
  };

  const renderContent = (contentItem, bulletPoints) => {
    const content = (
      <Box key={contentItem.label} style={{ marginBottom: '16px' }}>
        {contentItem.showLabel !== false && <LabelText>{contentItem.label}</LabelText>}
        <ValueText
          fontSize={contentItem.fontSize}
          fontWeight={contentItem.fontWeight}
          dangerouslySetInnerHTML={{ __html: contentItem.value }}
        />
      </Box>
    );

    if (bulletPoints) {
      return (
        <li key={contentItem.label} style={bulletPointStyle}>
          {content}
        </li>
      );
    }

    return content;
  };

  const CustomDivider = styled(Divider)(({ theme }) => ({
    backgroundColor: '#26272B',
    margin: '16px 0',
  }));

  return (
    <Box sx={{ display: 'flex' }}>
      <SettingsMenu hitchUser={hitchUser} isDrawerOpen={drawerOpen} setIsDrawerOpen={setDrawerOpen} />
      <Box sx={{ flexGrow: 1, marginLeft: !isMobile && drawerOpen ? '240px' : '0px' }}>
        {isMobile && !drawerOpen && (
          <IconButton
            onClick={() => setDrawerOpen(true)}
            size="small"
            sx={{
              color: 'white',
              backgroundColor: '#18181B',
              opacity: 0.8,
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
              margin: '10px',
              zIndex: 1000,
              position: 'fixed',
              top: '66px',
              left: '16px',
              '&:hover': {
                backgroundColor: 'rgba(21, 101, 192, 0.8)',
              },
            }}
          >
            <KeyboardDoubleArrowRight />
          </IconButton>
        )}

    <Box style={supportStyle}>
      <Box style={boxStyle}>
                <Box style={headerStyle}>
                    <h2 style={titleStyle}>Release Notes</h2>
                </Box>
        <div>
          <SectionTitle>Select a Version:</SectionTitle>
          <TextField
            select
            value={selectedRelease?.version || ''}
            onChange={handleSelectRelease}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              style: {
                color: theme.palette.primaryText[theme.palette.mode],
                fontFamily: '"Poppins", sans-serif',
              },
            }}
            InputProps={{
              style: {
                color: theme.palette.primaryText[theme.palette.mode],
                fontFamily: '"Poppins", sans-serif',
              },
            }}
            sx={textFieldStyle}
          >
            {releaseNotesData.releases.map((release, index) => (
              <MenuItem key={index} value={release.version} style={{ fontFamily: '"Poppins", sans-serif' }}>
                {release.version} - {release.releaseDate}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div>
          <SectionTitle>Version {selectedRelease.version} - {selectedRelease.releaseDate}</SectionTitle>
          <Typography style={{ marginBottom: '16px', fontSize: '16px', fontWeight: 'normal', fontFamily: '"Poppins", sans-serif', lineHeight: '1.7', letterSpacing: '0.25px' }}>
            Release Type: {selectedRelease.releaseType}
          </Typography>

          {selectedRelease.sections.map((section, index) => (
            <div key={index}>
              {index > 0 && <CustomDivider />}
              <SectionTitle>{section.title}</SectionTitle>
              <Box style={contentStyle}>
                {section.bulletPoints ? (
                  <ul>
                    {section.content.map((contentItem, contentIndex) =>
                      renderContent(contentItem, true)
                    )}
                  </ul>
                ) : (
                  section.content.map((contentItem, contentIndex) =>
                    renderContent(contentItem, false)
                  )
                )}
              </Box>
            </div>
          ))}
        </div>
      </Box>
    </Box>
    </Box>
    </Box>
  );
}

export default ReleaseNotes;
