import { Button, ButtonGroup, Grid, TextField } from "@mui/material";
import QuickbooksStep from "./QuickbooksStep";
import { useEffect, useState } from "react";

function QbTrainingFile({ hubConnection, trainingFile }) {

    return (
        <Grid className="qb_top_row" container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    multiline
                    fullWidth
                    rows={35}
                    disabled
                    value={trainingFile}
                    label="Training File" />
            </Grid>
        </Grid>
    )
}

export default QbTrainingFile;
