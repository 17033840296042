import React, { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Typography,
    Slider,
    Chip,
    Button,
    Autocomplete
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAdminHubConnections } from '../utils/HubAdminConnectionProvider';
import HitchBox from '../components/hitchbox/HitchBox';

function Node() {
    const navigate = useNavigate();
    const { nodeId } = useParams();
    const { adminConnection, hitchUser } = useAdminHubConnections();
    const [node, setNode] = useState({});
    const [accounts, setAccounts] = useState([]);
    const [containerImages, setContainerImages] = useState([]);
    const [versions, setVersions] = useState([]);

    useEffect(() => {
        if (adminConnection && hitchUser?.userId && nodeId) {
            const subscribeToEvents = () => {
                adminConnection.on('Node', setNode);
                adminConnection.on('Nodes', setNode);
                adminConnection.on('Accounts', setAccounts);
                adminConnection.on('ContainerImages', setContainerImages);
                adminConnection.on('Versions', setVersions);
            };
            subscribeToEvents();
            adminConnection
                .invoke('GetNode', hitchUser?.userId, nodeId)
                .catch((error) => console.error('Error fetching node:', error));
            adminConnection
                .invoke('GetAccounts', hitchUser?.userId, null)
                .catch((error) => console.error('Error fetching accounts:', error));
            adminConnection
                .invoke('GetContainerImages', hitchUser?.userId)
                .catch((error) => console.error('Error fetching container images:', error));
            return () => {
                adminConnection.off('Node', setNode);
                adminConnection.off('Nodes', setNode);
                adminConnection.off('Accounts', setAccounts);
                adminConnection.off('ContainerImages', setContainerImages);
                adminConnection.off('Versions', setVersions);
            };
        }
    }, [adminConnection, hitchUser?.userId, nodeId]);

    useEffect(() => {
        if (node.containerImage) {
            const containerImageId = containerImages.find(image => image.name === node.containerImage)?.id;
            if (containerImageId) {
                adminConnection
                    .invoke('GetVersions', hitchUser?.userId, containerImageId)
                    .catch((error) => console.error('Error fetching versions:', error));
            }
        }
    }, [node.containerImage, containerImages, adminConnection, hitchUser?.userId]);

    useEffect(() => {
        if (node.accountId && accounts.length > 0) {
            setNode(prevNode => ({
                ...prevNode,
                accountId: accounts.find(account => account.id === node.accountId)?.id || ''
            }));
        }
        if (node.containerImage && containerImages.length > 0) {
            setNode(prevNode => ({
                ...prevNode,
                containerImageId: containerImages.find(image => image.displayName === node.containerImage)?.id || ''
            }));
        }
        if (node.version && versions.length > 0) {
            setNode(prevNode => ({
                ...prevNode,
                version: versions.find(version => version.name === node.version)?.name || ''
            }));
        }
    }, [node, accounts, containerImages, versions]);

    const handleInputChange = (field, value) => {
        console.log('field', field);
        console.log('value', value);
        setNode(prevNode => ({
            ...prevNode,
            [field]: value
        }));
    };

    const handleRoleAddition = (role) => {
        setNode(prevNode => ({
            ...prevNode,
            roleList: prevNode.roleList ? `${prevNode.roleList},${role}` : role
        }));
    };

    const handleRoleDeletion = (role) => {
        setNode(prevNode => ({
            ...prevNode,
            roleList: prevNode.roleList.split(',').filter(r => r !== role).join(',')
        }));
    };
    
    const handleSave = () => {
        console.log('Node data:', node);
        
        // SaveNode
        adminConnection
                .invoke('SaveNode', hitchUser?.userId, node)
                .then(() => {
                    console.log('Node Saved!');
                    navigate('/admin/cluster');
                })
                .catch((error) => console.error('Error fetching node:', error));

    };
    
    function handleDelete() {
        console.log('Node data:', node);
                // SaveNode
                adminConnection
                .invoke('DeleteNode', hitchUser?.userId, node)
                .then(() => {
                    console.log('Node Deleted!');
                    navigate('/admin/cluster');
                })
                .catch((error) => console.error('Error deleting node:', error));
    }


    function cancelEdit() {
        navigate('/admin/cluster');
    }

    return (
        <HitchBox backgroundColor="#CCC" p={3}>
        
            <Typography variant="h4" gutterBottom>Edit Node</Typography>
            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <TextField
                        label="Name"
                        value={node.name || ''}
                        onChange={(e) => handleInputChange('name', e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="ID"
                        value={node.id || ''}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        options={accounts}
                        getOptionLabel={(option) => option.name || ''}
                        value={accounts.find(account => account.id === node.accountId) || null}
                        onChange={(event, newValue) => {
                            handleInputChange('accountId', newValue ? newValue.id : '');
                        }}
                        renderInput={(params) => <TextField {...params} label="Account" fullWidth />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Container Image</InputLabel>
                        <Select
                            value={node.containerImage || ''}
                            onChange={(e) => handleInputChange('containerImage', e.target.value)}
                        >
                            {containerImages.map((image) => (
                                <MenuItem key={image.id} value={image.name}>
                                    {image.displayName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Version</InputLabel>
                        <Select
                            value={node.version || ''}
                            onChange={(e) => handleInputChange('version', e.target.value)}
                        >
                            {versions.map((version) => (
                                <MenuItem key={version.name} value={version.name}>
                                    {version.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Typography gutterBottom>Replicas</Typography>
                    <Slider
    value={node.replicas || 0}
    onChange={(e, value) => handleInputChange('replicas', value)}
    aria-labelledby="discrete-slider"
    valueLabelDisplay="auto"
    step={1}
    marks
    min={0}
    max={5}
    color="primary"  // Use primary color for the Slider
    sx={{
        '& .MuiSlider-thumb': {
            color: 'primary.main', // Customize thumb color
        },
        '& .MuiSlider-track': {
            color: 'primary.main', // Customize track color
        },
        '& .MuiSlider-rail': {
            color: 'primary.light', // Customize rail color
        },
    }}
/>

                </Grid>

                <Grid item xs={12}>
                    <Typography gutterBottom>Roles</Typography>
                    <Box display="flex" flexWrap="wrap" gap={1}>
                        {node.roleList ? node.roleList.split(',').map((role) => (
                            <Chip
                                key={role}
                                label={role}
                                onDelete={() => handleRoleDeletion(role)}
                                color="primary"
                            />
                        )) : null}
                    </Box>
                    <Button variant="outlined" onClick={() => handleRoleAddition(prompt('Enter role:'))}>
                        Add Role
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Created"
                        value={node.created || ''}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Created By"
                        value={node.createdBy || ''}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Updated"
                        value={node.updated || ''}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Updated By"
                        value={node.updatedBy || ''}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                    <Button variant="contained" color="warning" onClick={handleDelete}>
                        delete
                    </Button>
                    <Button variant="outlined" onClick={() => cancelEdit()}>
                        Cancel
                    </Button>
                </Grid>

            </Grid>
        </HitchBox>
        
    );
}
export default Node;  