import { Avatar, Button, ButtonGroup, Grid, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import JsonView from "react18-json-view";
import Message from "../../hitchy/Conversation/Message";
import MessageLink from "../../hitchy/Conversation/MessageLink";

function FinalizePromptOperation({ payload, selectedServiceId, payloadResponse, runTest }) {
    useEffect(() => {
        console.log('payload', payload);
    }, [payload]);

    return (
        <Grid container>
            <Grid item xs={4}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar
                                alt={`${payload?.system}`}
                                src={`${window._env_.HITCH_API}/api/accimage/${selectedServiceId}.png`}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={payload?.system}
                            secondary={
                                <>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {payload?.operation_type} - {payload?.operation_subtype}
                                    </Typography>
                                    {` — ${payload?.name}`}
                                </>
                            }>
                        </ListItemText>
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={8}>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <ButtonGroup>
                                <Button onClick={(e) => runTest()}>
                                    Test Step
                                </Button>
                                <Button>
                                    Request Assistance
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Grid>
                    {payloadResponse && payloadResponse.output_type && payloadResponse.output_type == "text" && (

                        <Grid item xs={12}>
                            <TextField
                                value={payloadResponse.value}
                                multiline
                                fullWidth
                                minRows={10} />
                        </Grid>

                    )}
                    {payloadResponse && payloadResponse.output_type && payloadResponse.output_type == "link" && (

                        
                    <Grid item xs={12}>
                        <MessageLink message={payloadResponse.value} />
                    </Grid>

                    )}
                    {payloadResponse && payloadResponse.output_type && payloadResponse.output_type == "json" && (

                    <Grid item xs={12}>
                        <JsonView src={payloadResponse.value} />
                    </Grid>

                    )}
                </Grid>
                <Typography>Testing Pane Here.</Typography>
            </Grid>
        </Grid>
    )
}

export default FinalizePromptOperation;
