import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faExpand } from "@fortawesome/pro-duotone-svg-icons";
import JsonView from "react18-json-view";

function QbResults({ allResults, copyToClipboard }) {

    return (
        <Grid item xs={12}>
        <Typography variant="h6">Results</Typography>
        
        {allResults &&
            allResults.map((result, index) => (
                <div key={index}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faExpand} />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>{result.stepId}</Typography>
                            <FontAwesomeIcon icon={faCopy} onClick={(e) => copyToClipboard(result.stepId)} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <JsonView src={JSON.parse(result.jsonLine)} />
                        </AccordionDetails>
                    </Accordion>
                </div>
            ))
        }
    </Grid>
    );

}
export default QbResults;