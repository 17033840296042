import React, { useRef, useEffect, useState } from 'react';
import { Box, FormControl, OutlinedInput, IconButton, InputAdornment, Paper, Tooltip } from '@mui/material';
import { Send } from '@mui/icons-material';
import { useTheme, styled, keyframes } from '@mui/material/styles';
import { useHubConnections } from '../../utils/HubConnectionsProvider';
import { useChatConnections } from '../../utils/ChatConnectionProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleStop } from '@fortawesome/free-solid-svg-icons';

// Define keyframes for the slow beat fade animation
const slowBeatFadeKeyframes = keyframes`
  0%, 100% { opacity: 1; transform: scale(1); }
  50% { opacity: 0.5; transform: scale(1.1); }
`;

const SlowBeatFadeIcon = styled(FontAwesomeIcon)`
  animation: ${slowBeatFadeKeyframes} 2.5s infinite; /* Adjust the duration to 2.5 seconds */
`;


const ChatBox = React.forwardRef(({
  newMessage,
  handleSendMessage,
  setNewMessage,
  isChatBoxActive,
  hitchUser,
  leftConversationDrawerOpen,
  isMobile,
  isInChatDrawer,
  drawerHeight,
  maxWidth,
  setMessages,
}, ref) => {
  const theme = useTheme();
  const themeBackgroundColor = theme.palette.primary[theme.palette.mode];
  const themeBackgroundTextColor = theme.palette.primaryText[theme.palette.mode];
  const themePrimaryButtonColor = theme.palette.primaryButton[theme.palette.mode];
  const themePrimaryBorderColor = theme.palette.primaryBorder[theme.palette.mode];
  const themeIconDisabledColor = theme.palette.iconDisabled[theme.palette.mode];
  const themeIconActiveColor = theme.palette.iconActive[theme.palette.mode];
  const themeTextboxOutline = theme.palette.textboxOutline[theme.palette.mode];
  const inputRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { infoSnack } = useHubConnections();
  const { chatBusy, chatConnection, isTyping, hitchCommand } = useChatConnections(); // Access isTyping and hitchCommand from context

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMessageEmpty = !newMessage.content;

  const parsedMaxWidth = typeof maxWidth === 'string' ? parseInt(maxWidth, 10) : NaN;
  const maxWidthValue = parseInt(maxWidth, 10);

  const chatBoxBottom = isInChatDrawer ? `calc(100vh - ${drawerHeight} - 4vh)` : '56px';
  let calculatedLeft, calculatedRight;

  if (isInChatDrawer && windowWidth > maxWidthValue) {
    const excessWidth = windowWidth - maxWidthValue + 88;
    calculatedLeft = calculatedRight = `${(excessWidth / 2)}px`;
  } else {
    calculatedLeft = isMobile ? '8px' : (leftConversationDrawerOpen ? '392px' : '120px');
    calculatedRight = isMobile ? '8px' : '32px';
  }

  const chatBoxStyle = {
    position: 'fixed',
    bottom: chatBoxBottom,
    backgroundColor: themeBackgroundColor,
    padding: isMobile ? '0px 4px 0px 8px' : '0px 12px 0px 12px',  // Adjust padding based on screen size
    borderRadius: '10px',
    border: `1px solid ${themePrimaryBorderColor}`,
    width: 'auto',
    maxWidth: 'maxWidth',
    left: `calc(${calculatedLeft} + 24px)`,
    right: `calc(${calculatedRight} + 24px)`,
    zIndex: 1000,
    '&:hover, &:focus-within': {
      borderColor: theme.palette.textboxOutline.hover[theme.palette.mode],
    },
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isTyping && !isMessageEmpty) {
      handleSendMessage(newMessage);
    }
  };

  const handleStopTyping = () => {
    console.log('hehehehehehehhehe');
    infoSnack('HELLO');

    if(chatConnection) {
      hitchCommand('chat', 'conversation', 'cancel');
    }


  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      setNewMessage(prevMessage => ({
        ...prevMessage,
        content: prevMessage.content + '\n'
      }));
    } else if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!isTyping && !isMessageEmpty) {
        handleSendMessage(newMessage);
      }
    }
  };

  useEffect(() => {
    if (isChatBoxActive && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isChatBoxActive]);

  return (
    <Box ref={ref} sx={chatBoxStyle}>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <FormControl fullWidth={true}>
          <OutlinedInput
            placeholder="How can Hitchy help you?"
            style={{
              width: '100%',
              color: themeBackgroundTextColor,
              fontFamily: '"Poppins", sans-serif',
              padding: '12px 4px',
            }}
            id="chat-box"
            autoFocus={isChatBoxActive}
            multiline={true}
            maxRows={5}
            value={newMessage.content}
            onChange={(e) => {
              const newMessageObject = {
                content: e.target.value,
                contentType: 'string',
                user: hitchUser?.id
              };
              setNewMessage(newMessageObject);
            }}
            onKeyDown={handleKeyDown}
            endAdornment={
              <InputAdornment position='end'>
                {!isTyping ? (
                  <Tooltip title="Submit prompt">
                    <IconButton
                      type="submit"
                      sx={{
                        color: isMessageEmpty ? themeIconDisabledColor : themePrimaryButtonColor,
                        marginRight: '-4px',
                        marginLeft: '-4px',
                        '&:hover': {
                          backgroundColor: isMessageEmpty ? 'transparent' : 'rgba(114, 216, 197, 0.1)',
                          color: isMessageEmpty ? themeIconDisabledColor : themePrimaryButtonColor,
                        },
                        '&:active': {
                          backgroundColor: isMessageEmpty ? 'transparent' : 'rgba(114, 216, 197, 0.2)',
                          color: isMessageEmpty ? themeIconDisabledColor : themeIconActiveColor,
                        },
                        '&.Mui-disabled': {
                          color: themeIconDisabledColor,
                        },
                      }}
                      disabled={isMessageEmpty}
                    >
                      <Send />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Stop typing">
                    <IconButton
                      onClick={handleStopTyping}
                      sx={{
                        color: '#FF6347', // Tomato
                        '&:hover': {
                          backgroundColor: 'rgba(255, 99, 71, 0.1)', // Lighter Tomato
                          color: '#FF4500', // OrangeRed
                        },
                        '&:active': {
                          backgroundColor: 'rgba(255, 69, 0, 0.2)', // More intense OrangeRed
                          color: '#FF4500', // OrangeRed
                        },
                      }}
                    >
                      <SlowBeatFadeIcon icon={faCircleStop} />
                    </IconButton>
                  </Tooltip>
                )}
              </InputAdornment>
            }
            inputRef={inputRef}
            sx={{
              background: themeBackgroundColor,
              color: themeBackgroundTextColor,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& textarea': {
                resize: 'vertical-reverse',
              },
            }}
          />
        </FormControl>
      </form>
    </Box>
  );
});

export default ChatBox;
