import React from 'react';
import { useTheme } from '@mui/material/styles';

function FooterHitchAI() {

    const theme = useTheme();
    const themeBackgroundColor = theme.palette.secondary[theme.palette.mode];
    const themeTextColor = theme.palette.primaryText[theme.palette.mode];

    const footerStyle = {
        backgroundColor: themeBackgroundColor,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: '12px', // Adjust as needed
        zIndex: 1100, // Ensure lower z-index
    };

    const aiTextStyle = {
        fontSize: '16px', // Larger font size for '.AI'
        fontWeight: '100',
        verticalAlign: 'middle', // Aligns logo with the text
        color: themeTextColor, // This will change the color of SVG logo to white
        opacity: 0.3, // Adjust for watermark effect
        fontFamily: 'BenchNine, sans-serif', // Apply BenchNine font
    };

    const logoStyle = {
        width: '35.5px',
        height: '35.5px',
        marginRight: '0px',
        verticalAlign: 'middle', // Aligns logo with the text
        marginTop: '-2px', // You can also use a negative margin to lift the logo up
        color: themeTextColor, // This will change the color of SVG logo to white
        opacity: 0.3, // Adjust for watermark effect

    };

    const watermarkStyle = {
        color: themeTextColor, // This will change the color of SVG logo to white
        opacity: 0.3, // Adjust for watermark effect
        fontSize: '12px',
        display: 'inline' // Ensure it's inline with the logo
    };

    return (
        <footer style={footerStyle} className="App-footer">
            <p>
            <span style={watermarkStyle}>powered by Hitch.AI</span>
            </p>
        </footer>
    );
}

export default FooterHitchAI;
