import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import tipsData from './WelcomeOnboardingTips.json';
import HomeIcon from '@mui/icons-material/Home';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import DoneIcon from '@mui/icons-material/Done';

const iconMap = {
  HomeIcon: HomeIcon,
  SecurityIcon: SecurityIcon,
  SettingsIcon: SettingsIcon,
  DoneIcon: DoneIcon,
};

function SplashScreenWithSlideInTips() {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentTipIndex, setCurrentTipIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => setCurrentStep(1), 4000); // Show welcome message for 4 seconds
    setTimeout(() => setCurrentStep(2), 8000); // Show intro message for another 4 seconds
  }, []);

  useEffect(() => {
    if (currentStep === 2) {
      const autoAdvance = setInterval(() => {
        setCurrentTipIndex((prevIndex) => (prevIndex + 1) % tipsData.length);
      }, 5000); // Auto-advance tips every 5 seconds

      const timeout = setTimeout(() => {
        setCurrentStep(3); // Move to the final message
        setTimeout(() => {
          navigate('/app'); // Redirect to /app after final message
        }, 4000); // Show final message for 4 seconds
      }, 15000); // Show tips for 15 seconds total

      return () => {
        clearInterval(autoAdvance);
        clearTimeout(timeout);
      };
    }
  }, [currentStep, navigate]);

  const renderContent = () => {
    if (currentStep === 0) {
      return (
        <>
          <Typography variant="h3" sx={welcomeTextStyle}>
            Welcome to Hitch.AI
          </Typography>
        </>
      );
    } else if (currentStep === 1) {
      return (
        <>
          <Typography variant="h4" sx={introTextStyle}>
            We're setting things up. <br /> Here's what you can expect...
          </Typography>
        </>
      );
    } else if (currentStep === 2 && tipsData.length > 0) {
      const { icon, message } = tipsData[currentTipIndex];
      const TipIcon = iconMap[icon];

      return (
        <Box sx={slideInContainer}>
          <TipIcon sx={{ fontSize: 50, color: '#00EDED', marginBottom: '16px' }} />
          <Typography variant="h5" sx={tipTextStyle}>
            {message}
          </Typography>
        </Box>
      );
    } else if (currentStep === 3) {
      return (
        <Box sx={finalStepContainer}>
          <CheckCircleIcon sx={{ fontSize: 80, color: '#00EDED', marginBottom: '20px' }} />
          <Typography variant="h4" sx={finalStepTextStyle}>
            You're all set! Let's get started!
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={modalContainer}>
      <Box sx={modalOverlay}>
        <Box sx={contentBox}>{renderContent()}</Box>
      </Box>
    </Box>
  );
}

// Styles for different parts of the modal
const modalContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 9999,
  overflow: 'hidden',
};

const modalOverlay = {
  backgroundColor: 'rgba(0, 0, 0, 0.85)', // Dark semi-transparent background
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const contentBox = {
  textAlign: 'center',
  color: '#fff',
  padding: '20px',
  borderRadius: '10px',
};

const welcomeTextStyle = {
  fontFamily: 'Poppins, sans-serif',
  fontSize: '36px',
  fontWeight: 600,
  letterSpacing: '1px',
  animation: 'fadeIn 1s ease-in-out',
};

const introTextStyle = {
  fontFamily: 'Roboto, sans-serif',
  fontSize: '28px',
  fontWeight: 400,
  animation: 'fadeIn 1s ease-in-out',
};

const tipTextStyle = {
  fontFamily: 'Roboto, sans-serif',
  fontSize: '22px',
  fontWeight: 400,
  marginTop: '20px',
  animation: 'slideIn 0.5s ease-in-out',
};

const slideInContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  animation: 'slideIn 0.5s ease-in-out',
};

const finalStepContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  animation: 'fadeIn 1s ease-in-out',
};

const finalStepTextStyle = {
  fontFamily: 'Roboto, sans-serif',
  fontSize: '28px',
  fontWeight: 500,
  marginTop: '20px',
  animation: 'fadeIn 1s ease-in-out',
};

// Keyframes for animations
const globalStyles = `
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  @keyframes slideIn {
    0% { transform: translateX(100%); }
    100% { transform: translateX(0); }
  }
`;

// Inject global styles for animations
document.head.insertAdjacentHTML(
  'beforeend',
  `<style>${globalStyles}</style>`
);

export default SplashScreenWithSlideInTips;
