import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import './CancelPrompt.css'; // Make sure to create this CSS file

const dialogStyle = {
  color: 'white', // Set the text color to white
  border: '2px solid white', // Add a white border to make it look like a pop-up
  borderRadius: '8px', // Add some border radius for rounded corners
};

const paperPropsStyle = {
  backgroundColor: '#0D0D0D',
  color: 'white',
  borderRadius: '10px',
  border: '1px solid white',
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  maxWidth: '600px', // Adjust this value as needed
  width: '80%', // Adjust as needed, or remove if not needed
  margin: 'auto', // Centers the dialog
  position: 'relative' // Helps in positioning
};

const innerDivStyle = {
  padding: '20px',
  backgroundColor: '#0D0D0D',
  color: 'white'
};

const dialogTitleStyle = {
  background: '#0D0D0D', // Set the background color of the content area to black
  color: 'white', // Set the text color to white
  fontFamily: '"Poppins", sans-serif',
};


const dialogContentStyle = {
  background: '#0D0D0D', // Set the background color of the content area to black
  color: 'white', // Set the text color to white
};

const dialogContentText = {
  color: '#fff', // Set the text color to white
  fontFamily: '"Poppins", sans-serif',
};

const dialogActionsStyle = {
  justifyContent: 'center',
  padding: '8px',
  background: '#0D0D0D', // Set the background color of the title area to black
};


const buttonStyle = {
  borderRadius: '24px',
  height: '36px',
  padding: '4px 8px',
  fontSize: '0.7rem',
  fontFamily: '"Poppins", sans-serif',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  maxWidth: '150px', // Maximum width for the button
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginTop: '0px',
  marginBottom: '16px',
};

function CancelPrompt({ open, onClose, onConfirm }) {

  const buttonBaseStyle = {
    borderRadius: '24px',
    height: '36px',
    padding: '0px 0px', // Reduced padding
    fontSize: '0.7rem', // Slightly smaller font size
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    maxWidth: '144px', // Maximum width for the button
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // ... other styles
  };

  const keepEditingButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#00EDED',
    color: 'black',
    cursor: 'pointer',
    border: '2px solid #00EDED',
    minWidth: '144px', // Adjust the minimum width as needed
    whiteSpace: 'nowrap', // Prevent text wrapping
    overflow: 'hidden', // Hide overflowed text
    textOverflow: 'ellipsis', // Show ellipsis if text overflows
  };

  const cancelButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#555', // Use a darker background color
    color: 'white', // Set the text color to white
    border: '1px solid #888', // Add a subtle border
    minWidth: '144px', // Adjust the minimum width as needed
    whiteSpace: 'nowrap', // Prevent text wrapping
    overflow: 'hidden', // Hide overflowed text
    textOverflow: 'ellipsis', // Show ellipsis if text overflows
  };

  const handleConfirm = () => {
    // Perform any additional logic here before navigating
    // For example, you can add an API call or cleanup logic

    // Navigate to '/'
    window.location.href = '/';
  };

  return (
    <Dialog open={open} 
    onClose={onClose} 
    aria-labelledby="cancel-dialog-title" 
    PaperProps={{ style: paperPropsStyle }}
    
    >
      <div style={innerDivStyle}>
    
    <DialogTitle id="cancel-dialog-title" style={dialogTitleStyle}>Confirm Cancellation</DialogTitle>
   
      <DialogContent style={dialogContentStyle}>
        <DialogContentText style={dialogContentText}>
          Are you sure you want to cancel? All unsaved changes will be lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions style={dialogActionsStyle}>
        <Button onClick={handleConfirm} style={cancelButtonStyle}>
          Yes, Cancel
        </Button>
        <Button onClick={onClose} style={keepEditingButtonStyle}>
          No, Keep Editing
        </Button>
      </DialogActions>
      </div>
    </Dialog>
  );
}

export default CancelPrompt;
