import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    List,
    Typography,
    InputAdornment,
    OutlinedInput,
    Divider
} from '@mui/material';
import ConversationHistoryV2 from './ConversationHistoryV2';
import SearchIcon from "@mui/icons-material/Search";
import FileListV2 from './FileListV2';
import MiniVariantDrawerV2 from './MiniVariantDrawerV2';
import RefreshButton from './RefreshButton'; // Import the RefreshButton component

const drawerWidth = 275;
const miniDrawerWidth = 56;

function LeftConversationDrawerV2({
    onClose,
    open,
    setOpen,
    tabValueV2,
    setTabValueV2,
    conversationHistory,
    focusChatBoxInput,
    deleteConversation,
    redirectPath,
    selectedChat,
    setName = setName
}) {
    const theme = useTheme();
    const isTabletMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const themeBackgroundColor = theme.palette.chatLeftMenuBackground[theme.palette.mode];
    const themeTextColor = theme.palette.chatLeftMenuText[theme.palette.mode];
    const themeFontFamily = theme.palette.typography.fontFamilyMain;
    const newChat = useNavigate();
    const [searchInput, setSearchInput] = useState('');
    const headerHeight = isMobile ? 56 : isTablet ? 65 : 64;
    const navigate = useNavigate();

    useEffect(() => {
        setOpen(open);
    }, [open, setOpen]);

    const handleTabChange = (newValue) => {
        // console.log('Tab changed to:', newValue);
        if (tabValueV2 === newValue) {
            setOpen(false);
            setTabValueV2('none'); // Close the drawer
        } else {
            setOpen(true);
            setTabValueV2(newValue); // Switch the view
        }
    };

    const handleNewChat = () => {
        newChat(redirectPath);
        focusChatBoxInput();
        if (isTabletMobile) onClose();
    };

    const handleFocusChatBox = () => {
        focusChatBoxInput();
    };

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const filteredConversationHistory = conversationHistory.filter((item) =>
        item?.text?.toLowerCase()?.includes(searchInput.toLowerCase())
    );

    const handleRefreshClick = () => {
        navigate('/purchase-refresh');
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <MiniVariantDrawerV2
                open={true}
                onSelect={handleTabChange}
                onNewChat={handleNewChat}
                drawerOpen={open}
            />
            <Drawer
                variant={isTabletMobile ? 'temporary' : 'persistent'}
                open={open}
                onClose={onClose}
                ModalProps={{ keepMounted: true }}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        backgroundColor: themeBackgroundColor,
                        top: `${headerHeight}px`,
                        overflow: "hidden",
                        marginLeft: `${miniDrawerWidth}px`,
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        backgroundColor: themeBackgroundColor,
                    }}
                >
                    <Box sx={{ flex: 1, overflowY: 'auto', paddingX: '16px', paddingY: '8px' }}>
                        {tabValueV2 === 'history' && (
                            <List style={{ marginTop: "0px" }}>
                                <Typography variant="h7" sx={{ fontFamily: themeFontFamily, color: themeTextColor }}>
                                    Chat History
                                </Typography>

                                <OutlinedInput
                                    placeholder="Search History"
                                    fullWidth
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon
                                                sx={{
                                                    color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.54)' : '#808080'
                                                }}
                                            />
                                        </InputAdornment>
                                    }
                                    sx={{
                                        fontFamily: themeFontFamily,
                                        color: themeTextColor,
                                        height: '40px',
                                        marginTop: '8px',
                                        borderRadius: 0,
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderTop: theme.palette.mode === 'dark' ? '2px solid #333333' : '1px solid #D9D9D9',
                                            borderBottom: theme.palette.mode === 'dark' ? '2px solid #333333' : '1px solid #D9D9D9',
                                            borderLeft: theme.palette.mode === 'dark' ? '0px solid #333333' : '0px solid #D9D9D9',
                                            borderRight: theme.palette.mode === 'dark' ? '0px solid #333333' : '0px solid #D9D9D9'
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderLeft: theme.palette.mode === 'dark' ? '1px solid #00EDED' : '1px solid #00EDED',
                                            borderRight: theme.palette.mode === 'dark' ? '1px solid #00EDED' : '1px solid #00EDED',
                                            borderTop: theme.palette.mode === 'dark' ? '1px solid #00EDED' : '1px solid #00EDED',
                                            borderBottom: theme.palette.mode === 'dark' ? '1px solid #00EDED' : '1px solid #00EDED',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderLeft: theme.palette.mode === 'dark' ? '2px solid #00EDED' : '2px solid #00EDED',
                                            borderRight: theme.palette.mode === 'dark' ? '2px solid #00EDED' : '2px solid #00EDED',
                                            borderTop: theme.palette.mode === 'dark' ? '2px solid #00EDED' : '2px solid #00EDED',
                                            borderBottom: theme.palette.mode === 'dark' ? '2px solid #00EDED' : '2px solid #00EDED',
                                        },
                                        '& .MuiInputAdornment-positionStart:not(:first-child)': {
                                            marginRight: '8px',
                                        },
                                        '& .MuiInputAdornment-positionStart': {
                                            marginLeft: '0px',
                                        },
                                    }}
                                    onChange={handleSearchInputChange}
                                />

                                <div
                                    style={{
                                        padding: "0 4px 4px 4px",
                                        marginBottom: "16px",
                                        color: "white",
                                        textAlign: "center",
                                        overflowY: 'hidden'
                                    }}
                                >
                                    <ConversationHistoryV2
                                        redirectPath={redirectPath}
                                        conversationHistory={filteredConversationHistory}
                                        deleteConversation={deleteConversation}
                                        selectedChatId={selectedChat}
                                        onClose={onClose}
                                        setName={setName}
                                    />
                                </div>
                                <RefreshButton onClick={handleRefreshClick} /> {/* Add RefreshButton here */}
                            </List>
                        )}
                        {tabValueV2 === 'files' && (
                            <FileListV2 selectedChat={selectedChat} />
                        )}
                    </Box>
                    <Divider />
                </Box>
            </Drawer>
        </Box>
    );
}

export default LeftConversationDrawerV2;
