import { faExpand } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import FeatureSearchDetail from "./FeatureSearchDetail";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons";

function FeatureSearch({ account, connection, feature, featureSearchResults, expanded, handleChange, hubConnection, service, sessionId, setTheFeature }) {
    return (
        <>
        {service !== null && (
        <Accordion
            expanded={expanded === 'featurePanel'}
            onChange={handleChange('featurePanel')}>
            <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faExpand} />}
                area-controls="panelh-feature"
                id="panelh-feature"
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Feature Selection
                </Typography>
                <Typography sx={{ color: 'text.primary' }}>
                    {feature === null ? 'No Feature Selected' : (
                        <>
                            {feature.name} <FontAwesomeIcon icon={faTrash} onClick={(e) => setTheFeature(null)} />
                        </>
                    )}
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    backgroundColor: "#EEEEFF",
                    marginTop: "0px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    color: "#333333"
                }}>
                <Typography>
                    <FeatureSearchDetail
                        account={account}
                        connection={connection}
                        featureSearchResults={featureSearchResults}
                        hubConnection={hubConnection}
                        service={service}
                        sessionId={sessionId}
                        setTheFeature={setTheFeature} />
                </Typography>
            </AccordionDetails>
        </Accordion>)}
        </>
    )
}

export default FeatureSearch;