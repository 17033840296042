import { Grid, Typography } from "@mui/material";
import RunIfStep from "./RunIfStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/pro-duotone-svg-icons";

function RunIfSteps({ addIf, handleAliasChange, handleExpressionChange, removeStep, runIfTrue }) {

    return (
        <>
        <Grid item xs={12}>
            <Typography variant="h6">Run if...</Typography>
        </Grid>
        <Grid item xs={12}>
            {runIfTrue && runIfTrue.map((step, index) => (
                <div key={index}>
                    <RunIfStep
                        transformerStep={step}
                        onAliasChange={(value) => handleAliasChange(index, value)}
                        onExpressionChange={(value) => handleExpressionChange(index, value)}
                        removeStep={() => removeStep(index)}
                    />
                </div>
            ))}
            <FontAwesomeIcon icon={faAdd} onClick={addIf} />
        </Grid>
        </>
    );

}
export default RunIfSteps;