// Header.js
import React from 'react';
import { Button } from "@mui/material";

const Header = ({ openChat }) => {
    return (
        <header className="header">
            <h1>Virtual Sales</h1>
            <Button variant="contained" onClick={openChat}>Open Chat</Button>
        </header>
    );
}

export default Header;
