import { faFeather } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, Button, ButtonGroup, Divider, Grid, Tooltip, Typography } from "@mui/material";

function SelectFeature({ availableFeatures, submitFeatureId }) {

    function sFeature(featureId) {
        console.log('featureId', featureId);
        submitFeatureId(featureId);
    }

    return (
        <Box>
            <Typography>Select A Feature</Typography>
            <Divider />

            {/* Grid container with centered content */}
            <Grid container spacing={2} justifyContent="center">
                <ButtonGroup>
                {availableFeatures && availableFeatures.map((svc) => (
                    <Button 
                        variant="outlined" 
                        startIcon={<FontAwesomeIcon icon={faFeather} />}
                        onClick={() => sFeature(svc.id)}
                        key={svc.id}>
                        {svc.name}
                    </Button>
                ))}
                </ButtonGroup>
            </Grid>
        </Box>
    );
}

export default SelectFeature;
