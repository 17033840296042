import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Grid, Typography } from '@mui/material';

const EditableField = ({ initialValue, onSave, label }) => {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(initialValue);

    const handleStartEditing = () => {
        setEditing(true);
    };

    const handleSave = () => {
        onSave(value);
        setEditing(false);
    };

    const handleCancel = () => {
        setValue(initialValue);
        setEditing(false);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    if (editing) {
        return (
            <Grid container>
                <Grid item><Typography>{label}: </Typography></Grid>
                <Grid item ml={2}><TextField
                    value={value}
                    onChange={handleChange}
                    onBlur={handleSave}
                    autoFocus
                    fullWidth
                    variant='outlined'
                    inputProps={{
                        style: { fontSize: 'inherit', padding: '5px 0', color: 'white' }, // Set text color to white
                    }}
                /></Grid>
            </Grid>

        );
    }

    return (
        <div onClick={handleStartEditing}>
            <Grid container>
                <Grid item><Typography>{label}: </Typography></Grid>
                <Grid item ml={2}>{value}</Grid>
            </Grid>
            
        </div>
    );
};

export default EditableField;
