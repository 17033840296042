import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, IconButton, Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useAdminHubConnections } from '../../utils/HubAdminConnectionProvider';
import { useHubConnections } from '../../utils/HubConnectionsProvider';

function UserTenantRecords({ user, editMode, loading, setLoading }) {
    const { adminConnection, hitchUser, isConnectionReady } = useAdminHubConnections();
    const {         
        defaultSnack,
        infoSnack,
        warningSnack,
        successSnack,
        errorSnack, 
    } = useHubConnections();

    useEffect(() => {
        if (adminConnection) {
            adminConnection.on('IdentityRecords', (data) => {
                console.log('IdentityRecords', data);
                setTenants(data);
                setLoading(false);
            });

            getTenantUsers();
            return () => {
                adminConnection.off('IdentityRecords');
            };
        }
    }, [adminConnection]);

    const [tenants, setTenants] = useState([]);
    const [expanded, setExpanded] = useState({});

    function getTenantUsers() {
        adminConnection.invoke("GetUserIdentityRecords", hitchUser.userId, user.id)
            .then(() => {
                console.log('i asked for Tenant Users');
                setLoading(true);
            })
            .catch(error => console.error("Error sending message:", error));
    }

    useEffect(() => {
        // Use static dummy data for now
        const dummyTenants = [
            {
                id: "1",
                created: "2024-01-01",
                updated: "2024-01-02",
                email: "tenantuser1@example.com",
                lastLoginDate: "2024-01-02",
                userName: "tenantuser1",
                passwordHash: "hash1",
                securityStamp: "stamp1",
                discriminator: "discriminator1",
                legacyPasswordHash: "legacyHash1",
                loweredUserName: "lowereduser1",
                isAnonymous: false,
                lastActivityDate: "2024-01-03",
                mobilePIN: "1234",
                loweredEmail: "tenantuser1@example.com",
                passwordQuestion: "Question?",
                passwordAnswer: "Answer",
                isApproved: true,
                isLockedOut: false,
                createDate: "2024-01-01",
                lastPasswordChangedDate: "2024-01-02",
                lastLockoutDate: null,
                failedPasswordAttemptCount: 0,
                failedPasswordAttemptWindowStart: null,
                failedPasswordAnswerAttemptCount: 0,
                failedPasswordAnswerAttemptWindowStart: null,
                comment: "Comment here",
                temporaryBearerToken: null,
                firstName: "Tenant",
                lastName: "User",
                displayName: "Tenant User One"
            },
            {
                id: "2",
                created: "2024-02-01",
                updated: "2024-02-02",
                email: "tenantuser2@example.com",
                lastLoginDate: "2024-02-02",
                userName: "tenantuser2",
                passwordHash: "hash2",
                securityStamp: "stamp2",
                discriminator: "discriminator2",
                legacyPasswordHash: "legacyHash2",
                loweredUserName: "lowereduser2",
                isAnonymous: false,
                lastActivityDate: "2024-02-03",
                mobilePIN: "5678",
                loweredEmail: "tenantuser2@example.com",
                passwordQuestion: "Question?",
                passwordAnswer: "Answer",
                isApproved: true,
                isLockedOut: false,
                createDate: "2024-02-01",
                lastPasswordChangedDate: "2024-02-02",
                lastLockoutDate: null,
                failedPasswordAttemptCount: 0,
                failedPasswordAttemptWindowStart: null,
                failedPasswordAnswerAttemptCount: 0,
                failedPasswordAnswerAttemptWindowStart: null,
                comment: "Another comment",
                temporaryBearerToken: null,
                firstName: "Tenant",
                lastName: "User",
                displayName: "Tenant User Two"
            }
        ];
        setTenants(dummyTenants);
    }, []);

    const handleExpandClick = (id) => {
        setExpanded((prevExpanded) => ({
            ...prevExpanded,
            [id]: !prevExpanded[id]
        }));
    };

    return (
        <Box>
            {tenants.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Updated</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Last Login Date</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tenants.map((tenant) => (
                                <React.Fragment key={tenant.id}>
                                    <TableRow>
                                        <TableCell sx={{ padding: '4px 8px' }}>{tenant.id}</TableCell>
                                        <TableCell sx={{ padding: '4px 8px' }}>{tenant.created}</TableCell>
                                        <TableCell sx={{ padding: '4px 8px' }}>{tenant.updated}</TableCell>
                                        <TableCell sx={{ padding: '4px 8px' }}>{tenant.email}</TableCell>
                                        <TableCell sx={{ padding: '4px 8px' }}>{tenant.lastLoginDate}</TableCell>
                                        <TableCell sx={{ padding: '4px 8px' }}>
                                            <IconButton
                                                onClick={() => handleExpandClick(tenant.id)}
                                                aria-expanded={expanded[tenant.id]}
                                                aria-label="show more"
                                            >
                                                {expanded[tenant.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ padding: 0 }} colSpan={6}>
                                            <Collapse in={expanded[tenant.id]} timeout="auto" unmountOnExit>
                                            <Box margin={0} sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 1 }}>
                                                    <Grid container spacing={2}>
                                                        {Object.keys(tenant).map((key) => (
                                                            !['id', 'created', 'updated', 'email', 'lastLoginDate'].includes(key) && (
                                                                <Grid item xs={12} sm={6} key={key}>
                                                                    <Typography variant="body2"><strong>{key}:</strong> {tenant[key]}</Typography>
                                                                </Grid>
                                                            )
                                                        ))}
                                                    </Grid>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>No Records to Display</Typography>
            )}
        </Box>
    );
}

export default UserTenantRecords;
