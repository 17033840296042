import { Chip, Divider, Grid, Typography } from '@mui/material';
import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

export default memo(({ data, isConnectable }) => {
    return (
        <>
            <Handle
                type="target"
                position={Position.Left}
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <Grid container spacing={0} style={{ backgroundColor: "#FF0000", minWidth: "200px", minHeight: "50px" }}>
                <Grid item xs={12}>
                    <Grid container style={{ backgroundColor: "#000000", color: "#FFFFFF" }} >
                        <Grid item xs={3} />
                        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography variant='body2'>{data.title}</Typography>
                        </Grid>
                        <Grid item xs={3} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={0} style={{ backgroundColor: "#FFFFFF", color: "#000000", display: 'flex', justifyContent: 'center' }} >
                        <Grid item style={{ display: 'flex', justifyContent: 'center', padding: 8 }}>
                            <Chip color="success" label={data.version} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


            <Handle
                type="source"
                position={Position.Right}
                id="a"
                style={{ top: 10, background: '#555' }}
                isConnectable={isConnectable}
            />
            <Handle
                type="source"
                position={Position.Right}
                id="b"
                style={{ bottom: 10, top: 'auto', background: '#555' }}
                isConnectable={isConnectable}
            />
        </>
    );
});
