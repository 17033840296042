// AddPlanStyles.jsx
export const modalContentStyle = {
    overflowY: 'none',
    maxHeight: '80vh',
    backgroundColor: '#000',
    marginTop: '-12px'
};

export const arrowIconStyle = (isSelected) => {
    return {
        color: isSelected ? 'black' : 'black',
        fontSize: '1em',
    };
};

export const buttonStyle = (plan, selectedPlanId) => {
    const isSelected = selectedPlanId === plan.id;
    const isLocked = plan.isLocked;
    // console.info('selectedPlanId', selectedPlanId);
    // console.info('plan.id', plan.id);
    // console.info('isSelected', isSelected);
    if (plan.isCustom) {
        return {
            backgroundColor: isSelected ? 'white' : 'transparent',
            borderColor: isSelected ? 'white' : '#ccc',
            color: isSelected ? 'black' : 'white',
        };
    }
    if (plan.mustContact) {
        return {
            backgroundColor: isSelected ? 'white' : 'transparent',
            borderColor: isSelected ? 'white' : '#ccc',
            color: isSelected ? 'black' : 'white',
        };
    }
    if (plan.isCurrent) {
        return {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            color: 'transparent',
        };
    } else {
        return {
            backgroundColor: isSelected ? '#00eded' : '#00eded',
            borderColor: isSelected ? '#00eded' : '#00eded',
            color: isSelected ? 'black' : 'black',
        };
    }
};

// Define the base styles for the buttons
const baseButtonStyle = {
    borderRadius: '24px',
    height: '36px',
    padding: '4px 10px',
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    width: '144px',
    marginTop: '36px',
    marginBottom: '-8px',
    fontFamily: '"Poppins", sans-serif',
};

// Define styles for the primary button
export const primaryButtonStyle = {
    ...baseButtonStyle,
    backgroundColor: '#00eded',
    color: 'black',
    cursor: 'pointer',
};

// Define styles for the secondary button
export const secondaryButtonStyle = {
    ...baseButtonStyle,
    backgroundColor: '#000',
    borderColor: 'white',
    color: 'white',
};
