import React, { useState } from 'react';
import { Tooltip, Menu, MenuItem, IconButton } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faDownload, faTrash, faPen } from "@fortawesome/pro-solid-svg-icons"; // Import the new icon
import { useTheme } from '@mui/material/styles';

const icons = {
  faDownload, // Add the new icon here
  faTrash,
  faPen
};

function PopupMenu({ item, onAction, menuOptions }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, action) => {
    event.stopPropagation();
    onAction(action, item);
    handleClose(event);
  };

  const themeModalBackgroundColor = theme.palette.modalBackground[theme.palette.mode];
  const themeModalTextPrimaryColor = theme.palette.modalTextPrimary[theme.palette.mode];


  const menuStyle = {
    background: themeModalBackgroundColor,
    color: themeModalTextPrimaryColor,
    border: '1px solid #2A2E39',
    borderRadius: '10px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.6)', // Subtle shadow for depth
    p: 4,
    zIndex: 2501, // Ensure the menu appears above other elements
  };

  const menuItemStyle = {
    fontSize: '16px',
    fontFamily: '"Poppins", sans-serif',
  };

  const deleteMenuItemStyle = {
    ...menuItemStyle,
    color: '#EF2E3D', // Adjust the red color
  };

  return (
    <div>
      <IconButton
        size="small"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          zIndex: 2505,
          marginLeft: 'auto',
          marginRight: '8px',
          '& svg': {
            fontSize: 16,
            color: 'grey',
            '&:hover': {
              color: '#00EDED',
              transform: 'scale(1.2)',
            },
            '&:active': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              borderRadius: '50%',
              transform: 'scale(1.1)',
              boxShadow: '0 0 8px rgba(255, 255, 255, 0.6)',
            },
          }
        }}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 2501 }} // Apply zIndex here
        PaperProps={{ style: menuStyle }}
      >
        {menuOptions.map((option, index) => (
          <MenuItem
            key={index}
            onClick={(event) => handleMenuItemClick(event, option.action)}
            sx={{
              ...(option.action === 'delete' ? deleteMenuItemStyle : menuItemStyle),
              '&:hover': {
                backgroundColor: '#303336' // Keep a consistent hover background color
              },
            }}
          >
            <FontAwesomeIcon icon={icons[option.icon]} style={{ marginRight: '16px' }} />
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default PopupMenu;
