import { Divider, Grid, IconButton, TextField, Button } from "@mui/material";
import React, { useEffect } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

function SkuGrid({ skus, handleAddSku, handleRemoveSku, handleSkuChange, handleSaveSku }) {
    
    useEffect(() => {
        if (skus) {
            console.log('skus', skus);
        }
    }, [skus]);
    
    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                {skus?.map((sku, index) => (
                    <React.Fragment key={sku.id}>
                        <Divider />
                        <Grid item xs={4}>
                            <TextField
                                label="Original SKU Number"
                                fullWidth
                                value={sku.sku.skuNumber}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label="Name"
                                fullWidth
                                value={sku?.name}
                                onChange={(e) => handleSkuChange(index, 'name', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Override Price"
                                fullWidth
                                value={sku?.overridePrice}
                                onChange={(e) => handleSkuChange(index, 'overridePrice', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={() => handleRemoveSku(sku)}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton onClick={() => handleSaveSku(sku)}>
                                <SaveIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Original Name"
                                fullWidth
                                value={sku.sku.name}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Original Price"
                                fullWidth
                                value={sku.sku.unitPrice}
                                disabled
                            />
                        </Grid>
                        <Divider />
                    </React.Fragment>
                ))}
            </Grid>
        </Grid>
    );
}

export default SkuGrid;
