import { Box, Breadcrumbs, Button, ButtonGroup, Grid, Typography, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import HitchBox from "../../components/hitchbox/HitchBox";
import { Link as MuiLink } from '@mui/material';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useHubConnections } from "../../utils/HubConnectionsProvider";
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import SourceSelector from "./SourceSelector";
import SourceDetails from "./SourceDetails";
import StoreDocumentsGrid from "./StoreDocumentsGrid";
import DeleteConfirmModal from "./DeleteConfirmModal";

function StoreDetails() {
    const { dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady, jwtToken, user } = useHubConnections();
    const navigate = useNavigate();
    const { guid } = useParams();
    const [repository, setRepository] = useState({});
    const [path, setPath] = useState('/'); // Assuming you have some default path
    const [openRepoDelete, setOpenRepoDelete] = useState(false);
    const [selectedSource, setSelectedSource] = useState(null);

    function setSelecteSourceOverride(data) {
        // console.log('Changing Selected Source');
        setSelectedSource(data);
    }

    useEffect(() => {
        if (dashboardConnection && isConnectionReady && guid) {
            dashboardConnection.on("1995157e-00be-42e3-9a60-7261a0d095ce", setRepository);
            dashboardConnection.on("9ee8a0b3-5d12-4395-929a-714e208472a5", setRepository);
            dashboardConnection.on("27bcc231-4486-49ab-a1f2-8351b2f4d08b", repositoryDeleted);

            hitchDashCommand('document', 'repo', 'get', { 'RepositoryId': guid }, () => { });
        }

        return () => {
            if (dashboardConnection) {
                dashboardConnection.off("1995157e-00be-42e3-9a60-7261a0d095ce", setRepository);
                dashboardConnection.off("9ee8a0b3-5d12-4395-929a-714e208472a5", setRepository);
                dashboardConnection.off("27bcc231-4486-49ab-a1f2-8351b2f4d08b", repositoryDeleted);
            }
        };
    }, [dashboardConnection, isConnectionReady, guid, hitchDashCommand]);

    function repositoryDeleted(repositoryData) {
        navigate('/app/storage');
    }

    const handleUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        const url = `${window._env_.HITCH_API}/api/store/${guid}/upload?filePath=${path}`;
        const formData = new FormData();
        formData.append('file', file);
    
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,
                },
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to upload file');
            }
    
            // Check if response type is JSON
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.indexOf('application/json') !== -1) {
                const result = await response.json();
                 console.log('File uploaded successfully', result);
            } else {
                 console.log('File uploaded successfully');
            }
    
            // Update file list or handle success
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handleView = (fileId) => {
        // console.log('handleView', fileId);
    };

    const handleDownload = (fileId) => {
        // console.log('handleDownload', fileId);
    };

    const handleRepositoryNameChange = (e) => {
        setRepository({ ...repository, name: e.target.value });
    };

    const handleSave = () => {
        let theRepo = {
            'Repository': repository
        };
        hitchDashCommand('document', 'repo', 'update', theRepo, () => { });
    };

    const handleDeleteRepository = () => {
        setOpenRepoDelete(true);
    };

    const handleCloseRepoDelete = () => {
        setOpenRepoDelete(false);
    };

    const confirmRepoDelete = () => {
        hitchDashCommand('document', 'repo', 'delete', { 'RepositoryId': guid }, () => { });
        setOpenRepoDelete(false);
    };

    return (
        <Box m={3}>
            <HitchBox backgroundColor={'#777'} color={'#fff'}>
                <Breadcrumbs>
                    <MuiLink component={Link} underline="hover" color="inherit" to="/app/storage"><FontAwesomeIcon icon={faArrowLeft} /> Back To Document Storage</MuiLink>
                </Breadcrumbs>
            </HitchBox>
            <HitchBox backgroundColor='#077' color='#ffffff'>
                <Grid container>
                    <Grid item xs={10}>
                        <Typography variant="h4">Document Store: {repository?.name}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <ButtonGroup>
                            <Button variant="contained" onClick={handleSave}>Save</Button>
                            <Button variant="contained" onClick={() => navigate('/app/storage')}>Cancel</Button>
                            <Button variant="contained" onClick={handleDeleteRepository}>Delete</Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                        <HitchBox backgroundColor='#AAAAAA' color='#FFFF00'>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Store Id"
                                        value={repository?.id || ''}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Store Name"
                                        value={repository?.name || ''}
                                        onChange={handleRepositoryNameChange}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </HitchBox>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <HitchBox backgroundColor='#AAAAAA' color='#00FFFF'>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <SourceSelector
                                        guid={guid}
                                        setSelectedSource={setSelectedSource} />
                                </Grid>
                                <Grid item xs={6}>
                                    <SourceDetails
                                        guid={guid}
                                        selectedSource={selectedSource}
                                        setSelectedSource={setSelectedSource} />
                                </Grid>
                            </Grid>
                        </HitchBox>
                    </Grid>
                    <Grid item xs={12} m={3}>
                        <HitchBox backgroundColor='#AAAAAA' color='#044'>
                            <StoreDocumentsGrid
                                path={path}
                                handleUpload={handleUpload}
                                handleView={handleView}
                                handleDownload={handleDownload}
                            />
                        </HitchBox>
                    </Grid>
                </Grid>
            </HitchBox>

            <DeleteConfirmModal
                title="Delete the STORE?"
                content="Are you sure you want to delete this repository and all of its documents? This action is not reversible!"
                labelNo="No"
                actionNo={handleCloseRepoDelete}
                labelYes="Yes"
                actionYes={confirmRepoDelete}
                open={openRepoDelete}
                onClose={handleCloseRepoDelete}
            />
        </Box>
    )
}

export default StoreDetails;
