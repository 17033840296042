import React, { useEffect } from 'react';
import { Box, Typography, Grid, Card, CardMedia, CardContent, List, ListItem, Button } from '@mui/material';

const Offer = ({ acceptIt, declineIt, offer, lead }) => {
  // Calculate the total offer price and list price
  const totalOfferPrice = offer.skus?.reduce((sum, sku) => sum + (sku.overridePrice || 0), 0) || 0;
  const totalListPrice = offer.skus?.reduce((sum, sku) => sum + (sku.overridePrice || 0), 0) || 0;
  // const totalListPrice = offer.skus?.reduce((sum, sku) => sum + (sku.listPrice || 0), 0) || 0;

  useEffect(() => {
    console.log('new offer', offer);
  }, [offer]);

  useEffect(() => {
    console.log('new lead', lead);
  }, [lead]);

  return (
    <Box sx={{ p: 4, maxWidth: 800, mx: 'auto', border: '2px solid #000', boxShadow: 3, backgroundColor: '#fff' }}>
      <Typography variant="h4" align="center" gutterBottom color="textPrimary">
        Official Offer For {lead.organizationName}!
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={6} display="flex" justifyContent="center" alignItems="center">
          <Card>
            <CardMedia
              component="img"
              image="/img/hitch.png"
              alt="Offer"
              sx={{ maxWidth: 150, maxHeight: 150 }} // Adjust the size here
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" justifyContent="center" alignItems="center">
          <CardContent>
            <Typography variant="h6" color="textPrimary" align="center">
              {offer.name}
            </Typography>
            <Typography variant="body2" color="textPrimary" align="center">
              By joining Hitch.AI, you will be part of a revolutionary platform that is transforming the workforce. Below are the terms of the offer:
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Typography variant="h6" color="textPrimary">
          EXCLUSIVELY FOR YOU <span style={{ textDecoration: 'line-through', color: 'red' }}>${totalListPrice}</span> <span style={{ color: 'green', fontWeight: 'bold' }}>${totalOfferPrice}</span> PER MONTH.
        </Typography>
        <List>
          {offer.skus?.map((feature, index) => (
            <ListItem key={index}>
              <Typography variant="body1" color="textPrimary">• {feature.name}</Typography>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Button variant="contained" color="success" sx={{ mx: 1 }} onClick={acceptIt}>
          Accept
        </Button>
        <Button variant="contained" color="error" sx={{ mx: 1 }} onClick={declineIt}>
          Decline
        </Button>
      </Box>
    </Box>
  );
};

export default Offer;
