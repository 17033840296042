import { faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";

function TransformerStep({ transformerStep, onAliasChange, onExpressionChange, removeStep }) {

    return (
        <Grid container spacing={2} padding={1}>
            <Grid item xs={3}>
                <TextField
                    label="Alias"
                    value={transformerStep.variable}
                    fullWidth
                    onChange={(e) => onAliasChange(e.target.value)}
                />
            </Grid>
            <Grid item xs={8}>
                <TextField
                    label="Expression"
                    value={transformerStep.lambda}
                    fullWidth
                    onChange={(e) => onExpressionChange(e.target.value)}
                />
            </Grid>
            <Grid item xs>
            <IconButton onClick={() => removeStep()}>
                <FontAwesomeIcon icon={faTrash} />
            </IconButton>
            </Grid>
        </Grid>
    );
}

export default TransformerStep;
