// DemoVideo.js
import React from 'react';

const DemoVideo = ({ url }) => {
    return (
        <div>
            <h2>Demo Video</h2>
            <video width="100%" controls>
                <source src={url} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
}

export default DemoVideo;
