import React, { useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Avatar, Grid, Typography } from '@mui/material';
import ContextMenu from './ContextMenu';
import { useNavigate } from 'react-router-dom';
import { useHubConnections } from '../../utils/HubConnectionsProvider';

const UserNode = ({ data }) => {
  const [hovered, setHovered] = useState(false);
  const [contextMenuAnchor, setContextMenuAnchor] = useState(null);
  const navigate = useNavigate();
  const {hitchUser} = useHubConnections();
  const [userImage, setUserImage] = useState('');


  useEffect(() => {
    if(hitchUser) {
      // console.log(hitchUser);
      setUserImage(`${window._env_.HITCH_API}/api/accimage/${hitchUser?.userId}.png`);
    }
  }, [hitchUser]);

  const handleContextMenuOpen = (event) => {
    event.preventDefault();
    setContextMenuAnchor(event.currentTarget);
  };

  const handleContextMenuClose = () => {
    setContextMenuAnchor(null);
  };

  const menuItems = [
    {
      label: 'Edit Profile',
      onClick: () => {
        handleContextMenuClose();
        navigate('/app/account/profile');
      },
    },
  ];

  return (
    <div
      className="customNode user-node"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="customNodeBody" onContextMenu={handleContextMenuOpen}>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Avatar sx={{ width: 24, height: 24 }} src={userImage} />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle1" className="user-name">{hitchUser?.name}</Typography>
            <Typography variant="body2" className="user-title">{data.description}</Typography>
          </Grid>
          <Grid item xs={2}>
            {hovered && <div className="snowmanIcon" onClick={handleContextMenuOpen}>&#8942;</div>}
          </Grid>
        </Grid>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        className="customHandle"
      />
      <ContextMenu anchorEl={contextMenuAnchor} handleClose={handleContextMenuClose} menuItems={menuItems} />
    </div>
  );
};

export default UserNode;
