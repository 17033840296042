import { Grid, Typography } from "@mui/material";
import TransformerStep from "./TransformerStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/pro-duotone-svg-icons";

function QbTransformerSteps({ addStep, handleAliasChange, handleExpressionChange, removeStep, transformerSteps }) {

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6">Transform Variables</Typography>
            </Grid>
            <Grid item xs={12}>
                {transformerSteps && transformerSteps.map((step, index) => (
                    <div key={index}>
                        <TransformerStep
                            transformerStep={step}
                            onAliasChange={(value) => handleAliasChange(index, value)}
                            onExpressionChange={(value) => handleExpressionChange(index, value)}
                            removeStep={() => removeStep(index)}
                        />
                    </div>
                ))}
                <FontAwesomeIcon icon={faAdd} onClick={addStep} />
            </Grid>
        </>
    );

}
export default QbTransformerSteps;