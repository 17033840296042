import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, Typography } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import BotOrgChartStyles from "./BotOrgChartStyles";

function BotOperations({ 
    editMode,
    expandedSection,
    functions,
    removeFunction,
    setExpandedSection,
    setOpen
}) {

    const {
        themePrimaryTextColor,
        themeSecondaryBackgroundColor
    } = BotOrgChartStyles();

    return (
        <Accordion id="section4" elevation={0} expanded={expandedSection.section4} onChange={() => setExpandedSection({ ...expandedSection, section4: !expandedSection.section4 })}>
            <AccordionSummary expandIcon={<GridExpandMoreIcon sx={{ color: '#fff' }} />} sx={{ backgroundColor: themeSecondaryBackgroundColor }}>
                <Typography variant="h6" sx={{ color: themePrimaryTextColor, fontFamily: '"Poppins", sans-serif' }}>Operations</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: themeSecondaryBackgroundColor }}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {editMode ? (
                                <>
                                    <Grid item xs={12}>
                                        <Box display="flex" alignItems="center">
                                            <IconButton
                                                onClick={(e) => setOpen(true)}
                                                sx={{
                                                    marginLeft: 'auto',
                                                    color: themePrimaryTextColor,
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                    {Array.isArray(functions) && functions.map((func, index) => (
                                        <Box key={index} display="flex" alignItems="center">
                                            <Typography sx={{ color: themePrimaryTextColor }}>{func?.name || "Unknown Function"}</Typography>
                                            {func?.name && (
                                                <IconButton onClick={() => removeFunction(func.name)}
                                                    sx={{ marginLeft: 'auto', color: themePrimaryTextColor }}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </IconButton>
                                            )}
                                        </Box>
                                    ))}
                                </>
                            ) : (
                                <>
                                    {Array.isArray(functions) && functions.map((func, index) => (
                                        <Box key={index} display="flex" alignItems="center">
                                            <Typography sx={{ color: themePrimaryTextColor }}>{func?.name ? func.name : "BAD-RECORD"}</Typography>
                                        </Box>
                                    ))}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export default BotOperations;