import { faChevronDoubleDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";

function ClusterActorSearch({
    accountId,
    accounts, 
    actorId, 
    connectionId, 
    connections, 
    featureId, 
    features, 
    operationId, 
    operations, 
    search, 
    serviceId,
    services, 
    setAccountId,
    setActorId, 
    setConnectionId,
    setServiceId, 
    setFeatureId, 
    setOperationId}) {

    return (
        <Accordion style={{ width: "100%" }}>
            <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDoubleDown} />}>Search</AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <InputLabel>Account</InputLabel>
                            <Select
                                value={accountId}
                                onChange={(e) => setAccountId(e.target.value)}
                                label="Account"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {accounts.map((account) => (
                                    <MenuItem key={account.id} value={account.id}>
                                        {account.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <InputLabel>Service</InputLabel>
                            <Select
                                value={serviceId}
                                onChange={(e) => setServiceId(e.target.value)}
                                label="Service"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {services.map((service) => (
                                    <MenuItem key={service.id} value={service.id}>
                                        {service.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <InputLabel>Connection</InputLabel>
                            <Select
                                value={connectionId}
                                onChange={(e) => setConnectionId(e.target.value)}
                                label="Connection"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {connections.map((connection) => (
                                    <MenuItem key={connection.id} value={connection.id}>
                                        {connection.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <InputLabel>Feature</InputLabel>
                            <Select
                                value={featureId}
                                onChange={(e) => setFeatureId(e.target.value)}
                                label="Feature"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {features.map((feature) => (
                                    <MenuItem key={feature.id} value={feature.id}>
                                        {feature.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <InputLabel>Operation</InputLabel>
                            <Select
                                value={operationId}
                                onChange={(e) => setOperationId(e.target.value)}
                                label="Operation"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {operations.map((operation) => (
                                    <MenuItem key={operation.id} value={operation.id}>
                                        {operation.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Actor Id"
                            variant="outlined"
                            value={actorId}
                            onChange={(e) => setActorId(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth variant="contained" onClick={search}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

export default ClusterActorSearch;