import React, { useState } from 'react';
import { faCommentPen, faCommentPlus, faDuck, faTrashCan } from '@fortawesome/pro-duotone-svg-icons';
import { Button, IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Widget({ id, content, isEditMode, removeWidget, conversationId, setOpenAttach, setOpenConfirm, setCurrentWidgetID }) {

    const [showAttachModal, setShowAttachModal] = useState(false);

    const handleRemove = () => {
        setCurrentWidgetID(id); // set the ID of the widget to be removed
        setOpenConfirm(true);
    };

    const handleAttach = () => {
        // Your logic for handling attaching a conversation can go here
        setOpenAttach(true);
    };
    

    const handleEdit = () => {
        if (conversationId) {
            window.location.href = `/app/conversation/${conversationId}`;
        } else {
            alert("No conversation attached to this widget.");
        }
    };

    return (
        <div style={{
            border: '3px solid black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            height: '100%'
        }}>
            {isEditMode && (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'black',
                    padding: '10px'
                }}>
                    <Tooltip title="Attach conversation">
                        <IconButton
                            variant='outlined'
                            onClick={handleAttach}
                            style={{ color: 'white' }}>
                            <FontAwesomeIcon icon={faCommentPlus} />
                        </IconButton>
                    </Tooltip>
                    {conversationId && (
                        <Tooltip title="Edit conversation">
                            <IconButton
                                variant='outlined'
                                onClick={handleEdit}
                                style={{ color: 'white' }}>
                                <FontAwesomeIcon icon={faCommentPen} />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title="Remove widget">
                        <IconButton
                            variant='outlined'
                            onClick={handleRemove}
                            style={{ color: 'white' }}>
                            <FontAwesomeIcon icon={faTrashCan} />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
            <p>{content}</p>
        </div>
    );
}

export default Widget;
