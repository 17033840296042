import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import useMediaQuery from '@mui/material/useMediaQuery';
import AiConversationTabs from './AiConversationTabs';
import AiChatBox from './AiChatBox';
import LinearColor from '../../hitchy/Conversation/LinearColor';
import { useQualifierConnections } from '../../utils/QualifierConnectionProvider';
import isValidGUID from '../../utils/IsValidGuid';

function AiConversation({ 
    noHistory, 
    redirectPath, 
    shouldFocusChatBox, 
    overrideSessionId, 
    maxWidth, 
    overrideHeight, 
    isInChatDrawer, 
    drawerHeightValue, 
    noHeader,
    theme,
    mode,
    defaultPlaceholder }) {
    
    const [newMessage, setNewMessage] = useState('');
    const [drawersOpen, setDrawersOpen] = useState(false);
    const chatContainerRef = useRef(null);
    const miniDrawerRef = useRef(null);
    const leftDrawerRef = useRef(null);
    const history = useNavigate();
    const { guid } = useParams();
    const [useChatGpt, setUseChatGpt] = useState(false);
    const [isChatBoxActive, setIsChatBoxActive] = useState(false);
    const [editingMessage, setEditingMessage] = useState(null);
    const [editedMessageText, setEditedMessageText] = useState('');
    
    const themeBackgroundColor = theme.palette.secondary[theme.palette.mode];
    const isTabletMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const chatBoxRef = useRef(null);

    const {
        chatConnection,
        conversation,
        hitchCommand,
        loading,
        matrixRoutes,
        messages,
        setMessages,
        user,
        setMode,
        isTyping 
    } = useQualifierConnections();

    useEffect(() => {
        setMode(mode);
    }, [mode]);

    const [chatBoxKey, setChatBoxKey] = useState(0);

    const focusChatBoxInput = () => {
        setIsChatBoxActive(true);
        setChatBoxKey(prevKey => prevKey + 1);
    };

    const requestCancel = () => hitchCommand("chat", "thread", "cancel");

    const hasRedirectedRef = useRef(false);

    useEffect(() => {
        if (!guid || !isValidGUID(guid)) {
            if (!hasRedirectedRef.current) {
                // Generate a new GUID
                const newGuid = uuidv4();
                // Remove the existing guid from the current path if it exists
                const pathWithoutGuid = window.location.pathname.replace(`/${guid}`, '');
                // Redirect to the current path with the new GUID
                history(`${pathWithoutGuid}/${newGuid}`);
                // Mark as redirected
                hasRedirectedRef.current = true;
            }
        } 
    }, [guid, history]);

    useEffect(() => {
        focusChatBoxInput();
    }, []);

    useEffect(() => {
        if (shouldFocusChatBox) {
            focusChatBoxInput();
        }
    }, [shouldFocusChatBox]);

    useEffect(() => {
        if (!isTabletMobile) {
            setDrawersOpen(true);
        } else {
            setDrawersOpen(false);
        }
        if (noHistory) {
            setDrawersOpen(false);
        }
    }, [isTabletMobile, noHistory]);

    useEffect(() => {
        if (chatContainerRef.current) {
            const element = chatContainerRef.current;
            element.scrollTop = element.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                miniDrawerRef.current &&
                leftDrawerRef.current &&
                !miniDrawerRef.current.contains(event.target) &&
                !leftDrawerRef.current.contains(event.target)
            ) {
                if (isTabletMobile || isTablet) {
                    setDrawersOpen(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isTabletMobile, isTablet]);

    const handleSendMessage = () => {
        if (newMessage.content !== '') {
            hitchCommand('chat', 'conversation', 'message-add', newMessage, () => {
                // Once the message is added, handle additional logic here
            });
            const newMessageToAdd = {
                text: newMessage.content,
                content: newMessage.content,
                contentType: 'text',
                type: 'user',
                user: newMessage.user
            };
            setMessages(prevMessages => [...prevMessages, newMessageToAdd]);
            setNewMessage({ content: '', contentType: 'text', user: null });
        }
    };

    const isHelpful = (messageId) => {
        hitchCommand('chat', 'conversation', 'message-helpful', messageId);
    };

    const isNotHelpful = (messageId) => {
        hitchCommand('chat', 'conversation', 'message-not-helpful', messageId);
    };

    const saveEditedMessage = () => {
        if (editingMessage) {
            editingMessage.text = [editedMessageText];
            setEditingMessage(null);
            setEditedMessageText('');
        }
    };

    const supportStyle = {
        backgroundColor: themeBackgroundColor,
        width: `calc(100vw)`, 
        height: `calc(100vh - 84px)`, 
        margin: 0, 
        marginLeft: isMobile ? 'auto' : '-24px',
        padding: 0, 
        display: 'flex',
        flexDirection: 'column',
    };

    return (
        <div style={supportStyle}>
            <Grid 
                container 
                spacing={0} 
                marginTop={0} 
                style={{ maxHeight: overrideHeight, backgroundColor: themeBackgroundColor }}>
                
                <Grid item xs={12} lg={12}>
                    <Grid item xs={12} style={{ backgroundColor: themeBackgroundColor, display: 'flex', alignItems: 'center', maxWidth: maxWidth }}>
                        <AiConversationTabs
                            conversation={conversation}
                            useChatGpt={useChatGpt}
                            setUseChatGpt={setUseChatGpt}
                            guid={guid}
                            messages={messages}
                            chatContainerRef={chatContainerRef}
                            isHelpful={isHelpful}
                            isNotHelpful={isNotHelpful}
                            saveEditedMessage={saveEditedMessage}
                            setEditingMessage={setEditingMessage}
                            setEditedMessageText={setEditedMessageText}
                            editedMessageText={editedMessageText}
                            editingMessage={editingMessage}
                            isTabletMobile={isTabletMobile}
                            isMobile={isMobile}
                            isTablet={isTablet}
                            overrideHeight={overrideHeight}
                            maxWidth={maxWidth}
                            isInChatDrawer={isInChatDrawer}
                            isTyping={isTyping}
                            setMessages={setMessages}
                            chatBoxRef={chatBoxRef} 
                            theme={theme}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ backgroundColor: themeBackgroundColor, display: 'flex', alignItems: 'center', maxWidth: maxWidth }}>
                        <AiChatBox
                            key={chatBoxKey}
                            newMessage={newMessage}
                            handleSendMessage={handleSendMessage}
                            setNewMessage={setNewMessage} 
                            isChatBoxActive={isChatBoxActive}
                            leftConversationDrawerOpen={drawersOpen}
                            isMobile={isTabletMobile}
                            isInChatDrawer={isInChatDrawer}
                            drawerHeight={drawerHeightValue}
                            maxWidth={maxWidth}
                            setMessages={setMessages}
                            ref={chatBoxRef} 
                            theme={theme}
                            defaultPlaceholder={defaultPlaceholder}
                        />
                        {loading &&
                            <LinearColor
                                requestCancel={requestCancel} />
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default AiConversation;
