import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, IconButton, Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useAdminHubConnections } from '../../utils/HubAdminConnectionProvider';
import { useHubConnections } from '../../utils/HubConnectionsProvider';

function OrganizationJournals({organization}) {
    const [journals, setJournals] = useState([]);
    const [expanded, setExpanded] = useState({});

    const { adminConnection, hitchUser, isConnectionReady } = useAdminHubConnections();
    const {         
        defaultSnack,
        infoSnack,
        warningSnack,
        successSnack,
        errorSnack, 
    } = useHubConnections();

    useEffect(() => {
        if (adminConnection) {
            adminConnection.on('AccountJournals', (data) => {
                console.log('AccountJournals', data);
                setJournals(data);
                // setLoading(false);
            });

            getAccountJournals();
            return () => {
                adminConnection.off('AccountJournals');
            };
        }
    }, [adminConnection]);

    function getAccountJournals() {
        adminConnection.invoke("GetAccountJournals", hitchUser.userId, organization.Id)
            .then(() => {
                console.log('i asked for Account Roles');
                // setLoading(true);
            })
            .catch(error => console.error("Error sending message:", error));
    }

    useEffect(() => {
        // Use static dummy data for now
        const dummyJournals = [
            {
                id: "1",
                name: "Journal One",
                created: "2024-01-01",
                createdBy: "admin",
                updated: "2024-01-02",
                updatedBy: "admin",
                accountId: "12345",
                journalClosed: "2024-01-10",
                journalClosedBy: "admin",
                journalStatus: "Closed",
                squareUpCartId: "cart1",
                quickbooksInvoiceId: "invoice1"
            },
            {
                id: "2",
                name: "Journal Two",
                created: "2024-02-01",
                createdBy: "admin",
                updated: "2024-02-02",
                updatedBy: "admin",
                accountId: "67890",
                journalClosed: null,
                journalClosedBy: null,
                journalStatus: "Open",
                squareUpCartId: "cart2",
                quickbooksInvoiceId: "invoice2"
            }
        ];
        setJournals(dummyJournals);
    }, []);

    const handleExpandClick = (id) => {
        setExpanded((prevExpanded) => ({
            ...prevExpanded,
            [id]: !prevExpanded[id]
        }));
    };

    return (
        <Box >
            
            {journals.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Journal Status</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Journal Closed</TableCell>
                                <TableCell>SquareUp Cart Id</TableCell>
                                <TableCell>Quickbooks Invoice Id</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {journals.map((journal) => (
                                <React.Fragment key={journal.id}>
                                    <TableRow sx={{ '& .MuiTableCell-root': { padding: '8px' } }}>
                                        <TableCell>{journal.name}</TableCell>
                                        <TableCell>{journal.journalStatus}</TableCell>
                                        <TableCell>{journal.created}</TableCell>
                                        <TableCell>{journal.journalClosed}</TableCell>
                                        <TableCell>{journal.squareUpCartId}</TableCell>
                                        <TableCell>{journal.quickbooksInvoiceId}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => handleExpandClick(journal.id)}
                                                aria-expanded={expanded[journal.id]}
                                                aria-label="show more"
                                            >
                                                {expanded[journal.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '& .MuiTableCell-root': { padding: '8px' } }}>
                                        <TableCell style={{ padding: 0 }} colSpan={7}>
                                            <Collapse in={expanded[journal.id]} timeout="auto" unmountOnExit>
                                            <Box margin={0} sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 1 }}>
                                                    <Grid container spacing={2}>
                                                        {Object.keys(journal).map((key) => (
                                                            !['name', 'journalStatus', 'created', 'journalClosed', 'squareUpCartId', 'quickbooksInvoiceId'].includes(key) && (
                                                                <Grid item xs={12} sm={6} key={key}>
                                                                    <Typography variant="body2"><strong>{key}:</strong> {journal[key]}</Typography>
                                                                </Grid>
                                                            )
                                                        ))}
                                                    </Grid>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>No Records to Display</Typography>
            )}
        </Box>
    );
}

export default OrganizationJournals;
