import React, { useState, useEffect } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import ProfileDetails from './ProfileDetails';
import { useHubConnections } from '../../../utils/HubConnectionsProvider';
import InputMask from 'react-input-mask';
import { Box, Typography, TextField, Button, IconButton } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import SettingsMenu from '../SettingsMenu/SettingsMenu';  // Import the SettingsMenu component
import { useSettingsStyles } from '../common/SettingsStyleProvider';
import HitchImageUploader from '../../../components/HitchImageUploader/HitchImageUploader';

function ManageProfile() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    font,
    themeBackgroundColor,
    themePrimaryTextColor,
    supportStyle,
    boxStyle,
    buttonStyle,
    buttonStyleSecondary,
    dividerStyle,
    headerStyle,
    titleStyle,
    buttonContainerStyle,
    setIsDrawerOpen,
    defaultOutlineStyle,
    activeOutlineStyle,
    errorOutlineStyle,
    initialState,
    containerStyle,
    helperTextStyle,
    fixedWidthStyle,
    greetingContainerStyle,
    isDrawerOpen
  } = useSettingsStyles();

  const { hitchUser, dashboardConnection, errorSnack, hitchDashCommand, isConnectionReady, infoSnack, successSnack } = useHubConnections();

  const [selectedData, setSelectedData] = useState({ hitchUser });
  const [editedData, setEditedData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [formFields, setFormFields] = useState(initialState);
  const [userProfile, setUserProfile] = useState({});

  useEffect(() => {
    if (!isMobile) {
      setIsDrawerOpen(true);  // Open the drawer when not in mobile view
    }
  }, [isMobile, setIsDrawerOpen]);

  useEffect(() => {
    if (dashboardConnection && hitchDashCommand && isConnectionReady && hitchUser) {
      dashboardConnection.on("57d357a6-ba66-4a81-8112-0c28aa9c552b", (data) => {
        if (data.StackTraceString) {
          errorSnack(`Error: ${data.Message}`);
        } else {
          setUserProfile(data);
          successSnack('User Profile has been updated.');
        }
      });
      dashboardConnection.on("7c286f12-37fd-4085-a8e6-53955e1c8d07", (data) => {
        if (data.StackTraceString) {
          errorSnack(`Error: ${data.Message}`);
        } else {
          setUserProfile(data);
        }
      });

      function callForProfile() {
        hitchDashCommand('user', 'profile', 'get', {
          profileUserId: hitchUser.userId
        });
      }

      callForProfile();

      return () => {
        if (dashboardConnection) {
          dashboardConnection.off("7c286f12-37fd-4085-a8e6-53955e1c8d07");
          dashboardConnection.off("57d357a6-ba66-4a81-8112-0c28aa9c552b");
        }
      };
    }
  }, [dashboardConnection, hitchDashCommand, isConnectionReady, hitchUser]);

  useEffect(() => {
    if (userProfile) {
      setEditedData(userProfile);
    }
  }, [userProfile]);

  function saveProfile(updatedData) {
    if (dashboardConnection && hitchDashCommand) {
      hitchDashCommand('user', 'profile', 'update', { User: updatedData });
      infoSnack('Saving data.');
    } else {
      errorSnack('Cannot Save, Something went wrong.');
    }
  }

  useEffect(() => {
    if (hitchUser) {
      setSelectedData(hitchUser);
    }
  }, [hitchUser]);

  function handleInputChange(event) {
    const { name, value } = event.target;
    let formattedValue = value.trim() === '' ? '' : value;

    if (name === 'defaultPhoneNumber') {
      formattedValue = formatPhoneNumber(value);
      if (formattedValue.replace(/\D/g, '').length === 0) {
        formattedValue = '';
      }
    }

    setEditedData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  }

  const fieldLabels = {
    firstName: 'First Name',
    lastName: 'Last Name',
    defaultPhoneNumber: 'Phone',
  };

  useEffect(() => {
    handleRequiredValidation();
  }, [editedData]);

  function handleRequiredValidation() {
    const newFieldsState = { ...formFields };

    const requiredFields = Object.keys(fieldLabels);

    requiredFields.forEach(field => {
      if (!editedData[field]) {
        newFieldsState[field].error = `${fieldLabels[field]} is required. Please provide a value.`;
        newFieldsState[field].outlineStyle = { borderColor: errorOutlineStyle };
        newFieldsState[field].outlineStyleActive = { borderColor: errorOutlineStyle };
      } else {
        newFieldsState[field].error = '';
        newFieldsState[field].outlineStyle = { borderColor: defaultOutlineStyle };
        newFieldsState[field].outlineStyleActive = { borderColor: activeOutlineStyle };
      }
    });

    setFormFields(newFieldsState);
  }

  function handleEdit() {
    setEditMode(true);
  }

  function handleSave() {
    let hasError = false;

    const validateField = (fieldName, fieldValue, errorMessage) => {
      const trimmedValue = fieldValue ? fieldValue.trim() : '';
      if (!trimmedValue) {
        setFormFields(prevFields => ({
          ...prevFields,
          [fieldName]: {
            ...prevFields[fieldName],
            error: errorMessage,
            outlineStyle: { borderColor: errorOutlineStyle },
            outlineStyleActive: { borderColor: errorOutlineStyle },
          },
        }));
        hasError = true;
      } else {
        setFormFields(prevFields => ({
          ...prevFields,
          [fieldName]: {
            ...prevFields[fieldName],
            error: '',
            outlineStyle: { borderColor: defaultOutlineStyle },
            outlineStyleActive: { borderColor: activeOutlineStyle },
          },
        }));
      }
    };

    validateField('firstName', editedData.firstName, 'First Name is required. Please provide a value.');
    validateField('lastName', editedData.lastName, 'Last Name is required. Please provide a value.');
    validateField('defaultPhoneNumber', editedData.defaultPhoneNumber, 'Phone is required. Please provide a value.');

    if (editedData.defaultPhoneNumber) {
      const phoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
      if (!phoneNumberRegex.test(editedData.defaultPhoneNumber)) {
        setFormFields(prevFields => ({
          ...prevFields,
          defaultPhoneNumber: {
            ...prevFields.defaultPhoneNumber,
            error: 'Invalid format. Please provide valid format.',
            outlineStyle: { borderColor: errorOutlineStyle },
            outlineStyleActive: { borderColor: errorOutlineStyle },
          },
        }));
        hasError = true;
      }
    }

    if (hasError) {
      return;
    }

    const updatedData = {
      ...editedData,
      name: `${editedData.firstName} ${editedData.lastName}`
    };

    saveProfile(updatedData);

    setSelectedData({
      ...selectedData,
      ...updatedData,
    });

    setEditMode(false);
  }

  function handleCancel() {
    setEditedData(userProfile);
    setEditMode(false);
  }

  function formatPhoneNumber(defaultPhoneNumber) {
    if (!defaultPhoneNumber) return '';

    const numericPhoneNumber = defaultPhoneNumber.replace(/\D/g, '');
    return `(${numericPhoneNumber.slice(0, 3)}) ${numericPhoneNumber.slice(3, 6)}-${numericPhoneNumber.slice(6)}`;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <SettingsMenu hitchUser={hitchUser} />
      <Box sx={{ flexGrow: 1, marginLeft: !isMobile && isDrawerOpen ? '240px' : '0px' }}>
        {isMobile && !isDrawerOpen && (
          <IconButton
            onClick={() => setIsDrawerOpen(true)}
            size="small"
            sx={{
              color: 'white',
              backgroundColor: '#18181B',
              opacity: 0.8,
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
              margin: '10px',
              zIndex: 1000,
              position: 'fixed',
              top: '66px',
              left: '16px',
              '&:hover': {
                backgroundColor: 'rgba(21, 101, 192, 0.8)',
              },
            }}
          >
            <KeyboardDoubleArrowRight />
          </IconButton>
        )}

        <Box sx={supportStyle}>
          <Box sx={boxStyle}>
            <Box sx={headerStyle}>
              <Typography variant="h6" sx={titleStyle}>Manage Profile</Typography>
            </Box>

            <Box display="flex" justifyContent="center" marginBottom="32px">
              <HitchImageUploader guid={hitchUser?.userId} />
            </Box>
            <Box sx={greetingContainerStyle}>
              <Typography variant="h5" gutterBottom sx={{ fontFamily: font }}>
                Hello {editedData?.firstName ? editedData?.firstName : editedData?.name}!
              </Typography>
              <Typography variant="body2" gutterBottom sx={{ fontFamily: font }}>
                Email: {hitchUser?.email}
              </Typography>
            </Box>
            <Box sx={dividerStyle}></Box>
            <Box sx={buttonContainerStyle}>
              {!editMode ? (
                <Button
                  variant="outlined"
                  onClick={handleEdit}
                  sx={buttonStyle}
                >
                  <CreateIcon fontSize="smaller" /> Edit
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    onClick={handleCancel}
                    style={buttonStyleSecondary}
                  >
                    <CancelIcon fontSize="smaller" /> Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleSave}
                    style={buttonStyle}
                  >
                    <SaveIcon fontSize="smaller" /> Save
                  </Button>
                </>
              )}
            </Box>
            {editMode ? (
              <>
                <Box sx={containerStyle}>
                  <Box>
                    <TextField
                      name="firstName"
                      label={fieldLabels.firstName}
                      variant="outlined"
                      fullWidth
                      value={editedData?.firstName || ''}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          color: themePrimaryTextColor,
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '16px',
                          fontFamily: font,
                        },
                      }}
                      InputProps={{
                        style: {
                          color: themePrimaryTextColor,
                          fontSize: '16px',
                          fontFamily: font,
                          ...fixedWidthStyle,
                        },
                        sx: {
                          background: themeBackgroundColor,
                          height: '40px',
                          color: themePrimaryTextColor,
                          '& .MuiOutlinedInput-notchedOutline': {
                            ...formFields.firstName.outlineStyle,
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            ...formFields.firstName.outlineStyleActive,
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            ...formFields.firstName.outlineStyleActive,
                          },
                        },
                      }}
                      sx={{ marginBottom: '12px' }}
                      required
                      error={!!formFields.firstName.error}
                      helperText={formFields.firstName.error || ' '}
                      FormHelperTextProps={{ style: helperTextStyle }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box>
                    <TextField
                      name="lastName"
                      label={fieldLabels.lastName}
                      variant="outlined"
                      fullWidth
                      value={editedData?.lastName || ''}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          color: themePrimaryTextColor,
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '16px',
                          fontFamily: font,
                        },
                      }}
                      InputProps={{
                        style: {
                          color: themePrimaryTextColor,
                          fontSize: '16px',
                          fontFamily: font,
                          ...fixedWidthStyle,
                        },
                        sx: {
                          background: themeBackgroundColor,
                          height: '40px',
                          color: themePrimaryTextColor,
                          '& .MuiOutlinedInput-notchedOutline': {
                            ...formFields.lastName.outlineStyle,
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            ...formFields.lastName.outlineStyleActive,
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            ...formFields.lastName.outlineStyleActive,
                          },
                        },
                      }}
                      sx={{ marginBottom: '12px' }}
                      required
                      error={!!formFields.lastName.error}
                      helperText={formFields.lastName.error || ' '}
                      FormHelperTextProps={{ style: helperTextStyle }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box>
                    <InputMask
                      mask="(999) 999-9999"
                      maskChar={null}
                      value={editedData?.defaultPhoneNumber || ''}
                      onChange={handleInputChange}
                    >
                      {(inputProps) => (
                        <TextField
                          {...inputProps}
                          name="defaultPhoneNumber"
                          label={fieldLabels.defaultPhoneNumber}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color: themePrimaryTextColor,
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '16px',
                              fontFamily: font,
                            },
                          }}
                          InputProps={{
                            style: {
                              color: themePrimaryTextColor,
                              fontSize: '16px',
                              fontFamily: font,
                              ...fixedWidthStyle,
                            },
                            sx: {
                              background: themeBackgroundColor,
                              height: '40px',
                              color: themePrimaryTextColor,
                              '& .MuiOutlinedInput-notchedOutline': {
                                ...formFields.defaultPhoneNumber.outlineStyle,
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                ...formFields.defaultPhoneNumber.outlineStyleActive,
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                ...formFields.defaultPhoneNumber.outlineStyleActive,
                              },
                            },
                          }}
                          sx={{ marginBottom: '12px' }}
                          required
                          error={!!formFields.defaultPhoneNumber.error}
                          helperText={formFields.defaultPhoneNumber.error || ' '}
                          FormHelperTextProps={{ style: helperTextStyle }}
                          autoComplete="off"
                        />
                      )}
                    </InputMask>
                  </Box>
                </Box>
              </>
            ) : (
              <ProfileDetails hitchUser={editedData} formatPhoneNumber={formatPhoneNumber} themePrimaryTextColor={themePrimaryTextColor} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ManageProfile;
