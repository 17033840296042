import ELK from 'elkjs/lib/elk.bundled.js';

const elk = new ELK();

const transformToElkFormat = (json) => {
    const elkNodes = json.nodes.map(node => {
        // Initialize layoutOptions with increased spacing
        let layoutOptions = {
            'elk.spacing.nodeNode': 100, // Increased node-to-node spacing
            'elk.spacing.edgeNode': 50,  // Increased edge-to-node spacing
        };

        // Assign layer and position constraints with adjustments for separation
        if (node.type === 'condition') {
            console.log('THIS IS A CONDITION!');
            layoutOptions['elk.layering.layerChoiceConstraint'] = 'FIRST_SEPARATE';
            layoutOptions['elk.nodePlacement.strategy'] = 'NETWORK_SIMPLEX';
            // Explicitly set wider spacing for conditions to ensure separation
            layoutOptions['elk.spacing.nodeNodeBetweenLayers'] = 400; // Adjusted to ensure conditions move to the right
        } else if (node.type === 'action') {
            console.log('THIS IS AN ACTION!');
            layoutOptions['elk.layering.layerChoiceConstraint'] = 'FIRST_SEPARATE';
            layoutOptions['elk.nodePlacement.strategy'] = 'NETWORK_SIMPLEX';
            // Use vertical spacing to separate actions below the rule
            layoutOptions['elk.spacing.nodeNode'] = 400; // Increased to separate actions below
        }

        return {
            id: node.id,
            width: node.data.width || 400, // Ensure nodes are sufficiently wide
            height: node.data.height || 200, // Ensure nodes have enough height to avoid overlap
            layoutOptions: layoutOptions,
        };
    });

    // Transform edges without modification
    const elkEdges = json.edges.map(edge => ({
        id: edge.id,
        sources: [edge.source],
        targets: [edge.target],
    }));

    return { nodes: elkNodes, edges: elkEdges };
};


const graph = {
    id: "root",
    layoutOptions: {
        'elk.direction': 'RIGHT',
        'cycleBreaking.strategy': 'INTERACTIVE',
        'layering.strategy': 'INTERACTIVE',
        'crossingMinimization.semiInteractive': true,
        'separateConnectedComponents': false,
    },
    children: [],
    edges: [],
};

/**
 * Transforms the ELK layout back into the original JSON format, updating node positions.
 * @param {Object} json - The original JSON object with nodes and edges.
 * @param {Object} layout - The layout object returned by ELK.
 * @returns {Object} The updated JSON object with new node positions.
 */
const transformBack = (json, layout) => {
    // Create a map of layout node positions for easy access
    const layoutPositions = layout.children.reduce((acc, node) => {
        acc[node.id] = { x: node.x, y: node.y };
        return acc;
    }, {});

    // Update the positions of the nodes in the original JSON
    const updatedNodes = json.nodes.map(node => {
        const position = layoutPositions[node.id];
        if (position) {
            return {
                ...node,
                position: {
                    x: position.x,
                    y: position.y
                }
            };
        }
        return node;
    });

    // Return the updated JSON, including any changes to edges if needed
    return {
        ...json,
        nodes: updatedNodes
    };
};


export const calculateLayout = async (json) => {
    const transformed = transformToElkFormat(json);
    graph.children = transformed.nodes;
    graph.edges = transformed.edges;

    try {
        const layout = await elk.layout(graph);
        return transformBack(json, layout); // Assume transformBack adapts the layout to your original JSON format
    } catch (error) {
        console.error("ELK Layout calculation failed:", error);
        return json; // Return the original object in case of failure
    }
};
