import React, { useState } from "react";
import { TextField, Button } from "@mui/material";

function ComplexObjectForm({ property, propertyName }) {
  const [editedProperty, setEditedProperty] = useState(property);

  const handleNestedPropertyChange = (propertyName, value) => {
    // Update the editedProperty with the new value for the nested property
    setEditedProperty((prevState) => ({
      ...prevState,
      [propertyName]: value,
    }));
  };

  const handleSave = () => {
    // Handle saving the edited complex object
    // You can send it to an API, update state, etc.
    console.log("Saving edited complex object:", editedProperty);
  };

  return (
    <div>
      <h3>{propertyName}</h3>
      {editedProperty && Object.keys(editedProperty).map((nestedProperty) => (
        <TextField
          key={nestedProperty}
          label={nestedProperty}
          value={editedProperty[nestedProperty]}
          onChange={(e) =>
            handleNestedPropertyChange(nestedProperty, e.target.value)
          }
          fullWidth
        />
      ))}
      <Button variant="contained" color="primary" onClick={handleSave}>
        Save
      </Button>
    </div>
  );
}

export default ComplexObjectForm;
