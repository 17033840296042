import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    List,
    Typography,
    InputAdornment,
    OutlinedInput,
    Divider
} from '@mui/material';
import ConversationHistory from './ConversationHistory';
import SearchIcon from "@mui/icons-material/Search";
import FileList from '../FileList/FileList';
import MiniVariantDrawer from '../MiniVariantDrawer/MiniVariantDrawer';

const drawerWidth = 275;
const miniDrawerWidth = 88;

function LeftConversationDrawer({
    onClose,
    open,
    setOpen,
    tabValue,
    setTabValue,
    conversationHistory,
    focusChatBoxInput,
    deleteConversation,
    redirectPath,
    selectedChat,
    setName,
    miniDrawerOpen,
    setMiniDrawerOpen,
    isTabletMobile
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const themeBackgroundColor = theme.palette.chatLeftMenuBackground[theme.palette.mode];
    const themeTextColor = theme.palette.chatLeftMenuText[theme.palette.mode];
    const themeFontFamily = theme.palette.typography.fontFamilyMain;
    const newChat = useNavigate();
    const [searchInput, setSearchInput] = useState('');
    const headerHeight = isMobile ? 56 : isTablet ? 65 : 64;
    const navigate = useNavigate();

    useEffect(() => {
        setOpen(open);
    }, [open, setOpen]);

    useEffect(() => {
        setMiniDrawerOpen(miniDrawerOpen);
    }, [miniDrawerOpen, setMiniDrawerOpen]);

    const handleTabChange = (newValue) => {
        if (tabValue === newValue) {
            setOpen(false);
            if (isTabletMobile) {
                setMiniDrawerOpen(false);
            }
            setTabValue('none'); // Close the drawer
        } else {
            setOpen(true);
            setMiniDrawerOpen(true); // Keep the mini drawer open
            setTabValue(newValue); // Switch the view
        }
    };

    const handleNewChat = () => {
        newChat(redirectPath);
        focusChatBoxInput();
        if (isTabletMobile) onClose();
    };

    const handleFocusChatBox = () => {
        focusChatBoxInput();
    };

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const filteredConversationHistory = conversationHistory.filter((item) =>
        item?.text?.toLowerCase()?.includes(searchInput.toLowerCase())
    );

    const chatMenuOptions = [
        {
            "label": "Rename Chat",
            "action": "rename",
            "icon": "faPen"
        },
        {
            "label": "Delete Chat",
            "action": "delete",
            "icon": "faTrash"
        }
    ];

    return (
        <Box sx={{ display: 'flex' }}>
            <MiniVariantDrawer
                open={miniDrawerOpen}
                onSelect={handleTabChange}
                onNewChat={handleNewChat}
                drawerOpen={open}
                miniDrawerOpen={miniDrawerOpen}
                setMiniDrawerOpen={setMiniDrawerOpen}
                isTabletMobile={isTabletMobile}
                isTablet={isTablet}
                selectedTab={tabValue} // Pass the selected tab value here
            />
            <Drawer
                variant={isTabletMobile ? 'temporary' : 'persistent'}
                open={open}
                onClose={onClose}
                ModalProps={{ keepMounted: true }}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        backgroundColor: themeBackgroundColor,
                        top: `${headerHeight}px`,
                        overflow: "hidden",
                        marginLeft: `${miniDrawerWidth}px`,
                        zIndex: 2500, // Ensure higher z-index
                        //paddingRight: 0, // Ensure no padding on the right
                        //paddingLeft: 0, // Ensure no padding on the right
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        backgroundColor: themeBackgroundColor,
                    }}
                >
                    <Box sx={{ flex: 1, overflowY: 'auto', paddingX: '0px', paddingY: '8px', paddingRight: 0 }}>
                        {tabValue === 'history' && (
                            <List style={{ marginTop: "0px" }}>
                                <Typography variant="h7" sx={{ paddingLeft: 1, fontFamily: themeFontFamily, color: themeTextColor }}>
                                    Chat History
                                </Typography>

                                <OutlinedInput
                                    placeholder="Search History"
                                    fullWidth
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon
                                                sx={{
                                                    color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.54)' : '#808080'
                                                }}
                                            />
                                        </InputAdornment>
                                    }
                                    sx={{
                                        fontFamily: themeFontFamily,
                                        color: themeTextColor,
                                        height: '40px',
                                        marginTop: '8px',
                                        borderRadius: 0,
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderTop: theme.palette.mode === 'dark' ? '2px solid #333333' : '1px solid #D9D9D9',
                                            borderBottom: theme.palette.mode === 'dark' ? '2px solid #333333' : '1px solid #D9D9D9',
                                            borderLeft: theme.palette.mode === 'dark' ? '0px solid #333333' : '0px solid #D9D9D9',
                                            borderRight: theme.palette.mode === 'dark' ? '0px solid #333333' : '0px solid #D9D9D9'
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderLeft: theme.palette.mode === 'dark' ? '1px solid #00EDED' : '1px solid #00EDED',
                                            borderRight: theme.palette.mode === 'dark' ? '1px solid #00EDED' : '1px solid #00EDED',
                                            borderTop: theme.palette.mode === 'dark' ? '1px solid #00EDED' : '1px solid #00EDED',
                                            borderBottom: theme.palette.mode === 'dark' ? '1px solid #00EDED' : '1px solid #00EDED',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderLeft: theme.palette.mode === 'dark' ? '2px solid #00EDED' : '2px solid #00EDED',
                                            borderRight: theme.palette.mode === 'dark' ? '2px solid #00EDED' : '2px solid #00EDED',
                                            borderTop: theme.palette.mode === 'dark' ? '2px solid #00EDED' : '2px solid #00EDED',
                                            borderBottom: theme.palette.mode === 'dark' ? '2px solid #00EDED' : '2px solid #00EDED',
                                        },
                                        '& .MuiInputAdornment-positionStart:not(:first-child)': {
                                            marginRight: '8px',
                                        },
                                        '& .MuiInputAdornment-positionStart': {
                                            marginLeft: '0px',
                                        },
                                    }}
                                    onChange={handleSearchInputChange}
                                />

                                <div
                                    style={{
                                        padding: "4px 4px 4px 4px",
                                        color: "white",
                                        textAlign: "center",
                                        overflowY: 'auto',
                                        maxHeight: `calc(100vh - ${headerHeight + 16}px)`, // Adjust this value to limit the height
                                    }}
                                >
                                    <ConversationHistory
                                        redirectPath={redirectPath}
                                        conversationHistory={filteredConversationHistory}
                                        deleteConversation={deleteConversation}
                                        selectedChatId={selectedChat}
                                        onClose={onClose}
                                        setName={setName}
                                        menuOptions={chatMenuOptions} // Pass the chat-specific menu options here
                                    />
                                </div>
                            </List>
                        )}
                        {tabValue === 'files' && (
                            <FileList selectedChat={selectedChat} />
                        )}
                    </Box>
                    <Divider />
                </Box>
            </Drawer>
        </Box>
    );
}

export default LeftConversationDrawer;
