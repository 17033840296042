import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import userManager from '../services/authService';

const PrivateRoute = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function checkAuthentication() {
            try {
                const authenticated = await userManager.getUser();
                setIsAuthenticated(authenticated != null);
            } catch (error) {
                console.error('Error checking authentication:', error);
            } finally {
                setIsLoading(false);
            }
        }

        checkAuthentication();
    }, []);

    if (isLoading) {
        // You might want to show a loading spinner or something while checking authentication
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        console.log('Doin That Redirect, fore thout, it is me doing the redirect');
        console.log(`redirect is: ${window.location.pathname}${window.location.search}`);
        sessionStorage.setItem('redirectTo', window.location.pathname + window.location.search);
        return <Navigate to="/login" />;
    }

    return <Outlet />;
}

export default PrivateRoute;