import userManager from '../../services/authService';
import { Box, Divider, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import TrainingHeader from "./TrainingHeader";
import * as signalR from '@microsoft/signalr';
import { v4 as uuidv4 } from 'uuid';
import AccountSearch from "./AccountSearch";
import ServiceSearch from './ServiceSearch';
import ConnectionSearch from "./ConnectionSearch";
import FeatureSearch from "./FeatureSearch";

import { useNavigate, useParams } from "react-router-dom";
import TrainingEditor from "./TrainingEditor";
import isValidGUID from '../../utils/IsValidGuid';

function Training({  }) {
    const history = useNavigate();
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [jwtToken, setJwtToken] = useState(null);
    const [start, setStart] = useState(false);
    const [hubConnection, setHubConnection] = useState(null);
    const [hitchUser, setHitchUser] = useState(null);
    const [messages, setMessages] = useState([]);

    const [trainingLine, setTrainingLine] = useState('');

    const [searchTerm, setSearchTerm] = useState('');
    const [editingModel, setEditingModel] = useState(null);
    const [expanded, setExpanded] = useState(false);

    const [outputType, setOutputType] = useState('prompt');

    const [account, setAccount] = useState({});
    const [accountSearchResults, setAccountSearchResults] = useState([]);

    const [service, setService] = useState({});
    const [serviceSearchResults, setServiceSearchResults] = useState([]);

    const [connection, setConnection] = useState({});
    const [connectionSearchResults, setConnectionSearchResults] = useState([]);

    const [feature, setFeature] = useState({});
    const [featureSearchResults, setFeatureSearchResults] = useState([]);

    const [sessionId, setSessionId] = useState(null);

    const [numLines, setNumLines] = useState(0);
    const [fileName, setFileName] = useState('');

    const [steps, setSteps] = useState([]);

    const { guid } = useParams();
    const [conversation, setConversation] = useState(null);

    useEffect(() => {
        // Fetch user data and set authentication status
        async function checkAuthentication() {
            try {
                const authenticated = await userManager.getUser();
                if (authenticated.expires_in < 0) {
                    const currentAddress = `${window.location.pathname}${window.location.search}`;
                    window.location.href = `/login?redirect=${encodeURIComponent(currentAddress)}`;
                }
                setUser(authenticated);
                setIsAuthenticated(authenticated != null);
                if (authenticated) {
                    setJwtToken(authenticated.id_token);
                    setStart(true);
                }
            } catch (error) {
                console.error('Error checking authentication:', error);
                const currentAddress = `${window.location.pathname}${window.location.search}`;
                window.location.href = `/login?redirect=${encodeURIComponent(currentAddress)}`;
            }
        }
        checkAuthentication();
    }, []);

    useEffect(() => {
        if (user && user.id_token) {
            //console.log('user changed', user);
        }
    }, [user]);

    useEffect(() => {
        let isMounted = true; // Track if the component is mounted

        const startConnection = async () => {
            if (jwtToken && start) {
                console.log('sessionId', sessionId);
                const connection = new signalR.HubConnectionBuilder()
                    .withUrl(`${window._env_.HITCH_API}/hitchTrainingHub?sessionId=${sessionId}`, {
                        accessTokenFactory: () => jwtToken
                    })
                    .withAutomaticReconnect()
                    .build();

                try {
                    await connection.start();
                    console.log(`Training Session ${sessionId} online.`);
                    setStart(false);
                    setHubConnection(connection);
                    connection.on("State", handleStateUpdate);
                    connection.on("AccountSearchResults", handleSearchResults);
                    connection.on("ServiceSearchResults", handleServiceSearchResults);
                    connection.on("ConnectionSearchResults", handleConnectionSearchResults);
                    connection.on("FeatureSearchResults", handleFeatureSearchResults);
                    connection.on("ConversationUpdate", conversationUpdate);
                    connection.on("WhoAmI", handleWhomAmI);

                } catch (error) {
                    console.error('Dashboard Hub connection error:', error);
                    // Handle error
                }
            }
        };

        startConnection();

        return () => {
            if (hubConnection) {
                hubConnection.off("State", handleStateUpdate);
                hubConnection.off("AccountSearchResults", handleSearchResults);
                hubConnection.off("ServiceSearchResults", handleServiceSearchResults);
                hubConnection.off("ConnectionSearchResults", handleConnectionSearchResults);
                hubConnection.off("FeatureSearchResults", handleFeatureSearchResults);
                hubConnection.off("ConversationUpdate", conversationUpdate);
                hubConnection.off("WhoAmI", handleWhomAmI);
                hubConnection.stop();
            }
        };
    }, [jwtToken, start]);

    useEffect(() => {
        if (hubConnection) {
            console.log('hubConnection', hubConnection);
        }
    }, [hubConnection]);

    useEffect(() => {
        if (guid && isValidGUID(guid)) {
            setSessionId(guid);
            setStart(false);
        } else {
            const newGuid = uuidv4();
            history(`/admin/training/${newGuid}`);
            setSessionId(newGuid);
        }
    }, [guid]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    function handleSearchResults(data) {
        setAccountSearchResults(data);
    }

    function handleServiceSearchResults(data) {
        setServiceSearchResults(data);
    }

    function handleConnectionSearchResults(data) {
        setConnectionSearchResults(data);
    }

    function handleFeatureSearchResults(data) {
        setFeatureSearchResults(data);
    }

    function setTheAccount(value) {
        if (hubConnection) {
            hubConnection.invoke("SetAccountId", sessionId, value)
                .then(() => {
                    // Do Nothing.  When Request is returned, it should update the page.
                })
                .catch(error => console.error("Error sending message:", error));
        }
        setSearchTerm(''); // TODO: WIPE OUT IN CHILD
        setAccountSearchResults([]);
        setExpanded('');
        console.log('setting Account Value', value);
    }

    function setTheService(value) {
        if (hubConnection) {
            hubConnection.invoke("SetServiceId", sessionId, value)
                .then(() => {
                    // Do Nothing.  When Request is returned, it should update the page.
                })
                .catch(error => console.error("Error sending message:", error));
        }
        setSearchTerm(''); // TODO: WIPE OUT IN CHILD
        setServiceSearchResults([]);
        setExpanded('');
        console.log('setting Service Value', value);
    }

    function setTheConnection(value) {
        if (hubConnection) {
            hubConnection.invoke("SetConnectionId", sessionId, value)
                .then(() => {
                    // Do Nothing.  When Request is returned, it should update the page.
                })
                .catch(error => console.error("Error sending message:", error));
        }
        setSearchTerm(''); // TODO: WIPE OUT IN CHILD
        setConnectionSearchResults([]);
        setExpanded('');
        console.log('setting Connection Value', value);
    }

    function setTheFeature(value) {
        if (hubConnection) {
            hubConnection.invoke("SetFeatureId", sessionId, value)
                .then(() => {
                    // Do Nothing.  When Request is returned, it should update the page.
                })
                .catch(error => console.error("Error sending message:", error));
        }
        setSearchTerm('');// TODO: WIPE OUT IN CHILD
        setFeatureSearchResults([]);
        setExpanded('');
        console.log('setting Feature Value', value);
    }

    

    function handleStateUpdate(data) {
        setAccount(data.selectedAccount);
        setService(data.selectedService);
        setConnection(data.selectedConnection);
        setFeature(data.selectedFeature);
        setNumLines(data.numberOfLines);
        setFileName(data.trainingFile);
        console.log('data.prompts', data?.prompts);
        setSteps(data.prompts);
        console.log('state', data);
        setTrainingLine(data.trainingLine);
    }

    const handleWhomAmI = (whoAmIResponse) => {
        console.log("WhoAmI", whoAmIResponse);
        setHitchUser(whoAmIResponse);
    };

    function conversationUpdate(updatedConversation) {
        console.log('conversationUpdate', updatedConversation);
        setMessages(updatedConversation.messages.map(msg => ({
            messageId: msg.messageId,
            dateTime: msg.messageDateTime,
            user: msg.userId,
            text: msg.message,
            payload: msg.content,
            contentType: msg.contentType,
            isHelpful: msg.isHelpful,
            isNotHelpful: msg.isNotHelpful
        })));
        setConversation(updatedConversation);
        // setUsers(updatedConversation.users);
    }
    
    return (
        <Box sx={{ height: '65vh', marginLeft: '40px', marginRight: '40px' }}>
            <Grid container spacing={1}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        backgroundColor: "#222222",
                        marginTop: "20px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        color: "#FFFFFF"
                    }}
                >
                    <TrainingHeader
                        editingModel={editingModel}
                        hubConnection={hubConnection}
                        searchTerm={searchTerm}
                        sessionId={sessionId}
                        setSearchTerm={setSearchTerm}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        backgroundColor: "#FFFFFF",
                        marginTop: "20px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        color: "#333333"
                    }}
                >
                    <AccountSearch 
                        account={account}
                        accountSearchResults={accountSearchResults}
                        expanded={expanded} 
                        handleChange={handleChange}
                        hubConnection={hubConnection} 
                        sessionId={sessionId}
                        setTheAccount={setTheAccount} />

                    <ServiceSearch
                        expanded={expanded}
                        handleChange={handleChange}
                        hubConnection={hubConnection}
                        service={service} 
                        serviceSearchResults={serviceSearchResults}
                        sessionId={sessionId}
                        setTheService={setTheService}
                    />

                    <ConnectionSearch 
                        account={account}
                        connection={connection}
                        connectionSearchResults={connectionSearchResults}
                        expanded={expanded}
                        handleChange={handleChange}
                        hubConnection={hubConnection}
                        service={service}
                        sessionId={sessionId}
                        setTheConnection={setTheConnection}
                    />

                    <FeatureSearch
                        account={account}
                        connection={connection}
                        feature={feature}
                        featureSearchResults={featureSearchResults}
                        expanded={expanded}
                        handleChange={handleChange}
                        hubConnection={hubConnection}
                        service={service}
                        sessionId={sessionId}
                        setTheFeature={setTheFeature}
                    />
                    
                </Grid>
                <Divider />
                <TrainingEditor 
                    conversation={conversation}
                    fileName={fileName}
                    guid={guid}
                    hitchUser={hitchUser}
                    hubConnection={hubConnection}
                    messages={messages}
                    numLines={numLines}
                    outputType={outputType}
                    sessionId={sessionId}
                    setMessages={setMessages}
                    setSteps={setSteps}
                    setOutputType={setOutputType}
                    steps={steps}
                    trainingLine={trainingLine}
                    setTrainingLine={setTrainingLine}
                    user={user}
                />
            </Grid>
        </Box>
    );
}

export default Training;