import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Typography, OutlinedInput, Avatar, Container, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './chatdrawer.css'; // Import your CSS file for styling
import Conversation from '../../hitchy/Conversation/Conversation';

function ChatDrawer({ isOpen, onClose, overrideHeight }) {
  const theme = useTheme(); // Access the theme to use in the media query
  const [message, setMessage] = useState('');
  const inputRef = useRef(null); // Step 1: Create a ref for the input field
  const [sessionId, setSessionId] = useState('39e13d93-1c38-4176-a273-da2b1a4628ce');
  const maxWidth = '1584px';
  const isXS = useMediaQuery(theme.breakpoints.down('sm')); // 'xs' applies for sizes smaller than 'sm'
  const isSM = useMediaQuery(theme.breakpoints.between('sm', 'md')); // 'sm' for tablets

  // Dynamically adjust overrideHeight based on the screen size
  // Define height values numerically for easier manipulation
  let baseHeightValue;
  if (overrideHeight) {
    baseHeightValue = overrideHeight;
  } else {
    if (isXS) {
      baseHeightValue = 65; // For mobile devices
    } else if (isSM) {
      baseHeightValue = 40; // For tablets
    } else {
      baseHeightValue = 40; // For larger screens
    }
  }

  // Calculate overrideHeight and Box height with a little extra height for the Box
  const overrideHeightInternal = `${baseHeightValue}vh`;
  let adjHeight;
  let drawerHeightValue;
  if (isXS) {

    const screenHeightThresholdMobileXsm = 500; // Example threshold for smaller mobile devices
    const screenHeightThresholdMobileSm = 600; // Example threshold for smaller mobile devices
    const screenHeightThresholdMobileMd = 800; // Example threshold for medium mobile devices

    if (window.innerHeight < screenHeightThresholdMobileXsm) {
      // For very small devices
      drawerHeightValue = `95vh`
    } else if (window.innerHeight <= screenHeightThresholdMobileSm && window.innerHeight > screenHeightThresholdMobileXsm) {
      // For medium devices, greater than or equal to 600 and less than 800
      drawerHeightValue = `95vh`
    } else if (window.innerHeight <= screenHeightThresholdMobileMd && window.innerHeight > screenHeightThresholdMobileSm) {
      // For medium devices, greater than or equal to 600 and less than 800
      drawerHeightValue = `95vh`
    } else {
      // For larger devices, greater than or equal to 800
      drawerHeightValue = `95vh`
    };
    // Add 25vh to make the Box a little bigger for mobile devices
  } else if (isSM) {
    drawerHeightValue = `${baseHeightValue + 16}vh`; // Add 25vh to make the Box a little bigger for tablets
  } else {
    const screenHeightThreshold = 1200; // Example threshold, adjust as needed
    adjHeight = window.innerHeight < screenHeightThreshold ? 20 : 12;
    drawerHeightValue = `${baseHeightValue + adjHeight}vh`; // Add 25vh to make the Box a little bigger for larger screens
  }

  const boxHeight = drawerHeightValue;

  // Focus on the input when the drawer opens
  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);


  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  // Step 3: Styling the focus outline to be seafoam green
  const focusStyle = {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'seafoamGreen', // Adjust this color as per your theme
      },
    },
  };

  return (
    <>
      {isOpen && (
        <Box className={`chat-drawer ${isOpen ? 'open' : ''}`}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: boxHeight, // Apply the calculated Box height here
            backgroundColor: '#1a1a1a',
            color: 'white',
            left: 0,
            right: 0,
            maxWidth: maxWidth,
          }}
        >
          {/* Close button */}
          <IconButton onClick={onClose}
            sx={{
              alignSelf: 'flex-end',
              color: 'white',
              mt: '2px', // This specifically targets the top margin
              mr: '4px', // Keep the right margin as is
            }}>
            <CloseIcon />
          </IconButton>
          {/* Content container */}
          <Container className='fix-chat' sx={{ minWidth: '100%', maxWidth: 'none', mt: '-14px' }}>
            <Conversation isInChatDrawer={true} noHistory={true} overrideSessionId={sessionId} maxWidth={maxWidth} overrideHeight={overrideHeightInternal} drawerHeightValue={drawerHeightValue} />
          </Container>
        </Box>
      )}
    </>
  );

}

export default ChatDrawer;
