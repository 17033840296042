import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { Box, OutlinedInput, Typography, InputAdornment, IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useHubConnections } from '../../../utils/HubConnectionsProvider';
import FileViewerModal from '../../FileViewer/FileViewerModal'; 
import { useTheme, useMediaQuery } from '@mui/material';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useSettingsStyles } from '../common/SettingsStyleProvider';
import SettingsMenu from '../SettingsMenu/SettingsMenu';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  fontFamily: '"Poppins", sans-serif',
  '& .fixed-background': {
    backgroundColor: theme.palette.fixedGridBackground.default,
    '&:hover': {
      backgroundColor: theme.palette.fixedGridBackground.hover[theme.palette.mode],
    },
    '&.MuiSelected': {
      backgroundColor: theme.palette.fixedGridBackground.selected[theme.palette.mode],
      '&:hover': {
        backgroundColor: theme.palette.fixedGridBackground.selected_hover[theme.palette.mode],
      },
    }
  },
  '& .deactivated-row': {
    fontStyle: 'italic',
  },
  '& .role-link, & .action-link': {
    color: theme.palette.link[theme.palette.mode],
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '& .MuiDataGrid-cell': {
    borderColor: theme.palette.gridCellBorderColor[theme.palette.mode],
    border: '2',
  },
  '& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIconButton': {
    opacity: 1,
    color: theme.palette.link[theme.palette.mode],
  },
  '& .MuiDataGrid-iconSeparator': {
    opacity: 1,
    color: theme.palette.gridColumnHeaders[theme.palette.mode]
  },
  '& .MuiDataGrid-footerContainer': {
    backgroundColor: theme.palette.gridColumnHeaders[theme.palette.mode],
    color: theme.palette.gridColumnHeadersText[theme.palette.mode],
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.gridColumnHeaders[theme.palette.mode],
    color: theme.palette.gridColumnHeadersText[theme.palette.mode],
    fontSize: '14px', // Smaller font size for better fit on small screens
    textOverflow: 'ellipsis', // Ensure text does not overflow
    overflow: 'hidden', // Hide overflow text
    whiteSpace: 'nowrap', // Prevent text from wrapping
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  '& .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row': {
    borderBottom: '1px solid #000 !important',
  },
  '& .MuiDataGrid-row': {
    '&.paid-row': {
      backgroundColor: '#4CAF50',
    },
    '&.pending-row': {
      backgroundColor: '#FFC107',
    },
    '&.unpaid-row': {
      backgroundColor: '#FF5722',
    },
    '&.past-due-row': {
      backgroundColor: '#FFC107',
    },
  },
}));

const InvoiceLink = styled('a')(({ theme }) => ({
  color: theme.palette.link[theme.palette.mode],
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const CustomInput = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  padding: '0px 10px',
  marginBottom: '10px',
  background: theme.palette.secondary[theme.palette.mode],
  color: theme.palette.gridText[theme.palette.mode],
  fontFamily: '"Poppins", sans-serif',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.textboxOutline[theme.palette.mode],
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.textboxOutline.hover[theme.palette.mode],
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.textboxOutline.hover[theme.palette.mode],
    borderWidth: '2px',
  },
}));

function ListBillingHistory() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  
  const [filterText, setFilterText] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [openFileViewer, setOpenFileViewer] = useState(false);
  const [fileContent, setFileContent] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileContentType, setFileContentType] = useState(null);


  const drawerWidth = 240; // Adjust to match your drawer's width
  const themeGridColumnHeadersText = theme.palette.gridColumnHeadersText[theme.palette.mode];

  const {
    font,
    themeBackgroundColor,
    themePrimaryTextColor,
    supportStyle,
    boxStyle,
    buttonStyle,
    buttonStyleSecondary,
    dividerStyle,
    headerStyle,
    titleStyle,
    buttonContainerStyle,
    setIsDrawerOpen,
    defaultOutlineStyle,
    activeOutlineStyle,
    errorOutlineStyle,
    initialState,
    containerStyle,
    helperTextStyle,
    fixedWidthStyle
  } = useSettingsStyles();

  const { dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady } = useHubConnections();

  const [journals, setJournals] = useState([]);

  useEffect(() => {
    if (!isMobile) {
      setIsDrawerOpen(true);  // Open the drawer when not in mobile view
    }
  }, [isMobile, setIsDrawerOpen]);

  
  useEffect(() => {
    if (dashboardConnection && isConnectionReady && hitchUser) {
      dashboardConnection.on("22a76588-3f32-4874-b1b8-fa9b71fe9b54", (data) => {
        console.log('journals', data);
        setJournals(data);
      });

      callForJournals();

      return () => {
        if (dashboardConnection) {
          dashboardConnection.off("22a76588-3f32-4874-b1b8-fa9b71fe9b54");
        }
      };
    }
  }, [dashboardConnection, hitchUser, isConnectionReady]);

  // useEffect(() => {
  //   if (journals.length > 0) {
  //     console.log('journals', journals);
  //     setFilteredData(journals);
  //   }
  // }, [journals]);

  function callForJournals() {
    if (dashboardConnection) {
      hitchDashCommand('account', 'billing', 'list', {

      });
    }
  }

  const handleClearSearch = () => {
    setFilterText('');
  };

  const handleSearchInputChange = (event) => {
    setFilterText(event.target.value);
  };

  // Define styles for the clear button with dynamic theme handling
  const clearButtonStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    backgroundColor: theme.palette.secondaryButton[theme.palette.mode], // Dynamic background color
    border: '#FFFFFF',
    //todo: put back
    //border: `1px solid ${theme.palette.secondaryBorder[theme.palette.mode]}`,
    borderRadius: '100px',
    cursor: 'pointer',
    padding: 0,
    transition: 'all 0.3s', // Ensure smooth transition for hover effect
  };

  // Use a function to determine the hover style based on the theme mode
  const getHoverStyle = (mode) => ({
    backgroundColor: theme.palette.secondaryButton[theme.palette.mode],
    borderColor: '#71EEB8',
    //todo: put back
    //backgroundColor: theme.palette.secondaryButton.hover[theme.palette.mode] , // Dynamic hover color based on mode
    ////'borderColor: theme.palette.secondaryBorder[theme.palette.mode], // Maintain border color
    //border: `1px solid ${theme.palette.secondaryBorder[theme.palette.mode]}`,
  });




  const columns = [
    {
      field: 'invoiceNumber',
      headerName: 'Receipt Number',
      flex: 1,
      minWidth: 180, // Ensure a minimum width for the column
      padding: '10px',
      renderCell: (params) => (
        <span>{params.value}</span>
      ),
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1.5, // Increase flex value to allocate more space
      minWidth: 120, // Set a minimum width to prevent truncation
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1.5, // Increase flex value to allocate more space
      minWidth: 130, // Set a minimum width to prevent truncation
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        const status = params.row.status;

        let statusIcon = null;
        switch (status.toUpperCase()) {
          case 'PAID':
            statusIcon = <CheckCircleOutlineIcon style={{ color: '#4CAF50', verticalAlign: 'middle' }} />;
            break;
          case 'PAST DUE':
            statusIcon = <WarningIcon style={{ color: '#FFC107', verticalAlign: 'middle' }} />;
            break;
          case 'DUE':
            statusIcon = <ScheduleIcon style={{ color: '#FFC107', verticalAlign: 'middle' }} />;
            break;
          default:
            break;
        }

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ padding: '8px' }}>
              {statusIcon}
            </div>
            <div style={{ marginLeft: '8px' }}>{status}</div>
          </div>
        );
      },
    },
  ];



  const handleChangePaymentMethod = () => {
    // console.log('Edit Payment Method clicked');
  };

  const handleChangeBillingAddress = () => {
    // console.log('Edit Billing Address clicked');
  };

  const handleInvoiceNumberClick = async (invoice) => {
    try {
      // Fetch file content based on the invoice (this is a placeholder and should be replaced with the actual fetch logic)
      const response = await fetch(`path_to_fetch_file_content/${invoice.invoiceNumber}`);
      const fileContent = await response.text();
      setFileContent(fileContent);
      setFileName(`Invoice ${invoice.invoiceNumber}`);
      setFileContentType('application/pdf'); // Set the correct content type
      setOpenFileViewer(true);
    } catch (error) {
      console.error('Error fetching file content:', error);
    }
  };

  const handleCloseFileViewer = () => {
    setOpenFileViewer(false);
    setFileContent(null);
    setFileName('');
    setFileContentType(null);
  };

  useEffect(() => {
    const filtered = journals.filter((row) =>
      row.invoiceNumber.toLowerCase().includes(filterText.toLowerCase()) ||
      row.amount.toString().toLowerCase().includes(filterText.toLowerCase()) ||
      row.date.toLowerCase().includes(filterText.toLowerCase()) ||
      row.status.toLowerCase().includes(filterText.toLowerCase())
    );
    setFilteredData(filtered);
  }, [journals, filterText]);

  const getRowClassName = () => 'fixed-background';

  return (
    <Box sx={{ display: 'flex' }}>
      <SettingsMenu hitchUser={hitchUser} isDrawerOpen={drawerOpen} setIsDrawerOpen={setDrawerOpen} />
      <Box sx={{ flexGrow: 1, marginLeft: !isMobile && drawerOpen ? '240px' : '0px' }}>
        {isMobile && !drawerOpen && (
          <IconButton
            onClick={() => setDrawerOpen(true)}
            size="small"
            sx={{
              color: 'white',
              backgroundColor: '#18181B',
              opacity: 0.8,
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
              margin: '10px',
              zIndex: 1000,
              position: 'fixed',
              top: '66px',
              left: '16px',
              '&:hover': {
                backgroundColor: 'rgba(21, 101, 192, 0.8)',
              },
            }}
          >
            <KeyboardDoubleArrowRight />
          </IconButton>
        )}

        <Box sx={supportStyle}>




          <Box style={boxStyle}>

            <Box sx={headerStyle}>
              <Typography variant="h6" sx={titleStyle}>Billing History</Typography>
            </Box>
            <div style={{ padding: '10px' }}>
              <CustomInput
                type="text"
                placeholder="Search Billing History..."
                value={filterText}
                onChange={handleSearchInputChange}
                endAdornment={
                  filterText && (
                    <InputAdornment position="end">
                      <button
                        onClick={handleClearSearch}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{
                          ...clearButtonStyle,
                          ...(isHovered ? getHoverStyle() : {}),
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          style={{
                            color: 'white',
                            fontSize: '16px',
                          }}
                        />
                      </button>
                    </InputAdornment>
                  )
                }
              />
            </div>
            {filteredData.length > 0 ? (
              <Box sx={{
                width: '100%',
                backgroundColor: theme.palette.fixedGridBackground.default[theme.palette.mode],
                overflowX: 'auto', // Enable horizontal scrolling
              }}>
                <StyledDataGrid
                  rows={filteredData}
                  columns={columns}
                  autoHeight
                  getRowClassName={getRowClassName}
                  getRowId={(row) => row.invoiceNumber}
                  rowHeight={36}
                  sx={{
                    '& .MuiDataGrid-cell:focus-within': {
                      outline: 'none',
                    },
                    '& .MuiDataGrid-cell:focus': {
                      outline: 'none',
                    },
                  }}
                />
              </Box>

            ) : (
              <Typography>No billing history found.</Typography>
            )}
          </Box>
          <FileViewerModal
            open={openFileViewer}
            onClose={handleCloseFileViewer}
            fileContent={fileContent}
            fileName={fileName}
            fileContentType={fileContentType}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ListBillingHistory;
